// src/components/CoachDashboard.jsx

import React, { useState, useEffect ,useCallback} from 'react';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  arrayUnion,
  arrayRemove,
  increment,
  addDoc,
  setDoc,
} from 'firebase/firestore';
import { CheckIcon, X , HelpCircle, Plus, ChevronRight, Users } from 'lucide-react';
import { Input } from './ui/Input';
import { Button } from './ui/Button';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { db } from '../firebase';
import NavigationBar from './NavigationBar';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { API_URLS } from '../constants/api';
import { loadStripe } from '@stripe/stripe-js';
import SubscriptionModal from './SubscriptionModal';
import { motion } from 'framer-motion';
import { initializeClientAccount } from '../utils/clientInitialization';

const stripePromise = loadStripe('pk_live_51PmzEsJp9datwQ8XX5QXruYq4HeCdzi5qLpPTOJ6DauNGUe4RicW0vtsCiyow8gqjubN2NtcB9w5jr7ycWtgMAaR00wFdVnC4K');

const CoachDashboard = ({ handlePaymentSuccess }) => {
  const { user, isAdmin } = useAuth();
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [message, setMessage] = useState('');
  const [subscriptionQuantity, setSubscriptionQuantity] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [requestedSeats, setRequestedSeats] = useState(0);
  const [coachData, setCoachData] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('');
  const [isLoadingClients, setIsLoadingClients] = useState(true);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [isRedirectingToCheckout, setIsRedirectingToCheckout] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    if (user?.uid) {
      const unsubscribe = loadClients();
      return () => {
        if (unsubscribe) unsubscribe();
      };
    }
  }, [user?.uid]);

  useEffect(() => {
    if (user?.uid) {
      const q = query(
        collection(db, 'users'),
        where('requestedCoachId', '==', user.uid),
        where('requestStatus', '==', 'pending')
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const uniqueRequests = new Map();
        
        snapshot.docs.forEach((doc) => {
          const request = {
            id: doc.id,
            ...doc.data()
          };
          // Only add if not already in the map
          if (!uniqueRequests.has(doc.id)) {
            uniqueRequests.set(doc.id, request);
          }
        });
        
        const requests = Array.from(uniqueRequests.values());
        console.log('Pending requests:', requests);
        setPendingRequests(requests);
      });

      return () => unsubscribe();
    }
  }, [user?.uid]);

  useEffect(() => {
    let unsubscribeCoach = () => {};
  
    const setupSubscriptions = async () => {
      if (!user?.uid) return;
  
      try {
        const coachRef = doc(db, 'coaches', user.uid);
        unsubscribeCoach = onSnapshot(coachRef, (doc) => {
          if (doc.exists) {
            const data = doc.data();
            setSubscriptionQuantity(data.subscriptionQuantity || 0);
            setCoachData(data);
          }
        });
      } catch (error) {
        console.error('Error setting up subscription listener:', error);
      }
    };
  
    setupSubscriptions();
    return () => unsubscribeCoach();
  }, [user?.uid]);

  const loadClients = () => {
    if (!user?.uid) return;
  
    setIsLoadingClients(true);
  
    const clientsQuery = query(
      collection(db, 'users'),
      where('coachId', '==', user.uid)
    );
  
    return onSnapshot(clientsQuery, async (snapshot) => {
      try {
        const clientList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setClients(clientList);
        
        const clientIds = clientList.map(client => client.id);
        
        const coachDocRef = doc(db, 'coaches', user.uid);
        await updateDoc(coachDocRef, {
          numberOfClients: clientList.length,
          clients: clientIds,
          updatedAt: serverTimestamp(),
        });
  
        const coachDoc = await getDoc(coachDocRef);
        if (coachDoc.exists()) {
          const data = coachDoc.data();
          setSubscriptionQuantity(data.subscriptionQuantity || 0);
        } else {
          setSubscriptionQuantity(0);
        }
      } catch (error) {
        console.error('Error processing client update:', error);
        setMessage('Error updating clients: ' + error.message);
      } finally {
        setIsLoadingClients(false);
      }
    }, (error) => {
      console.error('Error in client subscription:', error);
      setMessage('Error loading clients: ' + error.message);
      setIsLoadingClients(false);
    });
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setUploading(true);
    setUploadMessage('');
    
    try {
      const fileContents = await file.text();
      const exercisesData = JSON.parse(fileContents);
  
      const idToken = await user.getIdToken();
      console.log('Checkout URL:', API_URLS.createCheckoutSession);
      const response = await fetch(API_URLS.uploadExercises, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify({ exercises: exercisesData }),
      });
  
      const result = await response.json();
  
      if (result.success) {
        setUploadMessage('Exercises uploaded successfully');
      } else {
        throw new Error(result.error || 'Failed to upload exercises');
      }
    } catch (error) {
      console.error('Error uploading exercises:', error);
      setUploadMessage(`Error uploading exercises: ${error.message}`);
    } finally {
      setUploading(false);
    }
  };

  const removeClient = async (clientId) => {
    try {
      const idToken = await user.getIdToken();
      const response = await fetch(API_URLS.removeClient, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify({ clientId }),
      });
  
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Failed to remove client');
      }
  
      await loadClients();
      setMessage('Client removed successfully');
    } catch (error) {
      console.error('Error removing client:', error);
      setMessage('Error removing client: ' + error.message);
    }
  };

  const handleClientClick = (clientId) => {
    navigate(`/client/${clientId}`);
  };

  const acceptRequest = async (client) => {
    try {
      // Check if coach has a customer ID and active/trialing subscription
      if (!coachData?.stripeCustomerId || coachData?.subscriptionStatus === 'canceled' || coachData?.subscriptionStatus === 'inactive') {
        // Store the pending client ID in localStorage before redirecting
        if (client.type === 'demo') {
          localStorage.setItem('pendingDemoClient', JSON.stringify(client));
        }
        
        setIsRedirectingToCheckout(true);
        const idToken = await user.getIdToken();
        
        // Define base URL
        const baseUrl = window.location.origin;
        console.log('Base URL:', baseUrl); // Debug log
        
        const sessionData = {
          seatCount: 1,
          domain: baseUrl,
          successUrl: `${baseUrl}/dashboard`,
          cancelUrl: `${baseUrl}/dashboard`,
        };
        
        console.log('Session data:', sessionData); // Debug log
        
        const response = await fetch(API_URLS.createCheckoutSession, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`,
          },
          body: JSON.stringify(sessionData),
        });

        const data = await response.json();
        console.log('Stripe session response:', data); // Debug log
        
        if (data.sessionId) {
          const stripe = await stripePromise;
          const result = await stripe.redirectToCheckout({
            sessionId: data.sessionId,
          });
          if (result.error) {
            console.error('Stripe redirect error:', result.error);
            setMessage(result.error.message);
            setIsRedirectingToCheckout(false);
          }
        }
        return;
      }

      // If subscription is active/trialing but no seats left
      if (clients.length >= subscriptionQuantity) {
        setRequestedSeats(clients.length + 1);
        setShowSubscriptionModal(true);
        return;
      }

      // Accept the request
      await updateDoc(doc(db, 'users', client.id), {
        coachId: user.uid,
        requestedCoachId: null,
        requestStatus: 'accepted',
        updatedAt: serverTimestamp(),
        subscriptionStatus: 'active',
      });
      
      // Initialize client account with welcome messages and settings
      await initializeClientAccount(client.id, user.uid, client.name);
      
      setMessage('Client request accepted successfully');
    } catch (error) {
      setMessage(`Error accepting request: ${error.message}`);
      setIsRedirectingToCheckout(false);
    }
    window.history.replaceState({}, '', '/dashboard');
  };

  
  const declineRequest = async (client) => {
    try {
      await updateDoc(doc(db, 'users', client.id), {
        requestedCoachId: null,
        requestStatus: 'declined',
        updatedAt: serverTimestamp()
      });
    } catch (error) {
      setMessage(`Error declining request: ${error.message}`);
    }
  };

  const handleSubscriptionUpdate = async (seats) => {
    setIsLoading(true);
    try {
      // Force token refresh and log the full process
      console.log('Starting subscription update...');
      console.log('Getting fresh token...');
      
      // Make sure we have a user
      if (!user) {
        throw new Error('No user found');
      }
      
      const idToken = await user.getIdToken(true);
      console.log('Token received:', {
        tokenLength: idToken.length,
        tokenStart: idToken.substring(0, 20),
        tokenEnd: idToken.substring(idToken.length - 20)
      });

      const coachDoc = await getDoc(doc(db, 'coaches', user.uid));
      const cData = coachDoc.data();
      
      // Log the full request details
      const requestDetails = {
        url: API_URLS.createCheckoutSession,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: {
          seatCount: seats || requestedSeats,
          domain: window.location.origin
        }
      };
      console.log('Request details:', requestDetails);

      if (!cData.subscriptionId || cData.subscriptionStatus === 'canceled') {
        const response = await fetch(API_URLS.createCheckoutSession, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
          },
          body: JSON.stringify({
            seatCount: seats || requestedSeats,
            domain: window.location.origin
          })
        });

        console.log('Response status:', response.status);
        console.log('Response headers:', Object.fromEntries(response.headers.entries()));
        
        const data = await response.json();
        console.log('Response data:', data);

        if (!response.ok) {
          throw new Error(data.error || 'Failed to create checkout session');
        }

        if (data.sessionId) {
          console.log('Received session ID, redirecting to checkout...');
          const stripe = await stripePromise;
          const result = await stripe.redirectToCheckout({ sessionId: data.sessionId });
          if (result.error) {
            setMessage(result.error.message);
          }
        } else {
          setShowSubscriptionModal(false);
          setMessage('Subscription updated successfully');
        }
        return;
      }

      // Update existing subscription
      let subscriptionResponse;
      try {
        subscriptionResponse = await fetch(API_URLS.createCheckoutSession, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            seatCount: seats || requestedSeats,
            domain: window.location.origin,
          }),
        });
      
        if (!subscriptionResponse.ok) {
          const errorData = await subscriptionResponse.json();
          throw new Error(errorData.error || 'Failed to create checkout session');
        }
      
        const subscriptionData = await subscriptionResponse.json();
        if (subscriptionData.sessionId) {
          const stripe = await stripePromise;
          const result = await stripe.redirectToCheckout({
            sessionId: subscriptionData.sessionId,
          });
          if (result.error) {
            setMessage(result.error.message);
            setIsRedirectingToCheckout(false);
          }
        }

        // Handle success case
        if (subscriptionData.success) {
          setShowSubscriptionModal(false);
          setMessage('Subscription updated successfully');

          const updatedCoachDoc = await getDoc(doc(db, 'coaches', user.uid));
          if (updatedCoachDoc.exists()) {
            const d = updatedCoachDoc.data();
            setSubscriptionQuantity(d.subscriptionQuantity || 0);
          }
        } else if (subscriptionData.redirectUrl) {
          window.location.href = subscriptionData.redirectUrl;
        }

      } catch (error) {
        console.error('Detailed error:', error);
        setMessage(`Error creating checkout session: ${error.message}`);
        setIsRedirectingToCheckout(false);
        throw error; // Re-throw to be caught by outer catch block
      }      

    } catch (error) {
      console.error('Full error details:', {
        name: error.name,
        message: error.message,
        stack: error.stack
      });
      setMessage(`Error updating subscription: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Combine the Stripe return check into a single useEffect
  useEffect(() => {
    const checkStripeReturn = async () => {
      if (!user) return;

      const urlParams = new URLSearchParams(window.location.search);
      const sessionId = urlParams.get('session_id');
      
      if (!sessionId) return;
      
      console.log('Checking Stripe return:', sessionId); // Should now only log once
      
      try {
        const idToken = await user.getIdToken();
        const response = await fetch(API_URLS.checkSession, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`,
          },
          body: JSON.stringify({ sessionId }),
        });

        const data = await response.json();
        if (data.success) {
          handlePaymentSuccess?.();
          // Clear the URL parameters without refreshing the page
          window.history.replaceState({}, '', window.location.pathname);
        }
      } catch (error) {
        console.error('Error checking session:', error);
      }
    };

    checkStripeReturn();
  }, [user, handlePaymentSuccess]); // Only depend on user and handlePaymentSuccess

  return (
    <div className="min-h-screen bg-background-primary mt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Dashboard Header */}
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-8">
          <div>
            <h1 className="font-headers text-3xl font-bold text-text-primary">
              Evolv.
            </h1>
            <p className="text-text-light mt-2">
              Managing {clients.length} of {subscriptionQuantity} client spots
            </p>
          </div>
          <Button 
            variant="primary"
            onClick={() => setShowSubscriptionModal(true)}
            className="flex items-center gap-2 mt-4 sm:mt-0"
          >
            <Plus className="h-4 w-4" />
            Add Client Spots
          </Button>
        </div>

        {/* Main Content */}
        <div className="space-y-6">
          {/* Client Overview Card */}
          <Card>
            <CardHeader>
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                <CardTitle>Clients</CardTitle>
                <div className="flex items-center gap-4 mt-2 sm:mt-0">
                  <span className="text-sm text-text-light">
                    {pendingRequests.length} pending {pendingRequests.length === 1 ? 'request' : 'requests'}
                  </span>
                </div>
              </div>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                {/* Pending Requests */}
                {pendingRequests.map((client) => (
                  <div 
                    key={client.id}
                    className="bg-background-tertiary rounded-lg p-4 border-l-4 border-accent-primary"
                  >
                    <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
                      <div>
                        <div className="flex items-center gap-2">
                          <span className="font-medium text-text-primary">{client.name}</span>
                          <span className="text-sm px-2 py-1 bg-accent-primary/10 text-accent-primary rounded-full">
                            New Request
                          </span>
                        </div>
                        <div className="text-sm text-text-light mt-1">{client.email}</div>
                      </div>
                      <div className="flex gap-2 mt-2 sm:mt-0">
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() => acceptRequest(client)}
                          disabled={isRedirectingToCheckout}
                        >
                          Accept
                        </Button>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => declineRequest(client)}
                          disabled={isRedirectingToCheckout}
                        >
                          Decline
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}

                {/* Active Clients */}
                {clients.map((client) => (
                  <div 
                    key={client.id}
                    className="group bg-background-secondary hover:bg-background-tertiary rounded-lg p-4 transition-all cursor-pointer"
                    onClick={() => handleClientClick(client.id)}
                  >
                    <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
                      <div>
                        <div className="font-medium text-text-primary">{client.name}</div>
                        <div className="text-sm text-text-light mt-1">{client.email}</div>
                      </div>
                      <div className="flex items-center gap-2 mt-2 sm:mt-0">
                        <Button
                          variant="ghost"
                          size="sm"
                          className="opacity-0 group-hover:opacity-100 transition-opacity"
                          onClick={(e) => {
                            e.stopPropagation();
                            removeClient(client.id);
                          }}
                        >
                          Remove
                        </Button>
                        <ChevronRight className="h-5 w-5 text-text-light group-hover:text-accent-primary transition-colors" />
                      </div>
                    </div>
                  </div>
                ))}

                {/* Empty State */}
                {isLoadingClients ? (
                  <div className="text-center py-12">
                    <div className="animate-spin h-8 w-8 border-4 border-accent-primary border-t-transparent rounded-full mx-auto mb-4" />
                    <p className="text-text-light">Loading clients...</p>
                  </div>
                ) : clients.length === 0 && pendingRequests.length === 0 ? (
                  <div className="text-center py-12">
                    <div className="bg-background-tertiary rounded-full p-4 w-16 h-16 mx-auto mb-4 flex items-center justify-center">
                      <Users className="h-8 w-8 text-text-light" />
                    </div>
                    <h3 className="text-lg font-medium text-text-primary mb-2">No clients yet</h3>
                    <p className="text-sm text-text-light max-w-md mx-auto">
                      Share your coach profile with potential clients to get started
                    </p>
                  </div>
                ) : null}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>

      {/* Existing Modals */}
      <SubscriptionModal
        isOpen={showSubscriptionModal}
        onClose={() => setShowSubscriptionModal(false)}
        currentSeats={subscriptionQuantity}
        requestedSeats={requestedSeats}
        onConfirm={handleSubscriptionUpdate}
        isLoading={isLoading}
        trialEndsAt={coachData?.trialEndsAt}
        subscriptionStatus={coachData?.subscriptionStatus || 'inactive'}
      />

      {/* Loading Overlay */}
      {isRedirectingToCheckout && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-background-secondary p-6 rounded-lg shadow-lg">
            <div className="animate-spin h-8 w-8 border-4 border-accent-primary border-t-transparent rounded-full mx-auto mb-4" />
            <div className="text-text-primary">Processing your request...</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CoachDashboard;