// src/components/SessionTab.jsx

import React, { useEffect, useState } from 'react';
import { Button } from './ui/Button';
import { Input } from './ui/Input';
import { Plus, ArrowLeft, Trash2 } from 'lucide-react';
import { format } from 'date-fns';
import { Card, CardContent, CardHeader, CardTitle } from './ui/Card';
import { doc, getDoc, updateDoc, addDoc, serverTimestamp, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import InsightsGenerator from './InsightsGenerator';
import { useAuth } from '../contexts/AuthContext';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import NudgeManager from './NudgeManager';

const SessionTab = ({
  clientId,
  clientInfo,
}) => {
  const { user } = useAuth();
  const [showNudgeManager, setShowNudgeManager] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [isAddingSession, setIsAddingSession] = useState(false);
  const [sessionData, setSessionData] = useState(null);
  const [showInsightsGenerator, setShowInsightsGenerator] = useState(false);
  const [newInsights, setNewInsights] = useState(null);
  const [nudges, setNudges] = useState([]);
  const [adherenceValue, setAdherenceValue] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    const fetchSessions = async () => {
      if (!clientId) return;
      try {
        const sessionsCollection = collection(db, 'users', clientId, 'sessions');
        const q = query(sessionsCollection, orderBy('date', 'desc'));
        const querySnapshot = await getDocs(q);
        const fetchedSessions = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSessions(fetchedSessions);
      } catch (error) {
        console.error('Error fetching sessions:', error);
      }
    };
    fetchSessions();
  }, [clientId]);

  useEffect(() => {
    const fetchNudges = async () => {
      if (!clientId) return;
      try {
        const nudgesCollection = collection(db, 'users', clientId, 'nudges');
        const q = query(nudgesCollection, orderBy('scheduledTime', 'asc'));
        const querySnapshot = await getDocs(q);
        const fetchedNudges = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setNudges(fetchedNudges);
      } catch (error) {
        console.error('Error fetching nudges:', error);
      }
    };
    fetchNudges();
  }, [clientId]);

  useEffect(() => {
    const fetchSessionData = async () => {
      if (!selectedSessionId) return;
      try {
        const sessionDoc = await getDoc(doc(db, 'users', clientId, 'sessions', selectedSessionId));
        if (sessionDoc.exists()) {
          const data = sessionDoc.data();
          setSessionData(data);
          setAdherenceValue(data.adherence || null);
        }
      } catch (error) {
        console.error('Error fetching session data:', error);
      }
    };
    fetchSessionData();
  }, [selectedSessionId, clientId]);

  const handleManageNudges = () => {
    setShowNudgeManager(true);
  };

  const handleUpdateNudges = async () => {
    const nudgesCollection = collection(db, 'users', clientId, 'nudges');
    const q = query(nudgesCollection, orderBy('scheduledTime', 'asc'));
    const querySnapshot = await getDocs(q);
    const fetchedNudges = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setNudges(fetchedNudges);
  };

  const handleTrackAdherence = async (value) => {
    try {
      setAdherenceValue(value);
      if (selectedSessionId) {
        await updateDoc(doc(db, 'users', clientId, 'sessions', selectedSessionId), {
          adherence: value
        });
      } else {
        setSessionData(prev => ({
          ...prev,
          adherence: value
        }));
      }
    } catch (error) {
      console.error('Error tracking adherence:', error);
    }
  };

  const handleSaveClick = async () => {
    if (!sessionData?.note?.trim()) {
      alert('Please enter session notes before saving.');
      return;
    }

    try {
      if (isAddingSession) {
        const sessionsCollection = collection(db, 'users', clientId, 'sessions');
        const newSessionData = {
          ...sessionData,
          date: new Date().toISOString(),
          adherence: adherenceValue,
          createdAt: serverTimestamp(),
        };
        
        const docRef = await addDoc(sessionsCollection, newSessionData);
        
        setSessions(prevSessions => [{
          id: docRef.id,
          ...newSessionData
        }, ...prevSessions]);
      } else {
        const sessionRef = doc(db, 'users', clientId, 'sessions', selectedSessionId);
        const updatedData = {
          ...sessionData,
          adherence: adherenceValue,
        };
        await updateDoc(sessionRef, updatedData);
        
        setSessions(prevSessions => prevSessions.map(session => 
          session.id === selectedSessionId ? { ...session, ...updatedData } : session
        ));
      }

      setSelectedSessionId(null);
      setIsAddingSession(false);
      setSessionData(null);
      setAdherenceValue(null);
    } catch (error) {
      console.error('Error saving session:', error);
    }
  };

  const handleInsightsGenerated = (insights) => {
    setNewInsights(insights);
    setShowInsightsGenerator(false);
  };

  const handleDeleteSession = async () => {
    if (!selectedSessionId) return;
    
    try {
      await deleteDoc(doc(db, 'users', clientId, 'sessions', selectedSessionId));
      
      setSessions(prevSessions => prevSessions.filter(session => session.id !== selectedSessionId));
      
      setSelectedSessionId(null);
      setSessionData(null);
      setAdherenceValue(null);
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error('Error deleting session:', error);
      alert('Error deleting session. Please try again.');
    }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="h-full flex flex-col bg-background-primary p-6 font-body">
      {/* Header section */}
      <div className="flex-none mb-6">
        {selectedSessionId || isAddingSession ? (
          <div className="flex justify-between items-center">
            <Button
              variant="ghost"
              className="text-accent-tertiary hover:text-accent-primary hover:bg-background-tertiary transition-all duration-300 font-medium"
              onClick={() => {
                setSelectedSessionId(null);
                setIsAddingSession(false);
                setSessionData(null);
                setAdherenceValue(null);
              }}
            >
              <ArrowLeft className="mr-2 h-4 w-4" /> Back to Sessions
            </Button>
            <div className="space-x-3">
              {selectedSessionId && !isAddingSession && (
                <Button
                  variant="destructive"
                  className="bg-red-500 text-white hover:bg-red-600 transition-all duration-300"
                  onClick={() => setIsDeleteModalOpen(true)}
                >
                  <Trash2 className="mr-2 h-4 w-4" /> Delete Session
                </Button>
              )}
              <Button
                variant="secondary"
                className="shadow-sm hover:shadow-md transition-all duration-300"
                onClick={handleManageNudges}
              >
                Manage Nudges
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-headers font-bold text-text-primary">
              Client Sessions
            </h2>
            <Button
              variant="primary"
              className="bg-accent-primary text-white hover:bg-hover-primary transition-all duration-300"
              onClick={() => {
                setIsAddingSession(true);
                setSessionData({ note: '' });
              }}
            >
              <Plus className="mr-2 h-4 w-4" /> New Session
            </Button>
          </div>
        )}
      </div>
  
      {/* Main content area */}
      <div className="flex-1 overflow-y-auto min-h-0 space-y-6">
        {!selectedSessionId && !isAddingSession ? (
          // Session List View - Improved grid layout
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {sessions?.map((session) => (
              <Card
                key={session.id}
                className="bg-background-secondary hover:bg-background-tertiary transition-all duration-300 cursor-pointer shadow-sm hover:shadow-md border border-border-light group"
                onClick={() => setSelectedSessionId(session.id)}
              >
                <CardContent className="p-4">
                  <div className="flex flex-col space-y-2">
                    <div className="flex justify-between items-center">
                      <p className="text-text-primary font-medium">
                        {format(new Date(session.date), 'MMM d, yyyy')}
                      </p>
                      {session.adherence && (
                        <span className={`px-2 py-1 rounded-full text-sm ${
                          session.adherence >= 8 ? 'bg-green-100 text-green-800' :
                          session.adherence >= 5 ? 'bg-yellow-100 text-yellow-800' :
                          'bg-red-100 text-red-800'
                        }`}>
                          {session.adherence}/10
                        </span>
                      )}
                    </div>
                    {session.note && (
                      <p className="text-text-light text-sm line-clamp-2 group-hover:text-text-primary transition-colors">
                        {session.note}
                      </p>
                    )}
                  </div>
                </CardContent>
              </Card>
            )) || (
              <p className="text-text-light col-span-full text-center py-8">
                No sessions found. Click "New Session" to get started.
              </p>
            )}
          </div>
        ) : (
          // Session Detail View
          <div className="space-y-6">
            {/* Session Details Card */}
            <Card className="bg-background-secondary border border-border-medium">
              <CardHeader className="border-b border-border-light">
                <div className="flex justify-between items-center">
                  <CardTitle className="text-text-primary font-headers text-h2">
                    {isAddingSession ? 'New Session' : 'Session Details'}
                  </CardTitle>
                  <div className="flex flex-col items-end">
                    <span className="text-sm text-text-light mb-2">Adherence Score</span>
                    <div className="flex items-center space-x-1">
                      {[...Array(10)].map((_, i) => (
                        <button
                          key={i}
                          className={`w-6 h-6 rounded-full transition-colors ${
                            (adherenceValue || sessionData?.adherence) > i
                              ? 'bg-accent-primary'
                              : 'bg-background-tertiary hover:bg-accent-secondary'
                          }`}
                          onClick={() => handleTrackAdherence(i + 1)}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </CardHeader>
              <CardContent className="p-6">
                <textarea
                  className="w-full h-32 p-4 bg-background-primary border border-border-light rounded-md 
                    focus:ring-2 focus:ring-accent-primary focus:border-accent-primary
                    text-text-primary font-body transition-all duration-300"
                  value={sessionData?.note || ''}
                  onChange={(e) => setSessionData({ ...(sessionData || {}), note: e.target.value })}
                  placeholder="Enter session notes here..."
                />
              </CardContent>
            </Card>
  
            {/* AI-Generated Insights Card */}
            <Card className="bg-background-secondary border border-border-medium">
              <CardHeader className="border-b border-border-light">
                <div className="flex justify-between items-center">
                  <CardTitle className="text-text-primary font-headers text-h2">
                    AI-Generated Insights
                  </CardTitle>
                  <Button
                    variant="primary"
                    className="shadow-sm hover:shadow-md transition-all duration-300"
                    onClick={() => setShowInsightsGenerator(true)}
                  >
                    Generate Insights
                  </Button>
                </div>
              </CardHeader>
              <CardContent className="p-6">
                {newInsights || sessionData?.insights ? (
                  <div className="space-y-6">
                    {/* Summary Section */}
                    <div>
                      <h3 className="text-h3 font-headers font-medium text-accent-tertiary mb-3">Summary</h3>
                      <p className="text-text-secondary leading-relaxed">
                        {(newInsights || sessionData.insights).summary}
                      </p>
                    </div>
                    
                    {/* Wins Section */}
                    {(newInsights || sessionData.insights).wins &&
                      Object.keys((newInsights || sessionData.insights).wins).length > 0 && (
                        <div>
                          <h3 className="text-h3 font-headers font-medium text-accent-tertiary mb-3">Wins</h3>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {Object.entries((newInsights || sessionData.insights).wins).map(
                              ([category, items]) => (
                                <div key={category} className="bg-background-secondary rounded-lg p-4">
                                  <h4 className="text-text-primary font-medium mb-2 capitalize">
                                    {category.replace(/_/g, ' ')}
                                  </h4>
                                  <ul className="space-y-2">
                                    {items.map((item, index) => (
                                      <li key={index} className="text-text-secondary flex items-start">
                                        <span className="text-accent-tertiary mr-2">•</span>
                                        {item}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )}
  
                    {/* Challenges Section */}
                    {(newInsights || sessionData.insights).challenges &&
                      Object.keys((newInsights || sessionData.insights).challenges).length > 0 && (
                        <div>
                          <h3 className="text-h3 font-headers font-medium text-accent-tertiary mb-3">
                            Challenges
                          </h3>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {Object.entries(
                              (newInsights || sessionData.insights).challenges
                            ).map(([category, items]) => (
                              <div key={category} className="bg-background-secondary rounded-lg p-4">
                                <h4 className="text-text-primary font-medium mb-2 capitalize">
                                  {category.replace(/_/g, ' ')}
                                </h4>
                                <ul className="space-y-2">
                                  {items.map((item, index) => (
                                    <li key={index} className="text-text-secondary flex items-start">
                                      <span className="text-accent-tertiary mr-2">•</span>
                                      {item}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
  
                    {/* Recommendations Section */}
                    {(newInsights || sessionData.insights).recommendations &&
                      (newInsights || sessionData.insights).recommendations.length > 0 && (
                        <div>
                          <h3 className="text-h3 font-headers font-medium text-accent-tertiary mb-3">
                            AI Recommendations
                          </h3>
                          <div className="bg-background-secondary rounded-lg p-4">
                            <ul className="space-y-3">
                              {(newInsights || sessionData.insights).recommendations.map(
                                (item, index) => (
                                  <li key={index} className="text-text-secondary flex items-start">
                                    <span className="text-accent-tertiary mr-2">{index + 1}.</span>
                                    {item}
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      )}
                  </div>
                ) : (
                  <p className="text-text-light">
                    Click "Generate Insights" to analyze this session.
                  </p>
                )}
              </CardContent>
            </Card>
          </div>
        )}
      </div>
  
      {/* Footer */}
      {(selectedSessionId || isAddingSession) && (
        <div className="flex-none mt-6">
          <Button
            variant="primary"
            className="w-full shadow-md hover:shadow-lg transition-all duration-300"
            onClick={handleSaveClick}
          >
            {isAddingSession ? (
              <>
                <Plus className="mr-2 h-4 w-4" /> Save New Session
              </>
            ) : (
              'Save Changes'
            )}
          </Button>
        </div>
      )}
  
      {/* Insights Generator Modal */}
      {showInsightsGenerator && (
        <InsightsGenerator
          clientId={clientId}
          onClose={() => setShowInsightsGenerator(false)}
          onInsightsGenerated={handleInsightsGenerated}
        />
      )}

      {/* Add NudgeManager Modal */}
      {showNudgeManager && (
        <NudgeManager
          nudges={nudges}
          onClose={() => setShowNudgeManager(false)}
          onUpdateNudges={handleUpdateNudges}
          clientId={clientId}
          coachFirstName={user?.displayName?.split(' ')[0] || 'Coach'}
        />
      )}

      {/* Add Delete Confirmation Modal */}
      {isDeleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-background-primary rounded-lg p-6 max-w-md w-full mx-4">
            <h3 className="text-lg font-semibold mb-4">Delete Session</h3>
            <p className="text-text-secondary mb-6">
              Are you sure you want to delete this session? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <Button
                variant="ghost"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="destructive"
                className="bg-red-500 text-white hover:bg-red-600"
                onClick={handleDeleteSession}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SessionTab;
