// src/components/MessagesPage.jsx

import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  collection,
  doc,
  getDocs,
  query,
  orderBy,
  deleteDoc,
  addDoc,
  getDoc,
  where,
  updateDoc,
  serverTimestamp,
  onSnapshot,
  limit
} from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import ConversationSidebar from './components/ConversationSidebar';
import ChatArea from './components/ChatArea';

const MessagesPage = () => {
  const { user, isAdmin } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const currentPage = location.pathname.substring(1);
  const [pendingMessages, setPendingMessages] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [aiResponse, setAiResponse] = useState('');
  const [currentPendingMessageId, setCurrentPendingMessageId] = useState(null);
  const chatContainerRef = useRef(null);
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);

  const inactivityTime = 15 * 1000; // 15 seconds in milliseconds
  const inactivityTimeoutRef = useRef(null);

  useEffect(() => {
    let heartbeatInterval;

    const updateLastActive = async () => {
      if (user) {
        const coachDocRef = doc(db, 'coaches', user.uid);
        await updateDoc(coachDocRef, {
          lastActive: serverTimestamp(),
        });
      }
    };

    updateLastActive(); // Update immediately when component mounts

    heartbeatInterval = setInterval(updateLastActive, 5000); // Update every 5 seconds

    // Cleanup function
    return () => {
      clearInterval(heartbeatInterval);
    };
  }, [user]);

  useEffect(() => {
    fetchPendingMessages();
    fetchConversations();
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatMessages]);

  const fetchPendingMessages = async () => {
    try {
      const pendingMessagesRef = collection(db, 'coaches', user.uid, 'pendingMessages');
      const q = query(pendingMessagesRef, orderBy('timestamp', 'asc'));
      const querySnapshot = await getDocs(q);
      const messages = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPendingMessages(messages);
    } catch (error) {
      console.error('Error fetching pending messages:', error);
    }
  };

  const fetchConversations = async () => {
    try {
      const coachDoc = await getDoc(doc(db, 'coaches', user.uid));
      const coachData = coachDoc.data();
      
      if (coachData.clients && coachData.clients.length > 0) {
        const clientPromises = coachData.clients.map(async (clientId) => {
          const clientDoc = await getDoc(doc(db, 'users', clientId));
          const clientData = clientDoc.data();
          
          // Get recent messages, but look for the most recent non-preview message
          const messagesRef = collection(db, 'users', clientId, 'messages');
          const q = query(messagesRef, orderBy('date_sent', 'desc'), limit(5)); // Fetch more messages to find non-preview
          const messageSnapshot = await getDocs(q);
          
          // Find the first message that isn't a preview type
          const lastMessage = messageSnapshot.docs.find(doc => {
            const messageData = doc.data();
            return messageData.type !== 'meal_preview' && messageData.type !== 'workout';
          })?.data();
          
          return {
            clientId,
            name: clientData.name || clientData.email || clientData.phoneNumber || 'Unnamed Client',
            lastActive: clientData.lastActive,
            lastMessage: lastMessage ? {
              content: lastMessage.content,
              type: lastMessage.type,
              date: lastMessage.date_sent,
              isPending: lastMessage.isPending || false,
              aiResponse: lastMessage.aiResponse
            } : null
          };
        });
        
        const clients = await Promise.all(clientPromises);
        const sortedClients = clients.sort((a, b) => {
          const aTime = a.lastMessage?.date?.seconds || a.lastActive?.seconds || 0;
          const bTime = b.lastMessage?.date?.seconds || b.lastActive?.seconds || 0;
          return bTime - aTime;
        });
        
        setConversations(sortedClients);
      }
    } catch (error) {
      console.error('Error fetching conversations:', error);
    }
  };

  useEffect(() => {
    // Set up real-time listener for conversations
    const setupConversationsListener = async () => {
      if (!user?.uid) return;

      const coachDoc = await getDoc(doc(db, 'coaches', user.uid));
      const coachData = coachDoc.data();
      
      if (!coachData?.clients?.length) return;

      // Create listeners for each client's messages collection
      const unsubscribes = coachData.clients.map(clientId => {
        const messagesRef = collection(db, 'users', clientId, 'messages');
        const q = query(messagesRef, orderBy('date_sent', 'desc'), limit(5));
        
        return onSnapshot(q, async () => {
          // Refresh conversations when messages change
          await fetchConversations();
        });
      });

      // Cleanup function to unsubscribe all listeners
      return () => unsubscribes.forEach(unsubscribe => unsubscribe());
    };

    setupConversationsListener();
  }, [user]);

  const handleSelectClient = async (client, pendingMessage = null) => {
    setSelectedClient(client);
    if (pendingMessage) {
      setMessage(pendingMessage.aiResponse);
      setCurrentPendingMessageId(pendingMessage.id);
    } else {
      setMessage('');
      setCurrentPendingMessageId(null);
    }
    setAiResponse('');
    await fetchConversation(client.clientId);
  };

  const fetchConversation = async (clientId) => {
    try {
      const messagesRef = collection(db, 'users', clientId, 'messages');
      const q = query(messagesRef, orderBy('date_sent', 'asc'));
      
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const messages = snapshot.docs.map((doc) => {
          const data = doc.data();
          const isFromCoach = data.sender_id === data.coach_id;
          
          // Handle preview messages
          if (data.type === 'workout' || data.type === 'meal_preview') {
            return {
              id: doc.id,
              text: data.content, // This contains the JSON string
              sender: isFromCoach ? 'coach' : 'client',
              time: data.date_sent?.toDate().toLocaleString() || new Date().toLocaleString(),
              type: data.type,
              isPending: data.isPending || false,
              aiResponse: data.aiResponse
            };
          }

          // Handle regular messages
          return {
            id: doc.id,
            text: data.content,
            sender: isFromCoach ? 'coach' : 'client',
            time: data.date_sent?.toDate().toLocaleString() || new Date().toLocaleString(),
            isPending: data.isPending || false,
            aiResponse: data.aiResponse
          };
        });
        
        setChatMessages(messages);
      });

      return unsubscribe;
    } catch (error) {
      console.error('Error setting up message listener:', error);
    }
  };

  async function handleSendMessage(clientId, messageContent, pendingMessageId = null) {
    try {
      // Prevent empty messages
      if (!messageContent.trim()) return;

      // Create message document
      const messageDoc = {
        content: messageContent,
        is_system_message: false,
        date_sent: serverTimestamp(),
        media_url: "",
        source: "web",
        client_id: clientId,
        coach_id: user.uid,
        sender_id: user.uid
      };

      // Add message to user's messages collection
      const messageRef = collection(db, 'users', clientId, 'messages');
      await addDoc(messageRef, messageDoc);

      // Handle pending message if it exists
      if (pendingMessageId) {
        const pendingMessageRef = doc(db, 'coaches', user.uid, 'pendingMessages', pendingMessageId);
        const pendingMessageDoc = await getDoc(pendingMessageRef);
        
        if (pendingMessageDoc.exists()) {
          const pendingMessageData = pendingMessageDoc.data();
          await saveExchange(
            clientId, 
            messageContent, 
            pendingMessageData.aiResponse, 
            pendingMessageData.userMessage
          );
          
          // Delete the pending message
          await deleteDoc(pendingMessageRef);
          setPendingMessages((prev) => prev.filter((msg) => msg.id !== pendingMessageId));
        }
      }

      // Reset states
      setMessage('');
      setAiResponse('');
      setCurrentPendingMessageId(null);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  }
  
  const saveExchange = async (clientId, coachResponse, aiResponse, userMessage) => {
    try {
      const editsRequired = coachResponse !== aiResponse;
      const exchangeData = {
        coachResponse,
        aiResponse,
        userMessage,
        editsRequired,
        timestamp: new Date(),
      };
      await addDoc(collection(db, 'coaches', user.uid, 'messageExchanges'), exchangeData);
    } catch (error) {
      console.error('Error saving exchange:', error);
    }
  };

  const handleQuickSend = async (pendingMessage) => {
    await handleSendMessage(
      pendingMessage.clientId,
      pendingMessage.aiResponse,
      pendingMessage.id
    );
  };

  const handleCancelPendingMessage = async (pendingMessageId) => {
    try {
      // Delete the pending message from Firestore
      const pendingMessageRef = doc(db, 'coaches', user.uid, 'pendingMessages', pendingMessageId);
      await deleteDoc(pendingMessageRef);
  
      // Update state
      setPendingMessages((prev) => prev.filter((msg) => msg.id !== pendingMessageId));
      setMessage('');
      setAiResponse('');
      setCurrentPendingMessageId(null);
    } catch (error) {
      console.error('Error canceling pending message:', error);
    }
  };

  const onNavigate = (path) => {
    navigate(`/${path}`);
  };

  const onSelectClient = (clientId) => {
    navigate(`/client/${clientId}`);
  };

  const renderPreviewCard = (msg) => {
    try {
      let content;
      if (typeof msg.text === 'string') {
        content = JSON.parse(msg.text);
      } else {
        content = msg.text;
      }
      
      // For workout messages
      if (msg.type === 'workout' && content.preview) {
        return <WorkoutPreviewCard preview={content.preview} />;
      }
      
      // For meal preview messages
      if (msg.type === 'meal_preview') {
        // Parse the content if it's a string
        const mealData = typeof content === 'string' ? JSON.parse(content) : content;
        // Transform the data to match the MealPreviewCard expected format
        const preview = {
          date: mealData.date,
          totalProtein: mealData.totalProtein,
          totalCalories: mealData.totalCalories,
          foodItems: mealData.foodItems
        };
        return <MealPreviewCard preview={preview} />;
      }
      
      // Fallback for regular messages
      return typeof msg.text === 'string' ? msg.text : JSON.stringify(msg.text);
    } catch (error) {
      console.error('Error rendering preview card:', error);
      console.error('Message data:', msg);
      return typeof msg.text === 'string' ? msg.text : JSON.stringify(msg.text);
    }
  };

  // Combine conversations with pending messages
  const combinedConversations = useMemo(() => {
    const conversationMap = new Map();
    
    // Add regular conversations
    conversations.forEach(conv => {
      conversationMap.set(conv.clientId, { ...conv });
    });
    
    // Add or update with pending messages
    pendingMessages.forEach(pending => {
      const existing = conversationMap.get(pending.clientId);
      if (existing) {
        existing.pendingMessage = pending;
      } else {
        conversationMap.set(pending.clientId, {
          clientId: pending.clientId,
          name: pending.clientEmail || pending.clientPhoneNumber || 'Unknown Client',
          pendingMessage: pending
        });
      }
    });
    
    // Convert map to array and sort
    return Array.from(conversationMap.values()).sort((a, b) => {
      // Prioritize conversations with pending messages
      if (a.pendingMessage && !b.pendingMessage) return -1;
      if (!a.pendingMessage && b.pendingMessage) return 1;
      
      // Then sort by most recent message/activity
      const aTime = a.lastMessage?.date?.seconds || a.lastActive?.seconds || 0;
      const bTime = b.lastMessage?.date?.seconds || b.lastActive?.seconds || 0;
      return bTime - aTime;
    });
  }, [conversations, pendingMessages]);

  useEffect(() => {
    console.log('Pending Messages:', pendingMessages);
    console.log('Combined Conversations:', combinedConversations);
  }, [pendingMessages, combinedConversations]);

  // Add function to handle mobile drawer
  const toggleMobileDrawer = () => {
    setIsMobileDrawerOpen(!isMobileDrawerOpen);
  };

  return (
    <div className="bg-background-primary min-h-screen md:pt-14 flex flex-col">
      <div className="flex flex-grow overflow-hidden p-4 gap-4 max-w-8xl mx-auto w-full h-[calc(100vh-120px)] md:h-[calc(100vh-120px)] pb-16 md:pb-4">
        {/* Mobile Sidebar Overlay */}
        {isMobileDrawerOpen && (
          <div 
            className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden"
            onClick={() => setIsMobileDrawerOpen(false)}
          />
        )}

        {/* Sidebar - Mobile & Desktop */}
        <div 
          className={`
            fixed md:relative inset-y-0 left-0 z-50 w-[85%] sm:w-[400px] md:w-1/3 md:min-w-[300px] md:max-w-[400px]
            transform transition-transform duration-300 ease-in-out
            ${isMobileDrawerOpen ? 'translate-x-0' : '-translate-x-full md:translate-x-0'}
          `}
        >
          <ConversationSidebar 
            conversations={combinedConversations}
            onSelectClient={(client, pendingMessage) => {
              handleSelectClient(client, pendingMessage);
              setIsMobileDrawerOpen(false);
            }}
            onQuickSend={handleQuickSend}
            onCancelPending={handleCancelPendingMessage}
            isMobile={true}
            onClose={() => setIsMobileDrawerOpen(false)}
          />
        </div>
        
        {/* Chat Area */}
        <div className="flex-1 w-full md:w-2/3">
          <ChatArea 
            selectedClient={selectedClient}
            chatMessages={chatMessages}
            message={message}
            setMessage={setMessage}
            handleSendMessage={handleSendMessage}
            currentPendingMessageId={currentPendingMessageId}
            chatContainerRef={chatContainerRef}
            onSelectClient={onSelectClient}
            renderPreviewCard={renderPreviewCard}
            onOpenMobileDrawer={toggleMobileDrawer}
            isMobileDrawerOpen={isMobileDrawerOpen}
          />
        </div>
      </div>
    </div>
  );
};

export default MessagesPage;
