import React from 'react';
import { cn } from '../../lib/utils';

const NavigationBar = ({ tabs = [], activeTab, onChange }) => {
  return (
    <div className="border-b border-border-light">
      <div className="flex space-x-1">
        {Array.isArray(tabs) && tabs.map((tab) => (
          <button
            key={tab.id || Math.random()}
            onClick={() => onChange && onChange(tab.id)}
            className={cn(
              "flex items-center gap-2 px-4 py-2.5 text-sm font-medium transition-all",
              "border-b-2 -mb-px",
              activeTab === tab.id
                ? "border-accent-primary text-accent-primary"
                : "border-transparent text-text-light hover:text-text-primary hover:border-border-medium"
            )}
          >
            {tab.icon || null}
            {tab.label || 'Tab'}
          </button>
        ))}
      </div>
    </div>
  );
};

export default NavigationBar; 