import React, { useState, useEffect } from 'react';
import { Input } from '../ui/Input';
import { Textarea } from '../ui/Textarea';
import { Select } from '../ui/Select';
import { Button } from '../ui/Button';
import { X, Plus, Move, Save, AlertCircle, Info } from 'lucide-react';
import { Badge } from '../ui/Badge';
import { Tooltip } from '../ui/Tooltip';

const QuestionEditor = ({ question, onUpdate }) => {
  const [title, setTitle] = useState(question.title || '');
  const [description, setDescription] = useState(question.description || '');
  const [type, setType] = useState(question.type || 'text');
  const [step, setStep] = useState(question.step || 0);
  const [options, setOptions] = useState(question.options || []);
  const [validations, setValidations] = useState(question.validations || {});
  const [saving, setSaving] = useState(false);
  
  // Determine if fields are editable based on question properties
  const isDefaultQuestion = question.isDefault || false;
  const isEditable = question.isEditable || {
    title: true,
    description: true,
    options: true,
    type: true
  };
  
  // Update local state when question prop changes
  useEffect(() => {
    setTitle(question.title || '');
    setDescription(question.description || '');
    setType(question.type || 'text');
    setStep(question.step || 0);
    setOptions(question.options || []);
    setValidations(question.validations || {});
  }, [question]);
  
  // Generate a safe value from label text (lowercase, no spaces, alphanumeric)
  const generateValueFromLabel = (label) => {
    return label
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '_')
      .replace(/_+/g, '_')
      .replace(/^_|_$/g, '');
  };
  
  const handleSave = async () => {
    setSaving(true);
    try {
      const updatedQuestion = {
        ...question, // Preserve all original properties including isDefault, isEditable, etc.
        title,
        description,
        type: isDefaultQuestion && !isEditable.type ? question.type : type,
        step,
        options: isDefaultQuestion && !isEditable.options ? question.options : options,
        validations,
        // isRequired and saveToField are now handled by the backend
      };
      
      await onUpdate(updatedQuestion);
    } finally {
      setSaving(false);
    }
  };
  
  const addOption = () => {
    const label = `Option ${options.length + 1}`;
    const newOption = {
      label: label,
      value: generateValueFromLabel(label)
    };
    setOptions([...options, newOption]);
  };
  
  const updateOption = (index, label) => {
    const newOptions = [...options];
    newOptions[index] = {
      label: label,
      value: generateValueFromLabel(label)
    };
    setOptions(newOptions);
  };
  
  const removeOption = (index) => {
    setOptions(options.filter((_, i) => i !== index));
  };
  
  const updateValidation = (field, value) => {
    setValidations({
      ...validations,
      [field]: value
    });
  };
  
  return (
    <div className="p-4 space-y-4 bg-background-secondary/20">
      {isDefaultQuestion && (
        <div className="bg-primary/10 border border-primary/20 rounded-md p-3 mb-4">
          <div className="flex items-start">
            <AlertCircle className="h-5 w-5 text-primary mr-2 mt-0.5" />
            <div>
              <h4 className="font-medium text-primary">Required Question</h4>
              <p className="text-sm text-gray-600">
                This question is required for the AI algorithm. Some fields may be restricted from editing.
              </p>
            </div>
          </div>
        </div>
      )}
      
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium mb-1">Question Title</label>
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter question title"
            disabled={isDefaultQuestion && !isEditable.title}
          />
        </div>
        
        <div>
          <label className="block text-sm font-medium mb-1">Description</label>
          <Textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter question description"
            rows={2}
            disabled={isDefaultQuestion && !isEditable.description}
          />
        </div>
        
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium mb-1">
              Question Type
              {isDefaultQuestion && !isEditable.type && (
                <Tooltip content="This field cannot be changed for required questions">
                  <Info className="h-4 w-4 inline ml-1 text-gray-400" />
                </Tooltip>
              )}
            </label>
            <Select
              value={type}
              onChange={(e) => setType(e.target.value)}
              disabled={isDefaultQuestion && !isEditable.type}
            >
              <option value="text">Text</option>
              <option value="number">Number</option>
              <option value="multipleChoice">Multiple Choice</option>
              <option value="checkbox">Checkbox</option>
              <option value="date">Date</option>
            </Select>
          </div>
          
          <div>
            <label className="block text-sm font-medium mb-1">Step</label>
            <Select
              value={step}
              onChange={(e) => setStep(parseInt(e.target.value))}
            >
              <option value={0}>Step 1</option>
              <option value={1}>Step 2</option>
              <option value={2}>Step 3</option>
              <option value={3}>Step 4</option>
              <option value={4}>Step 5</option>
            </Select>
          </div>
        </div>
        
        {/* Options for multiple choice and checkbox questions */}
        {(type === 'multipleChoice' || type === 'checkbox') && (
          <div>
            <label className="block text-sm font-medium mb-2">
              Options
              {isDefaultQuestion && !isEditable.options && (
                <Tooltip content="Options cannot be changed for this required question">
                  <Info className="h-4 w-4 inline ml-1 text-gray-400" />
                </Tooltip>
              )}
            </label>
            
            <div className="space-y-2">
              {options.map((option, index) => (
                <div key={index} className="flex items-center gap-2">
                  <Input
                    value={option.label}
                    onChange={(e) => updateOption(index, e.target.value)}
                    placeholder="Option text"
                    className="flex-1"
                    disabled={isDefaultQuestion && !isEditable.options}
                  />
                  {(!isDefaultQuestion || isEditable.options) && (
                    <Button
                      type="button"
                      variant="ghost"
                      size="icon"
                      onClick={() => removeOption(index)}
                      className="text-destructive"
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  )}
                </div>
              ))}
              
              {(!isDefaultQuestion || isEditable.options) && (
                <Button
                  type="button"
                  variant="outline"
                  size="sm"
                  onClick={addOption}
                  className="mt-2"
                >
                  <Plus className="h-4 w-4 mr-1" />
                  Add Option
                </Button>
              )}
            </div>
          </div>
        )}
        
        {/* Validations based on question type */}
        {type === 'number' && (
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium mb-1">Min Value</label>
              <Input
                type="number"
                value={validations.min || ''}
                onChange={(e) => updateValidation('min', e.target.value ? parseInt(e.target.value) : '')}
                placeholder="Minimum value"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Max Value</label>
              <Input
                type="number"
                value={validations.max || ''}
                onChange={(e) => updateValidation('max', e.target.value ? parseInt(e.target.value) : '')}
                placeholder="Maximum value"
              />
            </div>
          </div>
        )}
        
        <div className="flex justify-end">
          <Button
            type="button"
            onClick={handleSave}
            disabled={saving}
            className="flex items-center"
          >
            {saving ? 'Saving...' : (
              <>
                <Save className="h-4 w-4 mr-1" />
                Save Question
              </>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default QuestionEditor; 