// src/components/ClientSearch.jsx

import React, { useState, useEffect, useRef } from 'react';
import { collection, query, doc, getDoc, getDocs, where } from 'firebase/firestore';
import { db } from '../firebase';
import { Input } from './ui/Input';
import { User } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';

const ClientSearch = ({ value, onChange, className }) => {
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);
  const [results, setResults] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [clients, setClients] = useState([]);

  // Fetch all clients for this coach on mount
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('coachId', '==', user.uid));
        const snapshot = await getDocs(q);
        const clientsList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setClients(clientsList);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };
    fetchClients();
  }, [user.uid]);

  // Fetch selected client name on mount if value exists
  useEffect(() => {
    const fetchClient = async () => {
      if (!value) return;
      try {
        const userDoc = await getDoc(doc(db, 'users', value));
        if (userDoc.exists()) {
          const clientData = userDoc.data();
          setSearchTerm(clientData.name);
          setSelectedClient(clientData);
        }
      } catch (error) {
        console.error('Error fetching client:', error);
      }
    };
    fetchClient();
  }, [value]);

  // Local search function
  const searchClients = (term) => {
    if (!term) {
      setResults([]);
      return;
    }

    const searchTermLower = term.toLowerCase();
    const filteredResults = clients.filter(client => 
      client.name?.toLowerCase().includes(searchTermLower)
    );

    // Sort results - exact matches first, then alphabetically
    const sortedResults = filteredResults.sort((a, b) => {
      const aStartsWith = a.name?.toLowerCase().startsWith(searchTermLower);
      const bStartsWith = b.name?.toLowerCase().startsWith(searchTermLower);
      
      if (aStartsWith && !bStartsWith) return -1;
      if (!aStartsWith && bStartsWith) return 1;
      
      return (a.name || '').localeCompare(b.name || '');
    });

    setResults(sortedResults);
    setIsOpen(true);
  };

  // Add debounced search
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchTerm) {
        searchClients(searchTerm);
      }
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSelectClient = (client) => {
    setSearchTerm(client.name);
    setSelectedClient(client);
    onChange(client.id);
    setIsOpen(false);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <div className="relative">
        <Input
          type="text"
          value={searchTerm}
          onChange={(e) => {
            const value = e.target.value;
            setSearchTerm(value);
            setIsOpen(true);
            if (!value) {
              onChange('');
              setSelectedClient(null);
              setResults([]);
            }
          }}
          onFocus={() => {
            setIsOpen(true);
            if (searchTerm) {
              searchClients(searchTerm);
            }
          }}
          placeholder="Search clients..."
          className={`bg-neutral-800 border-neutral-700 ${className}`}
        />
        <User className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
      </div>

      {isOpen && results.length > 0 && (
        <div className="absolute z-50 w-full mt-1 bg-neutral-800 border border-neutral-700 rounded-md shadow-lg max-h-60 overflow-auto">
          {results.map(client => (
            <div
              key={client.id}
              className="px-4 py-2 hover:bg-neutral-700 cursor-pointer"
              onClick={() => handleSelectClient(client)}
            >
              <div className="font-medium text-white">{client.name}</div>
              <div className="text-sm text-gray-400">{client.email}</div>
            </div>
          ))}
        </div>
      )}

      {isOpen && searchTerm && results.length === 0 && (
        <div className="absolute z-50 w-full mt-1 bg-neutral-800 border border-neutral-700 rounded-md shadow-lg p-4 text-center text-gray-400">
          No clients found
        </div>
      )}
    </div>
  );
};

export default ClientSearch;