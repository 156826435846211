// src/services/workoutMessageService.js

import { db } from '../firebase';
import { collection, addDoc, doc, updateDoc, setDoc, getDoc, Timestamp } from 'firebase/firestore';

export const sendWorkoutMessage = async (clientId, workoutData) => {
  try {
    // First ensure we have all exercise data with complete structure
    const exercisePromises = workoutData.exercises.map(async (exercise) => {
      if (!exercise.exerciseId) return {
        ...exercise,
        name: "Unnamed Exercise",
        instructions: [],
        commonMistakes: [],
        mediaUrl: '',
        tags: []
      };

      const exerciseRef = doc(db, 'exercises', exercise.exerciseId);
      const exerciseDoc = await getDoc(exerciseRef);
      const exData = exerciseDoc.data() || {};
      
      // Normalize video URL field to mediaUrl
      const mediaUrl = exData.mediaUrl || exData.defaultVideoUrl || exData.coachVideo || '';

      // Create clean exercise object without legacy fields
      const cleanExercise = {
        ...exercise,
        name: exData.name || 'Unnamed Exercise',
        instructions: exData.instructions || [],
        commonMistakes: exData.commonMistakes || [],
        mediaUrl,  // Only use normalized mediaUrl field
        tags: exData.tags || [],
        sets: exercise.sets?.length > 0 ? exercise.sets : [{
          reps: 0,
          weight: 0,
          actualReps: null,
          actualWeight: null,
          completed: false
        }]
      };

      // Explicitly remove any unwanted fields
      delete cleanExercise.coachVideo;
      delete cleanExercise.defaultVideoUrl;
      delete cleanExercise.isCustomExercise;

      return cleanExercise;
    });

    const exercisesWithNames = await Promise.all(exercisePromises);

    // Handle scheduledTime conversion
    let scheduledTimestamp;
    if (workoutData.scheduledTime instanceof Timestamp) {
      scheduledTimestamp = workoutData.scheduledTime;
    } else if (workoutData.scheduledTime?.seconds) {
      scheduledTimestamp = new Timestamp(
        workoutData.scheduledTime.seconds,
        workoutData.scheduledTime.nanoseconds || 0
      );
    } else {
      // Default to current time if no valid scheduledTime is provided
      scheduledTimestamp = Timestamp.now();
    }

    // Create the workout document with clean exercise data
    const workoutDoc = {
      id: workoutData.id,
      name: workoutData.name || 'Untitled Workout',
      description: workoutData.description || '',
      headerImageUrl: workoutData.headerImageUrl || '',
      scheduledTime: scheduledTimestamp,
      status: 'assigned',
      dateAssigned: Timestamp.now(),
      dateStarted: null,
      dateCompleted: null,
      coachId: workoutData.coachId || '',
      sections: workoutData.sections || [],
      exerciseCount: exercisesWithNames.length,
      duration: estimateWorkoutDuration(exercisesWithNames),
      exercises: exercisesWithNames.map((exercise, index) => {
        // Create clean exercise object for workout document
        const cleanExercise = {
          exerciseId: exercise.exerciseId,
          name: exercise.name,
          description: "",
          instructions: exercise.instructions,
          commonMistakes: exercise.commonMistakes,
          mediaUrl: exercise.mediaUrl,  // Only use normalized mediaUrl field
          sets: exercise.sets?.map((set, setIndex) => ({
            setNumber: setIndex + 1,
            reps: set.reps || exercise.reps || 8,
            weight: set.weight || exercise.weight || 0,
            actualReps: set.reps || exercise.reps || 8,  // Initialize with reps
            actualWeight: set.weight || exercise.weight || 0,  // Initialize with weight
            completed: false,
            rpe: null
          })) || [],
          rest: parseInt(exercise.rest) || 60,
          notes: exercise.notes || "",
          order: index,
          sectionId: exercise.sectionId,
          sectionType: exercise.sectionType,
          sectionName: exercise.sectionName,
          completed: false
        };

        // Explicitly remove any unwanted fields
        delete cleanExercise.coachVideo;
        delete cleanExercise.defaultVideoUrl;
        delete cleanExercise.isCustomExercise;

        return cleanExercise;
      })
    };

    // Create the properly formatted preview object
    const previewData = {
      duration: workoutDoc.duration,
      headerImageUrl: workoutDoc.headerImageUrl,
      userId: clientId,  // Add userId to match iOS format
      scheduledTime: scheduledTimestamp,
      description: workoutDoc.description,
      id: workoutDoc.id,
      exerciseCount: workoutDoc.exerciseCount,
      name: workoutDoc.name
    };

    // Create message with properly formatted preview
    const messageContent = {
      clientId: clientId,  // Add clientId to match iOS format
      type: 'workout',
      workoutId: workoutDoc.id,
      preview: previewData
    };

    // Add message to client's messages collection
    await addDoc(collection(db, 'users', clientId, 'messages'), {
      content: JSON.stringify(messageContent),
      date_sent: Timestamp.now(),
      is_system_message: false,
      type: 'workout',
      source: 'web',
      client_id: clientId,
      coach_id: workoutData.coachId,
      sender_id: workoutData.coachId,
      media_url: ''  // Add empty media_url to match iOS format
    });

    return workoutDoc.id;
  } catch (error) {
    console.error('Error sending workout:', error);
    throw error;
  }
};


// Helper function to estimate workout duration based on exercises
function estimateWorkoutDuration(exercises) {
  const totalSets = exercises.reduce((sum, ex) => sum + (ex.sets?.length || 0), 0);
  const avgRestTime = exercises.reduce((sum, ex) => sum + (parseInt(ex.rest) || 60), 0) / exercises.length;
  return Math.round((totalSets * 45 + totalSets * avgRestTime) / 60);
}



export const updateWorkoutProgress = async (clientId, workoutId, exerciseData) => {
  try {
    const workoutRef = doc(db, 'users', clientId, 'workouts', workoutId);
    const today = new Date().toISOString().split('T')[0];
    
    // Update workout status
    await updateDoc(workoutRef, {
      status: exerciseData.every(ex => ex.completed) ? 'completed' : 'in_progress',
      dateStarted: exerciseData.some(ex => ex.sets.some(set => set.completed)) ? Timestamp.now() : null,
      dateCompleted: exerciseData.every(ex => ex.completed) ? Timestamp.now() : null,
      exercises: exerciseData.map(ex => ({
        exerciseId: ex.exerciseId,
        completed: ex.completed,
        sets: ex.sets.map(set => ({
          setNumber: set.setNumber,
          reps: set.reps !== '' ? Number(set.reps) : null,
          weight: set.weight !== '' ? Number(set.weight) : null,
          actualReps: set.actualReps !== '' ? Number(set.actualReps) : null,
          actualWeight: set.actualWeight !== '' ? Number(set.actualWeight) : null,
          completed: !!set.completed,
          rpe: set.rpe || null
        }))
      }))
    });

    // Update exercise history
    for (const exercise of exerciseData) {
      if (!exercise.sets?.some(set => set.completed)) continue;

      const exerciseHistoryRef = doc(
        db, 
        'users', 
        clientId, 
        'exerciseHistory', 
        exercise.exerciseId, 
        'dates', 
        today
      );

      await setDoc(exerciseHistoryRef, {
        workoutId: workoutId,
        sets: exercise.sets
          .filter(set => set.completed)
          .map(set => ({
            setNumber: set.setNumber,
            weight: set.actualWeight || 0,
            reps: set.actualReps || 0,
            rpe: set.rpe || null,
            timestamp: Timestamp.now()
          })),
        notes: exercise.notes || '',
        completed: exercise.completed
      });
    }
  } catch (error) {
    console.error('Error updating workout progress:', error);
    throw error;
  }
};
