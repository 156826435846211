// src/styles/theme.js
const theme = {
    colors: {
      background: {
        primary: '#F5F5F0',    // Off-white for main backgrounds
        secondary: '#FFFFFF',   // Pure white for cards and elevated surfaces
        tertiary: '#F8F8F5'    // Slightly darker off-white for hover states
      },
      text: {
        primary: '#2C2C2C',    // Dark grey for main text
        secondary: '#4A3636',   // Deep brown for secondary text
        light: '#6E6E6E'       // Light grey for supporting text
      },
      accent: {
        primary: '#C4845F',    // Terracotta for CTAs
        secondary: '#9F8C74',  // Taupe for secondary actions
        tertiary: '#3B4664'    // Navy for links
      },
      hover: {
        primary: '#4A3636',    // Deep brown for hover states
        secondary: '#E5E5E0'   // Light grey for secondary hovers
      },
      border: {
        light: '#E5E5E0',      // Light border
        medium: '#D1D1D1'      // Medium border
      }
    },
    typography: {
      fontFamily: {
        headers: 'Satoshi, sans-serif',
        body: '"Plus Jakarta Sans", sans-serif'
      },
      fontSize: {
        hero: '2.5rem',        // 40px
        h1: '2rem',           // 32px
        h2: '1.5rem',         // 24px
        h3: '1.125rem',       // 18px
        body: '1rem',         // 16px
        small: '0.875rem'     // 14px
      },
      lineHeight: {
        tight: '1.2',
        normal: '1.5',
        relaxed: '1.75'
      },
      fontWeight: {
        light: 300,
        regular: 400,
        medium: 500,
        bold: 700
      }
    },
    spacing: {
      xs: '0.5rem',    // 8px
      sm: '0.75rem',   // 12px
      md: '1rem',      // 16px
      lg: '1.5rem',    // 24px
      xl: '2rem',      // 32px
      xxl: '4rem'      // 64px
    },
    shadows: {
      sm: '0 1px 2px rgba(0, 0, 0, 0.05)',
      md: '0 4px 6px rgba(0, 0, 0, 0.05)',
      lg: '0 10px 15px rgba(0, 0, 0, 0.05)'
    },
    borderRadius: {
      sm: '0.375rem',  // 6px
      md: '0.5rem',    // 8px
      lg: '0.75rem',   // 12px
      full: '9999px'
    }
  };

export default theme;