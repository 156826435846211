// src/components/Login.jsx

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { User, Lock, Hexagon } from 'lucide-react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from '../firebase';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/dashboard');
    } catch (error) {
      console.error('Error logging in:', error);
      alert('Error logging in: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-background-primary flex items-center justify-center px-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md"
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="text-center mb-12"
        >
          <Hexagon className="w-16 h-16 text-accent-primary mx-auto mb-6" />
          <h1 className="text-6xl font-light text-text-primary mb-3 tracking-tight font-headers">
            Evolv<span className="text-accent-primary">.</span>
          </h1>
          <p className="text-text-light text-lg font-body font-light">Own Your Story</p>
        </motion.div>

        <form onSubmit={handleLogin} className="space-y-8">
          <div className="relative group">
            <User className="absolute top-3 left-0 text-text-light group-focus-within:text-accent-primary transition-colors" size={20} />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full pl-8 pb-3 text-lg bg-transparent border-b-2 border-text-light/20 
                       focus:border-accent-primary text-text-primary placeholder-text-light/50 
                       focus:outline-none transition-all duration-300 font-body"
              placeholder="Email"
              required
            />
          </div>
          <div className="relative group">
            <Lock className="absolute top-3 left-0 text-text-light group-focus-within:text-accent-primary transition-colors" size={20} />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full pl-8 pb-3 text-lg bg-transparent border-b-2 border-text-light/20 
                       focus:border-accent-primary text-text-primary placeholder-text-light/50 
                       focus:outline-none transition-all duration-300 font-body"
              placeholder="Password"
              required
            />
          </div>

          <motion.button
            whileHover={{ scale: 1.02, boxShadow: "0 8px 20px rgba(196, 132, 95, 0.2)" }}
            whileTap={{ scale: 0.98 }}
            type="submit"
            disabled={isLoading}
            className="w-full bg-accent-primary text-white py-3 rounded-lg 
                     hover:bg-hover-primary focus:outline-none focus:ring-2 
                     focus:ring-accent-primary focus:ring-opacity-50 
                     transition-all duration-300 transform shadow-lg 
                     text-lg font-medium font-body mt-8"
          >
            {isLoading ? 'Signing in...' : 'Sign In'}
          </motion.button>
        </form>

        <div className="mt-8 text-center">
          <a href="#" className="text-sm text-accent-tertiary hover:text-accent-primary transition-colors duration-300 font-body">
            Forgot password?
          </a>
        </div>
        <div className="mt-6 text-center">
          <p className="text-sm text-text-light font-body">
            Don't have an account?{' '}
            <Link to="/signup" className="text-accent-primary hover:text-hover-primary transition-colors duration-300 font-medium">
              Create Account
            </Link>
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default Login;
