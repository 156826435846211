import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { User, Mail, Phone, Loader, Lock, AlertCircle } from 'lucide-react';
import { Input } from '../../ui/Input';
import { Button } from '../../ui/Button';

const ContactForm = ({ 
  contactInfo, 
  onChange, 
  onSubmit, 
  error, 
  loading, 
  submitting, 
  coachData 
}) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    // Clear error when typing
    if (passwordError) setPasswordError('');
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    // Clear error when typing
    if (passwordError) setPasswordError('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Basic validation
    if (!contactInfo.name || !contactInfo.name.trim()) {
      setPasswordError("Please enter your name");
      return;
    }
    
    if (!contactInfo.email || !contactInfo.email.includes('@')) {
      setPasswordError("Please enter a valid email address");
      return;
    }
    
    // Validate passwords match
    if (password !== confirmPassword) {
      setPasswordError("Passwords don't match");
      return;
    }
    
    // Validate password length and strength
    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters");
      return;
    }
    
    // Basic password strength check
    const hasLetter = /[a-zA-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    
    if (!hasLetter || !hasNumber) {
      setPasswordError("Password must contain both letters and numbers");
      return;
    }
    
    // Call the parent onSubmit with the password
    onSubmit(e, password);
  };

  return (
    <motion.div
      key="contact-form"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5, ease: [0.22, 1, 0.36, 1] }}
      className="w-full max-w-md mx-auto px-4 md:px-0"
    >
      <h2 className="text-3xl font-headers font-medium text-text-primary mb-4 text-center">
        Create Your Account
      </h2>
      <p className="text-text-light mb-8 text-center text-lg">
        Please create an account so {coachData?.firstName || 'your coach'} can work with you.
      </p>
      
      {error && (
        <div className="p-4 mb-6 bg-red-50 text-red-600 rounded-lg border border-red-200 text-center">
          {error}
        </div>
      )}
      
      {passwordError && (
        <div className="p-4 mb-6 bg-red-50 text-red-600 rounded-lg border border-red-200 text-center flex items-center justify-center">
          <AlertCircle className="w-5 h-5 mr-2" />
          {passwordError}
        </div>
      )}
      
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-5">
          <motion.div 
            className="relative"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.1 }}
          >
            <User className="absolute top-3 left-3 text-text-light" size={18} />
            <Input
              type="text"
              name="name"
              placeholder="Full Name"
              value={contactInfo.name}
              onChange={onChange}
              required
              className="pl-10 py-3 rounded-xl"
            />
          </motion.div>
          
          <motion.div 
            className="relative"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.2 }}
          >
            <Mail className="absolute top-3 left-3 text-text-light" size={18} />
            <Input
              type="email"
              name="email"
              placeholder="Email Address"
              value={contactInfo.email}
              onChange={onChange}
              required
              className="pl-10 py-3 rounded-xl"
            />
          </motion.div>
          
          <motion.div 
            className="relative"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.3 }}
          >
            <Phone className="absolute top-3 left-3 text-text-light" size={18} />
            <Input
              type="tel"
              name="phone"
              placeholder="Phone Number (optional)"
              value={contactInfo.phone || ''}
              onChange={onChange}
              className="pl-10 py-3 rounded-xl"
            />
          </motion.div>
          
          <motion.div 
            className="relative"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.4 }}
          >
            <Lock className="absolute top-3 left-3 text-text-light" size={18} />
            <Input
              type="password"
              name="password"
              placeholder="Create Password"
              value={password}
              onChange={handlePasswordChange}
              required
              className="pl-10 py-3 rounded-xl"
            />
          </motion.div>
          
          <motion.div 
            className="relative"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.5 }}
          >
            <Lock className="absolute top-3 left-3 text-text-light" size={18} />
            <Input
              type="password"
              name="confirmPassword"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              required
              className="pl-10 py-3 rounded-xl"
            />
          </motion.div>
        </div>
        
        <motion.div 
          className="pt-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.6 }}
        >
          <Button
            type="submit"
            variant="primary"
            className="w-full py-3 rounded-xl"
            disabled={loading || submitting}
          >
            {loading || submitting ? (
              <span className="flex items-center justify-center">
                <Loader className="w-5 h-5 mr-2 animate-spin" />
                Creating Account...
              </span>
            ) : (
              'Create Account & Continue'
            )}
          </Button>
        </motion.div>
      </form>
    </motion.div>
  );
};

export default ContactForm; 