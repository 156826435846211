// src/services/workoutService.js

import { db } from '../firebase';
import { 
  doc, 
  collection, 
  getDocs, 
  getDoc, 
  deleteDoc, 
  addDoc, 
  setDoc, 
  updateDoc,
  serverTimestamp,
  query,
  where,
  orderBy,
  limit,
  Timestamp
} from 'firebase/firestore';

import { getExerciseById } from './exerciseService';
import { getExerciseProgression } from './exerciseProgressionService';
import { getHistoryRange } from './exerciseHistoryService';
import { getAlternatives } from './exerciseAlternativesService';
import { sendWorkoutMessage } from './workoutMessageService';

const VALID_WORKOUT_STATUS = ['assigned', 'in_progress', 'completed'];
const VALID_MESSAGE_STATUS = ['pending', 'sent'];
const VALID_EXERCISE_TYPES = ['weight', 'time', 'reps_to_failure'];
const VALID_ALTERNATIVE_TYPES = ['easier', 'harder', 'bodyweight'];
const VALID_SECTION_TYPES = ['warmup', 'block', 'cooldown'];
const VALID_EXECUTION_STYLES = ['sequential', 'circuit'];
const VALID_PROGRESSION_TYPES = ['percentage', 'fixed'];

/**
 * Validate previous week performance data
 */
const validatePreviousPerformance = (performance) => {
    if (!performance.workoutId) {
        throw new Error('Previous workout ID is required');
    }
    if (!performance.date) {
        throw new Error('Previous workout date is required');
    }
    if (!Array.isArray(performance.sets) || performance.sets.length === 0) {
        throw new Error('Previous performance must have at least one set');
    }

    // Validate each set
    performance.sets.forEach(set => {
        if (typeof set.targetValue !== 'number' || set.targetValue < 0) {
            throw new Error('Target value must be a non-negative number');
        }
        if (typeof set.actualValue !== 'number' || set.actualValue < 0) {
            throw new Error('Actual value must be a non-negative number');
        }
        if (set.reps) {
            if (typeof set.reps === 'number') {
                if (set.reps < 1) {
                    throw new Error('Reps must be positive');
                }
            } else {
                if (set.reps.min < 1) {
                    throw new Error('Minimum reps must be positive');
                }
                if (set.reps.max < set.reps.min) {
                    throw new Error('Maximum reps must be greater than or equal to minimum reps');
                }
            }
        }
        if (set.actualReps !== undefined && (typeof set.actualReps !== 'number' || set.actualReps < 0)) {
            throw new Error('Actual reps must be a non-negative number');
        }
        if (set.rpe !== undefined && (typeof set.rpe !== 'number' || set.rpe < 1 || set.rpe > 10)) {
            throw new Error('RPE must be between 1 and 10');
        }
    });

    return true;
};

/**
 * Get previous week's performance for an exercise
 */
const getPreviousWeekPerformance = async (userId, exerciseId, workoutId, weekNumber) => {
    if (weekNumber <= 1) return null;

    try {
        // Get previous week's workout
        const prevWorkoutSnap = await db.collection('users').doc(userId)
            .collection('workouts')
            .where('week', '==', weekNumber - 1)
            .where('exercises', 'array-contains', { exerciseId })
            .orderBy('scheduledDate', 'desc')
            .limit(1)
            .get();

        if (prevWorkoutSnap.empty) return null;

        const prevWorkout = prevWorkoutSnap.docs[0].data();
        const exercise = prevWorkout.exercises.find(ex => ex.exerciseId === exerciseId);
        
        if (!exercise) return null;

        const performance = {
            workoutId: prevWorkout.id,
            date: prevWorkout.scheduledDate,
            sets: exercise.sets.map(set => ({
                targetValue: set.targetValue,
                actualValue: set.actualValue || 0,
                reps: set.reps,
                actualReps: set.actualReps,
                rpe: set.actualRPE
            }))
        };

        validatePreviousPerformance(performance);
        return performance;

    } catch (error) {
        console.error('Error getting previous performance:', error);
        return null;
    }
};

/**
 * Validate exercise type
 */
const validateExerciseType = (type) => {
  if (!VALID_EXERCISE_TYPES.includes(type)) {
    throw new Error(`Invalid exercise type. Must be one of: ${VALID_EXERCISE_TYPES.join(', ')}`);
  }
  return true;
};

/**
 * Validate section data
 */
const validateSection = (section) => {
  if (!section.id) throw new Error('Section id is required');
  if (!section.name) throw new Error('Section name is required');
  if (!VALID_SECTION_TYPES.includes(section.type)) {
    throw new Error(`Section type must be one of: ${VALID_SECTION_TYPES.join(', ')}`);
  }
  if (typeof section.order !== 'number') throw new Error('Section order must be a number');
  if (!VALID_EXECUTION_STYLES.includes(section.executionStyle)) {
    throw new Error(`Execution style must be one of: ${VALID_EXECUTION_STYLES.join(', ')}`);
  }
  if (section.executionStyle === 'circuit' && (!section.circuitRounds || section.circuitRounds < 1)) {
    throw new Error('Circuit rounds must be a positive number for circuit-style sections');
  }
  return true;
};

/**
 * Validate progression rule
 */
const validateProgressionRule = (rule) => {
  if (!rule) return true;
  if (!VALID_PROGRESSION_TYPES.includes(rule.type)) {
    throw new Error(`Progression type must be one of: ${VALID_PROGRESSION_TYPES.join(', ')}`);
  }
  if (typeof rule.value !== 'number' || rule.value <= 0) {
    throw new Error('Progression value must be a positive number');
  }
  return true;
};

/**
 * Convert template exercise to assigned exercise
 */
const convertToAssignedExercise = async (exercise, userId, weekNumber = 1) => {
  // Validate exercise type
  validateExerciseType(exercise.type);
  
  // Get progression data
  const progression = await getExerciseProgression(userId, exercise.exerciseId);
  
  // Get previous performance
  const previousPerformance = await getPreviousWeekPerformance(
    userId,
    exercise.exerciseId,
    exercise.id,
    weekNumber
  );

  // Convert sets using progression data
  const assignedSets = exercise.sets.map(set => {
    // Ensure set type matches exercise type
    if (set.type !== exercise.type) {
      throw new Error(`Set type "${set.type}" does not match exercise type "${exercise.type}"`);
    }

    // Validate progression rule
    validateProgressionRule(set.progressionRule);

    const targetValue = progression?.recommendedNextValue || set.targetValue;
    
    // Validate reps format
    if (set.reps) {
      if (typeof set.reps === 'number') {
        if (set.reps < 1) throw new Error('Reps must be positive');
      } else if (typeof set.reps === 'object') {
        if (!set.reps.min || !set.reps.max || set.reps.min > set.reps.max) {
          throw new Error('Invalid reps range');
        }
      } else {
        throw new Error('Invalid reps format');
      }
    }

    // Validate RPE if present
    if (set.targetRPE && (set.targetRPE < 1 || set.targetRPE > 10)) {
      throw new Error('Target RPE must be between 1 and 10');
    }
    
    return {
      type: exercise.type,
      targetValue,
      actualValue: null,
      reps: set.reps,
      actualReps: null,
      targetRPE: set.targetRPE,
      actualRPE: null,
      completed: false,
      progressionRule: set.progressionRule
    };
  });

  // Get alternatives
  const alternatives = await getAlternatives(userId, exercise.exerciseId);
  
  // Validate alternative types
  alternatives.forEach(alt => {
    if (!VALID_ALTERNATIVE_TYPES.includes(alt.type)) {
      throw new Error(`Alternative type must be one of: ${VALID_ALTERNATIVE_TYPES.join(', ')}`);
    }
  });

  return {
    id: exercise.id,
    exerciseId: exercise.exerciseId,
    isCustom: exercise.isCustom,
    sectionId: exercise.sectionId,
    order: exercise.order,
    sets: assignedSets,
    notes: exercise.notes,
    rest: exercise.rest,
    selectedAlternativeId: null,
    previousWeekPerformance: previousPerformance,
    alternativeExercises: alternatives
  };
};

/**
 * Assign a workout template to a user
 */
export const assignWorkoutTemplate = async (
  templateId, 
  userId, 
  coachId, 
  scheduledDate, 
  programId = null,
  weekNumber = 1
) => {
  // Get template
  const template = await getWorkoutTemplate(coachId, templateId);
  if (!template) throw new Error('Template not found');

  // Convert template exercises to assigned exercises
  const assignedExercises = await Promise.all(
    template.exercises.map(ex => convertToAssignedExercise(ex, userId, weekNumber))
  );

  const workout = {
    id: '', // Will be set after creation
    userId,
    coachId,
    name: template.name,
    description: template.description || '',
    headerImageUrl: template.headerImageUrl || '',
    exercises: assignedExercises,
    scheduledDate: serverTimestamp(),
    messageDeliveryDate: serverTimestamp(),
    messageStatus: 'pending',
    status: 'assigned',
    createdAt: serverTimestamp(),
    startedAt: null,
    completedAt: null
  };

  const workoutRef = collection(db, `users/${userId}/workouts`);
  const docRef = await addDoc(workoutRef, workout);
  
  // Update with ID
  const workoutWithId = {
    ...workout,
    id: docRef.id
  };
  
  await updateDoc(docRef, { id: docRef.id });

  return workoutWithId;
};

/**
 * Get assigned workouts for a client
 */
export const getAssignedWorkouts = async (userId, options = {}) => {
  const workoutsRef = collection(db, `users/${userId}/workouts`);
  let q = query(workoutsRef);

  if (options.status) {
    if (!VALID_WORKOUT_STATUS.includes(options.status)) {
      throw new Error(`Invalid status. Must be one of: ${VALID_WORKOUT_STATUS.join(', ')}`);
    }
    q = query(q, where('status', '==', options.status));
  }

  if (options.week) {
    q = query(q, where('week', '==', options.week));
  }

  if (options.orderBy) {
    q = query(q, orderBy(options.orderBy, options.orderDirection || 'desc'));
  }

  const snap = await getDocs(q);
  return snap.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
};

/**
 * Update workout progress
 */
export const updateWorkoutProgress = async (userId, workoutId, exerciseUpdates) => {
  const workoutRef = doc(db, `users/${userId}/workouts`, workoutId);
  const workoutSnap = await getDoc(workoutRef);
  
  if (!workoutSnap.exists()) throw new Error('Workout not found');
  
  const workout = workoutSnap.data();
  const updatedExercises = workout.exercises.map(exercise => {
    const update = exerciseUpdates.find(u => u.id === exercise.id);
    if (!update) return exercise;

    // Validate set updates
    update.sets.forEach((set, index) => {
      // Validate RPE if present
      if (set.actualRPE && (set.actualRPE < 1 || set.actualRPE > 10)) {
        throw new Error(`Invalid RPE value ${set.actualRPE} for set ${index + 1}`);
      }

      // Validate actual value based on type
      if (set.actualValue) {
        if (exercise.type === 'weight' && set.actualValue < 0) {
          throw new Error(`Invalid weight value ${set.actualValue} for set ${index + 1}`);
        }
        if (exercise.type === 'time' && set.actualValue < 0) {
          throw new Error(`Invalid time value ${set.actualValue} for set ${index + 1}`);
        }
      }

      // Validate actual reps if present
      if (set.actualReps && set.actualReps < 0) {
        throw new Error(`Invalid reps value ${set.actualReps} for set ${index + 1}`);
      }
    });

    return {
      ...exercise,
      ...update,
      sets: update.sets.map((set, i) => ({
        ...exercise.sets[i],
        ...set
      }))
    };
  });

  const allCompleted = updatedExercises.every(ex => 
    ex.sets.every(set => set.completed)
  );

  const updates = {
    exercises: updatedExercises,
    status: allCompleted ? 'completed' : 'in_progress',
    startedAt: workout.startedAt || serverTimestamp(),
    completedAt: allCompleted ? serverTimestamp() : null
  };

  await updateDoc(workoutRef, updates);

  // Update exercise history
  const currentDate = new Date();
  const month = currentDate.toISOString().slice(0, 7); // YYYY-MM
  const day = currentDate.toISOString().slice(8, 10); // DD

  const historyUpdates = updatedExercises.map(async (exercise) => {
    const completedSets = exercise.sets.filter(set => set.completed);
    if (completedSets.length === 0) return;

    // Format for history
    const historyRecord = {
      month,
      records: {
        [day]: {
          workoutId,
          sets: completedSets.map(set => ({
            type: exercise.type,
            targetValue: set.targetValue,
            actualValue: set.actualValue,
            reps: set.reps,
            actualReps: set.actualReps,
            rpe: set.actualRPE,
            completed: true,
            notes: exercise.notes
          }))
        }
      }
    };

    // Update history
    await updateExerciseHistory(
      userId,
      exercise.exerciseId,
      month,
      historyRecord
    );
  });

  await Promise.all(historyUpdates);

  return {
    id: workoutId,
    ...workout,
    ...updates
  };
};

/**
 * Delete an assigned workout
 */
export const deleteWorkout = async (userId, workoutId) => {
  const workoutRef = doc(db, `users/${userId}/workouts`, workoutId);
  await deleteDoc(workoutRef);
  return true;
};

/**
 * Get a single workout
 */
export const getWorkout = async (userId, workoutId) => {
  console.log('workoutService - getWorkout called:', { userId, workoutId });
  
  const workoutRef = doc(db, `users/${userId}/workouts`, workoutId);
  const snap = await getDoc(workoutRef);
  
  if (!snap.exists()) {
    console.log('workoutService - Workout not found');
    return null;
  }
  
  const workout = {
    id: snap.id,
    ...snap.data()
  };
  
  console.log('workoutService - Retrieved workout:', workout);
  return workout;
};

export const getWorkoutTemplate = async (coachId, templateId) => {
  if (!coachId || !templateId) throw new Error('Coach ID and template ID are required');
  
  const templateRef = doc(db, `coaches/${coachId}/workoutTemplates`, templateId);
  const snap = await getDoc(templateRef);
  
  if (!snap.exists()) return null;
  
  return {
    id: snap.id,
    ...snap.data()
  };
};

export const getAssignedWorkoutsForClient = async (coachId, clientId) => {
  if (!coachId || !clientId) throw new Error('Coach ID and client ID are required');
  
  // Changed path to get workouts directly from the user's workouts collection
  const workoutsRef = collection(db, `users/${clientId}/workouts`);
  const q = query(workoutsRef, where('coachId', '==', coachId));
  const snap = await getDocs(q);
  
  return snap.docs.map(doc => {
    const data = doc.data();
    return {
      id: doc.id,
      ...data,
      scheduledTime: data.scheduledDate, // Normalize the field name
      scheduledDate: data.scheduledDate // Keep for backward compatibility
    };
  });
};

export const assignCustomWorkout = async (workoutData, clientId, scheduledDate, messageSendTime) => {
  console.log('workoutService - assignCustomWorkout called:', { 
    workoutData, 
    clientId, 
    scheduledDate, 
    messageSendTime 
  });

  try {
    // Validate exercise types and sets
    if (!Array.isArray(workoutData.exercises)) {
      throw new Error('Exercises must be an array');
    }

    const VALID_EXERCISE_TYPES = ['weight', 'time', 'reps_to_failure'];
    const VALID_SET_CATEGORIES = ['warmup', 'working', 'backoff', 'dropset'];

    // Process and validate each exercise
    const processedExercises = await Promise.all(workoutData.exercises.map(async (exercise, exerciseIndex) => {
      console.log('workoutService - Validating exercise:', {
        index: exerciseIndex,
        id: exercise.id,
        exerciseId: exercise.exerciseId,
        type: exercise.type,
        setsCount: exercise.sets?.length
      });

      // Get the exercise details to ensure we have the correct type
      const exerciseDetails = await getExerciseById(exercise.exerciseId, workoutData.coachId);
      if (!exerciseDetails) {
        throw new Error(`Exercise ${exercise.exerciseId} not found`);
      }

      // Use the type from exercise details if not specified
      const exerciseType = exercise.type || exerciseDetails.type;
      if (!exerciseType || !VALID_EXERCISE_TYPES.includes(exerciseType)) {
        throw new Error(`Invalid exercise type: ${exerciseType} for exercise at index ${exerciseIndex}`);
      }

      if (!Array.isArray(exercise.sets)) {
        throw new Error(`Exercise ${exerciseIndex} sets must be an array`);
      }

      // Process sets with initialized actual values
      const processedSets = exercise.sets.map((set, setIndex) => {
        console.log(`workoutService - Processing set ${setIndex} for exercise ${exerciseIndex}:`, {
          type: set.type,
          category: set.setCategory,
          targetValue: set.targetValue,
          reps: set.reps
        });

        // Validate set category
        if (!set.setCategory || !VALID_SET_CATEGORIES.includes(set.setCategory)) {
          throw new Error(`Invalid set category: ${set.setCategory} for set ${setIndex} in exercise ${exerciseIndex}`);
        }

        // Ensure set type matches exercise type
        if (set.type !== exerciseType) {
          throw new Error(`Set type ${set.type} does not match exercise type ${exerciseType} for set ${setIndex} in exercise ${exerciseIndex}`);
        }

        // Initialize the base set with actual values matching target values
        const processedSet = {
          id: set.id,
          type: exerciseType,
          targetValue: set.targetValue || 0,
          actualValue: set.targetValue || 0, // Initialize actual to target
          targetRPE: set.targetRPE || null,
          actualRPE: set.targetRPE || null, // Initialize actual to target
          completed: false,
          setCategory: set.setCategory
        };

        // Handle reps based on exercise type
        if (exerciseType === 'reps_to_failure') {
          processedSet.reps = 'failure';
          processedSet.actualReps = 'failure';
        } else if (exerciseType !== 'time') {
          processedSet.reps = set.reps || { min: 8, max: 12 };
          processedSet.actualReps = set.reps || { min: 8, max: 12 }; // Initialize actual to target
        }

        // Add percentage fields based on set category for weight exercises
        if (exerciseType === 'weight') {
          if (set.setCategory === 'warmup' && typeof set.warmupPercentage === 'number') {
            processedSet.warmupPercentage = set.warmupPercentage;
          }
          if (set.setCategory === 'dropset' && typeof set.dropsetPercentage === 'number') {
            processedSet.dropsetPercentage = set.dropsetPercentage;
          }
          if (set.setCategory === 'backoff' && typeof set.backoffPercentage === 'number') {
            processedSet.backoffPercentage = set.backoffPercentage;
          }
        }

        return processedSet;
      });

      return {
        ...exercise,
        type: exerciseType,
        isCustom: exerciseDetails.isCustom || false,
        sets: processedSets
      };
    }));

    // Create the workout with processed exercises
    const workoutRef = collection(db, `users/${clientId}/workouts`);
    const docRef = await addDoc(workoutRef, {
      ...workoutData,
      exercises: processedExercises,
      assignedAt: serverTimestamp(),
      status: 'assigned'
    });

    // Update the document with its own ID
    await updateDoc(docRef, {
      id: docRef.id
    });
    
    const result = {
      id: docRef.id,
      ...workoutData,
      exercises: processedExercises
    };

    // Check if workout is scheduled for today or tomorrow
    const now = new Date();
    const workoutDate = new Date(scheduledDate);
    const tomorrow = new Date(now);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const isToday = workoutDate.toDateString() === now.toDateString();
    const isTomorrow = workoutDate.toDateString() === tomorrow.toDateString();

    if (isToday || isTomorrow) {
      // Create workout preview message
      const messageRef = collection(db, `users/${clientId}/messages`);
      const workoutPreview = {
        userId: clientId,
        description: workoutData.description || '',
        exerciseCount: workoutData.exercises.length,
        scheduledTime: Timestamp.fromDate(workoutDate),
        id: docRef.id,
        name: workoutData.name,
        duration: estimateWorkoutDuration(workoutData.exercises),
        headerImageUrl: workoutData.headerImageUrl || ''
      };

      const messageContent = {
        type: 'workout',
        workoutId: docRef.id,
        preview: workoutPreview,
        clientId: clientId
      };

      await addDoc(messageRef, {
        client_id: clientId,
        coach_id: workoutData.coachId,
        content: JSON.stringify(messageContent),
        date_sent: serverTimestamp(),
        is_system_message: false,
        media_url: '',
        sender_id: workoutData.coachId,
        source: 'ios',
        type: 'workout'
      });

      // Update workout with message status
      await updateDoc(docRef, {
        messageStatus: 'sent',
        messageDeliveryDate: serverTimestamp()
      });
    }
    
    console.log('workoutService - Workout assigned successfully:', result);
    return result;
  } catch (error) {
    console.error('workoutService - Error assigning workout:', error);
    throw error;
  }
};

// Helper function to estimate workout duration based on exercises
function estimateWorkoutDuration(exercises) {
  const totalSets = exercises.reduce((sum, ex) => sum + (ex.sets?.length || 0), 0);
  const avgRestTime = exercises.reduce((sum, ex) => sum + (parseInt(ex.rest) || 60), 0) / exercises.length;
  return Math.round((totalSets * 45 + totalSets * avgRestTime) / 60);
}

export const deleteAssignedWorkout = async (coachId, clientId, workoutId) => {
  if (!coachId || !clientId || !workoutId) {
    throw new Error('Coach ID, client ID and workout ID are required');
  }
  
  try {
    const workoutRef = doc(db, `users/${clientId}/workouts`, workoutId);
    await deleteDoc(workoutRef);
    return true;
  } catch (error) {
    console.error('Error deleting workout:', error);
    throw new Error('Failed to delete workout: ' + error.message);
  }
};

export const updateAssignedWorkout = async (coachId, clientId, workoutId, workoutData) => {
  console.log('workoutService - updateAssignedWorkout called with:', {
    coachId,
    clientId,
    workoutId,
    workoutDataFields: Object.keys(workoutData),
    exercises: workoutData.exercises?.length
  });

  try {
    if (!clientId) {
      throw new Error('Client ID is required for updating workout');
    }

    if (!workoutId) {
      throw new Error('Workout ID is required for updating workout');
    }

    // Validate exercise types and sets
    if (!Array.isArray(workoutData.exercises)) {
      throw new Error('Exercises must be an array');
    }

    workoutData.exercises.forEach((exercise, index) => {
      console.log(`workoutService - Validating exercise ${index}:`, {
        id: exercise.id,
        exerciseId: exercise.exerciseId,
        type: exercise.type,
        setsCount: exercise.sets?.length
      });
      
      if (!VALID_EXERCISE_TYPES.includes(exercise.type)) {
        throw new Error(`Invalid exercise type: ${exercise.type}`);
      }

      if (!Array.isArray(exercise.sets)) {
        throw new Error(`Exercise ${index} sets must be an array`);
      }

      exercise.sets.forEach((set, setIndex) => {
        console.log(`workoutService - Validating set ${setIndex} for exercise ${index}:`, {
          type: set.type,
          targetValue: set.targetValue,
          reps: set.reps
        });
        
        if (exercise.type === 'time' && (set.reps || set.actualReps)) {
          console.warn('workoutService - Time-based exercise should not have reps:', set);
        }
        
        if (exercise.type === 'reps_to_failure' && 
            (typeof set.reps !== 'object' || typeof set.actualReps !== 'object')) {
          console.warn('workoutService - Reps to failure exercise has invalid reps format:', set);
        }
      });
    });

    // Get the existing workout to preserve critical fields
    const workoutRef = doc(db, `users/${clientId}/workouts`, workoutId);
    const existingWorkoutSnap = await getDoc(workoutRef);
    
    if (!existingWorkoutSnap.exists()) {
      throw new Error('Workout not found');
    }

    const existingWorkout = existingWorkoutSnap.data();
    console.log('workoutService - Existing workout:', {
      id: existingWorkout.id,
      name: existingWorkout.name,
      fields: Object.keys(existingWorkout)
    });

    // Only update the fields that should change, ensuring no undefined values
    const updates = {
      name: workoutData.name || existingWorkout.name,
      description: workoutData.description || existingWorkout.description || '',
      workoutStructure: workoutData.workoutStructure || existingWorkout.workoutStructure || [],
      exercises: workoutData.exercises.map(exercise => ({
        id: exercise.id,
        exerciseId: exercise.exerciseId,
        workoutStructureId: exercise.workoutStructureId,
        isCustom: exercise.isCustom || false,
        order: exercise.order || 0,
        type: exercise.type,
        sets: exercise.sets.map(set => ({
          id: set.id,
          type: set.type,
          targetValue: set.targetValue || 0,
          actualValue: set.actualValue || 0,
          reps: set.reps || null,
          actualReps: set.actualReps || null,
          targetRPE: set.targetRPE || null,
          actualRPE: set.actualRPE || null,
          completed: set.completed || false,
          setCategory: set.setCategory || 'working',
          warmupPercentage: set.warmupPercentage || null
        })),
        notes: exercise.notes || '',
        rest: exercise.rest || 60,
        selectedAlternativeId: exercise.selectedAlternativeId || null,
        previousWeekPerformance: exercise.previousWeekPerformance || null
      })),
      updatedAt: serverTimestamp(),
      exerciseCount: workoutData.exercises.length
    };

    // Preserve critical fields from existing workout
    const preservedFields = [
      'id',
      'coachId',
      'userId',
      'templateId',
      'programId',
      'programWeek',
      'programDay',
      'isOverride',
      'scheduledDate',
      'messageDeliveryDate',
      'messageStatus',
      'status',
      'createdAt',
      'startedAt',
      'completedAt',
      'week',
      'duration'
    ];

    preservedFields.forEach(field => {
      if (existingWorkout[field] !== undefined) {
        updates[field] = existingWorkout[field];
      }
    });

    console.log('workoutService - Final update payload:', {
      fields: Object.keys(updates),
      exerciseCount: updates.exercises.length,
      hasUndefined: Object.values(updates).includes(undefined)
    });

    await updateDoc(workoutRef, updates);
    
    console.log('workoutService - Workout updated successfully');
    return true;
  } catch (error) {
    console.error('workoutService - Error updating workout:', error);
    throw error;
  }
};


