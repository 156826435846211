import React, { useState, useEffect } from 'react';
import Joyride from 'react-joyride';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import { updateDoc, doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import { motion } from 'framer-motion';

export const dashboardSteps = [
  {
    selector: '[data-tour="pending-1"]',
    content: 'When current or potential clients download our app "Evolv.", they will search for your name and request to join your program here.',
    styles: {
      popover: (base) => ({
        ...base,
        '--reactour-accent': '#6495ED',
        background: '#1C1C1C',
        color: 'white',
        padding: '1rem',
      }),

      
    }
  },

  {
    selector: '[data-tour="pending-2"]',
    content: 'Once you accept your first client, a Stripe Checkout page will pop up where you can start your 30-day free trial.',
    styles: {
      popover: (base) => ({
        ...base,
        '--reactour-accent': '#6495ED',
        background: '#1C1C1C',
        color: 'white',
        padding: '1rem',
      }),
      
       
       
    }
  },
  {
    selector: '[data-tour="clients-section"]',
    content: 'Here you can view and manage all your current clients.',
    styles: {
      popover: (base) => ({
        ...base,
        '--reactour-accent': '#6495ED',
        background: '#1C1C1C',
        color: 'white',
        padding: '1rem',
      }),
       
       
    }
  },
  {
    selector: '[data-tour="adjust-capacity"]',
    content: 'Click here to increase or decrease the number of clients you can manage.',
    styles: {
      popover: (base) => ({
        ...base,
        '--reactour-accent': '#6495ED',
        background: '#1C1C1C',
        color: 'white',
        padding: '1rem',
      }),
       
       
    }
  },
    {
      selector: '[data-tour="performance-card"]',
      content: "Let's explore your coaching performance metrics.",
      position: 'top',  
      styles: {
        popover: (base) => ({
          ...base,
          '--reactour-accent': '#6495ED',
          background: '#1C1C1C',
          color: 'white',
          padding: '1rem',
          marginTop: '80px'
        }),
       
       
      }
    },
    {
      selector: '[data-tour="all-time-adherence"]',
      content: 'This shows the overall adherence rate of all your clients across their entire training history.',
      styles: {
        popover: (base) => ({
          ...base,
          '--reactour-accent': '#6495ED',
          background: '#1C1C1C',
          color: 'white',
          padding: '1rem',
        }),
     
         
      }
    },
    {
      selector: '[data-tour="current-week"]',
      content: 'View how your clients are performing in the current week.',
      styles: {
        popover: (base) => ({
          ...base,
          '--reactour-accent': '#6495ED',
          background: '#1C1C1C',
          color: 'white',
          padding: '1rem',
        }),
     
         
      }
    },
    {
      selector: '[data-tour="total-sessions"]',
      content: 'Track the total number of training sessions completed by your clients.',
      styles: {
        popover: (base) => ({
          ...base,
          '--reactour-accent': '#6495ED',
          background: '#1C1C1C',
          color: 'white',
          padding: '1rem',
        }),
      
         
      }
    },
    {
      selector: '[data-tour="weekly-trends"]',
      content: 'Monitor how client adherence changes over time with this weekly trend chart.',
      styles: {
        popover: (base) => ({
          ...base,
          '--reactour-accent': '#6495ED',
          background: '#1C1C1C',
          color: 'white',
          padding: '1rem',
        }),
      
         
      }
    },
    
  ];

  

  export const calendarSteps = [
    {
      selector: '[data-tour="client-search"]',
      content: 'First, select a client to assign or edit their workouts.',
      position: 'bottom',
      styles: {
        popover: (base) => ({
          ...base,
          '--reactour-accent': '#6495ED',
          background: '#1C1C1C',
          color: 'white',
          padding: '1rem',
        }),
         
         
      }
    },
    {
      selector: '[data-tour="calendar-date"]',
      content: 'Click on any date to assign a workout for your client.',
      position: 'top',
      styles: {
        popover: (base) => ({
          ...base,
          '--reactour-accent': '#6495ED',
          background: '#1C1C1C',
          color: 'white',
          padding: '1rem',
        }),
         
         
      }
    },
    {
      selector: '[data-tour="view-templates"]',
      content: 'Click here to view and manage your workout templates.',
      position: 'left',
      styles: {
        popover: (base) => ({
          ...base,
          '--reactour-accent': '#6495ED',
          background: '#1C1C1C',
          color: 'white',
          padding: '1rem',
        }),
       
      }
    }
  ];
  export const accountSteps = [
  {
    selector: '[data-tour="nutrition-tab"]',
    content: "Choose a base diet philosophy that matches your approach. You can customize specific foods and restrictions next.",
    position: 'top',
    styles: {
      popover: (base) => ({
        ...base,
        '--reactour-accent': '#6495ED',
        background: '#1C1C1C',
        color: 'white',
        padding: '1rem',
      }),
    },
    action: () => {
      // If you have a function to switch to nutrition tab
      // switchTab('nutrition');
    }
  },
  {
    selector: '[data-tour="diet-template"]',
    content: "Select a diet template that best matches your coaching philosophy. This will be your AI's foundation for nutrition advice.",
    position: 'bottom',
    styles: {
      popover: (base) => ({
        ...base,
        '--reactour-accent': '#6495ED',
        background: '#1C1C1C',
        color: 'white',
        padding: '1rem',
      }),
    },
  
  },
  {
    selector: '[data-tour="routines-tab"]',
    content: "Add your recommended morning and evening routines. Your AI will share these with clients who ask for lifestyle advice.",
    position: 'top',
    styles: {
      popover: (base) => ({
        ...base,
        '--reactour-accent': '#6495ED',
        background: '#1C1C1C',
        color: 'white',
        padding: '1rem',
      }),
    },
   
  },
  {
    selector: '[data-tour="products-tab"]',
    content: "Add products you frequently recommend to clients. Your AI will share these exact recommendations. Common categories include supplements like Fish Oil, Magnesium, and Protein Powder.",
    position: 'top',
    styles: {
      popover: (base) => ({
        ...base,
        '--reactour-accent': '#6495ED',
        background: '#1C1C1C',
        color: 'white',
        padding: '1rem',
      }),
    },
    
  },
  {
    selector: '[data-tour="quick-add-products"]',
    content: "Quickly add your recommended products. These will be suggested to clients when relevant to their questions.",
    position: 'bottom',
    styles: {
      popover: (base) => ({
        ...base,
        '--reactour-accent': '#6495ED',
        background: '#1C1C1C',
        color: 'white',
        padding: '1rem',
      }),
    }
  },
  {
    selector: '[data-tour="messaging-tab"]',
    content: "Final step! Let's teach your AI to message exactly like you. Upload 2-3 screenshots of your coaching messages to clone your communication style.",
    position: 'top',
    styles: {
      popover: (base) => ({
        ...base,
        '--reactour-accent': '#6495ED',
        background: '#1C1C1C',
        color: 'white',
        padding: '1rem',
      }),
    },
    action: () => {
    }
  },
];

  
  export const FirstAccountTour = [
    {
      title: "Set Your Nutrition Philosophy",
      content: "Choose a base diet philosophy that matches your approach. You can customize specific foods and restrictions next. Select Keto for now, you can change this later.",
      tip: "This will help your AI understand your nutritional preferences when coaching clients."
    },
    {
      target: '[data-tour="routines-tab-first"]',
      content: "Add your recommended morning and evening routines. Your AI will share these with clients who ask for lifestyle advice. We have added a basic routine for now, you can change this later.",
      placement: 'top',
      spotlightPadding: 0,
      styles: {
        popover: (base) => ({
          ...base,
          '--reactour-accent': '#6495ED',
          background: '#1C1C1C',
          color: 'white',
          bottomPadding: '2rem',
          bottomMargin: '2rem',
        }),
        spotlight: {
          borderRadius: 5
        },
      }
    },
    {
      target: '[data-tour="products-tab-first"]',
      content: "Add products you frequently recommend to clients. Your AI will share these exact recommendations. Add 'Protein Powder' to continue, you can change this later.",
      disableBeacon: true,
      spotlightPadding: 0,
      placement: 'top',
      styles: {
        popover: (base) => ({
          ...base,
          '--reactour-accent': '#6495ED',
          background: '#1C1C1C',
          color: 'white',
        }),
      },
    },
    {
      target: '[data-tour="messaging-tab-first"]',
      content: "Final step! Let's teach your AI to message exactly like you. You can upload messages or select you way of communication so your AI can learn your communication style.",
      placement: 'top',
      styles: {
        popover: (base) => ({
          ...base,
          '--reactour-accent': '#6495ED',
          background: '#1C1C1C',
          color: 'white',
          padding: '1rem',
        }),
      }
    },
  ];
const MAIN_TOUR_STEPS = [
  {
    target: '[data-tour="dashboard"]',
    content: 'View your dashboard overview',
    disableBeacon: true,
  },
  {
    target: '[data-tour="messages"]',
    content: 'Access your messages and communications',
  },
  {
    target: '[data-tour="calendar"]',
    content: 'Manage your schedule and appointments',
  },
  {
    target: '[data-tour="account"]',
    content: 'Configure your account settings',
  }
];

export const FirstDashboardTour = [
  {
    content: "When potential clients download Evolv from the App Store, they can search for your name and send a request to join your coaching program. New requests appear here.",
    selector: '[data-tour="pending-1"]'
  },
  {
    content: "Click the checkmark to add a client to your program. For your first client, we'll set up your 30-day free trial.",
    selector: '[data-tour="pending-2"]'
  },
 
];




  