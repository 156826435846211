import React, { useState, useRef } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/Card';
import { Button } from '../ui/Button';
import { Input } from '../ui/Input';
import { Textarea } from '../ui/Textarea';
import { Label } from '../ui/Label';
import { X, Plus, Trash2, Upload } from 'lucide-react';
import { createCustomExercise } from '../../services/exerciseService';

const VideoPreview = ({ url, file }) => {
  if (file) {
    return (
      <div className="relative pt-[56.25%] w-full">
        <video
          className="absolute top-0 left-0 w-full h-full rounded-lg"
          controls
          src={URL.createObjectURL(file)}
        />
      </div>
    );
  }

  if (!url) return null;

  if (url.includes('youtube.com')) {
    // Extract video ID from any YouTube URL (regular or shorts)
    const videoId = url.includes('/embed/') 
      ? url.split('/embed/')[1]
      : url.match(/(?:shorts\/|v=)([^/?&]+)/)?.[1];

    if (!videoId) return null;

    return (
      <div className="relative pt-[56.25%] w-full">
        <iframe
          className="absolute top-0 left-0 w-full h-full rounded-lg"
          src={`https://www.youtube.com/embed/${videoId}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    );
  }

  return (
    <div className="relative pt-[56.25%] w-full">
      <video
        className="absolute top-0 left-0 w-full h-full rounded-lg"
        controls
        src={url}
      />
    </div>
  );
};

const AVAILABLE_MUSCLE_GROUPS = [
  'chest',
  'back',
  'shoulders',
  'biceps',
  'triceps',
  'forearms',
  'quadriceps',
  'hamstrings',
  'calves',
  'glutes',
  'core',
  'abdominals',
  'obliques',
  'traps',
  'lats',
  'pectorals',
  'anterior deltoid',
  'posterior deltoid',
  'lateral deltoid'
];

const CreateExerciseOverlay = ({ coachId, onSave, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    videoUrl: '',
    videoFile: null,
    instructions: [''],
    formCues: [''],
    equipment: [''],
    muscleGroups: [],
    type: 'weight',
    defaultProgressionRule: {
      type: 'percentage',
      value: 5
    },
    difficulty: 'intermediate',
    category: 'strength',
    metrics: {
      weight: true,
      reps: true,
      time: false,
      distance: false
    }
  });

  const handleArrayUpdate = (field, index, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: prev[field].map((item, i) => i === index ? value : item)
    }));
  };

  const addArrayItem = (field) => {
    setFormData(prev => ({
      ...prev,
      [field]: [...prev[field], '']
    }));
  };

  const removeArrayItem = (field, index) => {
    setFormData(prev => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index)
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData(prev => ({
        ...prev,
        videoFile: file,
        videoUrl: ''
      }));
    }
  };

  const toggleMuscleGroup = (muscleGroup) => {
    setFormData(prev => ({
      ...prev,
      muscleGroups: prev.muscleGroups.includes(muscleGroup)
        ? prev.muscleGroups.filter(m => m !== muscleGroup)
        : [...prev.muscleGroups, muscleGroup]
    }));
  };

  const saveExercise = async () => {
    // Validation
    if (!formData.name.trim()) {
      alert('Exercise name is required');
      return;
    }

    if (!formData.description.trim()) {
      alert('Description is required');
      return;
    }

    if (formData.muscleGroups.length === 0) {
      alert('At least one muscle group is required');
      return;
    }

    if (!formData.instructions[0].trim()) {
      alert('At least one instruction is required');
      return;
    }

    if (!formData.formCues[0].trim()) {
      alert('At least one form cue is required');
      return;
    }

    if (!formData.equipment[0].trim()) {
      alert('At least one piece of equipment is required');
      return;
    }

    setIsLoading(true);
    try {
      // Generate a unique ID for the new exercise
      const exerciseId = crypto.randomUUID();
      
      const exerciseData = {
        ...formData,
        originalExerciseId: exerciseId,
      };

      // Create the exercise using the service
      const savedExercise = await createCustomExercise(coachId, exerciseData);
      
      // Call onSave with the saved exercise data
      onSave(savedExercise);
      onClose();
    } catch (error) {
      console.error('Error saving custom exercise:', error);
      alert('Error saving exercise. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <Card className="w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <CardHeader className="flex flex-row items-center justify-between sticky top-0 bg-background z-10">
          <CardTitle>Create Custom Exercise</CardTitle>
          <Button variant="ghost" size="icon" onClick={onClose}>
            <X className="h-4 w-4" />
          </Button>
        </CardHeader>

        <CardContent className="space-y-6">
          {/* Basic Information */}
          <div className="space-y-4">
            <div>
              <Label>Exercise Name *</Label>
              <Input
                value={formData.name}
                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                placeholder="Exercise name"
                required
              />
            </div>

            <div>
              <Label>Description *</Label>
              <Textarea
                value={formData.description}
                onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                placeholder="Detailed exercise description"
                rows={4}
                required
              />
            </div>

            {/* Exercise Type */}
            <div>
              <Label>Exercise Type *</Label>
              <select
                value={formData.type}
                onChange={(e) => setFormData(prev => ({ ...prev, type: e.target.value }))}
                className="w-full rounded-md border border-border-light bg-background-tertiary p-2"
                required
              >
                <option value="weight">Weight</option>
                <option value="bodyweight">Bodyweight</option>
                <option value="cardio">Cardio</option>
                <option value="mobility">Mobility</option>
              </select>
            </div>

            {/* Video Upload */}
            <div className="space-y-2">
              <Label>Demonstration Video</Label>
              <div className="flex items-center space-x-2">
                <Input
                  value={formData.videoUrl}
                  onChange={(e) => setFormData(prev => ({ 
                    ...prev, 
                    videoUrl: e.target.value,
                    videoFile: null
                  }))}
                  placeholder="Video URL (YouTube or direct link)"
                  className="flex-1"
                  disabled={!!formData.videoFile}
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept="video/*"
                  className="hidden"
                />
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => fileInputRef.current?.click()}
                >
                  <Upload className="h-4 w-4" />
                </Button>
              </div>
              {formData.videoFile && (
                <p className="text-sm text-muted-foreground mt-1">
                  Selected file: {formData.videoFile.name}
                </p>
              )}
              <div className="mt-4">
                <VideoPreview url={formData.videoUrl} file={formData.videoFile} />
              </div>
            </div>

            {/* Muscle Groups */}
            <div>
              <Label>Muscle Groups * (select at least one)</Label>
              <div className="grid grid-cols-3 gap-2 mt-2">
                {AVAILABLE_MUSCLE_GROUPS.map((muscle) => (
                  <Button
                    key={muscle}
                    type="button"
                    variant={formData.muscleGroups.includes(muscle) ? 'default' : 'outline'}
                    onClick={() => toggleMuscleGroup(muscle)}
                    className={`${formData.muscleGroups.includes(muscle) ? 'bg-accent-primary' : ''} text-sm`}
                  >
                    {muscle.charAt(0).toUpperCase() + muscle.slice(1)}
                  </Button>
                ))}
              </div>
            </div>

            {/* Instructions */}
            <div>
              <Label className="flex justify-between items-center">
                Instructions *
                <Button
                  type="button"
                  variant="ghost"
                  size="sm"
                  onClick={() => addArrayItem('instructions')}
                >
                  <Plus className="h-4 w-4" />
                </Button>
              </Label>
              {formData.instructions.map((instruction, index) => (
                <div key={index} className="flex items-center space-x-2 mt-2">
                  <Input
                    value={instruction}
                    onChange={(e) => handleArrayUpdate('instructions', index, e.target.value)}
                    placeholder={`Step ${index + 1}`}
                    required={index === 0}
                  />
                  <Button
                    type="button"
                    variant="ghost"
                    size="sm"
                    onClick={() => removeArrayItem('instructions', index)}
                    disabled={index === 0 && formData.instructions.length === 1}
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
              ))}
            </div>

            {/* Form Cues */}
            <div>
              <Label className="flex justify-between items-center">
                Form Cues *
                <Button
                  type="button"
                  variant="ghost"
                  size="sm"
                  onClick={() => addArrayItem('formCues')}
                >
                  <Plus className="h-4 w-4" />
                </Button>
              </Label>
              {formData.formCues.map((cue, index) => (
                <div key={index} className="flex items-center space-x-2 mt-2">
                  <Input
                    value={cue}
                    onChange={(e) => handleArrayUpdate('formCues', index, e.target.value)}
                    placeholder="Form cue"
                    required={index === 0}
                  />
                  <Button
                    type="button"
                    variant="ghost"
                    size="sm"
                    onClick={() => removeArrayItem('formCues', index)}
                    disabled={index === 0 && formData.formCues.length === 1}
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
              ))}
            </div>

            {/* Equipment */}
            <div>
              <Label className="flex justify-between items-center">
                Required Equipment *
                <Button
                  type="button"
                  variant="ghost"
                  size="sm"
                  onClick={() => addArrayItem('equipment')}
                >
                  <Plus className="h-4 w-4" />
                </Button>
              </Label>
              {formData.equipment.map((item, index) => (
                <div key={index} className="flex items-center space-x-2 mt-2">
                  <Input
                    value={item}
                    onChange={(e) => handleArrayUpdate('equipment', index, e.target.value)}
                    placeholder="Equipment needed"
                    required={index === 0}
                  />
                  <Button
                    type="button"
                    variant="ghost"
                    size="sm"
                    onClick={() => removeArrayItem('equipment', index)}
                    disabled={index === 0 && formData.equipment.length === 1}
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
              ))}
            </div>

            {/* Default Progression Rule */}
            <div className="space-y-2">
              <Label>Default Progression Rule *</Label>
              <div className="grid grid-cols-2 gap-4">
                <select
                  value={formData.defaultProgressionRule.type}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    defaultProgressionRule: {
                      ...prev.defaultProgressionRule,
                      type: e.target.value
                    }
                  }))}
                  className="rounded-md border border-border-light bg-background-tertiary p-2"
                  required
                >
                  <option value="percentage">Percentage</option>
                  <option value="fixed">Fixed Amount</option>
                  <option value="rpe">RPE Based</option>
                </select>
                <Input
                  type="number"
                  value={formData.defaultProgressionRule.value}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    defaultProgressionRule: {
                      ...prev.defaultProgressionRule,
                      value: parseFloat(e.target.value)
                    }
                  }))}
                  placeholder="Value"
                  min="0"
                  step="0.5"
                  required
                />
              </div>
            </div>
          </div>
        </CardContent>

        <div className="sticky bottom-0 p-4 bg-background border-t">
          <Button
            onClick={saveExercise}
            className="w-full bg-accent-primary hover:bg-accent-primary/90"
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-muted-foreground border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status" />
            ) : 'Create Exercise'}
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default CreateExerciseOverlay; 