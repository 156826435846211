// AdherenceModal.jsx
import React, { useState, useEffect } from 'react';
import { Button } from './ui/Button';

const AdherenceModal = ({ onClose, onSubmit, currentAdherence }) => {
  const [selectedAdherence, setSelectedAdherence] = useState(currentAdherence);

  useEffect(() => {
    setSelectedAdherence(currentAdherence);
  }, [currentAdherence]);

  const handleSelectAdherence = (value) => {
    setSelectedAdherence(value);
    onSubmit(value);
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="bg-[#1C1C1C] rounded-lg p-6 z-10">
        <h2 className="text-xl text-[#FFFFFF] mb-4">Client improved</h2>
        <div className="flex space-x-4 mb-4">
          <Button
            onClick={() => handleSelectAdherence('yes')}
            className={`${
              selectedAdherence === 'yes' ? 'bg-[#6495ED]' : 'bg-[#333333]'
            } hover:bg-[#4F7BE8] text-white`}
          >
            Yes
          </Button>
          <Button
            onClick={() => handleSelectAdherence('no')}
            className={`${
              selectedAdherence === 'no' ? 'bg-[#FF6B6B]' : 'bg-[#333333]'
            } hover:bg-[#E85858] text-white`}
          >
            No
          </Button>
        </div>
        <div className="flex justify-end">
          <Button onClick={onClose} variant="ghost" className="text-[#FFFFFF] hover:bg-[#333333]">
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AdherenceModal;
