import React from 'react';
import { CheckCircle, Copy, Trash, ChevronRight } from 'lucide-react';
import { Button } from '../ui/Button';
import { Badge } from '../ui/Badge';
import { formatDistanceToNow } from 'date-fns';

const FormList = ({ 
  forms, 
  selectedFormId, 
  onSelectForm, 
  onDeleteForm, 
  onDuplicateForm, 
  onSetActive, 
  activeFormId 
}) => {
  // Format timestamp to readable date
  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    
    try {
      // Handle different timestamp formats
      let date;
      
      if (timestamp.toDate) {
        // Firebase Timestamp object
        date = timestamp.toDate();
      } else if (timestamp.seconds) {
        // Firebase Timestamp-like object with seconds
        date = new Date(timestamp.seconds * 1000);
      } else if (typeof timestamp === 'object' && timestamp instanceof Date) {
        // JavaScript Date object
        date = timestamp;
      } else if (typeof timestamp === 'number') {
        // Unix timestamp (milliseconds)
        date = new Date(timestamp);
      } else if (typeof timestamp === 'string') {
        // ISO string or other date string
        date = new Date(timestamp);
      } else {
        return 'Invalid date';
      }
      
      // Check if date is valid
      if (isNaN(date.getTime())) {
        return 'Invalid date';
      }
      
      return formatDistanceToNow(date, { addSuffix: true });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };

  if (forms.length === 0) {
    return (
      <div className="text-center py-8">
        <p className="text-text-light text-sm">No forms yet. Create your first form!</p>
      </div>
    );
  }

  return (
    <div className="space-y-2">
      {forms.map((form) => (
        <div 
          key={form.id}
          className={`
            p-3 rounded-lg transition-all cursor-pointer
            ${selectedFormId === form.id 
              ? 'bg-accent-primary/5 border border-accent-primary/20 shadow-sm' 
              : 'bg-white hover:bg-background-tertiary border border-transparent hover:border-border-light'}
          `}
          onClick={() => onSelectForm(form.id)}
        >
          <div className="flex justify-between items-start">
            <div className="flex-1 min-w-0">
              <div className="font-medium text-text-primary mb-1 flex items-center truncate">
                <span className="truncate">{form.title || 'Untitled Form'}</span>
                {form.id === activeFormId && (
                  <Badge className="ml-2 text-xs bg-green-100 text-green-800 border border-green-200 px-1.5 py-0.5 rounded-full">Active</Badge>
                )}
              </div>
              <div className="text-xs text-text-light">
                Updated {formatDate(form.updatedAt)}
              </div>
            </div>
            <ChevronRight className="h-4 w-4 text-text-light flex-shrink-0 ml-2" />
          </div>
          
          <div className="flex flex-wrap gap-1 mt-3 justify-end">
            {form.id !== activeFormId && (
              <Button
                variant="ghost"
                size="xs"
                className="text-xs text-accent-tertiary hover:text-accent-primary hover:bg-accent-tertiary/5"
                onClick={(e) => {
                  e.stopPropagation();
                  onSetActive(form.id);
                }}
              >
                <CheckCircle className="h-3 w-3 mr-1" />
                Set Active
              </Button>
            )}
            
            <Button
              variant="ghost"
              size="xs"
              className="text-xs text-accent-tertiary hover:text-accent-primary hover:bg-accent-tertiary/5"
              onClick={(e) => {
                e.stopPropagation();
                onDuplicateForm(form.id);
              }}
            >
              <Copy className="h-3 w-3 mr-1" />
              Duplicate
            </Button>
            
            <Button
              variant="ghost"
              size="xs"
              className="text-xs text-red-500 hover:text-red-700 hover:bg-red-50"
              onClick={(e) => {
                e.stopPropagation();
                if (window.confirm('Are you sure you want to delete this form?')) {
                  onDeleteForm(form.id);
                }
              }}
            >
              <Trash className="h-3 w-3 mr-1" />
              Delete
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FormList; 