// src/services/workoutTemplateService.js

import { db } from '../firebase';
import { 
  doc, 
  collection, 
  addDoc, 
  setDoc, 
  getDoc, 
  getDocs, 
  updateDoc, 
  writeBatch, 
  serverTimestamp,
  query,
  where,
  orderBy
} from 'firebase/firestore';

import { getExerciseById } from './exerciseService';
import { getAlternatives } from './exerciseAlternativesService';

const VALID_SECTION_TYPES = ['warmup', 'block', 'cooldown'];
const VALID_EXECUTION_STYLES = ['sequential', 'circuit'];
const VALID_EXERCISE_TYPES = ['weight', 'time', 'reps_to_failure'];
const VALID_SET_CATEGORIES = ['warmup', 'working', 'backoff', 'dropset'];
const VALID_TEMPLATE_TYPES = ['standalone', 'program_template'];

/**
 * Validate section data
 */
const validateSection = (section) => {
  if (!section.id) {
    throw new Error('Section ID is required');
  }
  if (!section.name) {
    throw new Error('Section name is required');
  }
  if (!VALID_SECTION_TYPES.includes(section.type)) {
    throw new Error('Invalid section type');
  }
  if (typeof section.order !== 'number' || section.order < 0) {
    throw new Error('Section order must be a non-negative number');
  }
  if (!VALID_EXECUTION_STYLES.includes(section.executionStyle)) {
    throw new Error('Invalid execution style');
  }
  
  // Circuit validation
  if (section.executionStyle === 'circuit') {
    if (section.circuitRounds === undefined) {
      throw new Error('Circuit rounds must be specified for circuit-style sections');
    }
    if (typeof section.circuitRounds !== 'number' || section.circuitRounds < 1) {
      throw new Error('Circuit rounds must be a positive number');
    }
  } else if (section.circuitRounds !== undefined) {
    throw new Error('Circuit rounds should only be specified for circuit-style sections');
  }
  
  return true;
};

/**
 * Validate template set data against exercise type
 */
const validateTemplateSet = (set, exerciseType) => {
  if (!set.id) {
    throw new Error('Set ID is required');
  }
  if (typeof set.setNumber !== 'number' || set.setNumber < 1) {
    throw new Error('Set number must be a positive number');
  }
  if (!VALID_EXERCISE_TYPES.includes(set.type)) {
    throw new Error(`Set type must be one of: ${VALID_EXERCISE_TYPES.join(', ')}`);
  }
  if (!VALID_SET_CATEGORIES.includes(set.setCategory)) {
    throw new Error('Invalid set category');
  }

  // Ensure set type matches exercise type
  if (exerciseType && set.type !== exerciseType) {
    throw new Error(`Set type "${set.type}" does not match exercise type "${exerciseType}"`);
  }
  
  if (typeof set.targetValue !== 'number') {
    throw new Error('Target value must be a number');
  }
  
  // Validate reps based on exercise type
  if (set.type !== 'reps_to_failure' && set.reps !== undefined) {
    if (typeof set.reps === 'number') {
      if (set.reps < 1) throw new Error('Reps must be positive');
    } else if (typeof set.reps === 'object') {
      if (!set.reps.min || !set.reps.max || set.reps.min > set.reps.max) {
        throw new Error('Invalid reps range');
      }
    } else {
      throw new Error('Invalid reps format');
    }

    // Reps validation based on type
    if (set.type === 'time' && set.reps) {
      throw new Error('Time-based exercises should not have reps');
    }
  }

  if (set.targetRPE !== undefined) {
    if (typeof set.targetRPE !== 'number' || set.targetRPE < 1 || set.targetRPE > 10) {
      throw new Error('Target RPE must be between 1 and 10');
    }
  }

  // Validate percentage fields for different set categories
  if (set.type === 'weight') {
    if (set.setCategory === 'warmup') {
      if (typeof set.warmupPercentage !== 'number' || set.warmupPercentage <= 0 || set.warmupPercentage >= 1) {
        throw new Error('Warmup percentage must be between 0 and 1');
      }
    } else if (set.setCategory === 'dropset') {
      if (typeof set.dropsetPercentage !== 'number' || set.dropsetPercentage <= 0 || set.dropsetPercentage >= 1) {
        throw new Error('Dropset percentage must be between 0 and 1');
      }
    } else if (set.setCategory === 'backoff') {
      if (typeof set.backoffPercentage !== 'number' || set.backoffPercentage <= 0 || set.backoffPercentage >= 1) {
        throw new Error('Backoff percentage must be between 0 and 1');
      }
    }
  }

  if (set.progressionRule) {
    if (!['percentage', 'fixed'].includes(set.progressionRule.type)) {
      throw new Error('Progression rule type must be either "percentage" or "fixed"');
    }
    if (typeof set.progressionRule.value !== 'number' || set.progressionRule.value <= 0) {
      throw new Error('Progression rule value must be a positive number');
    }

    // Validate progression rules based on type
    if (set.type === 'reps_to_failure' && set.progressionRule.type === 'percentage') {
      throw new Error('Reps to failure exercises should use fixed progression');
    }
  }

  return true;
};

/**
 * Validate template exercise data against exercise data
 */
const validateTemplateExercise = async (exercise, exerciseData) => {
    if (!exercise.id) {
        throw new Error('Exercise ID is required');
    }
    if (!exercise.exerciseId) {
        throw new Error('Referenced exercise ID is required');
    }
    if (!exercise.workoutStructureId) {
        throw new Error('Workout structure ID is required');
    }
    if (typeof exercise.order !== 'number' || exercise.order < 0) {
        throw new Error('Exercise order must be a non-negative number');
    }
    if (typeof exercise.rest !== 'number' || exercise.rest < 0) {
        throw new Error('Rest time must be a non-negative number');
    }
    if (!Array.isArray(exercise.sets) || exercise.sets.length === 0) {
        throw new Error('Exercise must have at least one set');
    }
    if (typeof exercise.isCustom !== 'boolean') {
        throw new Error('isCustom must be a boolean');
    }
    
    // Validate each set
    for (const set of exercise.sets) {
        await validateTemplateSet(set, exerciseData.type);
    }
    
    // Validate alternative exercises if present
    if (exercise.alternativeExercises) {
        if (!Array.isArray(exercise.alternativeExercises)) {
            throw new Error('Alternative exercises must be an array');
        }
        for (const alt of exercise.alternativeExercises) {
            if (!alt.exerciseId) {
                throw new Error('Alternative exercise ID is required');
            }
            if (!['easier', 'harder', 'bodyweight'].includes(alt.type)) {
                throw new Error('Invalid alternative exercise type');
            }
        }
    }
    
    return true;
};

/**
 * Create a workout template
 */
export const createWorkoutTemplate = async (coachId, templateData) => {
  if (!coachId) throw new Error('Coach ID is required');
  
  const {
    name,
    description,
    headerImageUrl,
    workoutStructure,
    exercises,
    programId,
    type,
    alternativeFor
  } = templateData;

  // Validate required fields
  if (!name) throw new Error('Template name is required');
  if (!workoutStructure || !Array.isArray(workoutStructure) || workoutStructure.length === 0) {
    throw new Error('Template must have at least one section in workoutStructure');
  }
  if (!exercises || !Array.isArray(exercises) || exercises.length === 0) {
    throw new Error('Template must have at least one exercise');
  }

  // Validate optional fields
  if (type && !VALID_TEMPLATE_TYPES.includes(type)) {
    throw new Error('Invalid template type');
  }

  // Validate and enrich exercises with data
  const enrichedExercises = await Promise.all(
    exercises.map(async (exercise, index) => {
      try {
        // Get exercise data first
        const exerciseData = await getExerciseById(exercise.exerciseId, coachId);
        if (!exerciseData) throw new Error(`Exercise ${exercise.exerciseId} not found`);
        
        // Validate with exercise data
        await validateTemplateExercise(exercise, exerciseData);
        
        // Get alternatives if any
        const alternatives = await getAlternatives(coachId, exercise.exerciseId);
        
        return {
          ...exercise,
          name: exerciseData.name,
          type: exerciseData.type || 'weight',
          alternativeExercises: alternatives.alternatives || []
        };
      } catch (error) {
        throw new Error(`Invalid exercise at index ${index}: ${error.message}`);
      }
    })
  );

  const template = {
    coachId,
    name,
    description: description || '',
    headerImageUrl: headerImageUrl || '',
    workoutStructure,
    exercises: enrichedExercises,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
    ...(programId && { programId }),
    ...(type && { type }),
    ...(alternativeFor && { alternativeFor })
  };

  const templateRef = collection(db, `coaches/${coachId}/workoutTemplates`);
  const docRef = await addDoc(templateRef, template);
  
  return {
    id: docRef.id,
    ...template
  };
};

/**
 * Update a workout template
 */
export const updateWorkoutTemplate = async (coachId, templateId, templateData) => {
  if (!coachId || !templateId) throw new Error('Coach ID and template ID are required');
  
  // Validate sections and exercises same as create
  if (templateData.workoutStructure) {
    templateData.workoutStructure.forEach((section, index) => {
      try {
        validateSection(section);
      } catch (error) {
        throw new Error(`Invalid section at index ${index}: ${error.message}`);
      }
    });
  }

  let enrichedExercises;
  if (templateData.exercises) {
    enrichedExercises = await Promise.all(
      templateData.exercises.map(async (exercise, index) => {
        try {
          const exerciseData = await getExerciseById(exercise.exerciseId, coachId);
          if (!exerciseData) throw new Error(`Exercise ${exercise.exerciseId} not found`);
          
          // Clean up the exercise data
          const cleanExercise = {
            id: exercise.id,
            exerciseId: exercise.exerciseId,
            workoutStructureId: exercise.workoutStructureId,
            name: exerciseData.name,
            type: exerciseData.type || 'weight',
            order: exercise.order,
            rest: exercise.rest,
            isCustom: exercise.isCustom || false,
            sets: exercise.sets.map(set => {
              const cleanSet = {
                id: set.id,
                setNumber: set.setNumber,
                type: set.type,
                targetValue: set.targetValue || 0,
                setCategory: set.setCategory,
                targetRPE: set.targetRPE || 7
              };

              // Only include reps if not reps_to_failure
              if (set.type !== 'reps_to_failure' && set.reps) {
                cleanSet.reps = {
                  min: set.reps.min,
                  max: set.reps.max
                };
              }

              // Include percentage fields based on set category
              if (set.type === 'weight') {
                if (set.setCategory === 'warmup' && typeof set.warmupPercentage === 'number') {
                  cleanSet.warmupPercentage = set.warmupPercentage;
                } else if (set.setCategory === 'dropset' && typeof set.dropsetPercentage === 'number') {
                  cleanSet.dropsetPercentage = set.dropsetPercentage;
                } else if (set.setCategory === 'backoff' && typeof set.backoffPercentage === 'number') {
                  cleanSet.backoffPercentage = set.backoffPercentage;
                }
              }

              return cleanSet;
            })
          };

          const alternatives = await getAlternatives(coachId, exercise.exerciseId);
          cleanExercise.alternativeExercises = alternatives?.alternatives || [];
          
          return cleanExercise;
        } catch (error) {
          throw new Error(`Invalid exercise at index ${index}: ${error.message}`);
        }
      })
    );
  }

  // Only include the fields we want to update
  const updates = {
    name: templateData.name,
    description: templateData.description || '',
    headerImageUrl: templateData.headerImageUrl || '',
    workoutStructure: templateData.workoutStructure.map(section => ({
      id: section.id,
      type: section.type,
      name: section.name,
      order: section.order,
      executionStyle: section.executionStyle,
      ...(section.circuitRounds && { circuitRounds: section.circuitRounds })
    })),
    exercises: enrichedExercises || templateData.exercises,
    updatedAt: serverTimestamp()
  };

  const templateRef = doc(db, `coaches/${coachId}/workoutTemplates`, templateId);
  await updateDoc(templateRef, updates);

  return {
    id: templateId,
    ...updates
  };
};

/**
 * Get a workout template
 */
export const getWorkoutTemplate = async (coachId, templateId) => {
  if (!coachId || !templateId) throw new Error('Coach ID and template ID are required');
  
  const templateRef = doc(db, `coaches/${coachId}/workoutTemplates`, templateId);
  const snap = await getDoc(templateRef);
  
  if (!snap.exists()) return null;
  
  return {
    id: snap.id,
    ...snap.data()
  };
};

/**
 * List workout templates
 */
export const listWorkoutTemplates = async (coachId, options = {}) => {
  if (!coachId) throw new Error('Coach ID is required');
  
  const templatesRef = collection(db, `coaches/${coachId}/workoutTemplates`);
  let q = query(templatesRef);
  
  if (options.orderBy) {
    q = query(q, orderBy(options.orderBy, options.orderDirection || 'asc'));
  }
  
  const snap = await getDocs(q);
  return snap.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
};

/**
 * Delete a workout template
 */
export const deleteWorkoutTemplate = async (coachId, templateId) => {
  if (!coachId || !templateId) throw new Error('Coach ID and template ID are required');
  
  const templateRef = doc(db, `coaches/${coachId}/workoutTemplates`, templateId);
  await updateDoc(templateRef, {
    deleted: true,
    deletedAt: serverTimestamp()
  });
  
  return true;
};



