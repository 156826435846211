// src/components/Signup.jsx

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { User, Lock, Mail, Hexagon } from 'lucide-react';
import { 
  createUserWithEmailAndPassword, 
  signOut,
  updateCurrentUser // <-- import updateCurrentUser
} from 'firebase/auth';
import {
  doc,
  serverTimestamp,
  writeBatch,
  increment,
  query,
  where,
  collection,
  getDocs,
  limit
} from 'firebase/firestore';
import { useNavigate, Link } from 'react-router-dom';
import { auth, db } from '../firebase';
import { API_URLS } from '../constants/api';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [referralError, setReferralError] = useState('');
  const navigate = useNavigate();

  // Calls your private Cloud Function. Requires the user to be authenticated.
  const validateReferralCode = async (code) => {
    const token = await auth.currentUser.getIdToken();
    const response = await fetch(API_URLS.validateReferralCode, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ code })
    });
    if (!response.ok) {
      throw new Error('Invalid referral code');
    }
    return response.json();
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setReferralError('');
    setIsLoading(true);

    let userCredential = null;
    try {
      // 1) Create user in Firebase Auth
      userCredential = await createUserWithEmailAndPassword(auth, email, password);

      // 2) If user entered a referral code, validate it
      if (referralCode.trim()) {
        try {
          // Ensure the just-created user is recognized as currentUser
          await updateCurrentUser(auth, userCredential.user);

          // Force-refresh the ID token so it can be used in validateReferralCode
          await userCredential.user.getIdToken(true);

          await validateReferralCode(referralCode.trim());
        } catch (error) {
          console.error('Invalid referral code:', error);
          
          setReferralError('That referral code is invalid. Please try again.');
          await userCredential.user.delete();
          await signOut(auth);
          setIsLoading(false);
          return;
        }
      }

      // 3) Proceed with Firestore logic
      const batch = writeBatch(db);
      const coachRef = doc(db, 'coaches', userCredential.user.uid);
      const messageStyleRef = doc(db, 'coaches', userCredential.user.uid, 'philosophy', 'messageStyleSettings');


      let referredBy = null;
      if (referralCode.trim()) {
        const referralsQuery = query(
          collection(db, 'referrals'),
          where('code', '==', referralCode.trim()),
          limit(1)
        );
        const referralSnapshot = await getDocs(referralsQuery);
        if (!referralSnapshot.empty) {
          referredBy = referralSnapshot.docs[0].data().createdBy;
        }
      }


      batch.set(coachRef, {
        email: userCredential.user.email,
        isCoach: true,
        isAdmin: false,
        subscriptionStatus: 'inactive',
        subscriptionQuantity: 0,
        numberOfClients: 0,
        clients: [],
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        stripeCustomerId: '',
        hasHadTrial: false,
        referralCodeUsed: referralCode.trim() || null,
        referredBy: referredBy,
        hasProcessedReferralReward: false,
        firstTime: true,
        firstTimeDashboard: true,
      });

      batch.set(messageStyleRef, {
        toneStyle: ['Encouraging', 'Professional'],
        emojiUse: false,
        grammarLevel: 'Perfect Grammar',
        languageStyle: 'Motivational',
        exampleConversation: {},
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });

      if (referralCode.trim() !== '' && referredBy) {  // Only update if we found a valid referral
        const referralsQuery = query(
          collection(db, 'referrals'),
          where('code', '==', referralCode.trim()),
          limit(1)
        );
        const referralSnapshot = await getDocs(referralsQuery);
        if (!referralSnapshot.empty) {
          const referralDoc = referralSnapshot.docs[0];
          batch.update(referralDoc.ref, {
            usageCount: increment(1),
            lastUsed: serverTimestamp(),
            'metadata.lastUsedBy': userCredential.user.uid,
          });
        }
      }      

      // 4) Commit Firestore batch
      await batch.commit();

      // 5) Navigate to next page
      navigate('/onboarding/setup');
    } catch (error) {
      console.error('Error signing up:', error);
      alert('Error signing up: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-background-primary flex items-center justify-center px-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md"
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="text-center mb-12"
        >
          <Hexagon className="w-16 h-16 text-accent-primary mx-auto mb-6" />
          <h1 className="text-6xl font-light text-text-primary mb-3 tracking-tight font-headers">
            Join Evolv<span className="text-accent-primary">.</span>
          </h1>
          <p className="text-text-light text-lg font-body font-light">Begin Your Journey</p>
        </motion.div>

        {referralError && (
          <motion.div 
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            className="mb-6 p-4 rounded-lg bg-red-50 border border-red-200 text-red-600 font-body text-sm"
          >
            {referralError}
          </motion.div>
        )}

        <form onSubmit={handleSignup} className="space-y-8">
          <div className="relative group">
            <Mail className="absolute top-3 left-0 text-text-light group-focus-within:text-accent-primary transition-colors" size={20} />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full pl-8 pb-3 text-lg bg-transparent border-b-2 border-text-light/20 
                       focus:border-accent-primary text-text-primary placeholder-text-light/50 
                       focus:outline-none transition-all duration-300 font-body"
              placeholder="Email"
              required
            />
          </div>
          <div className="relative group">
            <Lock className="absolute top-3 left-0 text-text-light group-focus-within:text-accent-primary transition-colors" size={20} />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full pl-8 pb-3 text-lg bg-transparent border-b-2 border-text-light/20 
                       focus:border-accent-primary text-text-primary placeholder-text-light/50 
                       focus:outline-none transition-all duration-300 font-body"
              placeholder="Password"
              required
            />
          </div>
          {/* <div className="relative group">
            <User className="absolute top-3 left-0 text-text-light group-focus-within:text-accent-primary transition-colors" size={20} />
            <input
              type="text"
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
              className="w-full pl-8 pb-3 text-lg bg-transparent border-b-2 border-text-light/20 
                       focus:border-accent-primary text-text-primary placeholder-text-light/50 
                       focus:outline-none transition-all duration-300 font-body"
              placeholder="Referral Code (optional)"
            />
          </div> */}

          <motion.button
            whileHover={{ scale: 1.02, boxShadow: "0 8px 20px rgba(196, 132, 95, 0.2)" }}
            whileTap={{ scale: 0.98 }}
            type="submit"
            disabled={isLoading}
            className="w-full bg-accent-primary text-white py-3 rounded-lg 
                     hover:bg-hover-primary focus:outline-none focus:ring-2 
                     focus:ring-accent-primary focus:ring-opacity-50 
                     transition-all duration-300 transform shadow-lg 
                     text-lg font-medium font-body mt-8"
          >
            {isLoading ? (
              <span className="flex items-center justify-center">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Creating Account...
              </span>
            ) : (
              'Create Account'
            )}
          </motion.button>
        </form>

        <div className="mt-8 text-center">
          <p className="text-sm text-text-light font-body">
            Already have an account?{' '}
            <Link 
              to="/" 
              className="text-accent-primary hover:text-hover-primary transition-colors duration-300 font-medium"
            >
              Sign In
            </Link>
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default Signup;