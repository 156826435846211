import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../../ui/Card';
import { Textarea } from '../../ui/Textarea';
import { Input } from '../../ui/Input';

const ContentTab = ({ 
  heroTitle,
  heroBio,
  successStoriesTitle,
  successStoriesSubtitle,
  faqTitle,
  faqSubtitle,
  ctaTitle,
  setHeroTitle,
  setHeroBio,
  setSuccessStoriesTitle,
  setSuccessStoriesSubtitle,
  setFaqTitle,
  setFaqSubtitle,
  setCtaTitle
}) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Text Content</CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        {/* Hero Section */}
        <div className="space-y-4">
          <h3 className="text-lg font-medium text-text-primary">Hero Section</h3>
          
          <div>
            <label className="block text-text-light mb-2">
              Hero Title <span className="text-accent-primary">*</span>
            </label>
            <Input 
              value={heroTitle}
              onChange={(e) => setHeroTitle(e.target.value)}
              placeholder="Transform your body. Elevate your life."
              maxLength={70}
              required
            />
            <p className="text-xs text-accent-primary/80 mt-1">
              {heroTitle ? `${heroTitle.length}/70 characters` : '0/70 characters'}
            </p>
          </div>
          
          <div>
            <label className="block text-text-light mb-2">
              Bio Text <span className="text-accent-primary">*</span>
            </label>
            <Textarea 
              value={heroBio}
              onChange={(e) => setHeroBio(e.target.value)}
              placeholder="I help ambitious people achieve their fitness goals through personalized coaching and proven methods that fit your lifestyle."
              rows={4}
              maxLength={300}
              required
            />
            <p className="text-xs text-accent-primary/80 mt-1">
              {heroBio ? `${heroBio.length}/300 characters` : '0/300 characters'}
            </p>
          </div>
        </div>
        
        <hr className="border-border-light" />
        
        {/* Testimonials Section Title */}
        <div>
          <h3 className="text-lg font-medium text-text-primary mb-4">Testimonials Section</h3>
          
          <div className="space-y-4">
            <div>
              <label className="block text-text-light mb-2">Section Title</label>
              <Input 
                value={successStoriesTitle}
                onChange={(e) => setSuccessStoriesTitle(e.target.value)}
                placeholder="Success Stories"
              />
            </div>
            
            <div>
              <label className="block text-text-light mb-2">Section Subtitle</label>
              <Input 
                value={successStoriesSubtitle}
                onChange={(e) => setSuccessStoriesSubtitle(e.target.value)}
                placeholder="Join the growing community of clients who have transformed their lives"
              />
            </div>
          </div>
        </div>
        
        <hr className="border-border-light" />
        
        {/* FAQ Section Title */}
        <div>
          <h3 className="text-lg font-medium text-text-primary mb-4">FAQ Section</h3>
          
          <div className="space-y-4">
            <div>
              <label className="block text-text-light mb-2">Section Title</label>
              <Input 
                value={faqTitle}
                onChange={(e) => setFaqTitle(e.target.value)}
                placeholder="Frequently Asked Questions"
              />
            </div>
            
            <div>
              <label className="block text-text-light mb-2">Section Subtitle</label>
              <Input 
                value={faqSubtitle}
                onChange={(e) => setFaqSubtitle(e.target.value)}
                placeholder="Everything you need to know about working with me"
              />
            </div>
          </div>
        </div>
        
        <hr className="border-border-light" />
        
        {/* CTA Section */}
        <div>
          <h3 className="text-lg font-medium text-text-primary mb-4">Call to Action</h3>
          
          <div>
            <label className="block text-text-light mb-2">CTA Headline</label>
            <Input 
              value={ctaTitle}
              onChange={(e) => setCtaTitle(e.target.value)}
              placeholder="Ready to Transform Your Life?"
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ContentTab; 