import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Calendar, ArrowRight, Phone, Clock, User, ChevronLeft, CheckCircle } from 'lucide-react';
import { getDoc, doc, updateDoc, serverTimestamp, addDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { Button } from '../ui/Button';
import SimplifiedAnalyticsService from '../../services/SimplifiedAnalyticsService';

const BookingPage = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [coach, setCoach] = useState(null);
  const [coachId, setCoachId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [bookingComplete, setBookingComplete] = useState(false);
  const [userAccountCreated, setUserAccountCreated] = useState(false);
  const [userId, setUserId] = useState(null);
  // Reference for Calendly container
  const calendlyContainer = useRef(null);
  
  // Get response ID from URL query params or location state
  const queryParams = new URLSearchParams(location.search);
  const responseId = queryParams.get('response') || location.state?.responseId;
  const contactInfo = location.state?.contactInfo || { name: '', email: '', phone: '' };

  // Track booking page view
  useEffect(() => {
    if (coach && !loading && coachId) {
      SimplifiedAnalyticsService.trackFormStart({
        coachId: coachId,
        formId: coach.activeOnboardingFormId || 'default',
        metadata: { responseId: responseId }
      });
    }
  }, [coach, loading, coachId, responseId]);

  // Check if user account was created in onboarding
  useEffect(() => {
    const checkUserAccount = async () => {
      if (responseId) {
        try {
          const responseDoc = await getDoc(doc(db, 'onboardingResponses', responseId));
          if (responseDoc.exists()) {
            const responseData = responseDoc.data();
            setUserAccountCreated(responseData.userCreated || false);
            
            // Get the actual userId from the response document
            if (responseData.userId) {
              setUserId(responseData.userId);
            }
          }
        } catch (error) {
          console.error('Error checking user account creation:', error);
        }
      }
    };
    
    if (responseId && coachId) {
      checkUserAccount();
    }
  }, [responseId, coachId]);

  useEffect(() => {
    const fetchCoach = async () => {
      try {
        setLoading(true);
        
        // First try to query for coach by username field
        const coachesQuery = query(
          collection(db, 'coaches'),
          where('username', '==', username)
        );
        const coachesSnapshot = await getDocs(coachesQuery);
        
        if (!coachesSnapshot.empty) {
          // Found coach by username
          const coachDoc = coachesSnapshot.docs[0];
          setCoach(coachDoc.data());
          setCoachId(coachDoc.id);
        } else {
          // Try to get coach directly by ID (if username is actually a UID)
          const coachSnapshot = await getDoc(doc(db, 'coaches', username));
          
          if (coachSnapshot.exists()) {
            setCoach(coachSnapshot.data());
            setCoachId(username);
          } else {
            setError('Coach not found');
          }
        }
      } catch (err) {
        console.error('Error fetching coach:', err);
        setError('Error loading coach profile');
      } finally {
        setLoading(false);
      }
    };

    if (username) {
      fetchCoach();
    }
  }, [username]);

  // Set up Calendly widget when coach data is loaded
  useEffect(() => {
    if (!coach || loading) return;
    
    console.log('Setting up Calendly with contact info:', contactInfo);
    
    // Load Calendly script
    const script = document.createElement('script');
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.head.appendChild(script);
    
    // Initialize Calendly widget when script loads
    script.onload = () => {
      if (window.Calendly && coach?.calendlyLink && calendlyContainer.current) {
        // Format phone number to ensure compatibility
        const formattedPhone = contactInfo.phone ? contactInfo.phone.replace(/\D/g, '') : '';
        
        const prefillData = {
          name: contactInfo.name || '',
          email: contactInfo.email || '',
          // Don't prefill the notes section with phone number
          location: contactInfo.location || ''
        };
        
        console.log('Calendly prefill data:', prefillData);
        
        window.Calendly.initInlineWidget({
          url: coach.calendlyLink,
          parentElement: calendlyContainer.current,
          prefill: prefillData,
          utm: {
            utmSource: SimplifiedAnalyticsService._utmParams.source || document.referrer,
            utmMedium: SimplifiedAnalyticsService._utmParams.medium || 'onboarding',
            utmCampaign: SimplifiedAnalyticsService._utmParams.campaign || coach.activeOnboardingFormId
          }
        });
      }
    };
    
    // Setup event listener for Calendly events
    function isCalendlyEvent(e) {
      return e.origin === 'https://calendly.com' && e.data.event && e.data.event.indexOf('calendly.') === 0;
    }
    
    function handleCalendlyEvent(e) {
      if (isCalendlyEvent(e)) {
        console.log('Calendly event received:', e.data.event, e.data);
        
        if (e.data.event === 'calendly.event_scheduled') {
          // Track successful booking with improved data
          SimplifiedAnalyticsService.trackCallBooked({
            coachId: coachId,
            formId: coach.activeOnboardingFormId || 'default',
            responseId: responseId,
            metadata: { 
              eventType: e.data.payload?.event_type?.name || 'consultation',
              inviteeName: e.data.payload?.invitee?.name,
              inviteeEmail: e.data.payload?.invitee?.email,
              startTime: e.data.payload?.event?.start_time,
              endTime: e.data.payload?.event?.end_time 
            }
          });
          
          // Also track as call_scheduled to maintain backward compatibility
          SimplifiedAnalyticsService.trackEvent('call_scheduled', {
            coachId: coachId,
            formId: coach.activeOnboardingFormId || 'default',
            responseId: responseId
          });
          
          handleBookingComplete(e.data.payload);
        } else if (e.data.event === 'calendly.date_and_time_selected') {
          // Track date selection with formId
          SimplifiedAnalyticsService.trackEvent('date_selected', {
            coachId: coachId,
            formId: coach.activeOnboardingFormId || 'default',
            responseId: responseId,
          });
        }
      }
    }
    
    window.addEventListener('message', handleCalendlyEvent);
    
    return () => {
      // Clean up
      window.removeEventListener('message', handleCalendlyEvent);
      if (script.parentNode) {
        document.head.removeChild(script);
      }
    };
  }, [coach, loading, contactInfo]);

  const handleBookingComplete = async (payload = null) => {
    try {
      setBookingComplete(true);
      
      // Update the response with booking information
      if (responseId) {
        const responseRef = doc(db, 'onboardingResponses', responseId);
        const responseDoc = await getDoc(responseRef);
        
        if (responseDoc.exists()) {
          const responseData = responseDoc.data();
          
          const updateData = {
            bookingComplete: true,
            bookingData: payload,
            updatedAt: serverTimestamp()
          };
          
          await updateDoc(responseRef, updateData);
          
          // Get userId either from state or response document
          const clientUserId = userId || responseData.userId;
          
          // If we have a userId, update the user document directly
          if (clientUserId) {
            try {
              const userRef = doc(db, 'users', clientUserId);
              await updateDoc(userRef, {
                hasCompletedOnboarding: true,
                hasBookedCall: true,
                bookingData: {
                  date: payload?.event?.start_time || null,
                  eventType: payload?.event_type?.name || 'consultation',
                  bookingDate: serverTimestamp()
                },
                updatedAt: serverTimestamp()
              });
              
              console.log('Updated user document with booking information');
            } catch (userError) {
              console.error('Error updating user document:', userError);
            }
          }
        }
        
        // Track call booking
        SimplifiedAnalyticsService.trackCallBooked({
          coachId: coachId,
          formId: coach.activeOnboardingFormId || 'default',
          responseId: responseId,
          metadata: { 
            userAccountCreated: userAccountCreated 
          }
        });
      }
    } catch (error) {
      console.error('Error updating booking status:', error);
      
      // Track error event
      SimplifiedAnalyticsService.trackEvent('error', {
        coachId: coachId,
        formId: coach.activeOnboardingFormId || 'default',
        responseId: responseId,
        metadata: { 
          errorType: 'booking_error',
          errorMessage: error.message 
        }
      });
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-background-primary flex items-center justify-center">
        <div className="animate-pulse flex flex-col items-center">
          <div className="w-32 h-32 bg-border-light rounded-full mb-6"></div>
          <div className="w-48 h-8 bg-border-light rounded mb-4"></div>
          <div className="w-64 h-4 bg-border-light rounded"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-background-primary flex items-center justify-center p-4">
        <div className="text-center bg-background-secondary p-8 rounded-lg shadow-md max-w-md">
          <h1 className="text-2xl font-semibold text-text-primary mb-4">Something Went Wrong</h1>
          <p className="text-text-light mb-6">{error}</p>
          <Button onClick={() => navigate(-1)}>Go Back</Button>
        </div>
      </div>
    );
  }

  const coachName = coach ? `${coach.firstName} ${coach.lastName}` : 'your coach';

  return (
    <div className="min-h-screen bg-background-primary">
      {/* Header */}
      <header className="bg-background-secondary shadow-sm py-4">
        <div className="container mx-auto px-4">
          <nav className="flex items-center">
            <h1 className="text-xl font-medium text-text-primary ml-2">
              Schedule a Call
            </h1>
          </nav>
        </div>
      </header>

      <main className="container mx-auto px-4 py-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="max-w-3xl mx-auto bg-background-secondary rounded-xl shadow-md overflow-hidden"
        >
          <div className="p-8">
            <div className="text-center mb-8">
              <Calendar className="w-16 h-16 text-accent-primary mx-auto mb-4" />
              <h2 className="text-2xl font-headers font-medium text-text-primary mb-3">
                {bookingComplete 
                  ? 'Your Consultation is Scheduled!' 
                  : 'Schedule Your Free Consultation'
                }
              </h2>
              <p className="text-text-light">
                {bookingComplete
                  ? `Thank you for booking with ${coachName}. You'll receive a confirmation email with all the details.`
                  : `Choose a time to connect with ${coachName} and discuss your fitness goals.`
                }
              </p>
            </div>

            {coach?.calendlyLink ? (
              <div 
                ref={calendlyContainer}
                className="calendly-inline-widget rounded-lg overflow-hidden" 
                style={{ minWidth: '320px', height: '630px' }}
                data-auto-load="false"
              ></div>
            ) : (
              <div className="border-2 border-dashed border-border-light rounded-lg p-8 mb-8 text-center bg-background-primary">
                <p className="text-text-light mb-4">
                  This coach hasn't connected their calendar yet. Please try again later.
                </p>
                <div className="max-w-md mx-auto space-y-4">
                  <div className="bg-background-secondary border border-border-light rounded-lg p-4 flex items-center">
                    <Clock className="w-5 h-5 text-accent-primary mr-3" />
                    <div>
                      <p className="text-text-primary font-medium">30 Minute Call</p>
                      <p className="text-text-light text-sm">Initial consultation</p>
                    </div>
                  </div>
                  <div className="bg-background-secondary border border-border-light rounded-lg p-4 flex items-center">
                    <Phone className="w-5 h-5 text-accent-primary mr-3" />
                    <div>
                      <p className="text-text-primary font-medium">Phone or Video</p>
                      <p className="text-text-light text-sm">Choose your preferred method</p>
                    </div>
                  </div>
                  <div className="bg-background-secondary border border-border-light rounded-lg p-4 flex items-center">
                    <User className="w-5 h-5 text-accent-primary mr-3" />
                    <div>
                      <p className="text-text-primary font-medium">1-on-1 Session</p>
                      <p className="text-text-light text-sm">Personal attention to your needs</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!coach?.calendlyLink && (
              <div className="text-center">
                <Button 
                  variant="primary"
                  size="lg"
                  className="mb-4 px-8 flex items-center mx-auto"
                  onClick={() => navigate(-1)}
                >
                  Go Back
                </Button>
              </div>
            )}
            
            {bookingComplete && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="mt-8 bg-accent-primary/5 border-2 border-accent-primary rounded-xl p-6 text-center"
              >
                <div className="w-16 h-16 mx-auto mb-4 bg-accent-primary rounded-full flex items-center justify-center">
                  <CheckCircle className="w-8 h-8 text-white" />
                </div>
                <h3 className="font-headers font-medium text-text-primary text-2xl mb-2">Booking Confirmed!</h3>
                <p className="text-text-light mb-6 max-w-md mx-auto">
                  We've recorded your booking. You'll receive a confirmation email shortly with all the details.
                </p>
                <div className="w-24 h-1 bg-accent-primary/20 mx-auto"></div>
              </motion.div>
            )}
          </div>
        </motion.div>
      </main>
    </div>
  );
};

export default BookingPage; 