// src/components/ui/Styles.js

import theme from '../../styles/theme';

export const buttonStyles = {
  primary: 
    'bg-accent-primary text-white ' +
    'font-body font-medium ' +
    'px-6 py-3 rounded-lg ' +
    'hover:bg-hover-primary ' +
    'transition-all duration-300 ' +
    'shadow-md hover:shadow-lg',
  secondary: 
    'bg-accent-secondary ' +
    'text-white ' +
    'font-body font-medium ' +
    'px-6 py-3 rounded-lg ' +
    'hover:bg-hover-primary ' +
    'transition-all duration-300 ' +
    'shadow-md hover:shadow-lg'
};

export const cardStyles = 
  'bg-background-primary ' +
  'rounded-xl ' +
  'shadow-lg ' +
  'border-2 ' +
  'border-background-secondary ' +
  'overflow-hidden';
