import React, { useState, useEffect, useRef } from 'react';
import { Bold, Italic, Eye, Edit } from 'lucide-react';
import MarkdownTextRenderer from './MarkdownTextRenderer';

/**
 * A simple rich text editor component with basic formatting options and preview mode
 * 
 * @param {Object} props
 * @param {string} props.value - The current text value
 * @param {Function} props.onChange - Function to call when text changes
 * @param {string} props.placeholder - Placeholder text
 * @param {number} props.rows - Number of rows for the textarea
 * @param {string} props.className - Additional CSS classes
 */
const RichTextEditor = ({ 
  value, 
  onChange, 
  placeholder = 'Enter text here...', 
  rows = 4, 
  className = '' 
}) => {
  const [text, setText] = useState(value || '');
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const editorRef = useRef(null);
  
  useEffect(() => {
    setText(value || '');
  }, [value]);

  // Handle text changes and propagate to parent
  const handleChange = (e) => {
    const newValue = e.target.value;
    setText(newValue);
    onChange && onChange(newValue);
  };

  // Format selected text
  const formatText = (formatType) => {
    if (!editorRef.current) return;
    
    const editor = editorRef.current;
    const start = editor.selectionStart;
    const end = editor.selectionEnd;
    
    if (start === end) return; // No selection
    
    const selectedText = text.substring(start, end);
    console.log(`[DEBUG] Formatting text: "${selectedText}" as ${formatType}`);
    
    let newText = '';
    let cursorPosition = 0;
    
    // Check if the selected text already has this formatting
    const hasFormatting = (formatType === 'bold' && 
      selectedText.startsWith('**') && selectedText.endsWith('**')) ||
      (formatType === 'italic' && 
      selectedText.startsWith('*') && selectedText.endsWith('*') && 
      !(selectedText.startsWith('**') && selectedText.endsWith('**')));
    
    if (hasFormatting) {
      // Remove formatting
      if (formatType === 'bold') {
        const cleanText = selectedText.slice(2, -2);
        newText = text.substring(0, start) + cleanText + text.substring(end);
        cursorPosition = start + cleanText.length;
      } else if (formatType === 'italic') {
        const cleanText = selectedText.slice(1, -1);
        newText = text.substring(0, start) + cleanText + text.substring(end);
        cursorPosition = start + cleanText.length;
      }
    } else {
      // Add formatting
      switch(formatType) {
        case 'bold':
          newText = text.substring(0, start) + `**${selectedText}**` + text.substring(end);
          cursorPosition = end + 4; // 4 is the length of ** + **
          console.log(`[DEBUG] New text after bold formatting: "${newText}"`);
          break;
        case 'italic':
          newText = text.substring(0, start) + `*${selectedText}*` + text.substring(end);
          cursorPosition = end + 2; // 2 is the length of * + *
          console.log(`[DEBUG] New text after italic formatting: "${newText}"`);
          break;
        default:
          return;
      }
    }
    
    setText(newText);
    onChange && onChange(newText);
    
    // Force a rerender before setting selection
    setTimeout(() => {
      editor.focus();
      editor.setSelectionRange(start, cursorPosition);
    }, 0);
  };

  // Toggle between edit and preview modes
  const togglePreviewMode = () => {
    setIsPreviewMode(!isPreviewMode);
  };

  return (
    <div className={`rich-text-editor border border-gray-300 rounded-md overflow-hidden ${className}`}>
      <div className="flex items-center justify-between bg-gray-50 p-2 border-b border-gray-300">
        <div className="flex">
          <button 
            type="button"
            onClick={() => formatText('bold')}
            className={`p-1 hover:bg-gray-200 rounded-md mr-1 ${isPreviewMode ? 'opacity-50 cursor-not-allowed' : ''}`}
            title="Bold"
            disabled={isPreviewMode}
          >
            <Bold size={16} />
          </button>
          <button 
            type="button"
            onClick={() => formatText('italic')}
            className={`p-1 hover:bg-gray-200 rounded-md mr-1 ${isPreviewMode ? 'opacity-50 cursor-not-allowed' : ''}`}
            title="Italic"
            disabled={isPreviewMode}
          >
            <Italic size={16} />
          </button>
        </div>
        <button
          type="button"
          onClick={togglePreviewMode}
          className="p-1 hover:bg-gray-200 rounded-md flex items-center"
          title={isPreviewMode ? "Edit" : "Preview"}
        >
          {isPreviewMode ? <Edit size={16} /> : <Eye size={16} />}
          <span className="ml-1 text-xs">{isPreviewMode ? "Edit" : "Preview"}</span>
        </button>
      </div>
      
      {isPreviewMode ? (
        <div className="w-full p-3 min-h-[100px] bg-white">
          <MarkdownTextRenderer text={text} />
        </div>
      ) : (
        <textarea
          ref={editorRef}
          className="w-full p-3 outline-none focus:ring-2 focus:ring-accent-primary/20 resize-none"
          value={text}
          onChange={handleChange}
          placeholder={placeholder}
          rows={rows}
        />
      )}
      
      <div className="bg-gray-50 p-2 text-xs text-gray-500 border-t border-gray-300">
        {isPreviewMode ? (
          "Preview mode. Click Edit to make changes."
        ) : (
          "Select text and click a button to format it. Use B for bold, I for italic."
        )}
      </div>
    </div>
  );
};

export default RichTextEditor; 