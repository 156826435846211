import React, { useState, useEffect } from 'react';
import { Textarea } from '../ui/Textarea';
import { Label } from '../ui/Label';
import { Card, CardContent } from '../ui/Card';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { Info } from 'lucide-react';

const NutritionTab = ({ userId }) => {
  const [nutritionForm, setNutritionForm] = useState({
    ingredientsToAvoid: '',
    proteinSources: '',
    carbSources: '',
    fatSources: '',
    nutritionalGoals: ''
  });

  useEffect(() => {
    const loadNutritionData = async () => {
      try {
        const nutritionDoc = await getDoc(doc(db, 'coaches', userId, 'philosophy', 'nutrition'));
        if (nutritionDoc.exists()) {
          setNutritionForm(nutritionDoc.data());
        }
      } catch (error) {
        console.error('Error loading nutrition data:', error);
      }
    };
    loadNutritionData();
  }, [userId]);

  const handleNutritionChange = async (field, value) => {
    const updatedForm = { ...nutritionForm, [field]: value };
    setNutritionForm(updatedForm);
    try {
      const nutritionRef = doc(db, 'coaches', userId, 'philosophy', 'nutrition');
      await setDoc(nutritionRef, updatedForm, { merge: true });
    } catch (error) {
      console.error('Error updating nutrition data:', error);
    }
  };

  return (
    <div className="h-full overflow-y-auto">
      <div className="px-8 py-6">
        <div className="mb-8">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <h2 className="text-2xl font-bold text-white">Nutrition Philosophy</h2>
              <Info className="w-5 h-5 text-blue-400 hover:text-blue-300 cursor-pointer transition-colors" />
            </div>
          </div>
        </div>

        <div className="space-y-8 pb-8">
          <Card className="bg-neutral-900 border-neutral-800 shadow-xl rounded-xl">
            <CardContent className="p-8">
              <h3 className="text-xl font-semibold text-white mb-8">Macro Sources</h3>
              <div className="grid grid-cols-3 gap-6">
                {[
                  { label: 'Protein Sources', field: 'proteinSources', placeholder: 'Enter protein sources (e.g., grass-fed beef, chicken, eggs...)' },
                  { label: 'Carb Sources', field: 'carbSources', placeholder: 'Enter carb sources (e.g., rice, sweet potatoes, oats...)' },
                  { label: 'Fat Sources', field: 'fatSources', placeholder: 'Enter fat sources (e.g., avocados, olive oil, nuts...)' }
                ].map(({ label, field, placeholder }) => (
                  <div key={field} className="space-y-3">
                    <Label className="text-lg font-medium text-white">{label}</Label>
                    <div className="relative h-[400px] rounded-lg overflow-hidden">
                      <Textarea
                        placeholder={placeholder}
                        className="absolute inset-0 p-4 text-lg bg-neutral-800 border-neutral-700 text-white placeholder:text-neutral-400 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 resize-none overflow-y-auto scrollbar-thin scrollbar-thumb-neutral-600 scrollbar-track-neutral-800"
                        value={nutritionForm[field]}
                        onChange={(e) => handleNutritionChange(field, e.target.value)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>

          <Card className="bg-neutral-900 border-neutral-800 shadow-xl rounded-xl">
            <CardContent className="p-8">
              <h3 className="text-xl font-semibold text-white mb-8">Additional Information</h3>
              <div className="grid grid-cols-2 gap-6">
                {[
                  { label: 'Ingredients to Avoid', field: 'ingredientsToAvoid', placeholder: 'List ingredients to avoid...' },
                  { label: 'Nutritional Goals', field: 'nutritionalGoals', placeholder: 'Describe nutritional goals...' }
                ].map(({ label, field, placeholder }) => (
                  <div key={field} className="space-y-3">
                    <Label className="text-lg font-medium text-white">{label}</Label>
                    <div className="relative h-[250px] rounded-lg overflow-hidden">
                      <Textarea
                        placeholder={placeholder}
                        className="absolute inset-0 p-4 text-lg bg-neutral-800 border-neutral-700 text-white placeholder:text-neutral-400 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 resize-none overflow-y-auto scrollbar-thin scrollbar-thumb-neutral-600 scrollbar-track-neutral-800"
                        value={nutritionForm[field]}
                        onChange={(e) => handleNutritionChange(field, e.target.value)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default NutritionTab;