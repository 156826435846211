import React, { useState, useEffect, useRef } from 'react';
import { X, Send, Loader2 } from 'lucide-react';
import { collection, getDocs, getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { cn } from '../lib/utils';
import { useAuth } from '../contexts/AuthContext';

const AIChatOverlay = ({ initialMessages = [], onClose }) => {
  const { user } = useAuth();
  const [messages, setMessages] = useState(initialMessages);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [coachContext, setCoachContext] = useState(null);
  const messagesEndRef = useRef(null);
  
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const gatherCoachData = async () => {
      if (!user?.uid) return;
      
      const coachData = {
        products: [],
        nutrition: {},
        routines: {},
        daily: {}
      };

      try {
        // Get all products
        const productsRef = collection(db, 'coaches', user.uid, 'products');
        const productsSnap = await getDocs(productsRef);
        coachData.products = productsSnap.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // Get nutrition philosophy
        const nutritionRef = doc(db, 'coaches', user.uid, 'philosophy', 'nutrition');
        const nutritionSnap = await getDoc(nutritionRef);
        if (nutritionSnap.exists()) {
          coachData.nutrition = nutritionSnap.data();
        }

        // Get daily routines
        const dailyRef = doc(db, 'coaches', user.uid, 'daily', 'morning');
        const dailySnap = await getDoc(dailyRef);
        if (dailySnap.exists()) {
          coachData.daily = dailySnap.data();
        }

        // Get routines
        const routinesRef = collection(db, 'coaches', user.uid, 'routines');
        const routinesSnap = await getDocs(routinesRef);
        coachData.routines = routinesSnap.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setCoachContext(coachData);
      } catch (error) {
        console.error('Error gathering coach data:', error);
      }
    };

    gatherCoachData();
  }, [user?.uid]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim() || !coachContext) return;

    const userMessage = input.trim();
    setInput('');

    const previousMessage = messages.length > 0 ? messages[messages.length - 1].content : null;
    const lastFiveMessages = messages.slice(-5).map(msg => msg.content).join('\n');

    setMessages(prev => [...prev, { role: 'user', content: userMessage }]);
    setIsLoading(true);

    try {
      const functions = getFunctions();
      const generateDemoResponse = httpsCallable(functions, 'generateDemoResponse');
      
      const result = await generateDemoResponse({
        message: userMessage,
        coachData: coachContext,
        previousMessage: previousMessage,
        lastFiveMessages: lastFiveMessages
      });
      
      if (result.data.success && result.data.data) {
        setMessages(prev => [...prev, { 
          role: 'assistant', 
          content: result.data.data 
        }]);
      } else {
        throw new Error(result.data.message || 'No response received');
      }
    } catch (error) {
      console.error('Error getting response:', error);
      setMessages(prev => [...prev, { 
        role: 'assistant', 
        content: 'Sorry, I encountered an error. Please try again.' 
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[99999]">
      <div className="absolute inset-0 bg-black/50 backdrop-blur-sm" onClick={onClose} />
      
      <div className="relative bg-background-secondary w-full max-w-3xl mx-4 h-[80vh] rounded-xl 
                    border border-border-light shadow-lg flex flex-col overflow-hidden">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b border-border-light bg-background-primary">
          <h2 className="text-xl font-headers text-text-primary">Chat with Your AI Coach</h2>
          <button 
            onClick={onClose}
            className="p-2 rounded-lg text-text-light hover:text-text-primary 
                     hover:bg-background-tertiary transition-all duration-200"
          >
            <X className="h-5 w-5" />
          </button>
        </div>
        
        {/* Messages */}
        <div className="flex-1 overflow-y-auto p-4 space-y-4">
          {messages.map((message, index) => (
            <div 
              key={index}
              className={cn(
                "max-w-[80%] p-4 rounded-xl font-body",
                message.role === 'user' 
                  ? "ml-auto bg-accent-primary text-white" 
                  : "bg-background-primary border border-border-light text-text-primary"
              )}
            >
              {message.content}
            </div>
          ))}
          {isLoading && (
            <div className="max-w-[80%] p-4 rounded-xl bg-background-primary 
                          border border-border-light text-text-secondary flex items-center gap-2">
              <Loader2 className="h-4 w-4 animate-spin" />
              <span>Thinking...</span>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        
        {/* Input */}
        <form onSubmit={handleSubmit} className="p-4 border-t border-border-light bg-background-primary">
          <div className="flex gap-2">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Ask your AI coach a question..."
              className="flex-1 px-4 py-2 rounded-lg bg-background-primary border border-border-light 
                       text-text-primary placeholder:text-text-light focus:border-accent-primary 
                       focus:ring-1 focus:ring-accent-primary transition-all duration-200"
            />
            <button 
              type="submit"
              disabled={isLoading || !input.trim()}
              className={cn(
                "px-4 py-2 rounded-lg font-body font-medium flex items-center gap-2",
                "bg-accent-primary text-white hover:bg-hover-primary",
                "disabled:opacity-50 disabled:cursor-not-allowed",
                "transition-all duration-200"
              )}
            >
              <Send className="h-4 w-4" />
              <span>Send</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AIChatOverlay;
