import React from 'react';
import { motion } from 'framer-motion';
import { Check } from 'lucide-react';

const CheckboxGroup = ({ options, value = [], onChange }) => {
  // Ensure value is always an array
  const selectedValues = Array.isArray(value) ? value : [];

  const handleToggle = (optionValue) => {
    let newValues;
    if (selectedValues.includes(optionValue)) {
      // Remove if already selected
      newValues = selectedValues.filter(v => v !== optionValue);
    } else {
      // Add if not already selected
      newValues = [...selectedValues, optionValue];
    }
    onChange(newValues);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="w-full max-w-lg mx-auto"
    >
      <div className="space-y-3">
        {options.map((option, index) => {
          const isSelected = selectedValues.includes(option.value);
          
          return (
            <motion.div
              key={option.value}
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3, delay: index * 0.05 }}
            >
              <button
                type="button"
                onClick={() => handleToggle(option.value)}
                className={`w-full p-4 rounded-lg flex items-center 
                         border-2 transition-all duration-200
                         ${isSelected 
                          ? 'border-accent-primary bg-accent-primary bg-opacity-5' 
                          : 'border-border-light hover:border-accent-primary hover:bg-background-tertiary'}`}
              >
                <div 
                  className={`w-6 h-6 mr-4 rounded flex items-center justify-center 
                           border-2 transition-colors duration-200
                           ${isSelected 
                             ? 'bg-accent-primary border-accent-primary' 
                             : 'border-border-medium'}`}
                >
                  {isSelected && <Check size={14} className="text-white" />}
                </div>
                <span 
                  className={`text-lg transition-colors duration-200
                           ${isSelected 
                             ? 'text-text-primary font-medium' 
                             : 'text-text-light'}`}
                >
                  {option.label}
                </span>
              </button>
            </motion.div>
          );
        })}
      </div>

      {/* Selected options count */}
      {selectedValues.length > 0 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="mt-6 text-center text-sm text-accent-primary"
        >
          {selectedValues.length} option{selectedValues.length !== 1 ? 's' : ''} selected
        </motion.div>
      )}
    </motion.div>
  );
};

export default CheckboxGroup; 