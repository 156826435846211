// src/components/WorkoutTracking.jsx

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { Button } from './ui/Button';
import { Input } from './ui/Input';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { Play, CheckCircle, Video } from 'lucide-react';

const WorkoutTracking = () => {
  const { workoutId } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [workout, setWorkout] = useState(null);
  const [exerciseProgress, setExerciseProgress] = useState({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const loadWorkout = async () => {
      try {
        const workoutDoc = await getDoc(
          doc(db, 'users', user.uid, 'workouts', workoutId)
        );
        if (!workoutDoc.exists()) {
          console.error('Workout not found');
          return;
        }
    
        const workoutData = workoutDoc.data();
        
        // Initialize progress tracking for each exercise
        const initialProgress = {};
        workoutData.exercises.forEach(exercise => {
          initialProgress[exercise.exerciseId] = {
            setsCompleted: exercise.sets.map(() => ({
              weight: '',
              reps: '',
              completed: false,
              rpe: ''
            }))
          };
        });
    
        // Load today's progress if it exists
        const today = new Date().toISOString().split('T')[0];
        const exercises = await Promise.all(
          workoutData.exercises.map(async exercise => {
            const historyDoc = await getDoc(
              doc(db, 'users', user.uid, 'exerciseHistory', exercise.exerciseId, 'dates', today)
            );
            return { exerciseId: exercise.exerciseId, data: historyDoc.data() };
          })
        );
    
        exercises.forEach(({ exerciseId, data }) => {
          if (data?.sets) {
            initialProgress[exerciseId].setsCompleted = data.sets.map(set => ({
              weight: set.weight || '',
              reps: set.reps || '',
              rpe: set.rpe || '',
              completed: true
            }));
          }
        });
    
        setWorkout(workoutData);
        setExerciseProgress(initialProgress);
        setLoading(false);
      } catch (error) {
        console.error('Error loading workout:', error);
      }
    };

    if (workoutId) {
      loadWorkout();
    }
  }, [workoutId]);

  const updateSetProgress = (exerciseId, setIndex, field, value) => {
    setExerciseProgress(prev => ({
      ...prev,
      [exerciseId]: {
        ...prev[exerciseId],
        setsCompleted: prev[exerciseId].setsCompleted.map((set, i) =>
          i === setIndex ? { ...set, [field]: value } : set
        )
      }
    }));
  };

  const toggleSetCompletion = (exerciseId, setIndex) => {
    setExerciseProgress(prev => ({
      ...prev,
      [exerciseId]: {
        ...prev[exerciseId],
        setsCompleted: prev[exerciseId].setsCompleted.map((set, i) =>
          i === setIndex ? { ...set, completed: !set.completed } : set
        )
      }
    }));
  };

  const saveProgress = async () => {
    if (!workout || saving) return;
    setSaving(true);
  
    try {
      const exerciseData = Object.entries(exerciseProgress).map(([exerciseId, data]) => ({
        exerciseId,
        sets: data.setsCompleted.filter(set => set.completed).map(set => ({
          weight: parseFloat(set.weight) || 0,
          reps: parseInt(set.reps) || 0,
          rpe: parseInt(set.rpe) || 0,
        })),
        notes: '',
        completed: data.setsCompleted.every(set => set.completed)
      }));
  
      await updateWorkoutProgress(user.uid, workoutId, exerciseData);
  
    } catch (error) {
      console.error('Error saving progress:', error);
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-black">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black text-white pt-20 px-4">
      <Card className="max-w-4xl mx-auto bg-neutral-900 border-0">
        <CardHeader>
          <CardTitle className="flex items-center justify-between">
            <span>{workout.name}</span>
            <Button
              onClick={saveProgress}
              disabled={saving}
              className="bg-blue-600 hover:bg-blue-700"
            >
              {saving ? 'Saving...' : 'Save Progress'}
            </Button>
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          {workout.exercises.map((exercise, exerciseIndex) => (
            <div 
              key={exercise.exerciseId}
              className="border border-neutral-700 rounded-lg p-4"
            >
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-semibold">{exercise.name}</h3>
                {exercise.videoUrl && (
                  <Button
                    variant="ghost"
                    className="text-blue-500 hover:text-blue-400"
                    onClick={() => window.open(exercise.videoUrl, '_blank')}
                  >
                    <Video className="h-5 w-5 mr-2" />
                    Watch Form
                  </Button>
                )}
              </div>

              <div className="grid grid-cols-5 gap-2 mb-2 text-sm font-medium">
                <div>Set</div>
                <div>Weight</div>
                <div>Reps</div>
                <div>RPE</div>
                <div>Done</div>
              </div>

              {exerciseProgress[exercise.exerciseId]?.setsCompleted.map((set, setIndex) => (
                <div key={setIndex} className="grid grid-cols-5 gap-2 mb-2">
                  <div className="flex items-center">{setIndex + 1}</div>
                  <Input
                    type="number"
                    value={set.weight}
                    onChange={(e) => updateSetProgress(exercise.exerciseId, setIndex, 'weight', e.target.value)}
                    className="bg-neutral-800 border-neutral-700"
                    placeholder="lbs"
                  />
                  <Input
                    type="number"
                    value={set.reps}
                    onChange={(e) => updateSetProgress(exercise.exerciseId, setIndex, 'reps', e.target.value)}
                    className="bg-neutral-800 border-neutral-700"
                    placeholder="reps"
                  />
                  <Input
                    type="number"
                    value={set.rpe}
                    onChange={(e) => updateSetProgress(exercise.exerciseId, setIndex, 'rpe', e.target.value)}
                    className="bg-neutral-800 border-neutral-700"
                    placeholder="1-10"
                    min="1"
                    max="10"
                  />
                  <Button
                    variant="ghost"
                    onClick={() => toggleSetCompletion(exercise.exerciseId, setIndex)}
                    className={`${
                      set.completed 
                        ? 'text-green-500 hover:text-green-400' 
                        : 'text-gray-500 hover:text-white'
                    }`}
                  >
                    <CheckCircle className="h-5 w-5" />
                  </Button>
                </div>
              ))}

              <div className="text-sm text-gray-400 mt-2">
                {exercise.notes && <p>Notes: {exercise.notes}</p>}
                <p>Rest: {exercise.rest}s between sets</p>
              </div>
            </div>
          ))}
        </CardContent>
      </Card>
    </div>
  );
};

export default WorkoutTracking;