import React from 'react';
import { motion } from 'framer-motion';

const MinimalistQuestionOverlay = ({ 
  question, 
  options, 
  selectedOption, 
  onChange,
  autoAdvance = false
}) => {
  const handleSelect = (optionValue) => {
    onChange(optionValue);
    
    // If autoAdvance is enabled, dispatch a custom event
    if (autoAdvance) {
      setTimeout(() => {
        const event = new CustomEvent('questionAnswered', {
          detail: { shouldAdvance: true }
        });
        document.dispatchEvent(event);
      }, 300);
    }
  };
  
  // Animation variants for staggered children
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.07,
        delayChildren: 0.1
      }
    }
  };
  
  const item = {
    hidden: { opacity: 0, y: 15 },
    show: { opacity: 1, y: 0, transition: { duration: 0.4, ease: [0.22, 1, 0.36, 1] } }
  };
  
  return (
    <div className="w-full max-w-xl mx-auto px-4 py-4">
      {/* Question text - removed background container */}
      <h2 className="text-xl md:text-2xl font-bold text-white mb-6 text-center tracking-tight leading-tight"
          style={{ textShadow: '0 2px 4px rgba(0,0,0,0.7)' }}>
        {question}
      </h2>
      
      {/* Options with clean white background */}
      <motion.div
        variants={container}
        initial="hidden"
        animate="show"
        className="space-y-2.5"
      >
        {options.map((option) => {
          const optionValue = option.value || option;
          const optionLabel = option.label || option;
          const isSelected = selectedOption === optionValue;
          
          return (
            <motion.button
              key={optionValue}
              variants={item}
              onClick={() => handleSelect(optionValue)}
              className={`w-full py-3.5 px-5 rounded-xl text-left transition-all duration-300
                        ${isSelected 
                          ? 'bg-white border-2 border-accent-primary shadow-lg' 
                          : 'bg-white/95 hover:bg-white shadow-md'}`}
              whileHover={{ scale: 1.01 }}
              whileTap={{ scale: 0.99 }}
            >
              <div className="flex items-center justify-between">
                <span className={`text-base md:text-lg font-medium 
                                ${isSelected 
                                  ? 'text-accent-primary' 
                                  : 'text-gray-800'}`}>
                  {optionLabel}
                </span>
                
                {isSelected && (
                  <motion.div
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    className="w-6 h-6 rounded-full flex items-center justify-center bg-accent-primary"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" className="text-white">
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                  </motion.div>
                )}
              </div>
            </motion.button>
          );
        })}
      </motion.div>
    </div>
  );
};

export default MinimalistQuestionOverlay; 