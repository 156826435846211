import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { collection, doc, getDoc, getDocs, serverTimestamp, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import { 
  Send, ArrowRight, User, MessageCircle, ThumbsUp, ThumbsDown, 
  RotateCcw, ChevronLeft, ChevronRight, Sparkles, CheckCircle, XCircle, MessageSquare, Info
} from 'lucide-react';
import { Button } from '../ui/Button';
import { getFunctions, httpsCallable } from 'firebase/functions';
import FeedbackModal from './FeedbackModal';

const ActivationScreen = () => {
  const { user, updateOnboardingProgress } = useAuth();
  const navigate = useNavigate();
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [coachContext, setCoachContext] = useState(null);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [coachData, setCoachData] = useState(null);
  
  // Consolidated message state
  const [messages, setMessages] = useState([]);
  
  const [isTyping, setIsTyping] = useState(false);
  const [profile, setProfile] = useState(null);
  const [isProfileReady, setIsProfileReady] = useState(false);
  const [fadeInClass, setFadeInClass] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState(null);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [showInfoTooltip, setShowInfoTooltip] = useState(true);
  
  // Demo client message suggestions
  const demoSuggestions = [
    "I've been feeling tired after my workouts",
    "What should I eat before morning training?",
    "I'm traveling next week. Any tips?",
    "Not seeing progress. What should I change?"
  ];
  
  useEffect(() => {
    // Update onboarding progress when this component mounts
    if (user) {
      updateOnboardingProgress(6);
    }
    
    // Gather coach data for AI context
    const gatherCoachData = async () => {
      if (!user?.uid) return;
      
      const coachData = {
        products: [],
        nutrition: {},
        routines: {},
        messagingStyle: {}
      };

      try {
        // Get coach profile data first to get the first name
        const coachRef = doc(db, 'coaches', user.uid);
        const coachSnap = await getDoc(coachRef);
        if (coachSnap.exists()) {
          const coachProfile = coachSnap.data();
          setProfile(coachProfile);
        }

        // Get all products
        const productsRef = collection(db, 'coaches', user.uid, 'products');
        const productsSnap = await getDocs(productsRef);
        coachData.products = productsSnap.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // Get nutrition philosophy
        const nutritionRef = doc(db, 'coaches', user.uid, 'philosophy', 'nutrition');
        const nutritionSnap = await getDoc(nutritionRef);
        if (nutritionSnap.exists()) {
          coachData.nutrition = nutritionSnap.data();
        }

        // Get routines
        const morningRef = doc(db, 'coaches', user.uid, 'Routines', 'morningRoutine');
        const eveningRef = doc(db, 'coaches', user.uid, 'Routines', 'eveningRoutine');
        const morningSnap = await getDoc(morningRef);
        const eveningSnap = await getDoc(eveningRef);
        
        coachData.routines = {
          morning: morningSnap.exists() ? morningSnap.data() : null,
          evening: eveningSnap.exists() ? eveningSnap.data() : null
        };
        
        // Get messaging style
        const messageStyleRef = doc(db, 'coaches', user.uid, 'philosophy', 'messageStyleSettings');
        const messageStyleSnap = await getDoc(messageStyleRef);
        if (messageStyleSnap.exists()) {
          coachData.messagingStyle = messageStyleSnap.data();
        } else {
          // Setup default message style if it doesn't exist
          await setDoc(messageStyleRef, {
            emojiUse: false,  // Default to no emojis
            toneStyle: ['Professional'],
            grammarLevel: 'Perfect Grammar',
            languageStyle: 'Direct',
            updatedAt: serverTimestamp()
          });
        }

        setCoachContext(coachData);
        
        // Add welcome message with short delay for animation effect
        setTimeout(() => {
          const firstName = coachProfile?.firstName || "AI Coach";
          setMessages([
            { 
              id: Date.now().toString(),
              sender: 'ai', 
              text: `${firstName} here. What's on your mind today?`,
              timestamp: new Date()
            }
          ]);
          setIsInitialLoad(false);
        }, 800);
        
      } catch (error) {
        console.error('Error gathering coach data:', error);
      }
    };

    gatherCoachData();
  }, [user, updateOnboardingProgress]);
  
  // Auto-focus input field after initial load
  useEffect(() => {
    if (!isInitialLoad && inputRef.current && !isTyping && !isLoading) {
      // Short timeout to ensure DOM is ready
      setTimeout(() => {
        inputRef.current.focus();
      }, 500);
    }
  }, [isInitialLoad, isTyping, isLoading]);
  
  // Consolidated function for sending messages
  const handleSendMessage = async (messageText) => {
    if (!messageText.trim() || !coachContext || isTyping || isLoading) return;
    
    const userMessage = messageText.trim();
    setInput('');
    
    const newMessageObj = {
      id: Date.now().toString(),
      sender: 'user',
      text: userMessage,
      timestamp: new Date()
    };
    
    setMessages(prev => [...prev, newMessageObj]);
    setIsLoading(true);
    setIsTyping(true);
    
    try {
      const functions = getFunctions();
      const generateDemoResponse = httpsCallable(functions, 'generateDemoResponse');
      
      // Format the last five messages for the cloud function
      const lastFiveMessages = messages.slice(-5).map(msg => 
        `${msg.sender.toUpperCase()}: ${msg.text}`
      ).join('\n');
      
      const result = await generateDemoResponse({
        message: userMessage,
        coachData: coachContext,
        previousMessage: messages.length > 0 ? messages[messages.length - 1].text : null,
        lastFiveMessages: lastFiveMessages
      });
      
      if (result.data.success && result.data.data) {
        const aiResponseObj = {
          id: Date.now().toString(),
          sender: 'ai',
          text: result.data.data,
          timestamp: new Date()
        };
        
        setMessages(prev => [...prev, aiResponseObj]);
        
        // Auto-hide the tooltip after first AI response
        setShowInfoTooltip(false);
        
      } else {
        throw new Error(result.data.message || 'No response received');
      }
    } catch (error) {
      console.error('Error getting response:', error);
      setMessages(prev => [...prev, {
        id: Date.now().toString(),
        sender: 'ai',
        text: 'Sorry, I encountered an error. Please try again.',
        timestamp: new Date()
      }]);
    } finally {
      setIsLoading(false);
      setIsTyping(false);
    }
  };
  
  const handleSuggestionClick = (suggestion) => {
    if (!isTyping && !isLoading) {
      try {
        handleSendMessage(suggestion);
      } catch (error) {
        console.error('Error handling suggestion click:', error);
        setIsLoading(false);
        setIsTyping(false);
      }
    }
  };
  
  const handleContinue = () => {
    navigate('/onboarding/success');
  };
  
  const handleBack = () => {
    navigate('/onboarding/setup/messaging');
  };
  
  // Scroll to bottom of messages
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);
  
  // New function to save message feedback
  const handleFeedback = async (messageId, feedbackType) => {
    try {
      if (!user) return;
      
      // Find the message that is being rated
      const targetMessage = messages.find(msg => msg.id === messageId);
      if (!targetMessage) return;
      
      // If it's positive feedback, just record it
      if (feedbackType === 'positive') {
        await setDoc(doc(db, `coaches/${user.uid}/aiFeedback/${messageId}`), {
          messageId,
          messageContent: targetMessage.text,
          feedback: 'positive',
          timestamp: new Date()
        });
        
        // Update UI to show feedback was recorded
        setMessages(prevMessages => 
          prevMessages.map(msg => 
            msg.id === messageId 
              ? { ...msg, feedback: 'positive' } 
              : msg
          )
        );
      } 
      // If it's negative feedback, show modal to collect more information
      else if (feedbackType === 'negative') {
        setFeedbackMessage(targetMessage);
        setSelectedMessageId(messageId);
        setShowFeedbackModal(true);
      }
    } catch (error) {
      console.error("Error saving feedback:", error);
    }
  };
  
  // Handle submitting negative feedback with reason
  const handleNegativeFeedbackSubmit = async (reason, correctedMessage = null) => {
    try {
      if (!user || !selectedMessageId) return;
      
      const feedbackData = {
        messageId: selectedMessageId,
        messageContent: feedbackMessage.text,
        feedback: 'negative',
        reason,
        timestamp: new Date()
      };
      
      // Save the feedback
      await setDoc(doc(db, `coaches/${user.uid}/aiFeedback/${selectedMessageId}`), feedbackData);
      
      // If user provided a corrected version, save that too
      if (correctedMessage) {
        await setDoc(doc(db, `coaches/${user.uid}/aiCorrections/${selectedMessageId}`), {
          originalMessage: feedbackMessage.text,
          correctedMessage,
          timestamp: new Date()
        });
      }
      
      // Update UI
      setMessages(prevMessages => 
        prevMessages.map(msg => 
          msg.id === selectedMessageId 
            ? { ...msg, feedback: 'negative' } 
            : msg
        )
      );
      
      // Close modal
      setShowFeedbackModal(false);
      setFeedbackMessage(null);
      setSelectedMessageId(null);
      
    } catch (error) {
      console.error("Error saving negative feedback:", error);
    }
  };
  
  // Cancel feedback
  const handleCancelFeedback = () => {
    setShowFeedbackModal(false);
    setFeedbackMessage(null);
    setSelectedMessageId(null);
  };
  
  return (
    <div className="max-w-4xl mx-auto px-4 pt-6 pb-16">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="mb-6"
      >
        <div className="flex items-center gap-3 mb-2">
          <div className="bg-accent-primary/10 p-1.5 rounded-full">
            <Sparkles className="h-5 w-5 text-accent-primary" />
          </div>
          <h2 className="text-2xl font-bold text-text-primary font-headers">
            Test Your AI Coach
          </h2>
        </div>
        <p className="text-text-secondary font-body ml-10">
          See how your AI responds based on your training. Your feedback helps it learn and improve.
        </p>
      </motion.div>
      
      {/* Chat interface */}
      <div className="bg-background-secondary rounded-xl border border-border-light overflow-hidden flex flex-col shadow-md h-[600px]">
        {/* Chat header */}
        <div className="px-4 py-3 border-b border-border-light flex items-center justify-between bg-background-primary">
          <div className="flex items-center gap-3">
            <div className="rounded-full bg-accent-secondary p-2.5 shadow-sm">
              <MessageCircle className="w-5 h-5 text-white" />
            </div>
            <div>
              <div className="font-medium text-text-primary">
                {profile?.firstName ? `AI ${profile.firstName}` : 'Your AI Coach'}
              </div>
              <div className="text-xs text-text-light">Personalized training AI</div>
            </div>
          </div>
          
          {/* Feedback info tooltip - only shown initially */}
          {showInfoTooltip && (
            <div className="bg-accent-primary/10 text-accent-primary text-xs px-3 py-1.5 rounded-full flex items-center gap-1.5">
              <Info className="h-3.5 w-3.5" />
              <span>Your feedback trains the AI</span>
            </div>
          )}
        </div>
        
        {/* Chat messages */}
        <div className="flex-1 overflow-y-auto p-6 space-y-6 bg-gradient-to-b from-background-secondary to-background-primary">
          <AnimatePresence initial={false}>
            {messages.map((message, index) => (
              <motion.div
                key={message.id || index}
                initial={{ opacity: 0, y: 10, scale: 0.98 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                transition={{ duration: 0.3, ease: "easeOut" }}
                className={`flex items-end gap-3 ${
                  message.sender === 'user' ? 'justify-end' : ''
                }`}
              >
                {message.sender === 'ai' && (
                  <div className="rounded-full bg-accent-secondary p-2.5 shadow-sm flex-shrink-0">
                    <MessageCircle className="h-5 w-5 text-white" />
                  </div>
                )}
                
                <div 
                  className={`max-w-[75%] px-4 py-3.5 shadow-sm ${
                    message.sender === 'user' 
                      ? "bg-accent-primary text-white rounded-t-2xl rounded-bl-2xl rounded-br-md ml-auto" 
                      : "bg-background-tertiary text-text-primary rounded-t-2xl rounded-br-2xl rounded-bl-md border border-border-light/30"
                  }`}
                >
                  <div className="font-body leading-relaxed">{message.text}</div>
                  <div className={`text-xs mt-1.5 opacity-70 ${message.sender === 'user' ? 'text-white' : 'text-text-light'}`}>
                    {message.sender === 'user' ? 'You' : 'AI'} · {message.timestamp.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                  </div>
                  
                  {/* Feedback buttons - only for AI messages */}
                  {message.sender === 'ai' && !message.feedback && (
                    <div className="mt-3 pt-2 border-t border-border-light/30">
                      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2">
                        <span className="text-xs text-text-light flex items-center gap-1.5">
                          <ThumbsUp className="h-3 w-3 opacity-60" />
                          <span>Was this response helpful?</span>
                        </span>
                        <div className="flex gap-3">
                          <button 
                            onClick={() => handleFeedback(message.id, 'positive')}
                            className="text-xs py-1 px-3 bg-green-50 text-green-600 rounded-full hover:bg-green-100 transition-colors flex items-center gap-1"
                            aria-label="This response is good"
                          >
                            <CheckCircle className="h-3 w-3" />
                            <span>Good</span>
                          </button>
                          <button 
                            onClick={() => handleFeedback(message.id, 'negative')}
                            className="text-xs py-1 px-3 bg-red-50 text-red-500 rounded-full hover:bg-red-100 transition-colors flex items-center gap-1"
                            aria-label="This response needs improvement"
                          >
                            <ThumbsDown className="h-3 w-3" />
                            <span>Improve</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  
                  {/* Feedback confirmation - improve visual style */}
                  {message.sender === 'ai' && message.feedback === 'positive' && (
                    <div className="mt-2 text-xs text-green-600 flex items-center gap-1.5 bg-green-50 px-2.5 py-1.5 rounded-full">
                      <CheckCircle className="h-3 w-3" />
                      <span>Feedback recorded - AI will learn from this</span>
                    </div>
                  )}
                  
                  {message.sender === 'ai' && message.feedback === 'negative' && (
                    <div className="mt-2 text-xs text-accent-primary flex items-center gap-1.5 bg-accent-primary/10 px-2.5 py-1.5 rounded-full">
                      <RotateCcw className="h-3 w-3" />
                      <span>Thanks! Your AI will improve based on your feedback</span>
                    </div>
                  )}
                </div>
                
                {message.sender === 'user' && (
                  <div className="rounded-full bg-background-tertiary p-2.5 shadow-sm flex-shrink-0 border border-border-light/30">
                    <User className="h-5 w-5 text-text-secondary" />
                  </div>
                )}
              </motion.div>
            ))}
            
            {/* AI typing indicator */}
            {isTyping && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0 }}
                className="flex items-end gap-3"
              >
                <div className="rounded-full bg-accent-secondary p-2.5 shadow-sm flex-shrink-0">
                  <MessageCircle className="h-5 w-5 text-white" />
                </div>
                <div className="bg-background-tertiary text-text-primary px-4 py-3.5 rounded-t-2xl rounded-br-2xl rounded-bl-md shadow-sm border border-border-light/30">
                  <div className="flex items-center space-x-2">
                    <div className="w-2.5 h-2.5 bg-text-light/60 rounded-full animate-pulse" style={{ animationDelay: "0ms", animationDuration: "1s" }}></div>
                    <div className="w-2.5 h-2.5 bg-text-light/60 rounded-full animate-pulse" style={{ animationDelay: "300ms", animationDuration: "1s" }}></div>
                    <div className="w-2.5 h-2.5 bg-text-light/60 rounded-full animate-pulse" style={{ animationDelay: "600ms", animationDuration: "1s" }}></div>
                  </div>
                  <div className="text-xs mt-1.5 text-text-light opacity-70">
                    AI · Thinking...
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          <div ref={messagesEndRef} />
        </div>
        
        {/* Quick suggestions - only show when no active request and fewer than 3 messages */}
        {messages.length <= 2 && !isTyping && !isLoading && (
          <div className="px-4 py-3 border-t border-border-light bg-background-tertiary/50">
            <div className="flex items-center gap-2 mb-2">
              <MessageSquare className="h-4 w-4 text-accent-primary" />
              <p className="text-sm text-text-secondary">Try asking:</p>
            </div>
            <div className="flex flex-wrap gap-2">
              {demoSuggestions.map((suggestion, index) => (
                <button
                  key={index}
                  onClick={() => {
                    if (!isTyping && !isLoading) {
                      handleSuggestionClick(suggestion);
                    }
                  }}
                  disabled={isTyping || isLoading}
                  className="px-3 py-1.5 bg-background-primary text-text-secondary text-sm rounded-full
                           border border-border-light hover:border-accent-primary transition-colors
                           hover:bg-accent-primary/5 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {suggestion}
                </button>
              ))}
            </div>
          </div>
        )}
        
        {/* Input area */}
        <div className="p-4 border-t border-border-light bg-background-primary">
          <div className="bg-background-secondary rounded-xl p-3 shadow-sm border border-border-light/50 relative">
            {(isTyping || isLoading) && (
              <div className="absolute top-0 left-0 right-0 h-1">
                <div className="h-full bg-accent-primary/30 animate-pulse rounded-t-xl"></div>
              </div>
            )}
            <form 
              onSubmit={(e) => {
                e.preventDefault();
                if (!isTyping && !isLoading) {
                  handleSendMessage(input);
                }
              }}
              className="flex gap-2"
            >
              <input
                ref={inputRef}
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder={
                  isTyping || isLoading 
                    ? "AI is thinking..." 
                    : messages.length === 0 
                      ? "Ask something like 'What should I eat before training?'" 
                      : "Type your next question..."
                }
                className="flex-1 px-4 py-2.5 rounded-lg bg-background-primary border border-border-light/50 
                         text-text-primary placeholder:text-text-light/50 focus:border-accent-primary 
                         focus:ring-1 focus:ring-accent-primary/30 focus:outline-none transition-all"
                disabled={isTyping || isLoading}
              />
              <button 
                type="submit"
                disabled={isTyping || isLoading || !input.trim()}
                className="px-5 py-2.5 rounded-lg font-body font-medium
                        bg-accent-primary text-white hover:bg-hover-primary
                        disabled:opacity-50 disabled:cursor-not-allowed
                        transition-all duration-200 flex items-center gap-2
                        shadow-sm hover:shadow focus:ring-2 focus:ring-accent-primary/30 focus:outline-none min-w-[100px] justify-center"
              >
                {isTyping || isLoading ? (
                  <>
                    <div className="h-4 w-4 border-2 border-white/30 border-t-white rounded-full animate-spin"></div>
                    <span>Thinking</span>
                  </>
                ) : (
                  <>
                    <Send className="h-4 w-4" />
                    <span>Send</span>
                  </>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
      
      {/* Bottom note */}
      <div className="mt-4 bg-accent-primary/5 rounded-xl p-4 shadow-sm border border-accent-primary/20">
        <div className="flex flex-col sm:flex-row items-start gap-3">
          <div className="rounded-full bg-accent-primary/10 p-2 mt-0.5 flex-shrink-0">
            <Sparkles className="h-5 w-5 text-accent-primary" />
          </div>
          <div>
            <h4 className="font-medium text-accent-primary mb-1">Training your AI</h4>
            <p className="text-sm text-text-secondary">
              Your AI learns from your feedback. Thumbs up keeps what works, thumbs down lets you correct responses.
              Each interaction makes your AI more aligned with your coaching style.
            </p>
          </div>
        </div>
      </div>
      
      <div className="mt-8 flex flex-col sm:flex-row justify-between gap-4">
        <Button
          onClick={handleBack}
          className="bg-background-tertiary text-text-primary hover:bg-hover-secondary 
                   transition-all duration-200 border border-border-medium px-8 py-3 
                   text-base shadow-sm hover:shadow flex items-center justify-center gap-2 rounded-lg"
        >
          <ChevronLeft className="w-4 h-4" />
          Back
        </Button>
        
        <Button
          onClick={handleContinue}
          className="bg-accent-primary text-white hover:bg-hover-primary 
                   transition-all duration-300 shadow-md hover:shadow-lg 
                   min-w-[200px] px-8 py-3.5 text-base rounded-lg
                   flex items-center justify-center gap-2"
        >
          Complete Setup
          <ChevronRight className="w-4 h-4" />
        </Button>
      </div>
      
      {/* Feedback Modal */}
      {showFeedbackModal && (
        <FeedbackModal
          message={feedbackMessage?.text}
          onSubmit={handleNegativeFeedbackSubmit}
          onCancel={handleCancelFeedback}
        />
      )}
    </div>
  );
};

export default ActivationScreen; 