// src/components/overlays/CreateExerciseOverlay.jsx
import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/Card';
import { Button } from '../ui/Button';
import { Input } from '../ui/Input';
import { Badge } from '../ui/Badge';
import { 
  X, 
  Plus, 
  Trash, 
  Tag, 
  Dumbbell, 
  Video,
  ChevronRight,
  AlertCircle,
  ListOrdered,
  ScanLine
} from 'lucide-react';

const CreateExerciseOverlay = ({ onSave, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    mediaUrl: '',
    instructions: [''],
    commonMistakes: [''],
    equipmentNeeded: [],
    tags: []
  });

  const [newTag, setNewTag] = useState('');
  const [newEquipment, setNewEquipment] = useState('');

  const handleArrayUpdate = (field, index, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: prev[field].map((item, i) => i === index ? value : item)
    }));
  };

  const addArrayItem = (field) => {
    setFormData(prev => ({
      ...prev,
      [field]: [...prev[field], '']
    }));
  };

  const removeArrayItem = (field, index) => {
    setFormData(prev => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index)
    }));
  };

  const addTag = () => {
    if (newTag.trim()) {
      setFormData(prev => ({
        ...prev,
        tags: [...new Set([...prev.tags, newTag.trim().toLowerCase()])]
      }));
      setNewTag('');
    }
  };

  const removeTag = (tagToRemove) => {
    setFormData(prev => ({
      ...prev,
      tags: prev.tags.filter(tag => tag !== tagToRemove)
    }));
  };

  const addEquipment = () => {
    if (newEquipment.trim()) {
      setFormData(prev => ({
        ...prev,
        equipmentNeeded: [...new Set([...prev.equipmentNeeded, newEquipment.trim()])]
      }));
      setNewEquipment('');
    }
  };

  const removeEquipment = (equipmentToRemove) => {
    setFormData(prev => ({
      ...prev,
      equipmentNeeded: prev.equipmentNeeded.filter(eq => eq !== equipmentToRemove)
    }));
  };

  return (
    <div className="fixed inset-0 bg-black/90 flex items-center justify-center z-50 overflow-y-auto p-4">
      <Card className="relative bg-neutral-900 w-full max-w-4xl rounded-xl shadow-2xl">
        <Button
          onClick={onClose}
          variant="ghost"
          className="absolute top-4 right-4 text-gray-400 hover:text-white"
        >
          <X className="h-5 w-5" />
        </Button>

        <CardHeader className="border-b border-neutral-800 pb-6">
          <CardTitle className="flex items-center gap-3 text-2xl">
            <Dumbbell className="h-6 w-6 text-blue-500" />
            Create Custom Exercise
            <Badge className="bg-blue-500/20 text-blue-400 ml-auto">Custom</Badge>
          </CardTitle>
        </CardHeader>

        <CardContent className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Left Column - Basic Info */}
            <div className="space-y-6">
              <div className="space-y-4">
                <label className="text-sm font-medium text-gray-300 flex items-center gap-2">
                  <ListOrdered className="h-4 w-4 text-blue-400" />
                  Exercise Name
                </label>
                <Input
                  value={formData.name}
                  onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                  className="bg-neutral-800 border-neutral-700 focus:border-blue-500 focus:ring-blue-500"
                  placeholder="e.g., Bulgarian Split Squat"
                  required
                />
              </div>

              <div className="space-y-4">
                <label className="text-sm font-medium text-gray-300 flex items-center gap-2">
                  <Video className="h-4 w-4 text-blue-400" />
                  Video URL
                </label>
                <Input
                  value={formData.mediaUrl}
                  onChange={(e) => setFormData(prev => ({ ...prev, mediaUrl: e.target.value }))}
                  className="bg-neutral-800 border-neutral-700 focus:border-blue-500 focus:ring-blue-500"
                  placeholder="https://"
                />
              </div>

              <div className="space-y-4">
                <label className="text-sm font-medium text-gray-300 flex items-center gap-2">
                  <Dumbbell className="h-4 w-4 text-blue-400" />
                  Equipment Needed
                </label>
                <div className="flex flex-wrap gap-2 min-h-12 p-2 bg-neutral-800 rounded-md">
                  {formData.equipmentNeeded.map(equipment => (
                    <Badge
                      key={equipment}
                      className="bg-neutral-700 text-white hover:bg-neutral-600 transition-colors"
                      onClick={() => removeEquipment(equipment)}
                    >
                      {equipment}
                      <X className="h-3 w-3 ml-2" />
                    </Badge>
                  ))}
                </div>
                <div className="flex gap-2">
                  <Input
                    value={newEquipment}
                    onChange={(e) => setNewEquipment(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && (e.preventDefault(), addEquipment())}
                    placeholder="Add equipment"
                    className="bg-neutral-800 border-neutral-700"
                  />
                  <Button 
                    type="button" 
                    onClick={addEquipment}
                    className="bg-blue-600 hover:bg-blue-700"
                  >
                    <Plus className="h-4 w-4" />
                  </Button>
                </div>
              </div>

              <div className="space-y-4">
                <label className="text-sm font-medium text-gray-300 flex items-center gap-2">
                  <Tag className="h-4 w-4 text-blue-400" />
                  Tags
                </label>
                <div className="flex flex-wrap gap-2 min-h-12 p-2 bg-neutral-800 rounded-md">
                  {formData.tags.map(tag => (
                    <Badge
                      key={tag}
                      className="bg-blue-500/20 text-blue-400 hover:bg-blue-500/30 transition-colors"
                      onClick={() => removeTag(tag)}
                    >
                      #{tag}
                      <X className="h-3 w-3 ml-2" />
                    </Badge>
                  ))}
                </div>
                <div className="flex gap-2">
                  <Input
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && (e.preventDefault(), addTag())}
                    placeholder="Add tag"
                    className="bg-neutral-800 border-neutral-700"
                  />
                  <Button 
                    type="button" 
                    onClick={addTag}
                    className="bg-blue-600 hover:bg-blue-700"
                  >
                    <Plus className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            </div>

            {/* Right Column - Instructions & Mistakes */}
            <div className="space-y-6">
              <div className="space-y-4">
                <label className="text-sm font-medium text-gray-300 flex items-center gap-2">
                  <ScanLine className="h-4 w-4 text-blue-400" />
                  Instructions
                </label>
                <div className="space-y-3">
                  {formData.instructions.map((instruction, index) => (
                    <div key={index} className="flex gap-2 items-start">
                      <span className="text-sm text-gray-500 mt-2">{index + 1}.</span>
                      <Input
                        value={instruction}
                        onChange={(e) => handleArrayUpdate('instructions', index, e.target.value)}
                        className="bg-neutral-800 border-neutral-700 flex-1"
                      />
                      <Button
                        type="button"
                        variant="ghost"
                        onClick={() => removeArrayItem('instructions', index)}
                        className="text-red-400 hover:text-red-300 hover:bg-red-500/10"
                      >
                        <Trash className="h-4 w-4" />
                      </Button>
                    </div>
                  ))}
                </div>
                <Button
                  type="button"
                  onClick={() => addArrayItem('instructions')}
                  className="w-full bg-neutral-800 hover:bg-neutral-700 border border-dashed border-neutral-700"
                >
                  <Plus className="h-4 w-4 mr-2" />
                  Add Instruction
                </Button>
              </div>

              <div className="space-y-4">
                <label className="text-sm font-medium text-gray-300 flex items-center gap-2">
                  <AlertCircle className="h-4 w-4 text-blue-400" />
                  Common Mistakes
                </label>
                <div className="space-y-3">
                  {formData.commonMistakes.map((mistake, index) => (
                    <div key={index} className="flex gap-2 items-start">
                      <span className="text-sm text-gray-500 mt-2">•</span>
                      <Input
                        value={mistake}
                        onChange={(e) => handleArrayUpdate('commonMistakes', index, e.target.value)}
                        className="bg-neutral-800 border-neutral-700 flex-1"
                      />
                      <Button
                        type="button"
                        variant="ghost"
                        onClick={() => removeArrayItem('commonMistakes', index)}
                        className="text-red-400 hover:text-red-300 hover:bg-red-500/10"
                      >
                        <Trash className="h-4 w-4" />
                      </Button>
                    </div>
                  ))}
                </div>
                <Button
                  type="button"
                  onClick={() => addArrayItem('commonMistakes')}
                  className="w-full bg-neutral-800 hover:bg-neutral-700 border border-dashed border-neutral-700"
                >
                  <Plus className="h-4 w-4 mr-2" />
                  Add Common Mistake
                </Button>
              </div>
            </div>
          </div>

          <Button
            onClick={() => onSave(formData)}
            className="w-full mt-8 bg-blue-600 hover:bg-blue-700 py-6 text-lg font-medium"
          >
            Create Exercise
            <ChevronRight className="h-5 w-5 ml-2" />
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};


export default CreateExerciseOverlay;
