//src/components/ui/Textarea.jsx

import React from 'react';
import { cn } from '../../lib/utils';

const Textarea = React.forwardRef(({ className, rows = 3, ...props }, ref) => {
  return (
    <textarea
      ref={ref}
      rows={rows}
      className={cn(
        "w-full min-h-[100px] bg-transparent",
        "border-2 rounded-lg border-text-light/20",
        "px-4 py-3 text-base",
        "font-body text-text-primary",
        "placeholder:text-text-light/50",
        "focus:border-accent-primary focus:outline-none",
        "transition-all duration-300",
        "disabled:cursor-not-allowed disabled:opacity-50",
        "resize-y",
        className
      )}
      {...props}
    />
  );
});

Textarea.displayName = "Textarea";

export { Textarea };