import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { doc, getDoc, setDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import { useOutletContext } from 'react-router-dom';
import { MessageCircle } from 'lucide-react';
import { Button } from '../ui/Button';
import { dietToFoodSelections, DIET_PRINCIPLES } from '../../lib/nutritionMappings';

const NutritionSetup = () => {
  const { user } = useAuth();
  const { goToNextStep } = useOutletContext();
  const [selectedDiet, setSelectedDiet] = useState('mediterranean');
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  
  // Diet options with visualization and descriptions
  const dietOptions = [
    {
      id: 'mediterranean',
      label: 'Mediterranean',
      description: 'Emphasizes vegetables, fruits, whole grains, lean proteins like fish, and healthy fats like olive oil',
      example: 'I recommend focusing on whole foods like vegetables, fruits, whole grains, fish, and olive oil.'
    },
    {
      id: 'carnivore',
      label: 'Carnivore',
      description: 'Focuses exclusively on animal products like meat, fish, eggs, and limited dairy with zero plant foods',
      example: 'I recommend focusing on nutrient-dense animal foods like grass-fed meat, organ meats, eggs, and fish for optimal nutrition.'
    },
    {
      id: 'paleo',
      label: 'Paleo',
      description: 'Focuses on whole foods that our ancestors might have eaten: meats, fish, nuts, leafy greens, vegetables, and seeds',
      example: 'I recommend focusing on whole, unprocessed foods like quality meats, vegetables, fruits, nuts and seeds.'
    },
    {
      id: 'balanced',
      label: 'Balanced Macros',
      description: 'Focus on balanced protein, carbs, and fats for optimal performance and body composition',
      example: 'I recommend a balanced approach with good protein sources, complex carbs, and healthy fats in appropriate portions.'
    },
    {
      id: 'keto',
      label: 'Ketogenic',
      description: 'Very low carb, high fat diet to promote ketosis and metabolic health',
      example: 'I recommend focusing on healthy fats, moderate protein, and minimal carbohydrates to maintain ketosis.'
    },
    {
      id: 'plantBased',
      label: 'Plant-Based',
      description: 'Primarily or exclusively plant-derived foods for health and sustainability',
      example: 'I recommend building meals around a variety of plant foods, focusing on legumes, whole grains, vegetables, fruits, nuts and seeds.'
    },
    {
      id: 'lowCarb',
      label: 'Low Carb',
      description: 'Reduced carbohydrate intake with higher protein and fats for metabolic health',
      example: 'I recommend limiting refined carbs and focusing on protein, healthy fats, and non-starchy vegetables.'
    }
  ];
  
  // Load nutrition data without initializing coach document
  useEffect(() => {
    const loadNutritionData = async () => {
      try {
        if (!user?.uid) return;
        
        setIsLoading(true);
        
        // Load nutrition data
        const docRef = doc(db, 'coaches', user.uid, 'philosophy', 'nutrition');
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          setSelectedDiet(docSnap.data().selectedDiet || 'mediterranean');
        } else {
          // Create initial document with defaults
          await setDoc(docRef, {
            selectedDiet: 'mediterranean',
            selectedFoods: dietToFoodSelections('mediterranean'),
            restrictedItems: [],
            globalQualities: []
          });
        }
      } catch (error) {
        console.error('Error loading nutrition data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    loadNutritionData();
  }, [user]);
  
  const handleDietSelect = async (dietId) => {
    try {
      if (!user?.uid) return;
      
      setSelectedDiet(dietId);
      setIsSaving(true);
      
      // Auto-select foods based on diet type
      const foodSelections = dietToFoodSelections(dietId);
      
      const docRef = doc(db, 'coaches', user.uid, 'philosophy', 'nutrition');
      await setDoc(docRef, {
        selectedDiet: dietId,
        selectedFoods: foodSelections,
        updatedAt: serverTimestamp()
      }, { merge: true });
      
      // Also save diet principles for AI responses
      if (DIET_PRINCIPLES[dietId]) {
        await setDoc(docRef, {
          dietPrinciples: DIET_PRINCIPLES[dietId]
        }, { merge: true });
      }
    } catch (error) {
      console.error('Error updating diet selection:', error);
    } finally {
      setIsSaving(false);
    }
  };
  
  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-text-secondary">Loading...</div>
      </div>
    );
  }
  
  return (
    <div className="space-y-8">
      {/* Diet selection */}
      <div className="space-y-4">
        <h3 className="text-xl font-medium text-text-primary font-headers">Choose Your Nutrition Approach</h3>
        
        <div className="grid md:grid-cols-2 gap-4">
          {dietOptions.map(diet => (
            <div
              key={diet.id}
              onClick={() => handleDietSelect(diet.id)}
              className={`
                p-6 rounded-lg border-2 cursor-pointer transition-all duration-200
                ${selectedDiet === diet.id
                  ? 'border-accent-primary bg-accent-primary/10 shadow-md transform scale-[1.02]'
                  : 'border-border-light hover:border-accent-secondary hover:bg-background-tertiary'
                }
              `}
            >
              <div className="flex items-center gap-3 mb-2">
                <div className={`w-5 h-5 rounded-full border-2 flex items-center justify-center 
                                ${selectedDiet === diet.id ? 'border-accent-primary' : 'border-border-medium'}`}>
                  {selectedDiet === diet.id && (
                    <div className="w-2.5 h-2.5 rounded-full bg-accent-primary" />
                  )}
                </div>
                <h4 className="text-lg font-medium text-text-primary font-headers">{diet.label}</h4>
              </div>
              <p className="text-text-secondary mb-4 font-body">{diet.description}</p>
              
              {/* AI preview */}
              {selectedDiet === diet.id && (
                <div className="bg-background-tertiary p-4 rounded-lg mt-4 border border-accent-primary/20">
                  <div className="flex items-start gap-3">
                    <MessageCircle className="w-5 h-5 text-accent-primary shrink-0 mt-1" />
                    <div>
                      <p className="text-sm text-text-light mb-1 font-body">When clients ask about nutrition, your AI will say something like:</p>
                      <p className="text-text-primary font-body font-medium">"{diet.example}"</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      
      {/* Continue button */}
      <div className="flex justify-end mt-8">
        <Button
          onClick={goToNextStep}
          disabled={isSaving}
          className="bg-accent-primary text-white hover:bg-hover-primary transition-all duration-200 shadow-md hover:shadow-lg min-w-[150px] px-6 py-3 text-base"
        >
          {isSaving ? 'Saving...' : 'Continue'}
        </Button>
      </div>
    </div>
  );
};

export default NutritionSetup; 