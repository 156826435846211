// src/utils/recapProcessor.js

import { doc, getDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../firebase';

export async function processSessionRecap(clientId, sendMessageToClientCallable) {
  if (!clientId) {
    console.error('No clientId provided to processSessionRecap');
    return false;
  }

  try {
    console.log('Starting processSessionRecap for client:', clientId);
    
    const goalDocRef = doc(
      db,
      'users',
      clientId,
      'userKnowledge',
      'healthGoals',
      'goalsByTime',
      'weeklyGoals'
    );
    
    console.log('Fetching goal document...');
    const goalDoc = await getDoc(goalDocRef);
    console.log('Goal document exists:', goalDoc.exists());
    
    const currentGoal = goalDoc.exists() ? goalDoc.data()['Goal 1'] : '';
    console.log('Current goal:', currentGoal);

    if (!currentGoal) {
      console.log('No goal found, skipping recap message');
      return false;
    }

    const generateRecap = httpsCallable(functions, 'generateRecapMessage');
    
    try {
      const result = await generateRecap({ goal: currentGoal });
      console.log('Generated recap:', result.data);
      
      if (!result.data?.success || !result.data?.message) {
        throw new Error('Invalid response from generateRecapMessage');
      }

      // Add delay before sending the message
      console.log('Waiting 5 minutes before sending recap message...');
      await new Promise(resolve => setTimeout(resolve, 5 * 60 * 1000)); // 5 minutes in milliseconds

      await sendMessageToClientCallable({
        clientId,
        messageContent: result.data.message,
        scheduledTime: new Date(Date.now() + 5 * 60 * 1000).toISOString(), // Add scheduled time for tracking
      });

      console.log('Recap message sent successfully');
      return true;
    } catch (error) {
      console.error('Error in recap process:', error);
      return false;
    }
  } catch (error) {
    console.error('Error in processSessionRecap:', error);
    return false;
  }
}