// src/components/ui/Input.jsx

import React from 'react';
import { cn } from '../../lib/utils';

const Input = React.forwardRef(({ className = '', ...props }, ref) => (
  <input
    ref={ref}
    className={`border border-gray-700 bg-gray-800 text-white rounded px-2 py-1 focus:outline-none focus:border-blue-500 ${className}`}
    {...props}
  />
));

export { Input };
