// src/components/ExpandedSetsEditor.jsx

import React from 'react';
import { Button } from './ui/Button';
import { Input } from './ui/Input';
import { Plus, Trash2 } from 'lucide-react';

const ExpandedSetsEditor = ({ sets, onChange }) => {
  console.log('ExpandedSetsEditor - Initial props:', { sets });

  // Get the type from the first set, defaulting to weight
  const setType = sets[0]?.type || 'weight';
  console.log('ExpandedSetsEditor - Using set type:', setType);

  const addSet = () => {
    console.log('ExpandedSetsEditor - Adding new set with type:', setType);
    const targetValue = setType === 'time' ? 30 : 0;
    const reps = setType === 'time' ? undefined : 
                 setType === 'reps_to_failure' ? 'failure' : 
                 { min: 8, max: 12 };
    
    const newSet = {
      id: `set-${Date.now()}`,
      setNumber: sets.length + 1,
      type: setType,
      targetValue,
      actualValue: targetValue,
      reps,
      actualReps: reps,
      targetRPE: 7,
      actualRPE: 7,
      completed: false,
      setCategory: 'working',
      warmupPercentage: setType === 'weight' && sets.length === 0 ? 0.5 : undefined
    };
    console.log('ExpandedSetsEditor - Created new set:', newSet);
    onChange([...sets, newSet]);
  };

  const updateSet = (index, field, value) => {
    console.log('ExpandedSetsEditor - Updating set:', { index, field, value, setType });
    
    const newSets = sets.map((set, i) => {
      if (i === index) {
        if (field === 'warmupPercentage' || field === 'dropsetPercentage' || field === 'backoffPercentage') {
          if (value === null) {
            // If percentage is cleared, remove the percentage and calculated weight
            const { [field]: removed, targetValue: _, actualValue: __, ...rest } = set;
            return rest;
          }
          
          const decimalValue = value;
          const maxWorkingWeight = Math.max(
            ...sets.filter(s => s.setCategory === 'working').map(s => s.targetValue || 0)
          );
          console.log('ExpandedSetsEditor - Calculating percentage based weight:', {
            decimalValue,
            maxWorkingWeight
          });
          
          const calculatedWeight = maxWorkingWeight * decimalValue;
          const roundedWeight = Math.ceil(calculatedWeight / 5) * 5;
          return { 
            ...set, 
            [field]: decimalValue,
            targetValue: roundedWeight,
            actualValue: roundedWeight
          };
        }
        
        // Handle reps field based on exercise type
        if (field === 'reps' && setType === 'reps_to_failure') {
          console.log('ExpandedSetsEditor - Setting reps to failure');
          return { ...set, reps: 'failure', actualReps: 'failure' };
        }
        
        // For targetValue updates, sync actualValue
        if (field === 'targetValue') {
          return { ...set, targetValue: value, actualValue: value };
        }
        
        // For reps updates, sync actualReps
        if (field === 'reps') {
          return { ...set, reps: value, actualReps: value };
        }
        
        return { ...set, [field]: value };
      }
      return set;
    });

    if ((field === 'targetValue' || field.includes('Percentage')) && setType === 'weight') {
      const maxWorkingWeight = Math.max(
        ...newSets
          .filter(s => s.setCategory === 'working')
          .map(s => s.targetValue || 0)
      );
      console.log('ExpandedSetsEditor - Recalculating weights:', { maxWorkingWeight });

      newSets.forEach(set => {
        if (set.setCategory === 'warmup' && set.warmupPercentage) {
          const calculatedWeight = maxWorkingWeight * set.warmupPercentage;
          const roundedWeight = Math.ceil(calculatedWeight / 5) * 5;
          set.targetValue = roundedWeight;
          set.actualValue = roundedWeight;
          console.log('ExpandedSetsEditor - Updated warmup weight:', {
            percentage: set.warmupPercentage,
            calculatedWeight,
            roundedWeight
          });
        } else if (set.setCategory === 'dropset' && set.dropsetPercentage) {
          const calculatedWeight = maxWorkingWeight * set.dropsetPercentage;
          const roundedWeight = Math.ceil(calculatedWeight / 5) * 5;
          set.targetValue = roundedWeight;
          set.actualValue = roundedWeight;
        } else if (set.setCategory === 'backoff' && set.backoffPercentage) {
          const calculatedWeight = maxWorkingWeight * set.backoffPercentage;
          const roundedWeight = Math.ceil(calculatedWeight / 5) * 5;
          set.targetValue = roundedWeight;
          set.actualValue = roundedWeight;
        }
      });
    }

    onChange(newSets);
  };

  const removeSet = (index) => {
    const newSets = sets.filter((_, i) => i !== index);
    // Update set numbers
    const updatedSets = newSets.map((set, i) => ({
      ...set,
      setNumber: i + 1
    }));
    onChange(updatedSets);
  };

  const maxWorkingWeight = Math.max(
    ...sets
      .filter(s => s.setCategory === 'working')
      .map(s => s.targetValue)
  );

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-12 gap-2 text-sm font-medium text-text-light mb-2">
        <div className="col-span-1">#</div>
        <div className="col-span-2">Category</div>
        {setType === 'weight' && <div className="col-span-2">Weight (lbs)</div>}
        {setType === 'time' && <div className="col-span-2">Time (sec)</div>}
        {setType === 'reps_to_failure' && <div className="col-span-2">Target</div>}
        {setType !== 'time' && <div className="col-span-3">Reps</div>}
        <div className="col-span-1">RPE</div>
        {setType === 'weight' && <div className="col-span-2">% of Working</div>}
        <div className="col-span-1"></div>
      </div>

      {sets.map((set, index) => {
        console.log('ExpandedSetsEditor - Rendering set:', { 
          setNumber: set.setNumber, 
          type: set.type,
          setCategory: set.setCategory,
          reps: set.reps,
          targetValue: set.targetValue
        });
        
        return (
          <div key={set.id} className="grid grid-cols-12 gap-2 items-center">
            <div className="col-span-1 text-sm font-medium">{set.setNumber}</div>
            
            <div className="col-span-2">
              <select
                value={set.setCategory}
                onChange={(e) => updateSet(index, 'setCategory', e.target.value)}
                className="w-full bg-background-tertiary border border-border-light rounded-md px-2 py-1 text-sm"
              >
                {setType === 'weight' && <option value="warmup">Warmup</option>}
                <option value="working">Working</option>
                {setType === 'weight' && (
                  <>
                    <option value="backoff">Backoff</option>
                    <option value="dropset">Drop Set</option>
                  </>
                )}
              </select>
            </div>

            {/* Weight-based exercise */}
            {setType === 'weight' && (
              <div className="col-span-2">
                {['warmup', 'dropset', 'backoff'].includes(set.setCategory) ? (
                  <Input
                    type="number"
                    value={set.targetValue}
                    className="w-full bg-background-tertiary text-center opacity-50"
                    disabled
                  />
                ) : (
                  <Input
                    type="number"
                    value={set.targetValue}
                    onChange={(e) => updateSet(index, 'targetValue', parseFloat(e.target.value))}
                    className="w-full bg-background-tertiary text-center"
                    min="0"
                    step="5"
                  />
                )}
              </div>
            )}

            {/* Time-based exercise */}
            {setType === 'time' && (
              <div className="col-span-2">
                <Input
                  type="number"
                  value={set.targetValue}
                  onChange={(e) => updateSet(index, 'targetValue', parseInt(e.target.value))}
                  className="w-full bg-background-tertiary text-center"
                  min="0"
                  step="5"
                  placeholder="Seconds"
                />
              </div>
            )}

            {/* Reps to failure exercise */}
            {setType === 'reps_to_failure' && (
              <div className="col-span-2">
                <Input
                  type="text"
                  value="To Failure"
                  className="w-full bg-background-tertiary text-center"
                  disabled
                />
              </div>
            )}

            {/* Reps input (not shown for time-based exercises) */}
            {setType !== 'time' && (
              <div className="col-span-3">
                {setType === 'reps_to_failure' ? (
                  <Input
                    type="text"
                    value="To Failure"
                    className="w-full bg-background-tertiary text-center"
                    disabled
                  />
                ) : (
                  <div className="flex items-center gap-2">
                    <Input
                      type="number"
                      value={typeof set.reps === 'object' ? set.reps.min : set.reps}
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        updateSet(index, 'reps', typeof set.reps === 'object' 
                          ? { ...set.reps, min: value }
                          : value
                        );
                      }}
                      className="w-full bg-background-tertiary text-center"
                      min="1"
                    />
                    {typeof set.reps === 'object' && (
                      <>
                        <span className="text-text-light">-</span>
                        <Input
                          type="number"
                          value={set.reps.max}
                          onChange={(e) => updateSet(index, 'reps', { 
                            ...set.reps, 
                            max: parseInt(e.target.value) 
                          })}
                          className="w-full bg-background-tertiary text-center"
                          min={set.reps.min}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
            )}

            <div className="col-span-1">
              <Input
                type="number"
                value={set.targetRPE}
                onChange={(e) => updateSet(index, 'targetRPE', parseInt(e.target.value))}
                className="w-full bg-background-tertiary text-center [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                min="1"
                max="10"
              />
            </div>

            {setType === 'weight' && (
              <div className="col-span-2">
                {set.setCategory === 'warmup' && (
                  <div className="relative">
                    <Input
                      type="number"
                      value={set.warmupPercentage ? Math.round(set.warmupPercentage * 100) : ''}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === '') {
                          updateSet(index, 'warmupPercentage', null);
                        } else {
                          const numValue = parseInt(value);
                          if (!isNaN(numValue)) {
                            updateSet(index, 'warmupPercentage', numValue / 100);
                          }
                        }
                      }}
                      className="w-full bg-background-tertiary text-center pr-6"
                      min="0"
                      max="100"
                    />
                    <span className="absolute right-2 top-1/2 -translate-y-1/2 text-text-light">%</span>
                  </div>
                )}
                {set.setCategory === 'dropset' && (
                  <div className="relative">
                    <Input
                      type="number"
                      value={set.dropsetPercentage ? Math.round(set.dropsetPercentage * 100) : ''}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === '') {
                          updateSet(index, 'dropsetPercentage', null);
                        } else {
                          const numValue = parseInt(value);
                          if (!isNaN(numValue)) {
                            updateSet(index, 'dropsetPercentage', numValue / 100);
                          }
                        }
                      }}
                      className="w-full bg-background-tertiary text-center pr-6"
                      min="0"
                      max="100"
                    />
                    <span className="absolute right-2 top-1/2 -translate-y-1/2 text-text-light">%</span>
                  </div>
                )}
                {set.setCategory === 'backoff' && (
                  <div className="relative">
                    <Input
                      type="number"
                      value={set.backoffPercentage ? Math.round(set.backoffPercentage * 100) : ''}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === '') {
                          updateSet(index, 'backoffPercentage', null);
                        } else {
                          const numValue = parseInt(value);
                          if (!isNaN(numValue)) {
                            updateSet(index, 'backoffPercentage', numValue / 100);
                          }
                        }
                      }}
                      className="w-full bg-background-tertiary text-center pr-6"
                      min="0"
                      max="100"
                    />
                    <span className="absolute right-2 top-1/2 -translate-y-1/2 text-text-light">%</span>
                  </div>
                )}
              </div>
            )}

            <div className="col-span-1 flex justify-end">
              <Button
                type="button"
                variant="ghost"
                onClick={() => removeSet(index)}
                className="text-red-500 hover:text-red-400 p-1"
                disabled={sets.length === 1}
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            </div>
          </div>
        );
      })}

      <Button
        type="button"
        onClick={addSet}
        className="w-full bg-accent-primary/10 text-accent-primary hover:bg-accent-primary/20"
      >
        <Plus className="h-4 w-4 mr-2" />
        Add Set
      </Button>
    </div>
  );
};

export default ExpandedSetsEditor;
