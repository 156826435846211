import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useAuth } from '../../contexts/AuthContext';
import { Button } from '../ui/Button';

const SetupContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { updateOnboardingProgress } = useAuth();
  
  // Determine current step
  const currentPath = location.pathname;
  const currentStep = currentPath.split('/').pop();
  
  // Define steps order - Removed style-calibration step
  const steps = ['nutrition', 'routines', 'products', 'messaging'];
  const currentIndex = steps.indexOf(currentStep);
  
  // Update onboarding progress
  useEffect(() => {
    if (currentIndex >= 0) {
      // Now name-username is step 0, nutrition is step 1, routines is step 2, etc.
      // Adding 1 because currentIndex is 0-based and we want step 1 to be nutrition
      updateOnboardingProgress(currentIndex + 1);
    }
  }, [currentIndex, updateOnboardingProgress]);
  
  // Navigate to next/previous step
  const goToNextStep = () => {
    if (currentIndex < steps.length - 1) {
      navigate(`/onboarding/setup/${steps[currentIndex + 1]}`);
    } else {
      navigate('/onboarding/activation');
    }
  };
  
  const goToPrevStep = () => {
    if (currentIndex > 0) {
      navigate(`/onboarding/setup/${steps[currentIndex - 1]}`);
    } else {
      // Go back to name-username page if they're on the nutrition page
      navigate('/onboarding/name-username');
    }
  };
  
  // Step titles and descriptions
  const stepContent = {
    nutrition: {
      title: 'Step 1: Nutrition Philosophy',
      description: 'This will help your AI begin to understand your approach to nutrition with clients'
    },
    routines: {
      title: 'Step 2: Daily Routines',
      description: 'You can share your daily routines with your AI to share with your clients'
    },
    products: {
      title: 'Step 3: Product Recommendations',
      description: 'You can share your product recommendations with your AI to share with your clients'
    },
    messaging: {
      title: 'Step 4: Messaging Style',
      description: 'This will help your AI begin to understand your unique messaging style'
    }
  };

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-text-primary mb-4 font-headers">
          {stepContent[currentStep]?.title || `Step ${currentIndex + 1}`}
        </h2>
        <p className="text-text-secondary font-body">
          {stepContent[currentStep]?.description || 'Configure your AI coach settings'}
        </p>
      </div>
      
      {/* Step content */}
      <Outlet context={{ goToNextStep, goToPrevStep }} />
    </div>
  );
};

export default SetupContainer; 