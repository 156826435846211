// src/components/ui/Badge.jsx

import React from 'react';
import { cn } from '../../lib/utils';

const Badge = React.forwardRef(({ className, variant = "default", children, ...props }, ref) => {
  const variants = {
    default: "bg-accent-primary/20 text-accent-primary border border-accent-primary/30",
    secondary: "bg-accent-secondary/10 text-accent-secondary border border-accent-secondary/20",
    tertiary: "bg-accent-tertiary/10 text-accent-tertiary border border-accent-tertiary/20",
    outline: "border border-accent-primary text-accent-primary bg-transparent",
    subtle: "bg-background-tertiary text-text-secondary",
    success: "bg-green-100 text-green-800 border border-green-200",
    warning: "bg-amber-100 text-amber-800 border border-amber-200",
    error: "bg-red-100 text-red-800 border border-red-200",
    info: "bg-blue-100 text-blue-800 border border-blue-200"
  };

  return (
    <div
      ref={ref}
      className={cn(
        "inline-flex items-center rounded-full",
        "px-2 py-0.5 text-xs font-medium",
        "transition-colors duration-200",
        variants[variant],
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
});

Badge.displayName = "Badge";

export { Badge };
