// src/components/MessageOverlay.jsx
import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Button } from './ui/Button';
import Modal from './ui/Modal';

const MessageOverlay = ({ userId, isOpen, onClose }) => {
  const [conversation, setConversation] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [editedConversation, setEditedConversation] = useState('');

  // Fetch the current conversation when the overlay opens
  useEffect(() => {
    if (isOpen) {
      const fetchConversation = async () => {
        try {
          const messageStyleRef = doc(
            db,
            'coaches',
            userId,
            'philosophy',
            'messageStyleSettings'
          );
          const messageStyleDoc = await getDoc(messageStyleRef);
          if (messageStyleDoc.exists()) {
            const data = messageStyleDoc.data();
            setConversation(data.exampleConversation || '');
            setEditedConversation(data.exampleConversation || '');
          }
        } catch (error) {
          console.error('Error fetching conversation:', error);
        }
      };
      fetchConversation();
    }
  }, [isOpen, userId]);

  const handleSave = async () => {
    try {
      const messageStyleRef = doc(
        db,
        'coaches',
        userId,
        'philosophy',
        'messageStyleSettings'
      );
      await updateDoc(messageStyleRef, {
        exampleConversation: editedConversation,
      });
      setConversation(editedConversation);
      setEditMode(false);
      onClose();
    } catch (error) {
      console.error('Error updating conversation:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose} 
      className="w-3/4 max-w-4xl" // Passed wider width classes
    >
      <div className="p-6 bg-neutral-800 rounded-lg">
        <h2 className="text-2xl text-white mb-4">Style Imitator</h2>
        <textarea
          className="w-full p-2 rounded bg-neutral-700 text-white"
          rows="10"
          value={editedConversation}
          onChange={(e) => setEditedConversation(e.target.value)}
          disabled={!editMode}
        />
        <div className="flex justify-end mt-4 gap-2">
          {!editMode ? (
            <Button
              onClick={() => setEditMode(true)}
              className="bg-gray-500 text-white hover:bg-gray-600"
            >
              Edit
            </Button>
          ) : (
            <>
              <Button
                onClick={handleSave}
                className="bg-blue-500 text-white hover:bg-blue-600"
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  setEditMode(false);
                  setEditedConversation(conversation);
                }}
                className="bg-red-500 text-white hover:bg-red-600"
              >
                Cancel
              </Button>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default MessageOverlay;
