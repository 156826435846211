import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';

class SimplifiedAnalyticsService {
  constructor() {
    this._sessionId = this._generateSessionId();
    this._utmParams = this._parseUtmParams();
  }
  
  async trackEvent(type, params = {}) {
    try {
      const { coachId, formId, userId, responseId, metadata = {} } = params;
      
      // Check for required coachId
      if (!coachId) {
        console.warn('Analytics event missing coachId:', type);
        return null;
      }
      
      // Create event object
      const eventData = {
        type,
        timestamp: serverTimestamp(),
        sessionId: this._sessionId,
        coachId,
        formId: formId || 'default',
        utm: this._utmParams
      };
      
      // Add optional fields if they exist
      if (userId) eventData.userId = userId;
      if (responseId) eventData.responseId = responseId;
      if (Object.keys(metadata).length > 0) eventData.metadata = metadata;
      
      // Save to Firestore - FIXED PATH
      const eventsRef = collection(db, 'analytics', 'events', 'items');
      const docRef = await addDoc(eventsRef, eventData);
      console.log(`Analytics event tracked: ${type}`, { ...eventData, id: docRef.id });
      
      return eventData;
    } catch (error) {
      console.error('Error tracking analytics event:', error);
      // Log detailed error for debugging
      console.error('Error details:', {
        message: error.message,
        code: error.code,
        stack: error.stack
      });
      return null;
    }
  }
  
  // Specific event tracking methods
  async trackPageView(params = {}) {
    return this.trackEvent('page_view', params);
  }
  
  async trackFormStart(params = {}) {
    return this.trackEvent('form_start', params);
  }
  
  async trackFormComplete(params = {}) {
    return this.trackEvent('form_complete', params);
  }
  
  async trackCallBooked(params = {}) {
    return this.trackEvent('call_booked', params);
  }
  
  async trackPaymentComplete(params = {}) {
    return this.trackEvent('payment_complete', params);
  }
  
  // Helper methods
  _generateSessionId() {
    return 'sess_' + Math.random().toString(36).substring(2, 15);
  }
  
  _parseUtmParams() {
    const queryParams = new URLSearchParams(window.location.search);
    return {
      source: queryParams.get('utm_source') || '',
      medium: queryParams.get('utm_medium') || '',
      campaign: queryParams.get('utm_campaign') || ''
    };
  }
}

export default new SimplifiedAnalyticsService(); 