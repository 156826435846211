import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../../ui/Card';
import { Input } from '../../ui/Input';
import { Textarea } from '../../ui/Textarea';
import { Button } from '../../ui/Button';
import { Plus, Trash } from 'lucide-react';
import RichTextEditor from '../../ui/RichTextEditor';

const FAQsTab = ({
  faqs,
  addFaq,
  removeFaq,
  updateFaq
}) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex justify-between items-center">
          <span>Frequently Asked Questions</span>
          <Button 
            variant="outline" 
            size="sm" 
            onClick={addFaq}
            className="flex items-center gap-1 text-xs"
          >
            <Plus className="h-3 w-3" />
            Add Question
          </Button>
        </CardTitle>
      </CardHeader>
      <CardContent>
        {faqs.length === 0 ? (
          <div className="text-center p-8 border border-dashed border-border-light rounded-lg">
            <p className="text-text-light mb-4">No questions added yet</p>
            <Button 
              variant="outline" 
              size="sm" 
              onClick={addFaq}
              className="flex items-center gap-1 mx-auto"
            >
              <Plus className="h-4 w-4" />
              Add Question
            </Button>
          </div>
        ) : (
          <div className="space-y-6">
            {faqs.map((faq, index) => (
              <div key={index} className="p-6 border border-border-light rounded-lg relative">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => removeFaq(index)}
                  className="absolute right-2 top-2 p-1 h-8 w-8 flex items-center justify-center text-red-500 hover:bg-red-50"
                >
                  <Trash className="h-4 w-4" />
                </Button>
                
                <div className="mb-4">
                  <label className="block text-text-light mb-2">Question</label>
                  <RichTextEditor
                    value={faq.question}
                    onChange={(value) => updateFaq(index, 'question', value)}
                    placeholder="e.g., How does the coaching process work?"
                    rows={2}
                  />
                </div>
                
                <div>
                  <label className="block text-text-light mb-2">Answer</label>
                  <RichTextEditor
                    value={faq.answer}
                    onChange={(value) => updateFaq(index, 'answer', value)}
                    placeholder="Provide a clear and helpful answer"
                    rows={3}
                  />
                  <p className="text-xs text-text-light mt-1">
                    Select text to add bold, italic, or underline formatting
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default FAQsTab; 