// src/components/CoachDashboard.jsx

import React, { useState, useEffect } from 'react';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  arrayUnion,
  arrayRemove,
  increment,
} from 'firebase/firestore';
import { X } from 'lucide-react';
import { Input } from './ui/Input';
import { Button } from './ui/Button';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { db } from '../firebase';
import AdherenceWidget from './AdherenceWidget';
import NavigationBar from './NavigationBar';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { API_URLS } from '../constants/api';
import { loadStripe } from '@stripe/stripe-js';
import SubscriptionModal from './SubscriptionModal';

const stripePromise = loadStripe('pk_live_51PmzEsJp9datwQ8XX5QXruYq4HeCdzi5qLpPTOJ6DauNGUe4RicW0vtsCiyow8gqjubN2NtcB9w5jr7ycWtgMAaR00wFdVnC4K');

const CoachDashboard = ({ handlePaymentSuccess }) => {
  const { user, isAdmin } = useAuth();
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [message, setMessage] = useState('');
  const [subscriptionQuantity, setSubscriptionQuantity] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [requestedSeats, setRequestedSeats] = useState(0);
  const [coachData, setCoachData] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('');
  const [isLoadingClients, setIsLoadingClients] = useState(true);
  const [pendingRequests, setPendingRequests] = useState([]);
  
  // New state to show a loading overlay when redirecting to Stripe
  const [isRedirectingToCheckout, setIsRedirectingToCheckout] = useState(false);

  useEffect(() => {
    if (user?.uid) {
      const unsubscribe = loadClients();
      return () => {
        if (unsubscribe) unsubscribe();
      };
    }
  }, [user?.uid]);

  useEffect(() => {
    if (user?.uid) {
      const q = query(
        collection(db, 'users'),
        where('requestedCoachId', '==', user.uid),
        where('requestStatus', '==', 'pending')
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const requests = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        setPendingRequests(requests);
      });

      return () => unsubscribe();
    }
  }, [user?.uid]);

  useEffect(() => {
    let unsubscribeCoach = () => {};
  
    const setupSubscriptions = async () => {
      if (!user?.uid) return;
  
      try {
        const coachRef = doc(db, 'coaches', user.uid);
        unsubscribeCoach = onSnapshot(coachRef, (doc) => {
          if (doc.exists) {
            const data = doc.data();
            setSubscriptionQuantity(data.subscriptionQuantity || 0);
            setCoachData(data);
          }
        });
      } catch (error) {
        console.error('Error setting up subscription listener:', error);
      }
    };
  
    setupSubscriptions();
    return () => unsubscribeCoach();
  }, [user?.uid]);

  const loadClients = () => {
    if (!user?.uid) return;
  
    setIsLoadingClients(true);
  
    const clientsQuery = query(
      collection(db, 'users'),
      where('coachId', '==', user.uid)
    );
  
    return onSnapshot(clientsQuery, async (snapshot) => {
      try {
        const clientList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setClients(clientList);
        
        const clientIds = clientList.map(client => client.id);
        
        const coachDocRef = doc(db, 'coaches', user.uid);
        await updateDoc(coachDocRef, {
          numberOfClients: clientList.length,
          clients: clientIds,
          updatedAt: serverTimestamp(),
        });
  
        const coachDoc = await getDoc(coachDocRef);
        if (coachDoc.exists()) {
          const data = coachDoc.data();
          setSubscriptionQuantity(data.subscriptionQuantity || 0);
        } else {
          setSubscriptionQuantity(0);
        }
      } catch (error) {
        console.error('Error processing client update:', error);
        setMessage('Error updating clients: ' + error.message);
      } finally {
        setIsLoadingClients(false);
      }
    }, (error) => {
      console.error('Error in client subscription:', error);
      setMessage('Error loading clients: ' + error.message);
      setIsLoadingClients(false);
    });
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setUploading(true);
    setUploadMessage('');
    
    try {
      const fileContents = await file.text();
      const exercisesData = JSON.parse(fileContents);
  
      const idToken = await user.getIdToken();
      const response = await fetch(API_URLS.uploadExercises, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify({ exercises: exercisesData }),
      });
  
      const result = await response.json();
  
      if (result.success) {
        setUploadMessage('Exercises uploaded successfully');
      } else {
        throw new Error(result.error || 'Failed to upload exercises');
      }
    } catch (error) {
      console.error('Error uploading exercises:', error);
      setUploadMessage(`Error uploading exercises: ${error.message}`);
    } finally {
      setUploading(false);
    }
  };

  const removeClient = async (clientId) => {
    try {
      const idToken = await user.getIdToken();
      const response = await fetch(API_URLS.removeClient, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify({ clientId }),
      });
  
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Failed to remove client');
      }
  
      await loadClients();
      setMessage('Client removed successfully');
    } catch (error) {
      console.error('Error removing client:', error);
      setMessage('Error removing client: ' + error.message);
    }
  };

  const handleClientClick = (clientId) => {
    navigate(`/client/${clientId}`);
  };

  const acceptRequest = async (client) => {
    try {
      // Check if coach has a customer ID and active/trialing subscription
      if (!coachData?.stripeCustomerId || coachData?.subscriptionStatus === 'canceled' || coachData?.subscriptionStatus === 'inactive') {
        // Coach needs to set up Stripe or re-activate subscription
        setIsRedirectingToCheckout(true);
        const idToken = await user.getIdToken();
        const response = await fetch(API_URLS.createCheckoutSession, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            seatCount: 1,
            domain: window.location.origin,
          }),
        });
  
        const data = await response.json();
        if (data.sessionId) {
          const stripe = await stripePromise;
          const result = await stripe.redirectToCheckout({
            sessionId: data.sessionId,
          });
          if (result.error) {
            setMessage(result.error.message);
            setIsRedirectingToCheckout(false);
          }
          return; 
        } else {
          // If no sessionId returned (unlikely), stop loader
          setIsRedirectingToCheckout(false);
          return;
        }
      }

      // If subscription is active/trialing but no seats left
      if (clients.length >= subscriptionQuantity) {
        setRequestedSeats(clients.length + 1);
        setShowSubscriptionModal(true);
        return;
      }

      // Accept the request
      await updateDoc(doc(db, 'users', client.id), {
        coachId: user.uid,
        requestedCoachId: null,
        requestStatus: 'accepted',
        updatedAt: serverTimestamp()
      });
  
      setMessage('Client request accepted successfully');
    } catch (error) {
      setMessage(`Error accepting request: ${error.message}`);
      setIsRedirectingToCheckout(false);
    }
  };
  
  const declineRequest = async (client) => {
    try {
      await updateDoc(doc(db, 'users', client.id), {
        requestedCoachId: null,
        requestStatus: 'declined',
        updatedAt: serverTimestamp()
      });
    } catch (error) {
      setMessage(`Error declining request: ${error.message}`);
    }
  };

  const handleSubscriptionUpdate = async (seats) => {
    setIsLoading(true);
    try {
      const coachDoc = await getDoc(doc(db, 'coaches', user.uid));
      const cData = coachDoc.data();

      if (!cData.subscriptionId || cData.subscriptionStatus === 'canceled') {
        const response = await fetch(API_URLS.createCheckoutSession, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${await user.getIdToken()}`,
          },
          body: JSON.stringify({
            seatCount: seats || requestedSeats,
            domain: window.location.origin,
          }),
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || 'Failed to create checkout session');
        }

        if (data.sessionId) {
          const stripe = await stripePromise;
          const result = await stripe.redirectToCheckout({ sessionId: data.sessionId });
          if (result.error) {
            setMessage(result.error.message);
          }
        } else {
          setShowSubscriptionModal(false);
          setMessage('Subscription updated successfully');
        }
        return;
      }

      // Update existing subscription
      const response = await fetch(API_URLS.createCheckoutSession, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await user.getIdToken()}`,
        },
        body: JSON.stringify({
          seatCount: seats,
          domain: window.location.origin,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to update subscription');
      }

      if (data.success) {
        setShowSubscriptionModal(false);
        setMessage('Subscription updated successfully');

        const coachDoc = await getDoc(doc(db, 'coaches', user.uid));
        if (coachDoc.exists()) {
          const d = coachDoc.data();
          setSubscriptionQuantity(d.subscriptionQuantity || 0);
        }
      } else if (data.redirectUrl) {
        window.location.href = data.redirectUrl;
      }
    } catch (error) {
      console.error('Error updating subscription:', error);
      setMessage(`Error updating subscription: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-[#000000] min-h-screen text-[#E0E0E0] flex flex-col w-full pt-20">
      {message && (
        <div className={`p-4 rounded ${
          message.includes('Error') ? 'bg-red-500' : 'bg-green-500'
        } text-white mb-4`}>
          {message}
        </div>
      )}
      
      {isAdmin && (
        <div className="mb-4 px-6 md:px-8">
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Upload Exercises JSON
          </label>
          <input
            type="file"
            accept=".json"
            onChange={handleFileUpload}
            disabled={uploading}
            className="block w-full text-sm text-gray-500
              file:mr-4 file:py-2 file:px-4
              file:rounded-md file:border-0
              file:text-sm file:font-semibold
              file:bg-blue-50 file:text-blue-700
              hover:file:bg-blue-100"
          />
          {uploadMessage && (
            <div className="mt-2 text-sm text-green-600">{uploadMessage}</div>
          )}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full flex-grow px-6 md:px-8">
        {/* Coach Stats Card */}
        <Card className="bg-[#1C1C1C] border-0 w-full">
          <CardHeader>
            <CardTitle className="text-[#FFFFFF]">Coach Performance</CardTitle>
          </CardHeader>
          <CardContent>
            <AdherenceWidget coachId={user.uid} />
          </CardContent>
        </Card>

        {/* Clients and Pending Requests */}
        <Card className="bg-neutral-900 border-0">
          <CardHeader className="p-4">
            <div className="flex flex-col space-y-4">
              <div>
                <CardTitle className="text-lg font-semibold text-[#FFFFFF]">
                  Your Clients
                </CardTitle>
                <p className="text-sm text-gray-400 mt-1">
                  {clients.length} of {subscriptionQuantity} available spots filled
                </p>
              </div>

              <div className="flex items-center gap-2">
                <Button
                  variant="ghost"
                  className="flex-1 h-10 bg-neutral-800 text-white hover:bg-blue-600"
                  onClick={() => setShowSubscriptionModal(true)}
                >
                  Adjust Capacity
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardContent>
            {/* Pending Requests Section */}
            <div className="mb-4">
              <h2 className="text-white font-semibold mb-2">Pending Requests</h2>
              {pendingRequests.length === 0 ? (
                <div className="text-center py-2 text-gray-400">No pending requests</div>
              ) : (
                pendingRequests.map((req) => (
                  <div key={req.id} className="flex justify-between items-center bg-[#333333] p-2 rounded mb-2">
                    <div>
                      <div className="text-[#FFFFFF]">{req.name}</div>
                      <div className="text-sm text-gray-400">{req.email}</div>
                    </div>
                    <div className="flex gap-2">
                      <Button
                        variant="ghost"
                        className="bg-green-600 hover:bg-green-700 text-white px-3"
                        onClick={() => acceptRequest(req)}
                        disabled={isRedirectingToCheckout} // Prevent multiple clicks
                      >
                        Accept
                      </Button>
                      <Button
                        variant="ghost"
                        className="bg-red-600 hover:bg-red-700 text-white px-3"
                        onClick={() => declineRequest(req)}
                        disabled={isRedirectingToCheckout}
                      >
                        Decline
                      </Button>
                    </div>
                  </div>
                ))
              )}
            </div>

            {/* Clients Section */}
            <h2 className="text-white font-semibold mb-2">Current Clients</h2>
            <div className="space-y-2">
              {isLoadingClients ? (
                <div className="text-center py-4 text-gray-400">Loading clients...</div>
              ) : clients.length === 0 ? (
                <div className="text-center py-4 text-gray-400">No clients found</div>
              ) : (
                clients.map((client) => (
                  <div
                    key={client.id}
                    className="flex justify-between items-center bg-[#333333] p-2 rounded cursor-pointer"
                    onClick={() => handleClientClick(client.id)}
                  >
                    <div>
                      <div className="text-[#FFFFFF]">
                        {client.name} ({client.email})
                      </div>
                      <div className="text-sm text-gray-400">{client.phoneNumber}</div>
                    </div>
                    <Button
                      size="icon"
                      variant="ghost"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeClient(client.id);
                      }}
                      className="text-[#E0E0E0] hover:text-[#6495ED]"
                      disabled={isRedirectingToCheckout}
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  </div>
                ))
              )}
            </div>
          </CardContent>
        </Card>
      </div>

      <SubscriptionModal
        isOpen={showSubscriptionModal}
        onClose={() => setShowSubscriptionModal(false)}
        currentSeats={subscriptionQuantity}
        requestedSeats={requestedSeats}
        onConfirm={handleSubscriptionUpdate}
        isLoading={isLoading}
        trialEndsAt={coachData?.trialEndsAt}
        subscriptionStatus={coachData?.subscriptionStatus || 'inactive'}
      />

      {isRedirectingToCheckout && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="text-white flex flex-col items-center">
            <svg className="animate-spin h-8 w-8 text-white mb-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.372 0 0 5.372 0 12h4z"></path>
            </svg>
            <div>One moment please...</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CoachDashboard;
