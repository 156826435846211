// src/contexts/AuthContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged 
} from 'firebase/auth';
import { doc, setDoc, getDoc, onSnapshot, updateDoc, serverTimestamp } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { arrayUnion } from 'firebase/firestore'; 

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let unsubscribeAuth = () => {};
    let unsubscribeSnapshot = () => {};

    unsubscribeAuth = onAuthStateChanged(auth, async (firebaseUser) => {
      try {
        if (firebaseUser) {
          const idTokenResult = await firebaseUser.getIdTokenResult(true);
          setUser(firebaseUser);
          setIsAdmin(idTokenResult.claims.isAdmin || false);
          
          const coachRef = doc(db, 'coaches', firebaseUser.uid);
          const coachDoc = await getDoc(coachRef);

          if (!coachDoc.exists()) {
            console.log('Creating missing coach document');
            await setDoc(coachRef, {
              email: firebaseUser.email,
              isCoach: true,
              isAdmin: false,
              subscriptionStatus: 'inactive',
              subscriptionQuantity: 0,
              numberOfClients: 0,
              clients: [],
              createdAt: serverTimestamp(),
              updatedAt: serverTimestamp(),
              stripeCustomerId: null,
              hasHadTrial: false,
            });
          } else {
            // Update existing coach document with missing fields
            const data = coachDoc.data();
            const updateData = {};
            if (data.subscriptionQuantity === undefined) updateData.subscriptionQuantity = 0;
            if (data.numberOfClients === undefined) updateData.numberOfClients = 0;
            if (data.clients === undefined) updateData.clients = [];
            if (data.subscriptionStatus === undefined) updateData.subscriptionStatus = 'inactive';
            if (data.hasHadTrial === undefined) updateData.hasHadTrial = false;
            if (data.stripeCustomerId === undefined) updateData.stripeCustomerId = null;
            if (Object.keys(updateData).length > 0) {
              updateData.updatedAt = serverTimestamp();
              await updateDoc(coachRef, updateData);
              console.log('Updated coach document with missing fields:', updateData);
            }
          }

          // Set up listener for coach document
          unsubscribeSnapshot = onSnapshot(coachRef, (doc) => {
            if (doc.exists()) {
              const data = doc.data();
              console.log('Coach document updated:', data);
              setSubscriptionStatus(data.subscriptionStatus || 'inactive');
              setIsAdmin(data.isAdmin || false);
            } else {
              console.error('Coach document does not exist after creation attempt');
            }
          }, (error) => {
            console.error("Coach document listener error:", error);
          });
        } else {
          setUser(null);
          setIsAdmin(false);
          setSubscriptionStatus(null);
          if (unsubscribeSnapshot) unsubscribeSnapshot();
        }
      } catch (error) {
        console.error('Error in auth state change:', error);
      } finally {
        setLoading(false);
      }
    });

    return () => {
      unsubscribeAuth();
      if (unsubscribeSnapshot) unsubscribeSnapshot();
    };
  }, []);

  const login = async (email, password) => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      return result.user;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const signup = async (email, password) => {
    try {
      const result = await createUserWithEmailAndPassword(auth, email, password);
      
      // Create coach document
      await setDoc(doc(db, 'coaches', result.user.uid), {
        email: result.user.email,
        isCoach: true,
        isAdmin: false,
        subscriptionStatus: 'inactive',
        subscriptionQuantity: 0,
        numberOfClients: 0,
        clients: [],
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        stripeCustomerId: null,
        hasHadTrial: false,
      });

      return result.user;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const logout = () => signOut(auth);

  const value = {
    user,
    isAdmin,
    subscriptionStatus,
    loading,
    login,
    signup,
    logout,
    getIdToken: async () => user?.getIdToken(true),
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export { AuthProvider };
