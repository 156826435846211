// src/components/ClientPage.jsx

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Input } from './ui/Input';
import { Button } from './ui/Button';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { ChevronDown, Send } from 'lucide-react';
import { httpsCallable } from 'firebase/functions';
import {
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  orderBy,
  updateDoc,
  arrayUnion,
  arrayRemove,
  setDoc,
  deleteField,
  addDoc,
  where,
  limit,
  onSnapshot,
  writeBatch,
  serverTimestamp,
} from 'firebase/firestore';

import { functions, db } from '../firebase';
import NudgeManager from './NudgeManager';
import AdherenceModal from './AdherenceModal';
import { format } from 'date-fns';
import SessionTab from './SessionTab';
import ClientInfoTab from './ClientInfoTab';
import { processSessionRecap } from '../utils/recapProcessor';
import NutritionTab from './NutritionTab';
import { cn } from '../lib/utils';

const ClientPage = () => {
  const { user, isAdmin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { clientId } = useParams();
  const currentPage = location.pathname.substring(1);
  const [activeTab, setActiveTab] = useState('info');
  const [showNudgeManager, setShowNudgeManager] = useState(false);
  const [nudges, setNudges] = useState([]);
  const [message, setMessage] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [clients, setClients] = useState([]);
  const [isSelectingClient, setIsSelectingClient] = useState(false);
  const [isAddingPreference, setIsAddingPreference] = useState(false);
  const [newPreference, setNewPreference] = useState('');
  const [isUpdatingGoal, setIsUpdatingGoal] = useState(false);
  const [newGoal, setNewGoal] = useState('');
  const [isAdherenceModalOpen, setIsAdherenceModalOpen] = useState(false);
  const [hasTrackedToday, setHasTrackedToday] = useState(false);
  const [currentAdherenceValue, setCurrentAdherenceValue] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [isAddingSession, setIsAddingSession] = useState(false);
  const [sessionData, setSessionData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [coachFirstName, setCoachFirstName] = useState('');

  const chatContainerRef = useRef(null);
  const [newInsights, setNewInsights] = useState(null);

  const timeZone = 'America/New_York';

  const [clientInfo, setClientInfo] = useState({
    name: '',
    email: '',
    preferences: [],
    goals: [],
    coachId: ''
  });

  const fetchNudges = async () => {
    try {
      const nudgesCollection = collection(db, 'users', clientId, 'nudges');
      const nudgesSnapshot = await getDocs(nudgesCollection);
      const fetchedNudges = nudgesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNudges(fetchedNudges);
    } catch (error) {
      console.error('Error fetching nudges:', error);
      alert('Error fetching nudges: ' + error.message);
    }
  };

  const onNavigate = (path) => {
    navigate(`/${path}`);
  };

  const fetchMessages = () => {
    try {
      const messagesCollection = collection(db, 'users', clientId, 'messages');
      const q = query(messagesCollection, orderBy('date_sent', 'asc'));
      
      // Set up real-time listener
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const messages = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            text: data.sender_name === 'coach' ? data.aiResponse || data.content : data.content,
            sender: data.sender_name === 'coach' || data.is_system_message ? 'coach' : 'client',
            time: data.date_sent?.toDate().toLocaleString() || new Date().toLocaleString(),
          };
        });
        setChatMessages(messages);

        // Scroll to bottom when new messages arrive
        if (chatContainerRef.current) {
          chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
      });

      // Return unsubscribe function
      return unsubscribe;
    } catch (error) {
      console.error('Error setting up message listener:', error);
      alert('Error setting up message listener: ' + error.message);
    }
  };

  useEffect(() => {
    let unsubscribe;
    
    if (clientId) {
      unsubscribe = fetchMessages();
    }

    // Cleanup function to remove listener when component unmounts or clientId changes
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [clientId]);

  useEffect(() => {
    if (clientId && user) {
      // Remove fetchMessages() from here
      fetchClientData();
      fetchNudges();
      fetchSessions();
      checkIfTrackedToday();
    }
    if (user) {
      fetchCoachData();
      fetchClients();
    }
  }, [clientId, user]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatMessages]);

  useEffect(() => {
    if (showPopup) {
      const timer = setTimeout(() => {
        setShowPopup(false);
        setPopupMessage('');
      }, 3000); // Hide after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [showPopup]);

  const fetchCoachData = async () => {
    try {
      const coachDoc = await getDoc(doc(db, 'coaches', user.uid));
      // ... rest of the function
    } catch (error) {
      console.error('Error fetching coach data:', error);
    }
  };

  const fetchClientData = async () => {
    try {
      // Fetch client's basic info
      const userDoc = await getDoc(doc(db, 'users', clientId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setClientInfo(prevInfo => ({
          ...prevInfo,
          name: userData.name || 'Client',
          email: userData.email || 'No email provided',
          coachId: userData.coachId || ''
        }));

        // Fetch preferences
        const preferencesDoc = await getDoc(
          doc(db, 'users', clientId, 'userKnowledge', 'preferences')
        );
        if (preferencesDoc.exists()) {
          setClientInfo(prevInfo => ({
            ...prevInfo,
            preferences: preferencesDoc.data().foodPreferences || []
          }));
        }

        // Fetch goals
        const weeklyGoalsDoc = await getDoc(
          doc(db, 'users', clientId, 'userKnowledge', 'healthGoals', 'goalsByTime', 'weeklyGoals')
        );
        if (weeklyGoalsDoc.exists()) {
          const data = weeklyGoalsDoc.data();
          const goals = Object.keys(data).map((key) => ({ key, value: data[key] }));
          setClientInfo(prevInfo => ({
            ...prevInfo,
            goals
          }));
        }
      }
    } catch (error) {
      console.error('Error fetching client data:', error);
    }
  };

  const fetchClients = async () => {
    try {
      console.log('Fetching clients...');
      const coachDoc = await getDoc(doc(db, 'coaches', user.uid));
      console.log('Coach document:', coachDoc.data());
      
      if (coachDoc.exists() && coachDoc.data().clients) {
        // Add this to remove duplicates
        const uniqueClientIds = [...new Set(coachDoc.data().clients)];
        console.log('Unique client IDs:', uniqueClientIds);
        
        const clientPromises = uniqueClientIds.map((clientId) => 
          getDoc(doc(db, 'users', clientId))
        );
        const clientDocs = await Promise.all(clientPromises);
        
        const filteredClients = clientDocs
          .filter(doc => doc.exists())
          .map(doc => ({ id: doc.id, ...doc.data() }));
        
        console.log('Filtered clients:', filteredClients);
        setClients(filteredClients);
      } else {
        console.log('No clients found, setting empty array');
        setClients([]);
      }
    } catch (error) {
      console.error('Error loading clients:', error);
      alert('Error loading clients: ' + error.message);
    }
  };

  const fetchSessions = async () => {
    try {
      const sessionsCollection = collection(db, 'users', clientId, 'sessions');
      const q = query(sessionsCollection, orderBy('date', 'desc'));
      const querySnapshot = await getDocs(q);

      const fetchedSessions = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setSessions(fetchedSessions);
    } catch (error) {
      console.error('Error fetching sessions:', error);
      alert('Error fetching sessions: ' + error.message);
    }
  };

  const handleSendMessage = async () => {
    if (message.trim()) {
      try {
        // Create message document
        const messageDoc = {
          content: `${coachFirstName} ${message}`,
          sender_name: "coach",
          is_system_message: false,
          date_sent: new Date(),
          media_url: "",
          source: "web"
        };

        // Add message to user's messages collection
        const messageRef = collection(db, 'users', clientId, 'messages');
        await addDoc(messageRef, messageDoc);

        // Clear input
        setMessage('');
        
      } catch (error) {
        console.error('Error sending message:', error);
        alert('Error sending message: ' + error.message);
      }
    }
  };

  const handleAddPreference = async () => {
    if (newPreference.trim()) {
      try {
        const preferencesDocRef = doc(db, 'users', clientId, 'userKnowledge', 'preferences');
        const docSnapshot = await getDoc(preferencesDocRef);

        if (docSnapshot.exists()) {
          // Document exists, update it
          await updateDoc(preferencesDocRef, {
            foodPreferences: arrayUnion(newPreference),
          });
        } else {
          // Document doesn't exist, create it
          await setDoc(preferencesDocRef, {
            foodPreferences: [newPreference],
          });
        }

        setClientInfo((prevState) => ({
          ...prevState,
          preferences: [...prevState.preferences, newPreference],
        }));
        setNewPreference('');
        setIsAddingPreference(false);
      } catch (error) {
        console.error('Error adding preference:', error);
        alert('Error adding preference: ' + error.message);
      }
    }
  };

  const handleDeletePreference = async (preferenceToDelete) => {
    try {
      const preferencesDocRef = doc(db, 'users', clientId, 'userKnowledge', 'preferences');
      await updateDoc(preferencesDocRef, {
        foodPreferences: arrayRemove(preferenceToDelete),
      });
      // Update local state
      setClientInfo((prevState) => ({
        ...prevState,
        preferences: prevState.preferences.filter((pref) => pref !== preferenceToDelete),
      }));
    } catch (error) {
      console.error('Error deleting preference:', error);
      alert('Error deleting preference: ' + error.message);
    }
  };

  const handleUpdateGoal = async () => {
    if (newGoal.trim()) {
      try {
        const weeklyGoalsDocRef = doc(
          db,
          'users',
          clientId,
          'userKnowledge',
          'healthGoals',
          'goalsByTime',
          'weeklyGoals'
        );
  
        const goalKey = 'Goal 1';
  
        // Update or set the goal in Firestore
        await setDoc(
          weeklyGoalsDocRef,
          {
            [goalKey]: newGoal,
          },
          { merge: true } // This ensures that only the specified field is updated
        );
  
        // Update the local state to reflect the new goal
        setClientInfo((prevState) => ({
          ...prevState,
          goals: [{ key: goalKey, value: newGoal }],
        }));
        setNewGoal('');
        setIsUpdatingGoal(false);
      } catch (error) {
        console.error('Error updating goal:', error);
        alert('Error updating goal: ' + error.message);
      }
    }
  };

  const handleDeleteGoal = async (goalKey) => {
    try {
      const weeklyGoalsDocRef = doc(
        db,
        'users',
        clientId,
        'userKnowledge',
        'healthGoals',
        'goalsByTime',
        'weeklyGoals'
      );
      await updateDoc(weeklyGoalsDocRef, {
        [goalKey]: deleteField(),
      });
      // Update local state
      setClientInfo((prevState) => ({
        ...prevState,
        goals: prevState.goals.filter((goalObj) => goalObj.key !== goalKey),
      }));
    } catch (error) {
      console.error('Error deleting goal:', error);
      alert('Error deleting goal: ' + error.message);
    }
  };

  const handleManageNudges = () => {
    setShowNudgeManager(true);
  };

  const handleTrackAdherence = async () => {
    await checkIfTrackedToday();
    setIsAdherenceModalOpen(true);
  };

  const handleAdherenceSubmit = async (adherenceValue) => {
    try {
      const today = format(new Date(), 'yyyy-MM-dd');
  
      // Prepare adherence data for coach
      const coachAdherenceData = {
        date: today,
        adherenceValue: adherenceValue,
        clientId: clientId,
      };
  
      // Write to coach's Firestore
      const coachAdherenceRef = collection(db, 'coaches', user.uid, 'adherence');
      const q = query(
        coachAdherenceRef,
        where('date', '==', today),
        where('clientId', '==', clientId),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        // Update existing document
        const docRef = querySnapshot.docs[0].ref;
        await updateDoc(docRef, coachAdherenceData);
      } else {
        // Create new document
        await addDoc(coachAdherenceRef, coachAdherenceData);
      }
  
      // Prepare adherence data for client
      const clientAdherenceData = {
        date: today,
        adherenceValue: adherenceValue,
        coachId: user.uid,
      };
  
      // Write to client's Firestore
      const clientAdherenceDocRef = doc(db, 'users', clientId, 'adherence', today);
      await setDoc(clientAdherenceDocRef, clientAdherenceData);
  
      // Update state
      setHasTrackedToday(true);
      setCurrentAdherenceValue(adherenceValue);
      setPopupMessage('Adherence tracked successfully!');
      setShowPopup(true);
    } catch (error) {
      console.error('Error saving adherence data:', error);
      alert('Error saving adherence data: ' + error.message);
    }
  };

  const checkIfTrackedToday = async () => {
    try {
      const today = format(new Date(), 'yyyy-MM-dd');
      const coachAdherenceRef = collection(db, 'coaches', user.uid, 'adherence');
      const q = query(
        coachAdherenceRef,
        where('date', '==', today),
        where('clientId', '==', clientId),
        limit(1)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const docData = querySnapshot.docs[0].data();
        setHasTrackedToday(true);
        setCurrentAdherenceValue(docData.adherenceValue);
      } else {
        setHasTrackedToday(false);
        setCurrentAdherenceValue(null);
      }
    } catch (error) {
      console.error('Error checking adherence for today:', error);
      alert('Error checking adherence for today: ' + error.message);
    }
  };

  useEffect(() => {
    const fetchSessionData = async () => {
      if (selectedSessionId) {
        try {
          const sessionDocRef = doc(db, 'users', clientId, 'sessions', selectedSessionId);
          const docSnapshot = await getDoc(sessionDocRef);
          if (docSnapshot.exists()) {
            setSessionData({ id: docSnapshot.id, ...docSnapshot.data() });
          } else {
            console.error('Session document does not exist!');
          }
        } catch (error) {
          console.error('Error fetching session data:', error);
          alert('Error fetching session data: ' + error.message);
        }
      } else {
        setSessionData(null);
      }
    };
    fetchSessionData();
  }, [selectedSessionId]);

  const handleSaveSession = async () => {
    if (!sessionData || !sessionData.note || sessionData.note.trim() === '') {
      setPopupMessage('Session note required to save session.');
      setShowPopup(true);
      return;
    }
    try {
      if (isAddingSession) {
        // Create new session
        const newSessionRef = await addDoc(collection(db, 'users', clientId, 'sessions'), {
          date: new Date().toISOString(),
          note: sessionData.note,
          insights: newInsights || null,
        });
        setSessions([
          { id: newSessionRef.id, date: new Date().toISOString(), note: sessionData.note, insights: newInsights || null, },
          ...sessions,
        ]);
        setSelectedSessionId(newSessionRef.id);
        setIsAddingSession(false);
        setPopupMessage('Session saved successfully!');
        setShowPopup(true);
  
        try {
          // Only send recap message for new sessions
          const sendMessageToClientCallable = httpsCallable(functions, 'sendMessageToClient');
          const recapSuccess = await processSessionRecap(clientId, sendMessageToClientCallable);
          if (!recapSuccess) {
            console.log('Recap message failed but session was saved');
          }
        } catch (recapError) {
          console.error('Error sending recap message:', recapError);
          // Don't block the session save if recap fails
        }
      } else if (selectedSessionId) {
        const sessionDocRef = doc(db, 'users', clientId, 'sessions', selectedSessionId);
        await updateDoc(sessionDocRef, {
          note: sessionData.note,
          insights: newInsights || sessionData.insights || null,
        });
        // Update local sessions array
      setSessions((prevSessions) =>
        prevSessions.map((session) =>
          session.id === selectedSessionId
            ? {
                ...session,
                note: sessionData.note,
                insights: newInsights || sessionData.insights || null,
              }
            : session
        )
      );
      setPopupMessage('Session updated successfully!');
      setShowPopup(true);
    }

    // After saving, merge newInsights into sessionData and reset newInsights
    setSessionData((prevData) => ({
      ...prevData,
      insights: newInsights || prevData.insights || null,
    }));
    setNewInsights(null); // Reset newInsights after saving
  } catch (error) {
    console.error('Error saving session:', error);
    setPopupMessage('Error saving session. Please try again.');
    setShowPopup(true);
  }
};

useEffect(() => {
  console.log('Clients state changed:', clients);
}, [clients]);

return (
  <div className="h-full flex flex-col p-4 md:p-6 space-y-4 md:space-y-6 mt-16 mb-24 md:mb-0">
    {/* Header Section */}
    <div className="flex flex-col md:flex-row md:items-center md:justify-between">
      <div className="space-y-1">
        <h1 className="text-xl md:text-[2rem] font-headers font-bold text-text-primary">
          {clientInfo?.name || 'Loading...'}
        </h1>
        <p className="text-sm md:text-base text-text-light font-body">
          {clientInfo?.email || 'Loading...'}
        </p>
      </div>
    </div>

    {/* Navigation Tabs */}
    <div className="flex space-x-1 border-b border-border-light overflow-x-auto">
      <TabButton 
        active={activeTab === 'info'} 
        onClick={() => setActiveTab('info')}
      >
        Overview
      </TabButton>
      <TabButton 
        active={activeTab === 'nutrition'} 
        onClick={() => setActiveTab('nutrition')}
      >
        Nutrition
      </TabButton>
      <TabButton 
        active={activeTab === 'sessions'} 
        onClick={() => setActiveTab('sessions')}
      >
        Sessions
      </TabButton>
    </div>

    {/* Content Area - Added more padding for mobile */}
    <div className="flex-1 min-h-0 overflow-y-auto space-y-6 md:space-y-4 px-1 md:px-0">
      {activeTab === 'info' && (
        <ClientInfoTab clientId={clientId} clientInfo={clientInfo} />
      )}
      {activeTab === 'nutrition' && (
        <NutritionTab clientId={clientId} />
      )}
      {activeTab === 'sessions' && (
        <SessionTab clientId={clientId} clientInfo={clientInfo} />
      )}
    </div>
  </div>
);
};

// Tab Button Component
const TabButton = ({ active, children, onClick }) => (
  <button
    onClick={onClick}
    className={cn(
      "px-4 py-2 font-body font-medium transition-colors relative",
      "hover:text-accent-primary focus:outline-none",
      active ? [
        "text-accent-primary",
        "after:absolute after:bottom-0 after:left-0 after:right-0",
        "after:h-0.5 after:bg-accent-primary",
      ] : "text-text-light"
    )}
  >
    {children}
  </button>
);

export default ClientPage;
