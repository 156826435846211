// src/components/InjuryForm.js

import React from 'react';
import { DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from './ui/Dialog';
import { Input } from './ui/Input';
import { Button } from './ui/Button';
import { Label } from './ui/Label';
import { Calendar, AlertCircle } from 'lucide-react';
import { format } from 'date-fns';
import { Alert, AlertDescription } from "./ui/Alert";

const InjuryForm = ({ 
  isEditing,
  formData,
  onInputChange,
  onSubmit,
  onClose,
  isSubmitting = false,
  error = null
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Trim the name field only when submitting
    const trimmedFormData = {
      ...formData,
      name: formData.name.trim()
    };
    onSubmit(trimmedFormData);
  };

  const today = format(new Date(), 'yyyy-MM-dd');

  return (
    <DialogContent className={`
      sm:max-w-[600px] 
      bg-background-secondary
      border border-border-medium
      shadow-lg
      font-body
    `}>
      <DialogHeader className="space-y-2">
        <DialogTitle className={`
          font-headers text-h1 font-bold
          text-accent-primary
        `}>
          {isEditing ? 'Edit Injury' : 'Add New Injury'}
        </DialogTitle>
        <DialogDescription className="text-text-light font-body">
          {isEditing ? 'Update injury details' : 'Record a new injury'}
        </DialogDescription>
      </DialogHeader>

      {error && (
        <Alert variant="destructive" className="bg-red-50 border-red-200 text-red-700">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription className="font-body">{error}</AlertDescription>
        </Alert>
      )}

      <form onSubmit={handleSubmit} className="space-y-8">
        <div className="grid grid-cols-2 gap-6">
          <div className="space-y-3">
            <Label 
              htmlFor="name" 
              className="text-text-secondary font-medium font-body text-small"
            >
              Injury Name*
            </Label>
            <Input
              id="name"
              name="name"
              value={formData.name}
              onChange={(e) => onInputChange({ ...formData, name: e.target.value })}
              placeholder="e.g., Left Knee Strain"
              className={`
                bg-background-primary
                border-border-light
                text-text-primary
                placeholder-text-light
                focus:border-accent-primary
                hover:border-accent-secondary
                transition-all duration-300
                rounded-md
                font-body
              `}
              required
              maxLength={100}
            />
          </div>

          <div className="space-y-3">
            <Label 
              htmlFor="date" 
              className="text-text-secondary font-medium font-body text-small flex items-center gap-2"
            >
              <Calendar className="w-4 h-4 text-accent-primary" />
              Date of Injury*
            </Label>
            <Input
              id="date"
              type="date"
              name="date"
              value={formData.date}
              max={today}
              onChange={(e) => onInputChange({ ...formData, date: e.target.value })}
              className={`
                bg-background-primary
                border-border-light
                text-text-primary
                focus:border-accent-primary
                hover:border-accent-secondary
                transition-all duration-300
                rounded-md
                font-body
              `}
              required
            />
          </div>
        </div>

        <DialogFooter className="gap-4 pt-6">
          <Button
            type="button"
            variant="ghost"
            onClick={onClose}
            disabled={isSubmitting}
            className={`
              text-text-secondary
              hover:bg-background-tertiary
              font-body font-medium
              px-6 py-3 rounded-md
              transition-all duration-300
            `}
          >
            Cancel
          </Button>
          <Button 
            type="submit"
            disabled={isSubmitting}
            className={`
              bg-accent-primary
              text-white
              font-body font-medium
              px-6 py-3 rounded-md
              hover:bg-hover-primary
              transition-all duration-300
              disabled:opacity-50 
              disabled:cursor-not-allowed
              shadow-md hover:shadow-lg
            `}
          >
            {isSubmitting ? 'Saving...' : isEditing ? 'Update Injury' : 'Add Injury'}
          </Button>
        </DialogFooter>
      </form>
    </DialogContent>
  );
};

export default InjuryForm;