import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc, collection, query, getDocs, addDoc, deleteDoc, orderBy, serverTimestamp, writeBatch } from 'firebase/firestore';
import { db } from '../../firebase';
import { Button } from '../ui/Button';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/Card';
import { Input } from '../ui/Input';
import { Textarea } from '../ui/Textarea';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Plus, GripVertical, Edit2, Trash, Save, Lock, AlertCircle, X } from 'lucide-react';
import { Badge } from '../ui/Badge';
import QuestionEditor from './QuestionEditor';
import { Tooltip } from '../ui/Tooltip';

const FormEditor = ({ formId, onUpdate }) => {
  const [form, setForm] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [formTitle, setFormTitle] = useState('');
  const [formDescription, setFormDescription] = useState('');
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [callToAction, setCallToAction] = useState('');
  const [editingQuestionId, setEditingQuestionId] = useState(null);
  const [error, setError] = useState('');
  
  // Fetch form data
  useEffect(() => {
    const fetchFormData = async () => {
      setLoading(true);
      try {
        // Get form data
        const formRef = doc(db, 'onboardingForms', formId);
        const formDoc = await getDoc(formRef);
        
        if (formDoc.exists()) {
          const formData = formDoc.data();
          setForm(formData);
          setFormTitle(formData.title || '');
          setFormDescription(formData.description || '');
          setWelcomeMessage(formData.welcomeMessage || '');
          setCallToAction(formData.callToAction || '');
          
          // Get questions
          const questionsQuery = query(
            collection(db, 'onboardingForms', formId, 'questions'),
            orderBy('order')
          );
          
          const questionsSnapshot = await getDocs(questionsQuery);
          const questionsList = questionsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          
          setQuestions(questionsList);
        } else {
          setError('Form not found');
        }
      } catch (error) {
        console.error('Error fetching form data:', error);
        setError('Failed to load form. Please try again.');
      } finally {
        setLoading(false);
      }
    };
    
    if (formId) {
      fetchFormData();
    }
  }, [formId]);
  
  // Save form details
  const saveFormDetails = async () => {
    if (!formId) return;
    
    setSaving(true);
    try {
      const formRef = doc(db, 'onboardingForms', formId);
      
      // Use serverTimestamp for the database update
      const updatedFormData = {
        title: formTitle,
        updatedAt: serverTimestamp()
      };
      
      await updateDoc(formRef, updatedFormData);
      
      // Use a JavaScript Date for the local state update
      const localStateUpdate = {
        title: formTitle,
        updatedAt: new Date() // Use JavaScript Date for local state
      };
      
      setForm({ ...form, ...localStateUpdate });
      
      // Notify parent component of update with the JavaScript Date
      if (onUpdate) {
        onUpdate(localStateUpdate);
      }
    } catch (error) {
      console.error('Error saving form details:', error);
      setError('Failed to save form details. Please try again.');
    } finally {
      setSaving(false);
    }
  };
  
  // Add new question
  const addNewQuestion = async () => {
    try {
      const questionsRef = collection(db, 'onboardingForms', formId, 'questions');
      
      const newQuestion = {
        order: questions.length,
        step: Math.min(questions.length, 4), // Default to the next step, max 4
        title: 'New Question',
        description: 'Question description',
        type: 'text',
        isRequired: true,
        options: [],
        saveToField: '',
        validations: {}
      };
      
      const docRef = await addDoc(questionsRef, newQuestion);
      
      const newQuestionWithId = { id: docRef.id, ...newQuestion };
      setQuestions([...questions, newQuestionWithId]);
      setEditingQuestionId(docRef.id);
    } catch (error) {
      console.error('Error adding question:', error);
      setError('Failed to add question. Please try again.');
    }
  };
  
  // Delete question
  const deleteQuestion = async (questionId) => {
    try {
      // Find the question
      const questionToDelete = questions.find(q => q.id === questionId);
      
      // Check if it's a default question that cannot be removed
      if (questionToDelete.isDefault && !questionToDelete.isRemovable) {
        setError('This question is required and cannot be deleted.');
        return;
      }
      
      // Delete question document
      await deleteDoc(doc(db, 'onboardingForms', formId, 'questions', questionId));
      
      // Update questions state
      const updatedQuestions = questions.filter(q => q.id !== questionId);
      
      // Reorder remaining questions
      const reorderedQuestions = updatedQuestions.map((q, index) => ({
        ...q,
        order: index
      }));
      
      // Update state
      setQuestions(reorderedQuestions);
      
      // Update order in Firestore for all remaining questions
      reorderedQuestions.forEach(async (question) => {
        const questionRef = doc(db, 'onboardingForms', formId, 'questions', question.id);
        await updateDoc(questionRef, { order: question.order });
      });
      
      // If we were editing this question, reset
      if (editingQuestionId === questionId) {
        setEditingQuestionId(null);
      }
    } catch (error) {
      console.error('Error deleting question:', error);
      setError('Failed to delete question. Please try again.');
    }
  };
  
  // Update question
  const updateQuestion = async (questionId, updatedData) => {
    try {
      // Update question in Firestore
      const questionRef = doc(db, 'onboardingForms', formId, 'questions', questionId);
      await updateDoc(questionRef, updatedData);
      
      // Update local state
      setQuestions(questions.map(q => 
        q.id === questionId ? { ...q, ...updatedData } : q
      ));
    } catch (error) {
      console.error('Error updating question:', error);
      setError('Failed to update question. Please try again.');
    }
  };
  
  // Handle drag and drop reordering
  const handleDragEnd = async (result) => {
    if (!result.destination) return;
    
    const { source, destination } = result;
    
    // If dropped in the same position, do nothing
    if (source.index === destination.index) return;
    
    // Reorder locally
    const reorderedQuestions = Array.from(questions);
    const [removed] = reorderedQuestions.splice(source.index, 1);
    reorderedQuestions.splice(destination.index, 0, removed);
    
    // Update order for all
    const updatedQuestions = reorderedQuestions.map((q, index) => ({
      ...q,
      order: index
    }));
    
    // Update state immediately for responsive UI
    setQuestions(updatedQuestions);
    
    // Update in Firestore
    try {
      // Use writeBatch from Firebase
      const batch = writeBatch ? writeBatch(db) : null;
      
      for (const question of updatedQuestions) {
        const questionRef = doc(db, 'onboardingForms', formId, 'questions', question.id);
        if (batch) {
          batch.update(questionRef, { order: question.order });
        } else {
          await updateDoc(questionRef, { order: question.order });
        }
      }
      
      if (batch) {
        await batch.commit();
      }
    } catch (error) {
      console.error('Error updating question order:', error);
      setError('Failed to update question order. Please try again.');
      
      // Revert to original order on error
      setQuestions(questions);
    }
  };
  
  // Render the list of questions
  const renderQuestions = () => {
    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="questions">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="space-y-4"
            >
              {questions.map((question, index) => (
                <Draggable
                  key={question.id}
                  draggableId={question.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className={`border rounded-lg p-4 bg-white shadow-sm ${
                        question.isDefault ? 'border-l-4 border-l-primary' : ''
                      } ${snapshot.isDragging ? 'opacity-70' : ''}`}
                      style={{
                        ...provided.draggableProps.style
                      }}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          <div {...provided.dragHandleProps} className="cursor-move">
                            <GripVertical className="h-5 w-5 text-gray-400" />
                          </div>
                          <div>
                            <div className="flex items-center space-x-2">
                              <h3 className="font-medium">{question.title}</h3>
                              {question.isDefault && (
                                <Tooltip content="This is a required question for the AI algorithm">
                                  <Badge variant="outline" className="bg-primary/10 text-primary border-primary/20">
                                    <AlertCircle className="h-3 w-3 mr-1" />
                                    Required
                                  </Badge>
                                </Tooltip>
                              )}
                              <Badge variant="outline" className="text-xs">
                                Step {question.step + 1}
                              </Badge>
                            </div>
                            <p className="text-sm text-gray-500 mt-1">
                              {question.description}
                            </p>
                          </div>
                        </div>
                        <div className="flex space-x-2">
                          <Button
                            size="sm"
                            variant="ghost"
                            onClick={() => setEditingQuestionId(question.id)}
                          >
                            <Edit2 className="h-4 w-4 mr-1" />
                            Edit
                          </Button>
                          {(!question.isDefault || question.isRemovable) ? (
                            <Button
                              size="sm"
                              variant="ghost"
                              className="text-destructive"
                              onClick={() => deleteQuestion(question.id)}
                            >
                              <Trash className="h-4 w-4 mr-1" />
                              Delete
                            </Button>
                          ) : (
                            <Tooltip content="This question is required and cannot be deleted">
                              <Button
                                size="sm"
                                variant="ghost"
                                className="text-gray-400 cursor-not-allowed"
                                disabled
                              >
                                <Lock className="h-4 w-4 mr-1" />
                                Locked
                              </Button>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };
  
  if (loading) {
    return (
      <Card>
        <CardContent className="flex justify-center items-center py-12">
          <div className="animate-spin h-8 w-8 border-4 border-accent-primary border-t-transparent rounded-full"></div>
        </CardContent>
      </Card>
    );
  }
  
  if (error && !form) {
    return (
      <Card>
        <CardContent className="py-6">
          <div className="text-red-500 text-center">{error}</div>
        </CardContent>
      </Card>
    );
  }
  
  return (
    <div className="space-y-6">
      {/* Form Details */}
      <Card>
        <CardHeader>
          <CardTitle>Form Details</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-text-primary mb-1">
                Form Title
              </label>
              <Input
                value={formTitle}
                onChange={(e) => setFormTitle(e.target.value)}
                placeholder="Enter form title"
                className="w-full"
              />
            </div>
            
            <div className="flex justify-end">
              <Button
                variant="primary"
                onClick={saveFormDetails}
                disabled={saving}
                className="flex items-center gap-2"
              >
                {saving ? <div className="animate-spin h-4 w-4 border-2 border-white border-t-transparent rounded-full"></div> : <Save className="h-4 w-4" />}
                Save Details
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
      
      {/* Questions Section */}
      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>Form Questions</CardTitle>
          <Button 
            variant="secondary" 
            size="sm"
            onClick={addNewQuestion}
            className="flex items-center gap-2"
          >
            <Plus className="h-4 w-4" />
            Add Question
          </Button>
        </CardHeader>
        <CardContent>
          {questions.length === 0 ? (
            <div className="text-center py-8">
              <p className="text-text-light mb-4">No questions added yet</p>
              <Button 
                variant="primary"
                onClick={addNewQuestion}
                className="flex items-center gap-2 mx-auto"
              >
                <Plus className="h-4 w-4" />
                Add Your First Question
              </Button>
            </div>
          ) : (
            renderQuestions()
          )}
        </CardContent>
      </Card>
      
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
          <span className="block sm:inline">{error}</span>
          <span 
            className="absolute top-0 bottom-0 right-0 px-4 py-3 cursor-pointer"
            onClick={() => setError('')}
          >
            <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
            </svg>
          </span>
        </div>
      )}
      
      {/* Question Editor Modal */}
      {editingQuestionId && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto">
            <div className="p-4 border-b flex justify-between items-center">
              <h2 className="text-lg font-medium">Edit Question</h2>
              <Button 
                variant="ghost" 
                size="icon" 
                onClick={() => setEditingQuestionId(null)}
              >
                <X className="h-5 w-5" />
              </Button>
            </div>
            <div className="p-4">
              <QuestionEditor 
                question={questions.find(q => q.id === editingQuestionId) || {}}
                onUpdate={(updatedQuestion) => {
                  updateQuestion(editingQuestionId, updatedQuestion);
                  setEditingQuestionId(null);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormEditor; 