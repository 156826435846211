import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { Input } from '../../../ui/Input';

/**
 * TagInput component that allows users to enter items and press Enter to add them as tags
 * Used for food preferences/dislikes and other list-based inputs
 */
const TagInput = ({ value = [], onChange, placeholder = 'Type and press Enter...' }) => {
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState('');

  // Initialize tags from value prop
  useEffect(() => {
    if (Array.isArray(value)) {
      setTags(value);
    } else if (typeof value === 'string' && value.trim() !== '') {
      // Parse comma-separated string into array if needed
      setTags(value.split(',').map(item => item.trim()).filter(item => item !== ''));
    } else {
      setTags([]);
    }
  }, []);

  // Handle input change
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // Handle key down events (Enter to add tag, Backspace to remove last tag)
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      e.preventDefault();
      addTag(inputValue);
    } else if (e.key === 'Backspace' && inputValue === '' && tags.length > 0) {
      // Remove the last tag if backspace is pressed and input is empty
      removeTag(tags.length - 1);
    }
  };

  // Add a new tag
  const addTag = (tag) => {
    const trimmedTag = tag.trim();
    if (trimmedTag !== '' && !tags.includes(trimmedTag)) {
      const newTags = [...tags, trimmedTag];
      setTags(newTags);
      onChange(newTags); // Notify parent component
      setInputValue(''); // Clear input
    }
  };

  // Remove a tag by index
  const removeTag = (indexToRemove) => {
    const newTags = tags.filter((_, index) => index !== indexToRemove);
    setTags(newTags);
    onChange(newTags); // Notify parent component
  };

  return (
    <div className="w-full">
      <div className="flex flex-wrap gap-2 mb-2">
        {tags.map((tag, index) => (
          <div
            key={index}
            className="flex items-center gap-1 bg-primary/10 text-primary px-3 py-1 rounded-full text-sm"
          >
            <span>{tag}</span>
            <button
              type="button"
              onClick={() => removeTag(index)}
              className="hover:text-primary-dark focus:outline-none"
              aria-label={`Remove ${tag}`}
            >
              <X size={14} />
            </button>
          </div>
        ))}
      </div>

      <Input
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        className="w-full"
        aria-label="Add item"
      />

      <p className="text-text-light text-sm mt-2">
        Type an item and press Enter to add it to your list
      </p>
    </div>
  );
};

export default TagInput; 