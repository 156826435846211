// src/components/Login.jsx

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { User, Lock, Hexagon } from 'lucide-react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from '../firebase';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/dashboard');
    } catch (error) {
      console.error('Error logging in:', error);
      alert('Error logging in: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 flex items-center justify-center px-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md"
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="text-center mb-12"
        >
          <Hexagon className="w-12 h-12 text-indigo-500 mx-auto mb-4" />
          <h1 className="text-5xl font-bold text-white mb-2 tracking-tight">Evolv.</h1>
          <p className="text-gray-400 text-lg">Own Your Story</p>
        </motion.div>

        <form onSubmit={handleLogin} className="space-y-8">
          <div className="relative">
            <User className="absolute top-3 left-0 text-gray-500" size={20} />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full pl-8 pb-3 text-lg bg-transparent border-b border-gray-700 focus:border-indigo-500 text-white placeholder-gray-500 focus:outline-none transition duration-300"
              placeholder="Email"
              required
            />
          </div>
          <div className="relative">
            <Lock className="absolute top-3 left-0 text-gray-500" size={20} />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full pl-8 pb-3 text-lg bg-transparent border-b border-gray-700 focus:border-indigo-500 text-white placeholder-gray-500 focus:outline-none transition duration-300"
              placeholder="Password"
              required
            />
          </div>

          <motion.button
            whileHover={{ scale: 1.02, boxShadow: "0 0 20px rgba(79, 70, 229, 0.4)" }}
            whileTap={{ scale: 0.98 }}
            type="submit"
            disabled={isLoading}
            className="w-full bg-indigo-600 text-white py-2.5 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 transition duration-300 ease-in-out transform hover:-translate-y-1 shadow-lg text-lg font-semibold mt-8"
          >
            {isLoading ? 'Signing in...' : 'Sign In'}
          </motion.button>
        </form>

        <div className="mt-8 text-center">
          <a href="#" className="text-sm text-gray-400 hover:text-indigo-400 transition duration-300">
            Forgot password?
          </a>
        </div>
        <div className="mt-6 text-center">
          <p className="text-sm text-gray-500">
            Don't have an account?{' '}
            <Link to="/signup" className="text-indigo-400 hover:text-indigo-300 transition duration-300">
              Create Account
            </Link>
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default Login;
