// src/components/Signup.jsx

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { User, Lock, Mail, Hexagon } from 'lucide-react';
import { createUserWithEmailAndPassword, signOut } from 'firebase/auth';
import { doc, serverTimestamp, writeBatch } from 'firebase/firestore';
import { useNavigate, Link } from 'react-router-dom';
import { auth, db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { signInWithGoogle } = useAuth();
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    let userCredential = null;
    try {
      // Create user in Firebase Auth
      userCredential = await createUserWithEmailAndPassword(auth, email, password);

      // Proceed with Firestore logic
      const batch = writeBatch(db);
      const coachRef = doc(db, 'coaches', userCredential.user.uid);
      const messageStyleRef = doc(db, 'coaches', userCredential.user.uid, 'philosophy', 'messageStyleSettings');

      batch.set(coachRef, {
        email: userCredential.user.email,
        isCoach: true,
        isAdmin: false,
        subscriptionStatus: 'inactive',
        subscriptionQuantity: 0,
        numberOfClients: 0,
        clients: [],
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        stripeCustomerId: '',
        hasHadTrial: false,
        firstTime: true,
        firstTimeDashboard: true,
      });

      batch.set(messageStyleRef, {
        toneStyle: ['Encouraging', 'Professional'],
        emojiUse: false,
        grammarLevel: 'Perfect Grammar',
        languageStyle: 'Motivational',
        exampleConversation: {},
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });

      // Commit Firestore batch
      await batch.commit();

      // Navigate to welcome screen instead of directly to setup
      navigate('/onboarding');
    } catch (error) {
      console.error('Error signing up:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignup = async () => {
    setError('');
    setIsLoading(true);
    try {
      const { user, needsOnboarding } = await signInWithGoogle();
      
      // Navigate based on whether onboarding is needed
      if (needsOnboarding) {
        navigate('/onboarding');
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error signing up with Google:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-background-primary flex items-center justify-center px-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md"
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="text-center mb-12"
        >
          <Hexagon className="w-16 h-16 text-accent-primary mx-auto mb-6" />
          <h1 className="text-6xl font-light text-text-primary mb-3 tracking-tight font-headers">
            Join Evolv<span className="text-accent-primary">.</span>
          </h1>
          <p className="text-text-light text-lg font-body font-light">Begin Your Journey</p>
        </motion.div>

        {error && (
          <motion.div 
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            className="mb-6 p-4 rounded-lg bg-red-50 border border-red-200 text-red-600 font-body text-sm"
          >
            {error}
          </motion.div>
        )}

        {/* Google Sign-In Button */}
        <motion.button
          whileHover={{ scale: 1.02, boxShadow: "0 8px 20px rgba(66, 133, 244, 0.2)" }}
          whileTap={{ scale: 0.98 }}
          onClick={handleGoogleSignup}
          disabled={isLoading}
          className="w-full flex items-center justify-center mb-6 bg-white text-gray-700 py-3 px-4 rounded-lg 
                    border border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 
                    focus:ring-blue-500 focus:ring-opacity-50 
                    transition-all duration-300 transform shadow-md 
                    text-lg font-medium font-body"
        >
          {isLoading ? (
            <span className="flex items-center justify-center">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Signing in...
            </span>
          ) : (
            <>
              <svg className="w-5 h-5 mr-3" viewBox="0 0 24 24">
                <path
                  fill="#4285F4"
                  d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                />
                <path
                  fill="#34A853"
                  d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                />
                <path
                  fill="#FBBC05"
                  d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                />
                <path
                  fill="#EA4335"
                  d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                />
                <path fill="none" d="M1 1h22v22H1z" />
              </svg>
              Continue with Google
            </>
          )}
        </motion.button>

        <div className="relative flex items-center justify-center mb-6">
          <div className="flex-grow border-t border-text-light/20"></div>
          <span className="flex-shrink mx-4 text-text-light">or</span>
          <div className="flex-grow border-t border-text-light/20"></div>
        </div>

        <form onSubmit={handleSignup} className="space-y-8">
          <div className="relative group">
            <Mail className="absolute top-3 left-0 text-text-light group-focus-within:text-accent-primary transition-colors" size={20} />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full pl-8 pb-3 text-lg bg-transparent border-b-2 border-text-light/20 
                       focus:border-accent-primary text-text-primary placeholder-text-light/50 
                       focus:outline-none transition-all duration-300 font-body"
              placeholder="Email"
              required
            />
          </div>
          <div className="relative group">
            <Lock className="absolute top-3 left-0 text-text-light group-focus-within:text-accent-primary transition-colors" size={20} />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full pl-8 pb-3 text-lg bg-transparent border-b-2 border-text-light/20 
                       focus:border-accent-primary text-text-primary placeholder-text-light/50 
                       focus:outline-none transition-all duration-300 font-body"
              placeholder="Password"
              required
            />
          </div>
          
          <motion.button
            whileHover={{ scale: 1.02, boxShadow: "0 8px 20px rgba(196, 132, 95, 0.2)" }}
            whileTap={{ scale: 0.98 }}
            type="submit"
            disabled={isLoading}
            className="w-full bg-accent-primary text-white py-3 rounded-lg 
                     hover:bg-hover-primary focus:outline-none focus:ring-2 
                     focus:ring-accent-primary focus:ring-opacity-50 
                     transition-all duration-300 transform shadow-lg 
                     text-lg font-medium font-body mt-8"
          >
            {isLoading ? (
              <span className="flex items-center justify-center">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Creating Account...
              </span>
            ) : (
              'Create Account'
            )}
          </motion.button>
        </form>

        <div className="mt-8 text-center">
          <p className="text-sm text-text-light font-body">
            Already have an account?{' '}
            <Link 
              to="/" 
              className="text-accent-primary hover:text-hover-primary transition-colors duration-300 font-medium"
            >
              Sign In
            </Link>
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default Signup;