import React, { useState, useEffect } from 'react';
import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { parseISO, startOfWeek, formatISO, format, subWeeks, addWeeks, eachWeekOfInterval, max, min, isThisWeek } from 'date-fns';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts';

const AdherenceWidget = ({ coachId }) => {
  const [adherencePercentage, setAdherencePercentage] = useState(0);
  const [currentWeekAdherence, setCurrentWeekAdherence] = useState(null);
  const [totalSessions, setTotalSessions] = useState(0);
  const [weeklyAdherence, setWeeklyAdherence] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAdherenceData = async () => {
      try {
        const adherenceRef = collection(db, 'coaches', coachId, 'adherence');
        const q = query(adherenceRef);
        const querySnapshot = await getDocs(q);
        const adherenceDocs = querySnapshot.docs.map((doc) => doc.data());

        // Compute all-time adherence and total sessions
        const total = adherenceDocs.length;
        const yesCount = adherenceDocs.filter(
          (doc) => doc.adherenceValue === 'yes'
        ).length;
        const percentage = total > 0 ? Math.round((yesCount / total) * 1000) / 10 : 0;
        setAdherencePercentage(percentage.toFixed(1));
        setTotalSessions(total);

        // Group adherence data by week
        const weeklyData = {};
        let currentWeekTotal = 0;
        let currentWeekYes = 0;

        adherenceDocs.forEach((doc) => {
          const date = parseISO(doc.date);
          const weekStart = startOfWeek(date, { weekStartsOn: 1 });
          const weekStartStr = formatISO(weekStart, { representation: 'date' });

          if (!weeklyData[weekStartStr]) {
            weeklyData[weekStartStr] = { total: 0, yes: 0 };
          }
          weeklyData[weekStartStr].total += 1;
          if (doc.adherenceValue === 'yes') {
            weeklyData[weekStartStr].yes += 1;
          }

          // Calculate current week adherence
          if (isThisWeek(date, { weekStartsOn: 1 })) {
            currentWeekTotal++;
            if (doc.adherenceValue === 'yes') {
              currentWeekYes++;
            }
          }
        });

        // Set current week adherence
        if (currentWeekTotal > 0) {
          setCurrentWeekAdherence((Math.round((currentWeekYes / currentWeekTotal * 1000)) / 10).toFixed(1));
        }

        // Determine the date range for the graph
        const dataWeeks = Object.keys(weeklyData).map(date => new Date(date));
        const latestDataWeek = max(dataWeeks);
        const earliestDataWeek = min(dataWeeks);
        
        const graphStart = subWeeks(earliestDataWeek, 2);
        const graphEnd = addWeeks(latestDataWeek, 2);

        // Generate weeks for display
        const weekStarts = eachWeekOfInterval(
          { start: graphStart, end: graphEnd },
          { weekStartsOn: 1 }
        );

        // Calculate adherence percentage per week
        const weeklyAdherenceArray = weekStarts.map((weekStart) => {
          const weekStartStr = formatISO(weekStart, { representation: 'date' });
          const { total = 0, yes = 0 } = weeklyData[weekStartStr] || {};
          const percentage = total > 0 ? Math.round((yes / total) * 1000) / 10 : null;
          return {
            weekStart: weekStartStr,
            percentage: percentage !== null ? parseFloat(percentage.toFixed(1)) : null,
          };
        });

        setWeeklyAdherence(weeklyAdherenceArray);
      } catch (error) {
        console.error('Error fetching adherence data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAdherenceData();
  }, [coachId]);

  if (loading) {
    return <div>Loading adherence data...</div>;
  }

  return (
    <div className="text-[#E0E0E0]">
      <div className="grid grid-cols-3 gap-4 mb-6">
        <div className="bg-[#2C2C2C] p-4 rounded-lg">
          <h3 className="text-lg mb-2">All-time Adherence</h3>
          <p className="text-2xl font-bold">{adherencePercentage}%</p>
        </div>
        <div className="bg-[#2C2C2C] p-4 rounded-lg">
          <h3 className="text-lg mb-2">Current Week</h3>
          <p className="text-2xl font-bold">{currentWeekAdherence ? `${currentWeekAdherence}%` : 'N/A'}</p>
        </div>
        <div className="bg-[#2C2C2C] p-4 rounded-lg">
          <h3 className="text-lg mb-2">Total Sessions</h3>
          <p className="text-2xl font-bold">{totalSessions}</p>
        </div>
      </div>
      <div className="bg-[#2C2C2C] p-4 rounded-lg">
        <h3 className="text-lg font-semibold mb-4">Weekly Adherence Trend</h3>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={weeklyAdherence}
            margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#444" />
            <XAxis
              dataKey="weekStart"
              tickFormatter={(tick) => format(new Date(tick), 'MMM')}
              stroke="#E0E0E0"
              tick={{ fill: '#E0E0E0' }}
              ticks={weeklyAdherence.filter((_, i) => i % 4 === 0).map(w => w.weekStart)}
            />
            <YAxis 
              domain={[0, 100]} 
              stroke="#E0E0E0"
              tick={{ fill: '#E0E0E0' }}
          />
          <Tooltip
            labelFormatter={(label) => `Week of ${format(new Date(label), 'MMM d, yyyy')}`}
            formatter={(value) => value !== null ? `${value}%` : 'No data'}
            contentStyle={{ backgroundColor: '#333', border: 'none' }}
          />
          <ReferenceLine y={75} stroke="#888" strokeDasharray="3 3" />
          <Line
            type="monotone"
            dataKey="percentage"
            stroke="#6495ED"
            strokeWidth={2}
            dot={{ r: 4, fill: '#6495ED' }}
            activeDot={{ r: 6, fill: '#4F7BE8' }}
            connectNulls
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  </div>
  );
};

export default AdherenceWidget;