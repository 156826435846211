import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../../ui/Card';
import { Input } from '../../ui/Input';
import { Textarea } from '../../ui/Textarea';
import { Button } from '../../ui/Button';
import { Plus, Trash, Star } from 'lucide-react';
import RichTextEditor from '../../ui/RichTextEditor';

const TestimonialsTab = ({
  testimonials,
  addTestimonial,
  removeTestimonial,
  updateTestimonial
}) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex justify-between items-center">
          <span>Testimonials</span>
          <Button 
            variant="outline" 
            size="sm" 
            onClick={addTestimonial}
            className="flex items-center gap-1 text-xs"
          >
            <Plus className="h-3 w-3" />
            Add Testimonial
          </Button>
        </CardTitle>
      </CardHeader>
      <CardContent>
        {testimonials.length === 0 ? (
          <div className="text-center p-8 border border-dashed border-border-light rounded-lg">
            <p className="text-text-light mb-4">No testimonials added yet</p>
            <Button 
              variant="outline" 
              size="sm" 
              onClick={addTestimonial}
              className="flex items-center gap-1 mx-auto"
            >
              <Plus className="h-4 w-4" />
              Add Testimonial
            </Button>
          </div>
        ) : (
          <div className="space-y-6">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="p-6 border border-border-light rounded-lg relative">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => removeTestimonial(index)}
                  className="absolute right-2 top-2 p-1 h-8 w-8 flex items-center justify-center text-red-500 hover:bg-red-50"
                >
                  <Trash className="h-4 w-4" />
                </Button>
                
                <div className="mb-4">
                  <label className="block text-text-light mb-2">Client Name</label>
                  <Input 
                    value={testimonial.name}
                    onChange={(e) => updateTestimonial(index, 'name', e.target.value)}
                    placeholder="e.g., John Smith"
                    className="max-w-xs"
                  />
                </div>
                
                <div className="mb-4">
                  <label className="block text-text-light mb-2">Testimonial Text</label>
                  <RichTextEditor 
                    value={testimonial.text}
                    onChange={(value) => {
                      console.log(`[DEBUG] Updating testimonial text: "${value}"`);
                      console.log(`[DEBUG] Text has bold markers: ${value?.includes('**')}`);
                      updateTestimonial(index, 'text', value);
                    }}
                    placeholder="What did your client say about your services?"
                    rows={3}
                  />
                  <p className="text-xs text-text-light mt-1">
                    Select text to add bold, italic, or underline formatting
                  </p>
                </div>
                
                <div>
                  <label className="block text-text-light mb-2">Rating (1-5 stars)</label>
                  <div className="flex items-center gap-1">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <button
                        key={star}
                        type="button"
                        onClick={() => updateTestimonial(index, 'stars', star)}
                        className="focus:outline-none"
                      >
                        <Star
                          className={`w-6 h-6 ${star <= testimonial.stars ? 'text-yellow-400 fill-yellow-400' : 'text-gray-300'}`}
                        />
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default TestimonialsTab; 