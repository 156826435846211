
export const API_URLS = {
  // Client Management
  createClient: 'https://createclient-lkqk4idpeq-uc.a.run.app',
  removeClient: 'https://removeclient-lkqk4idpeq-uc.a.run.app',
  addClient: 'https://addclient-lkqk4idpeq-uc.a.run.app',

  // Stripe Functions
  createCheckoutSession: 'https://createcheckoutsession-lkqk4idpeq-uc.a.run.app',
  createPortalSession: 'https://createportalsession-lkqk4idpeq-uc.a.run.app',
  getProrationCost: 'https://getprorationcost-lkqk4idpeq-uc.a.run.app',
  verifyCheckoutSession: 'https://verifycheckoutsession-lkqk4idpeq-uc.a.run.app',
  uploadExercises: 'https://uploadexercises-lkqk4idpeq-uc.a.run.app',
};

