import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { doc, getDoc, setDoc, updateDoc, serverTimestamp, collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import { useOutletContext } from 'react-router-dom';
import { MessageCircle, Sun, Moon, Activity, Brain, Utensils, Droplets } from 'lucide-react';
import { Button } from '../ui/Button';
import { v4 as uuidv4 } from 'uuid';

const RoutinesSetup = () => {
  const { user } = useAuth();
  const { goToNextStep, goToPrevStep } = useOutletContext();
  const [selectedRoutines, setSelectedRoutines] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [existingMorningSteps, setExistingMorningSteps] = useState([]);
  const [existingEveningSteps, setExistingEveningSteps] = useState([]);

  // Predefined routine options
  const routineOptions = [
    { 
      id: 'morningHydration', 
      label: 'Morning Hydration', 
      description: 'Drink 16-20oz of water upon waking',
      icon: <Droplets className="w-5 h-5 text-accent-tertiary" />,
      aiExample: "I recommend starting your day with 16-20oz of water to rehydrate after sleep.",
      type: 'checklist',
      routineType: 'morning'
    },
    { 
      id: 'morningMovement', 
      label: 'Morning Movement', 
      description: '5-10 minutes of light stretching or movement',
      icon: <Activity className="w-5 h-5 text-accent-tertiary" />,
      aiExample: "Try to incorporate 5-10 minutes of light movement in the morning to energize your body.",
      type: 'timed',
      duration: 10,
      routineType: 'morning'
    },
    { 
      id: 'morningMindfulness', 
      label: 'Morning Mindfulness', 
      description: '5-10 minutes of meditation or deep breathing',
      icon: <Brain className="w-5 h-5 text-accent-tertiary" />,
      aiExample: "Starting your day with 5-10 minutes of meditation can help set a positive tone for the day.",
      type: 'timed',
      duration: 10,
      routineType: 'morning'
    },
    { 
      id: 'mealPrep', 
      label: 'Meal Preparation', 
      description: 'Prepare healthy meals and snacks in advance',
      icon: <Utensils className="w-5 h-5 text-accent-tertiary" />,
      aiExample: "I recommend meal prepping once or twice a week to ensure you have healthy options ready to go.",
      type: 'timed',
      duration: 30,
      routineType: 'morning'
    },
    { 
      id: 'eveningReflection', 
      label: 'Evening Reflection', 
      description: 'Journal about daily wins and areas for improvement',
      icon: <Moon className="w-5 h-5 text-accent-tertiary" />,
      aiExample: "Taking 5 minutes in the evening to reflect on your day can help you identify patterns and improve over time.",
      type: 'timed',
      duration: 5,
      routineType: 'evening'
    },
    { 
      id: 'sleepRoutine', 
      label: 'Sleep Routine', 
      description: 'Consistent bedtime with 30 minutes of screen-free time before sleep',
      icon: <Moon className="w-5 h-5 text-accent-tertiary" />,
      aiExample: "A consistent sleep routine with 30 minutes of screen-free time before bed can significantly improve your sleep quality.",
      type: 'timed',
      duration: 30,
      routineType: 'evening'
    },
    { 
      id: 'sunExposure', 
      label: 'Morning Sunlight', 
      description: 'Get 5-10 minutes of morning sunlight exposure',
      icon: <Sun className="w-5 h-5 text-accent-tertiary" />,
      aiExample: "Getting 5-10 minutes of morning sunlight can help regulate your circadian rhythm and boost mood.",
      type: 'timed',
      duration: 10,
      routineType: 'morning'
    }
  ];

  // Convert routine option to proper step format for saving
  const routineToStep = (routineOption) => {
    return {
      id: uuidv4(),
      type: routineOption.type,
      title: routineOption.label,
      description: routineOption.description,
      ...(routineOption.type === 'timed' && { duration: routineOption.duration })
    };
  };

  // Load saved routines if they exist
  useEffect(() => {
    const loadRoutineData = async () => {
      try {
        if (!user?.uid) return;
        
        // Load morning and evening routines from the correct path
        const morningDoc = await getDoc(doc(db, 'coaches', user.uid, 'Routines', 'morningRoutine'));
        const eveningDoc = await getDoc(doc(db, 'coaches', user.uid, 'Routines', 'eveningRoutine'));
        
        // Store existing steps
        const morningSteps = morningDoc.exists() ? morningDoc.data().steps || [] : [];
        const eveningSteps = eveningDoc.exists() ? eveningDoc.data().steps || [] : [];
        
        setExistingMorningSteps(morningSteps);
        setExistingEveningSteps(eveningSteps);
        
        // Extract selected routines based on title matches
        const selectedIds = [];
        
        // Check morning steps
        for (const step of morningSteps) {
          const matchingRoutine = routineOptions.find(r => 
            r.routineType === 'morning' && r.label === step.title
          );
          if (matchingRoutine) {
            selectedIds.push(matchingRoutine.id);
          }
        }
        
        // Check evening steps
        for (const step of eveningSteps) {
          const matchingRoutine = routineOptions.find(r => 
            r.routineType === 'evening' && r.label === step.title
          );
          if (matchingRoutine) {
            selectedIds.push(matchingRoutine.id);
          }
        }
        
        // If no routines found, set defaults
        if (selectedIds.length === 0) {
          setSelectedRoutines(['morningHydration', 'sleepRoutine']);
          
          // Create default routine documents
          const defaultMorningSteps = [
            routineToStep(routineOptions.find(r => r.id === 'morningHydration'))
          ];
          
          const defaultEveningSteps = [
            routineToStep(routineOptions.find(r => r.id === 'sleepRoutine'))
          ];
          
          await setDoc(doc(db, 'coaches', user.uid, 'Routines', 'morningRoutine'), {
            steps: defaultMorningSteps
          });
          
          await setDoc(doc(db, 'coaches', user.uid, 'Routines', 'eveningRoutine'), {
            steps: defaultEveningSteps
          });
          
          setExistingMorningSteps(defaultMorningSteps);
          setExistingEveningSteps(defaultEveningSteps);
        } else {
          setSelectedRoutines(selectedIds);
        }
      } catch (error) {
        console.error('Error loading routine data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    loadRoutineData();
  }, [user]);

  // Toggle routine selection
  const toggleRoutine = async (routineId) => {
    try {
      if (!user?.uid) return;
      
      const routineOption = routineOptions.find(r => r.id === routineId);
      if (!routineOption) return;
      
      const isSelected = selectedRoutines.includes(routineId);
      let newSelectedRoutines;
      
      if (isSelected) {
        // Remove routine
        newSelectedRoutines = selectedRoutines.filter(id => id !== routineId);
        
        // Determine which document to update
        const routineType = routineOption.routineType;
        const docRef = doc(db, 'coaches', user.uid, 'Routines', `${routineType}Routine`);
        
        // Get current steps
        const currentSteps = routineType === 'morning' 
          ? [...existingMorningSteps] 
          : [...existingEveningSteps];
        
        // Filter out the step with matching title
        const updatedSteps = currentSteps.filter(step => step.title !== routineOption.label);
        
        // Update Firestore document
        await setDoc(docRef, { steps: updatedSteps });
        
        // Update local state
        if (routineType === 'morning') {
          setExistingMorningSteps(updatedSteps);
        } else {
          setExistingEveningSteps(updatedSteps);
        }
      } else {
        // Add routine
        newSelectedRoutines = [...selectedRoutines, routineId];
        
        // Determine which document to update
        const routineType = routineOption.routineType;
        const docRef = doc(db, 'coaches', user.uid, 'Routines', `${routineType}Routine`);
        
        // Get current steps
        const currentSteps = routineType === 'morning' 
          ? [...existingMorningSteps] 
          : [...existingEveningSteps];
        
        // Add new step in the correct format
        const newStep = routineToStep(routineOption);
        const updatedSteps = [...currentSteps, newStep];
        
        // Update Firestore document
        await setDoc(docRef, { steps: updatedSteps });
        
        // Update local state
        if (routineType === 'morning') {
          setExistingMorningSteps(updatedSteps);
        } else {
          setExistingEveningSteps(updatedSteps);
        }
      }
      
      // Update selected routines state
      setSelectedRoutines(newSelectedRoutines);
    } catch (error) {
      console.error('Error updating routine selection:', error);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-text-secondary">Loading...</div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {/* Routine selection */}
      <div className="space-y-4">
        <h3 className="text-xl font-medium text-text-primary font-headers">Select Recommended Daily Routines</h3>
        <p className="text-text-secondary mb-6 font-body">
          Choose the routines you typically recommend to clients. You can customize these later.
        </p>
        
        <div className="grid md:grid-cols-2 gap-4">
          {routineOptions.map((routine) => (
            <div
              key={routine.id}
              onClick={() => toggleRoutine(routine.id)}
              className={`
                p-6 rounded-lg border-2 cursor-pointer transition-all duration-200
                ${selectedRoutines.includes(routine.id)
                  ? 'border-accent-primary bg-accent-primary/10 shadow-md transform scale-[1.02]'
                  : 'border-border-light hover:border-accent-secondary hover:bg-background-tertiary'
                }
              `}
            >
              <div className="flex items-center gap-3 mb-2">
                <div className={`w-5 h-5 rounded-full border-2 flex items-center justify-center 
                              ${selectedRoutines.includes(routine.id) ? 'border-accent-primary' : 'border-border-medium'}`}>
                  {selectedRoutines.includes(routine.id) && (
                    <div className="w-2.5 h-2.5 rounded-full bg-accent-primary" />
                  )}
                </div>
                <h4 className="text-lg font-medium text-text-primary font-headers flex items-center gap-2">
                  {routine.icon}
                  <span>{routine.label}</span>
                </h4>
              </div>
              <p className="text-text-secondary mb-4 font-body">{routine.description}</p>
              
              {/* Routine type indicator */}
              <div className="flex items-center gap-2 mb-3 text-text-light">
                {routine.type === 'timed' ? (
                  <span className="text-xs bg-background-tertiary px-2 py-1 rounded-full">
                    {routine.duration} min activity
                  </span>
                ) : (
                  <span className="text-xs bg-background-tertiary px-2 py-1 rounded-full">
                    Checklist item
                  </span>
                )}
                <span className="text-xs bg-background-tertiary px-2 py-1 rounded-full capitalize">
                  {routine.routineType} routine
                </span>
              </div>
              
              {/* AI preview */}
              {selectedRoutines.includes(routine.id) && (
                <div className="bg-background-tertiary p-4 rounded-lg mt-4 border border-accent-primary/20">
                  <div className="flex items-start gap-3">
                    <MessageCircle className="w-5 h-5 text-accent-primary shrink-0 mt-1" />
                    <div>
                      <p className="text-sm text-text-light mb-1 font-body">When clients ask about daily habits, your AI will say something like:</p>
                      <p className="text-text-primary font-body font-medium">"{routine.aiExample}"</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      
      {/* Customization note */}
      <div className="border-t border-border-light pt-8">
        <div className="bg-background-tertiary p-4 rounded-lg border border-border-medium">
          <div className="flex items-start gap-3">
            <div className="text-accent-secondary text-sm font-medium border border-accent-secondary/30 rounded-full w-5 h-5 flex items-center justify-center shrink-0">i</div>
            <p className="text-text-secondary text-sm font-body">
              You can create custom routines and fine-tune these recommendations later in the Routine Builder section of your account settings.
              The AI will use these selections to provide consistent advice to your clients.
            </p>
          </div>
        </div>
      </div>
      
      {/* Navigation buttons */}
      <div className="flex justify-between mt-8">
        <Button
          onClick={goToPrevStep}
          className="bg-background-tertiary text-text-primary hover:bg-hover-secondary transition-all duration-200 border border-border-medium px-6 py-3 text-base"
        >
          Back
        </Button>
        
        <Button
          onClick={goToNextStep}
          className="bg-accent-primary text-white hover:bg-hover-primary transition-all duration-200 shadow-md hover:shadow-lg min-w-[150px] px-6 py-3 text-base"
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default RoutinesSetup; 