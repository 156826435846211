// components/ui/Button.jsx
import React from 'react';
import { cn } from '../../lib/utils';

const Button = React.forwardRef(
  ({ variant = 'default', size = 'default', className = '', children, ...props }, ref) => {
    const variants = {
      primary: 'bg-accent-primary text-white hover:bg-[#B37350] shadow-sm hover:shadow-md active:translate-y-0.5 active:shadow-inner',
      secondary: 'bg-accent-secondary text-white hover:bg-[#8A7A65] shadow-sm hover:shadow-md active:translate-y-0.5 active:shadow-inner',
      outline: 'border border-accent-primary text-accent-primary hover:bg-accent-primary/10 active:bg-accent-primary/20',
      ghost: 'text-text-primary hover:bg-background-tertiary active:bg-background-tertiary/70',
      link: 'text-accent-tertiary hover:text-accent-primary underline-offset-4 hover:underline p-0 h-auto'
    };

    const sizes = {
      xs: 'h-7 px-2.5 text-xs rounded',
      sm: 'h-8 px-3 text-sm rounded-md',
      default: 'h-9 px-4 text-sm rounded-md',
      lg: 'h-11 px-6 text-base rounded-lg',
      icon: 'h-9 w-9 rounded-md'
    };

    return (
      <button
        ref={ref}
        className={cn(
          'inline-flex items-center justify-center',
          'font-body font-medium',
          'transition-all duration-200',
          'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-accent-primary/50 focus-visible:ring-offset-1',
          'disabled:opacity-60 disabled:pointer-events-none',
          variants[variant],
          sizes[size],
          className
        )}
        {...props}
      >
        {children}
      </button>
    );
  }
);

Button.displayName = "Button";

export { Button };