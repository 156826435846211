// src/components/ui/Tabs.jsx

import React from 'react';
import * as TabsPrimitive from "@radix-ui/react-tabs";
import { cn } from '../../lib/utils';

// Radix UI Tabs components - renamed for clarity
const Root = TabsPrimitive.Root;

const List = React.forwardRef(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      "inline-flex items-center justify-center rounded-md bg-background-secondary p-1",
      className
    )}
    {...props}
  />
));
List.displayName = "TabsList";

const Trigger = React.forwardRef(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      "inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium transition-all focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-accent-primary data-[state=active]:text-white",
      className
    )}
    {...props}
  />
));
Trigger.displayName = "TabsTrigger";

const Content = React.forwardRef(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      "mt-2 focus-visible:outline-none",
      className
    )}
    {...props}
  />
));
Content.displayName = "TabsContent";

export {
  Root as Tabs,
  Root as Tabs2, // For backward compatibility
  List as TabsList,
  Trigger as TabsTrigger,
  Content as TabsContent
};