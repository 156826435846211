// src/components/ClientInfoTab.jsx

import React, { useState, useEffect } from 'react';
import { Shield, Activity, Target, Apple, Bandage, ChevronRight } from 'lucide-react';
import { Button } from './ui/Button';
import { Input } from './ui/Input';
import { Switch } from './ui/Switch';
import { Tabs, TabsList, TabsTrigger, TabsContent } from './ui/Tabs';
import { ScrollArea } from './ui/ScrollArea';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from './ui/Card';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { auth } from '../firebase';

// Import components
import ClientRoutineBuilder from './ClientRoutineBuilder';
import ClientGoalTab from './ClientGoalTab';
import ClientDietTab from './ClientDietTab';
import ClientInjuriesTab from './ClientInjuriesTab';


const ClientInfoTab = ({
  clientInfo,
  clientId,
  isAddingPreference,
  setIsAddingPreference,
  newPreference,
  setNewPreference,
  handleAddPreference,
  handleDeletePreference,
}) => {
  const [requireApproval, setRequireApproval] = useState(false);
  const [activeTab, setActiveTab] = useState('routines');

  useEffect(() => {
    fetchApprovalSetting();
  }, []);

  const fetchApprovalSetting = async () => {
    try {
      const settingsDoc = await getDoc(
        doc(db, 'users', clientId, 'settings', 'messageSettings')
      );
      if (settingsDoc.exists()) {
        const data = settingsDoc.data();
        setRequireApproval(data.requireCoachApproval || true);
      }
    } catch (error) {
      console.error('Error fetching approval setting:', error);
    }
  };

  const handleToggleApproval = async () => {
    try {
      const newValue = !requireApproval;
      setRequireApproval(newValue);
      
      await setDoc(
        doc(db, 'users', clientId, 'settings', 'messageSettings'),
        { requireCoachApproval: newValue },
        { merge: true }
      );

      const user = auth.currentUser;
      // if (user) {
      //   await setDoc(
      //     doc(db, 'coaches', user.uid),
      //     //{ aiApproval: newValue },
      //     //{ merge: true }
      //   );
      // }
    } catch (error) {
      console.error('Error updating approval setting:', error);
    }
  };

  const tabs = [
    { id: 'routines', label: 'Routines', icon: Activity, component: ClientRoutineBuilder },
    { id: 'goals', label: 'Goals', icon: Target, component: ClientGoalTab },
    { id: 'diet', label: 'Diet', icon: Apple, component: ClientDietTab },
    { id: 'injuries', label: 'Injuries', icon: Bandage, component: ClientInjuriesTab },
  ];

  return (
    <div className="flex flex-col h-full space-y-6">
      {/* AI Control Card */}
      <Card className="bg-background-secondary border-border-light hover:shadow-md transition-shadow">
        <CardContent className="p-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <div className="p-2 bg-accent-primary/10 rounded-lg">
                <Shield className="h-6 w-6 text-accent-primary" />
              </div>
              <div className="space-y-1">
                <h4 className="font-headers text-text-primary font-medium">
                  AI Message Control
                </h4>
                <p className="text-sm text-text-light">
                  {requireApproval 
                    ? "Manual approval required for AI messages"
                    : "AI messages are sent automatically"}
                </p>
              </div>
            </div>
            <Switch
              checked={requireApproval}
              onCheckedChange={handleToggleApproval}
              className="data-[state=checked]:bg-accent-primary"
            />
          </div>
        </CardContent>
      </Card>

      {/* Main Navigation */}
      <div className="grid grid-cols-4 gap-4">
        {tabs.map(({ id, label, icon: Icon }) => (
          <button
            key={id}
            onClick={() => setActiveTab(id)}
            className={`
              flex items-center justify-between p-4 rounded-lg
              transition-all duration-200
              ${activeTab === id 
                ? 'bg-accent-primary text-white shadow-md' 
                : 'bg-background-secondary text-text-primary hover:bg-background-tertiary'
              }
            `}
          >
            <div className="flex items-center space-x-3">
              <Icon className="h-5 w-5" />
              <span className="font-medium">{label}</span>
            </div>
            <ChevronRight className={`h-4 w-4 transition-transform ${
              activeTab === id ? 'rotate-90' : ''
            }`} />
          </button>
        ))}
      </div>

      {/* Content Area */}
      <Card className="flex-1 bg-background-secondary border-border-light overflow-hidden">
        <ScrollArea className="h-full">
          <div className="p-6">
            {tabs.map(({ id, component: Component }) => (
              <div key={id} className={activeTab === id ? 'block' : 'hidden'}>
                <Component clientId={clientId} />
              </div>
            ))}
          </div>
        </ScrollArea>
      </Card>
    </div>
  );
};

export default ClientInfoTab;