// src/services/workoutTemplateService.js

import { db } from '../firebase';
import { doc, collection, addDoc, setDoc, getDoc, getDocs, updateDoc, writeBatch, Timestamp } from 'firebase/firestore';

const PATHS = {
  EXERCISES: 'exercises',
  COACH_CUSTOM_EXERCISES: 'coaches/{coachId}/customExercises',
  WORKOUT_TEMPLATES: 'coaches/{coachId}/workoutTemplates',
  COACH_ASSIGNED_WORKOUTS: 'coaches/{coachId}/users/{userId}/assignedWorkouts',
  USER_WORKOUTS: 'users/{userId}/workouts',
};

export const getExerciseData = async (exerciseId, coachId = null) => {
  if (coachId) {
    const customExerciseRef = doc(db, PATHS.COACH_CUSTOM_EXERCISES.replace('{coachId}', coachId), exerciseId);
    const customSnap = await getDoc(customExerciseRef);
    if (customSnap.exists()) {
      return customSnap.data();
    }
  }

  // fallback to global
  const exerciseRef = doc(db, PATHS.EXERCISES, exerciseId);
  const exerciseSnap = await getDoc(exerciseRef);
  return exerciseSnap.exists() ? exerciseSnap.data() : null;
};

/**
 * Create a workout template
 */
export const createWorkoutTemplate = async (coachId, templateData) => {
  // Fetch full exercise data for each exercise
  const exercisesWithData = await Promise.all(
    templateData.exercises.map(async (exercise) => {
      const exerciseData = await getExerciseData(exercise.exerciseId, coachId);
      return {
        ...exercise,
        name: exerciseData.name,
        isCustomExercise: exerciseData.coachId ? true : false
      };
    })
  );

  const template = {
    ...templateData,
    exercises: exercisesWithData,
    coachId,
    isTemplate: true,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now()
  };

  const templateRef = collection(db, PATHS.WORKOUT_TEMPLATES.replace('{coachId}', coachId));
  const docRef = await addDoc(templateRef, template);
  return docRef.id;
};

/**
 * Get a single workout template by ID
 */
export const getWorkoutTemplate = async (coachId, templateId) => {
  const templateRef = doc(db, PATHS.WORKOUT_TEMPLATES.replace('{coachId}', coachId), templateId);
  const snap = await getDoc(templateRef);
  return snap.exists() ? snap.data() : null;
};

/**
 * Assign template-based workouts to users
 */
export const assignWorkoutToUsers = async (coachId, templateId, userIds, scheduleDates) => {
  const template = await getWorkoutTemplate(coachId, templateId);
  if (!template) throw new Error('Template not found');

  const batch = writeBatch(db);

  for (const userId of userIds) {
    for (const date of scheduleDates) {
      const assignedWorkoutId = doc(collection(db, 'temp')).id; // Generate an ID

      const assignedWorkout = {
        ...template,
        userId,
        dateAssigned: Timestamp.now(),
        scheduledTime: Timestamp.fromDate(date),
        status: 'assigned',
        originalTemplateId: templateId,
        isTemplate: false,
        dateStarted: null,
        dateCompleted: null
      };

      // Add to coach's assigned workouts
      const coachWorkoutRef = doc(
        db, 
        PATHS.COACH_ASSIGNED_WORKOUTS
          .replace('{coachId}', template.coachId)
          .replace('{userId}', userId),
        assignedWorkoutId
      );

      // Add to user's workouts
      const userWorkoutRef = doc(
        db,
        PATHS.USER_WORKOUTS.replace('{userId}', userId),
        assignedWorkoutId
      );

      batch.set(coachWorkoutRef, assignedWorkout);
      batch.set(userWorkoutRef, assignedWorkout);
    }
  }

  await batch.commit();
};

/**
 * Bulk modify assigned workouts (for progression)
 */
export const bulkModifyWorkouts = async (coachId, userId, workoutIds, modifications) => {
  const batch = writeBatch(db);

  for (const workoutId of workoutIds) {
    const assignedRef = doc(db, PATHS.COACH_ASSIGNED_WORKOUTS.replace('{coachId}', coachId).replace('{userId}', userId), workoutId);
    const assignedSnap = await getDoc(assignedRef);
    if (!assignedSnap.exists()) continue;

    const workout = assignedSnap.data();

    const updatedExercises = workout.exercises.map(exercise => {
      const updated = { ...exercise };
      if (modifications.repsAdjustment) {
        updated.targetReps = adjustRepsRange(exercise.targetReps, modifications.repsAdjustment);
      }
      if (modifications.weightPercentageChange && exercise.targetWeight) {
        updated.targetWeight = exercise.targetWeight * (1 + modifications.weightPercentageChange / 100);
      }
      return updated;
    });

    // Update coach's doc
    batch.update(assignedRef, { exercises: updatedExercises });
    // Update user's doc
    const userRef = doc(db, PATHS.USER_WORKOUTS.replace('{userId}', userId), workoutId);
    batch.update(userRef, { exercises: updatedExercises });
  }

  await batch.commit();
};

/**
 * Helper to adjust a reps range (e.g. "8-12") by a certain integer offset
 */
const adjustRepsRange = (repsRange, adjustment) => {
  const [min, max] = repsRange.split('-').map(Number);
  return `${min + adjustment}-${max + adjustment}`;
};
