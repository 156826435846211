import React, { useState } from 'react';
import { motion } from 'framer-motion';

const TextInput = ({ value, onChange, placeholder = 'Type your answer here...' }) => {
  const [isFocused, setIsFocused] = useState(false);
  
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, ease: [0.22, 1, 0.36, 1] }}
      className="w-full max-w-xl mx-auto"
    >
      <div className="relative">
        <motion.div
          initial={{ opacity: 0, scale: 0.98 }}
          animate={{ 
            opacity: 1, 
            scale: 1,
            boxShadow: isFocused ? '0 4px 20px rgba(0, 0, 0, 0.08)' : '0 2px 8px rgba(0, 0, 0, 0.04)'
          }}
          transition={{ duration: 0.4 }}
          className={`relative w-full rounded-xl overflow-hidden 
                    border-2 transition-all duration-300 ease-out
                    ${isFocused 
                      ? 'border-accent-primary bg-background-secondary' 
                      : value 
                        ? 'border-border-medium bg-background-secondary' 
                        : 'border-border-light bg-background-tertiary'}`}
        >
          <input
            type="text"
            value={value || ''}
            onChange={(e) => onChange(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder={placeholder}
            className="w-full py-4 px-5 text-xl text-text-primary bg-transparent 
                     focus:outline-none transition-colors duration-300
                     placeholder:text-text-light placeholder:opacity-60"
            autoFocus
          />
          
          {value && value.length > 0 && (
            <motion.div 
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ type: "spring", stiffness: 400, damping: 15 }}
              className="absolute right-4 top-1/2 transform -translate-y-1/2 h-2 w-2 rounded-full bg-accent-primary"
            />
          )}
        </motion.div>
      </div>
      
      {/* Character counter for long answers */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: value && value.length > 0 ? 1 : 0 }}
        transition={{ duration: 0.3 }}
        className="text-right mt-2 text-xs text-text-light"
      >
        {value && value.length > 0 ? `${value.length} characters` : ''}
      </motion.div>
    </motion.div>
  );
};

export default TextInput; 