// src/components/accountpagetabs/SleepTab.js

import React, { useState, useEffect } from 'react';
import { Textarea } from '../ui/Textarea';
import { Label } from '../ui/Label';
import { Card, CardContent } from '../ui/Card';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { Info } from 'lucide-react';

const SleepTab = ({ userId }) => {
  const [sleepForm, setSleepForm] = useState({
    sleep: '',
    sleepRoutine: '',
    morningRoutine: '',
    sleepTips: '',
    alcoholPhilosophy: ''
  });

  useEffect(() => {
    const loadSleepData = async () => {
      try {
        const sleepDoc = await getDoc(doc(db, 'coaches', userId, 'philosophy', 'sleep'));
        if (sleepDoc.exists()) {
          setSleepForm(sleepDoc.data());
        }
      } catch (error) {
        console.error('Error loading sleep data:', error);
      }
    };
    loadSleepData();
  }, [userId]);

  const handleSleepChange = async (field, value) => {
    const updatedForm = { ...sleepForm, [field]: value };
    setSleepForm(updatedForm);
    try {
      const sleepRef = doc(db, 'coaches', userId, 'philosophy', 'sleep');
      await setDoc(sleepRef, updatedForm, { merge: true });
    } catch (error) {
      console.error('Error updating sleep data:', error);
    }
  };

  return (
    <div className="h-full overflow-y-auto">
      <div className="px-8 py-6">
        <div className="mb-8">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <h2 className="text-2xl font-bold text-white">Sleep Philosophy</h2>
              <Info className="w-5 h-5 text-blue-400 hover:text-blue-300 cursor-pointer transition-colors" />
            </div>
          </div>
        </div>

        <div className="space-y-8 pb-8">
          <Card className="bg-neutral-900 border-neutral-800 shadow-xl rounded-xl">
            <CardContent className="p-8">
              <h3 className="text-xl font-semibold text-white mb-8">Sleep Fundamentals</h3>
              <div className="grid grid-cols-3 gap-6">
                {[
                  { label: 'Sleep Overview', field: 'sleep', placeholder: 'Enter general sleep philosophy and importance...' },
                  { label: 'Sleep Tips', field: 'sleepTips', placeholder: 'Enter sleep optimization tips...' },
                  { label: 'Alcohol Philosophy', field: 'alcoholPhilosophy', placeholder: 'Enter alcohol consumption guidelines and effects on sleep...' }
                ].map(({ label, field, placeholder }) => (
                  <div key={field} className="space-y-3">
                    <Label className="text-lg font-medium text-white">{label}</Label>
                    <div className="relative h-[400px] rounded-lg overflow-hidden">
                      <Textarea
                        placeholder={placeholder}
                        className="absolute inset-0 p-4 text-lg bg-neutral-800 border-neutral-700 text-white placeholder:text-neutral-400 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 resize-none overflow-y-auto scrollbar-thin scrollbar-thumb-neutral-600 scrollbar-track-neutral-800"
                        value={sleepForm[field]}
                        onChange={(e) => handleSleepChange(field, e.target.value)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>

          <Card className="bg-neutral-900 border-neutral-800 shadow-xl rounded-xl">
            <CardContent className="p-8">
              <h3 className="text-xl font-semibold text-white mb-8">Daily Routines</h3>
              <div className="grid grid-cols-2 gap-6">
                {[
                  { label: 'Sleep Routine', field: 'sleepRoutine', placeholder: 'Describe recommended evening and sleep routine...' },
                  { label: 'Morning Routine', field: 'morningRoutine', placeholder: 'Describe recommended morning routine...' }
                ].map(({ label, field, placeholder }) => (
                  <div key={field} className="space-y-3">
                    <Label className="text-lg font-medium text-white">{label}</Label>
                    <div className="relative h-[250px] rounded-lg overflow-hidden">
                      <Textarea
                        placeholder={placeholder}
                        className="absolute inset-0 p-4 text-lg bg-neutral-800 border-neutral-700 text-white placeholder:text-neutral-400 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 resize-none overflow-y-auto scrollbar-thin scrollbar-thumb-neutral-600 scrollbar-track-neutral-800"
                        value={sleepForm[field]}
                        onChange={(e) => handleSleepChange(field, e.target.value)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default SleepTab;