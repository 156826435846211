import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, getDoc, addDoc, updateDoc, deleteDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import { Button } from '../ui/Button';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/Card';
import NavigationBar from '../ui/NavigationBar';
import { Plus, Settings, Eye, Copy, Trash, Edit2, CheckCircle, ChevronRight, FileText, ExternalLink, Calendar, Paintbrush, BarChart2 } from 'lucide-react';
import FormList from './FormList';
import FormEditor from './FormEditor';
import SettingsPanel from './SettingsPanel';
import { useNavigate } from 'react-router-dom';
import { getDefaultQuestions } from '../../utils/defaultQuestions';

const OnboardingManager = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [forms, setForms] = useState([]);
  const [selectedFormId, setSelectedFormId] = useState(null);
  const [activeForm, setActiveForm] = useState(null);
  const [activeTab, setActiveTab] = useState('forms');
  const [isLoading, setIsLoading] = useState(true);
  const [coachData, setCoachData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  // Fetch forms and coach data
  useEffect(() => {
    const fetchData = async () => {
      if (!user?.uid) return;
      
      setIsLoading(true);
      try {
        // Get coach data
        const coachRef = doc(db, 'coaches', user.uid);
        const coachDoc = await getDoc(coachRef);
        
        if (coachDoc.exists()) {
          const data = coachDoc.data();
          setCoachData({
            ...data,
            id: user.uid,
            // Ensure username exists
            username: data.username || user.uid
          });
          
          // If activeOnboardingFormId exists, set it
          if (data.activeOnboardingFormId) {
            setActiveForm(data.activeOnboardingFormId);
          }
        } else {
          // Create coach document if it doesn't exist
          const newCoachData = {
            id: user.uid,
            username: user.uid,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp()
          };
          
          await setDoc(coachRef, newCoachData);
          setCoachData(newCoachData);
        }
        
        // Get forms
        const formsQuery = query(
          collection(db, 'onboardingForms'),
          where('coachId', '==', user.uid)
        );
        
        const formsSnapshot = await getDocs(formsQuery);
        const formsList = formsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          isActive: doc.id === (coachDoc.data()?.activeOnboardingFormId || null)
        }));
        
        setForms(formsList);
        
        // If there's an active form, select it automatically
        const activeFormId = coachDoc.data()?.activeOnboardingFormId;
        if (activeFormId && formsList.some(form => form.id === activeFormId)) {
          setSelectedFormId(activeFormId);
        }
        // If no active form but there are forms, select the first one
        else if (formsList.length > 0 && !selectedFormId) {
          setSelectedFormId(formsList[0].id);
        }
      } catch (error) {
        console.error('Error fetching forms:', error);
        setErrorMessage('Failed to load forms. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchData();
  }, [user?.uid]);

  const createNewForm = async () => {
    try {
      setIsLoading(true);
      
      // Create a new form with default values
      const newFormRef = await addDoc(collection(db, 'onboardingForms'), {
        coachId: user.uid,
        title: 'New Client Questionnaire',
        description: 'Get to know your clients better',
        welcomeMessage: 'Welcome! Please fill out this questionnaire to help me understand your goals better.',
        callToAction: 'Submit and Book a Call',
        isActive: false,
        redirectToCall: true,
        showDirectPayment: false,
        formVersion: 1,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        settings: {
          progressBar: true,
          stepLabels: ['Welcome', 'Basic Info', 'Goals', 'Health History', 'Finish'],
          allowSkip: false,
          requireEmail: true,
          requirePhone: true,
          customBranding: {
            primaryColor: '#C4845F',
            accentColor: '#3B4664',
            backgroundImage: ''
          }
        }
      });
      
      // Get default questions with proper order and step assignments
      const defaultQuestions = getDefaultQuestions(0);
      
      // Add default questions first
      for (const question of defaultQuestions) {
        await addDoc(collection(db, 'onboardingForms', newFormRef.id, 'questions'), question);
      }
      
      // Add a custom example question after the default questions
      await addDoc(collection(db, 'onboardingForms', newFormRef.id, 'questions'), {
        order: defaultQuestions.length,
        step: defaultQuestions.length + 1,
        title: 'This is an example custom question which you can create',
        description: 'The default questions are required for the onboarding form to work, but you can add as many custom questions as you want.',
        type: 'multipleChoice',
        isRequired: true,
        options: [
          { label: 'Answer 1', value: 'answer_1' },
          { label: 'Answer 2', value: 'answer_2' },
          { label: 'Answer 3', value: 'answer_3' },
          { label: 'Answer 4', value: 'answer_4' }
        ],
        saveToField: 'goals.primary',
        validations: {},
        isDefault: false,
        isEditable: {
          title: true,
          description: true,
          options: true,
          type: true,
          saveToField: true
        },
        isRemovable: true
      });
      
      // Fetch updated forms list
      const formsQuery = query(
        collection(db, 'onboardingForms'),
        where('coachId', '==', user.uid)
      );
      
      const formsSnapshot = await getDocs(formsQuery);
      const formsList = formsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        isActive: doc.id === (coachData?.activeOnboardingFormId || null)
      }));
      
      // If this is the only form, set it as active automatically
      if (formsList.length === 1 || (forms.length === 0 && formsList.length > 0)) {
        await setFormAsActive(newFormRef.id);
      }
      
      setForms(formsList);
      setSelectedFormId(newFormRef.id);
      setActiveTab('editor');
    } catch (error) {
      console.error('Error creating form:', error);
      setErrorMessage('Failed to create new form. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const deleteForm = async (formId) => {
    try {
      setIsLoading(true);
      
      // Check if this is the active form
      if (formId === activeForm) {
        // Remove activeOnboardingFormId from coach document
        const coachRef = doc(db, 'coaches', user.uid);
        await updateDoc(coachRef, {
          activeOnboardingFormId: null
        });
        setActiveForm(null);
      }
      
      // Delete the form and all its questions
      await deleteDoc(doc(db, 'onboardingForms', formId));
      
      // Update forms list
      setForms(forms.filter(form => form.id !== formId));
      
      // If the deleted form was selected, select another form or none
      if (formId === selectedFormId) {
        const remainingForms = forms.filter(form => form.id !== formId);
        setSelectedFormId(remainingForms.length > 0 ? remainingForms[0].id : null);
      }
    } catch (error) {
      console.error('Error deleting form:', error);
      setErrorMessage('Failed to delete form. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const setFormAsActive = async (formId) => {
    try {
      setIsLoading(true);
      
      // Update coach document
      const coachRef = doc(db, 'coaches', user.uid);
      await updateDoc(coachRef, {
        activeOnboardingFormId: formId
      });
      
      // Update the form's updatedAt field
      const formRef = doc(db, 'onboardingForms', formId);
      await updateDoc(formRef, {
        updatedAt: serverTimestamp()
      });
      
      // Update local state
      setActiveForm(formId);
      setForms(forms.map(form => ({
        ...form,
        isActive: form.id === formId,
        updatedAt: form.id === formId ? new Date() : form.updatedAt // Update local state timestamp
      })));
    } catch (error) {
      console.error('Error setting active form:', error);
      setErrorMessage('Failed to set active form. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const duplicateForm = async (formId) => {
    try {
      setIsLoading(true);
      
      // Get the form to duplicate
      const formRef = doc(db, 'onboardingForms', formId);
      const formDoc = await getDoc(formRef);
      
      if (!formDoc.exists()) {
        throw new Error('Form not found');
      }
      
      const formData = formDoc.data();
      
      // Create a copy with a new name
      const newFormRef = await addDoc(collection(db, 'onboardingForms'), {
        ...formData,
        title: `${formData.title} (Copy)`,
        isActive: false,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });
      
      // Get questions from the original form
      const questionsQuery = query(collection(db, 'onboardingForms', formId, 'questions'));
      const questionsSnapshot = await getDocs(questionsQuery);
      
      // Copy all questions to the new form
      const questionPromises = questionsSnapshot.docs.map(questionDoc => {
        const questionData = questionDoc.data();
        return addDoc(collection(db, 'onboardingForms', newFormRef.id, 'questions'), questionData);
      });
      
      await Promise.all(questionPromises);
      
      // Refresh forms list
      const formsQuery = query(
        collection(db, 'onboardingForms'),
        where('coachId', '==', user.uid)
      );
      
      const formsSnapshot = await getDocs(formsQuery);
      const formsList = formsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        isActive: doc.id === activeForm
      }));
      
      setForms(formsList);
    } catch (error) {
      console.error('Error duplicating form:', error);
      setErrorMessage('Failed to duplicate form. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const viewFormResponses = () => {
    navigate('/form-responses');
  };

  const viewLandingPage = () => {
    if (coachData?.username) {
      // Open in a new tab
      window.open(`/${coachData.username}`, '_blank');
    }
  };

  const editLandingPage = () => {
    navigate('/landing-page-editor');
  };

  const viewAnalytics = () => {
    navigate('/analytics/onboarding');
  };

  // Render loading state
  if (isLoading && forms.length === 0) {
    return (
      <div className="min-h-screen bg-background-primary pt-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin h-8 w-8 border-4 border-accent-primary border-t-transparent rounded-full"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background-primary pt-20">
      <div className="container mx-auto p-4">
        <div className="flex flex-col md:flex-row md:items-center justify-between mb-6 gap-4">
          <div>
            <h1 className="text-2xl font-semibold text-text-primary mb-1">Onboarding Forms</h1>
            <p className="text-text-light text-sm">Create and manage your client onboarding forms</p>
          </div>
          <div className="flex items-center">
            <div className="bg-white rounded-lg shadow-sm border border-border-light p-1 flex items-center">
              <Button
                variant="ghost"
                onClick={viewLandingPage}
                className="flex items-center gap-2 text-accent-tertiary hover:text-accent-primary rounded-md"
                disabled={!coachData?.username}
              >
                <ExternalLink className="h-4 w-4" />
                <span className="hidden sm:inline">View Landing Page</span>
                <span className="sm:hidden">Landing</span>
              </Button>
              <div className="h-6 w-px bg-border-light mx-1"></div>
              <Button
                variant="ghost"
                onClick={editLandingPage}
                className="flex items-center gap-2 text-accent-tertiary hover:text-accent-primary rounded-md"
                disabled={!coachData?.username}
              >
                <Paintbrush className="h-4 w-4" />
                <span className="hidden sm:inline">Edit Landing Page</span>
                <span className="sm:hidden">Edit</span>
              </Button>
              <div className="h-6 w-px bg-border-light mx-1"></div>
              <Button
                variant="ghost"
                onClick={viewFormResponses}
                className="flex items-center gap-2 text-accent-tertiary hover:text-accent-primary rounded-md"
              >
                <FileText className="h-4 w-4" />
                <span className="hidden sm:inline">View Responses</span>
                <span className="sm:hidden">Responses</span>
              </Button>
              <div className="h-6 w-px bg-border-light mx-1"></div>
              <Button
                variant="ghost"
                onClick={viewAnalytics}
                className="flex items-center gap-2 text-accent-tertiary hover:text-accent-primary rounded-md"
              >
                <BarChart2 className="h-4 w-4" />
                <span className="hidden sm:inline">View Analytics</span>
                <span className="sm:hidden">Analytics</span>
              </Button>
            </div>
            <Button
              variant="primary"
              onClick={createNewForm}
              className="flex items-center gap-2 ml-3 shadow-sm"
              disabled={isLoading}
            >
              <Plus className="h-4 w-4" />
              <span className="hidden sm:inline">Create Form</span>
              <span className="sm:hidden">Create</span>
            </Button>
          </div>
        </div>

        {errorMessage && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
            {errorMessage}
          </div>
        )}

        {selectedFormId && coachData && !coachData.calendlyLink && (
          <div className="bg-amber-50 border border-amber-200 rounded-lg p-4 mb-6 shadow-sm">
            <div className="flex items-start">
              <Calendar className="w-5 h-5 text-amber-500 mr-3 mt-0.5 flex-shrink-0" />
              <div>
                <h3 className="font-medium text-amber-800 mb-1">Connect Your Calendar</h3>
                <p className="text-amber-700 text-sm mb-3">
                  Clients won't be able to book calls with you until you connect your Calendly account.
                </p>
                <Button 
                  variant="outline" 
                  size="sm"
                  onClick={() => navigate('/account-settings')}
                  className="text-xs bg-white border-amber-400 text-amber-700 hover:bg-amber-100"
                >
                  Connect Calendly
                </Button>
              </div>
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
          {/* Forms Sidebar */}
          <div className="lg:col-span-1">
            <Card className="h-full overflow-hidden border border-border-light shadow-sm">
              <CardHeader className="bg-background-secondary border-b border-border-light pb-3">
                <CardTitle className="text-lg font-medium">Your Forms</CardTitle>
              </CardHeader>
              <CardContent className="p-3">
                <FormList 
                  forms={forms} 
                  selectedFormId={selectedFormId}
                  onSelectForm={(formId) => {
                    setSelectedFormId(formId);
                    setActiveTab('editor');
                  }}
                  onDeleteForm={deleteForm}
                  onDuplicateForm={duplicateForm}
                  onSetActive={setFormAsActive}
                  activeFormId={activeForm}
                />
              </CardContent>
            </Card>
          </div>

          {/* Main Editing Area */}
          <div className="lg:col-span-3">
            {selectedFormId ? (
              <>
                <Card className="border border-border-light shadow-sm">
                  <FormEditor 
                    formId={selectedFormId} 
                    onUpdate={(updatedForm) => {
                      setForms(forms.map(form => 
                        form.id === selectedFormId ? { ...form, ...updatedForm } : form
                      ));
                    }}
                  />
                </Card>
              </>
            ) : (
              <Card className="border border-border-light shadow-sm bg-background-secondary">
                <CardContent className="flex flex-col items-center justify-center py-16">
                  <div className="bg-background-tertiary rounded-full p-5 mb-5 shadow-sm">
                    <Plus className="h-7 w-7 text-accent-primary" />
                  </div>
                  <h3 className="text-xl font-medium text-text-primary mb-3">No form selected</h3>
                  <p className="text-text-light text-center max-w-md mb-6">
                    Create your first form or select an existing one from the sidebar
                  </p>
                  <Button variant="primary" onClick={createNewForm} className="flex items-center gap-2 px-6 py-2">
                    <Plus className="h-4 w-4" />
                    <span>Create Form</span>
                  </Button>
                </CardContent>
              </Card>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingManager; 