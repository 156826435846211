// src/components/onboarding/NameUsernamePage.jsx

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { User, AtSign, Hexagon } from 'lucide-react';
import { doc, updateDoc, getDocs, collection, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const NameUsernamePage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    username: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  const checkUsernameExists = async (username) => {
    const coachesRef = collection(db, 'coaches');
    const q = query(coachesRef, where('username', '==', username));
    const snapshot = await getDocs(q);
    return !snapshot.empty;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, username } = formData;

    if (!firstName || !lastName || !username) {
      alert('Please fill in all fields');
      return;
    }

    try {
      setIsLoading(true);
      
      const exists = await checkUsernameExists(username);
      if (exists) {
        alert('Username already exists. Please choose another one.');
        setIsLoading(false);
        return;
      }

      const coachRef = doc(db, 'coaches', user.uid);
      await updateDoc(coachRef, {
        firstName,
        lastName,
        username,
        updatedAt: new Date()
      });

      navigate('/account');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Error updating profile. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-background-primary flex items-center justify-center px-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md"
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="text-center mb-12"
        >
          <Hexagon className="w-16 h-16 text-accent-primary mx-auto mb-6" />
          <h1 className="text-6xl font-light text-text-primary mb-3 tracking-tight font-headers">
            Welcome<span className="text-accent-primary">.</span>
          </h1>
          <p className="text-text-light text-lg font-body font-light">Let's set up your profile</p>
        </motion.div>

        <form onSubmit={handleSubmit} className="space-y-8">
          <div className="relative group">
            <User className="absolute top-3 left-0 text-text-light group-focus-within:text-accent-primary transition-colors" size={20} />
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={(e) => setFormData(prev => ({ ...prev, firstName: e.target.value }))}
              className="w-full pl-8 pb-3 text-lg bg-transparent border-b-2 border-text-light/20 
                       focus:border-accent-primary text-text-primary placeholder-text-light/50 
                       focus:outline-none transition-all duration-300 font-body"
              placeholder="First Name"
              required
            />
          </div>
          <div className="relative group">
            <User className="absolute top-3 left-0 text-text-light group-focus-within:text-accent-primary transition-colors" size={20} />
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={(e) => setFormData(prev => ({ ...prev, lastName: e.target.value }))}
              className="w-full pl-8 pb-3 text-lg bg-transparent border-b-2 border-text-light/20 
                       focus:border-accent-primary text-text-primary placeholder-text-light/50 
                       focus:outline-none transition-all duration-300 font-body"
              placeholder="Last Name"
              required
            />
          </div>
          <div className="relative group">
            <AtSign className="absolute top-3 left-0 text-text-light group-focus-within:text-accent-primary transition-colors" size={20} />
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={(e) => setFormData(prev => ({ ...prev, username: e.target.value }))}
              className="w-full pl-8 pb-3 text-lg bg-transparent border-b-2 border-text-light/20 
                       focus:border-accent-primary text-text-primary placeholder-text-light/50 
                       focus:outline-none transition-all duration-300 font-body"
              placeholder="Choose a username"
              required
            />
          </div>

          <motion.button
            whileHover={{ scale: 1.02, boxShadow: "0 8px 20px rgba(196, 132, 95, 0.2)" }}
            whileTap={{ scale: 0.98 }}
            type="submit"
            disabled={isLoading}
            className="w-full bg-accent-primary text-white py-3 rounded-lg 
                     hover:bg-hover-primary focus:outline-none focus:ring-2 
                     focus:ring-accent-primary focus:ring-opacity-50 
                     transition-all duration-300 transform shadow-lg 
                     text-lg font-medium font-body mt-8"
          >
            {isLoading ? (
              <span className="flex items-center justify-center">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Setting up...
              </span>
            ) : (
              'Complete Setup'
            )}
          </motion.button>
        </form>
      </motion.div>
    </div>
  );
};

export default NameUsernamePage;
