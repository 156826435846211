import { db } from '../firebase';
import { 
  doc, 
  collection,
  getDoc,
  getDocs,
  query,
  where,
  runTransaction,
  serverTimestamp,
  arrayUnion,
  arrayRemove
} from 'firebase/firestore';

const VALID_ALTERNATIVE_TYPES = ['easier', 'harder', 'bodyweight'];

/**
 * Validate alternative exercise data
 */
const validateAlternative = (alternative) => {
  if (!alternative.exerciseId) {
    throw new Error('Alternative exerciseId is required');
  }
  if (!VALID_ALTERNATIVE_TYPES.includes(alternative.type)) {
    throw new Error(`Alternative type must be one of: ${VALID_ALTERNATIVE_TYPES.join(', ')}`);
  }
  if (alternative.notes !== undefined && typeof alternative.notes !== 'string') {
    throw new Error('Notes must be a string if provided');
  }
  return true;
};

/**
 * Add an alternative exercise mapping
 */
export const addAlternative = async (coachId, exerciseId, alternativeExercise, type = 'easier', notes = '') => {
  if (!coachId || !exerciseId) {
    throw new Error('coachId and exerciseId are required');
  }
  if (!alternativeExercise || !alternativeExercise.id) {
    throw new Error('Alternative exercise with id is required');
  }
  if (!VALID_ALTERNATIVE_TYPES.includes(type)) {
    throw new Error(`Type must be one of: ${VALID_ALTERNATIVE_TYPES.join(', ')}`);
  }
  if (exerciseId === alternativeExercise.id) {
    throw new Error('Cannot add exercise as its own alternative');
  }

  const alternativesRef = doc(db, `coaches/${coachId}/exerciseAlternatives`, exerciseId);
  
  try {
    await runTransaction(db, async (transaction) => {
      const doc = await transaction.get(alternativesRef);
      
      const alternative = {
        exerciseId: alternativeExercise.id,
        type,
        notes: notes || ''
      };

      validateAlternative(alternative);
      
      if (!doc.exists()) {
        transaction.set(alternativesRef, {
          exerciseId,
          alternatives: [alternative],
          updatedAt: serverTimestamp()
        });
      } else {
        const data = doc.data();
        // Check if alternative already exists
        if (!data.alternatives.some(alt => alt.exerciseId === alternativeExercise.id)) {
          transaction.update(alternativesRef, {
            alternatives: arrayUnion(alternative),
            updatedAt: serverTimestamp()
          });
        }
      }
    });
    
    return true;
  } catch (error) {
    console.error('Error adding alternative:', error);
    throw error;
  }
};

/**
 * Remove an alternative exercise mapping
 */
export const removeAlternative = async (coachId, exerciseId, alternativeExerciseId) => {
  if (!coachId || !exerciseId || !alternativeExerciseId) {
    throw new Error('coachId, exerciseId, and alternativeExerciseId are required');
  }

  const alternativesRef = doc(db, `coaches/${coachId}/exerciseAlternatives`, exerciseId);
  
  try {
    await runTransaction(db, async (transaction) => {
      const doc = await transaction.get(alternativesRef);
      
      if (doc.exists()) {
        const data = doc.data();
        const alternative = data.alternatives.find(alt => alt.exerciseId === alternativeExerciseId);
        
        if (alternative) {
          transaction.update(alternativesRef, {
            alternatives: arrayRemove(alternative),
            updatedAt: serverTimestamp()
          });
        }
      }
    });
    
    return true;
  } catch (error) {
    console.error('Error removing alternative:', error);
    throw error;
  }
};

/**
 * Update alternative exercise type or notes
 */
export const updateAlternative = async (coachId, exerciseId, alternativeExerciseId, newType, newNotes = '') => {
  if (!coachId || !exerciseId || !alternativeExerciseId) {
    throw new Error('coachId, exerciseId, and alternativeExerciseId are required');
  }
  if (!VALID_ALTERNATIVE_TYPES.includes(newType)) {
    throw new Error(`Type must be one of: ${VALID_ALTERNATIVE_TYPES.join(', ')}`);
  }

  const alternativesRef = doc(db, `coaches/${coachId}/exerciseAlternatives`, exerciseId);
  
  try {
    await runTransaction(db, async (transaction) => {
      const doc = await transaction.get(alternativesRef);
      
      if (doc.exists()) {
        const data = doc.data();
        const alternatives = data.alternatives.map(alt => {
          if (alt.exerciseId === alternativeExerciseId) {
            const updated = { ...alt, type: newType, notes: newNotes };
            validateAlternative(updated);
            return updated;
          }
          return alt;
        });
        
        transaction.update(alternativesRef, {
          alternatives,
          updatedAt: serverTimestamp()
        });
      }
    });
    
    return true;
  } catch (error) {
    console.error('Error updating alternative:', error);
    throw error;
  }
};

/**
 * Get all alternatives for an exercise
 */
export const getAlternatives = async (coachId, exerciseId) => {
  const alternativesRef = doc(db, `coaches/${coachId}/exerciseAlternatives`, exerciseId);
  const snap = await getDoc(alternativesRef);
  
  return snap.exists() 
    ? snap.data()
    : { exerciseId, alternatives: [] };
};

/**
 * Get all exercises that have this exercise as an alternative
 */
export const getExercisesUsingAsAlternative = async (coachId, exerciseId) => {
  const alternativesRef = collection(db, `coaches/${coachId}/exerciseAlternatives`);
  const alternativesSnap = await getDocs(
    query(alternativesRef)
  );
  
  return alternativesSnap.docs
    .map(doc => doc.data())
    .filter(data => 
      data.alternatives.some(alt => alt.exerciseId === exerciseId)
    );
};

/**
 * Find suitable alternatives based on type and equipment
 */
export const findSuitableAlternatives = async (coachId, exercise, preferredType = 'easier') => {
  // Get all alternatives for this exercise
  const alternatives = await getAlternatives(coachId, exercise.id);
  
  // Filter by preferred type if specified
  let suitableAlternatives = alternatives.alternatives;
  if (preferredType) {
    suitableAlternatives = suitableAlternatives.filter(alt => alt.type === preferredType);
  }
  
  // Get full exercise details for each alternative
  const exerciseDetails = await Promise.all(
    suitableAlternatives.map(async (alt) => {
      const exerciseRef = doc(db, `coaches/${coachId}/customExercises`, alt.exerciseId);
      const exerciseSnap = await getDoc(exerciseRef);
      
      if (!exerciseSnap.exists()) {
        // Try global exercises if not found in custom
        const globalRef = doc(db, 'exercises', alt.exerciseId);
        const globalSnap = await getDoc(globalRef);
        
        if (!globalSnap.exists()) return null;
        return {
          id: alt.exerciseId,
          ...globalSnap.data(),
          alternativeType: alt.type,
          notes: alt.notes
        };
      }
      
      return {
        id: alt.exerciseId,
        ...exerciseSnap.data(),
        alternativeType: alt.type,
        notes: alt.notes
      };
    })
  );
  
  return exerciseDetails.filter(ex => ex !== null);
}; 