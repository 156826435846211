import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/Card';
import { Button } from '../ui/Button';
import { ArrowRight } from 'lucide-react';
import MarkdownTextRenderer from '../ui/MarkdownTextRenderer';
import MultipleChoice from '../clientOnboarding/OnboardingFlow/QuestionTypes/MultipleChoice';

const PreviewPanel = ({
  backgroundMedia,
  backgroundMediaType,
  heroTitle,
  heroBio,
  coachData,
  user,
  // Optional props with default values
  successStoriesTitle,
  faqTitle,
  ctaTitle,
  testimonials = [],
  faqs = []
}) => {
  // Example first question for preview
  const dummyQuestion = {
    title: "What are your goals?",
    options: [
      { label: "Building muscle and strength", value: "muscle_gain" },
      { label: "Losing weight and transforming your body", value: "weight_loss" },
      { label: "Improving overall health and muscle tone", value: "health" }
    ]
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Landing Page Preview</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="border rounded-lg overflow-hidden bg-black">
          <div className="relative pb-[180%] w-full">
            {/* Simulated Device Frame */}
            <div className="absolute inset-0 bg-gray-900 border-4 border-gray-800 rounded-lg overflow-hidden">
              {/* Status Bar */}
              <div className="h-8 bg-black flex items-center justify-between px-3 text-xs text-white">
                <span>1:07</span>
                <div className="flex items-center space-x-1">
                  <span>•••</span>
                  <span>Wi-Fi</span>
                  <span>100%</span>
                </div>
              </div>
              
              {/* URL Bar */}
              <div className="h-8 bg-gray-800 flex items-center justify-center text-xs text-gray-300">
                <span>🔒 app.evolv.coach/{coachData?.firstName?.toLowerCase() || 'coach'}</span>
              </div>
              
              {/* Main Content */}
              <div className="relative bg-black w-full h-[calc(100%-16px)] overflow-hidden">
                {/* Background Media - Now with proper portrait media handling */}
                <div className="absolute inset-0">
                  {backgroundMedia ? (
                    <>
                      {/* Media Container for proper display */}
                      <div className="relative h-full w-full mx-auto">
                        {backgroundMediaType === 'video' ? (
                          <video 
                            src={backgroundMedia} 
                            className="w-full h-full object-cover" 
                            autoPlay 
                            muted 
                            loop 
                            playsInline
                          />
                        ) : (
                          <img 
                            src={backgroundMedia} 
                            alt="Background" 
                            className="w-full h-full object-cover"
                          />
                        )}
                      </div>
                      <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/40 to-black/70"></div>
                    </>
                  ) : (
                    <div className="absolute inset-0 bg-gradient-to-br from-accent-primary to-gray-900"></div>
                  )}
                </div>
                
                {/* Content */}
                <div className="relative h-full flex flex-col z-10">
                  {/* Logo/Name Area - 10% */}
                  <div className="h-[10%] flex items-center justify-center">
                    <div className="text-center">
                      <h1 className="text-xs font-bold text-white uppercase tracking-wide">
                        {coachData?.businessName || 
                         (coachData?.firstName && coachData?.lastName ? 
                          `${coachData.firstName} ${coachData.lastName}` : 
                          'ELEVATE')}
                      </h1>
                    </div>
                  </div>
                  
                  {/* Content Area - 30% */}
                  <div className="h-[30%] flex flex-col items-center justify-center px-3">
                    {/* Hero Title */}
                    <h2 className="text-sm font-bold text-white text-center mb-1.5">
                      <MarkdownTextRenderer text={heroTitle || "DON'T SLEEP ON YOUR POTENTIAL"} />
                    </h2>
                    
                    {/* Bio Text */}
                    <div className="overflow-y-auto max-h-full">
                      <p className="text-[10px] text-white/95 text-center leading-tight">
                        <MarkdownTextRenderer text={heroBio || "I help ambitious people achieve their fitness goals through personalized coaching and proven methods that fit your lifestyle."} />
                      </p>
                    </div>
                  </div>
                  
                  {/* Question Container - positioned at bottom of screen */}
                  <div className="h-[60%] flex flex-col justify-end pb-4 px-3">
                    {/* Question Overlay */}
                    <div className="bg-black/40 backdrop-blur-sm rounded-lg p-3">
                      <div className="mb-3 text-center">
                        <p className="text-xs text-white font-medium">
                          {dummyQuestion.title}
                        </p>
                      </div>
                      
                      {/* Question Options */}
                      <div className="space-y-2">
                        {dummyQuestion.options.map((option, index) => (
                          <div 
                            key={index}
                            className="bg-white/95 rounded-md p-2 text-center text-[10px] font-medium"
                          >
                            {option.label}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  
                  {/* Bottom Navigation Bar */}
                  <div className="absolute bottom-0 left-0 right-0 h-12 bg-black flex items-center justify-around border-t border-gray-800">
                    <span className="w-6 h-6 bg-gray-800 rounded-full"></span>
                    <span className="w-6 h-6 bg-gray-800 rounded-full"></span>
                    <span className="w-6 h-6 bg-gray-800 rounded-full"></span>
                    <span className="w-6 h-6 bg-gray-800 rounded-full"></span>
                    <span className="w-6 h-6 bg-gray-800 rounded-full"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default PreviewPanel; 