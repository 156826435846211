// src/components/ui/Switch.jsx

import React from 'react';
import { cn } from '../../lib/utils';

export const Switch = React.forwardRef(({ checked, onCheckedChange, className, ...props }, ref) => {
  return (
    <button
      ref={ref}
      role="switch"
      aria-checked={checked}
      onClick={() => onCheckedChange?.(!checked)}
      className={cn(
        "relative inline-flex h-5 w-9 md:h-6 md:w-11 items-center rounded-full transition-colors duration-200",
        "focus:outline-none focus:ring-2 focus:ring-accent-primary focus:ring-offset-2",
        checked 
          ? "bg-accent-primary hover:bg-hover-primary border border-accent-primary" 
          : "bg-background-secondary hover:bg-background-tertiary border border-accent-secondary",
        className
      )}
      {...props}
    >
      <span
        className={cn(
          "inline-block h-3.5 w-3.5 md:h-4 md:w-4 transform rounded-full",
          "shadow-sm transition-transform duration-200 ease-in-out",
          "bg-white",
          checked 
            ? "translate-x-5 md:translate-x-6 border border-accent-primary"
            : "translate-x-0.5 md:translate-x-1 border border-accent-secondary"
        )}
      />
    </button>
  );
});

Switch.displayName = "Switch";
