import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Button } from './ui/Button';
import { Copy, Home, MessageSquare, Calendar, User, Users } from 'lucide-react';

const NavigationBar = ({ isAdmin }) => {
  const [showCopiedToast, setShowCopiedToast] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleCopyAppLink = () => {
    navigator.clipboard.writeText('https://apps.apple.com/us/app/evolv/id6739216841');
    setShowCopiedToast(true);
    setTimeout(() => setShowCopiedToast(false), 2000);
  };

  const navItems = [
    { to: '/dashboard', icon: <Home size={20} />, label: 'Dashboard', dataTour: 'dashboard' },
    { to: '/messages', icon: <MessageSquare size={20} />, label: 'Messages', dataTour: 'messages' },
    { to: '/schedule', icon: <Calendar size={20} />, label: 'Calendar', dataTour: 'calendar' },
    { to: '/account', icon: <User size={20} />, label: 'Account', dataTour: 'account-tab' },
    ...(isAdmin ? [{ to: '/coaches', icon: <Users size={20} />, label: 'Coaches' }] : []),
  ];

  if (isMobile) {
    return (
      <nav className="fixed bottom-0 left-0 right-0 z-50 bg-background-secondary border-t border-border-light">
        <div className="flex justify-around items-center h-16">
          {navItems.map((item) => (
            <NavLink
              key={item.to}
              to={item.to}
              className={({ isActive }) =>
                `flex flex-col items-center justify-center p-2 ${
                  isActive ? 'text-accent-primary' : 'text-text-light'
                }`
              }
              data-tour={item.dataTour}
            >
              {item.icon}
              <span className="text-[10px] mt-1">{item.label}</span>
            </NavLink>
          ))}
        </div>
      </nav>
    );
  }

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-background-secondary border-b border-border-light">
      <div className="container mx-auto px-6 h-16 flex items-center justify-between">
        {/* Logo/Brand Section */}
        <div className="flex items-center">
          <Button
            variant="ghost"
            onClick={handleCopyAppLink}
            className="text-accent-tertiary hover:text-accent-primary hover:bg-background-tertiary flex items-center gap-2"
          >
            <Copy size={16} />
            <span className="font-body">Copy App Link</span>
          </Button>
        </div>

        {/* Navigation Links */}
        <div className="flex items-center space-x-1">
          {navItems.map((item) => (
            <NavLink
              key={item.to}
              to={item.to}
              data-tour={item.dataTour}
              className={({ isActive }) =>
                `px-4 py-2 rounded-md transition-all duration-300 flex items-center gap-2 ${
                  isActive
                    ? 'text-accent-primary bg-background-tertiary'
                    : 'text-text-primary hover:text-accent-primary hover:bg-background-tertiary'
                }`
              }
            >
              {item.icon}
              <span className="font-body">{item.label}</span>
            </NavLink>
          ))}
        </div>

        {/* Right Section (for symmetry) */}
        <div className="w-[140px]" />
      </div>

      {showCopiedToast && (
        <div className="fixed top-20 left-1/2 transform -translate-x-1/2 bg-accent-primary text-white px-4 py-2 rounded-md shadow-lg">
          Link copied to clipboard!
        </div>
      )}
    </nav>
  );
};

export default NavigationBar;