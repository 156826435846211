import React from 'react';

/**
 * Component to render text with markdown-style formatting:
 * - Bold: **bold text**
 * - Italic: *italic text*
 * 
 * @param {Object} props - Component props
 * @param {string} props.text - Text to render, may include formatting markers
 * @param {string} props.className - CSS classes to apply to the container
 * @returns {React.ReactElement} - Rendered component
 */
const MarkdownTextRenderer = ({ text, className }) => {
  if (!text) return null;
  
  // Ensure text is a string
  const textStr = String(text);
  
  // Only log in debug mode
  const isDebugMode = window.location.search.includes('debug=true');
  const debugLog = (message) => {
    if (isDebugMode) console.log(message);
  };
  
  debugLog(`[MarkdownTextRenderer] Processing: "${textStr}"`);
  debugLog(`[MarkdownTextRenderer] Text type: ${typeof text}, Text length: ${textStr.length}`);
  debugLog(`[MarkdownTextRenderer] Contains bold markers: ${textStr.includes('**')}`);
  
  // Two-pass approach:
  // 1. First handle bold text (with **) because it's more specific
  // 2. Then handle italic text (with *) in the non-bold segments
  
  // First pass: Handle bold text with **
  const boldPattern = /\*\*(.*?)\*\*/g;
  let segments = [];
  let lastIndex = 0;
  let match;
  
  // Find all bold matches and build segments
  while ((match = boldPattern.exec(textStr)) !== null) {
    debugLog(`[MarkdownTextRenderer] Found bold text: "${match[1]}"`);
    
    // Add any text before this match
    if (match.index > lastIndex) {
      segments.push({
        type: 'text',
        content: textStr.substring(lastIndex, match.index)
      });
    }
    
    // Add the bold text (without **)
    segments.push({
      type: 'bold',
      content: match[1]
    });
    
    // Update lastIndex to after this match
    lastIndex = match.index + match[0].length;
  }
  
  // Add any remaining text after the last match
  if (lastIndex < textStr.length) {
    segments.push({
      type: 'text',
      content: textStr.substring(lastIndex)
    });
  }
  
  // If no segments were created, use the entire text as one segment
  if (segments.length === 0) {
    segments.push({
      type: 'text',
      content: textStr
    });
  }
  
  // Second pass: Process italics in each text segment
  const processedSegments = segments.map(segment => {
    if (segment.type === 'bold') {
      return segment; // Keep bold segments as is
    }
    
    // Process italics in text segments
    const italicPattern = /\*(.*?)\*/g;
    const textContent = segment.content;
    let italicSegments = [];
    let lastItalicIndex = 0;
    let italicMatch;
    
    while ((italicMatch = italicPattern.exec(textContent)) !== null) {
      debugLog(`[MarkdownTextRenderer] Found italic text: "${italicMatch[1]}"`);
      
      // Add any text before this match
      if (italicMatch.index > lastItalicIndex) {
        italicSegments.push({
          type: 'plaintext',
          content: textContent.substring(lastItalicIndex, italicMatch.index)
        });
      }
      
      // Add the italic text (without *)
      italicSegments.push({
        type: 'italic',
        content: italicMatch[1]
      });
      
      // Update lastItalicIndex to after this match
      lastItalicIndex = italicMatch.index + italicMatch[0].length;
    }
    
    // Add any remaining text after the last match
    if (lastItalicIndex < textContent.length) {
      italicSegments.push({
        type: 'plaintext',
        content: textContent.substring(lastItalicIndex)
      });
    }
    
    // If no italic segments were created, return the original text segment
    if (italicSegments.length === 0) {
      return segment;
    }
    
    // Return processed italic segments
    return {
      type: 'italicized',
      segments: italicSegments
    };
  });
  
  debugLog(`[MarkdownTextRenderer] Processed segments: ${processedSegments.length}`);
  
  // Render the processed segments
  return (
    <span className={className}>
      {processedSegments.map((segment, i) => {
        if (segment.type === 'bold') {
          return <strong key={i} className="font-bold">{segment.content}</strong>;
        } else if (segment.type === 'text') {
          return <span key={i}>{segment.content}</span>;
        } else if (segment.type === 'italicized') {
          return (
            <span key={i}>
              {segment.segments.map((subSegment, j) => {
                if (subSegment.type === 'italic') {
                  return <em key={j}>{subSegment.content}</em>;
                } else {
                  return <span key={j}>{subSegment.content}</span>;
                }
              })}
            </span>
          );
        }
        return null;
      })}
    </span>
  );
};

export default MarkdownTextRenderer; 