// src/components/LoadingComponents.jsx


import React from 'react';
import { useLocation } from 'react-router-dom';

// Spinner component for consistent loading animation
const Spinner = () => (
  <div className="flex items-center justify-center">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#6495ED]" />
  </div>
);

// Full-page loading component for initial app load
export const FullPageLoader = () => (
  <div className="min-h-screen flex items-center justify-center bg-[#000000]">
    <Spinner />
  </div>
);

// Page transition loader that overlays current content
export const PageTransitionLoader = () => (
  <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center backdrop-blur-sm transition-all duration-300">
    <Spinner />
  </div>
);

// Hook to handle route change loading states
export const usePageLoading = () => {
  const [isChangingPage, setIsChangingPage] = React.useState(false);
  const location = useLocation();
  
  React.useEffect(() => {
    setIsChangingPage(true);
    // Short timeout to ensure loader shows even for quick transitions
    const timeout = setTimeout(() => {
      setIsChangingPage(false);
    }, 300);
    
    return () => clearTimeout(timeout);
  }, [location]);
  
  return isChangingPage;
};

export default {
  FullPageLoader,
  PageTransitionLoader,
  usePageLoading
};