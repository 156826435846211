// src/components/AccountPage.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs2 as Tabs, TabsContent, TabsList, TabsTrigger } from './ui/Tabs';
import { Button } from './ui/Button';
import { Settings, MessageCircle } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import AIChatOverlay from './AIChatOverlay';
import { buttonStyles } from './ui/Styles';

import NutritionPhilosophyEditor from './AccountPageTabs/NutritionPhilosophyEditor';
import ProductsTab from './AccountPageTabs/ProductsTab';
import MessagingTab from './AccountPageTabs/MessagingTab';
import RoutineBuilder from './AccountPageTabs/RoutineBuilder';

const AccountPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("nutrition");
  const [isFirstSignup, setIsFirstSignup] = useState(false);
  const [showChatOverlay, setShowChatOverlay] = useState(false);
  const [demoMessages, setDemoMessages] = useState([]);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [demoMessages]);
  
  useEffect(() => {
    const loadDemoMessages = async () => {
      if (!user) return;
      
      try {
        const demoMessagesRef = collection(db, 'coaches', user.uid, 'demoMessages');
        const messagesSnap = await getDocs(demoMessagesRef);
        const messages = messagesSnap.docs
          .map(doc => doc.data())
          .sort((a, b) => a.timestamp?.seconds - b.timestamp?.seconds);
        setDemoMessages(messages);
      } catch (error) {
        console.error('Error loading demo messages:', error);
      }
    };

    loadDemoMessages();
  }, [user]);

  return (
    <div className="min-h-screen w-full bg-background-primary relative">
      <button 
        className="fixed top-6 right-6 z-50 p-3 rounded-lg hover:bg-background-tertiary transition-colors text-text-primary"
        onClick={() => navigate('/account-settings')}
      >
        <Settings className="w-6 h-6" />
      </button>

      {showChatOverlay && (
        <AIChatOverlay 
          initialMessages={demoMessages}
          onClose={() => {
            setShowChatOverlay(false);
          }}
        />
      )}

      <div className="container mx-auto px-4 pt-20 pb-0 max-w-[1400px]">
        <Tabs value={activeTab} onValueChange={setActiveTab}>
          <TabsList className="grid w-full grid-cols-4 bg-background-secondary rounded-lg p-1 mb-6">
            <TabsTrigger 
              value="nutrition"
              className="font-body text-text-primary data-[state=active]:bg-accent-primary data-[state=active]:text-white"
            >
              Nutrition
            </TabsTrigger>
            <TabsTrigger 
              value="routines"
              className="font-body text-text-primary data-[state=active]:bg-accent-primary data-[state=active]:text-white"
            >
              Routines
            </TabsTrigger>
            <TabsTrigger 
              value="products"
              className="font-body text-text-primary data-[state=active]:bg-accent-primary data-[state=active]:text-white"
            >
              Products
            </TabsTrigger>
            <TabsTrigger 
              value="messaging"
              className="font-body text-text-primary data-[state=active]:bg-accent-primary data-[state=active]:text-white"
            >
              Messaging
            </TabsTrigger>
          </TabsList>

          <TabsContent value="nutrition" className="mb-0">
            <NutritionPhilosophyEditor userId={user?.uid} />
          </TabsContent>
          <TabsContent value="routines" className="mb-0">
            <RoutineBuilder userId={user?.uid} />
          </TabsContent>
          <TabsContent value="products" className="mb-0">
            <ProductsTab userId={user?.uid} isFirstSignup={isFirstSignup} />
          </TabsContent>
          <TabsContent value="messaging" className="mb-0">
            <MessagingTab userId={user?.uid} />
          </TabsContent>
        </Tabs>
      </div>

      <Button
        onClick={() => setShowChatOverlay(true)}
        className={`${buttonStyles.primary} fixed bottom-20 md:bottom-8 right-8 shadow-lg flex items-center gap-2 z-50 rounded-full`}
      >
        <MessageCircle className="w-5 h-5" />
        <span>Test your AI</span>
      </Button>
    </div>
  );
};

export default AccountPage;