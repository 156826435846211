// src/components/NudgeManager.jsx


import React, { useState } from 'react';
import { X, Plus, Clock } from 'lucide-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Input } from './ui/Input';
import { Button } from './ui/Button';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { Label } from './ui/Label';
import { collection, addDoc, deleteDoc, doc, Timestamp, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

const NudgeManager = ({ nudges, onClose, onUpdateNudges, clientId, coachFirstName }) => {
  const [newNudgeText, setNewNudgeText] = useState('');
  const [newNudgeTime, setNewNudgeTime] = useState(new Date());

  const handleAddNudge = async () => {
    if (newNudgeText.trim()) {
      try {
        const prefixedNudgeText = `${coachFirstName}: ${newNudgeText.trim()}`;

        if (newNudgeTime <= new Date()) {
          alert('Please select a future date and time.');
          return;
        }

        const nudge = {
          text: prefixedNudgeText,
          scheduledTime: Timestamp.fromDate(newNudgeTime),
          sent: false,
          createdAt: Timestamp.now(),
        };

        const nudgesCollection = collection(db, 'users', clientId, 'nudges');
        await addDoc(nudgesCollection, nudge);

        setNewNudgeText('');
        setNewNudgeTime(new Date());
        onUpdateNudges();
      } catch (error) {
        console.error('Error adding nudge:', error);
        alert('Error adding nudge: ' + error.message);
      }
    } else {
      alert('Please enter nudge text.');
    }
  };

  const handleDeleteNudge = async (nudgeId) => {
    try {
      await deleteDoc(doc(db, 'users', clientId, 'nudges', nudgeId));
      onUpdateNudges();
    } catch (error) {
      console.error('Error deleting nudge:', error);
      alert('Error deleting nudge: ' + error.message);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <Card className="w-full max-w-xl max-h-[90vh] shadow-xl">
        <CardHeader className="border-b border-neutral-800">
          <div className="flex items-center justify-between">
            <CardTitle>Manage Nudges</CardTitle>
            <button 
              onClick={onClose}
              className="h-8 w-8 rounded-full flex items-center justify-center text-white hover:bg-neutral-800"
            >
              <X className="h-4 w-4" />
            </button>
          </div>
        </CardHeader>
        
        <CardContent className="flex flex-col h-[calc(90vh-8rem)] pt-6">
          <div className="flex-1 overflow-y-auto mb-6 pr-2 space-y-3">
            {nudges.length > 0 ? (
              nudges.map((nudge) => (
                <div
                  key={nudge.id}
                  className="group flex items-start gap-4 p-4 rounded-lg bg-neutral-800/50 hover:bg-neutral-800 transition-colors"
                >
                  <div className="flex-1 min-w-0">
                    <p className="text-neutral-100 font-medium break-words">{nudge.text}</p>
                    <div className="flex items-center gap-2 mt-2 text-sm text-neutral-400">
                      <Clock className="h-4 w-4" />
                      <span>{nudge.scheduledTime.toDate().toLocaleString()}</span>
                    </div>
                  </div>
                  <Button
                    size="sm"
                    variant="ghost"
                    onClick={() => handleDeleteNudge(nudge.id)}
                    className="opacity-0 group-hover:opacity-100 transition-opacity hover:bg-red-500/20 hover:text-red-400"
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </div>
              ))
            ) : (
              <div className="flex flex-col items-center justify-center h-32 text-neutral-400">
                <p>No nudges scheduled</p>
                <p className="text-sm mt-1">Create one using the form below</p>
              </div>
            )}
          </div>

          <div className="border-t border-neutral-800 pt-6 space-y-4">
            <div className="space-y-4">
              <div>
                <Label htmlFor="nudgeText" className="text-sm text-neutral-300 mb-1.5">
                  Message
                </Label>
                <Input
                  id="nudgeText"
                  value={newNudgeText}
                  onChange={(e) => setNewNudgeText(e.target.value)}
                  placeholder="Enter your nudge message..."
                  className="w-full bg-neutral-800 border-neutral-700"
                />
              </div>
              
              <div>
                <Label htmlFor="nudgeTime" className="text-sm text-neutral-300 mb-1.5">
                  Schedule Time
                </Label>
                <DatePicker
                  id="nudgeTime"
                  selected={newNudgeTime}
                  onChange={(date) => setNewNudgeTime(date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  className="w-full p-2 bg-neutral-800 border border-neutral-700 rounded-md text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                  wrapperClassName="w-full"
                />
              </div>
            </div>

            <Button 
              onClick={handleAddNudge}
              className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 flex items-center justify-center gap-2"
            >
              <Plus className="h-5 w-5" />
              <span>Add Nudge</span>
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default NudgeManager;