// src/components/CoachProfile.jsx


import React, { useState, useEffect } from 'react';
import {
  getDoc,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  getDocs,
  collection,
  query,
  where,
} from 'firebase/firestore';
import { db, functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';
import { Input } from './ui/Input';
import { Button } from './ui/Button';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { Plus, X } from 'lucide-react';
import ActivateClient from './ActivateClient';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const CoachProfile = () => {
  const { coachId } = useParams();
  const navigate = useNavigate();
  const { isAdmin } = useAuth();
  const [coachData, setCoachData] = useState(null);
  const [clients, setClients] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isActivatingClient, setIsActivatingClient] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (coachId) {
      loadCoachData();
    }
  }, [coachId]);

  const loadCoachData = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const coachDoc = await getDoc(doc(db, 'coaches', coachId));
      if (coachDoc.exists()) {
        const data = coachDoc.data();
        setCoachData(data);

        // Fetch clients using query instead of array
        const clientsQuery = query(
          collection(db, 'users'),
          where('coachId', '==', coachId)
        );
        const clientsSnapshot = await getDocs(clientsQuery);
        const clientsList = clientsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setClients(clientsList);
      } else {
        setError('Coach not found');
      }
    } catch (error) {
      console.error('Error loading coach data:', error);
      setError('Error loading coach data: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const addClient = async (clientId) => {
    try {
      // Update the client document with the coach ID
      await updateDoc(doc(db, 'users', clientId), {
        coachId: coachId
      });
      
      // Increment the coach's client count
      await updateDoc(doc(db, 'coaches', coachId), {
        numberOfClients: (coachData.numberOfClients || 0) + 1
      });
      
      await loadCoachData();
    } catch (error) {
      console.error('Error adding client:', error);
      setError('Error adding client: ' + error.message);
    }
  };

  const removeClient = async (clientId) => {
    try {
      // Remove coach ID from client document
      await updateDoc(doc(db, 'users', clientId), {
        coachId: null
      });
      
      // Decrement the coach's client count
      await updateDoc(doc(db, 'coaches', coachId), {
        numberOfClients: Math.max((coachData.numberOfClients || 1) - 1, 0)
      });
      
      setClients(clients.filter(client => client.id !== clientId));
      await loadCoachData();
    } catch (error) {
      console.error('Error removing client:', error);
      setError('Error removing client: ' + error.message);
    }
  };

  const handleBack = () => {
    navigate('/coaches');
  };

  // Rest of your component code remains the same...

  return (
    <div className="bg-[#000000] min-h-screen text-[#E0E0E0] flex flex-col w-full">
      <div className="p-6">
        <Button 
          variant="ghost" 
          className="text-[#FFFFFF] mb-4" 
          onClick={handleBack}
        >
          Back to Coaches
        </Button>

        {loading ? (
          <div className="flex justify-center p-4">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#6495ED]" />
          </div>
        ) : error ? (
          <div className="text-red-500 p-4">{error}</div>
        ) : (
          <Card className="bg-[#1C1C1C] border-0 w-full">
            <CardHeader className="flex flex-row items-start justify-between p-4">
              <CardTitle className="text-lg font-semibold text-[#FFFFFF]">
                {coachData?.email || 'Coach Profile'}
              </CardTitle>
              <div className="flex space-x-2">
                <Button
                  size="icon"
                  variant="ghost"
                  className="rounded-md bg-[#333333] text-[#FFFFFF] hover:bg-[#6495ED]"
                  onClick={() => setIsActivatingClient(true)}
                >
                  Activate
                </Button>
                <Button
                  size="icon"
                  variant="ghost"
                  className="rounded-md bg-[#333333] text-[#FFFFFF] hover:bg-[#6495ED]"
                  onClick={() => setIsSearching(!isSearching)}
                >
                  {isSearching ? <X className="h-4 w-4" /> : <Plus className="h-4 w-4" />}
                </Button>
              </div>
            </CardHeader>
            
            <CardContent>
              {/* Search section */}
              {isSearching && (
                <div className="mb-4 flex">
                  <Input
                    placeholder="Search by phone number"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="rounded-l-md border-r-0 bg-[#333333] text-[#E0E0E0] border-[#6495ED]"
                  />
                  <Button
                    onClick={searchClient}
                    className="rounded-l-none bg-[#6495ED] text-[#FFFFFF] hover:bg-[#4F7BE8]"
                  >
                    Add
                  </Button>
                </div>
              )}

              {/* Clients list */}
              <div className="space-y-2">
                {clients.map((client) => (
                  <div
                    key={client.id}
                    className="flex justify-between items-center bg-[#333333] p-2 rounded"
                  >
                    <div>
                      <div className="text-[#FFFFFF]">{client.email || client.phoneNumber}</div>
                      <div>{client.phoneNumber}</div>
                    </div>
                    <Button
                      size="icon"
                      variant="ghost"
                      onClick={() => removeClient(client.id)}
                      className="text-[#E0E0E0] hover:text-[#6495ED]"
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        )}

        {isActivatingClient && (
          <ActivateClient
            isOpen={isActivatingClient}
            onClose={() => setIsActivatingClient(false)}
            onActivate={activateClient}
          />
        )}
      </div>
    </div>
  );
};

export default CoachProfile;