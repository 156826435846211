import React, { useState, useEffect } from 'react';
import { Search, X, Plus, Calendar, AlertCircle } from 'lucide-react';
import { Input } from '../../../ui/Input';
import { Button } from '../../../ui/Button';
import DatePicker from './DatePicker';

/**
 * InjurySelector component that allows users to search for and select injuries,
 * then specify severity and expected recovery date
 */
const InjurySelector = ({ value = [], onChange, question }) => {
  const [injuries, setInjuries] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedInjury, setSelectedInjury] = useState(null);
  const [severity, setSeverity] = useState('');
  const [recoveryDate, setRecoveryDate] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  // Access the injury options and severity options from the question prop
  const injuryOptions = question?.injuryOptions || [];
  const severityOptions = question?.severityOptions || [];

  // Initialize injuries from value prop
  useEffect(() => {
    if (Array.isArray(value)) {
      setInjuries(value);
    } else {
      setInjuries([]);
    }
  }, []);

  // Filter injury options based on search term
  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredOptions(injuryOptions);
    } else {
      const filtered = injuryOptions.filter(option => 
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredOptions(filtered);
    }
  }, [searchTerm, injuryOptions]);

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setShowDropdown(true);
  };

  // Handle selecting an injury from the dropdown
  const handleSelectInjury = (option) => {
    setSelectedInjury(option);
    setSearchTerm('');
    setShowDropdown(false);
  };

  // Handle selecting a severity
  const handleSelectSeverity = (severityValue) => {
    setSeverity(severityValue);
  };

  // Handle recovery date change
  const handleRecoveryDateChange = (date) => {
    setRecoveryDate(date);
  };

  // Add the current injury to the list
  const handleAddInjury = () => {
    if (selectedInjury && severity) {
      const newInjury = {
        id: `injury_${Date.now()}`,
        name: selectedInjury.label,
        value: selectedInjury.value,
        severity: severity,
        recoveryDate: recoveryDate || null,
        date: new Date().toISOString().split('T')[0]
      };
      
      const updatedInjuries = [...injuries, newInjury];
      setInjuries(updatedInjuries);
      onChange(updatedInjuries);
      
      // Reset selection
      setSelectedInjury(null);
      setSeverity('');
      setRecoveryDate('');
    }
  };

  // Remove an injury by index
  const handleRemoveInjury = (indexToRemove) => {
    const updatedInjuries = injuries.filter((_, index) => index !== indexToRemove);
    setInjuries(updatedInjuries);
    onChange(updatedInjuries);
  };

  return (
    <div className="w-full">
      {/* List of already added injuries */}
      {injuries.length > 0 && (
        <div className="mb-6">
          <h4 className="text-base font-medium mb-2">Your current injuries/limitations:</h4>
          <div className="space-y-3">
            {injuries.map((injury, index) => (
              <div 
                key={injury.id || index} 
                className="flex items-start justify-between bg-background-light p-3 rounded-md"
              >
                <div>
                  <div className="flex items-center">
                    <span className="font-medium">{injury.name}</span>
                    <span className="ml-2 text-sm py-0.5 px-2 rounded-full bg-background-dark/10">
                      {severityOptions.find(opt => opt.value === injury.severity)?.label || injury.severity}
                    </span>
                  </div>
                  {injury.recoveryDate && (
                    <div className="text-sm text-text-light flex items-center mt-1">
                      <Calendar size={14} className="mr-1" />
                      Expected recovery: {injury.recoveryDate}
                    </div>
                  )}
                </div>
                <button
                  type="button"
                  onClick={() => handleRemoveInjury(index)}
                  className="text-text-light hover:text-danger p-1"
                  aria-label={`Remove ${injury.name}`}
                >
                  <X size={16} />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Injury search and selection */}
      <div className="mb-4 relative">
        <div className="flex items-center relative">
          <Search size={18} className="absolute left-3 text-text-light" />
          <Input
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search for an injury or limitation..."
            className="pl-10"
            onFocus={() => setShowDropdown(true)}
            onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
          />
        </div>
        
        {/* Dropdown menu */}
        {showDropdown && filteredOptions.length > 0 && (
          <div className="absolute z-10 mt-1 w-full bg-white rounded-md shadow-lg max-h-60 overflow-auto">
            {filteredOptions.map((option) => (
              <div
                key={option.value}
                className="px-4 py-2 hover:bg-background-light cursor-pointer"
                onMouseDown={() => handleSelectInjury(option)}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Selected injury details */}
      {selectedInjury && (
        <div className="border border-border-light rounded-md p-4 mb-4">
          <h4 className="font-medium mb-3">Tell us about your {selectedInjury.label}</h4>
          
          {/* Severity selection */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">
              How severe is this injury/limitation?
            </label>
            <div className="space-y-2">
              {severityOptions.map((option) => (
                <label 
                  key={option.value} 
                  className="flex items-center space-x-2 cursor-pointer"
                >
                  <input
                    type="radio"
                    name="severity"
                    value={option.value}
                    checked={severity === option.value}
                    onChange={() => handleSelectSeverity(option.value)}
                    className="form-radio"
                  />
                  <span>{option.label}</span>
                </label>
              ))}
            </div>
          </div>
          
          {/* Recovery date */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">
              Expected recovery date (if known)
            </label>
            <DatePicker
              value={recoveryDate}
              onChange={handleRecoveryDateChange}
              min={new Date().toISOString().split('T')[0]}
              placeholder="Select a date (optional)"
            />
          </div>
          
          <Button
            onClick={handleAddInjury}
            disabled={!severity}
            className="w-full"
          >
            <Plus size={16} className="mr-1" />
            Add {selectedInjury.label}
          </Button>
        </div>
      )}

      {/* Instructions */}
      {!selectedInjury && injuries.length === 0 && (
        <div className="flex items-center text-text-light mt-4">
          <AlertCircle size={16} className="mr-2" />
          <span>Search and select any injuries or physical limitations you currently have</span>
        </div>
      )}
    </div>
  );
};

export default InjurySelector; 