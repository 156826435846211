import React from 'react';
import { Card, CardContent } from '../ui/Card';
import { Folder, Trash2, ChevronLeft, Plus } from 'lucide-react';
import { Button } from '../ui/Button';

const FolderCard = ({ folder, onSelect, onDelete, isBackButton = false, onBack, onAddTemplate }) => {
  const handleDelete = (e) => {
    e.stopPropagation();
    if (window.confirm('Are you sure you want to delete this folder?')) {
      onDelete(folder);
    }
  };

  if (isBackButton) {
    return (
      <Card
        onClick={onBack}
        className="group hover:border-accent-primary/30 transition-all duration-300 cursor-pointer"
      >
        <CardContent className="p-4">
          <div className="flex items-center gap-3">
            <div className="flex-shrink-0 w-10 h-10 rounded-lg bg-accent-primary/10 flex items-center justify-center">
              <ChevronLeft className="h-5 w-5 text-accent-primary" />
            </div>
            <div>
              <h3 className="font-medium text-text-primary">
                Back to All Folders
              </h3>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card
      onClick={() => onSelect(folder)}
      className="group hover:border-accent-primary/30 transition-all duration-300 cursor-pointer"
    >
      <CardContent className="p-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <div className="flex-shrink-0 w-10 h-10 rounded-lg bg-accent-primary/10 flex items-center justify-center">
              <Folder className="h-5 w-5 text-accent-primary" />
            </div>
            <div>
              <h3 className="font-medium text-text-primary">
                {folder.name}
              </h3>
              <p className="text-sm text-text-light">
                {folder.items?.length || 0} workouts
              </p>
            </div>
          </div>
          <div className="flex items-center gap-2">
            {onAddTemplate && folder.type === 'workout' && (
              <Button
                variant="ghost"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  onAddTemplate(folder);
                }}
                className="opacity-0 group-hover:opacity-100"
              >
                <Plus className="h-4 w-4 mr-1" />
                Add Template
              </Button>
            )}
            <Button
              variant="ghost"
              size="icon"
              onClick={handleDelete}
              className="opacity-0 group-hover:opacity-100 text-red-500 hover:text-red-600 hover:bg-red-50"
            >
              <Trash2 className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default FolderCard; 