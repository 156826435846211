import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from './ui/Button';

const NavigationBar = ({ isAdmin }) => {
  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-black py-4 border-b-0">
      <div className="flex justify-center">
        <div className="space-x-4">
          <NavLink to="/dashboard">
            {({ isActive }) => (
              <Button
                variant="ghost"
                className={`${
                  isActive ? 'text-blue-500' : 'text-white'
                } hover:bg-neutral-800`}
              >
                Dashboard
              </Button>
            )}
          </NavLink>

          {isAdmin && (
            <NavLink to="/coaches">
              {({ isActive }) => (
                <Button
                  variant="ghost"
                  className={`${
                    isActive ? 'text-blue-500' : 'text-white'
                  } hover:bg-neutral-800`}
                >
                  Coaches
                </Button>
              )}
            </NavLink>
          )}

          <NavLink to="/messages">
            {({ isActive }) => (
              <Button
                variant="ghost"
                className={`${
                  isActive ? 'text-blue-500' : 'text-white'
                } hover:bg-neutral-800`}
              >
                Messages
              </Button>
              )}
          </NavLink>

          <NavLink to="/schedule">
            {({ isActive }) => (
              <Button
                variant="ghost"
                className={`${
                  isActive ? 'text-blue-500' : 'text-white'
                } hover:bg-neutral-800`}
              >
                Calendar
              </Button>
            )}
          </NavLink>

          <NavLink to="/account">
            {({ isActive }) => (
              <Button
                variant="ghost"
                className={`${
                  isActive ? 'text-blue-500' : 'text-white'
                } hover:bg-neutral-800`}
              >
                Account
              </Button>
            )}
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;