// src/components/TemplateCreation.jsx

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../contexts/AuthContext';
import { Button } from './ui/Button';
import { Input } from './ui/Input';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { Plus, Trash2, Image as ImageIcon, Edit, FileText, X, Flame, Dumbbell, Wind } from 'lucide-react';
import ExerciseSearch from './ExerciseSearch';
import { createWorkoutTemplate, getWorkoutTemplate, updateWorkoutTemplate } from '../services/workoutTemplateService';
import  ModifyExerciseOverlay  from './overlays/ModifyExerciseOverlay';
import  CreateExerciseOverlay  from './overlays/CreateExerciseOverlay';
import { getAllExercises } from '../services/exerciseService';
import { modifyExercise, createCustomExercise } from '../services/exerciseService';
import ExpandedSetsEditor from './ExpandedSetsEditor';
import { Loader2 } from 'lucide-react';

window.onerror = function(msg, url, lineNo, columnNo, error) {
  console.warn('GLOBAL ERROR CAUGHT:', {
      message: msg,
      url: url,
      lineNo: lineNo,
      columnNo: columnNo,
      error: error
  });
  return false;
};


const TemplateCreation = ({ isEdit }) => {
  const { user } = useAuth();
  const { templateId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { returnToAssignment, onComplete } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [headerImage, setHeaderImage] = useState(null);
  const [headerImagePreview, setHeaderImagePreview] = useState(null);
  const [showExerciseEdit, setShowExerciseEdit] = useState(false);
  const [exerciseToEdit, setExerciseToEdit] = useState(null);
  const [showModifyOverlay, setShowModifyOverlay] = useState(false);
  const [showCreateOverlay, setShowCreateOverlay] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [allExercises, setAllExercises] = useState([]);

  useEffect(() => {
    const fetchExercises = async () => {
      try {
        const exercises = await getAllExercises(user.uid);
        setAllExercises(exercises);
      } catch (error) {
        console.error('Error fetching exercises:', error, {
          userId: user.uid,
          timestamp: new Date().toISOString(),
          stack: error.stack
        });
      }
    };
  
    fetchExercises();
  }, [user.uid]);
    

  const [templateData, setTemplateData] = useState(() => {
    try {
      if (isEdit) {
        const saved = sessionStorage.getItem("templateData");
        if (saved) {
          return JSON.parse(saved);
        }
      }
    } catch(error) {
      console.error('Error getting template data from sessionStorage:', error);
    }
    return {
      name: '',
      description: '',
      headerImageUrl: '',
      workoutStructure: [], // Changed from sections
      exercises: [],
    };
  });

  // Clear session storage when component unmounts
  useEffect(() => {
    return () => {
      if (!isEdit) {
        sessionStorage.removeItem("templateData");
      }
    };
  }, [isEdit]);

  // keep saving form state to session storage when the form changes
  useEffect(() => {
    try {
      sessionStorage.setItem("templateData", JSON.stringify(templateData));
    } catch (error) {
      console.error('Error saving to sessionStorage:', error);
    }
  }, [templateData]);

  useEffect(() => {
    let isMounted = true;

    const fetchWorkoutTemplate = async () => {
      try {
        const workout = await getWorkoutTemplate(user.uid, templateId);

        if (workout) {
          setTemplateData({
            name: workout.name,
            description: workout.description,
            headerImageUrl: workout.headerImageUrl,
            workoutStructure: workout.workoutStructure.map(section => ({
              ...section,
              exercises: workout.exercises
                .filter(exercise => exercise.workoutStructureId == section.id)
                .map(exercise => ({
                  exerciseId: exercise.exerciseId,
                  sets: exercise.sets,
                  rest: exercise.rest,
                  notes: exercise.notes,
                  order: exercise.order
                }))
            })),
            exercises: workout.exercises
          });
          setHeaderImagePreview(workout.headerImageUrl);
        }
      } catch (error) {
        console.error('Error fetching template:', error, {
        templateId,
        userId: user.uid,
        timestamp: new Date().toISOString()
      });
      }
    }

    if (isEdit) {
      fetchWorkoutTemplate()
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setHeaderImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setHeaderImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const addSection = (type) => {
    const newSection = {
      id: `${type}-${Date.now()}`,
      type,
      name: type === 'warmup' ? 'Warm Up' 
           : type === 'cooldown' ? 'Cool Down' 
           : `Block ${String.fromCharCode(65 + templateData.workoutStructure.filter(s => s.type === 'block').length)}`,
      order: templateData.workoutStructure.length,
      executionStyle: 'sequential', // Default execution style
      circuitRounds: type === 'block' ? 1 : undefined // Only for blocks
    };
  
    setTemplateData(prev => {
      let newWorkoutStructure;
      if (type === 'warmup') {
        newWorkoutStructure = [newSection, ...prev.workoutStructure];
      } else {
        newWorkoutStructure = [...prev.workoutStructure, newSection];
      }
      return { ...prev, workoutStructure: newWorkoutStructure };
    });
  };

  const removeSection = (sectionId) => {
    setTemplateData(prev => ({
      ...prev,
      workoutStructure: prev.workoutStructure.filter(section => section.id !== sectionId),
      exercises: prev.exercises.filter(exercise => exercise.workoutStructureId !== sectionId)
    }));
  };

  const addExercise = (sectionId) => {
    const newExercise = {
      id: `exercise-${Date.now()}`,
      exerciseId: '',
      workoutStructureId: sectionId,
      isCustom: false,
      order: templateData.exercises.filter(e => e.workoutStructureId === sectionId).length,
      sets: Array(3).fill().map((_, index) => ({
        id: `set-${Date.now()}-${index}`,
        setNumber: index + 1,
        type: 'weight', // Default type
        targetValue: 0,
        reps: { min: 8, max: 12 }, // Default rep range
        setCategory: 'working',
        rest: 60,
        targetRPE: 7 // Default RPE
      })),
      notes: '',
      rest: 60
    };

    setTemplateData(prev => ({
      ...prev,
      exercises: [...prev.exercises, newExercise]
    }));
  };

  const updateExercise = (sectionId, exerciseId, field, value) => {
    setTemplateData(prev => ({
      ...prev,
      exercises: prev.exercises.map(exercise => 
        exercise.id === exerciseId 
          ? { ...exercise, [field]: value }
          : exercise
      )
    }));
  };

  const removeExercise = (sectionId, exerciseId) => {
    setTemplateData(prev => ({
      ...prev,
      exercises: prev.exercises.filter(exercise => exercise.id !== exerciseId)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      let headerImageUrl = templateData.headerImageUrl;
      if (headerImage) {
        const imageRef = ref(storage, `coaches/${user.uid}/images/templates/${Date.now()}-${headerImage.name}`);
        await uploadBytes(imageRef, headerImage);
        headerImageUrl = await getDownloadURL(imageRef);
      }
  
      const formattedTemplateData = {
        name: templateData.name,
        headerImageUrl,
        description: templateData.description,
        workoutStructure: templateData.workoutStructure,
        exercises: templateData.exercises
      };
  
      let savedTemplate;
      if (isEdit) {
        await updateWorkoutTemplate(user.uid, templateId, formattedTemplateData);
      } else {
        savedTemplate = await createWorkoutTemplate(user.uid, formattedTemplateData);
      }

      sessionStorage.removeItem("templateData");

      // Navigate back to template management
      navigate('/templates');
    } catch (error) {
      console.error('Error saving template:', error);
      // TODO: Add error notification to user
    } finally {
      setLoading(false);
    }
  };

  const handleEditExercise = (exercise) => {
    setSelectedExercise(exercise);
    if (exercise) {
      setShowModifyOverlay(true);
    } else {
      setShowCreateOverlay(true);
    }
  };
  
  const handleSaveExercise = async (formData) => {
    try {
      let savedExercise;
      if (selectedExercise) {
        // Modifying existing exercise
        savedExercise = await modifyExercise(user.uid, selectedExercise.id, formData);

        setAllExercises(prev => prev.map(ex => 
          ex.id === selectedExercise.id 
            ? { ...ex, ...formData, isModified: true }
            : ex
        ));
  
      } else {
        // Creating new exercise
        savedExercise = await createCustomExercise(user.uid, formData);

        setAllExercises(prev => [...prev, { ...savedExercise, isCustom: true }]);
      }

      const exerciseId = selectedExercise ? selectedExercise.id : savedExercise.id;
      
    

    } catch (error) {
      console.error('Error saving exercise:', error);
    }
  };

  return (
    <div className="min-h-screen bg-background-primary pt-20">
      <div className="container mx-auto p-4 max-w-6xl">
        <Card className="bg-background-secondary shadow-lg">
          {/* Header */}
          <div className="border-b border-border-light/20 p-6">
            <h2 className="text-2xl font-semibold text-text-primary">
              {isEdit ? 'Edit Template' : 'Create New Template'}
            </h2>
          </div>

          {/* Content */}
          <div className="p-6">
            <form onSubmit={handleSubmit} className="space-y-8">
              {/* Left Column - Basic Info */}
              <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
                <div className="lg:col-span-4 space-y-6">
                  <Card>
                    <CardHeader>
                      <CardTitle className="text-xl flex items-center gap-2">
                        <FileText className="h-5 w-5 text-accent-primary" />
                        Basic Information
                      </CardTitle>
                    </CardHeader>
                    <CardContent className="space-y-4">
                      <div className="space-y-2">
                        <label className="text-sm font-medium text-text-primary">Template Name</label>
                        <Input
                          placeholder="Enter template name"
                          value={templateData.name}
                          onChange={(e) => setTemplateData(prev => ({ ...prev, name: e.target.value }))}
                          className="bg-background-tertiary border-border-light focus:border-accent-primary"
                          required
                        />
                      </div>
                      <div className="space-y-2">
                        <label className="text-sm font-medium text-text-primary">Description</label>
                        <textarea
                          placeholder="Enter template description"
                          value={templateData.description}
                          onChange={(e) => setTemplateData(prev => ({ ...prev, description: e.target.value }))}
                          className="w-full min-h-[100px] rounded-lg bg-background-tertiary border-border-light focus:border-accent-primary p-3"
                        />
                      </div>
                    </CardContent>
                  </Card>

                  <Card>
                    <CardHeader>
                      <CardTitle className="text-xl flex items-center gap-2">
                        <ImageIcon className="h-5 w-5 text-accent-primary" />
                        Header Image
                      </CardTitle>
                    </CardHeader>
                    <CardContent className="space-y-4">
                      <Button
                        type="button"
                        onClick={() => document.getElementById('header-image').click()}
                        className="w-full bg-background-tertiary hover:bg-accent-primary/10 text-text-primary border-2 border-dashed border-border-light"
                      >
                        <ImageIcon className="h-4 w-4 mr-2" />
                        Choose Image
                      </Button>
                      <input
                        id="header-image"
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        className="hidden"
                      />
                      {headerImagePreview && (
                        <div className="relative rounded-lg overflow-hidden">
                          <img
                            src={headerImagePreview}
                            alt="Header preview"
                            className="w-full h-40 object-cover"
                          />
                          <Button
                            type="button"
                            onClick={() => {
                              setHeaderImage(null);
                              setHeaderImagePreview(null);
                            }}
                            className="absolute top-2 right-2 bg-background-secondary/80 hover:bg-background-secondary p-2 rounded-full"
                          >
                            <X className="h-4 w-4" />
                          </Button>
                        </div>
                      )}
                    </CardContent>
                  </Card>
                </div>

                {/* Right Column - Sections */}
                <div className="lg:col-span-8 space-y-6">
                  <div className="flex gap-2 mb-4">
                    {!templateData.workoutStructure.some(s => s.type === 'warmup') && (
                      <Button
                        type="button"
                        onClick={() => addSection('warmup')}
                        className="bg-accent-primary/10 text-accent-primary hover:bg-accent-primary/20"
                      >
                        <Plus className="h-4 w-4 mr-2" />
                        Add Warmup
                      </Button>
                    )}
                    <Button
                      type="button"
                      onClick={() => addSection('block')}
                      className="bg-accent-primary/10 text-accent-primary hover:bg-accent-primary/20"
                    >
                      <Plus className="h-4 w-4 mr-2" />
                      Add Block
                    </Button>
                    {!templateData.workoutStructure.some(s => s.type === 'cooldown') && (
                      <Button
                        type="button"
                        onClick={() => addSection('cooldown')}
                        className="bg-accent-primary/10 text-accent-primary hover:bg-accent-primary/20"
                      >
                        <Plus className="h-4 w-4 mr-2" />
                        Add Cooldown
                      </Button>
                    )}
                  </div>

                  {/* Sections */}
                  {templateData.workoutStructure.map((section) => (
                    <Card key={section.id}>
                      <CardHeader className="flex flex-row items-center justify-between">
                        <div className="flex items-center gap-4 flex-1">
                          <CardTitle className="text-xl capitalize flex items-center gap-2">
                            {section.type === 'warmup' && <Flame className="h-5 w-5 text-orange-500" />}
                            {section.type === 'block' && <Dumbbell className="h-5 w-5 text-accent-primary" />}
                            {section.type === 'cooldown' && <Wind className="h-5 w-5 text-blue-500" />}
                            {section.name}
                          </CardTitle>
                          <select
                            value={section.executionStyle}
                            onChange={(e) => {
                              const newStyle = e.target.value;
                              setTemplateData(prev => ({
                                ...prev,
                                workoutStructure: prev.workoutStructure.map(s => 
                                  s.id === section.id 
                                    ? { 
                                        ...s, 
                                        executionStyle: newStyle,
                                        circuitRounds: newStyle === 'circuit' ? 1 : undefined 
                                      }
                                    : s
                                )
                              }));
                            }}
                            className="bg-background-tertiary border border-border-light rounded-md px-3 py-1 text-sm"
                          >
                            <option value="sequential">Sequential</option>
                            <option value="circuit">Circuit</option>
                          </select>
                          {section.executionStyle === 'circuit' && (
                            <div className="flex items-center gap-2">
                              <label className="text-sm">Rounds:</label>
                              <input
                                type="number"
                                min="1"
                                value={section.circuitRounds}
                                onChange={(e) => {
                                  setTemplateData(prev => ({
                                    ...prev,
                                    workoutStructure: prev.workoutStructure.map(s =>
                                      s.id === section.id
                                        ? { ...s, circuitRounds: parseInt(e.target.value) }
                                        : s
                                    )
                                  }));
                                }}
                                className="w-16 bg-background-tertiary border border-border-light rounded-md px-2 py-1 text-sm"
                              />
                            </div>
                          )}
                        </div>
                        <Button
                          type="button"
                          onClick={() => removeSection(section.id)}
                          variant="ghost"
                          className="text-red-500 hover:text-red-400 hover:bg-red-500/10"
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </CardHeader>

                      <CardContent className="space-y-4">
                        {templateData.exercises
                          .filter(exercise => exercise.workoutStructureId === section.id)
                          .sort((a, b) => a.order - b.order)
                          .map((exercise) => (
                            <div 
                              key={exercise.id}
                              className="border border-border-light rounded-lg p-4 space-y-4"
                            >
                              <div className="flex items-center justify-between">
                                <div className="flex-1 mr-4">
                                <ExerciseSearch
                                  value={exercise.exerciseId}
                                  onChange={(value) => updateExercise(section.id, exercise.id, 'exerciseId', value)}
                                  className="w-full"
                                  onEditExercise={handleEditExercise}
                                  allExercises={allExercises} // Pass the latest exercises to ExerciseSearch
                                />
                                </div>
                                <Button
                                  type="button"
                                  variant="ghost"
                                  onClick={() => removeExercise(section.id, exercise.id)}
                                  className="text-red-500 hover:text-red-400"
                                >
                                  <Trash2 className="h-4 w-4" />
                                </Button>
                              </div>

                              <ExpandedSetsEditor
                                sets={exercise.sets}
                                onChange={(newSets) => updateExercise(section.id, exercise.id, 'sets', newSets)}
                              />

                              <div>
                                <label className="text-sm text-gray-400">Rest (seconds)</label>
                                <Input
                                  type="number"
                                  value={exercise.rest}
                                  onChange={(e) => updateExercise(section.id, exercise.id, 'rest', parseInt(e.target.value))}
                                  className="bg-background-tertiary border-border-light focus:border-accent-primary"
                                  min="0"
                                  required
                                />
                              </div>

                              <Input
                                placeholder="Notes (form cues, variations, etc.)"
                                value={exercise.notes}
                                onChange={(e) => updateExercise(section.id, exercise.id, 'notes', e.target.value)}
                                className="bg-background-tertiary border-border-light focus:border-accent-primary"
                              />
                            </div>
                          ))}

                        <Button
                          type="button"
                          onClick={() => addExercise(section.id)}
                          className="w-full bg-accent-primary/10 text-accent-primary hover:bg-accent-primary/20"
                        >
                          <Plus className="h-4 w-4 mr-2" />
                          Add Exercise to {section.name}
                        </Button>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </div>

              {/* Footer */}
              <div className="border-t border-border-light/20 pt-6 flex justify-end gap-4">
                <Button
                  type="button"
                  onClick={() => navigate(-1)}
                  variant="outline"
                  className="bg-background-tertiary hover:bg-background-secondary"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={loading}
                  className="bg-accent-primary hover:bg-accent-primary/90"
                >
                  {loading ? (
                    <Loader2 className="h-4 w-4 animate-spin mr-2" />
                  ) : null}
                  {isEdit ? 'Save Template' : 'Create Template'}
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </div>

      {showModifyOverlay && (
        <ModifyExerciseOverlay
          exercise={selectedExercise}
          coachId={user.uid}
          onSave={handleSaveExercise}
          onClose={() => {
            setShowModifyOverlay(false);
            setSelectedExercise(null);
          }}
        />
      )}

      {showCreateOverlay && (
        <CreateExerciseOverlay
          onSave={handleSaveExercise}
          onClose={() => {
            setShowCreateOverlay(false);
            setSelectedExercise(null);
          }}
          coachId={user.uid}
          originalExercise={null}
        />
      )}
    </div>
  );
};

export default TemplateCreation;
