import { doc, collection, setDoc, serverTimestamp, writeBatch } from 'firebase/firestore';
import { db } from '../firebase';

export const initializeClientAccount = async (clientId, coachId, clientName) => {
  try {
    // Create default message settings
    const messageSettingsRef = doc(db, 'users', clientId, 'settings', 'messageSettings');
    await setDoc(messageSettingsRef, {
      requireCoachApproval: true,
      language: 'en',
    });

    /* Commenting out welcome messages for now
    // Add welcome messages with sequence numbers
    const messagesRef = collection(db, 'users', clientId, 'messages');
    const welcomeMessages = [
      {
        content: `Welcome ${clientName?.split(' ')[0] || ''}!`,
        is_system_message: true,
        date_sent: serverTimestamp(),
        media_url: '',
        source: 'system',
        client_id: clientId,
        coach_id: coachId,
        sender_id: coachId,
        sequence: 1  // First message
      },
      {
        content: 'You can message me here with anything you need. I will also send your workouts for you to complete!',
        is_system_message: true,
        date_sent: serverTimestamp(),
        media_url: '',
        source: 'system',
        client_id: clientId,
        coach_id: coachId,
        sender_id: coachId,
        sequence: 2  // Second message
      },
      {
        content: 'The app will continuing add cool features to improve your experience. One cool feature is that you can send me what you ate and your nutrition values will be automatically be calculated in the progress view (top left).',
        is_system_message: true,
        date_sent: serverTimestamp(),
        media_url: '',
        source: 'system',
        client_id: clientId,
        coach_id: coachId,
        sender_id: coachId,
        sequence: 3  // Third message
      },
      {
        content: 'For example "I just had a cup of greek yogurt with a handful of blueberries and walnuts."',
        is_system_message: true,
        date_sent: serverTimestamp(),
        media_url: '',
        source: 'system',
        client_id: clientId,
        coach_id: coachId,
        sender_id: coachId,
        sequence: 4  // Fourth message
      }
    ];

    // Create a batch
    const batch = writeBatch(db);
    
    // Add messages to batch with sequential timestamps
    const now = Date.now();
    welcomeMessages.forEach((message, index) => {
      const messageRef = doc(messagesRef);
      // Add a small time difference between messages
      const timestamp = new Date(now + (index * 100)); // 100ms difference between messages
      batch.set(messageRef, {
        ...message,
        date_sent: timestamp  // Use manual timestamp instead of serverTimestamp
      });
    });
    
    // Commit the batch
    await batch.commit();
    */

  } catch (error) {
    console.error('Error initializing client account:', error);
    throw error;
  }
};