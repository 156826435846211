import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/Card';
import { ArrowRight } from 'lucide-react';

const SimpleFunnelSummary = ({ data }) => {
  if (!data) return null;
  
  const { totals, rates } = data.filteredData || { 
    totals: { pageViews: 0, formStarts: 0, formCompletions: 0, callBookings: 0, payments: 0 },
    rates: { landingToForm: 0, formToCompletion: 0, completionToCall: 0, callToPayment: 0, overall: 0 }
  };
  
  const stages = [
    { name: 'Page Views', value: totals.pageViews },
    { name: 'Form Starts', value: totals.formStarts, rate: rates.landingToForm },
    { name: 'Form Completions', value: totals.formCompletions, rate: rates.formToCompletion },
    { name: 'Call Bookings', value: totals.callBookings, rate: rates.completionToCall },
    { name: 'Paying Clients', value: totals.payments, rate: rates.callToPayment }
  ];
  
  const formatNumber = (num) => num.toLocaleString(undefined, { maximumFractionDigits: 1 });
  
  return (
    <Card>
      <CardHeader>
        <CardTitle>Conversion Funnel</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          <div className="grid grid-cols-5 gap-1 text-center mb-2">
            {stages.map((stage, index) => (
              <div key={index} className="flex flex-col items-center">
                <div className="text-sm font-medium mb-1">{stage.name}</div>
                <div className="text-2xl font-bold">{formatNumber(stage.value)}</div>
                {stage.rate !== undefined && (
                  <div className={`text-xs mt-1 ${stage.rate >= 40 ? 'text-green-600' : stage.rate >= 20 ? 'text-amber-500' : 'text-red-500'}`}>
                    {formatNumber(stage.rate)}%
                  </div>
                )}
              </div>
            ))}
          </div>
          
          <div className="flex justify-between items-center">
            <div className="h-2 flex-1 rounded bg-gray-100 overflow-hidden flex">
              {stages.slice(1).map((stage, index) => {
                const width = (stage.value / stages[0].value) * 100;
                const colors = ['bg-blue-500', 'bg-indigo-500', 'bg-purple-500', 'bg-green-500'];
                return (
                  <div key={index} className={`h-full ${colors[index]}`} style={{ width: `${width}%` }}></div>
                );
              })}
            </div>
          </div>
          
          <div className="mt-8">
            <h3 className="text-lg font-medium mb-4">Conversion Rates</h3>
            <div className="space-y-3">
              {stages.slice(0, -1).map((stage, index) => {
                const nextStage = stages[index + 1];
                return (
                  <div key={index} className="flex items-center gap-3">
                    <div className="bg-gray-100 rounded p-2 flex-grow">
                      {stage.name} <span className="text-gray-500">({stage.value})</span>
                    </div>
                    <ArrowRight className="text-gray-400" />
                    <div className="bg-gray-100 rounded p-2 flex-grow">
                      {nextStage.name} <span className="text-gray-500">({nextStage.value})</span>
                    </div>
                    <div className={`text-sm font-medium rounded-full px-3 py-1 ${nextStage.rate >= 40 ? 'bg-green-100 text-green-800' : nextStage.rate >= 20 ? 'bg-amber-100 text-amber-800' : 'bg-red-100 text-red-800'}`}>
                      {formatNumber(nextStage.rate)}%
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          
          <div className="mt-4 p-4 bg-blue-50 rounded-lg">
            <div className="flex items-center gap-2">
              <div className="text-blue-800 font-medium">Overall Conversion:</div>
              <div className={`text-sm font-medium rounded-full px-3 py-1 ${rates.overall >= 5 ? 'bg-green-100 text-green-800' : rates.overall >= 1 ? 'bg-amber-100 text-amber-800' : 'bg-red-100 text-red-800'}`}>
                {formatNumber(rates.overall)}%
              </div>
              <div className="text-blue-600 text-sm ml-2">
                Landing Page to Paying Client
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default SimpleFunnelSummary; 