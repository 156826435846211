// src/components/ClientWorkoutEdit.jsx

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getWorkoutTemplate, assignWorkoutToUsers } from '../services/workoutTemplateService';
import { Button } from './ui/Button';
import { Input } from './ui/Input';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { Plus, Trash2 } from 'lucide-react';
import ExerciseSearch from './ExerciseSearch';
import ClientSearch from './ClientSearch';

const ClientWorkoutEdit = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { templateId } = useParams();

  const [loading, setLoading] = useState(true);
  const [template, setTemplate] = useState(null);
  const [selectedClient, setSelectedClient] = useState('');
  const [weeks, setWeeks] = useState(1);
  const [dayOfWeek, setDayOfWeek] = useState('monday');
  const [repsAdjustment, setRepsAdjustment] = useState(0);
  const [weightPercentageChange, setWeightPercentageChange] = useState(0);

  useEffect(() => {
    const loadTemplate = async () => {
      try {
        const tmpl = await getWorkoutTemplate(user.uid, templateId);
        setTemplate(tmpl);
      } catch (error) {
        console.error('Error loading template:', error);
      } finally {
        setLoading(false);
      }
    };
    if (templateId) {
      loadTemplate();
    }
  }, [templateId, user.uid]);

  const updateExercise = (sectionId, index, field, value) => {
    setTemplate(prev => ({
      ...prev,
      exercises: prev.exercises.map(ex => {
        if (ex.sectionId === sectionId && ex.order === index) {
          return { ...ex, [field]: value };
        }
        return ex;
      })
    }));
  };

  const bulkAdjustExercises = () => {
    setTemplate(prev => {
      const updated = { ...prev, exercises: prev.exercises.map(ex => {
        let newReps = ex.targetReps;
        if (repsAdjustment !== 0 && ex.targetReps) {
          const [min, max] = ex.targetReps.split('-').map(Number);
          newReps = `${min + repsAdjustment}-${max + repsAdjustment}`;
        }
        let newWeight = ex.targetWeight;
        if (weightPercentageChange !== 0 && ex.targetWeight) {
          newWeight = ex.targetWeight * (1 + weightPercentageChange / 100);
        }
        return { ...ex, targetReps: newReps, targetWeight: newWeight };
      })};
      return updated;
    });
  };

  const assignWorkouts = async () => {
    if (!selectedClient || weeks < 1) return;

    // Generate scheduleDates based on weeks and dayOfWeek chosen
    const scheduleDates = [];
    const dayMap = {
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
      sunday: 0
    };
    const currentDate = new Date();
    // Find next chosen dayOfWeek from current date
    // Simple logic: move forward until the chosen weekday
    while (currentDate.getDay() !== dayMap[dayOfWeek]) {
      currentDate.setDate(currentDate.getDate() + 1);
    }

    for (let i = 0; i < weeks; i++) {
      scheduleDates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 7); // next week same day
    }

    await assignWorkoutToUsers(user.uid, templateId, [selectedClient], scheduleDates);
    navigate('/dashboard'); // or wherever you want to go after assignment
  };

  if (loading) {
    return <div className="text-white">Loading template...</div>;
  }

  if (!template) {
    return <div className="text-white">Template not found</div>;
  }

  // Group exercises by sections using template.sections
  const sections = template.sections.map(sec => ({
    ...sec,
    exercises: template.exercises
      .filter(ex => ex.sectionId === sec.id)
      .sort((a, b) => a.order - b.order)
  }));

  return (
    <div className="min-h-screen bg-black text-white pt-20 px-4">
      <Card className="max-w-4xl mx-auto bg-neutral-900 border-0 mb-8">
        <CardHeader>
          <CardTitle>Editing Template: {template.name}</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <ClientSearch
            value={selectedClient}
            onChange={setSelectedClient}
            className="w-full"
          />
          <div className="flex space-x-4">
            <div>
              <label className="text-gray-400 text-sm">Number of Weeks</label>
              <Input
                type="number"
                value={weeks}
                onChange={(e) => setWeeks(parseInt(e.target.value))}
                className="bg-neutral-800 border-neutral-700"
                min={1}
              />
            </div>
            <div>
              <label className="text-gray-400 text-sm">Day of Week</label>
              <select
                value={dayOfWeek}
                onChange={(e) => setDayOfWeek(e.target.value)}
                className="bg-neutral-800 border-neutral-700 text-white py-2 px-3 rounded"
              >
                <option value="sunday">Sunday</option>
                <option value="monday">Monday</option>
                <option value="tuesday">Tuesday</option>
                <option value="wednesday">Wednesday</option>
                <option value="thursday">Thursday</option>
                <option value="friday">Friday</option>
                <option value="saturday">Saturday</option>
              </select>
            </div>
          </div>

          <div className="border-t border-neutral-700 pt-4 space-y-2">
            <h3 className="font-semibold">Bulk Adjustments</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="text-gray-400 text-sm">Reps Adjustment (+/-)</label>
                <Input
                  type="number"
                  value={repsAdjustment}
                  onChange={(e) => setRepsAdjustment(parseInt(e.target.value))}
                  className="bg-neutral-800 border-neutral-700"
                />
              </div>
              <div>
                <label className="text-gray-400 text-sm">% Weight Increase/Decrease</label>
                <Input
                  type="number"
                  value={weightPercentageChange}
                  onChange={(e) => setWeightPercentageChange(parseFloat(e.target.value))}
                  className="bg-neutral-800 border-neutral-700"
                />
              </div>
            </div>
            <Button onClick={bulkAdjustExercises} className="mt-2 bg-neutral-800 hover:bg-neutral-700">
              Apply Bulk Adjustments
            </Button>
          </div>
        </CardContent>
      </Card>

      {sections.map(section => (
        <Card key={section.id} className="max-w-4xl mx-auto bg-neutral-900 border-0 mb-8 p-4 space-y-4">
          <h4 className="text-lg font-medium">{section.name}</h4>
          {section.exercises.map((exercise, idx) => (
            <div key={exercise.order} className="border border-neutral-700 p-4 space-y-4 rounded-lg">
              <div className="flex items-center justify-between">
                <div className="flex-1 mr-4">
                  <ExerciseSearch
                    value={exercise.exerciseId}
                    onChange={(val) => updateExercise(section.id, exercise.order, 'exerciseId', val)}
                    className="w-full"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                <div>
                  <label className="text-sm text-gray-400">Sets</label>
                  <Input
                    type="number"
                    value={exercise.sets}
                    onChange={(e) => updateExercise(section.id, exercise.order, 'sets', parseInt(e.target.value))}
                    className="bg-neutral-800 border-neutral-700"
                    min="1"
                  />
                </div>
                <div>
                  <label className="text-sm text-gray-400">Reps</label>
                  <Input
                    value={exercise.targetReps}
                    onChange={(e) => updateExercise(section.id, exercise.order, 'targetReps', e.target.value)}
                    className="bg-neutral-800 border-neutral-700"
                  />
                </div>
                <div>
                  <label className="text-sm text-gray-400">Weight (lbs)</label>
                  <Input
                    type="number"
                    value={exercise.targetWeight || ''}
                    onChange={(e) => updateExercise(section.id, exercise.order, 'targetWeight', parseFloat(e.target.value))}
                    className="bg-neutral-800 border-neutral-700"
                    min="0"
                    placeholder="Optional"
                  />
                </div>
                <div>
                  <label className="text-sm text-gray-400">Rest (sec)</label>
                  <Input
                    type="number"
                    value={exercise.rest}
                    onChange={(e) => updateExercise(section.id, exercise.order, 'rest', parseInt(e.target.value))}
                    className="bg-neutral-800 border-neutral-700"
                    min="0"
                  />
                </div>
              </div>
              <Input
                placeholder="Notes"
                value={exercise.notes}
                onChange={(e) => updateExercise(section.id, exercise.order, 'notes', e.target.value)}
                className="bg-neutral-800 border-neutral-700"
              />
            </div>
          ))}
        </Card>
      ))}

      <div className="max-w-4xl mx-auto mb-8">
        <Button
          onClick={assignWorkouts}
          className="w-full bg-blue-600 hover:bg-blue-700"
        >
          Assign Workouts to Client
        </Button>
      </div>
    </div>
  );
};

export default ClientWorkoutEdit;
