// src/lib/nutritionMappings.js
// Shared food mapping constants for nutrition-related components

export const DIET_PRESETS = {
  carnivore: {
    label: 'Carnivore',
    sources: [
      'beef', 'bison', 'lamb', 'chicken', 'turkey',
      'fish', 'salmon', 'sardines', 'shellfish', 'eggs'
    ]
  },
  keto: {
    label: 'Keto',
    sources: [
      'beef', 'bison', 'lamb', 'chicken', 'fish', 'salmon', 'sardines', 'eggs',
      'avocado', 'cold-pressed-olive-oil', 'coconut-oil', 'butter', 'ghee', 'nuts', 'seeds'
    ]
  },
  mediterranean: {
    label: 'Mediterranean',
    sources: [
      'fish', 'salmon', 'sardines', 'shellfish', 'chicken', 'turkey', 'cold-pressed-olive-oil',
      'whole-grains', 'lentils', 'chickpeas', 'black-beans', 'vegetables', 'fruits', 'nuts', 'seeds', 'greek-yogurt'
    ]
  },
  paleo: {
    label: 'Paleo',
    sources: [
      'beef', 'bison', 'lamb', 'chicken', 'turkey', 'fish', 'salmon', 'sardines', 'shellfish', 'eggs',
      'vegetables', 'fruits', 'nuts', 'seeds', 'sweet-potato', 'avocado', 'coconut-oil', 'cold-pressed-olive-oil'
    ]
  },
  vegan: {
    label: 'Vegan',
    sources: [
      'tempeh', 'tofu', 'lentils', 'chickpeas', 'black-beans', 'whole-grains',
      'fruits', 'vegetables', 'nuts', 'seeds', 'nut-butters', 'avocado', 'cold-pressed-olive-oil', 'coconut-oil',
      'oats', 'brown-rice', 'quinoa'
    ]
  },
  plantBased: { // Adding mapping for NutritionSetup's plantBased option
    label: 'Plant-Based',
    sources: [
      'tempeh', 'tofu', 'lentils', 'chickpeas', 'black-beans', 'whole-grains',
      'fruits', 'vegetables', 'nuts', 'seeds', 'nut-butters', 'avocado', 'cold-pressed-olive-oil', 'coconut-oil',
      'oats', 'brown-rice', 'quinoa'
    ]
  },
  lowCarb: { // Adding mapping for NutritionSetup's lowCarb option
    label: 'Low Carb',
    sources: [
      'beef', 'bison', 'lamb', 'chicken', 'turkey', 'fish', 'salmon', 'sardines', 'shellfish', 'eggs',
      'avocado', 'cold-pressed-olive-oil', 'coconut-oil', 'butter', 'ghee', 'nuts', 'seeds',
      'vegetables'
    ]
  },
  balanced: { // Adding mapping for NutritionSetup's balanced option
    label: 'Balanced Macros',
    sources: [
      'beef', 'chicken', 'turkey', 'fish', 'salmon', 'eggs', 'greek-yogurt', 'cottage-cheese',
      'sweet-potato', 'white-rice', 'brown-rice', 'quinoa', 'oats', 'whole-grains', 'fruits', 'vegetables',
      'cold-pressed-olive-oil', 'avocado', 'nuts', 'seeds'
    ]
  },
  wfpb: {
    label: 'Whole Food Plant Based',
    sources: [
      'lentils', 'chickpeas', 'black-beans', 'whole-grains', 'fruits', 'vegetables',
      'nuts', 'seeds', 'quinoa', 'oats', 'brown-rice'
    ]
  }
};

export const GLOBAL_QUALITIES = [
  {
    id: 'organic',
    label: 'All Organic',
    applicableTo: [
      'chicken', 'turkey', 'beef', 'lamb', 'bison', 'fruits', 'vegetables', 'lentils', 'chickpeas', 'black-beans',
      'sweet-potato', 'white-rice', 'brown-rice', 'whole-grains', 'oats', 'quinoa', 'tempeh', 'tofu', 'nuts', 'seeds'
    ]
  },
  {
    id: 'grassFed',
    label: 'All Grass-Fed',
    applicableTo: ['beef', 'bison', 'lamb', 'greek-yogurt']
  },
  {
    id: 'wildCaught',
    label: 'All Wild-Caught',
    applicableTo: ['fish', 'salmon', 'sardines', 'shellfish']
  },
  {
    id: 'pastureRaised',
    label: 'All Pasture-Raised',
    applicableTo: ['eggs', 'chicken', 'turkey']
  }
];

export const FOOD_QUALITY_MAPPING = {
  organic: {
    label: 'Organic',
    applicableTo: [
      'chicken', 'turkey', 'beef', 'lamb', 'bison', 'fruits', 'vegetables', 'lentils', 'chickpeas', 'black-beans',
      'sweet-potato', 'white-rice', 'brown-rice', 'whole-grains', 'oats', 'quinoa', 'tempeh', 'tofu', 'nuts', 'seeds'
    ]
  },
  grassFed: {
    label: 'Grass-Fed',
    applicableTo: ['beef', 'bison', 'lamb', 'greek-yogurt']
  },
  wildCaught: {
    label: 'Wild-Caught',
    applicableTo: ['fish', 'salmon', 'sardines', 'shellfish']
  },
  pastureRaised: {
    label: 'Pasture-Raised',
    applicableTo: ['eggs', 'chicken', 'turkey']
  }
};

export const ITEMS_TO_AVOID = {
  label: 'Items to Avoid',
  categories: [
    {
      id: 'seed-oils',
      label: 'Seed Oils',
      examples: 'Canola, Soybean, Corn, Sunflower oils'
    },
    {
      id: 'added-sugar',
      label: 'Added Sugar',
      examples: 'High fructose corn syrup, table sugar, artificial sweeteners'
    },
    {
      id: 'ultra-processed',
      label: 'Ultra Processed Foods',
      examples: 'Packaged snacks, processed meats, artificial ingredients'
    },
    {
      id: 'refined-carbs',
      label: 'Refined Carbohydrates',
      examples: 'White flour, processed grains, instant foods'
    },
    {
      id: 'alcohol',
      label: 'Alcohol',
      examples: 'Beer, wine, spirits'
    },
    {
      id: 'marijuana',
      label: 'Marijuana',
      examples: 'All forms of cannabis'
    },
    {
      id: 'caffeine',
      label: 'Caffeine',
      examples: 'Coffee, energy drinks, tea, supplements'
    },
    {
      id: 'nicotine',
      label: 'Nicotine',
      examples: 'Cigarettes, vaping, nicotine products'
    }
  ]
};

export const INITIAL_FOODS = {
  proteins: {
    label: 'Protein Sources',
    items: [
      { id: 'beef', label: 'Beef' },
      { id: 'bison', label: 'Bison' },
      { id: 'lamb', label: 'Lamb' },
      { id: 'chicken', label: 'Chicken' },
      { id: 'turkey', label: 'Turkey' },
      { id: 'fish', label: 'Fish (Cod, etc.)' },
      { id: 'salmon', label: 'Salmon' },
      { id: 'sardines', label: 'Sardines' },
      { id: 'shellfish', label: 'Shellfish' },
      { id: 'eggs', label: 'Eggs' },
      { id: 'greek-yogurt', label: 'Greek Yogurt' },
      { id: 'cottage-cheese', label: 'Cottage Cheese' },
      { id: 'tempeh', label: 'Tempeh' },
      { id: 'tofu', label: 'Tofu' },
      { id: 'lentils', label: 'Lentils' },
      { id: 'chickpeas', label: 'Chickpeas' },
      { id: 'black-beans', label: 'Black Beans' }
    ]
  },
  carbs: {
    label: 'Carb Sources',
    items: [
      { id: 'sweet-potato', label: 'Sweet Potato' },
      { id: 'white-rice', label: 'White Rice' },
      { id: 'brown-rice', label: 'Brown Rice' },
      { id: 'quinoa', label: 'Quinoa' },
      { id: 'oats', label: 'Oats' },
      { id: 'whole-grains', label: 'Whole Grains' },
      { id: 'fruits', label: 'Fruits' },
      { id: 'vegetables', label: 'Vegetables' },
      { id: 'lentils', label: 'Lentils' },
      { id: 'chickpeas', label: 'Chickpeas' },
      { id: 'black-beans', label: 'Black Beans' }
    ]
  },
  fats: {
    label: 'Fat Sources',
    items: [
      { id: 'cold-pressed-olive-oil', label: 'Cold-Pressed Olive Oil' },
      { id: 'coconut-oil', label: 'Coconut Oil' },
      { id: 'avocado', label: 'Avocado' },
      { id: 'butter', label: 'Butter' },
      { id: 'ghee', label: 'Ghee' },
      { id: 'nuts', label: 'Nuts' },
      { id: 'seeds', label: 'Seeds' },
      { id: 'nut-butters', label: 'Nut Butters' }
    ]
  }
};

// Helper function to convert diet sources to categorized food selections
export const dietToFoodSelections = (dietId) => {
  if (!DIET_PRESETS[dietId]) return {};
  
  const sources = DIET_PRESETS[dietId].sources;
  const selections = {
    proteins: [],
    carbs: [],
    fats: []
  };
  
  // Map sources to categories
  sources.forEach(source => {
    // Check each food category
    Object.entries(INITIAL_FOODS).forEach(([category, { items }]) => {
      const foodExists = items.some(item => item.id === source);
      if (foodExists && !selections[category].includes(source)) {
        selections[category].push(source);
      }
    });
  });
  
  return selections;
};

// Diet principles and descriptions for AI coaching
export const DIET_PRINCIPLES = {
  carnivore: {
    description: "Focuses exclusively on animal foods with zero plant foods. Primarily meat, fish, eggs, and limited dairy.",
    principles: [
      "Only animal-based foods are recommended",
      "Emphasizes nutrient density from animal sources",
      "Zero carbohydrates from plant sources",
      "Primarily sourced from ruminant animals (beef, lamb, bison)"
    ],
    foods: "Beef, lamb, bison, organ meats, eggs, fish, and optionally dairy products"
  },
  keto: {
    description: "Very low carb, high fat diet to promote ketosis and metabolic health",
    principles: [
      "Limit carbohydrates to 20-50g per day",
      "Moderate protein consumption",
      "High healthy fat intake",
      "Focus on whole, unprocessed foods"
    ],
    foods: "Meat, fatty fish, eggs, butter, cream, olive oil, nuts, avocados, and low-carb vegetables"
  },
  mediterranean: {
    description: "Emphasizes vegetables, fruits, whole grains, lean proteins like fish, and healthy fats like olive oil.",
    principles: [
      "Abundant plant foods (fruits, vegetables, whole grains, legumes)",
      "Olive oil as the primary fat source",
      "Moderate consumption of fish, seafood, and poultry",
      "Limited red meat",
      "Moderate wine consumption (optional)"
    ],
    foods: "Vegetables, fruits, whole grains, legumes, fish, olive oil, nuts, and seeds"
  },
  paleo: {
    description: "Focuses on whole foods that our ancestors might have eaten: meats, fish, nuts, leafy greens, vegetables, and seeds",
    principles: [
      "Eat whole, unprocessed foods",
      "Avoid grains, legumes, and dairy",
      "Focus on quality protein sources",
      "Include healthy fats",
      "Eliminate processed foods and sugars"
    ],
    foods: "Grass-fed meat, wild-caught fish, eggs, vegetables, fruits, nuts, and seeds"
  },
  plantBased: {
    description: "Primarily or exclusively plant-derived foods for health and sustainability",
    principles: [
      "Majority of calories from plant sources",
      "Minimal or no animal products",
      "Emphasis on whole foods rather than processed alternatives",
      "Focus on nutrient density and variety"
    ],
    foods: "Legumes, whole grains, vegetables, fruits, nuts, seeds, plant-based protein sources"
  },
  vegan: {
    description: "Excludes all animal products for ethical, environmental, and/or health reasons",
    principles: [
      "No animal products whatsoever",
      "Focus on complete protein combinations",
      "Supplement B12 and other potentially limited nutrients",
      "Emphasize whole plant foods over processed vegan alternatives"
    ],
    foods: "Legumes, tofu, tempeh, whole grains, vegetables, fruits, nuts, seeds"
  },
  lowCarb: {
    description: "Reduced carbohydrate intake with higher protein and fats for metabolic health",
    principles: [
      "Limit carbohydrates to 50-100g per day",
      "Focus on protein-rich foods",
      "Include healthy fats",
      "Eliminate refined carbs and sugars"
    ],
    foods: "Meat, fish, eggs, non-starchy vegetables, nuts, seeds, healthy oils, limited fruits"
  },
  balanced: {
    description: "Focus on balanced protein, carbs, and fats for optimal performance and body composition",
    principles: [
      "Roughly even distribution of macronutrients",
      "Focus on food quality and nutrient density",
      "Regular meal timing",
      "Portion control based on individual needs"
    ],
    foods: "Lean proteins, complex carbohydrates, healthy fats, fruits, vegetables, whole grains"
  },
  wfpb: {
    description: "Whole food plant based diet focusing on unprocessed plant foods with minimal or no animal products",
    principles: [
      "Focus on whole, minimally processed plant foods",
      "Eliminate or minimize animal products",
      "Avoid refined oils, added sugars, and processed foods",
      "Emphasize nutrient density from diverse plant sources"
    ],
    foods: "Vegetables, fruits, whole grains, legumes, nuts, seeds, minimal or no animal products"
  }
}; 