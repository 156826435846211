// src/components/App.jsx
import React from 'react';  
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from '../contexts/AuthContext';
import AppRoutes from './AppRoutes';

// We don't need to import these components here anymore
// They'll be handled by AppRoutes.jsx

const App = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;