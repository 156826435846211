import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { getDoc, doc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { ArrowRight, Loader, AlertCircle, ChevronDown, Star, ChevronUp, Quote } from 'lucide-react';
import MarkdownTextRenderer from '../ui/MarkdownTextRenderer';
import SimplifiedAnalyticsService from '../../services/SimplifiedAnalyticsService';
import theme from '../../styles/theme';
import FullScreenMedia from './FullScreenMedia';
import MinimalistQuestionOverlay from './MinimalistQuestionOverlay';
import { glassCardStyles, accentBorderStyles } from '../ui/Styles';

const CoachLandingPage = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const [coach, setCoach] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [firstQuestion, setFirstQuestion] = useState(null);
  const [firstQuestionResponse, setFirstQuestionResponse] = useState(null);
  const contentRef = useRef(null);
  
  // Brand colors from theme
  const brandColors = {
    primary: theme.colors.accent.primary,
    secondary: theme.colors.background.primary,
    accent: theme.colors.accent.secondary,
    text: theme.colors.text.primary
  };

  // Fetch coach and form data
  useEffect(() => {
    const fetchCoach = async () => {
      try {
        setLoading(true);
        
        // First try: Direct lookup by ID (assuming username might be a UID)
        const directLookup = await getDoc(doc(db, 'coaches', username));
        
        if (directLookup.exists()) {
          setCoach({...directLookup.data(), id: username});
          return;
        }
        
        // Second try: Query by username field
        const usernameQuery = query(
          collection(db, 'coaches'),
          where('username', '==', username)
        );
        
        const querySnapshot = await getDocs(usernameQuery);
        
        if (!querySnapshot.empty) {
          const coachDoc = querySnapshot.docs[0];
          setCoach({ id: coachDoc.id, ...coachDoc.data() });
          return;
        }
        
        // If we get here, no coach was found
        setError('Coach not found');
      } catch (err) {
        console.error('Error fetching coach:', err);
        setError('Error loading coach profile');
      } finally {
        setLoading(false);
      }
    };

    if (username) {
      fetchCoach();
    }
  }, [username]);

  // Fetch first question once we have the coach
  useEffect(() => {
    const fetchFirstQuestion = async () => {
      if (!coach?.activeOnboardingFormId) return;
      
      try {
        // Get the active form
        const formId = coach.activeOnboardingFormId;
        
        // Get questions for this form
        const questionsSnapshot = await getDocs(
          collection(db, 'onboardingForms', formId, 'questions')
        );
        
        // Get all questions and sort by order
        const questions = [];
        questionsSnapshot.forEach(doc => {
          questions.push({
            id: doc.id,
            ...doc.data()
          });
        });
        
        // Sort questions by order
        questions.sort((a, b) => a.order - b.order);
        
        // First try to find a question with order=0 (first question)
        let firstQuestion = questions.find(q => q.order === 0);
        
        // If no question with order=0, try finding one with step=1
        if (!firstQuestion) {
          firstQuestion = questions.find(q => q.step === 1);
        }
        
        // If still no match, just use the first one from the sorted array
        if (!firstQuestion && questions.length > 0) {
          firstQuestion = questions[0];
        }
        
        // Set the first question if available
        if (firstQuestion) {
          console.log('First question loaded for landing page:', {
            id: firstQuestion.id,
            title: firstQuestion.title,
            order: firstQuestion.order,
            step: firstQuestion.step
          });
          setFirstQuestion(firstQuestion);
        }
      } catch (err) {
        console.error('Error fetching first question:', err);
      }
    };
    
    if (coach) {
      fetchFirstQuestion();
    }
  }, [coach]);

  // Track page view when coach data is loaded
  useEffect(() => {
    if (coach && !loading) {
      const coachId = coach.id || username;
      // Track landing page view
      SimplifiedAnalyticsService.trackPageView({
        coachId: coachId,
        formId: coach.activeOnboardingFormId || 'default'
      });
    }
  }, [coach, loading, username]);

  // Set CSS variables for brand colors
  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', brandColors.primary);
    document.documentElement.style.setProperty('--secondary-color', brandColors.secondary);
    document.documentElement.style.setProperty('--accent-color', brandColors.accent);
    document.documentElement.style.setProperty('--text-color', brandColors.text);
  }, [brandColors]);

  const handleStartOnboardingWithValue = (responseValue) => {
    if (coach) {
      const coachId = coach.id || username;
      // Track onboarding start event
      SimplifiedAnalyticsService.trackFormStart({
        coachId: coachId,
        formId: coach.activeOnboardingFormId || 'default'
      });
      
      // Use the direct value instead of state
      if (firstQuestion && responseValue) {
        console.log('Navigating to onboarding with first question answered (direct value):', {
          questionId: firstQuestion.id,
          responseValue: responseValue,
        });
        
        navigate(`/${username}/onboarding`, { 
          state: { 
            firstQuestionId: firstQuestion.id,
            firstQuestionResponse: responseValue
          }
        });
      } else {
        console.log('Navigating to onboarding without pre-answered question');
        navigate(`/${username}/onboarding`);
      }
    }
  };

  const handleQuestionResponse = (value) => {
    // Store the value in state
    setFirstQuestionResponse(value);
    
    // We'll automatically advance to the next step after a short delay
    setTimeout(() => {
      // Pass the value directly instead of relying on state
      handleStartOnboardingWithValue(value);
    }, 500);
  };

  const handleStartOnboarding = () => {
    if (coach) {
      const coachId = coach.id || username;
      SimplifiedAnalyticsService.trackFormStart({
        coachId: coachId,
        formId: coach.activeOnboardingFormId || 'default'
      });
      navigate(`/${username}/onboarding`);
    }
  };

  const scrollToContent = () => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Loading skeleton component
  const LoadingSkeleton = () => (
    <div className="min-h-screen bg-black flex items-center justify-center p-4">
      <div className="animate-pulse flex flex-col items-center max-w-md w-full">
        <div className="w-32 h-32 bg-gray-600 rounded-full mb-8"></div>
        <div className="w-3/4 h-8 bg-gray-600 rounded mb-4"></div>
        <div className="w-1/2 h-6 bg-gray-600 rounded mb-8"></div>
        <div className="w-full h-4 bg-gray-600 rounded mb-3"></div>
        <div className="w-full h-4 bg-gray-600 rounded mb-3"></div>
        <div className="w-3/4 h-4 bg-gray-600 rounded mb-8"></div>
        <div className="w-40 h-12 bg-gray-600 rounded"></div>
      </div>
    </div>
  );

  // Error component
  const ErrorDisplay = () => (
    <div className="min-h-screen bg-black flex items-center justify-center p-4">
      <div className="text-center p-8 max-w-md bg-gray-800 rounded-lg shadow-md">
        <div className="text-accent-primary text-6xl mb-6">😕</div>
        <h1 className="text-2xl font-bold text-white mb-4">Coach Not Found</h1>
        <p className="text-gray-300 mb-8">
          We couldn't find a coach with the username "{username}". Please check the URL and try again.
        </p>
        <button 
          onClick={() => navigate('/')}
          className="px-4 py-2 bg-accent-primary text-white rounded-md"
        >
          Return Home
        </button>
      </div>
    </div>
  );

  // Testimonial Item component
  const TestimonialItem = ({ testimonial, isEven }) => (
    <div className={`backdrop-blur-md bg-white/5 rounded-xl border border-white/15 overflow-hidden transform transition-all duration-300 hover:-translate-y-1 hover:bg-white/8 hover:border-white/25`}>
      <div className="h-0.5 bg-accent-primary w-1/3 mx-auto"></div>
      <div className="p-6 sm:p-8">
        <div className="flex items-center gap-4 mb-6">
          {testimonial.image ? (
            <img 
              src={testimonial.image} 
              alt={testimonial.name} 
              className="w-12 h-12 rounded-full object-cover border border-white/20"
            />
          ) : (
            <div className="w-12 h-12 rounded-full bg-accent-primary/10 text-accent-primary flex items-center justify-center">
              <Quote className="w-5 h-5" />
            </div>
          )}
          <div>
            <h4 className="font-medium text-white text-lg">{testimonial.name}</h4>
            <div className="flex mt-1">
              {[...Array(5)].map((_, i) => (
                <Star 
                  key={i} 
                  className={`w-3.5 h-3.5 ${i < testimonial.stars ? 'text-yellow-400 fill-yellow-400' : 'text-gray-600'}`}
                />
              ))}
            </div>
          </div>
        </div>
        
        <div className="text-white/80 italic leading-relaxed">
          <MarkdownTextRenderer text={testimonial.text} />
        </div>
      </div>
    </div>
  );

  // FAQ Item component
  const FaqItem = ({ faq, index }) => {
    const [isOpen, setIsOpen] = useState(index === 0); // First one open by default
    
    return (
      <div className="border-b border-white/10 last:border-b-0">
        <button 
          className="w-full text-left py-5 flex justify-between items-center group"
          onClick={() => setIsOpen(!isOpen)}
          aria-expanded={isOpen}
        >
          <h4 className="text-xl font-medium text-white group-hover:text-accent-primary transition-colors pr-8">
            <MarkdownTextRenderer text={faq.question} />
          </h4>
          <div className={`rounded-full flex items-center justify-center h-9 w-9 bg-black/40 transition-transform duration-300 ${isOpen ? 'rotate-180 bg-accent-primary/10' : ''}`}>
            <ChevronDown className={`w-5 h-5 ${isOpen ? 'text-accent-primary' : 'text-white/70'} transition-colors`} />
          </div>
        </button>
        
        <AnimatePresence>
          {isOpen && (
            <motion.div 
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="overflow-hidden"
            >
              <div className="text-white/70 pb-6 pt-2 leading-relaxed pl-1">
                <MarkdownTextRenderer text={faq.answer} />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };

  return (
    <AnimatePresence>
      {loading ? (
        <LoadingSkeleton />
      ) : error ? (
        <ErrorDisplay />
      ) : (
        <div className="relative w-full overflow-x-hidden">
          {/* Main Full Screen Landing Section */}
          <div className="relative min-h-screen w-full overflow-hidden bg-black">
            {/* Background Media */}
            <FullScreenMedia 
              mediaUrl={coach?.landingPage?.backgroundMedia}
              mediaType={coach?.landingPage?.backgroundMediaType}
              fallbackImage={coach?.profileImage}
              objectPosition={coach?.landingPage?.backgroundMediaPosition || 'center'}
            />
            
            {/* Content Overlay */}
            <div className="relative z-10 min-h-screen flex flex-col">
              {/* Logo/Branding Area */}
              <div className="h-[10vh] flex items-center justify-center">
                <motion.div
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                  className="px-6"
                >
                  {coach?.logo ? (
                    <img 
                      src={coach.logo} 
                      alt={coach?.firstName ? `${coach.firstName} ${coach.lastName}` : 'Coach'} 
                      className="h-10 object-contain"
                    />
                  ) : (
                    <div className="flex flex-col items-center">
                      <h1 className="text-xl font-bold tracking-wide text-white uppercase">
                        {coach?.businessName || 
                         (coach?.firstName && coach?.lastName ? 
                          `${coach.firstName} ${coach.lastName}` : 
                          'Coach')}
                      </h1>
                    </div>
                  )}
                </motion.div>
              </div>
              
              {/* Main Content Area with proper spacing */}
              <div className="flex-1 flex flex-col justify-between py-4">
                {/* Hero and Bio Section - Taking less vertical space to make room for bio */}
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.8, delay: 0.3 }}
                  className="text-center px-6 pt-4 md:pt-8 md:max-w-2xl mx-auto"
                >
                  {/* Hero Title */}
                  <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-white tracking-tight leading-tight mb-4"
                      style={{ textShadow: '0 2px 10px rgba(0,0,0,0.5)' }}>
                    <MarkdownTextRenderer text={coach?.landingPage?.heroTitle || 'Transform Your Life'} />
                  </h1>
                  
                  {/* Coach Bio */}
                  {(coach?.landingPage?.heroBio || coach?.bio) && (
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.6, delay: 0.5 }}
                      className="mb-6 md:mb-8"
                    >
                      <p className="text-white/95 text-base md:text-lg leading-relaxed max-w-prose mx-auto"
                         style={{ textShadow: '0 1px 3px rgba(0,0,0,0.7)' }}>
                        <MarkdownTextRenderer text={coach?.landingPage?.heroBio || coach?.bio} />
                      </p>
                    </motion.div>
                  )}
                </motion.div>
                
                {/* First Question Container - adjusted to take appropriate space */}
                <motion.div 
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.7, delay: 0.7 }}
                  className="mt-auto mb-6 md:mb-10 w-full"
                >
                  {firstQuestion ? (
                    <MinimalistQuestionOverlay
                      question={firstQuestion.title}
                      options={firstQuestion.options || []}
                      selectedOption={firstQuestionResponse}
                      onChange={handleQuestionResponse}
                      autoAdvance={true}
                    />
                  ) : (
                    <div className="text-center py-6 px-4">
                      <button
                        onClick={handleStartOnboarding}
                        className="px-8 py-4 bg-white text-gray-800 rounded-full text-lg font-medium shadow-xl hover:shadow-2xl hover:scale-105 transition-all duration-300"
                      >
                        Start Your Journey
                        <ArrowRight className="ml-2 h-5 w-5 inline" />
                      </button>
                    </div>
                  )}
                </motion.div>
              </div>
              
              {/* Scroll indicator for additional content - only if testimonials or FAQs exist */}
              {((coach?.landingPage?.testimonials && coach?.landingPage?.testimonials.length > 0) || 
                (coach?.landingPage?.faqs && coach?.landingPage?.faqs.length > 0)) && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 1.2, duration: 0.6 }}
                  className="absolute bottom-6 left-0 right-0 flex justify-center"
                >
                  <button 
                    onClick={scrollToContent}
                    className="flex flex-col items-center text-white/80 hover:text-white transition-colors"
                  >
                    <span className="text-sm mb-1">Learn More</span>
                    <ChevronDown className="w-5 h-5 animate-bounce" />
                  </button>
                </motion.div>
              )}
            </div>
          </div>
          
          {/* Additional Content Sections */}
          {((coach?.landingPage?.testimonials && coach?.landingPage?.testimonials.length > 0) || 
            (coach?.landingPage?.faqs && coach?.landingPage?.faqs.length > 0)) && (
            <div 
              ref={contentRef}
              className="bg-black w-full"
            >
              {/* Testimonials Section */}
              {coach?.landingPage?.testimonials && coach?.landingPage?.testimonials.length > 0 && (
                <section className="py-28 px-4 relative border-b border-white/10">
                  <div className="container mx-auto max-w-5xl relative z-10">
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      viewport={{ once: true, margin: "-100px 0px" }}
                      transition={{ duration: 0.7 }}
                      className="text-center mb-20"
                    >
                      <h2 className="text-3xl md:text-4xl font-bold text-white mb-5 relative inline-block">
                        {coach?.landingPage?.successStoriesTitle || 'Success Stories'}
                        <div className="absolute -bottom-2 left-1/2 -translate-x-1/2 h-0.5 w-10 bg-accent-primary"></div>
                      </h2>
                      {coach?.landingPage?.successStoriesSubtitle && (
                        <p className="text-white/70 text-lg max-w-2xl mx-auto mt-6">
                          {coach?.landingPage?.successStoriesSubtitle}
                        </p>
                      )}
                    </motion.div>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                      {coach?.landingPage?.testimonials.map((testimonial, index) => (
                        <motion.div
                          key={index}
                          initial={{ opacity: 0, y: 20 }}
                          whileInView={{ opacity: 1, y: 0 }}
                          viewport={{ once: true, margin: "-100px 0px" }}
                          transition={{ duration: 0.5, delay: index * 0.1 }}
                        >
                          <TestimonialItem testimonial={testimonial} isEven={index % 2 === 0} />
                        </motion.div>
                      ))}
                    </div>
                  </div>
                </section>
              )}
              
              {/* FAQs Section */}
              {coach?.landingPage?.faqs && coach?.landingPage?.faqs.length > 0 && (
                <section className="py-28 px-4 relative">
                  <div className="container mx-auto max-w-3xl relative z-10">
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      viewport={{ once: true, margin: "-100px 0px" }}
                      transition={{ duration: 0.7 }}
                      className="text-center mb-20"
                    >
                      <h2 className="text-3xl md:text-4xl font-bold text-white mb-5 relative inline-block">
                        {coach?.landingPage?.faqTitle || 'Frequently Asked Questions'}
                        <div className="absolute -bottom-2 left-1/2 -translate-x-1/2 h-0.5 w-10 bg-accent-primary"></div>
                      </h2>
                      {coach?.landingPage?.faqSubtitle && (
                        <p className="text-white/70 text-lg max-w-2xl mx-auto mt-6">
                          {coach?.landingPage?.faqSubtitle}
                        </p>
                      )}
                    </motion.div>
                    
                    <div className="bg-black/60 backdrop-blur-md rounded-xl border border-white/10 p-4 sm:p-6 md:p-8">
                      {coach?.landingPage?.faqs.map((faq, index) => (
                        <motion.div
                          key={index}
                          initial={{ opacity: 0, y: 10 }}
                          whileInView={{ opacity: 1, y: 0 }}
                          viewport={{ once: true, margin: "-100px 0px" }}
                          transition={{ duration: 0.4, delay: index * 0.1 }}
                        >
                          <FaqItem faq={faq} index={index} />
                        </motion.div>
                      ))}
                    </div>
                  </div>
                </section>
              )}
              
              {/* CTA Section */}
              <section className="py-24 px-4 text-center border-t border-white/10">
                <motion.div
                  initial={{ opacity: 0, scale: 0.95 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  viewport={{ once: true, margin: "-100px 0px" }}
                  transition={{ duration: 0.7 }}
                  className="max-w-2xl mx-auto relative z-10"
                >
                  <div className="bg-black/60 backdrop-blur-md border border-white/10 rounded-xl py-12 px-6 md:px-10">
                    <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">
                      {coach?.landingPage?.ctaTitle || 'Ready to Transform Your Life?'}
                    </h2>
                    <p className="text-white/70 mb-10 max-w-md mx-auto">
                      Take the first step toward achieving your goals with personalized coaching tailored to your needs.
                    </p>
                    <button
                      onClick={handleStartOnboarding}
                      className="px-10 py-4 bg-accent-primary text-white rounded-full text-lg font-medium hover:bg-accent-primary/90 hover:scale-105 transition-all duration-300"
                    >
                      Start Your Journey
                      <ArrowRight className="ml-2 h-5 w-5 inline" />
                    </button>
                  </div>
                </motion.div>
              </section>
            </div>
          )}
        </div>
      )}
    </AnimatePresence>
  );
};

export default CoachLandingPage; 