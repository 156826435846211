// src/components/SessionTab.jsx

import React, { useEffect, useState } from 'react';
import { Button } from './ui/Button';
import { Input } from './ui/Input';
import { Plus, ArrowLeft } from 'lucide-react';
import { format } from 'date-fns';
import { Card, CardContent, CardHeader, CardTitle } from './ui/Card';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import InsightsGenerator from './InsightsGenerator';
import { useAuth } from '../contexts/AuthContext';



const SessionTab = ({
  clientId,
  sessions,
  setSessions,
  selectedSessionId,
  setSelectedSessionId,
  isAddingSession,
  setIsAddingSession,
  sessionData,
  setSessionData,
  clientInfo,
  setClientInfo,
  isUpdatingGoal,
  setIsUpdatingGoal,
  newGoal,
  setNewGoal,
  handleUpdateGoal,
  handleTrackAdherence,
  handleManageNudges,
  handleSaveSession,
}) => { 
  const { user } = useAuth();
  const [showInsightsGenerator, setShowInsightsGenerator] = useState(false);
  const [newInsights, setNewInsights] = useState(null);
  


  useEffect(() => {
    const fetchSessionData = async () => {
      if (selectedSessionId && user) {
        try {
          const sessionDocRef = doc(db, 'users', clientId, 'sessions', selectedSessionId);
          const docSnapshot = await getDoc(sessionDocRef);
          if (docSnapshot.exists()) {
            setSessionData({ id: docSnapshot.id, ...docSnapshot.data() });
          } else {
            console.error('Session document does not exist!');
          }
        } catch (error) {
          console.error('Error fetching session data:', error);
          alert('Error fetching session data: ' + error.message);
        }
      } else if (isAddingSession) {
        setSessionData({ note: '' });
      } else {
        setSessionData(null);
      }
    };
    fetchSessionData();
  }, [selectedSessionId, isAddingSession, user, clientId, setSessionData]);

  const handleSaveClick = () => {
    if (!sessionData?.note?.trim()) {
      alert('Please enter session notes before saving.');
      return;
    }
    handleSaveSession();
  };

  const handleSetPrimaryCoach = async () => {
    if (!user) return;
    try {
      await updateDoc(doc(db, 'users', clientId), {
        coachId: user.uid,
      });
      setClientInfo({ ...clientInfo, coachId: user.uid });
      alert('You are now the primary coach for this client.');
    } catch (error) {
      console.error('Error setting primary coach:', error);
      alert('Error setting primary coach: ' + error.message);
    }
  };

  const handleInsightsGenerated = (insights) => {
    setNewInsights(insights);
    setShowInsightsGenerator(false);
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="h-full flex flex-col">
      {/* Header section */}
      <div className="flex-none mb-4">
        {selectedSessionId || isAddingSession ? (
          <div className="flex justify-between items-center">
            <Button
              variant="ghost"
              className="text-blue-400 hover:text-blue-300 hover:bg-gray-800 transition-colors"
              onClick={() => {
                setSelectedSessionId(null);
                setIsAddingSession(false);
                setSessionData(null);
              }}
            >
              <ArrowLeft className="mr-2 h-4 w-4" /> Back to Sessions
            </Button>
            <div className="space-x-2">
              <Button
                variant="outline"
                className="text-gray-300 border-gray-700 hover:bg-gray-800 transition-colors"
                onClick={handleTrackAdherence}
              >
                Track Adherence
              </Button>
              <Button
                variant="outline"
                className="text-gray-300 border-gray-700 hover:bg-gray-800 transition-colors"
                onClick={handleManageNudges}
              >
                Manage Nudges
              </Button>
              {user && clientInfo?.coachId !== user.uid && (
                <Button
                  variant="outline"
                  className="text-gray-300 border-gray-700 hover:bg-gray-800 transition-colors"
                  onClick={handleSetPrimaryCoach}
                >
                  Set Primary Coach
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div className="flex justify-end space-x-2">
            <Button
              variant="outline"
              className="text-gray-300 border-gray-700 hover:bg-gray-800 transition-colors"
              onClick={handleManageNudges}
            >
              Manage Nudges
            </Button>
            {clientInfo.coachId !== user.uid && (
              <Button
                variant="outline"
                className="text-gray-300 border-gray-700 hover:bg-gray-800 transition-colors"
                onClick={handleSetPrimaryCoach}
              >
                Set Primary Coach
              </Button>
            )}
            <Button
              variant="outline"
              className="text-blue-400 border-gray-700 hover:bg-gray-800 transition-colors"
              onClick={() => {
                setIsAddingSession(true);
                setSessionData({ note: '' });
              }}
            >
              <Plus className="mr-2 h-4 w-4" /> Add New Session
            </Button>
          </div>
        )}
      </div>
  
      {/* Main content area */}
      <div className="flex-1 overflow-y-auto min-h-0">
        {!selectedSessionId && !isAddingSession ? (
          // Session List View
          <div className="space-y-2">
            {sessions.map((session) => (
              <Card
                key={session.id}
                className="bg-gray-800 hover:bg-gray-700 transition-colors cursor-pointer"
                onClick={() => setSelectedSessionId(session.id)}
              >
                <CardContent className="p-4">
                  <p className="text-gray-300">
                    Session on {format(new Date(session.date), 'MMMM d, yyyy')}
                  </p>
                </CardContent>
              </Card>
            ))}
          </div>
        ) : (
          // Session Detail View
          <div className="space-y-6">
            {/* AI-Generated Insights Card */}
            <Card className="bg-[#333333]">
              <div className="p-6 flex flex-row justify-between items-center border-b border-gray-700">
                <CardTitle className="text-white text-xl">AI-Generated Insights</CardTitle>
                <Button
                  variant="outline"
                  className="bg-blue-600 text-white hover:bg-blue-700 border-blue-500"
                  onClick={() => setShowInsightsGenerator(true)}
                >
                  Generate
                </Button>
              </div>
              <CardContent className="p-6">
                {newInsights || sessionData?.insights ? (
                  <div className="space-y-6">
                    {/* Summary Section */}
                    <div>
                      <h3 className="text-lg font-semibold text-blue-400 mb-2">Summary</h3>
                      <p className="text-gray-300">
                        {(newInsights || sessionData.insights).summary}
                      </p>
                    </div>
                    
                    {/* Wins Section */}
                    {(newInsights || sessionData.insights).wins &&
                      Object.keys((newInsights || sessionData.insights).wins).length > 0 && (
                        <div>
                          <h3 className="text-lg font-semibold text-green-400 mb-2">Wins</h3>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {Object.entries((newInsights || sessionData.insights).wins).map(
                              ([category, items]) => (
                                <div key={category} className="bg-gray-800 rounded-lg p-4">
                                  <h4 className="text-white font-medium mb-2 capitalize">
                                    {category.replace(/_/g, ' ')}
                                  </h4>
                                  <ul className="space-y-2">
                                    {items.map((item, index) => (
                                      <li key={index} className="text-gray-300 flex items-start">
                                        <span className="text-green-400 mr-2">•</span>
                                        {item}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )}
  
                    {/* Challenges Section */}
                    {(newInsights || sessionData.insights).challenges &&
                      Object.keys((newInsights || sessionData.insights).challenges).length > 0 && (
                        <div>
                          <h3 className="text-lg font-semibold text-yellow-400 mb-2">
                            Challenges
                          </h3>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {Object.entries(
                              (newInsights || sessionData.insights).challenges
                            ).map(([category, items]) => (
                              <div key={category} className="bg-gray-800 rounded-lg p-4">
                                <h4 className="text-white font-medium mb-2 capitalize">
                                  {category.replace(/_/g, ' ')}
                                </h4>
                                <ul className="space-y-2">
                                  {items.map((item, index) => (
                                    <li key={index} className="text-gray-300 flex items-start">
                                      <span className="text-yellow-400 mr-2">•</span>
                                      {item}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
  
                    {/* Recommendations Section */}
                    {(newInsights || sessionData.insights).recommendations &&
                      (newInsights || sessionData.insights).recommendations.length > 0 && (
                        <div>
                          <h3 className="text-lg font-semibold text-purple-400 mb-2">
                            AI Recommendations
                          </h3>
                          <div className="bg-gray-800 rounded-lg p-4">
                            <ul className="space-y-3">
                              {(newInsights || sessionData.insights).recommendations.map(
                                (item, index) => (
                                  <li key={index} className="text-gray-300 flex items-start">
                                    <span className="text-purple-400 mr-2">{index + 1}.</span>
                                    {item}
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      )}
                  </div>
                ) : (
                  <p className="text-gray-300">
                    Click "Generate" to get AI-generated insights.
                  </p>
                )}
              </CardContent>
            </Card>
  
            {/* Session Notes Card */}
            <Card className="bg-[#333333]">
              <CardHeader>
                <CardTitle className="text-white">Session Notes</CardTitle>
              </CardHeader>
              <CardContent>
                <textarea
                  className="w-full h-32 p-2 bg-[#1C1C1C] border border-gray-700 text-gray-200 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  value={sessionData?.note || ''}
                  onChange={(e) =>
                    setSessionData({ ...(sessionData || {}), note: e.target.value })
                  }
                  placeholder="Enter session notes here..."
                />
              </CardContent>
            </Card>
  
            {/* Current Goal Card */}
            <Card className="bg-[#333333]">
              <CardHeader>
                <CardTitle className="text-white">Current Goal</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="bg-[#1C1C1C] p-3 rounded-md mb-4">
                  <p className="text-gray-200">
                    {clientInfo.goals.length > 0 ? clientInfo.goals[0].value : 'No goal set'}
                  </p>
                </div>
                {isUpdatingGoal ? (
                  <div className="flex items-center space-x-2">
                    <Input
                      value={newGoal}
                      onChange={(e) => setNewGoal(e.target.value)}
                      placeholder="Enter new goal"
                      className="flex-grow bg-gray-900 border-gray-700 text-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    />
                    <Button
                      onClick={handleUpdateGoal}
                      className="bg-blue-600 hover:bg-blue-700 text-white transition-colors"
                    >
                      Update
                    </Button>
                    <Button
                      onClick={() => {
                        setIsUpdatingGoal(false);
                        setNewGoal('');
                      }}
                      variant="ghost"
                      className="text-gray-300 hover:bg-gray-700 transition-colors"
                    >
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <Button
                    onClick={() => setIsUpdatingGoal(true)}
                    variant="outline"
                    className="text-blue-400 border-gray-700 hover:bg-gray-800 transition-colors"
                  >
                    Update Goal
                  </Button>
                )}
              </CardContent>
            </Card>
          </div>
        )}
      </div>
  
      {/* Footer - Save button for session detail view */}
      {(selectedSessionId || isAddingSession) && (
        <div className="flex-none mt-4">
          <Button
            variant="outline"
            className="text-blue-400 border-gray-700 hover:bg-gray-800 transition-colors w-full"
            onClick={handleSaveClick}
          >
            {isAddingSession ? (
              <>
                <Plus className="mr-2 h-4 w-4" /> Save New Session
              </>
            ) : (
              'Save Changes'
            )}
          </Button>
        </div>
      )}
  
      {/* Insights Generator Modal */}
      {showInsightsGenerator && (
        <InsightsGenerator
          clientId={clientId}
          onClose={() => setShowInsightsGenerator(false)}
          onInsightsGenerated={handleInsightsGenerated}
        />
      )}
    </div>
  );
};

export default SessionTab;
