// components/ui/Button.jsx
import React from 'react';
import { cn } from '../../lib/utils';

const Button = React.forwardRef(
  ({ variant = 'default', size = 'default', className = '', children, ...props }, ref) => {
    let variantClasses = '';
    let sizeClasses = '';

    // Handle variant styles
    switch (variant) {
      case 'default':
        variantClasses = 'bg-blue-500 text-white hover:bg-blue-600';
        break;
      case 'outline':
        variantClasses = 'border border-gray-700 text-white hover:bg-gray-800';
        break;
      case 'ghost':
        variantClasses = 'text-gray-400 hover:text-white hover:bg-gray-800';
        break;
      default:
        variantClasses = 'bg-blue-500 text-white hover:bg-blue-600';
    }

    // Handle size styles
    switch (size) {
      case 'default':
        sizeClasses = 'h-10 px-4';
        break;
      case 'sm':
        sizeClasses = 'h-8 px-3 text-sm';
        break;
      case 'lg':
        sizeClasses = 'h-11 px-6';
        break;
      case 'icon':
        sizeClasses = 'h-9 w-9';
        break;
      default:
        sizeClasses = 'h-10 px-4';
    }

    return (
      <button
        ref={ref}
        className={cn(
          'inline-flex items-center justify-center rounded-md font-medium',
          'transition-colors duration-200',
          variantClasses,
          sizeClasses,
          className
        )}
        {...props}
      >
        {children}
      </button>
    );
  }
);

export { Button };