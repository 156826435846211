// components/InsightsGenerator.jsx

import React, { useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase'; // Ensure the correct path
import { Button } from './ui/Button';

const InsightsGenerator = ({ clientId, onClose, onInsightsGenerated }) => {
  const [timeFrame, setTimeFrame] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [insights, setInsights] = useState(null);
  const [error, setError] = useState(null);
  const containerHeightClass = timeFrame && (isGenerating || insights) ? 'h-3/4' : 'h-auto';


  const handleGenerateInsights = async (days) => {
    setTimeFrame(days);
    setIsGenerating(true);
    setError(null);

    try {
      const generateInsights = httpsCallable(functions, 'generateInsights');
      const result = await generateInsights({ clientId, timeFrameDays: days });

      if (result.data.success) {
        setInsights(result.data.data);
        if (onInsightsGenerated) {
            onInsightsGenerated(result.data.data);
          }
      } else {
        setError(result.data.message || 'Failed to generate insights.');
      }
    } catch (err) {
      console.error('Error calling generateInsights:', err);
      setError(err.message || 'Error generating insights.');
    } finally {
      setIsGenerating(false);
    }
  };

  const renderInsights = () => {
    if (!insights) return null;
  
    const { summary, wins, challenges, recommendations } = insights;
  
    return (
      <div className="space-y-6">
        {/* Summary Section */}
        <div>
          <h2 className="text-xl font-bold text-white mb-2">Summary</h2>
          <p className="text-gray-300 bg-gray-800 p-4 rounded-lg">{summary}</p>
        </div>
  
        {/* Wins Section */}
        {wins && Object.keys(wins).length > 0 && (
          <div>
            <h2 className="text-xl font-bold text-green-400 mb-2">Wins</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {Object.entries(wins).map(([category, items]) => (
                <div key={category} className="bg-gray-800 rounded-lg p-4">
                  <h3 className="text-lg font-semibold text-white mb-2 capitalize">
                    {category.replace(/_/g, ' ')}
                  </h3>
                  <ul className="space-y-2">
                    {items.map((item, index) => (
                      <li key={index} className="text-gray-300 flex items-start">
                        <span className="text-green-400 mr-2">•</span>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        )}
  
        {/* Challenges Section */}
        {challenges && Object.keys(challenges).length > 0 && (
          <div>
            <h2 className="text-xl font-bold text-yellow-400 mb-2">Challenges</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {Object.entries(challenges).map(([category, items]) => (
                <div key={category} className="bg-gray-800 rounded-lg p-4">
                  <h3 className="text-lg font-semibold text-white mb-2 capitalize">
                    {category.replace(/_/g, ' ')}
                  </h3>
                  <ul className="space-y-2">
                    {items.map((item, index) => (
                      <li key={index} className="text-gray-300 flex items-start">
                        <span className="text-yellow-400 mr-2">•</span>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        )}
  
        {/* Recommendations Section */}
        {recommendations && recommendations.length > 0 && (
          <div>
            <h2 className="text-xl font-bold text-purple-400 mb-2">AI Recommendations</h2>
            <div className="bg-gray-800 rounded-lg p-4">
              <ul className="space-y-3">
                {recommendations.map((item, index) => (
                  <li key={index} className="text-gray-300 flex items-start">
                    <span className="text-purple-400 mr-2">{index + 1}.</span>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className={`bg-gray-800 p-6 rounded-lg w-full max-w-3xl overflow-y-auto ${containerHeightClass}`}>
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-bold text-white">Generate Insights</h1>
          <Button onClick={onClose} variant="ghost" className="text-gray-300 hover:bg-gray-700">
            Close
          </Button>
        </div>

        {!timeFrame && (
          <div className="space-y-4">
            <p className="text-gray-300">Select a time frame for the conversation history:</p>
            <div className="flex space-x-4">
              <Button
                onClick={() => handleGenerateInsights(7)}
                className="bg-blue-600 text-white hover:bg-blue-700"
              >
                Last 7 Days
              </Button>
              <Button
                onClick={() => handleGenerateInsights(14)}
                className="bg-blue-600 text-white hover:bg-blue-700"
              >
                Last 14 Days
              </Button>
              <Button
                onClick={() => handleGenerateInsights(21)}
                className="bg-blue-600 text-white hover:bg-blue-700"
              >
                Last 21 Days
              </Button>
            </div>
          </div>
        )}

        {isGenerating && (
          <div className="flex items-center justify-center py-8">
            {/* Loading spinner */}
            <svg
              className="animate-spin h-8 w-8 text-gray-300"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              ></path>
            </svg>
          </div>
        )}

        {error && (
          <div className="text-red-500 mt-4">
            <p>{error}</p>
          </div>
        )}

        {!isGenerating && insights && (
          <div className="mt-4">
            {renderInsights()}
          </div>
        )}
      </div>
    </div>
  );
};

export default InsightsGenerator;
