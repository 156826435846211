// src/components/TemplateManagement.jsx

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { Button } from './ui/Button';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { Plus } from 'lucide-react';

const TemplateManagement = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const templatesRef = collection(db, `coaches/${user.uid}/workoutTemplates`);
        const snapshot = await getDocs(templatesRef);
        const templateList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        // Sort by date created
        templateList.sort((a, b) => b.createdAt?.toMillis() - a.createdAt?.toMillis());
        setTemplates(templateList);
      } catch (error) {
        console.error('Error fetching templates:', error);
      } finally {
        setLoading(false);
      }
    };

    if (user?.uid) {
      fetchTemplates();
    }
  }, [user]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-black">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black text-white pt-20 px-4">
      <Card className="max-w-4xl mx-auto bg-neutral-900 border-0">
        <CardHeader>
          <CardTitle className="flex items-center justify-between">
            <span>Workout Templates</span>
            <Button
              onClick={() => navigate('/create-template')}
              className="bg-blue-600 hover:bg-blue-700"
            >
              <Plus className="h-4 w-4 mr-2" />
              Create Template
            </Button>
          </CardTitle>
        </CardHeader>
        <CardContent>
          {templates.length === 0 ? (
            <div className="text-center text-gray-400 py-8">
              No templates created yet. Click the button above to create your first template.
            </div>
          ) : (
            <div className="space-y-4">
              {templates.map(template => (
                <Card 
                  key={template.id} 
                  className="bg-neutral-800 hover:bg-neutral-700 cursor-pointer transition-colors"
                  onClick={() => navigate(`/templates/edit/${template.id}`)}
                >
                  <CardContent className="p-4">
                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="font-semibold">{template.name}</h3>
                        <p className="text-sm text-gray-400">
                          {template.description}
                        </p>
                        <p className="text-sm text-blue-400">
                          {template.exercises?.length || 0} exercises
                        </p>
                      </div>
                      {template.headerImageUrl && (
                        <img
                          src={template.headerImageUrl}
                          alt="Template"
                          className="h-16 w-16 object-cover rounded"
                        />
                      )}
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default TemplateManagement;
