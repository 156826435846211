// src/components/MessageEditor.jsx

import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Button } from './ui/Button';
import { Save, Plus, Trash2, MessageCircle, Edit2, X } from 'lucide-react';
import { Dialog, DialogContent } from './ui/Dialog';
import { cn } from '../lib/utils';

const MessageEditor = ({ userId, isOpen, onClose }) => {
  const [conversations, setConversations] = useState({});
  const [editingMessage, setEditingMessage] = useState(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (isOpen) {
      fetchConversations();
    }
  }, [isOpen, userId]);

  const fetchConversations = async () => {
    try {
      const docRef = doc(db, 'coaches', userId, 'philosophy', 'messageStyleSettings');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setConversations(docSnap.data().exampleConversation || {});
      }
    } catch (error) {
      console.error('Error fetching conversations:', error);
    }
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      const docRef = doc(db, 'coaches', userId, 'philosophy', 'messageStyleSettings');
      await updateDoc(docRef, {
        exampleConversation: conversations
      });
      setEditingMessage(null);
    } catch (error) {
      console.error('Error saving conversations:', error);
    } finally {
      setSaving(false);
    }
  };

  const addMessage = (conversationKey, role) => {
    setConversations(prev => {
      const currentMessages = prev[conversationKey] || [];
      return {
        ...prev,
        [conversationKey]: [...currentMessages, { role, message: '' }]
      };
    });
    const newIndex = conversations[conversationKey]?.length || 0;
    setEditingMessage(`${conversationKey}-${newIndex}`);
  };

  const addNewConversation = () => {
    const newKey = `Conversation ${Object.keys(conversations).length + 1}`;
    setConversations(prev => ({
      ...prev,
      [newKey]: []
    }));
  };

  const deleteMessage = (conversationKey, messageIndex) => {
    setConversations(prev => ({
      ...prev,
      [conversationKey]: prev[conversationKey].filter((_, idx) => idx !== messageIndex)
    }));
  };

  const updateMessage = (conversationKey, messageIndex, field, value) => {
    setConversations(prev => ({
      ...prev,
      [conversationKey]: prev[conversationKey].map((msg, idx) => 
        idx === messageIndex ? { ...msg, [field]: value } : msg
      )
    }));
  };

  const MessageInput = ({ value, onChange, onSave, onCancel, placeholder }) => (
    <div className="space-y-3">
      <textarea
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        className="w-full p-3 bg-background-primary border border-border-light rounded-lg 
                 text-text-primary placeholder-text-light focus:ring-2 focus:ring-accent-primary 
                 focus:border-accent-primary transition-all duration-200 min-h-[100px] font-body
                 direction-ltr"
        autoFocus
      />
      <div className="flex justify-end gap-2">
        <Button onClick={onCancel} variant="ghost" className="text-text-secondary hover:text-text-primary">
          Cancel
        </Button>
        <Button onClick={onSave} className="bg-accent-primary text-white hover:bg-hover-primary">
          <Save className="w-4 h-4 mr-2" />
          Save
        </Button>
      </div>
    </div>
  );

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-3xl w-full bg-background-secondary p-6 rounded-xl border-0">
        <div className="flex items-center justify-between mb-6 pb-4 border-b border-border-light">
          <div className="flex items-center gap-3">
            <MessageCircle className="w-6 h-6 text-accent-primary" />
            <h2 className="text-xl font-headers text-text-primary">Example Conversations</h2>
          </div>
          <button
            onClick={onClose}
            className="p-2 text-text-light hover:text-text-primary rounded-lg 
                     transition-colors hover:bg-background-tertiary"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="space-y-8 max-h-[70vh] overflow-y-auto pr-4 custom-scrollbar">
          {Object.entries(conversations).map(([key, messages], conversationIndex) => (
            <div key={key}>
              <div className="space-y-4 mb-8">
                <div className="flex items-center gap-2">
                  <h3 className="font-headers text-lg text-text-primary">{key}</h3>
                  <span className="text-xs text-text-light font-body">
                    {messages.length} messages
                  </span>
                </div>

                <div className="space-y-4 bg-background-primary p-4 rounded-lg">
                  {messages.map((message, index) => (
                    <div
                      key={index}
                      className={cn(
                        "group flex items-start gap-3",
                        message.role === 'coach' ? 'flex-row-reverse' : ''
                      )}
                    >
                      <div className={cn(
                        "relative max-w-[80%]",
                        message.role === 'coach' ? 'items-end text-right' : 'items-start text-left'
                      )}>
                        <div className={cn(
                          "px-4 py-3 rounded-2xl break-words font-body shadow-sm",
                          message.role === 'coach'
                            ? "bg-accent-primary text-white rounded-tr-sm"
                            : "bg-background-secondary text-text-primary rounded-tl-sm"
                        )}>
                          {editingMessage === `${key}-${index}` ? (
                            <MessageInput
                              value={message.message}
                              onChange={(value) => updateMessage(key, index, 'message', value)}
                              onSave={() => setEditingMessage(null)}
                              onCancel={() => setEditingMessage(null)}
                              placeholder="Enter message..."
                            />
                          ) : (
                            <div className="whitespace-pre-wrap direction-ltr">
                              {message.message}
                            </div>
                          )}
                        </div>

                        <div className={cn(
                          "absolute top-0 flex items-center gap-1 opacity-0 group-hover:opacity-100 transition-opacity",
                          message.role === 'coach' ? '-left-16' : '-right-16'
                        )}>
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => setEditingMessage(`${key}-${index}`)}
                            className="h-8 w-8 p-0 text-text-light hover:text-accent-primary"
                          >
                            <Edit2 className="h-4 w-4" />
                          </Button>
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => deleteMessage(key, index)}
                            className="h-8 w-8 p-0 text-text-light hover:text-red-500"
                          >
                            <Trash2 className="h-4 w-4" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              
              {conversationIndex < Object.entries(conversations).length - 1 && (
                <div className="border-t border-border-light my-8" />
              )}
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MessageEditor;