// src/components/GoalForm.jsx

import React, { useState, useEffect } from 'react';
import { DialogContent, DialogHeader, DialogTitle, DialogDescription } from './ui/Dialog';
import { Input } from './ui/Input';
import { Button } from './ui/Button';
import { Label } from './ui/Label';
import { Progress } from './ui/Progress';
import { Calendar } from 'lucide-react';
import { Alert, AlertDescription } from './ui/Alert';

const GoalForm = ({ 
  isEditing,
  formData,
  handleInputChange,
  handleSubmit,
  onClose 
}) => {
  const [validation, setValidation] = useState({
    title: true,
    targetDate: true,
    targetValue: true
  });
  
  const [progress, setProgress] = useState(0);
  
  useEffect(() => {
    if (formData.currentValue && formData.targetValue) {
      const percentage = (formData.currentValue / formData.targetValue) * 100;
      setProgress(Math.min(100, Math.max(0, percentage)));
    }
  }, [formData.currentValue, formData.targetValue]);

  const handleValidation = (name, value) => {
    switch (name) {
      case 'title':
        setValidation(prev => ({ ...prev, title: value.length >= 3 }));
        break;
      case 'targetDate':
        const selectedDate = new Date(value);
        const today = new Date();
        setValidation(prev => ({ ...prev, targetDate: selectedDate >= today }));
        break;
      case 'targetValue':
        setValidation(prev => ({ ...prev, targetValue: value > 0 }));
        break;
      default:
        break;
    }
  };

  const handleLocalInputChange = (e) => {
    const { name, value } = e.target;
    handleInputChange(e);
    handleValidation(name, value);
  };

  const handleLocalSubmit = (e) => {
    e.preventDefault();
    const isValid = Object.values(validation).every(v => v);
    if (!isValid) return;
    handleSubmit(e);
  };

  const remainingDays = () => {
    if (!formData.targetDate) return null;
    const today = new Date();
    const target = new Date(formData.targetDate);
    const diff = Math.ceil((target - today) / (1000 * 60 * 60 * 24));
    return diff;
  };

  return (
    <DialogContent className="sm:max-w-[525px] bg-background-secondary border border-border-light shadow-lg">
      <DialogHeader>
        <DialogTitle className="text-2xl font-headers font-bold text-text-primary">
          {isEditing ? 'Edit Goal' : 'Create New Goal'}
        </DialogTitle>
        <DialogDescription className="text-text-light font-body">
          {isEditing ? 'Update your goal details and track progress' : 'Set a new goal and start tracking your journey'}
        </DialogDescription>
      </DialogHeader>

      <form onSubmit={handleLocalSubmit} className="space-y-6">
        <div className="space-y-2">
          <Label htmlFor="title" className="text-sm font-medium text-text-secondary font-body">
            Goal Title
          </Label>
          <Input
            id="title"
            name="title"
            value={formData.title}
            onChange={handleLocalInputChange}
            placeholder="e.g., Increase Bench Press"
            className={`bg-background-tertiary border-border-light text-text-primary placeholder-text-light font-body
              ${!validation.title ? 'border-red-500' : 'focus:border-accent-primary hover:border-accent-secondary'}`}
            required
          />
          {!validation.title && (
            <p className="text-sm text-red-400 font-body">Title must be at least 3 characters</p>
          )}
        </div>

        <div className="space-y-2">
          <Label htmlFor="targetDate" className="text-sm font-medium text-text-secondary font-body flex items-center gap-2">
            <Calendar className="w-4 h-4 text-accent-secondary" />
            Target Date
          </Label>
          <Input
            id="targetDate"
            type="date"
            name="targetDate"
            value={formData.targetDate}
            onChange={handleLocalInputChange}
            className={`bg-background-tertiary border-border-light text-text-primary font-body
              ${!validation.targetDate ? 'border-red-500' : 'focus:border-accent-primary hover:border-accent-secondary'}`}
            required
            min={new Date().toISOString().split('T')[0]}
          />
          {!validation.targetDate && (
            <p className="text-sm text-red-400 font-body">Target date must be in the future</p>
          )}
          {formData.targetDate && remainingDays() > 0 && (
            <p className="text-sm text-accent-primary font-body">
              {remainingDays()} days remaining
            </p>
          )}
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label htmlFor="targetValue" className="text-sm font-medium text-text-secondary font-body">
              Target Value
            </Label>
            <Input
              id="targetValue"
              type="number"
              name="targetValue"
              value={formData.targetValue}
              onChange={handleLocalInputChange}
              placeholder="e.g., 225"
              className={`bg-background-tertiary border-border-light text-text-primary placeholder-text-light font-body
                ${!validation.targetValue ? 'border-red-500' : 'focus:border-accent-primary hover:border-accent-secondary'}`}
              required
              min="0"
              step="any"
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="unit" className="text-sm font-medium text-text-secondary font-body">
              Unit of Measurement
            </Label>
            <Input
              id="unit"
              name="unit"
              value={formData.unit}
              onChange={handleLocalInputChange}
              placeholder="e.g., lbs, km, minutes"
              className="bg-background-tertiary border-border-light text-text-primary placeholder-text-light font-body
                focus:border-accent-primary hover:border-accent-secondary"
              required
            />
          </div>
        </div>

        {isEditing && (
          <>
            <div className="space-y-2">
              <Label htmlFor="currentValue" className="text-sm font-medium text-text-secondary font-body">
                Current Progress
              </Label>
              <div className="grid grid-cols-2 gap-4">
                <Input
                  id="currentValue"
                  type="number"
                  name="currentValue"
                  value={formData.currentValue}
                  onChange={handleLocalInputChange}
                  placeholder="Current value"
                  className="bg-background-tertiary border-border-light text-text-primary placeholder-text-light font-body
                    focus:border-accent-primary hover:border-accent-secondary"
                  min="0"
                  step="any"
                />
                <span className="text-sm text-accent-secondary font-medium font-body self-center">
                  {formData.unit}
                </span>
              </div>
            </div>

            {formData.currentValue && formData.targetValue && (
              <div className="space-y-2">
                <div className="flex justify-between text-sm font-body">
                  <span className="text-text-light">Progress</span>
                  <span className="font-medium text-accent-primary">{Math.round(progress)}%</span>
                </div>
                <div className="h-2 w-full bg-background-tertiary rounded-full overflow-hidden">
                  <div
                    className="h-full bg-accent-primary transition-all duration-500 ease-out"
                    style={{ width: `${progress}%` }}
                  />
                </div>
                {progress >= 100 && (
                  <Alert className="mt-2 bg-background-tertiary border border-accent-secondary">
                    <AlertDescription className="text-accent-primary font-body">
                      🎉 Congratulations! You've reached your goal!
                    </AlertDescription>
                  </Alert>
                )}
              </div>
            )}
          </>
        )}

        <div className="flex justify-end gap-3 pt-4">
          <Button
            variant="ghost"
            onClick={onClose}
            className="text-text-light hover:bg-background-tertiary hover:text-text-primary font-body"
          >
            Cancel
          </Button>
          <Button 
            type="submit"
            variant="primary"
            disabled={!Object.values(validation).every(v => v)}
          >
            {isEditing ? 'Update Goal' : 'Create Goal'}
          </Button>
        </div>
      </form>
    </DialogContent>
  );
};

export default GoalForm;