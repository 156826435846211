import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { doc, getDoc, setDoc, updateDoc, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import { useOutletContext } from 'react-router-dom';
import { MessageCircle, Save, AlertTriangle, Send, User, CheckCircle, ChevronLeft, ChevronRight, Info } from 'lucide-react';
import { Button } from '../ui/Button';
import confetti from 'canvas-confetti';

const MessagingSetup = () => {
  const { user } = useAuth();
  const { goToNextStep, goToPrevStep } = useOutletContext();
  const messagesEndRef = useRef(null);
  
  // Core states
  const [isLoading, setIsLoading] = useState(true);
  const [currentResponse, setCurrentResponse] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [savedStatus, setSavedStatus] = useState(null);
  const [showIntro, setShowIntro] = useState(true);
  const [showCompletion, setShowCompletion] = useState(false);
  const [coachFirstName, setCoachFirstName] = useState('');
  
  // Refs for managing timeouts and updates
  const timeoutRefs = useRef([]);
  const needsUpdate = useRef(false);
  
  // Core states for conversation
  const [conversationData, setConversationData] = useState({
    activeIndex: 0,
    state: 'initial', // 'initial', 'followup1', 'followup2', 'complete'
    messages: [],
    completedConversations: []
  });
  
  // Preserved separate states for better readability where it makes sense
  const [isClientTyping, setIsClientTyping] = useState(false);
  const [storedResponses, setStoredResponses] = useState({});
  
  // Conversation topics - reduced to 3 turns (initial + 2 followups)
  const conversationTopics = [
    {
      id: 'consistency',
      title: "Consistency Challenges",
      initialMessage: "Hey coach, I've been struggling to stay consistent with my workouts. Work has been crazy this week.",
      followupQuestion: "That makes sense. I'll try to adjust my schedule. How many days should I aim for when things are this hectic?",
      followupQuestion2: "Good advice! Any specific workouts you recommend when I'm short on time?"
    },
    {
      id: 'nutrition',
      title: "Pre-Workout Nutrition",
      initialMessage: "What should I eat before my morning workout? I always feel like I run out of energy halfway through.",
      followupQuestion: "That's helpful! How long before training should I eat this?",
      followupQuestion2: "Perfect, I'll give that a try tomorrow. Should I have coffee as well or avoid caffeine?"
    },
    {
      id: 'motivation',
      title: "Progress Concerns",
      initialMessage: "I've been following the plan for 2 weeks now but don't see any changes yet. Is this normal or am I doing something wrong?",
      followupQuestion: "That's reassuring. What other ways can I track progress besides just looking in the mirror?",
      followupQuestion2: "That makes a lot of sense. I'll focus on those metrics instead."
    }
  ];
  
  // Global timeout cleanup
  useEffect(() => {
    return () => {
      console.log("DEBUG: Cleaning up timeouts", timeoutRefs.current.length);
      timeoutRefs.current.forEach(id => clearTimeout(id));
      timeoutRefs.current = [];
    };
  }, []);
  
  // Helper function to update conversation data
  // Modify the updateConversationData function to include logging
  const updateConversationData = (updates) => {
    console.log("DEBUG: Updating conversation data", updates);
    // Prevent unnecessary updates
    const hasChanged = Object.entries(updates).some(
      ([key, value]) => JSON.stringify(conversationData[key]) !== JSON.stringify(value)
    );
    
    if (hasChanged) {
      setConversationData(prev => ({
        ...prev,
        ...updates
      }));
    } else {
      console.log("DEBUG: Skipped redundant update");
    }
  };
  
  // Get current conversation data
  const currentConversation = conversationTopics[conversationData.activeIndex];
  
  // Consolidated useEffect for handling conversation setup and changes
  useEffect(() => {
    console.log("EFFECT RUNNING - Conversation setup", {
      isLoading, 
      activeIndex: conversationData.activeIndex,
      messagesLength: conversationData.messages.length,
      state: conversationData.state
    });
    
    if (isLoading || !currentConversation) return;
    
    // IMPORTANT: Only update if we need to
    // This prevents infinite update cycles
    const convoId = currentConversation.id;
    const savedResponses = storedResponses[convoId] || {};
    
    // Reset the needs update flag
    needsUpdate.current = false;
    
    // Determine what should be updated
    let newState = conversationData.state;
    let newMessages = [...conversationData.messages];
    
    if (savedResponses.initial && conversationData.messages.length === 0) {
      console.log("DEBUG: Initial messages missing - setting them");
      needsUpdate.current = true;
      
      // Build messages array based on saved responses
      newMessages = [
        { role: 'client', content: currentConversation.initialMessage },
        { role: 'coach', content: savedResponses.initial }
      ];
      
      if (savedResponses.followup1) {
        newMessages.push({ role: 'client', content: currentConversation.followupQuestion });
        newMessages.push({ role: 'coach', content: savedResponses.followup1 });
        
        if (savedResponses.followup2) {
          newMessages.push({ role: 'client', content: currentConversation.followupQuestion2 });
          newMessages.push({ role: 'coach', content: savedResponses.followup2 });
          newState = 'complete';
        } else {
          newState = 'followup2';
        }
      } else {
        newState = 'followup1';
      }
    } else if (!savedResponses.initial && conversationData.messages.length === 0) {
      console.log("DEBUG: Initial client message missing - setting it");
      needsUpdate.current = true;
      newMessages = [{ role: 'client', content: currentConversation.initialMessage }];
      newState = 'initial';
    }
    
    // Only update state if needed
    if (needsUpdate.current) {
      console.log("DEBUG: Updating conversation data", { newState, messageCount: newMessages.length });
      updateConversationData({
        state: newState,
        messages: newMessages
      });
    }
  }, [isLoading, currentConversation, storedResponses, conversationData.messages.length, conversationData.state]);
  
  // Auto-scroll to bottom of messages
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [conversationData.messages]);

  // Add this effect to properly handle conversation changes
  useEffect(() => {
    console.log("DEBUG: Active index changed", conversationData.activeIndex);
    // Reset messages when changing conversations
    updateConversationData({
      messages: [] // This will trigger the main useEffect to set up the right messages
    });
  }, [conversationData.activeIndex]);
  
  // Load existing training data
  useEffect(() => {
    const loadTrainingData = async () => {
      try {
        if (!user?.uid) return;
        setIsLoading(true);
        
        const docRef = doc(db, 'coaches', user.uid, 'philosophy', 'trainingResponses');
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          
          // Transform data into our structure
          const loadedResponses = {};
          const completed = [];
          
          // Consistency conversation
          if (data.clientStrugglingResponse || data.consistencyFollowup1 || data.consistencyFollowup2) {
            loadedResponses.consistency = {
              initial: data.clientStrugglingResponse,
              followup1: data.consistencyFollowup1,
              followup2: data.consistencyFollowup2
            };
            
            // Add to completed if all responses exist
            if (data.clientStrugglingResponse && 
                data.consistencyFollowup1 && 
                data.consistencyFollowup2) {
              completed.push(conversationTopics.findIndex(t => t.id === 'consistency'));
            }
          }
          
          // Nutrition conversation
          if (data.nutritionQuestion || data.nutritionFollowup1 || data.nutritionFollowup2) {
            loadedResponses.nutrition = {
              initial: data.nutritionQuestion,
              followup1: data.nutritionFollowup1,
              followup2: data.nutritionFollowup2
            };
            
            // Add to completed if all responses exist
            if (data.nutritionQuestion && 
                data.nutritionFollowup1 && 
                data.nutritionFollowup2) {
              completed.push(conversationTopics.findIndex(t => t.id === 'nutrition'));
            }
          }
          
          // Motivation conversation
          if (data.motivationStyle || data.motivationFollowup1 || data.motivationFollowup2) {
            loadedResponses.motivation = {
              initial: data.motivationStyle,
              followup1: data.motivationFollowup1,
              followup2: data.motivationFollowup2
            };
            
            // Add to completed if all responses exist
            if (data.motivationStyle && 
                data.motivationFollowup1 && 
                data.motivationFollowup2) {
              completed.push(conversationTopics.findIndex(t => t.id === 'motivation'));
            }
          }
          
          setStoredResponses(loadedResponses);
          
          // If we have completed conversations, find the first incomplete one
          if (completed.length > 0 && completed.length < conversationTopics.length) {
            const incompleteIndex = conversationTopics.findIndex(
              (_, index) => !completed.includes(index)
            );
            
            if (incompleteIndex !== -1) {
              updateConversationData({
                activeIndex: incompleteIndex,
                messages: [{ role: 'client', content: conversationTopics[incompleteIndex].initialMessage }],
                completedConversations: completed
              });
            }
          } else if (completed.length === conversationTopics.length) {
            // All complete, show the last one with success state
            const lastIndex = conversationTopics.length - 1;
            updateConversationData({
              activeIndex: lastIndex,
              state: 'complete',
              messages: [
                { role: 'client', content: conversationTopics[lastIndex].initialMessage },
                { role: 'coach', content: loadedResponses[conversationTopics[lastIndex].id].initial },
                { role: 'client', content: conversationTopics[lastIndex].followupQuestion },
                { role: 'coach', content: loadedResponses[conversationTopics[lastIndex].id].followup1 },
                { role: 'client', content: conversationTopics[lastIndex].followupQuestion2 },
                { role: 'coach', content: loadedResponses[conversationTopics[lastIndex].id].followup2 }
              ],
              completedConversations: completed
            });
          }
        } else {
          // Create initial document
          await setDoc(docRef, {
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp()
          });
        }
      } catch (error) {
        console.error('Error loading training data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    loadTrainingData();
  }, [user]);
  
  // Load coach's first name
  useEffect(() => {
    const loadCoachData = async () => {
      try {
        if (!user?.uid) return;
        
        const coachRef = doc(db, 'coaches', user.uid);
        const coachSnap = await getDoc(coachRef);
        
        if (coachSnap.exists() && coachSnap.data().firstName) {
          setCoachFirstName(coachSnap.data().firstName);
        }
      } catch (error) {
        console.error('Error loading coach data:', error);
      }
    };
    
    loadCoachData();
  }, [user]);
  
  // Handle coach response submission - simplified to 3 turns
  const handleSubmitResponse = async (response) => {
    if (!response.trim() || isClientTyping) return;
    console.log("DEBUG: Response submitted", { state: conversationData.state });
    
    // Add coach response to messages
    const updatedMessages = [...conversationData.messages, { role: 'coach', content: response }];
    updateConversationData({
      messages: updatedMessages
    });
    setCurrentResponse('');
    
    // Update stored responses
    const convoId = currentConversation.id;
    const updatedResponses = { ...storedResponses };
    
    if (!updatedResponses[convoId]) {
      updatedResponses[convoId] = {};
    }
    
    if (conversationData.state === 'initial') {
      console.log("DEBUG: Initial response submitted");
      updatedResponses[convoId].initial = response;
      
      setStoredResponses(updatedResponses);
      saveResponses(updatedResponses);
      
      setIsClientTyping(true);
      
      const typingTimer = setTimeout(() => {
        console.log("DEBUG: Typing timer complete, adding client followup");
        setIsClientTyping(false);
        updateConversationData({
          messages: [...updatedMessages, { 
            role: 'client', 
            content: currentConversation.followupQuestion 
          }],
          state: 'followup1'
        });
      }, 2000);
      
      timeoutRefs.current.push(typingTimer);
    }
    else if (conversationData.state === 'followup1') {
      console.log("DEBUG: First followup response submitted");
      updatedResponses[convoId].followup1 = response;
      
      setStoredResponses(updatedResponses);
      saveResponses(updatedResponses);
      
      setIsClientTyping(true);
      
      const typingTimer = setTimeout(() => {
        setIsClientTyping(false);
        updateConversationData({
          messages: [...updatedMessages, { 
            role: 'client', 
            content: currentConversation.followupQuestion2 
          }],
          state: 'followup2'
        });
      }, 2000);
      
      timeoutRefs.current.push(typingTimer);
    }
    else if (conversationData.state === 'followup2') {
      console.log("DEBUG: Second followup response submitted");
      updatedResponses[convoId].followup2 = response;
      
      updateConversationData({
        state: 'complete',
        completedConversations: [...conversationData.completedConversations, conversationData.activeIndex]
      });
      
      setStoredResponses(updatedResponses);
      saveResponses(updatedResponses);
      
      triggerConfetti();
      
      const advanceTimer = setTimeout(() => {
        // If all conversations are complete, leave it on the success screen
        if (conversationData.completedConversations.length === conversationTopics.length - 1) {
          // This is the last one being completed, leave it as is for success screen
        } else {
          // Find next incomplete conversation
          const remainingConvos = conversationTopics.filter((_, index) => 
            ![...conversationData.completedConversations, conversationData.activeIndex].includes(index)
          );
          
          if (remainingConvos.length > 0) {
            // Get index of next conversation to show
            const nextIndex = conversationTopics.findIndex(c => c.id === remainingConvos[0].id);
            updateConversationData({
              activeIndex: nextIndex,
              state: 'initial',
              messages: [{ role: 'client', content: conversationTopics[nextIndex].initialMessage }]
            });
          }
        }
      }, 2500);
      
      timeoutRefs.current.push(advanceTimer);
    }
  };
  
  // Save responses to Firestore
  const saveResponses = async (updatedResponses = storedResponses) => {
    try {
      if (!user?.uid) return;
      
      setIsSaving(true);
      
      // Create data structure for all conversations
      const trainingData = {
        updatedAt: serverTimestamp()
      };
      
      // Map our stored responses to the expected Firebase structure
      Object.entries(updatedResponses).forEach(([topicId, responses]) => {
        if (topicId === 'consistency') {
          if (responses.initial) trainingData.clientStrugglingResponse = responses.initial;
          if (responses.followup1) trainingData.consistencyFollowup1 = responses.followup1;
          if (responses.followup2) trainingData.consistencyFollowup2 = responses.followup2;
        } else if (topicId === 'nutrition') {
          if (responses.initial) trainingData.nutritionQuestion = responses.initial;
          if (responses.followup1) trainingData.nutritionFollowup1 = responses.followup1;
          if (responses.followup2) trainingData.nutritionFollowup2 = responses.followup2;
        } else if (topicId === 'motivation') {
          if (responses.initial) trainingData.motivationStyle = responses.initial;
          if (responses.followup1) trainingData.motivationFollowup1 = responses.followup1;
          if (responses.followup2) trainingData.motivationFollowup2 = responses.followup2;
        }
      });
      
      // Save to Firestore
      const docRef = doc(db, 'coaches', user.uid, 'philosophy', 'trainingResponses');
      await updateDoc(docRef, trainingData);
      
      // Create Q&A pairs in trainingQA collection for AI training
      const trainingQARef = collection(db, 'coaches', user.uid, 'trainingQA');
      
      // Map conversations to Q&A pairs
      let qaPromises = [];
      
      conversationTopics.forEach((topic) => {
        const responses = updatedResponses[topic.id] || {};
        
        if (responses.initial) {
          qaPromises.push(
            setDoc(doc(trainingQARef, `${topic.id}_initial`), {
              question: topic.initialMessage,
              answer: responses.initial,
              updatedAt: serverTimestamp()
            })
          );
        }
        
        if (responses.followup1) {
          qaPromises.push(
            setDoc(doc(trainingQARef, `${topic.id}_followup1`), {
              question: topic.followupQuestion,
              answer: responses.followup1,
              updatedAt: serverTimestamp()
            })
          );
        }
        
        if (responses.followup2) {
          qaPromises.push(
            setDoc(doc(trainingQARef, `${topic.id}_followup2`), {
              question: topic.followupQuestion2,
              answer: responses.followup2,
              updatedAt: serverTimestamp()
            })
          );
        }
      });
      
      await Promise.all(qaPromises);
      
      setSavedStatus('success');
      setTimeout(() => setSavedStatus(null), 2000);
    } catch (error) {
      console.error('Error saving responses:', error);
      setSavedStatus('error');
      setTimeout(() => setSavedStatus(null), 3000);
    } finally {
      setIsSaving(false);
    }
  };
  
  // Confetti effect when conversation completes
  const triggerConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  };
  
  // Calculate progress percentage - updated for 3 turns
  const getProgress = () => {
    // Each conversation counts as 3 steps (initial + 2 followups)
    const totalSteps = conversationTopics.length * 3;
    let completedSteps = 0;
    
    conversationTopics.forEach((topic, index) => {
      const responses = storedResponses[topic.id] || {};
      if (responses.initial) completedSteps++;
      if (responses.followup1) completedSteps++;
      if (responses.followup2) completedSteps++;
    });
    
    return Math.round((completedSteps / totalSteps) * 100);
  };
  
  // Check if all required responses are completed - updated for 3 turns
  const hasRequiredResponses = () => {
    return conversationTopics.every(topic => {
      const responses = storedResponses[topic.id] || {};
      return responses.initial && responses.followup1 && responses.followup2;
    });
  };
  
  // Handle continue button click
  const handleContinue = async () => {
    if (!hasRequiredResponses()) {
      // Show which conversations need to be completed
      const missingTopics = [];
      conversationTopics.forEach(topic => {
        const responses = storedResponses[topic.id] || {};
        if (!responses.initial || !responses.followup1 || !responses.followup2) {
          missingTopics.push(topic.title);
        }
      });
      
      alert(`Please complete the following conversation(s): ${missingTopics.join(', ')}`);
      return;
    }
    
    await saveResponses();
    goToNextStep();
  };
  
  // Simplified intro screen
  if (showIntro) {
    return (
      <div className="space-y-8">
        <div className="text-center max-w-2xl mx-auto py-12">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-background-secondary rounded-2xl shadow-lg p-10 border border-border-light"
          >
            <div className="inline-block bg-accent-primary/10 p-4 rounded-full mb-6">
              <MessageCircle className="w-12 h-12 text-accent-primary" />
            </div>
            
            <h3 className="text-2xl font-bold text-text-primary font-headers mb-4">
              {coachFirstName ? `Train AI ${coachFirstName}` : 'Train Your AI Coach'}
            </h3>
            
            <p className="text-text-secondary mb-8 font-body max-w-lg mx-auto leading-relaxed">
              Respond to these client messages as if you were texting real clients.
              This helps your AI learn your coaching style and tone.
            </p>
            
            <div className="flex flex-col gap-3 items-center justify-center max-w-sm mx-auto">
              <Button
                onClick={() => setShowIntro(false)}
                className="bg-accent-primary text-white hover:bg-hover-primary transition-all 
                         px-8 py-3.5 rounded-lg w-full flex items-center justify-center gap-2
                         shadow-md hover:shadow-lg"
              >
                <Send className="w-4 h-4" />
                Start Training
              </Button>
              
              <div className="text-xs text-text-light mt-2">
                Three simple conversations to train your AI
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    );
  }
  
  // Loading state
  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="flex flex-col items-center gap-4 text-center px-8 py-12"
        >
          <div className="relative w-14 h-14">
            <div className="absolute inset-0 rounded-full border-t-2 border-accent-primary/20 border-r-2 border-accent-primary/20 border-b-2 border-accent-primary/20 border-l-2 border-accent-primary animate-spin"></div>
            <div className="absolute inset-0 flex items-center justify-center">
              <MessageCircle className="w-6 h-6 text-accent-primary" />
            </div>
          </div>
          <div className="mt-2">
            <p className="text-text-primary font-medium mb-1">Loading your conversations</p>
            <p className="text-text-light text-sm">Preparing your AI training interface...</p>
          </div>
        </motion.div>
      </div>
    );
  }
  
  // Completion screen
  if (showCompletion) {
    return (
      <div className="space-y-8">
        <div className="text-center max-w-3xl mx-auto py-10">
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="bg-background-secondary rounded-2xl shadow-lg p-10 border border-border-light"
          >
            <div className="inline-block bg-green-50 p-5 rounded-full mb-6 border-2 border-green-100">
              <CheckCircle className="w-16 h-16 text-green-500" />
            </div>
            
            <h3 className="text-2xl font-bold text-text-primary font-headers mb-4">
              AI Coach Training Complete!
            </h3>
            
            <p className="text-text-secondary mb-8 font-body text-lg max-w-xl mx-auto">
              Great job! Your AI has learned your coaching style from {conversationTopics.length} different 
              conversations. It's now ready to respond to clients in your unique voice.
            </p>
            
            <div className="bg-background-tertiary p-8 rounded-xl mb-8 shadow-sm border border-border-light">
              <h4 className="font-medium text-lg text-text-primary mb-5">Your AI has learned:</h4>
              <div className="grid md:grid-cols-2 gap-5 text-left">
                <div className="flex items-start gap-3 p-4 bg-background-secondary rounded-lg border border-border-light/50 shadow-sm">
                  <CheckCircle className="w-5 h-5 text-accent-primary shrink-0 mt-0.5" />
                  <span className="text-text-secondary">How you motivate clients facing consistency challenges</span>
                </div>
                <div className="flex items-start gap-3 p-4 bg-background-secondary rounded-lg border border-border-light/50 shadow-sm">
                  <CheckCircle className="w-5 h-5 text-accent-primary shrink-0 mt-0.5" />
                  <span className="text-text-secondary">Your specific nutrition recommendations and philosophy</span>
                </div>
                <div className="flex items-start gap-3 p-4 bg-background-secondary rounded-lg border border-border-light/50 shadow-sm">
                  <CheckCircle className="w-5 h-5 text-accent-primary shrink-0 mt-0.5" />
                  <span className="text-text-secondary">How you manage client expectations around progress</span>
                </div>
                <div className="flex items-start gap-3 p-4 bg-background-secondary rounded-lg border border-border-light/50 shadow-sm">
                  <CheckCircle className="w-5 h-5 text-accent-primary shrink-0 mt-0.5" />
                  <span className="text-text-secondary">Your unique communication style and tone</span>
                </div>
              </div>
            </div>
            
            <div className="flex justify-center">
              <Button
                onClick={handleContinue}
                className="bg-accent-primary text-white hover:bg-hover-primary transition-all 
                         duration-300 shadow-md hover:shadow-lg px-10 py-4 text-lg 
                         min-w-[220px] rounded-xl flex items-center justify-center gap-2"
              >
                Continue to Chat Test
                <ChevronRight className="w-5 h-5" />
              </Button>
            </div>
          </motion.div>
        </div>
      </div>
    );
  }
  
  return (
    <div className="space-y-8">
      {/* Header and progress */}
      <div>
        <h3 className="text-xl font-bold text-text-primary font-headers mb-2">Train Your AI Coach</h3>
        <p className="text-text-secondary mb-5 font-body">
          Respond as if you were texting your actual client. Your AI will learn your unique coaching style.
        </p>
        
        {/* Progress bar */}
        <div className="mb-8">
          <div className="flex justify-between items-center mb-3">
            <div className="flex items-center gap-2">
              <span className="font-medium text-text-primary">
                Conversation {conversationData.activeIndex + 1} of {conversationTopics.length}
              </span>
              <span className="px-2.5 py-1 bg-accent-primary/10 text-accent-primary rounded-md text-sm font-medium">
                {currentConversation.title}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-sm font-medium text-accent-primary">{getProgress()}%</span>
              <span className="text-sm text-text-light">complete</span>
            </div>
          </div>
          <div className="h-2.5 bg-background-tertiary rounded-full overflow-hidden shadow-inner">
            <motion.div 
              initial={{ width: 0 }}
              animate={{ width: `${getProgress()}%` }}
              transition={{ duration: 0.5, ease: "easeOut" }}
              className="h-full bg-accent-primary rounded-full shadow-sm"
            />
          </div>
        </div>
        
        {/* Step indicator with enhanced styling */}
        <div className="flex items-center justify-center mb-10">
          {conversationTopics.map((topic, index) => (
            <div key={index} className="flex items-center">
              {/* Step circle */}
              <div 
                className={`w-10 h-10 rounded-full flex items-center justify-center transition-all duration-300 shadow-sm
                  ${conversationData.completedConversations.includes(index) 
                    ? 'bg-green-50 text-green-600 border-2 border-green-500' 
                    : index === conversationData.activeIndex
                      ? 'bg-accent-primary text-white border-2 border-accent-primary'
                      : 'bg-background-tertiary text-text-light border-2 border-border-light'
                  }`}
              >
                {conversationData.completedConversations.includes(index) 
                  ? <CheckCircle className="w-5 h-5" />
                  : <span className="font-medium">{index + 1}</span>
                }
              </div>
              
              {/* Connector line between steps */}
              {index < conversationTopics.length - 1 && (
                <div className="w-16 h-1 bg-background-tertiary shadow-inner">
                  <motion.div 
                    className="h-full bg-accent-primary"
                    initial={{ width: "0%" }}
                    animate={{ 
                      width: index < conversationData.activeIndex || 
                             conversationData.completedConversations.includes(index) 
                             ? "100%" : "0%" 
                    }}
                    transition={{ duration: 0.5, ease: "easeOut" }}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      
      {/* Chat interface */}
      <div className="bg-background-secondary rounded-xl border border-border-light overflow-hidden flex flex-col h-[550px] shadow-md">
        {/* Message thread */}
        <div className="flex-1 overflow-y-auto p-6 space-y-6 bg-gradient-to-b from-background-secondary to-background-primary">
          <AnimatePresence initial={false} mode="wait">
            <motion.div
              key={`${conversationData.activeIndex}-${conversationData.state}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="space-y-6"
            >
              {conversationData.messages.map((message, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 10, scale: 0.98 }}
                  animate={{ opacity: 1, y: 0, scale: 1 }}
                  transition={{ duration: 0.3, ease: "easeOut" }}
                  className={`flex items-end gap-3 ${message.role === 'client' ? '' : 'justify-end'}`}
                >
                  {message.role === 'client' && (
                    <div className="rounded-full bg-background-tertiary p-2.5 shadow-sm flex-shrink-0 border border-border-light/30">
                      <User className="h-5 w-5 text-text-secondary" />
                    </div>
                  )}
                  <div 
                    className={`max-w-[75%] px-4 py-3.5 shadow-sm
                      ${message.role === 'client' 
                        ? "bg-background-tertiary text-text-primary rounded-t-2xl rounded-br-2xl rounded-bl-md border border-border-light/30" 
                        : "bg-accent-primary text-white rounded-t-2xl rounded-bl-2xl rounded-br-md"}`}
                  >
                    <div className="font-body leading-relaxed">{message.content}</div>
                    <div className={`text-xs mt-1.5 opacity-70 ${message.role === 'client' ? 'text-text-light' : 'text-white'}`}>
                      {message.role === 'client' ? 'Client' : 'You'} · {new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                    </div>
                  </div>
                  {message.role === 'coach' && (
                    <div className="rounded-full bg-accent-secondary p-2.5 shadow-sm flex-shrink-0">
                      <CheckCircle className="h-5 w-5 text-white" />
                    </div>
                  )}
                </motion.div>
              ))}
              
              {/* Client typing indicator */}
              {isClientTyping && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="flex items-center gap-3 pl-12"
                >
                  <div className="rounded-full bg-background-tertiary p-2.5 shadow-sm flex-shrink-0 border border-border-light/30">
                    <User className="h-5 w-5 text-text-secondary" />
                  </div>
                  <div className="bg-background-tertiary text-text-primary px-4 py-3.5 rounded-t-2xl rounded-br-2xl rounded-bl-md shadow-sm border border-border-light/30">
                    <div className="flex items-center space-x-1">
                      <div className="w-2.5 h-2.5 bg-text-light/60 rounded-full animate-pulse" style={{ animationDelay: "0ms", animationDuration: "1s" }}></div>
                      <div className="w-2.5 h-2.5 bg-text-light/60 rounded-full animate-pulse" style={{ animationDelay: "300ms", animationDuration: "1s" }}></div>
                      <div className="w-2.5 h-2.5 bg-text-light/60 rounded-full animate-pulse" style={{ animationDelay: "600ms", animationDuration: "1s" }}></div>
                    </div>
                    <div className="text-xs mt-1.5 text-text-light opacity-70">
                      Client · Typing...
                    </div>
                  </div>
                </motion.div>
              )}
            </motion.div>
          </AnimatePresence>
          
          <div ref={messagesEndRef} />
        </div>
        
        {/* Input area */}
        <div className="p-4 border-t border-border-light bg-background-primary">
          {conversationData.state === 'complete' ? (
            <div className="bg-green-50 text-green-800 p-4 rounded-xl flex items-center gap-3 shadow-sm border border-green-100">
              <div className="rounded-full bg-green-100 p-1.5">
                <CheckCircle className="w-5 h-5 text-green-600" />
              </div>
              <div>
                <p className="font-medium">Conversation completed!</p>
                {conversationData.activeIndex < conversationTopics.length - 1 ? (
                  <p className="text-sm opacity-80">Moving to the next topic shortly...</p>
                ) : (
                  <p className="text-sm opacity-80">You've completed all conversations!</p>
                )}
              </div>
            </div>
          ) : (
            <div className="bg-background-secondary rounded-xl p-3 shadow-sm border border-border-light/50">
              <form 
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmitResponse(currentResponse);
                }}
                className="space-y-2"
              >
                <textarea
                  value={currentResponse}
                  onChange={(e) => setCurrentResponse(e.target.value)}
                  placeholder="Type your response as you would in a real conversation..."
                  rows={3}
                  className="w-full p-3.5 bg-background-primary 
                          focus:outline-none focus:ring-1 focus:ring-accent-primary font-body
                          text-text-primary placeholder:text-text-light/50 resize-none rounded-lg
                          border border-border-light/50 transition-all duration-200"
                />
                <div className="flex justify-between items-center px-1">
                  <div className="text-xs text-text-light flex items-center gap-1.5">
                    <span className={`${currentResponse.length > 480 ? 'text-accent-primary font-medium' : ''}`}>
                      {currentResponse.length}
                    </span>
                    <span className="opacity-60">/ 500 characters</span>
                  </div>
                  <button 
                    type="submit"
                    disabled={!currentResponse.trim() || isClientTyping}
                    className="px-5 py-2.5 rounded-lg font-body font-medium
                            bg-accent-primary text-white hover:bg-hover-primary
                            disabled:opacity-50 disabled:cursor-not-allowed
                            transition-all duration-200 flex items-center gap-2
                            shadow-sm hover:shadow focus:ring-2 focus:ring-accent-primary/30 focus:outline-none"
                  >
                    <Send className="h-4 w-4" />
                    <span>Send</span>
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
      
      {/* Info note with cleaner styling */}
      <div className="bg-background-tertiary p-5 rounded-xl border border-border-light/70 shadow-sm">
        <div className="flex items-start gap-4">
          <div className="rounded-full bg-accent-primary/10 p-2 flex-shrink-0">
            <MessageCircle className="w-5 h-5 text-accent-primary" />
          </div>
          <div>
            <p className="text-sm font-medium text-text-primary mb-1.5 font-body">
              {conversationData.state === 'initial'
                ? "Initial Response"
                : conversationData.state === 'followup1'
                  ? "First Follow-up Response"
                  : "Conversation Complete"}
            </p>
            <p className="text-text-secondary text-sm font-body leading-relaxed">
              {conversationData.state === 'initial'
                ? "Respond exactly as you would to a real client with this challenge. Your AI will learn your tone, style, and coaching approach."
                : conversationData.state === 'followup1'
                  ? "The client has a follow-up question. Your response here helps your AI learn how you maintain a conversation."
                  : "Great job! This conversation is complete. Your AI will use these exchanges to learn your coaching style."}
            </p>
          </div>
        </div>
      </div>
      
      {/* Navigation buttons */}
      <div className="flex justify-between mt-10 mb-4">
        <Button
          onClick={goToPrevStep}
          className="bg-background-tertiary text-text-primary hover:bg-hover-secondary 
                   transition-all duration-200 border border-border-medium px-8 py-3 
                   text-base shadow-sm hover:shadow flex items-center gap-2 rounded-lg"
        >
          <ChevronLeft className="w-4 h-4" />
          Back
        </Button>
        
        <Button
          onClick={handleContinue}
          disabled={isSaving || !hasRequiredResponses()}
          className="bg-accent-primary text-white hover:bg-hover-primary 
                   transition-all duration-300 shadow-md hover:shadow-lg 
                   min-w-[180px] px-8 py-3.5 text-base rounded-lg
                   disabled:opacity-50 disabled:cursor-not-allowed
                   flex items-center justify-center gap-3"
        >
          {!hasRequiredResponses() ? (
            <>
              <div className="relative w-5 h-5">
                <svg viewBox="0 0 36 36" className="w-5 h-5 absolute">
                  <path
                    d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                    fill="none"
                    stroke="rgba(255, 255, 255, 0.3)"
                    strokeWidth="3"
                    strokeDasharray="100, 100"
                  />
                  <path
                    d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="3"
                    strokeDasharray={`${getProgress()}, 100`}
                  />
                </svg>
                <span className="absolute inset-0 flex items-center justify-center text-xs font-semibold">{getProgress()}%</span>
              </div>
              Complete All Conversations
            </>
          ) : (
            <>
              {isSaving ? (
                <>
                  <div className="animate-spin h-5 w-5 border-2 border-white/30 border-t-white rounded-full mr-1"></div>
                  Saving...
                </>
              ) : (
                <>
                  Continue
                  <ChevronRight className="w-4 h-4" />
                </>
              )}
            </>
          )}
        </Button>
      </div>
    </div>
  );
};

export default MessagingSetup; 