// src/components/AccountPageTabs/AddWorkoutOverlay.jsx

import React, { useState } from 'react';
import { Input } from '../ui/Input';
import { Textarea } from '../ui/Textarea';
import { Label } from '../ui/Label';
import { Button } from '../ui/Button';
import { X, Tag, Save } from 'lucide-react';

const AddWorkoutOverlay = ({ 
  isOpen, 
  onClose, 
  onSave, 
  availableTags 
}) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [workout, setWorkout] = useState({
    name: '',
    content: '',
    tags: [],
  });

  const toggleTag = (tag) => {
    setSelectedTags(prev => 
      prev.includes(tag) 
        ? prev.filter(t => t !== tag)
        : [...prev, tag]
    );
  };

  const handleSave = () => {
    onSave({
      ...workout,
      tags: selectedTags,
    });
    setWorkout({ name: '', content: '', tags: [] });
    setSelectedTags([]);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <div className="bg-background border border-accent/20 rounded-lg w-full max-w-4xl max-h-[90vh] overflow-hidden flex flex-col">
        <div className="p-6 border-b border-accent/20 flex justify-between items-center">
          <h2 className="text-xl font-semibold text-foreground">Create New Workout</h2>
          <Button 
            variant="ghost" 
            size="icon"
            onClick={onClose}
            className="text-muted-foreground hover:text-foreground"
          >
            <X className="w-4 h-4" />
          </Button>
        </div>

        <div className="flex-1 overflow-y-auto p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Left Column */}
            <div className="space-y-4">
              <div>
                <Label className="text-foreground">Workout Name</Label>
                <Input
                  placeholder="e.g., Upper Body Push Day"
                  value={workout.name}
                  onChange={(e) => setWorkout({ ...workout, name: e.target.value })}
                  className="bg-background text-foreground border-accent/20"
                />
              </div>

              <div>
                <Label className="text-foreground">Tags</Label>
                <div className="flex flex-wrap gap-2 mt-2">
                  {availableTags.map(tag => (
                    <Button
                      key={tag}
                      size="sm"
                      variant={selectedTags.includes(tag) ? "default" : "outline"}
                      onClick={() => toggleTag(tag)}
                      className={`
                        ${selectedTags.includes(tag) 
                          ? 'bg-accent text-accent-foreground hover:bg-accent/90' 
                          : 'text-muted-foreground hover:text-foreground border-accent/20'}
                      `}
                    >
                      <Tag className="w-3 h-3 mr-1" />
                      {tag}
                    </Button>
                  ))}
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div>
              <Label className="text-foreground">Workout Content (Markdown)</Label>
              <Textarea
                placeholder="Paste your workout plan here using markdown format..."
                value={workout.content}
                onChange={(e) => setWorkout({ ...workout, content: e.target.value })}
                className="h-[400px] w-full bg-background text-foreground border-accent/20 font-mono resize-none mt-2"
              />
            </div>
          </div>
        </div>

        <div className="p-6 border-t border-accent/20 flex justify-end gap-2">
          <Button 
            variant="outline"
            onClick={onClose}
            className="text-muted-foreground hover:text-foreground border-accent/20"
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSave}
            className="bg-accent hover:bg-accent/90 text-accent-foreground"
          >
            <Save className="w-4 h-4 mr-2" />
            Save Workout
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddWorkoutOverlay;