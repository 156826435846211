import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/Card';
import { Input } from '../ui/Input';
import { Button } from '../ui/Button';
import { Switch } from '../ui/Switch';
import { Save } from 'lucide-react';

// Fallback color picker if react-color isn't available
const SimpleColorPicker = ({ color, onChange }) => {
  const commonColors = [
    '#C4845F', '#3B4664', '#4A90E2', '#50E3C2', '#B8E986', 
    '#F8E71C', '#F5A623', '#D0021B', '#9013FE', '#000000'
  ];
  
  return (
    <div className="p-3 bg-white rounded-md shadow-md">
      <div className="grid grid-cols-5 gap-2 mb-3">
        {commonColors.map((clr, idx) => (
          <div 
            key={idx}
            className={`w-8 h-8 rounded-full cursor-pointer border-2 ${color === clr ? 'border-black' : 'border-transparent'}`}
            style={{ backgroundColor: clr }}
            onClick={() => onChange(clr)}
          />
        ))}
      </div>
      <div className="flex items-center gap-2">
        <Input 
          type="color"
          value={color}
          onChange={(e) => onChange(e.target.value)}
          className="w-10 h-10"
        />
        <Input
          value={color}
          onChange={(e) => onChange(e.target.value)}
          placeholder="#000000"
          className="flex-1"
        />
      </div>
    </div>
  );
};

// Try to import ChromePicker from react-color, but use fallback if it fails
let ChromePicker;
try {
  ({ ChromePicker } = require('react-color'));
} catch (error) {
  console.warn('react-color not available, using fallback color picker');
  ChromePicker = SimpleColorPicker;
}

const SettingsPanel = ({ formId, onUpdate }) => {
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  
  // Form settings state
  const [settings, setSettings] = useState({
    progressBar: true,
    stepLabels: ['Welcome', 'Basic Info', 'Goals', 'Health History', 'Finish'],
    allowSkip: false,
    requireEmail: true,
    requirePhone: true,
    customBranding: {
      primaryColor: '#C4845F',
      accentColor: '#3B4664',
      backgroundImage: ''
    }
  });
  
  const [redirectToCall, setRedirectToCall] = useState(true);
  const [showDirectPayment, setShowDirectPayment] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(null); // 'primary', 'accent', or null
  
  // Fetch form data
  useEffect(() => {
    const fetchFormData = async () => {
      setLoading(true);
      try {
        const formRef = doc(db, 'onboardingForms', formId);
        const formDoc = await getDoc(formRef);
        
        if (formDoc.exists()) {
          const formData = formDoc.data();
          setForm(formData);
          
          // Set form settings
          if (formData.settings) {
            setSettings(formData.settings);
          }
          
          setRedirectToCall(formData.redirectToCall !== false);
          setShowDirectPayment(formData.showDirectPayment === true);
        } else {
          setError('Form not found');
        }
      } catch (error) {
        console.error('Error fetching form data:', error);
        setError('Failed to load form settings. Please try again.');
      } finally {
        setLoading(false);
      }
    };
    
    if (formId) {
      fetchFormData();
    }
  }, [formId]);
  
  // Update a single setting
  const updateSetting = (key, value) => {
    setSettings({
      ...settings,
      [key]: value
    });
  };
  
  // Update a custom branding setting
  const updateBranding = (key, value) => {
    setSettings({
      ...settings,
      customBranding: {
        ...settings.customBranding,
        [key]: value
      }
    });
  };
  
  // Update step labels
  const updateStepLabel = (index, value) => {
    const newStepLabels = [...settings.stepLabels];
    newStepLabels[index] = value;
    updateSetting('stepLabels', newStepLabels);
  };
  
  // Save settings
  const saveSettings = async () => {
    setSaving(true);
    try {
      const formRef = doc(db, 'onboardingForms', formId);
      
      // Use serverTimestamp for the database update
      await updateDoc(formRef, {
        settings,
        redirectToCall,
        showDirectPayment,
        updatedAt: serverTimestamp()
      });
      
      // Notify parent component of update with a JavaScript Date for updatedAt
      if (onUpdate) {
        onUpdate({
          ...settings,
          updatedAt: new Date() // Add updatedAt with JavaScript Date for local state
        });
      }
      
      setError('');
    } catch (error) {
      console.error('Error saving form settings:', error);
      setError('Failed to save form settings. Please try again.');
    } finally {
      setSaving(false);
    }
  };
  
  if (loading) {
    return (
      <Card>
        <CardContent className="flex justify-center items-center py-12">
          <div className="animate-spin h-8 w-8 border-4 border-accent-primary border-t-transparent rounded-full"></div>
        </CardContent>
      </Card>
    );
  }
  
  if (error && !form) {
    return (
      <Card>
        <CardContent className="py-6">
          <div className="text-red-500 text-center">{error}</div>
        </CardContent>
      </Card>
    );
  }
  
  return (
    <div className="space-y-6">
      {/* Form Behavior */}
      <Card>
        <CardHeader>
          <CardTitle>Form Behavior</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {/* Progress Bar Settings */}
            <div className="flex items-center justify-between py-2">
              <div>
                <label className="block text-sm font-medium text-text-primary">
                  Show Progress Bar
                </label>
                <p className="text-text-light text-xs mt-1">
                  Display a progress bar to show steps completion
                </p>
              </div>
              <Switch
                checked={settings.progressBar}
                onChange={() => updateSetting('progressBar', !settings.progressBar)}
              />
            </div>
            
            {/* Step Labels */}
            {settings.progressBar && (
              <div className="pt-2">
                <label className="block text-sm font-medium text-text-primary mb-2">
                  Step Labels
                </label>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2">
                  {settings.stepLabels.map((label, idx) => (
                    <Input
                      key={idx}
                      value={label}
                      onChange={(e) => updateStepLabel(idx, e.target.value)}
                      placeholder={`Step ${idx + 1}`}
                      className="w-full"
                    />
                  ))}
                </div>
              </div>
            )}
            
            {/* Allow Skip Questions */}
            <div className="flex items-center justify-between py-2">
              <div>
                <label className="block text-sm font-medium text-text-primary">
                  Allow Skipping Required Questions
                </label>
                <p className="text-text-light text-xs mt-1">
                  Let users proceed without answering required questions
                </p>
              </div>
              <Switch
                checked={settings.allowSkip}
                onChange={() => updateSetting('allowSkip', !settings.allowSkip)}
              />
            </div>
            
            {/* Require Email */}
            <div className="flex items-center justify-between py-2">
              <div>
                <label className="block text-sm font-medium text-text-primary">
                  Require Email Address
                </label>
                <p className="text-text-light text-xs mt-1">
                  Ask for user's email address
                </p>
              </div>
              <Switch
                checked={settings.requireEmail}
                onChange={() => updateSetting('requireEmail', !settings.requireEmail)}
              />
            </div>
            
            {/* Require Phone */}
            <div className="flex items-center justify-between py-2">
              <div>
                <label className="block text-sm font-medium text-text-primary">
                  Require Phone Number
                </label>
                <p className="text-text-light text-xs mt-1">
                  Ask for user's phone number
                </p>
              </div>
              <Switch
                checked={settings.requirePhone}
                onChange={() => updateSetting('requirePhone', !settings.requirePhone)}
              />
            </div>
          </div>
        </CardContent>
      </Card>
      
      {/* Completion Actions */}
      <Card>
        <CardHeader>
          <CardTitle>Form Completion Actions</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {/* Redirect to Calendly */}
            <div className="flex items-center justify-between py-2">
              <div>
                <label className="block text-sm font-medium text-text-primary">
                  Redirect to Call Booking
                </label>
                <p className="text-text-light text-xs mt-1">
                  Direct user to book a call after form completion
                </p>
              </div>
              <Switch
                checked={redirectToCall}
                onChange={() => setRedirectToCall(!redirectToCall)}
              />
            </div>
            
            {/* Show Direct Payment Option */}
            <div className="flex items-center justify-between py-2">
              <div>
                <label className="block text-sm font-medium text-text-primary">
                  Show Direct Payment Option
                </label>
                <p className="text-text-light text-xs mt-1">
                  Allow users to subscribe directly without booking a call
                </p>
              </div>
              <Switch
                checked={showDirectPayment}
                onChange={() => setShowDirectPayment(!showDirectPayment)}
              />
            </div>
          </div>
        </CardContent>
      </Card>
      
      {/* Custom Branding */}
      <Card>
        <CardHeader>
          <CardTitle>Custom Branding</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            {/* Primary Color */}
            <div>
              <label className="block text-sm font-medium text-text-primary mb-2">
                Primary Color
              </label>
              <div className="flex items-center">
                <div 
                  className="w-10 h-10 rounded border cursor-pointer mr-3"
                  style={{ backgroundColor: settings.customBranding.primaryColor }}
                  onClick={() => setShowColorPicker(showColorPicker === 'primary' ? null : 'primary')}
                ></div>
                <Input
                  value={settings.customBranding.primaryColor}
                  onChange={(e) => updateBranding('primaryColor', e.target.value)}
                  placeholder="#000000"
                  className="w-32"
                />
                {showColorPicker === 'primary' && (
                  <div className="absolute z-10 mt-2">
                    <div className="fixed inset-0" onClick={() => setShowColorPicker(null)} />
                    <ChromePicker
                      color={settings.customBranding.primaryColor}
                      onChange={(color) => updateBranding('primaryColor', color.hex)}
                    />
                  </div>
                )}
                <p className="ml-4 text-text-light text-sm">
                  Used for buttons, progress bar, and main elements
                </p>
              </div>
            </div>
            
            {/* Accent Color */}
            <div>
              <label className="block text-sm font-medium text-text-primary mb-2">
                Accent Color
              </label>
              <div className="flex items-center">
                <div 
                  className="w-10 h-10 rounded border cursor-pointer mr-3"
                  style={{ backgroundColor: settings.customBranding.accentColor }}
                  onClick={() => setShowColorPicker(showColorPicker === 'accent' ? null : 'accent')}
                ></div>
                <Input
                  value={settings.customBranding.accentColor}
                  onChange={(e) => updateBranding('accentColor', e.target.value)}
                  placeholder="#000000"
                  className="w-32"
                />
                {showColorPicker === 'accent' && (
                  <div className="absolute z-10 mt-2">
                    <div className="fixed inset-0" onClick={() => setShowColorPicker(null)} />
                    <ChromePicker
                      color={settings.customBranding.accentColor}
                      onChange={(color) => updateBranding('accentColor', color.hex)}
                    />
                  </div>
                )}
                <p className="ml-4 text-text-light text-sm">
                  Used for secondary elements and highlights
                </p>
              </div>
            </div>
            
            {/* Background Image URL */}
            <div>
              <label className="block text-sm font-medium text-text-primary mb-2">
                Background Image URL (Optional)
              </label>
              <Input
                value={settings.customBranding.backgroundImage || ''}
                onChange={(e) => updateBranding('backgroundImage', e.target.value)}
                placeholder="https://example.com/image.jpg"
                className="w-full"
              />
              <p className="text-text-light text-xs mt-1">
                Add a background image to your form header (leave empty for solid color)
              </p>
            </div>
          </div>
        </CardContent>
      </Card>
      
      {/* Save Button */}
      <div className="flex justify-end">
        <Button
          variant="primary"
          onClick={saveSettings}
          disabled={saving}
          className="flex items-center gap-2"
        >
          {saving ? <div className="animate-spin h-4 w-4 border-2 border-white border-t-transparent rounded-full"></div> : <Save className="h-4 w-4" />}
          Save Settings
        </Button>
      </div>
      
      {/* Error Message */}
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
          <span className="block sm:inline">{error}</span>
          <span 
            className="absolute top-0 bottom-0 right-0 px-4 py-3 cursor-pointer"
            onClick={() => setError('')}
          >
            <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
            </svg>
          </span>
        </div>
      )}
    </div>
  );
};

export default SettingsPanel; 