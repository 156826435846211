// src/components/AppRoutes.jsx (updated with signup route)
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import NavigationBar from './NavigationBar';
import { FullPageLoader, PageTransitionLoader, usePageLoading } from './LoadingComponents';

import Login from './Login';
import Signup from './Signup';
import CoachDashboard from './CoachDashboard';
import ClientPage from './ClientPage';
import AccountPage from './AccountPage';
import MessagesPage from './Message/MessagesPage';
import CoachProfile from './CoachProfile';
import CoachesManagement from './CoachesManagement';
import AccountSettings from './AccountSettings';
import WorkoutTracking from './WorkoutTracking';
import TemplateManagement from './TemplateManagement';
import TemplateCreation from './TemplateCreation';
import CalendarPage from './CalendarPage';
import NameUsernamePage from './Onboarding/NameUsernamePage';
import MessageManagementDashboard from './Message/MessageManagementDashboard';
import ClientWorkoutEdit from './ClientWorkoutEdit';
//import ReferralManagementPage from './ReferralManagementPage'; 



const AppRoutes = () => {
  const { user, loading, isAdmin } = useAuth();
  const isChangingPage = usePageLoading();

  // Show full-page loader only during initial auth check
  if (loading) {
    return <FullPageLoader />;
  }

  return (
    <>
      {isChangingPage && <PageTransitionLoader />}
      <Routes>
        {/* Public routes */}
        <Route 
          path="/" 
          element={user ? <Navigate to="/dashboard" replace /> : <Login />} 
        />
        <Route 
          path="/signup"
          element={<Signup />} 
        />

        {/* Onboarding Routes (Protected - must be logged in) */}
        <Route
          path="/onboarding/setup"
          element={user ? <NameUsernamePage /> : <Navigate to="/" replace />}
        />

        {/* Protected routes */}
        <Route
          path="/dashboard"
          element={user ? (
            <>
              <NavigationBar isAdmin={isAdmin} />
              <CoachDashboard />
            </>
          ) : (
            <Navigate to="/" replace />
          )}
        />
        

        {/* Other protected routes */}
        <Route
          path="/client/:clientId"
          element={user ? <><NavigationBar isAdmin={isAdmin} /><ClientPage /></> : <Navigate to="/" replace />}
        />
        <Route
          path="/account"
          element={user ? <><NavigationBar isAdmin={isAdmin} /><AccountPage /></> : <Navigate to="/" replace />}
        />
        <Route
          path="/messages"
          element={user ? <><NavigationBar isAdmin={isAdmin} /><MessagesPage /></> : <Navigate to="/" replace />}
        />
        <Route
          path="/messageManagement"
          element={user ? (
            <>
              <NavigationBar isAdmin={isAdmin} />
              <MessageManagementDashboard />
            </>
          ) : (
            <Navigate to="/" replace />
          )}
        />
        <Route
          path="/coach-profile/:coachId"
          element={user ? <><NavigationBar isAdmin={isAdmin} /><CoachProfile /></> : <Navigate to="/" replace />}
        />
        <Route
          path="/account-settings"
          element={user ? <><NavigationBar isAdmin={isAdmin} /><AccountSettings /></> : <Navigate to="/" replace />}
        />
        <Route
          path="/workout/:workoutId"
          element={user ? <><NavigationBar isAdmin={isAdmin} /><WorkoutTracking /></> : <Navigate to="/" replace />}
        />
        <Route
          path="/templates"
          element={user ? <><NavigationBar isAdmin={isAdmin} /><TemplateManagement /></> : <Navigate to="/" replace />}
        />
        <Route
          path="/templates/edit/:templateId"
          element={user ? <><NavigationBar isAdmin={isAdmin} /><TemplateCreation isEdit={true} /></> : <Navigate to="/" replace />}
        />
        <Route
          path="/create-template"
          element={user ? <><NavigationBar isAdmin={isAdmin} /><TemplateCreation /></> : <Navigate to="/" replace />}
        />
        <Route
          path="/schedule"
          element={user ? <><NavigationBar isAdmin={isAdmin} /><CalendarPage /></> : <Navigate to="/" replace />}
        />
        <Route
          path="/workouts/edit/:workoutId"
          element={user ? (
            <>
              <NavigationBar isAdmin={isAdmin} />
              <ClientWorkoutEdit />
            </>
          ) : (
            <Navigate to="/" replace />
          )}
        />
        {/* <Route
          path="/referrals"
          element={user ? <><NavigationBar isAdmin={isAdmin} /><ReferralManagementPage /></> : <Navigate to="/" replace />}
        /> */}

        {/* Admin-only route */}
        <Route
          path="/coaches"
          element={
            user && isAdmin ? (
              <>
                <NavigationBar isAdmin={isAdmin} />
                <CoachesManagement />
              </>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        {/* Catch all route */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
