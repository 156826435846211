// src/components/overlays/ModifyExerciseOverlay.jsx
import React, { useState, useRef, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/Card';
import { Button } from '../ui/Button';
import { Input } from '../ui/Input';
import { Textarea } from '../ui/Textarea';
import { Label } from '../ui/Label';
import { X, Plus, Trash2, Video, Upload } from 'lucide-react';
import { createCustomExercise, modifyExercise, getExerciseById } from '../../services/exerciseService';

const VideoPreview = ({ url, file }) => {
  if (file) {
    return (
      <div className="relative pt-[56.25%] w-full">
        <video
          className="absolute top-0 left-0 w-full h-full rounded-lg"
          controls
          src={URL.createObjectURL(file)}
        />
      </div>
    );
  }

  if (!url) return null;

  if (url.includes('youtube.com')) {
    // Extract video ID from any YouTube URL (regular or shorts)
    const videoId = url.includes('/embed/') 
      ? url.split('/embed/')[1]
      : url.match(/(?:shorts\/|v=)([^/?&]+)/)?.[1];

    if (!videoId) return null;

    return (
      <div className="relative pt-[56.25%] w-full">
        <iframe
          className="absolute top-0 left-0 w-full h-full rounded-lg"
          src={`https://www.youtube.com/embed/${videoId}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    );
  }

  // For Firebase Storage or other video URLs
  return (
    <div className="relative pt-[56.25%] w-full">
      <video
        className="absolute top-0 left-0 w-full h-full rounded-lg"
        controls
        src={url}
      />
    </div>
  );
};

const ModifyExerciseOverlay = ({ exercise, coachId, onSave, onClose }) => {
  console.log('[DEBUG] ModifyExerciseOverlay props:', { exercise, coachId });
  const [isLoading, setIsLoading] = useState(false);
  const [existingCustomExercise, setExistingCustomExercise] = useState(null);
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    videoUrl: '',
    videoFile: null,
    instructions: [],
    formCues: [],
    equipment: [],
    muscleGroups: [],
    type: 'weight',
    defaultProgressionRule: {
      type: 'percentage',
      value: 5
    }
  });

  useEffect(() => {
    const checkExistingCustomExercise = async () => {
      try {
        setIsLoading(true);
        console.log('[DEBUG] Checking for exercise with id:', exercise.id, 'coachId:', coachId);
        // Get exercise data, this will return custom exercise if it exists
        const exerciseData = await getExerciseById(exercise.id, coachId);
        
        if (!exerciseData) {
          console.error('[DEBUG] No exercise data found for id:', exercise.id);
          return;
        }

        console.log('[DEBUG] Found exercise data:', exerciseData);
        
        if (exerciseData.isCustom) {
          console.log('[DEBUG] Using custom exercise data');
          setExistingCustomExercise(exerciseData);
        } else {
          console.log('[DEBUG] Using global exercise data');
        }

        // Always use the fetched exercise data, whether it's custom or global
        setFormData({
          ...exerciseData,
          videoFile: null
        });
      } catch (error) {
        console.error('Error checking for existing custom exercise:', error);
      } finally {
        setIsLoading(false);
      }
    };

    checkExistingCustomExercise();
  }, [exercise, coachId]);

  const handleArrayUpdate = (field, index, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: prev[field].map((item, i) => i === index ? value : item)
    }));
  };

  const addArrayItem = (field) => {
    setFormData(prev => ({
      ...prev,
      [field]: [...prev[field], '']
    }));
  };

  const removeArrayItem = (field, index) => {
    setFormData(prev => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index)
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData(prev => ({
        ...prev,
        videoFile: file,
        videoUrl: '' // Clear URL when file is selected
      }));
    }
  };

  const handleVideoPlay = (url) => {
    if (url.includes('firebasestorage.googleapis.com')) {
      window.open(url, '_blank');
    } else if (url.includes('youtube.com') || url.includes('youtu.be')) {
      window.open(url, '_blank');
    }
  };

  const saveExercise = async () => {
    setIsLoading(true);
    try {
      const exerciseData = {
        ...formData,
        originalExerciseId: exercise.id, // Always use the original exercise ID
      };

      let savedExercise;
      if (existingCustomExercise) {
        // Update existing custom exercise using the same ID
        savedExercise = await modifyExercise(coachId, exercise.id, exerciseData);
      } else {
        // Create new custom exercise using the original exercise ID
        savedExercise = await createCustomExercise(coachId, exerciseData);
      }

      onSave(savedExercise);
      onClose();
    } catch (error) {
      console.error('Error saving custom exercise:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <Card className="w-full max-w-lg max-h-[90vh] overflow-y-auto">
        <CardHeader className="flex flex-row items-center justify-between sticky top-0 bg-background z-10">
          <CardTitle>
            {existingCustomExercise ? 'Update Custom Exercise' : 'Create Custom Exercise'}
          </CardTitle>
          <Button variant="ghost" size="icon" onClick={onClose}>
            <X className="h-4 w-4" />
          </Button>
        </CardHeader>

        <CardContent className="space-y-6">
          <div className="space-y-4">
            <div>
              <Label>Name</Label>
              <Input
                value={formData.name}
                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                placeholder="Exercise name"
              />
            </div>

            <div>
              <Label>Description</Label>
              <Textarea
                value={formData.description}
                onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                placeholder="Exercise description"
              />
            </div>

            <div className="space-y-2">
              <Label>Video</Label>
              <div className="flex items-center space-x-2">
                <Input
                  value={formData.videoUrl}
                  onChange={(e) => setFormData(prev => ({ 
                    ...prev, 
                    videoUrl: e.target.value,
                    videoFile: null // Clear file when URL is entered
                  }))}
                  placeholder="Video URL"
                  className="flex-1"
                  disabled={!!formData.videoFile}
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept="video/*"
                  className="hidden"
                />
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => fileInputRef.current?.click()}
                >
                  <Upload className="h-4 w-4" />
                </Button>
              </div>
              {formData.videoFile && (
                <p className="text-sm text-muted-foreground mt-1">
                  Selected file: {formData.videoFile.name}
                </p>
              )}
              <div className="mt-4">
                <VideoPreview url={formData.videoUrl} file={formData.videoFile} />
              </div>
            </div>

            {/* Instructions */}
            <div>
              <Label className="flex justify-between items-center">
                Instructions
                <Button
                  type="button"
                  variant="ghost"
                  size="sm"
                  onClick={() => addArrayItem('instructions')}
                >
                  <Plus className="h-4 w-4" />
                </Button>
              </Label>
              {formData.instructions.map((instruction, index) => (
                <div key={index} className="flex items-center space-x-2 mt-2">
                  <Input
                    value={instruction}
                    onChange={(e) => handleArrayUpdate('instructions', index, e.target.value)}
                    placeholder={`Step ${index + 1}`}
                  />
                  <Button
                    type="button"
                    variant="ghost"
                    size="sm"
                    onClick={() => removeArrayItem('instructions', index)}
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
              ))}
            </div>

            {/* Form Cues */}
            <div>
              <Label className="flex justify-between items-center">
                Form Cues
                <Button
                  type="button"
                  variant="ghost"
                  size="sm"
                  onClick={() => addArrayItem('formCues')}
                >
                  <Plus className="h-4 w-4" />
                </Button>
              </Label>
              {formData.formCues.map((cue, index) => (
                <div key={index} className="flex items-center space-x-2 mt-2">
                  <Input
                    value={cue}
                    onChange={(e) => handleArrayUpdate('formCues', index, e.target.value)}
                    placeholder="Form cue"
                  />
                  <Button
                    type="button"
                    variant="ghost"
                    size="sm"
                    onClick={() => removeArrayItem('formCues', index)}
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </CardContent>

        <div className="sticky bottom-0 p-4 bg-background border-t">
          <Button
            onClick={saveExercise}
            className="w-full bg-accent-primary hover:bg-accent-primary/90"
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-muted-foreground border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status" />
            ) : existingCustomExercise ? 'Update Custom Exercise' : 'Create Custom Exercise'}
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default ModifyExerciseOverlay;
