// src/utils/defaultQuestions.js
// Configuration for default questions that will be present in all onboarding forms

/**
 * Default questions that will be included in all onboarding forms
 * These questions are required for the AI algorithm to function properly
 * 
 * Properties:
 * - isDefault: true for all default questions (used to identify them in the UI)
 * - isEditable: whether coaches can modify certain aspects of the question
 * - isRemovable: whether coaches can remove the question (should be false for required questions)
 * - saveToField: the field in the user data structure this question maps to
 */
export const DEFAULT_QUESTIONS = [
  // GOALS SECTION - Moved to the beginning
  {
    id: 'goals_primary',
    title: 'What is your primary fitness goal?',
    description: 'This will be the main focus of your personalized plan',
    type: 'multipleChoice',
    isRequired: true,
    isDefault: true,
    isRemovable: false,
    isEditable: {
      title: true,
      description: true,
      options: true,
      type: false,
      saveToField: false
    },
    saveToField: 'userKnowledge.goals.primary',
    options: [
      { label: 'Lose weight/fat', value: 'weight_loss' },
      { label: 'Build muscle', value: 'muscle_gain' },
      { label: 'Increase strength', value: 'strength' },
      { label: 'Improve athletic performance', value: 'performance' },
      { label: 'Improve health and longevity', value: 'longevity' }
    ],
    step: 1
  },
  {
    id: 'goals_secondary',
    title: 'Do you have any secondary fitness goals?',
    description: 'Select all that apply',
    type: 'checkbox',
    isRequired: false,
    isDefault: true,
    isRemovable: false,
    isEditable: {
      title: true,
      description: true,
      options: true,
      type: false,
      saveToField: false
    },
    saveToField: 'userKnowledge.goals.secondary',
    options: [
      { label: 'Improve flexibility', value: 'flexibility' },
      { label: 'Improve cardiovascular health', value: 'cardio_health' },
      { label: 'Reduce stress', value: 'stress_reduction' },
      { label: 'Improve posture', value: 'posture' },
      { label: 'Rehabilitate from injury', value: 'injury_rehab' },
      { label: 'Improve sleep quality', value: 'sleep_quality' },
      { label: 'Reduce pain', value: 'pain_reduction' },
      { label: 'Prepare for an event/competition', value: 'event_prep' }
    ],
    step: 2
  },
  
  // BIOMETRICS SECTION
  {
    id: 'bio_height',
    title: 'What is your height?',
    description: 'This helps us customize your fitness plan appropriately',
    type: 'number',
    isRequired: true,
    isDefault: true,
    isRemovable: false,
    isEditable: {
      title: true,
      description: true,
      type: false,
      saveToField: false
    },
    saveToField: 'userKnowledge.biometrics.height',
    validations: {
      min: 120,
      max: 230
    },
    step: 3,
    helperText: 'Enter your height in centimeters'
  },
  {
    id: 'bio_weight',
    title: 'What is your current weight?',
    description: 'This helps us track your progress over time',
    type: 'number',
    isRequired: true,
    isDefault: true,
    isRemovable: false,
    isEditable: {
      title: true,
      description: true,
      type: false,
      saveToField: false
    },
    saveToField: 'userKnowledge.biometrics.weight',
    validations: {
      min: 30,
      max: 250
    },
    step: 4,
    helperText: 'Enter your weight in kilograms'
  },
  {
    id: 'bio_age',
    title: 'How old are you?',
    description: 'Age helps us customize your fitness journey',
    type: 'number',
    isRequired: true,
    isDefault: true,
    isRemovable: false,
    isEditable: {
      title: true,
      description: true,
      type: false,
      saveToField: false
    },
    saveToField: 'userKnowledge.biometrics.age',
    validations: {
      min: 16,
      max: 100
    },
    step: 5
  },
  {
    id: 'bio_sleep',
    title: 'How many hours do you sleep on average each night?',
    description: 'Sleep is a crucial component of health and recovery',
    type: 'number',
    isRequired: true,
    isDefault: true,
    isRemovable: false,
    isEditable: {
      title: true,
      description: true,
      type: false,
      saveToField: false
    },
    saveToField: 'userKnowledge.biometrics.averageSleep',
    validations: {
      min: 3,
      max: 12
    },
    step: 6
  },
  
  // TIME CONSTRAINTS SECTION
  {
    id: 'time_days_available',
    title: 'How many days per week can you commit to structured exercise?',
    description: 'This helps us design a realistic training schedule',
    type: 'number',
    isRequired: true,
    isDefault: true,
    isRemovable: false,
    isEditable: {
      title: true,
      description: true,
      type: false,
      saveToField: false
    },
    saveToField: 'userKnowledge.timeConstraints.daysPerWeek',
    validations: {
      min: 1,
      max: 7
    },
    step: 7
  },
  {
    id: 'time_session_duration',
    title: 'How much time can you dedicate to each workout session?',
    description: 'Helps us design workouts that fit into your schedule',
    type: 'multipleChoice',
    isRequired: true,
    isDefault: true,
    isRemovable: false,
    isEditable: {
      title: true,
      description: true,
      options: true,
      type: false,
      saveToField: false
    },
    saveToField: 'userKnowledge.timeConstraints.sessionDuration',
    options: [
      { label: 'Less than 30 minutes', value: 'under_30min' },
      { label: '30-45 minutes', value: '30_45min' },
      { label: '45-60 minutes', value: '45_60min' },
      { label: '60-90 minutes', value: '60_90min' },
      { label: 'More than 90 minutes', value: 'over_90min' }
    ],
    step: 8
  },
  {
    id: 'time_preference',
    title: 'When do you prefer to exercise?',
    description: 'Timing can affect your consistency and results',
    type: 'multipleChoice',
    isRequired: true,
    isDefault: true,
    isRemovable: false,
    isEditable: {
      title: true,
      description: true,
      options: true,
      type: false,
      saveToField: false
    },
    saveToField: 'userKnowledge.preferences.timeOfDay',
    options: [
      { label: 'Early morning (before 8am)', value: 'early_morning' },
      { label: 'Morning (8am-12pm)', value: 'morning' },
      { label: 'Afternoon (12pm-5pm)', value: 'afternoon' },
      { label: 'Evening (5pm-8pm)', value: 'evening' },
      { label: 'Night (after 8pm)', value: 'night' }
    ],
    step: 9
  },
  
  // PREFERENCES SECTION
  {
    id: 'diet_restrictions',
    title: 'Do you have any dietary restrictions or preferences?',
    description: 'Select all that apply',
    type: 'checkbox',
    isRequired: false,
    isDefault: true,
    isRemovable: false,
    isEditable: {
      title: true,
      description: true,
      options: true,
      type: false,
      saveToField: false
    },
    saveToField: 'userKnowledge.preferences.dietaryRestrictions',
    options: [
      { label: 'Vegetarian', value: 'vegetarian' },
      { label: 'Vegan', value: 'vegan' },
      { label: 'Gluten-free', value: 'gluten_free' },
      { label: 'Dairy-free', value: 'dairy_free' },
      { label: 'Low carb', value: 'low_carb' },
      { label: 'Paleo', value: 'paleo' },
      { label: 'Keto', value: 'keto' },
      { label: 'Carnivore', value: 'carnivore' },
      { label: 'No sugar', value: 'no_sugar' },
      { label: 'Intermittent fasting', value: 'intermittent_fasting' }
    ],
    step: 10
  },
  {
    id: 'food_dislikes',
    title: 'Are there any specific foods you dislike or avoid?',
    description: 'Enter each food and press Enter to add it to your list',
    type: 'tagInput',
    isRequired: false,
    isDefault: true,
    isRemovable: false,
    isEditable: {
      title: true,
      description: true,
      type: false,
      saveToField: false
    },
    saveToField: 'userKnowledge.preferences.foodPreferences',
    placeholder: 'E.g., salmon, mushrooms, bell peppers',
    step: 11
  },
  
  // EXERCISE PREFERENCES
  {
    id: 'exercise_likes',
    title: 'What types of exercises or activities do you enjoy?',
    description: 'Select all that apply',
    type: 'checkbox',
    isRequired: false,
    isDefault: true,
    isRemovable: false,
    isEditable: {
      title: true,
      description: true,
      options: true,
      type: false,
      saveToField: false
    },
    saveToField: 'userKnowledge.preferences.exerciseLikes',
    options: [
      { label: 'Weight training', value: 'weights' },
      { label: 'Running/jogging', value: 'running' },
      { label: 'Cycling', value: 'cycling' },
      { label: 'Swimming', value: 'swimming' },
      { label: 'Yoga', value: 'yoga' },
      { label: 'Pilates', value: 'pilates' },
      { label: 'HIIT workouts', value: 'hiit' },
      { label: 'Team sports', value: 'team_sports' },
      { label: 'Dancing', value: 'dancing' },
      { label: 'Hiking', value: 'hiking' },
      { label: 'Combat sports/Boxing', value: 'combat' }
    ],
    step: 12
  },
  {
    id: 'exercise_dislikes',
    title: 'Are there any exercises or activities you strongly dislike?',
    description: 'Select all that apply',
    type: 'checkbox',
    isRequired: false,
    isDefault: true,
    isRemovable: false,
    isEditable: {
      title: true,
      description: true,
      options: true,
      type: false,
      saveToField: false
    },
    saveToField: 'userKnowledge.preferences.exerciseDislikes',
    options: [
      { label: 'Weight training', value: 'weights' },
      { label: 'Running/jogging', value: 'running' },
      { label: 'Cycling', value: 'cycling' },
      { label: 'Swimming', value: 'swimming' },
      { label: 'Yoga', value: 'yoga' },
      { label: 'Pilates', value: 'pilates' },
      { label: 'HIIT workouts', value: 'hiit' }
    ],
    step: 13
  },
  
  // EQUIPMENT & FACILITIES
  {
    id: 'equipment_access',
    title: 'What equipment or facilities do you have access to?',
    description: 'Select all that apply',
    type: 'checkbox',
    isRequired: true,
    isDefault: true,
    isRemovable: false,
    isEditable: {
      title: true,
      description: true,
      options: true,
      type: false,
      saveToField: false
    },
    saveToField: 'userKnowledge.equipment.available',
    options: [
      { label: 'Full gym membership', value: 'full_gym' },
      { label: 'Home gym', value: 'home_gym' },
      { label: 'Dumbbells/free weights', value: 'dumbbells' },
      { label: 'Resistance bands', value: 'bands' },
      { label: 'Pull-up bar', value: 'pullup_bar' },
      { label: 'Cardio equipment', value: 'cardio_equipment' },
      { label: 'Kettlebells', value: 'kettlebells' },
      { label: 'Cold plunge', value: 'cold_plunge' },
      { label: 'Sauna', value: 'sauna' },
      { label: 'No equipment (bodyweight only)', value: 'no_equipment' },
      { label: 'Swimming pool', value: 'pool' }
    ],
    step: 14
  },
  
  // INJURIES & LIMITATIONS
  {
    id: 'injuries',
    title: 'Do you have any current injuries or physical limitations?',
    description: 'Select your injury type and provide details about severity',
    type: 'injurySelector',
    isRequired: false,
    isDefault: true,
    isRemovable: false,
    isEditable: {
      title: true,
      description: true,
      type: false,
      saveToField: false
    },
    saveToField: 'userKnowledge.injuries.current',
    injuryOptions: [
      { label: 'Lower back pain', value: 'lower_back_pain' },
      { label: 'Knee injury', value: 'knee_injury' },
      { label: 'Shoulder pain', value: 'shoulder_pain' },
      { label: 'Tennis elbow', value: 'tennis_elbow' },
      { label: 'Ankle sprain', value: 'ankle_sprain' },
      { label: 'Hip pain', value: 'hip_pain' },
      { label: 'Neck pain', value: 'neck_pain' },
      { label: 'Wrist injury', value: 'wrist_injury' },
      { label: 'Hamstring strain', value: 'hamstring_strain' },
      { label: 'Plantar fasciitis', value: 'plantar_fasciitis' },
      { label: 'Rotator cuff injury', value: 'rotator_cuff' },
      { label: 'ACL tear/repair', value: 'acl_injury' },
      { label: 'Herniated disc', value: 'herniated_disc' },
      { label: 'Sciatica', value: 'sciatica' },
      { label: 'Other', value: 'other' }
    ],
    severityOptions: [
      { label: 'Mild - Minimal impact on activity', value: 'mild' },
      { label: 'Moderate - Some activity limitations', value: 'moderate' },
      { label: 'Severe - Significant limitations', value: 'severe' }
    ],
    step: 15
  }
];

/**
 * Get default questions with proper order assignments while preserving their step values
 * @param {number} startingOrder - The order to start assigning to default questions
 * @returns {Array} - Array of default questions with proper order and step
 */
export const getDefaultQuestions = (startingOrder = 0) => {
  return DEFAULT_QUESTIONS.map((question, index) => ({
    ...question,
    order: startingOrder + index
    // Each question already has its own unique step number defined in the array
  }));
}; 