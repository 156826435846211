// src/components/AccountPageTabs/TrainingTab
import React, { useState, useEffect } from 'react';
import { Button } from '../ui/Button';
import { Card, CardHeader, CardContent } from '../ui/Card';
import { Calendar, Send, X, Plus } from 'lucide-react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import ReactMarkdown from 'react-markdown';
import AddWorkoutOverlay from './AddWorkoutOverlay';

const TrainingTab = ({ userId }) => {
  const [workouts, setWorkouts] = useState([]);
  const [showAddWorkout, setShowAddWorkout] = useState(false);
  const [availableTags] = useState([
    'Upper Body', 'Lower Body', 'Push', 'Pull', 'Legs', 'Full Body', 
    'Chest', 'Back', 'Arms', 'Shoulders', 'Core', 'Cardio', 'HIIT', 
    'Strength', 'Hypertrophy', 'Endurance'
  ]);

  useEffect(() => {
    loadWorkouts();
  }, [userId]);

  const loadWorkouts = async () => {
    try {
      const workoutsDoc = await getDoc(doc(db, 'coaches', userId, 'workouts', 'plans'));
      if (workoutsDoc.exists()) {
        setWorkouts(workoutsDoc.data().plans || []);
      }
    } catch (error) {
      console.error('Error loading workouts:', error);
    }
  };

  const handleSaveWorkout = async (newWorkout) => {
    if (!newWorkout.name.trim() || !newWorkout.content.trim()) return;
    
    const timestamp = new Date().toISOString();
    const workoutToSave = {
      ...newWorkout,
      createdAt: timestamp,
      lastModified: timestamp
    };

    try {
      const updatedWorkouts = [...workouts, workoutToSave];
      await setDoc(doc(db, 'coaches', userId, 'workouts', 'plans'), 
        { plans: updatedWorkouts }, 
        { merge: true }
      );
      setWorkouts(updatedWorkouts);
      setShowAddWorkout(false);
    } catch (error) {
      console.error('Error saving workout:', error);
    }
  };

  const removeWorkout = async (index) => {
    try {
      const updatedWorkouts = workouts.filter((_, i) => i !== index);
      await setDoc(doc(db, 'coaches', userId, 'workouts', 'plans'), 
        { plans: updatedWorkouts }, 
        { merge: true }
      );
      setWorkouts(updatedWorkouts);
    } catch (error) {
      console.error('Error removing workout:', error);
    }
  };

  return (
    <div className="flex flex-col h-full overflow-y-auto">
      <div className="flex-1 space-y-6">
        {/* Header - Fixed at top */}
        <div className="sticky top-0 z-10 bg-background flex justify-between items-center px-4 py-2">
          <h2 className="text-2xl font-bold text-foreground">Workout Plans</h2>
          <Button 
            onClick={() => setShowAddWorkout(true)} 
            size="lg"
            className="inline-flex items-center gap-2"
          >
            <Plus className="w-5 h-5" />
            Add New Workout
          </Button>
        </div>

        {/* Workout Cards Grid - Scrollable */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4 pb-8">
          {workouts.map((workout, index) => (
            <Card key={index} className="bg-background border-accent/20 hover:border-accent/40 transition-colors">
              <CardHeader>
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="text-lg font-semibold text-foreground">{workout.name}</h3>
                    <div className="flex flex-wrap gap-1 mt-2">
                      {workout.tags?.map(tag => (
                        <span key={tag} className="px-2 py-1 text-xs rounded-full bg-accent/20 text-accent-foreground">
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="flex space-x-2">
                    <Button 
                      variant="ghost" 
                      size="icon"
                      className="text-muted-foreground hover:text-foreground"
                    >
                      <Calendar className="w-4 h-4" />
                    </Button>
                    <Button 
                      variant="ghost" 
                      size="icon"
                      className="text-muted-foreground hover:text-foreground"
                    >
                      <Send className="w-4 h-4" />
                    </Button>
                    <Button 
                      variant="ghost" 
                      size="icon"
                      onClick={() => removeWorkout(index)}
                      className="text-muted-foreground hover:text-red-400"
                    >
                      <X className="w-4 h-4" />
                    </Button>
                  </div>
                </div>
              </CardHeader>
              <CardContent className="max-h-96 overflow-y-auto">
                <div className="prose prose-invert prose-sm max-w-none">
                  <ReactMarkdown>{workout.content}</ReactMarkdown>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>

      {/* Add Workout Overlay */}
      <AddWorkoutOverlay
        isOpen={showAddWorkout}
        onClose={() => setShowAddWorkout(false)}
        onSave={handleSaveWorkout}
        availableTags={availableTags}
      />
    </div>
  );
};

export default TrainingTab;