import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight, Loader, Mail, User, AlertCircle } from 'lucide-react';
import { 
  doc, 
  getDoc, 
  collection, 
  getDocs, 
  addDoc, 
  serverTimestamp,
  query,
  where
} from 'firebase/firestore';
import { db } from '../../../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Button } from '../../ui/Button';
import FormStep from './FormStep';
import { Progress } from '../../ui/Progress';
import { generateRandomId } from '../../../utils/helpers';
import { Input } from '../../ui/Input';
import ContactForm from './ContactForm';
import { getDefaultQuestions } from '../../../utils/defaultQuestions';
import SimplifiedAnalyticsService from '../../../services/SimplifiedAnalyticsService';
import { Alert } from '../../ui/Alert';

const OnboardingFlow = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeFormId, setActiveFormId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [coachData, setCoachData] = useState(null);
  const [coachId, setCoachId] = useState(null);
  const [formData, setFormData] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [responses, setResponses] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const formContainerRef = useRef(null);
  
  // Simple contact info state
  const [showContactForm, setShowContactForm] = useState(false);
  const [contactInfo, setContactInfo] = useState({
    name: '',
    email: '',
    phone: ''
  });
  const [contactError, setContactError] = useState('');
  const [contactLoading, setContactLoading] = useState(false);
  
  // Add missing error state
  const [error, setError] = useState(null);
  
  // Alert state
  const [alertProps, setAlertProps] = useState({
    isOpen: false,
    title: '',
    message: '',
    type: 'info',
    onConfirm: () => {},
    onCancel: () => {}
  });

  // Check for pre-answered first question from landing page
  useEffect(() => {
    if (location.state) {
      const { firstQuestionId, firstQuestionResponse } = location.state;
      
      if (firstQuestionId && firstQuestionResponse) {
        console.log('Found pre-answered first question:', { 
          firstQuestionId, 
          firstQuestionResponse 
        });
        
        setResponses(prev => ({
          ...prev,
          [firstQuestionId]: firstQuestionResponse
        }));
      }
    }
  }, [location]);

  // Fetch coach and form data
  useEffect(() => {
    const fetchCoachAndForm = async () => {
      try {
        setLoading(true);
        let coach = null;
        let foundCoachId = null;
        
        // First try to query for coach by username field
        const coachesQuery = query(
          collection(db, 'coaches'),
          where('username', '==', username)
        );
        const coachesSnapshot = await getDocs(coachesQuery);
        
        if (!coachesSnapshot.empty) {
          // Found coach by username
          const coachDoc = coachesSnapshot.docs[0];
          coach = coachDoc.data();
          foundCoachId = coachDoc.id;
        } else {
          // Try to get coach directly by ID (if username is actually a UID)
          const coachSnapshot = await getDoc(doc(db, 'coaches', username));
          
          if (coachSnapshot.exists()) {
            coach = coachSnapshot.data();
            foundCoachId = username;
          } else {
            throw new Error('Coach not found');
          }
        }
        
        setCoachData(coach);
        setCoachId(foundCoachId);
        
        // Get active form ID
        const formId = coach.activeOnboardingFormId || 'default';
        setActiveFormId(formId);
        
        // Get form details
        const formSnapshot = await getDoc(doc(db, 'onboardingForms', formId));
        
        if (!formSnapshot.exists()) {
          throw new Error('Form not found');
        }
        
        setFormData(formSnapshot.data());
        
        // Get questions
        const questionsSnapshot = await getDocs(
          collection(db, 'onboardingForms', formId, 'questions')
        );
        
        const fetchedQuestions = [];
        questionsSnapshot.forEach((doc) => {
          fetchedQuestions.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        
        // Sort questions by order
        fetchedQuestions.sort((a, b) => a.order - b.order);
        setQuestions(fetchedQuestions);
        
        // The part below is now moved to a separate useEffect that runs 
        // after questions are loaded and sorted
      } catch (err) {
        console.error('Error fetching coach or form:', err);
        setError(err.message || 'Failed to load the onboarding form. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    if (username) {
      fetchCoachAndForm();
    }
  }, [username]);

  // NEW effect to handle starting from second question AFTER questions are loaded
  useEffect(() => {
    // Only run this when questions are loaded (not empty) and not during loading
    if (!loading && questions.length > 0 && location.state) {
      const { firstQuestionId, firstQuestionResponse } = location.state;
      
      if (firstQuestionId && firstQuestionResponse) {
        // Log detailed information about the questions for debugging
        console.log('Debugging question comparison:', {
          landingPageQuestionId: firstQuestionId,
          formFirstQuestion: questions[0],
          formFirstQuestionId: questions[0]?.id,
          formFirstQuestionStep: questions[0]?.step,
          formFirstQuestionOrder: questions[0]?.order
        });
        
        // More flexible matching - match by ID OR step=1 OR order=0
        const matchesFirstQuestion = 
          questions[0]?.id === firstQuestionId || 
          questions[0]?.step === 1 || 
          questions[0]?.order === 0;
        
        if (matchesFirstQuestion) {
          console.log('Starting on second question (step 2) due to pre-answered first question');
          setCurrentStep(1); // Start from the second question (index 1)
        } else {
          console.warn(
            'First question from landing page does not match the first question in the form',
            { 
              landingPageId: firstQuestionId, 
              formFirstQuestionId: questions[0]?.id,
              formFirstQuestionStep: questions[0]?.step,
              formFirstQuestionOrder: questions[0]?.order  
            }
          );
          
          // Additional logging of all questions to help debug
          console.log('All questions in form:', questions.map(q => ({
            id: q.id,
            title: q.title,
            step: q.step,
            order: q.order
          })));
        }
      }
    }
  }, [loading, questions, location.state]);

  // Use dummy data when real data is not available
  useEffect(() => {
    if (!loading && questions.length === 0) {
      console.log('No questions found in the form, using default questions');
      // Use default questions from defaultQuestions.js
      const defaultQuestions = getDefaultQuestions();
      setQuestions(defaultQuestions);
      
      // If we have a first question response from landing page, check which default question matches
      if (location.state?.firstQuestionId) {
        const { firstQuestionId } = location.state;
        // Try to find the matching question in the default questions
        const matchingQuestionIndex = defaultQuestions.findIndex(q => 
          q.id === firstQuestionId || 
          (q.step === 1) || 
          (q.order === 0)
        );
        
        if (matchingQuestionIndex >= 0) {
          console.log('Found matching default question for the response, starting from the next question');
          setCurrentStep(matchingQuestionIndex + 1); // Start from the next question
        }
      }
    }
  }, [loading, questions.length, location.state]);

  // Track step view when questions are loaded or current step changes
  useEffect(() => {
    if (!loading && questions.length > 0 && coachId) {
      // Get current question
      const currentQuestion = questions[currentStep];
      
      // Track question view (using only one event type to avoid double-counting)
      SimplifiedAnalyticsService.trackEvent('question_viewed', {
        coachId: coachId,
        formId: activeFormId,
        metadata: {
          questionId: currentQuestion?.id,
          questionTitle: currentQuestion?.title,
          stepNumber: currentStep + 1
        }
      });
    }
  }, [loading, questions, currentStep, coachId, activeFormId]);

  // Listen for auto-advance events from question components
  useEffect(() => {
    const handleQuestionAnswered = (event) => {
      const { shouldAdvance } = event.detail;
      
      if (shouldAdvance && currentStep < questions.length - 1) {
        // We're immediately getting the current question
        const currentQuestion = questions[currentStep];
        
        // Add a small delay to make it feel natural and ensure state updates have completed
        setTimeout(() => {
          // Re-check the responses state inside the timeout to ensure we have the latest data
          const response = responses[currentQuestion.id];
          
          // Only auto-advance if we have a valid response or if the question is not required
          if (!currentQuestion.isRequired || 
              (response !== undefined && response !== '' && !(Array.isArray(response) && response.length === 0))) {
            
            setCurrentStep(prevStep => prevStep + 1);
            
            // Scroll to top of form
            if (formContainerRef.current) {
              formContainerRef.current.scrollTop = 0;
            }
          }
        }, 400); // Slightly longer delay to ensure state updates complete
      }
    };

    document.addEventListener('questionAnswered', handleQuestionAnswered);
    
    return () => {
      document.removeEventListener('questionAnswered', handleQuestionAnswered);
    };
  }, [currentStep, questions, responses]);

  const showAlert = (props) => {
    setAlertProps({
      isOpen: true,
      ...props,
      onCancel: () => {
        setAlertProps(prev => ({ ...prev, isOpen: false }));
        if (props.onCancel) props.onCancel();
      }
    });
  };
  
  const handleNext = () => {
    // Validate current question
    const currentQuestion = questions[currentStep];
    const response = responses[currentQuestion.id];
    
    // Track the event before validation so we always log the attempt
    SimplifiedAnalyticsService.trackEvent('question_answered', {
      coachId: coachId,
      formId: activeFormId || 'default',
      metadata: {
        questionId: currentQuestion.id,
        questionTitle: currentQuestion.title,
        stepNumber: currentStep + 1
      }
    });
    
    if (currentQuestion.isRequired && (response === undefined || response === '' || (Array.isArray(response) && response.length === 0))) {
      // Show alert for required questions
      showAlert({
        title: 'Required Question',
        message: 'This question requires an answer before continuing.',
        type: 'warning',
        onConfirm: () => setAlertProps(prev => ({ ...prev, isOpen: false }))
      });
      return;
    }
    
    if (currentStep < questions.length - 1) {
      // Move to next question
      setCurrentStep(currentStep + 1);
      // Scroll to top of form
      if (formContainerRef.current) {
        formContainerRef.current.scrollTop = 0;
      }
    } else {
      // At the end of the questions
      // Show contact form instead of immediately submitting
      setShowContactForm(true);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      // Scroll to top of form
      if (formContainerRef.current) {
        formContainerRef.current.scrollTop = 0;
      }
    }
  };

  const handleChange = (questionId, value) => {
    // Track if this is a new answer or an update to an existing answer
    const isNewAnswer = !responses[questionId];
    
    // Set the response value immediately
    setResponses(prev => ({
      ...prev,
      [questionId]: value
    }));
    
    // Track question answered
    if (isNewAnswer && questionId && coachId) {
      const currentQuestion = questions.find(q => q.id === questionId);
      SimplifiedAnalyticsService.trackEvent('question_answered', {
        coachId: coachId,
        formId: activeFormId || 'default',
        metadata: {
          questionId: questionId,
          answer: value,
          stepNumber: currentStep + 1
        }
      });
    }
  };

  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setContactInfo({
      ...contactInfo,
      [name]: value
    });
  };

  const handleContactSubmit = async (e, password) => {
    e.preventDefault();
    
    // Validate email
    if (!contactInfo.email || !contactInfo.email.includes('@')) {
      setContactError('Please enter a valid email address');
      return;
    }
    
    // Validate password
    if (!password || password.length < 6) {
      setContactError('Password must be at least 6 characters long');
      return;
    }
    
    setContactLoading(true);
    setContactError(''); // Clear any previous errors
    
    try {
      // Parse name into first and last name
      let firstName = contactInfo.name;
      let lastName = '';
      
      if (contactInfo.name.includes(' ')) {
        const nameParts = contactInfo.name.split(' ');
        firstName = nameParts[0];
        lastName = nameParts.slice(1).join(' ');
      }
      
      // First, save the onboarding responses
      const responseData = {
        formId: activeFormId,
        coachId: coachId,
        coachUsername: username,
        responses: responses,
        contactInfo: {
          name: contactInfo.name,
          email: contactInfo.email,
          phone: contactInfo.phone || '',
        },
        userCreated: false, // Will be updated by the cloud function
        startedAt: serverTimestamp(),
        completedAt: serverTimestamp(),
        source: window.location.href,
        device: navigator.userAgent
      };
      
      // Save to Firebase and get response ID
      const responseRef = await addDoc(collection(db, 'onboardingResponses'), responseData);
      const responseId = responseRef.id;
      console.log('Responses saved with ID:', responseId);
      
      // Now create the client account with the cloud function
      const createClientFn = httpsCallable(getFunctions(), 'createClientAccount');
      
      try {
        const result = await createClientFn({
          email: contactInfo.email,
          password: password,
          firstName: firstName,
          lastName: lastName,
          coachId: coachId,
          responseId: responseId
        });
        
        console.log('Client account created:', result.data);
        
        // Track form submission
        SimplifiedAnalyticsService.trackFormComplete({
          coachId: coachId,
          formId: activeFormId,
          responseId: responseId,
          userId: result.data.userId
        });
        
        // Navigate to booking page with response ID and contact info
        navigate(`/${username}/booking?response=${responseId}`, {
          state: {
            responseId: responseId,
            contactInfo: contactInfo
          }
        });
      } catch (functionError) {
        console.error('Cloud function error:', functionError);
        
        // Get the error message from the cloud function
        let errorMessage = 'Failed to create account. Please try again.';
        
        // Handle different error formats
        if (functionError.message) {
          errorMessage = functionError.message;
        } else if (functionError.details) {
          errorMessage = functionError.details;
        } else if (functionError.data && functionError.data.message) {
          errorMessage = functionError.data.message;
        }
        
        setContactError(errorMessage);
        setContactLoading(false);
        
        // Log the error for analytics
        SimplifiedAnalyticsService.trackEvent('error', {
          coachId: coachId,
          formId: activeFormId,
          metadata: {
            errorType: 'account_creation_error',
            errorMessage: errorMessage
          }
        });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitting(false);
      
      SimplifiedAnalyticsService.trackEvent('error', {
        coachId: coachId,
        formId: activeFormId || 'default',
        metadata: {
          errorType: 'form_submission_error',
          errorMessage: error.message
        }
      });
      
      const errorMessage = error.message || 'Failed to save your information. Please try again.';
      setContactError(errorMessage);
      setContactLoading(false);
    }
  };

  // Render the component
  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="flex flex-col items-center justify-center text-center p-6">
          <Loader className="w-10 h-10 text-accent-primary animate-spin mb-4" />
          <h2 className="text-xl font-medium text-text-primary">Loading your onboarding form...</h2>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="w-full max-w-xl text-center p-6">
          <AlertCircle className="w-12 h-12 text-red-500 mx-auto mb-4" />
          <h2 className="text-2xl font-medium text-text-primary mb-2">Something went wrong</h2>
          <p className="text-text-light mb-6">{error}</p>
          <Button onClick={() => window.location.reload()}>Try Again</Button>
        </div>
      </div>
    );
  }

  return (
    <div className="relative min-h-screen flex flex-col bg-background-primary">
      {/* Top Progress Bar */}
      <div className="fixed top-0 left-0 w-full z-10">
        <div className="container mx-auto px-4">
          <div className="py-4">
            <Progress 
              value={Math.min(((currentStep + 1) / (questions.length + 1)) * 100, 100)} 
              className="h-2" 
            />
            
            <div className="flex justify-between items-center mt-2 text-xs text-text-light">
              <span>{currentStep + 1} of {questions.length}</span>
              <span>{formData?.title || 'Onboarding Form'}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col items-center justify-center pt-20 pb-24" ref={formContainerRef}>
        {/* Steps Display */}
        <div className="w-full flex-1 flex items-center justify-center">
          <AnimatePresence mode="wait">
            {showContactForm ? (
              <motion.div
                key="contact-form"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
                className="w-full"
              >
                <ContactForm 
                  contactInfo={contactInfo}
                  onChange={handleContactChange}
                  onSubmit={handleContactSubmit}
                  loading={contactLoading}
                  error={contactError}
                />
              </motion.div>
            ) : (
              <motion.div
                key={`question-${currentStep}`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
                className="w-full px-4 md:px-0"
              >
                {questions[currentStep] && (
                  <FormStep
                    question={questions[currentStep]}
                    value={responses[questions[currentStep]?.id] || ''}
                    onChange={(value) => handleChange(questions[currentStep]?.id, value)}
                  />
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>

      {/* Navigation Buttons */}
      <div className="fixed bottom-0 left-0 w-full bg-background-secondary border-t border-border-light z-10">
        <div className="container mx-auto px-4">
          <div className="py-4 flex justify-between items-center">
            {/* Back Button */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              {currentStep > 0 ? (
                <Button 
                  onClick={handlePrevious}
                  className="flex items-center gap-2 bg-transparent hover:bg-background-tertiary text-text-light hover:text-text-primary border border-border-light"
                >
                  <ChevronLeft size={16} />
                  <span>Back</span>
                </Button>
              ) : (
                <div className="w-20"></div> /* Empty placeholder for spacing */
              )}
            </motion.div>

            {/* Continue/Submit Button */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              {currentStep < questions.length - 1 ? (
                <Button 
                  onClick={handleNext}
                  className="flex items-center gap-2"
                  disabled={
                    questions[currentStep]?.isRequired && 
                    !responses[questions[currentStep]?.id]
                  }
                >
                  <span>Continue</span>
                  <ChevronRight size={16} />
                </Button>
              ) : (
                <Button 
                  onClick={showContactForm ? handleContactSubmit : handleNext}
                  className="flex items-center gap-2"
                  disabled={
                    (questions[currentStep]?.isRequired && 
                    !responses[questions[currentStep]?.id]) ||
                    submitting
                  }
                >
                  <span>{showContactForm ? 'Submit' : 'Finish'}</span>
                  {submitting ? (
                    <Loader size={16} className="animate-spin" />
                  ) : (
                    <ChevronRight size={16} />
                  )}
                </Button>
              )}
            </motion.div>
          </div>
        </div>
      </div>

      {/* Alert */}
      <AnimatePresence>
        {alertProps.isOpen && (
          <Alert {...alertProps} />
        )}
      </AnimatePresence>
    </div>
  );
};

export default OnboardingFlow; 