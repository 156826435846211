// src/services/workoutMessageService.js

import { db } from '../firebase';
import { collection, addDoc, doc, updateDoc, setDoc, getDoc, Timestamp } from 'firebase/firestore';

export const sendWorkoutMessage = async (clientId, workoutData) => {
  try {
    const exercisePromises = workoutData.exercises.map(async (exercise) => {
      if (!exercise.exerciseId) return { ...exercise, name: "Unnamed Exercise" };
      const exerciseDoc = await getDoc(doc(db, 'exercises', exercise.exerciseId));
      return {
        ...exercise,
        name: exerciseDoc.data()?.name || 'Unnamed Exercise'
      };
    });

    const exercisesWithNames = await Promise.all(exercisePromises);


    const workoutDoc = {
      name: workoutData.name || 'Untitled Workout',
      description: workoutData.description || '',
      headerImageUrl: workoutData.headerImageUrl || '', 
      scheduledTime: workoutData.scheduledTime || new Date(),
      status: 'assigned', // Status lifecycle: assigned -> in_progress -> completed
      dateAssigned: Timestamp.now(),
      dateStarted: null,
      dateCompleted: null,
      coachId: workoutData.coachId || '',
      sections: workoutData.sections,
      exercises: exercisesWithNames.map((exercise, index) => ({
        exerciseId: exercise.exerciseId,
        name: exercise.name,
        // Track both target and actual values for each set
        sets: exercise.sets?.map((set, setIndex) => ({
          setNumber: setIndex + 1,
          targetReps: set.reps || exercise.targetReps?.toString() || "10",
          targetWeight: set.weight || exercise.targetWeight,
          actualReps: null,
          actualWeight: null,
          completed: false,
          rpe: null // Rate of Perceived Exertion
        })) || Array(exercise.sets || 3).fill().map((_, i) => ({
          setNumber: i + 1,
          targetReps: exercise.targetReps?.toString() || "10",
          targetWeight: exercise.targetWeight,
          actualReps: null,
          actualWeight: null,
          completed: false,
          rpe: null
        })),
        rest: parseInt(exercise.rest) || 60,
        notes: exercise.notes || "",
        order: index + 1,
        sectionId: exercise.sectionId,
        sectionType: exercise.sectionType,
        sectionName: exercise.sectionName,
        completed: false
      }))
    };


    // Create workout document
    const workoutRef = await addDoc(collection(db, 'users', clientId, 'workouts'), workoutDoc);

    // Create message document
    const messageContent = {
      type: 'workout',
      workoutId: workoutRef.id,
      preview: {
        name: workoutDoc.name,
        description: workoutDoc.description,
        exerciseCount: workoutDoc.exercises.length,
        duration: estimateWorkoutDuration(workoutDoc.exercises),
      }
    };

    await addDoc(collection(db, 'users', clientId, 'messages'), {
      content: JSON.stringify(messageContent),
      sender_name: 'coach',
      date_sent: Timestamp.now(),
      is_system_message: false,
      type: 'workout',
      source: 'web'
    });

    return workoutRef.id;
  } catch (error) {
    console.error('Error sending workout:', error);
    throw error;
  }
};

// Helper function to estimate workout duration based on exercises
function estimateWorkoutDuration(exercises) {
  const totalSets = exercises.reduce((sum, ex) => sum + (ex.sets?.length || 0), 0);
  const avgRestTime = exercises.reduce((sum, ex) => sum + (parseInt(ex.rest) || 60), 0) / exercises.length;
  // Assume 45 seconds per set plus rest time
  return Math.round((totalSets * 45 + totalSets * avgRestTime) / 60);
}



export const updateWorkoutProgress = async (clientId, workoutId, exerciseData) => {
  try {
    const workoutRef = doc(db, 'users', clientId, 'workouts', workoutId);
    const today = new Date().toISOString().split('T')[0];
    
    // Update workout status
    await updateDoc(workoutRef, {
      status: exerciseData.every(ex => ex.completed) ? 'completed' : 'in_progress',
      dateStarted: exerciseData.some(ex => ex.sets.some(set => set.completed)) ? Timestamp.now() : null,
      dateCompleted: exerciseData.every(ex => ex.completed) ? Timestamp.now() : null,
      exercises: exerciseData.map(ex => ({
        exerciseId: ex.exerciseId,
        completed: ex.completed,
        sets: ex.sets.map(set => ({
          setNumber: set.setNumber,
          targetReps: set.targetReps,
          targetWeight: set.targetWeight,
          actualReps: set.actualReps,
          actualWeight: set.actualWeight,
          completed: set.completed,
          rpe: set.rpe
        }))
      }))
    });

    // Update exercise history
    for (const exercise of exerciseData) {
      if (!exercise.sets?.some(set => set.completed)) continue;

      const exerciseHistoryRef = doc(
        db, 
        'users', 
        clientId, 
        'exerciseHistory', 
        exercise.exerciseId, 
        'dates', 
        today
      );

      await setDoc(exerciseHistoryRef, {
        workoutId: workoutId,
        sets: exercise.sets
          .filter(set => set.completed)
          .map(set => ({
            setNumber: set.setNumber,
            weight: set.actualWeight || 0,
            reps: set.actualReps || 0,
            rpe: set.rpe || null,
            timestamp: Timestamp.now()
          })),
        notes: exercise.notes || '',
        completed: exercise.completed
      });
    }
  } catch (error) {
    console.error('Error updating workout progress:', error);
    throw error;
  }
};
