// src/components/overlays/CreateExerciseOverlay.jsx
import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/Card';
import { Button } from '../ui/Button';
import { Input } from '../ui/Input';
import { Badge } from '../ui/Badge';
import { 
  X, 
  Plus, 
  Trash, 
  Tag, 
  Dumbbell, 
  Video,
  ChevronRight,
  AlertCircle,
  ListOrdered,
  ScanLine,
  CheckCircle
} from 'lucide-react';

const CreateExerciseOverlay = ({ onSave, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    mediaUrl: '',
    instructions: [''],
    tags: [],
    equipmentNeeded: []
  });

  const [newTag, setNewTag] = useState('');
  const [newEquipment, setNewEquipment] = useState('');
  const [successMessage, setSuccessMessage] = useState('');


  const handleArrayUpdate = (field, index, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: prev[field].map((item, i) => i === index ? value : item)
    }));
  };

  const addArrayItem = (field) => {
    setFormData(prev => ({
      ...prev,
      [field]: [...prev[field], '']
    }));
  };

  const removeArrayItem = (field, index) => {
    setFormData(prev => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index)
    }));
  };

  const addTag = () => {
    if (newTag.trim()) {
      setFormData(prev => ({
        ...prev,
        tags: [...new Set([...prev.tags, newTag.trim().toLowerCase()])]
      }));
      setNewTag('');
    }
  };

  const removeTag = (tagToRemove) => {
    setFormData(prev => ({
      ...prev,
      tags: prev.tags.filter(tag => tag !== tagToRemove)
    }));
  };

  const addEquipment = () => {
    if (newEquipment.trim()) {
      setFormData(prev => ({
        ...prev,
        equipmentNeeded: [...new Set([...prev.equipmentNeeded, newEquipment.trim()])]
      }));
      setNewEquipment('');
    }
  };

  const removeEquipment = (equipmentToRemove) => {
    setFormData(prev => ({
      ...prev,
      equipmentNeeded: prev.equipmentNeeded.filter(eq => eq !== equipmentToRemove)
    }));
  };

  const handleSave = async () => {
    try {
      await onSave(formData);
      setSuccessMessage('Exercise successfully created!');
      // Close after a short delay or immediately
      setTimeout(() => {
        onClose();
      }, 1500);
    } catch (error) {
      console.error('Error saving exercise:', error);
      alert('Failed to create exercise. Please try again.');
    }
  };


  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <Card className="w-full max-w-lg max-h-[90vh] overflow-y-auto">
        <CardHeader className="flex flex-row items-center justify-between sticky top-0 bg-background-secondary z-10">
          <CardTitle>Create New Exercise</CardTitle>
          <Button variant="ghost" size="icon" onClick={onClose}>
            <X className="h-4 w-4" />
          </Button>
        </CardHeader>

        <CardContent>
          <form onSubmit={(e) => e.preventDefault()} className="space-y-6">
            <div className="space-y-4">
              <label className="text-base font-semibold text-text-primary flex items-center gap-2">
                <ListOrdered className="h-5 w-5 text-accent-primary" />
                Exercise Name
              </label>
              <Input
                value={formData.name}
                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                className="h-12 text-lg rounded-lg border-2 focus:border-accent-primary"
                placeholder="e.g., Bulgarian Split Squat"
              />
            </div>

            <div className="space-y-4">
              <label className="text-base font-semibold text-text-primary flex items-center gap-2">
                <Video className="h-5 w-5 text-accent-primary" />
                Video URL
              </label>
              <Input
                value={formData.mediaUrl}
                onChange={(e) => setFormData(prev => ({ ...prev, mediaUrl: e.target.value }))}
                className="h-12 text-lg rounded-lg border-2 focus:border-accent-primary"
                placeholder="https://"
              />
            </div>

            <div className="space-y-4">
              <label className="text-base font-semibold text-text-primary flex items-center gap-2">
                <Dumbbell className="h-5 w-5 text-accent-primary" />
                Equipment Needed
              </label>
              <div className="flex flex-wrap gap-2 min-h-12 p-2 bg-accent-primary/10 rounded-lg">
                {formData.equipmentNeeded.map(equipment => (
                  <Badge
                    key={equipment}
                    className="bg-accent-primary/20 text-accent-primary hover:bg-accent-primary/30 transition-colors"
                    onClick={() => removeEquipment(equipment)}
                  >
                    {equipment}
                    <X className="h-3 w-3 ml-2" />
                  </Badge>
                ))}
              </div>
              <div className="flex gap-2">
                <Input
                  value={newEquipment}
                  onChange={(e) => setNewEquipment(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && (e.preventDefault(), addEquipment())}
                  placeholder="Add equipment"
                  className="h-12 text-lg rounded-lg border-2 focus:border-accent-primary"
                />
                <Button 
                  type="button" 
                  onClick={addEquipment}
                  className="bg-accent-primary/10 hover:bg-accent-primary/20 text-accent-primary h-12 border-2 border-dashed border-accent-primary/30"
                >
                  <Plus className="h-5 w-5" />
                </Button>
              </div>
            </div>

            <div className="space-y-4">
              <label className="text-base font-semibold text-text-primary flex items-center gap-2">
                <Tag className="h-5 w-5 text-accent-primary" />
                Tags
              </label>
              <div className="flex flex-wrap gap-2 min-h-12 p-2 bg-accent-primary/10 rounded-lg">
                {formData.tags.map(tag => (
                  <Badge
                    key={tag}
                    className="bg-accent-primary/20 text-accent-primary hover:bg-accent-primary/30 transition-colors"
                    onClick={() => removeTag(tag)}
                  >
                    #{tag}
                    <X className="h-3 w-3 ml-2" />
                  </Badge>
                ))}
              </div>
              <div className="flex gap-2">
                <Input
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && (e.preventDefault(), addTag())}
                  placeholder="Add tag"
                  className="h-12 text-lg rounded-lg border-2 focus:border-accent-primary"
                />
                <Button 
                  type="button" 
                  onClick={addTag}
                  className="bg-accent-primary/10 hover:bg-accent-primary/20 text-accent-primary h-12 border-2 border-dashed border-accent-primary/30"
                >
                  <Plus className="h-5 w-5" />
                </Button>
              </div>
            </div>

            <div className="space-y-4">
              <label className="text-base font-semibold text-text-primary flex items-center gap-2">
                <ScanLine className="h-5 w-5 text-accent-primary" />
                Instructions
              </label>
              <div className="space-y-4 relative">
                {formData.instructions.map((instruction, index) => (
                  <div key={index} className="group flex gap-3 items-start">
                    <div className="flex-shrink-0 w-8 h-8 rounded-full bg-accent-primary/10 flex items-center justify-center text-accent-primary font-medium">
                      {index + 1}
                    </div>
                    <Input
                      value={instruction}
                      onChange={(e) => handleArrayUpdate('instructions', index, e.target.value)}
                      className="flex-1 h-12 rounded-lg border-2 group-hover:border-accent-primary/30"
                    />
                    <Button
                      type="button"
                      variant="ghost"
                      onClick={() => removeArrayItem('instructions', index)}
                      className="opacity-0 group-hover:opacity-100 text-red-400 hover:text-red-300 hover:bg-red-500/10"
                    >
                      <Trash className="h-4 w-4" />
                    </Button>
                  </div>
                ))}
              </div>
              <Button
                type="button"
                onClick={() => addArrayItem('instructions')}
                className="w-full h-12 bg-accent-primary/10 hover:bg-accent-primary/20 text-accent-primary border-2 border-dashed border-accent-primary/30"
              >
                <Plus className="h-5 w-5 mr-2" />
                Add Instruction
              </Button>
            </div>
          </form>
        </CardContent>

        <div className="sticky bottom-0 p-4 bg-background-secondary border-t border-border-light/20">
          <Button
            onClick={handleSave}
            className="w-full bg-accent-primary hover:bg-accent-primary/90 h-12"
          >
            Create Exercise
          </Button>
        </div>
      </Card>
    </div>
  );
};


export default CreateExerciseOverlay;
