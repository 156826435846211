// src/services/exerciseService.js
import { db, storage } from '../firebase';
import { 
  doc, 
  collection, 
  addDoc, 
  getDoc, 
  getDocs, 
  query, 
  setDoc,
  runTransaction,
  serverTimestamp,
  writeBatch,
  arrayUnion,
  arrayRemove
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

// Video URL validation and normalization
const normalizeVideoUrl = async (url, file = null, coachId = null, exerciseId = null) => {
  if (file) {
    // Create a clean filename without special characters
    const cleanFileName = file.name.replace(/[^a-zA-Z0-9.]/g, '_');
    const timestamp = Date.now();
    
    // Structure: coaches/{coachId}/exercise-videos/{exerciseId}/{timestamp}_{filename}
    const storagePath = exerciseId
      ? `coaches/${coachId}/exercise-videos/${exerciseId}/${timestamp}_${cleanFileName}`
      : `coaches/${coachId}/exercise-videos/temp/${timestamp}_${cleanFileName}`;
    
    const storageRef = ref(storage, storagePath);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  }

  if (!url) return '';
  
  // YouTube URL handling (including shorts)
  if (url.includes('youtube.com') || url.includes('youtu.be')) {
    // Handle youtube.com/shorts URLs
    if (url.includes('/shorts/')) {
      const shortId = url.match(/\/shorts\/([^/?]+)/);
      if (shortId) {
        // Convert shorts URL to regular YouTube embed URL
        return `https://youtube.com/embed/${shortId[1]}`;
      }
    }
    
    // Handle regular YouTube URLs
    const videoId = url.match(/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/);
    return videoId ? `https://youtube.com/embed/${videoId[1]}` : url;
  }
  
  // Firebase Storage URL (from camera roll or direct upload)
  if (url.includes('firebasestorage.googleapis.com')) {
    return url;
  }
  
  return url;
};

export const createCustomExercise = async (coachId, exerciseData) => {
  const customExerciseRef = doc(db, `coaches/${coachId}/customExercises`, exerciseData.originalExerciseId);
  
  const exercise = {
    id: exerciseData.originalExerciseId,
    name: exerciseData.name || '',
    videoUrl: '', // Will be updated after upload
    description: exerciseData.description || '',
    instructions: exerciseData.instructions.filter(i => i.trim() !== '') || [],
    formCues: exerciseData.formCues.filter(c => c.trim() !== '') || [],
    equipment: exerciseData.equipment.filter(e => e.trim() !== '') || [],
    muscleGroups: exerciseData.muscleGroups.filter(m => m.trim() !== '') || [],
    type: exerciseData.type || 'weight',
    defaultProgressionRule: exerciseData.defaultProgressionRule || {
      type: 'percentage',
      value: 5
    },
    isCustom: true,
    originalExerciseId: exerciseData.originalExerciseId,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp()
  };

  // Handle video upload with the exercise ID
  const videoUrl = await normalizeVideoUrl(
    exerciseData.videoUrl, 
    exerciseData.videoFile,
    coachId,
    exerciseData.originalExerciseId
  );

  // Set the exercise with the video URL
  await setDoc(customExerciseRef, {
    ...exercise,
    videoUrl
  });

  return { 
    ...exercise,
    videoUrl 
  };
};

export const createExerciseAlternatives = async (coachId, exerciseId, alternatives) => {
  const alternativesRef = doc(db, `coaches/${coachId}/exerciseAlternatives`, exerciseId);
  
  const data = {
    exerciseId,
    alternatives: alternatives.map(alt => ({
      exerciseId: alt.exerciseId,
      type: alt.type || 'easier',
      notes: alt.notes || ''
    })),
    updatedAt: serverTimestamp()
  };

  await setDoc(alternativesRef, data);
  return data;
};

export const getExerciseAlternatives = async (coachId, exerciseId) => {
  const alternativesRef = doc(db, `coaches/${coachId}/exerciseAlternatives`, exerciseId);
  const snap = await getDoc(alternativesRef);
  return snap.exists() ? snap.data() : { exerciseId, alternatives: [] };
};

export const getAllExercises = async (coachId) => {
  // Get global exercises
  const exercisesRef = collection(db, 'exercises');
  const exercisesSnap = await getDocs(exercisesRef);
  const globalExercises = exercisesSnap.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
    isGlobal: true
  }));

  // Get coach's custom exercises
  const customExercisesRef = collection(db, `coaches/${coachId}/customExercises`);
  const customExercisesSnap = await getDocs(customExercisesRef);
  const customExercises = customExercisesSnap.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));

  // Get exercise alternatives
  const alternativesRef = collection(db, `coaches/${coachId}/exerciseAlternatives`);
  const alternativesSnap = await getDocs(alternativesRef);
  const alternatives = alternativesSnap.docs.reduce((acc, doc) => {
    acc[doc.id] = doc.data().alternatives || [];
    return acc;
  }, {});

  // Combine and enrich exercises with alternatives
  const allExercises = [...globalExercises, ...customExercises].map(exercise => ({
    ...exercise,
    alternatives: alternatives[exercise.id] || []
  }));

  return allExercises.sort((a, b) => a.name.localeCompare(b.name));
};

export const getExerciseById = async (exerciseId, coachId) => {
  if (!exerciseId) return null;

  console.log(`[DEBUG] getExerciseById called with exerciseId: ${exerciseId}, coachId: ${coachId}`);

  // Try custom exercise first using the same ID
  if (coachId) {
    console.log(`[DEBUG] Checking for custom exercise at path: coaches/${coachId}/customExercises/${exerciseId}`);
    const customExerciseRef = doc(db, `coaches/${coachId}/customExercises`, exerciseId);
    const customExerciseSnap = await getDoc(customExerciseRef);
    
    if (customExerciseSnap.exists()) {
      console.log(`[DEBUG] Found custom exercise for ${exerciseId}`);
      const data = customExerciseSnap.data();
      console.log(`[DEBUG] Custom exercise data:`, data);
      return { 
        id: exerciseId, 
        ...data,
        isCustom: true
      };
    } else {
      console.log(`[DEBUG] No custom exercise found for ${exerciseId}`);
    }
  }

  // Only get global exercise if no custom exercise exists
  console.log(`[DEBUG] Checking for global exercise at path: exercises/${exerciseId}`);
  const exerciseRef = doc(db, 'exercises', exerciseId);
  const exerciseSnap = await getDoc(exerciseRef);
  
  if (!exerciseSnap.exists()) {
    console.log(`[DEBUG] No global exercise found for ${exerciseId}`);
    return null;
  }
  
  console.log(`[DEBUG] Found global exercise for ${exerciseId}`);
  const data = exerciseSnap.data();
  console.log(`[DEBUG] Global exercise data:`, data);
  
  return {
    id: exerciseId,
    ...data,
    isGlobal: true
  };
};

// Progress tracking with atomic updates
export const updateExerciseProgress = async (userId, exerciseId, newValue) => {
  const progressRef = doc(db, `users/${userId}/exerciseProgress`, exerciseId);
  
  try {
    await runTransaction(db, async (transaction) => {
      const progressDoc = await transaction.get(progressRef);
      
      if (!progressDoc.exists()) {
        transaction.set(progressRef, {
          exerciseId,
          lastCompletedValue: newValue,
          recommendedNextValue: newValue,
          lastUpdated: serverTimestamp()
        });
      } else {
        const currentData = progressDoc.data();
        transaction.update(progressRef, {
          lastCompletedValue: newValue,
          recommendedNextValue: Math.max(newValue, currentData.recommendedNextValue),
          lastUpdated: serverTimestamp()
        });
      }
    });
  } catch (error) {
    console.error('Error updating exercise progress:', error);
    throw error;
  }
};

export const addExerciseAlternative = async (coachId, exerciseId, alternativeExercise, type = 'easier', notes = '') => {
  const alternativesRef = doc(db, `coaches/${coachId}/exerciseAlternatives`, exerciseId);
  
  try {
    await runTransaction(db, async (transaction) => {
      const doc = await transaction.get(alternativesRef);
      
      if (!doc.exists()) {
        // Create new alternatives document
        transaction.set(alternativesRef, {
          exerciseId,
          alternatives: [{
            exerciseId: alternativeExercise.id,
            type,
            notes
          }],
          updatedAt: serverTimestamp()
        });
      } else {
        // Add to existing alternatives
        const data = doc.data();
        // Check if alternative already exists
        if (!data.alternatives.some(alt => alt.exerciseId === alternativeExercise.id)) {
          transaction.update(alternativesRef, {
            alternatives: arrayUnion({
              exerciseId: alternativeExercise.id,
              type,
              notes
            }),
            updatedAt: serverTimestamp()
          });
        }
      }
    });
    
    return true;
  } catch (error) {
    console.error('Error adding exercise alternative:', error);
    throw error;
  }
};

export const removeExerciseAlternative = async (coachId, exerciseId, alternativeExerciseId) => {
  const alternativesRef = doc(db, `coaches/${coachId}/exerciseAlternatives`, exerciseId);
  
  try {
    await runTransaction(db, async (transaction) => {
      const doc = await transaction.get(alternativesRef);
      
      if (doc.exists()) {
        const data = doc.data();
        const alternative = data.alternatives.find(alt => alt.exerciseId === alternativeExerciseId);
        
        if (alternative) {
          transaction.update(alternativesRef, {
            alternatives: arrayRemove(alternative),
            updatedAt: serverTimestamp()
          });
        }
      }
    });
    
    return true;
  } catch (error) {
    console.error('Error removing exercise alternative:', error);
    throw error;
  }
};

export const modifyExercise = async (coachId, exerciseId, exerciseData) => {
  if (!coachId || !exerciseId) throw new Error('Coach ID and exercise ID are required');
  
  const exerciseRef = doc(db, `coaches/${coachId}/customExercises`, exerciseId);
  
  // Handle video upload if there's a new video
  let videoUrl = exerciseData.videoUrl;
  if (exerciseData.videoFile) {
    videoUrl = await normalizeVideoUrl(
      null, 
      exerciseData.videoFile,
      coachId,
      exerciseId
    );
  }

  const updatedData = {
    ...exerciseData,
    videoUrl,
    isCustom: true,
    updatedAt: serverTimestamp()
  };

  // Remove videoFile from the data before saving
  delete updatedData.videoFile;

  await setDoc(exerciseRef, updatedData, { merge: true });

  return {
    id: exerciseId,
    ...updatedData
  };
};
