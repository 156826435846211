// src/components/ui/Badge.jsx


import React from 'react';
import { cn } from '../../lib/utils';

const Badge = React.forwardRef(({ className, variant = "default", children, ...props }, ref) => {
  const variants = {
    default: "bg-accent-primary text-white",
    secondary: "bg-accent-secondary text-white",
    outline: "border border-accent-primary text-accent-primary bg-transparent",
    subtle: "bg-background-tertiary text-text-secondary"
  };

  return (
    <div
      ref={ref}
      className={cn(
        "inline-flex items-center rounded-full",
        "px-2.5 py-0.5 text-xs font-medium",
        "transition-colors duration-200",
        variants[variant],
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
});

Badge.displayName = "Badge";

export { Badge };
