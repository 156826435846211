// src/components/ClientInfoTab.jsx

import React, { useState, useEffect } from 'react';
import { Button } from './ui/Button';
import { Input } from './ui/Input';
import { Plus, X } from 'lucide-react';
import Switch from './ui/Switch';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';

const ClientInfoTab = ({
  clientInfo,
  clientId,
  isAddingPreference,
  setIsAddingPreference,
  newPreference,
  setNewPreference,
  handleAddPreference,
  handleDeletePreference,
}) => {
  const [requireApproval, setRequireApproval] = useState(false);

  useEffect(() => {
    fetchApprovalSetting();
  }, []);

  const fetchApprovalSetting = async () => {
    try {
      const settingsDoc = await getDoc(
        doc(db, 'users', clientId, 'settings', 'messageSettings')
      );
      if (settingsDoc.exists()) {
        const data = settingsDoc.data();
        setRequireApproval(data.requireCoachApproval || false);
      } else {
        // Default to automatic response if settings doc does not exist
        setRequireApproval(false);
      }
    } catch (error) {
      console.error('Error fetching approval setting:', error);
    }
  };

  const handleToggleApproval = async () => {
    try {
      const newValue = !requireApproval;
      setRequireApproval(newValue);
      await setDoc(
        doc(db, 'users', clientId, 'settings', 'messageSettings'),
        { requireCoachApproval: newValue },
        { merge: true } // Use merge to update existing fields if needed
      );
    } catch (error) {
      console.error('Error updating approval setting:', error);
    }
  };
  
  return (
    <>
    <div className="h-full flex flex-col">
      <div className="flex-1 overflow-y-auto min-h-0">
        <h3 className="font-semibold mb-3 text-[#FFFFFF]">Preferences</h3>
        <div className="max-h-48 overflow-y-auto pr-2">
          {clientInfo.preferences.map((pref, index) => (
            <div
              key={index}
              className="bg-[#333333] p-3 mb-2 rounded-lg flex justify-between items-center"
            >
              <span className="text-[#FFFFFF]">{pref}</span>
              <Button
                size="icon"
                variant="ghost"
                onClick={() => handleDeletePreference(pref)}
                className="text-[#E0E0E0] hover:text-[#FF6B6B] hover:bg-[#4F4F4F]"
              >
                <X className="h-4 w-4" />
              </Button>
            </div>
          ))}
        </div>

        {isAddingPreference ? (
          <div className="flex items-center mt-3">
            <Input
              value={newPreference}
              onChange={(e) => setNewPreference(e.target.value)}
              placeholder="Enter new preference"
              className="flex-grow mr-2 bg-[#333333] border-[#4F4F4F] text-[#FFFFFF]"
            />
            <Button
              onClick={handleAddPreference}
              className="bg-[#6495ED] hover:bg-[#4F7BE8]"
            >
              Add
            </Button>
            <Button
              onClick={() => {
                setIsAddingPreference(false);
                setNewPreference('');
              }}
              variant="ghost"
              className="ml-2 text-[#FFFFFF] hover:bg-[#333333]"
            >
              Cancel
            </Button>
          </div>
        ) : (
          <Button
            onClick={() => setIsAddingPreference(true)}
            variant="ghost"
            className="mt-3 text-[#6495ED] hover:bg-[#333333]"
          >
            <Plus size={20} />
          </Button>
        )}
        {/* AI Message Approval Toggle */}
        <div className="mt-6">
          <h3 className="font-semibold mb-3 text-[#FFFFFF]">AI Message Approval</h3>
          <div className="flex items-center">
            <Switch
              checked={requireApproval}
              onChange={handleToggleApproval}
              className="mr-2"
            />
            <span className="text-[#FFFFFF]">
              {requireApproval ? 'Require approval before sending AI messages' : 'Send AI messages automatically'}
            </span>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};



export default ClientInfoTab;
