// src/components/ClientRoutineBuilder.jsx

import React, { useState, useEffect } from 'react';
import { Plus, Trash2, Edit2, Clock, Sun, Moon, GripVertical, CheckSquare } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { buttonStyles, cardStyles } from './ui/Styles';
import theme from '../styles/theme';

const ClientRoutineBuilder = ({ clientId }) => {
  const [routineType, setRoutineType] = useState('morning');
  const [editingIndex, setEditingIndex] = useState(null);
  const [morningSteps, setMorningSteps] = useState([]);
  const [eveningSteps, setEveningSteps] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadRoutines = async () => {
      if (!clientId) return;
      
      setIsLoading(true);
      try {
        // Load morning routine from client's userKnowledge collection
        const morningDocRef = doc(db, 'users', clientId, 'userKnowledge', 'morningRoutine');
        const morningDocSnap = await getDoc(morningDocRef);
        
        // Load evening routine
        const eveningDocRef = doc(db, 'users', clientId, 'userKnowledge', 'eveningRoutine');
        const eveningDocSnap = await getDoc(eveningDocRef);

        setMorningSteps(morningDocSnap.exists() ? (morningDocSnap.data().steps || []) : []);
        setEveningSteps(eveningDocSnap.exists() ? (eveningDocSnap.data().steps || []) : []);

      } catch (error) {
        console.error('Error loading routine data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadRoutines();
  }, [clientId]);

  useEffect(() => {
    const saveRoutine = async () => {
      if (!clientId || isLoading) return;
      
      const docRef = doc(db, 'users', clientId, 'userKnowledge', 'morningRoutine');
      try {
        await setDoc(docRef, { steps: morningSteps }, { merge: true });
      } catch (error) {
        console.error('Error saving morning routine:', error);
      }
    };
  
    saveRoutine();
  }, [clientId, morningSteps, isLoading]);
  
  // Save evening routine when it changes
  useEffect(() => {
    const saveRoutine = async () => {
      if (!clientId || isLoading) return;
      
      const docRef = doc(db, 'users', clientId, 'userKnowledge', 'eveningRoutine');
      try {
        await setDoc(docRef, { steps: eveningSteps }, { merge: true });
      } catch (error) {
        console.error('Error saving evening routine:', error);
      }
    };
  
    saveRoutine();
  }, [clientId, eveningSteps, isLoading]);  

  const TimelineView = () => {
    const steps = routineType === 'morning' ? morningSteps : eveningSteps;
    const setSteps = routineType === 'morning' ? setMorningSteps : setEveningSteps;

    const getRunningTime = () => {
      return steps
        .filter(step => step.type === 'timed')
        .reduce((total, step) => total + step.duration, 0);
    };

    return (
      <div className="space-y-4">
        {steps.map((step, index) => (
          <div 
            key={index} 
            className="group flex items-start gap-4 p-4 bg-background-secondary rounded-lg border border-border-light hover:border-accent-primary transition-all duration-300"
            draggable="true"
            onDragStart={(e) => e.dataTransfer.setData('text/plain', index)}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => {
              e.preventDefault();
              const fromIndex = parseInt(e.dataTransfer.getData('text/plain'));
              const toIndex = index;
              const newSteps = [...steps];
              const [movedStep] = newSteps.splice(fromIndex, 1);
              newSteps.splice(toIndex, 0, movedStep);
              setSteps(newSteps);
            }}
          >
            <div className="cursor-move text-text-light hover:text-accent-primary mt-2">
              <GripVertical className="w-5 h-5" />
            </div>
            
            <div className="flex-1">
              <div className="flex justify-between items-start mb-2">
                <div>
                  <h3 className="font-headers text-lg text-text-primary font-medium">{step.title}</h3>
                  <div className="flex items-center gap-2 mt-1">
                    {step.type === 'timed' ? (
                      <>
                        <Clock className="w-4 h-4 text-accent-secondary" />
                        <span className="text-sm text-text-light font-body">{step.duration} minutes</span>
                      </>
                    ) : (
                      <>
                        <CheckSquare className="w-4 h-4 text-accent-secondary" />
                        <span className="text-sm text-text-light font-body">Checklist item</span>
                      </>
                    )}
                  </div>
                </div>
                
                <div className="flex gap-2">
                  <button 
                    onClick={() => setEditingIndex(index)}
                    className="p-1.5 text-text-light hover:text-accent-primary hover:bg-background-tertiary rounded-lg transition-colors duration-300"
                  >
                    <Edit2 className="w-4 h-4" />
                  </button>
                  <button 
                    onClick={() => setSteps(steps.filter((_, i) => i !== index))}
                    className="p-1.5 text-text-light hover:text-accent-primary hover:bg-background-tertiary rounded-lg transition-colors duration-300"
                  >
                    <Trash2 className="w-4 h-4" />
                  </button>
                </div>
              </div>
              
              <p className="text-text-light text-sm font-body">{step.description}</p>
            </div>
          </div>
        ))}

        {steps.length > 0 && steps.some(step => step.type === 'timed') && (
          <div className="mt-6 pt-4 border-t border-border-light">
            <div className="flex items-center gap-2 text-text-secondary font-body">
              <Clock className="w-4 h-4" />
              <span className="font-medium">Total Time: {getRunningTime()} minutes</span>
            </div>
          </div>
        )}
      </div>
    );
  };

  const EditModal = ({ step, index, onClose }) => {
    const steps = routineType === 'morning' ? morningSteps : eveningSteps;
    const setSteps = routineType === 'morning' ? setMorningSteps : setEveningSteps;
    const [stepType, setStepType] = useState(step.type || 'timed');

    return (
      <div className="fixed inset-0 bg-text-primary/10 backdrop-blur-sm flex items-center justify-center z-50">
        <div className="bg-background-primary rounded-xl p-6 max-w-lg w-full m-4 border border-border-light shadow-lg">
          <h3 className="text-xl font-headers font-bold text-text-primary mb-2">Edit Step</h3>
          <p className="text-text-light font-body mb-6">Customize your routine step details</p>
          
          <form onSubmit={(e) => {
            e.preventDefault();
            const newSteps = [...steps];
            newSteps[index] = {
              title: e.target.title.value,
              description: e.target.description.value,
              type: stepType,
              ...(stepType === 'timed' && { duration: parseInt(e.target.duration?.value) }),
            };
            setSteps(newSteps);
            onClose();
          }}>
            <div className="space-y-6">
              <div className="flex gap-4">
                <button
                  type="button"
                  onClick={() => setStepType('timed')}
                  className={`flex-1 p-4 rounded-lg flex items-center justify-center gap-2 border-2 transition-all duration-300 ${
                    stepType === 'timed'
                      ? 'bg-accent-primary border-accent-primary text-white shadow-md'
                      : 'bg-background-secondary border-border-light text-text-light hover:border-accent-primary hover:text-accent-primary'
                  }`}
                >
                  <Clock className="w-4 h-4" />
                  <span className="font-body font-medium">Timed Activity</span>
                </button>
                <button
                  type="button"
                  onClick={() => setStepType('checklist')}
                  className={`flex-1 p-4 rounded-lg flex items-center justify-center gap-2 border-2 transition-all duration-300 ${
                    stepType === 'checklist'
                      ? 'bg-accent-primary border-accent-primary text-white shadow-md'
                      : 'bg-background-secondary border-border-light text-text-light hover:border-accent-primary hover:text-accent-primary'
                  }`}
                >
                  <CheckSquare className="w-4 h-4" />
                  <span className="font-body font-medium">Checklist Item</span>
                </button>
              </div>
              
              <div className="space-y-2">
                <label className="block text-sm font-medium text-text-secondary">Title</label>
                <input
                  name="title"
                  defaultValue={step.title}
                  className="w-full p-3 bg-background-secondary border border-border-light rounded-lg 
                           text-text-primary placeholder-text-light
                           focus:ring-2 focus:ring-accent-primary/20 focus:border-accent-primary
                           hover:border-accent-secondary transition-colors duration-300"
                  placeholder="Enter step title"
                />
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-text-secondary">Description</label>
                <textarea
                  name="description"
                  defaultValue={step.description}
                  rows={3}
                  className="w-full p-3 bg-background-secondary border border-border-light rounded-lg 
                           text-text-primary placeholder-text-light
                           focus:ring-2 focus:ring-accent-primary/20 focus:border-accent-primary
                           hover:border-accent-secondary transition-colors duration-300"
                  placeholder="Enter step description"
                />
              </div>

              {stepType === 'timed' && (
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-text-secondary">Duration (minutes)</label>
                  <input
                    name="duration"
                    type="number"
                    defaultValue={step.duration}
                    min="1"
                    className="w-full p-3 bg-background-secondary border border-border-light rounded-lg 
                             text-text-primary placeholder-text-light
                             focus:ring-2 focus:ring-accent-primary/20 focus:border-accent-primary
                             hover:border-accent-secondary transition-colors duration-300"
                  />
                </div>
              )}
            </div>

            <div className="flex justify-end gap-3 mt-8">
              <button
                type="button"
                onClick={onClose}
                className="px-6 py-2.5 border-2 border-border-light rounded-lg font-body font-medium
                         text-text-light hover:bg-background-tertiary hover:text-text-primary
                         transition-colors duration-300"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-6 py-2.5 bg-accent-primary rounded-lg font-body font-medium text-white
                         hover:bg-hover-primary shadow-md hover:shadow-lg
                         transition-all duration-300"
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-pulse text-neutral-400">Loading...</div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex space-x-4">
        <button
          className={`flex items-center px-6 py-3 rounded-lg transition-all duration-300 font-body font-medium ${
            routineType === 'morning' 
              ? 'bg-accent-primary text-white shadow-md' 
              : 'bg-background-secondary text-text-light hover:bg-background-tertiary'
          }`}
          onClick={() => setRoutineType('morning')}
        >
          <Sun className="w-4 h-4 mr-2" />
          Morning Routine
        </button>
        <button
          className={`flex items-center px-6 py-3 rounded-lg transition-all duration-300 font-body font-medium ${
            routineType === 'evening' 
              ? 'bg-accent-primary text-white shadow-md' 
              : 'bg-background-secondary text-text-light hover:bg-background-tertiary'
          }`}
          onClick={() => setRoutineType('evening')}
        >
          <Moon className="w-4 h-4 mr-2" />
          Evening Routine
        </button>
      </div>

      <Card className="border-border-light bg-background-secondary shadow-md">
        <CardHeader className="border-b border-border-light">
          <CardTitle className="text-xl font-headers font-bold text-text-primary">
            {routineType === 'morning' ? 'Morning' : 'Evening'} Routine Builder
          </CardTitle>
        </CardHeader>
        <CardContent className="p-6">
          <TimelineView />
          
          <button
            onClick={() => {
              const steps = routineType === 'morning' ? morningSteps : eveningSteps;
              const setSteps = routineType === 'morning' ? setMorningSteps : setEveningSteps;
              const newStep = {
                title: 'New Step',
                description: '',
                type: 'timed',
                duration: 15
              };
              const newSteps = [...steps, newStep];
              setSteps(newSteps);
              setTimeout(() => setEditingIndex(steps.length), 0);
            }}
            className="mt-6 w-full p-4 border-2 border-dashed border-border-medium rounded-lg 
                     text-text-light hover:border-accent-primary hover:text-accent-primary 
                     hover:bg-background-tertiary transition-all duration-300 
                     flex items-center justify-center gap-2 font-body"
          >
            <Plus className="w-5 h-5" />
            Add New Step
          </button>
        </CardContent>
      </Card>

      {editingIndex !== null && (
        <EditModal
          step={(routineType === 'morning' ? morningSteps : eveningSteps)[editingIndex]}
          index={editingIndex}
          onClose={() => setEditingIndex(null)}
        />
      )}
    </div>
  );
};

export default ClientRoutineBuilder;