import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, doc, getDoc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import { Button } from '../ui/Button';
import { ArrowLeft, Save, Image, Edit, Camera, Star, Upload, Video, Film } from 'lucide-react';
import theme from '../../styles/theme';
import NavigationBar from '../ui/NavigationBar';

// Import the extracted components
import ContentTab from './tabs/ContentTab';
import TestimonialsTab from './tabs/TestimonialsTab';
import FAQsTab from './tabs/FAQsTab';
import MediaUploadTab from './tabs/MediaUploadTab';
import PreviewPanel from './PreviewPanel';

const LandingPageEditor = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('media');
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  
  // Coach data state
  const [coachData, setCoachData] = useState(null);
  
  // Background media state
  const [backgroundMedia, setBackgroundMedia] = useState(null);
  const [backgroundMediaFile, setBackgroundMediaFile] = useState(null);
  const [backgroundMediaType, setBackgroundMediaType] = useState(null);
  const [backgroundMediaPosition, setBackgroundMediaPosition] = useState('center');
  
  // Text content state
  const [heroTitle, setHeroTitle] = useState('');
  const [heroBio, setHeroBio] = useState('');
  const [successStoriesTitle, setSuccessStoriesTitle] = useState('');
  const [successStoriesSubtitle, setSuccessStoriesSubtitle] = useState('');
  const [faqTitle, setFaqTitle] = useState('');
  const [faqSubtitle, setFaqSubtitle] = useState('');
  const [ctaTitle, setCtaTitle] = useState('');
  
  // Testimonials state
  const [testimonials, setTestimonials] = useState([]);
  
  // FAQ state
  const [faqs, setFaqs] = useState([]);
  
  // Brand colors from theme
  const brandColors = {
    primary: theme.colors.accent.primary,
    secondary: theme.colors.background.primary,
    accent: theme.colors.accent.secondary,
    text: theme.colors.text.primary
  };
  
  // Fetch coach data
  useEffect(() => {
    const fetchCoachData = async () => {
      if (!user?.uid) return;
      
      setIsLoading(true);
      try {
        const coachRef = doc(db, 'coaches', user.uid);
        const coachDoc = await getDoc(coachRef);
        
        if (coachDoc.exists()) {
          const data = coachDoc.data();
          setCoachData({
            ...data,
            id: user.uid,
          });
          
          // Set background media
          if (data.landingPage?.backgroundMedia) {
            setBackgroundMedia(data.landingPage.backgroundMedia);
            setBackgroundMediaType(data.landingPage.backgroundMediaType || 'image');
            setBackgroundMediaPosition(data.landingPage.backgroundMediaPosition || 'center');
          }
          
          setHeroTitle(data.landingPage?.heroTitle || 'Transform your body. Elevate your life.');
          setHeroBio(data.landingPage?.heroBio || data.bio || 'I help ambitious people achieve their fitness goals through personalized coaching and proven methods that fit your lifestyle.');
          setSuccessStoriesTitle(data.landingPage?.successStoriesTitle || 'Success Stories');
          setSuccessStoriesSubtitle(data.landingPage?.successStoriesSubtitle || 'Join the growing community of clients who have transformed their lives');
          setFaqTitle(data.landingPage?.faqTitle || 'Frequently Asked Questions');
          setFaqSubtitle(data.landingPage?.faqSubtitle || 'Everything you need to know about working with me');
          setCtaTitle(data.landingPage?.ctaTitle || 'Ready to Transform Your Life?');
          
          // Set testimonials
          setTestimonials(data.landingPage?.testimonials || [
            {
              name: 'Sarah J.',
              text: 'Working with this coach transformed my approach to fitness and nutrition. I have achieved results I never thought possible!',
              stars: 5,
              image: null,
            },
            {
              name: 'Michael T.',
              text: 'The personalized plan and constant support made all the difference in my fitness journey.',
              stars: 5,
              image: null,
            },
            {
              name: 'Emma R.',
              text: 'After trying numerous programs, this coaching approach finally helped me reach my goals. Highly recommend!',
              stars: 5,
              image: null,
            }
          ]);
          
          // Set FAQs
          setFaqs(data.landingPage?.faqs || [
            {
              question: 'How does the coaching process work?',
              answer: 'After filling out an initial questionnaire, we\'ll schedule a consultation to discuss your goals and create a personalized plan. I\'ll provide ongoing support, regular check-ins, and adjustments as needed.'
            },
            {
              question: 'How long until I see results?',
              answer: 'Results vary based on your starting point, goals, and consistency. Most clients see noticeable improvements within 4-6 weeks when following the program.'
            },
            {
              question: 'Do I need a gym membership?',
              answer: 'Not necessarily. I can create programs for home workouts, gym settings, or a combination based on your preferences and available equipment.'
            }
          ]);
        }
      } catch (err) {
        console.error('Error fetching coach data:', err);
        setErrorMessage('Failed to load your data. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchCoachData();
  }, [user]);
  
  // Save all changes
  const saveChanges = async () => {
    if (!user?.uid) return;
    
    setIsSaving(true);
    setErrorMessage('');
    setSuccessMessage('');
    
    try {
      let backgroundMediaUrl = backgroundMedia;
      
      // Upload background media if changed
      if (backgroundMediaFile) {
        const mediaStorageRef = ref(storage, `coaches/${user.uid}/landing-page-media`);
        await uploadBytes(mediaStorageRef, backgroundMediaFile);
        backgroundMediaUrl = await getDownloadURL(mediaStorageRef);
      }
      
      // Prepare landing page data
      const landingPageData = {
        heroTitle,
        heroBio,
        successStoriesTitle,
        successStoriesSubtitle,
        faqTitle,
        faqSubtitle,
        ctaTitle,
        testimonials,
        faqs,
        backgroundMedia: backgroundMediaUrl,
        backgroundMediaType,
        backgroundMediaPosition,
        // Using app brand colors instead of custom colors
        colors: brandColors,
        updatedAt: new Date()
      };
      
      // Update coach document
      const coachRef = doc(db, 'coaches', user.uid);
      await updateDoc(coachRef, {
        bio: heroBio,
        landingPage: landingPageData
      });
      
      setSuccessMessage('Your landing page has been updated successfully! All changes are now live.');
      
      // Scroll to top to ensure success message is visible
      window.scrollTo({ top: 0, behavior: 'smooth' });
      
      // Clear success message after 5 seconds
      setTimeout(() => {
        setSuccessMessage('');
      }, 5000);
    } catch (err) {
      console.error('Error saving changes:', err);
      setErrorMessage('Failed to save changes. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };
  
  // Add a new testimonial
  const addTestimonial = () => {
    setTestimonials([
      ...testimonials,
      {
        name: 'Client Name',
        text: 'Add your testimonial text here',
        stars: 5,
        image: null,
      }
    ]);
  };
  
  // Remove a testimonial
  const removeTestimonial = (index) => {
    const newTestimonials = [...testimonials];
    newTestimonials.splice(index, 1);
    setTestimonials(newTestimonials);
  };
  
  // Update testimonial field
  const updateTestimonial = (index, field, value) => {
    const newTestimonials = [...testimonials];
    newTestimonials[index][field] = value;
    setTestimonials(newTestimonials);
  };
  
  // Add a new FAQ
  const addFaq = () => {
    setFaqs([
      ...faqs,
      {
        question: 'New Question',
        answer: 'Your answer here'
      }
    ]);
  };
  
  // Remove a FAQ
  const removeFaq = (index) => {
    const newFaqs = [...faqs];
    newFaqs.splice(index, 1);
    setFaqs(newFaqs);
  };
  
  // Update FAQ field
  const updateFaq = (index, field, value) => {
    const newFaqs = [...faqs];
    newFaqs[index][field] = value;
    setFaqs(newFaqs);
  };
  
  // Render loading state
  if (isLoading) {
    return (
      <div className="min-h-screen bg-background-primary pt-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin h-8 w-8 border-4 border-accent-primary border-t-transparent rounded-full"></div>
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div className="min-h-screen bg-background-primary pt-20">
      <div className="container mx-auto p-4">
        <div className="flex items-center justify-between mb-6">
          <div>
            <div className="flex items-center gap-2">
              <Button 
                variant="ghost" 
                onClick={() => navigate('/onboarding-manager')}
                className="p-2"
              >
                <ArrowLeft className="h-5 w-5" />
              </Button>
              <h1 className="text-2xl font-semibold text-text-primary">Landing Page Editor</h1>
            </div>
            <p className="text-text-light">Customize your client landing page</p>
          </div>
          <div className="flex gap-3">
            <Button
              variant="outline"
              onClick={() => window.open(`/${coachData?.username || user.uid}`, '_blank')}
              className="flex items-center gap-2"
            >
              Preview
            </Button>
            <Button
              variant="primary"
              onClick={saveChanges}
              className="flex items-center gap-2"
              disabled={isSaving}
            >
              {isSaving ? (
                <div className="animate-spin h-4 w-4 border-2 border-white border-t-transparent rounded-full mr-2"></div>
              ) : (
                <Save className="h-4 w-4 mr-1" />
              )}
              Save Changes
            </Button>
          </div>
        </div>
        
        {errorMessage && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
            {errorMessage}
          </div>
        )}
        
        {successMessage && (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4 flex items-center">
            <div className="bg-green-500 rounded-full p-1 mr-3">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
            </div>
            <span>{successMessage}</span>
          </div>
        )}
        
        {/* Editor Tabs */}
        <div className="mb-6">
          <NavigationBar
            tabs={[
              { id: 'media', label: 'Media', icon: <Film className="h-4 w-4" /> },
              { id: 'content', label: 'Text Content', icon: <Edit className="h-4 w-4" /> },
              { id: 'testimonials', label: 'Testimonials', icon: <Star className="h-4 w-4" /> },
              { id: 'faqs', label: 'FAQs', icon: <Image className="h-4 w-4" /> }
            ]}
            activeTab={activeTab}
            onChange={setActiveTab}
          />
        </div>
        
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div className="lg:col-span-2">
            {/* Media Tab */}
            {activeTab === 'media' && (
              <MediaUploadTab 
                backgroundMedia={backgroundMedia}
                setBackgroundMedia={setBackgroundMedia}
                backgroundMediaFile={backgroundMediaFile}
                setBackgroundMediaFile={setBackgroundMediaFile}
                backgroundMediaType={backgroundMediaType}
                setBackgroundMediaType={setBackgroundMediaType}
                backgroundMediaPosition={backgroundMediaPosition}
                setBackgroundMediaPosition={setBackgroundMediaPosition}
              />
            )}
            
            {/* Content Tab */}
            {activeTab === 'content' && (
              <ContentTab 
                heroTitle={heroTitle}
                heroBio={heroBio}
                successStoriesTitle={successStoriesTitle}
                successStoriesSubtitle={successStoriesSubtitle}
                faqTitle={faqTitle}
                faqSubtitle={faqSubtitle}
                ctaTitle={ctaTitle}
                setHeroTitle={setHeroTitle}
                setHeroBio={setHeroBio}
                setSuccessStoriesTitle={setSuccessStoriesTitle}
                setSuccessStoriesSubtitle={setSuccessStoriesSubtitle}
                setFaqTitle={setFaqTitle}
                setFaqSubtitle={setFaqSubtitle}
                setCtaTitle={setCtaTitle}
              />
            )}
            
            {/* Testimonials Tab */}
            {activeTab === 'testimonials' && (
              <TestimonialsTab 
                testimonials={testimonials}
                addTestimonial={addTestimonial}
                removeTestimonial={removeTestimonial}
                updateTestimonial={updateTestimonial}
              />
            )}
            
            {/* FAQs Tab */}
            {activeTab === 'faqs' && (
              <FAQsTab 
                faqs={faqs}
                addFaq={addFaq}
                removeFaq={removeFaq}
                updateFaq={updateFaq}
              />
            )}
          </div>
          
          {/* Preview Panel */}
          <div className="lg:col-span-1">
            <div className="sticky top-24">
              <PreviewPanel 
                backgroundMedia={backgroundMedia}
                backgroundMediaType={backgroundMediaType}
                heroTitle={heroTitle}
                heroBio={heroBio}
                successStoriesTitle={successStoriesTitle}
                faqTitle={faqTitle}
                ctaTitle={ctaTitle}
                testimonials={testimonials}
                faqs={faqs}
                brandColors={brandColors}
                coachData={coachData}
                user={user}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPageEditor; 