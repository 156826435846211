import React, { useState, useEffect,useCallback } from 'react';
import { Button } from './ui/Button';
import { Card, CardContent } from './ui/Card';
import { ChevronLeft, ChevronRight, Plus, User, Eye, HelpCircle, MessageCircle } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { collection, getDocs,getDoc,docs,doc } from 'firebase/firestore';
import { db } from '../firebase';
import TemplateManagementOverlay from './overlays/TemplateManagementOverlay';
import { getAssignedWorkoutsForClient } from '../services/workoutService';
import ClientSearch from './ClientSearch';
import { Alert, AlertDescription } from './ui/Alert';
import { useNavigate } from 'react-router-dom';
import { cn } from '../lib/utils';


const CalendarPage = () => {
  const { user } = useAuth();
  const [clients, setClients] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [showTemplateOverlay, setShowTemplateOverlay] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [assignedWorkouts, setAssignedWorkouts] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchClients = async () => {
      if (!user?.uid) return;
    
      try {
        
        const coachRef = doc(db, 'coaches', user.uid);
        const coachSnap = await getDoc(coachRef);
        
        if (coachSnap.exists()) {
          const coachData = coachSnap.data();
          const clientList = coachData.clients || [];
          
          console.log('Found clients:', clientList);
          setClients(clientList);
          if (clientList.length > 0) {
            setSelectedClientId(clientList[0].id);
          }
        }
      } catch (error) {
        
      }
    };
  
    if (user?.uid) {
      fetchClients();
    }
  }, [user]);

  useEffect(() => {
    const loadWorkouts = async () => {
      if (user?.uid && selectedClientId) {
        const workouts = await getAssignedWorkoutsForClient(user.uid, selectedClientId);
        setAssignedWorkouts(workouts);
      }
    };
    loadWorkouts();
  }, [user, selectedClientId]);

  const handlePreviousMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1));
  };

  const handleDateClick = (date) => {
    if (!selectedClientId) {
      setShowError(true);
      setTimeout(() => setShowError(false), 5000);
      return;
    }
    setShowError(false);
    setSelectedDate(date);
    setShowTemplateOverlay(true);
  };

  const handleTemplateSelected = (template) => {
    if (!selectedClientId || !selectedDate) {
      console.error('Missing client or date information');
      return;
    }
    
    // Format date as ISO string for URL
    const formattedDate = selectedDate.toISOString();
    navigate(`/workouts/edit/new?clientId=${selectedClientId}&date=${formattedDate}&templateId=${template.id}`);
  };

  const handleWorkoutCardClick = (workout) => {
    if (!selectedClientId) {
      setShowError(true);
      setTimeout(() => setShowError(false), 5000);
      return;
    }
    setShowError(false);
    navigate(`/workouts/edit/${workout.id}?clientId=${selectedClientId}`);
  };

  const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  // Get first day of the month for calendar calculations
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const startingDayIndex = firstDayOfMonth.getDay(); // 0-6 (Sunday-Saturday)
  
  // Get the number of days in the current month
  const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();

  // Create calendar days array including empty cells for proper alignment
  const calendarDays = [...Array(startingDayIndex).fill(null), ...Array(daysInMonth).keys()].map((day, index) => {
    if (day === null) return null;
    return new Date(currentDate.getFullYear(), currentDate.getMonth(), day + 1);
  });

  return (
    <div className="min-h-screen bg-background-primary">
      <div className="container mx-auto p-4 pt-20">
        {/* Error Alert - Updated styling */}
        {showError && (
          <Alert variant="destructive" className="mb-4 bg-red-500/10 text-red-400 border border-red-500/20">
            <AlertDescription>
              Please select a client before assigning or editing workouts.
            </AlertDescription>
          </Alert>
        )}

        {/* Enhanced Header - Updated styling for mobile */}
        <Card className="mb-6 bg-background-secondary border border-border-light">
          <CardContent className="p-6">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="flex items-center space-x-6">
                <h1 className="font-headers text-text-primary text-2xl md:text-3xl">
                  {currentDate.toLocaleString('default', { month: 'long' })} {' '}
                  <span className="text-text-light">{currentDate.getFullYear()}</span>
                </h1>
                <div className="flex space-x-2">
                  <button 
                    onClick={handlePreviousMonth}
                    className="p-2 rounded-lg hover:bg-background-tertiary text-text-secondary 
                             hover:text-text-primary transition-all duration-300"
                  >
                    <ChevronLeft className="w-5 h-5" />
                  </button>
                  <button 
                    onClick={handleNextMonth}
                    className="p-2 rounded-lg hover:bg-background-tertiary text-text-secondary 
                             hover:text-text-primary transition-all duration-300"
                  >
                    <ChevronRight className="w-5 h-5" />
                  </button>
                </div>
              </div>
              
              <div className="flex items-center space-x-4">
                <div className="relative flex items-center w-full md:w-72">
                  <User className="absolute left-3 w-4 h-4 text-text-light" />
                  <ClientSearch
                    value={selectedClientId}
                    onChange={(val) => setSelectedClientId(val)}
                    className="w-full pl-10 pr-4 py-2 bg-background-primary text-text-primary rounded-lg 
                             border border-border-light focus:border-accent-primary focus:ring-1 
                             focus:ring-accent-primary transition-all duration-300"
                  />
                </div>
                <Button
                  onClick={() => window.location.assign('/templates')} 
                  className="flex items-center space-x-2 bg-accent-primary hover:bg-hover-primary text-white 
                           px-4 py-2 rounded-lg font-medium transition-all duration-300"
                >
                  <Eye className="w-4 h-4" />
                  <span className="text-sm md:text-base">View Templates</span>
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>

        {/* Calendar Grid - Updated styling for mobile */}
        <Card className="bg-background-secondary border border-border-light">
          <CardContent className="p-0">
            <div className="grid grid-cols-7 bg-background-tertiary border-b border-border-light">
              {weekDays.map((day) => (
                <div key={day} className="py-3 text-center text-sm font-medium text-text-secondary">
                  {day}
                </div>
              ))}
            </div>
            
            <div className="grid grid-cols-2 md:grid-cols-7 overflow-x-auto">
              {calendarDays.map((date, i) => {
                if (!date) return (
                  <div key={`empty-${i}`} className="bg-background-secondary border-b border-r border-border-light" />
                );
                
                const isToday = date.toDateString() === new Date().toDateString();
                const hasWorkouts = assignedWorkouts.some(w => {
                  return w.scheduledTime && w.scheduledTime.toDate().toDateString() === date.toDateString();
                });

                return (
                  <div
                    key={i}
                    onClick={() => handleDateClick(date)}
                    className={cn(
                      "min-h-[100px] p-2 cursor-pointer transition-all duration-300",
                      "border-b border-r border-border-light",
                      isToday && "bg-accent-primary/5 ring-1 ring-accent-primary",
                      hasWorkouts && "bg-accent-secondary/5",
                      "hover:bg-background-tertiary"
                    )}
                  >
                    <div className={cn(
                      "text-sm mb-1",
                      isToday ? "text-accent-primary font-medium" : "text-text-secondary"
                    )}>
                      {date.getDate()}
                    </div>
                    <div className="space-y-1">
                      {assignedWorkouts
                        .filter(w => {
                          return w.scheduledTime && w.scheduledTime.toDate().toDateString() === date.toDateString();
                        })
                        .map((w) => (
                          <div
                            key={w.id}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleWorkoutCardClick(w);
                            }}
                            className="px-2 py-1 text-xs rounded-lg font-body
                                     bg-background-primary text-text-primary
                                     hover:bg-accent-primary hover:text-white
                                     border border-border-light
                                     transition-all duration-300"
                          >
                            {w.name}
                          </div>
                        ))}
                    </div>
                  </div>
                );
              })}
            </div>
          </CardContent>
        </Card>
      </div>

      {showTemplateOverlay && (
        <TemplateManagementOverlay
          onClose={() => setShowTemplateOverlay(false)}
          onTemplateSelect={handleTemplateSelected}
          isAssigning={true}
        />
      )}
    </div>
  );
};

export default CalendarPage;