import { db } from '../firebase';
import { collection, addDoc, deleteDoc, doc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';

export const createFolder = async (coachId, folderData) => {
  try {
    const folder = {
      name: folderData.name,
      workoutTemplateIds: [],
      createdAt: new Date()
    };

    const folderRef = collection(db, `coaches/${coachId}/folders`);
    const docRef = await addDoc(folderRef, folder);
    return { id: docRef.id, ...folder };
  } catch (error) {
    console.error('Error creating folder:', error);
    throw error;
  }
};

export const deleteFolder = async (coachId, folderId) => {
  try {
    await deleteDoc(doc(db, `coaches/${coachId}/folders/${folderId}`));
    return true;
  } catch (error) {
    console.error('Error deleting folder:', error);
    throw error;
  }
};

export const addTemplateToFolder = async (coachId, folderId, templateId) => {
  try {
    const folderRef = doc(db, `coaches/${coachId}/folders/${folderId}`);
    await updateDoc(folderRef, {
      workoutTemplateIds: arrayUnion(templateId)
    });
    return true;
  } catch (error) {
    console.error('Error adding template to folder:', error);
    throw error;
  }
};

export const removeTemplateFromFolder = async (coachId, folderId, templateId) => {
  try {
    const folderRef = doc(db, `coaches/${coachId}/folders/${folderId}`);
    await updateDoc(folderRef, {
      workoutTemplateIds: arrayRemove(templateId)
    });
    return true;
  } catch (error) {
    console.error('Error removing template from folder:', error);
    throw error;
  }
}; 