import React from 'react';
import { MessageSquare, Bot, Send, X } from 'lucide-react';
import { Button } from '../../../components/ui/Button';

const ConversationItem = ({ client, pendingMessage, onSelectClient, onQuickSend, onCancelPending }) => {
  const hasPending = !!pendingMessage;
  
  const formatMessageTime = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp.seconds * 1000);
    const now = new Date();
    const isToday = date.toDateString() === now.toDateString();
    const isThisYear = date.getFullYear() === now.getFullYear();
    
    if (isToday) {
      return date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
    } else if (isThisYear) {
      return date.toLocaleDateString([], { month: 'short', day: 'numeric' });
    }
    return date.toLocaleDateString([], { month: 'short', day: 'numeric', year: 'numeric' });
  };

  const getLastActiveTime = () => {
    if (hasPending) return 'Pending Response';
    if (client.lastMessage?.date) return formatMessageTime(client.lastMessage.date);
    if (client.lastActive) return `Active ${formatMessageTime(client.lastActive)}`;
    return '';
  };

  return (
    <div 
      className={`
        p-4 border-b border-border-light 
        hover:bg-background-tertiary transition-colors duration-200 
        ${hasPending ? 'bg-accent-primary/5' : ''}
        cursor-pointer
      `}
      onClick={() => onSelectClient(client, pendingMessage)}
    >
      {/* Header with avatar and time */}
      <div className="flex items-center gap-3 mb-2">
        <div className="w-10 h-10 rounded-full bg-accent-secondary flex items-center justify-center text-white font-bold flex-shrink-0">
          {(client.name?.[0] || '?').toUpperCase()}
        </div>
        <div className="flex-1 min-w-0">
          <div className="flex justify-between items-center">
            <span className="font-medium text-text-primary truncate">
              {client.name || 'Unnamed Client'}
            </span>
            <span className="text-xs text-text-light whitespace-nowrap ml-2">
              {getLastActiveTime()}
            </span>
          </div>
        </div>
      </div>

      {/* Message Preview Section */}
      {hasPending ? (
        <div className="ml-13 space-y-3 bg-background-secondary rounded-lg p-3">
          <div className="flex items-start gap-2">
            <MessageSquare size={14} className="text-text-light mt-1 flex-shrink-0" />
            <p className="text-sm text-text-primary font-medium line-clamp-2">
              {pendingMessage.userMessage}
            </p>
          </div>
          <div className="flex items-start gap-2">
            <Bot size={14} className="text-accent-primary mt-1 flex-shrink-0" />
            <div className="flex-1">
              <p className="text-sm text-text-secondary line-clamp-3">
                {pendingMessage.aiResponse}
              </p>
              <div className="flex justify-end gap-2 mt-2">
                <Button
                  variant="ghost"
                  size="sm"
                  className="text-accent-primary hover:bg-accent-primary/10 px-3 py-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    onQuickSend(pendingMessage);
                  }}
                >
                  <Send size={14} className="mr-1" />
                  <span className="text-xs">Send</span>
                </Button>
                <Button
                  variant="ghost"
                  size="sm"
                  className="text-text-light hover:bg-background-tertiary px-3 py-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    onCancelPending(pendingMessage.id);
                  }}
                >
                  <X size={14} className="mr-1" />
                  <span className="text-xs">Cancel</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        client.lastMessage && (
          <div className="ml-13">
            <p className="text-sm text-text-secondary line-clamp-2">
              {client.lastMessage.content}
            </p>
          </div>
        )
      )}
    </div>
  );
};

const ConversationSidebar = ({ conversations, onSelectClient, onQuickSend, onCancelPending, isMobile, onClose }) => {
  return (
    <div className="h-full flex flex-col bg-background-secondary rounded-xl shadow-lg overflow-hidden">
      <div className="p-4 border-b border-border-light flex justify-between items-center sticky top-0 bg-background-secondary z-10">
        <h2 className="text-lg font-bold text-text-primary">Conversations</h2>
        {isMobile && (
          <Button
            variant="ghost"
            size="sm"
            onClick={onClose}
            className="md:hidden text-text-light hover:bg-background-tertiary p-2 rounded-lg"
          >
            <X size={20} />
          </Button>
        )}
      </div>
      
      <div className="flex-1 overflow-y-auto">
        {conversations.length > 0 ? (
          conversations.map((conv) => (
            <ConversationItem
              key={conv.clientId}
              client={conv}
              pendingMessage={conv.pendingMessage}
              onSelectClient={onSelectClient}
              onQuickSend={onQuickSend}
              onCancelPending={onCancelPending}
            />
          ))
        ) : (
          <div className="flex flex-col items-center justify-center h-full p-4 text-text-light">
            <MessageSquare size={40} className="opacity-50 mb-2" />
            <p>No conversations yet</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConversationSidebar; 