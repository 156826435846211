import React from 'react';
import { Info, Send, MessageSquare, Menu } from 'lucide-react';
import { Button } from '../../../components/ui/Button';
import { Input } from '../../../components/ui/Input';
import { WorkoutPreviewCard, MealPreviewCard } from './MessagePreviewCards';

const ChatArea = ({ 
  selectedClient, 
  chatMessages, 
  message, 
  setMessage, 
  handleSendMessage, 
  currentPendingMessageId,
  chatContainerRef,
  onSelectClient,
  onOpenMobileDrawer
}) => {
  // Add this useEffect to handle initial scroll position
  React.useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatMessages, selectedClient]);

  const renderPreviewCard = (msg) => {
    try {
      // For workout messages
      if (msg.type === 'workout') {
        const content = typeof msg.text === 'string' ? JSON.parse(msg.text) : msg.text;
        return <WorkoutPreviewCard preview={content.preview} />;
      }
      
      // For meal preview messages
      if (msg.type === 'meal_preview') {
        const content = typeof msg.text === 'string' ? JSON.parse(msg.text) : msg.text;
        // Create the preview object in the format MealPreviewCard expects
        const preview = {
          date: content.date,
          totalProtein: content.totalProtein,
          totalCalories: content.totalCalories,
          foodItems: content.foodItems
        };
        return <MealPreviewCard preview={preview} />;
      }
      
      // Fallback for regular messages
      return msg.text;
    } catch (error) {
      console.error('Error rendering preview card:', error);
      console.error('Message content:', msg.text);
      return msg.text || 'Error displaying message';
    }
  };

  return (
    <div className="h-full bg-background-secondary rounded-xl shadow-lg overflow-hidden flex flex-col">
      {selectedClient ? (
        <>
          {/* Chat Header */}
          <div className="p-4 border-b border-border-light flex items-center justify-between bg-background-secondary">
            <div className="flex items-center gap-3">
              <Button
                variant="ghost"
                className="md:hidden -ml-2 text-text-primary hover:bg-background-tertiary p-2"
                onClick={onOpenMobileDrawer}
              >
                <Menu size={24} />
              </Button>
              
              <div className="w-10 h-10 rounded-full bg-accent-secondary flex items-center justify-center text-white font-bold">
                {(selectedClient.name || '?')[0].toUpperCase()}
              </div>
              <h2 className="text-lg font-bold text-text-primary truncate">
                {selectedClient.name || 'Unnamed Client'}
              </h2>
            </div>
            <Button
              variant="ghost"
              className="text-accent-primary hover:bg-background-tertiary p-2"
              onClick={() => onSelectClient(selectedClient.clientId)}
            >
              <Info size={20} />
            </Button>
          </div>

          {/* Chat Messages - adjust padding for mobile */}
          <div 
            ref={chatContainerRef} 
            className="flex-1 overflow-y-auto p-2 md:p-4 space-y-4"
            style={{ minHeight: '0' }}
          >
            {chatMessages.map((msg) => (
              <div
                key={msg.id}
                className={`flex ${msg.sender === 'client' ? '' : 'justify-end'}`}
              >
                <div className={`max-w-[85%] md:max-w-[70%] ${msg.sender === 'client' ? 'items-start' : 'items-end'}`}>
                  {(msg.type === 'workout' || msg.type === 'meal_preview') ? (
                    renderPreviewCard(msg)
                  ) : (
                    <>
                      <div
                        className={`p-3 rounded-2xl ${
                          msg.sender === 'client'
                            ? 'bg-background-tertiary text-text-primary rounded-tl-none'
                            : 'bg-accent-primary text-white rounded-tr-none'
                        }`}
                      >
                        {msg.text}
                      </div>
                      <div className={`text-xs text-text-light mt-1 ${
                        msg.sender === 'client' ? 'text-left' : 'text-right'
                      }`}>
                        {msg.time}
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>

          {/* Message Input - adjust padding for mobile */}
          <div className="p-2 md:p-4 border-t border-border-light bg-background-secondary mt-auto">
            <div className="flex items-center gap-2 md:gap-3">
              <Input
                type="text"
                placeholder="Type your message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="flex-grow bg-background-tertiary border-none rounded-full px-4 md:px-6 py-2 md:py-3 text-text-primary placeholder-text-light focus:ring-2 focus:ring-accent-primary"
              />
              <Button
                onClick={() => handleSendMessage(selectedClient.clientId, message, currentPendingMessageId)}
                className="bg-accent-primary hover:bg-hover-primary text-white rounded-full p-2 md:p-3 shadow-md transition-all duration-300"
              >
                <Send size={20} />
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className="flex-grow flex items-center justify-center">
          <Button
            variant="ghost"
            className="md:hidden absolute top-4 left-4 text-text-primary hover:bg-background-tertiary p-2"
            onClick={onOpenMobileDrawer}
          >
            <Menu size={24} />
          </Button>
          <div className="text-text-light text-center">
            <div className="mb-4">
              <MessageSquare className="w-12 h-12 mx-auto opacity-50" />
            </div>
            <p className="text-lg">Select a conversation to start messaging</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatArea; 