import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useSimplifiedAnalytics } from '../../hooks/useSimplifiedAnalytics';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/Card';
import { Select } from '../ui/Select';
import SimpleFunnelSummary from './SimpleFunnelSummary';
import { Tabs2 as Tabs, TabsList, TabsTrigger, TabsContent } from '../ui/Tabs';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, AreaChart, Area } from 'recharts';
import { format, parseISO, subDays } from 'date-fns';

const SimpleAnalyticsDashboard = () => {
  const { user } = useAuth();
  const [selectedForm, setSelectedForm] = useState('all');
  const [dateRange, setDateRange] = useState('7days');
  
  const { loading, error, analyticsData, availableForms } = useSimplifiedAnalytics(
    user?.uid,
    selectedForm,
    dateRange
  );
  
  // Prepare data for time series chart
  const prepareChartData = () => {
    if (!analyticsData?.filteredData?.byDate) return [];
    
    return Object.entries(analyticsData.filteredData.byDate)
      .map(([date, metrics]) => ({
        date,
        pageViews: metrics.pageViews || 0,
        formStarts: metrics.formStarts || 0,
        formCompletions: metrics.formCompletions || 0,
        callBookings: metrics.callBookings || 0,
        payments: metrics.payments || 0
      }))
      .sort((a, b) => a.date.localeCompare(b.date));
  };
  
  const chartData = analyticsData ? prepareChartData() : [];
  
  // Enhanced UI for loading state
  if (loading) {
    return (
      <div className="min-h-screen bg-background-primary pt-16">
        <div className="container mx-auto p-6">
          <div className="max-w-5xl mx-auto">
            <h1 className="text-3xl font-headers font-bold text-text-primary mb-8">Analytics Dashboard</h1>
            <div className="bg-background-secondary rounded-xl shadow-lg border border-border-light p-12 flex flex-col items-center justify-center h-96">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-accent-primary mb-6"></div>
              <p className="text-text-secondary font-medium text-lg">Loading your analytics data...</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  // Enhanced UI for error state
  if (error) {
    return (
      <div className="min-h-screen bg-background-primary pt-16">
        <div className="container mx-auto p-6">
          <div className="max-w-5xl mx-auto">
            <h1 className="text-3xl font-headers font-bold text-text-primary mb-8">Analytics Dashboard</h1>
            <Card className="border-2 border-red-200 bg-red-50">
              <CardContent className="p-8">
                <div className="flex flex-col items-center text-center">
                  <div className="w-16 h-16 rounded-full bg-red-100 flex items-center justify-center mb-4">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-red-500">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                    </svg>
                  </div>
                  <h3 className="text-xl font-medium text-red-800 mb-2">Unable to Load Analytics</h3>
                  <p className="text-red-600 mb-4">There was an error loading your data: {error}</p>
                  <button 
                    className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition"
                    onClick={() => window.location.reload()}
                  >
                    Try Again
                  </button>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    );
  }
  
  // Enhanced UI for empty state
  if (!analyticsData) {
    return (
      <div className="min-h-screen bg-background-primary pt-16">
        <div className="container mx-auto p-6">
          <div className="max-w-5xl mx-auto">
            <h1 className="text-3xl font-headers font-bold text-text-primary mb-8">Analytics Dashboard</h1>
            <Card className="border-2 border-border-light bg-background-secondary">
              <CardContent className="p-10 text-center">
                <div className="flex flex-col items-center">
                  <div className="w-20 h-20 rounded-full bg-background-tertiary flex items-center justify-center mb-6">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 text-accent-secondary">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
                    </svg>
                  </div>
                  <h3 className="text-2xl font-medium text-text-primary mb-3">No Analytics Data Available</h3>
                  <p className="text-text-light max-w-md mx-auto mb-6">Start collecting data by sharing your forms with clients. Check back here once your forms are receiving traffic.</p>
                  <button className="bg-accent-primary text-white font-medium px-6 py-3 rounded-lg hover:bg-hover-primary transition-all duration-300 shadow-md hover:shadow-lg">
                    Create & Share a Form
                  </button>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    );
  }
  
  // Date formatter for charts
  const formatDate = (date) => format(parseISO(date), 'MMM d');
  const formatTooltipDate = (date) => format(parseISO(date), 'MMM d, yyyy');
  
  // Color scheme for charts
  const chartColors = {
    pageViews: '#3B4664',      // Navy (accent-tertiary)
    formStarts: '#9F8C74',     // Taupe (accent-secondary)
    formCompletions: '#C4845F', // Terracotta (accent-primary)
    callBookings: '#6E6E6E',   // Light grey
    payments: '#4A3636'        // Deep brown (hover-primary)
  };
  
  return (
    <div className="min-h-screen bg-background-primary pt-16">
      <div className="container mx-auto p-6">
        <div className="max-w-5xl mx-auto">
          {/* Dashboard Header */}
          <div className="flex flex-col md:flex-row md:items-center justify-between mb-8">
            <div>
              <h1 className="text-3xl font-headers font-bold text-text-primary mb-2">Analytics Dashboard</h1>
              <p className="text-text-light">Track your form performance and user engagement</p>
            </div>
            
            {/* Date Range Selector - Styled as Pills */}
            <div className="mt-4 md:mt-0">
              <div className="flex space-x-2 bg-background-secondary p-1 rounded-lg border border-border-light">
                {[
                  { value: '7days', label: '7 Days' },
                  { value: '30days', label: '30 Days' },
                  { value: '90days', label: '90 Days' }
                ].map((option) => (
                  <button
                    key={option.value}
                    className={`px-4 py-2 rounded-md text-sm font-medium transition-all duration-200 ${
                      dateRange === option.value
                        ? 'bg-accent-primary text-white shadow-sm'
                        : 'text-text-secondary hover:bg-background-tertiary'
                    }`}
                    onClick={() => setDateRange(option.value)}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            </div>
          </div>
          
          {/* Form Selector and Summary Section */}
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 mb-8">
            {/* Form Selector Card */}
            <div className="lg:col-span-1">
              <Card className="h-full border-2 border-border-light bg-background-secondary hover:border-accent-secondary transition-all duration-200">
                <CardHeader className="pb-2">
                  <CardTitle className="text-lg text-text-primary">Form Analytics</CardTitle>
                </CardHeader>
                <CardContent>
                  <label htmlFor="form-select" className="block text-sm font-medium text-text-light mb-2">
                    Select Form
                  </label>
                  <Select
                    id="form-select"
                    value={selectedForm}
                    onChange={(e) => setSelectedForm(e.target.value)}
                    className="w-full rounded-md border-border-medium bg-background-tertiary text-text-primary"
                  >
                    {availableForms.map((form) => (
                      <option key={form.id} value={form.id}>
                        {form.title}
                      </option>
                    ))}
                  </Select>
                  
                  <div className="mt-6 space-y-3">
                    <p className="text-sm text-text-light">Viewing data for:</p>
                    <div className="p-3 bg-background-tertiary rounded-md border border-border-light">
                      <p className="font-medium text-text-primary">
                        {availableForms.find(f => f.id === selectedForm)?.title || 'All Forms'}
                      </p>
                      <p className="text-xs text-text-light mt-1">
                        {dateRange === '7days' ? 'Last 7 days' : 
                         dateRange === '30days' ? 'Last 30 days' : 'Last 90 days'}
                      </p>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
            
            {/* Funnel Summary Section - Takes 3/4 of the grid */}
            <div className="lg:col-span-3">
              <SimpleFunnelSummary data={analyticsData} />
            </div>
          </div>
          
          {/* Performance Over Time - Enhanced Card */}
          <Card className="border-2 border-border-light bg-background-secondary mb-8 overflow-hidden">
            <CardHeader className="border-b border-border-light bg-background-tertiary">
              <div className="flex justify-between items-center">
                <CardTitle className="text-xl font-headers text-text-primary">Performance Over Time</CardTitle>
                <span className="text-sm text-text-light bg-background-primary px-3 py-1 rounded-full">
                  {chartData.length} data points
                </span>
              </div>
            </CardHeader>
            <CardContent className="p-6">
              <Tabs defaultValue="overview" className="w-full">
                <TabsList className="mb-6 bg-background-tertiary border border-border-light rounded-lg p-1 w-full flex justify-center">
                  <TabsTrigger 
                    value="overview" 
                    className="data-[state=active]:bg-accent-primary data-[state=active]:text-white rounded-md px-6 py-2 transition-all duration-200"
                  >
                    Overview
                  </TabsTrigger>
                  <TabsTrigger 
                    value="forms" 
                    className="data-[state=active]:bg-accent-primary data-[state=active]:text-white rounded-md px-6 py-2 transition-all duration-200"
                  >
                    Form Activity
                  </TabsTrigger>
                  <TabsTrigger 
                    value="conversions" 
                    className="data-[state=active]:bg-accent-primary data-[state=active]:text-white rounded-md px-6 py-2 transition-all duration-200"
                  >
                    Conversions
                  </TabsTrigger>
                </TabsList>
                
                {/* Overview Tab - Using Area Chart for better visualization */}
                <TabsContent value="overview" className="mt-0">
                  <div className="h-96 p-4 bg-white rounded-lg">
                    <ResponsiveContainer width="100%" height="100%">
                      <AreaChart data={chartData} margin={{ top: 10, right: 30, left: 5, bottom: 30 }}>
                        <defs>
                          <linearGradient id="pageViewsGradient" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={chartColors.pageViews} stopOpacity={0.3}/>
                            <stop offset="95%" stopColor={chartColors.pageViews} stopOpacity={0}/>
                          </linearGradient>
                          <linearGradient id="formStartsGradient" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={chartColors.formStarts} stopOpacity={0.3}/>
                            <stop offset="95%" stopColor={chartColors.formStarts} stopOpacity={0}/>
                          </linearGradient>
                          <linearGradient id="paymentsGradient" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={chartColors.payments} stopOpacity={0.3}/>
                            <stop offset="95%" stopColor={chartColors.payments} stopOpacity={0}/>
                          </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" stroke="#E5E5E0" />
                        <XAxis 
                          dataKey="date" 
                          tickFormatter={formatDate}
                          tick={{ fill: '#6E6E6E', fontSize: 12 }}
                          axisLine={{ stroke: '#D1D1D1' }}
                          dy={10}
                        />
                        <YAxis 
                          tick={{ fill: '#6E6E6E', fontSize: 12 }}
                          axisLine={{ stroke: '#D1D1D1' }}
                        />
                        <Tooltip 
                          formatter={(value, name) => [value, name]}
                          labelFormatter={formatTooltipDate}
                          contentStyle={{ 
                            backgroundColor: 'rgba(255, 255, 255, 0.95)', 
                            borderColor: '#D1D1D1',
                            borderRadius: '8px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)'
                          }}
                        />
                        <Legend 
                          verticalAlign="top" 
                          height={36}
                          wrapperStyle={{ paddingTop: '10px' }}
                        />
                        <Area 
                          type="monotone" 
                          dataKey="pageViews" 
                          stackId="1"
                          stroke={chartColors.pageViews} 
                          fill="url(#pageViewsGradient)" 
                          name="Page Views" 
                          strokeWidth={2}
                          activeDot={{ r: 6, stroke: chartColors.pageViews, strokeWidth: 2, fill: 'white' }}
                        />
                        <Area 
                          type="monotone" 
                          dataKey="formStarts" 
                          stackId="2"
                          stroke={chartColors.formStarts} 
                          fill="url(#formStartsGradient)" 
                          name="Form Starts" 
                          strokeWidth={2}
                          activeDot={{ r: 6, stroke: chartColors.formStarts, strokeWidth: 2, fill: 'white' }}
                        />
                        <Area 
                          type="monotone" 
                          dataKey="payments" 
                          stackId="3"
                          stroke={chartColors.payments} 
                          fill="url(#paymentsGradient)" 
                          name="Payments" 
                          strokeWidth={2}
                          activeDot={{ r: 6, stroke: chartColors.payments, strokeWidth: 2, fill: 'white' }}
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </TabsContent>
                
                {/* Form Activity Tab - Enhanced LineChart */}
                <TabsContent value="forms" className="mt-0">
                  <div className="h-96 p-4 bg-white rounded-lg">
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={chartData} margin={{ top: 10, right: 30, left: 5, bottom: 30 }}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#E5E5E0" />
                        <XAxis 
                          dataKey="date" 
                          tickFormatter={formatDate}
                          tick={{ fill: '#6E6E6E', fontSize: 12 }}
                          axisLine={{ stroke: '#D1D1D1' }}
                          dy={10}
                        />
                        <YAxis 
                          tick={{ fill: '#6E6E6E', fontSize: 12 }}
                          axisLine={{ stroke: '#D1D1D1' }}
                        />
                        <Tooltip 
                          formatter={(value, name) => [value, name]}
                          labelFormatter={formatTooltipDate}
                          contentStyle={{ 
                            backgroundColor: 'rgba(255, 255, 255, 0.95)', 
                            borderColor: '#D1D1D1',
                            borderRadius: '8px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)'
                          }}
                        />
                        <Legend 
                          verticalAlign="top" 
                          height={36}
                          wrapperStyle={{ paddingTop: '10px' }}
                        />
                        <Line 
                          type="monotone" 
                          dataKey="formStarts" 
                          stroke={chartColors.formStarts} 
                          strokeWidth={3}
                          dot={{ stroke: chartColors.formStarts, strokeWidth: 2, r: 4, fill: 'white' }}
                          activeDot={{ r: 8, stroke: chartColors.formStarts, strokeWidth: 2, fill: 'white' }}
                          name="Form Starts" 
                        />
                        <Line 
                          type="monotone" 
                          dataKey="formCompletions" 
                          stroke={chartColors.formCompletions}
                          strokeWidth={3}
                          dot={{ stroke: chartColors.formCompletions, strokeWidth: 2, r: 4, fill: 'white' }}
                          activeDot={{ r: 8, stroke: chartColors.formCompletions, strokeWidth: 2, fill: 'white' }}
                          name="Form Completions" 
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </TabsContent>
                
                {/* Conversions Tab - Enhanced LineChart */}
                <TabsContent value="conversions" className="mt-0">
                  <div className="h-96 p-4 bg-white rounded-lg">
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={chartData} margin={{ top: 10, right: 30, left: 5, bottom: 30 }}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#E5E5E0" />
                        <XAxis 
                          dataKey="date" 
                          tickFormatter={formatDate}
                          tick={{ fill: '#6E6E6E', fontSize: 12 }}
                          axisLine={{ stroke: '#D1D1D1' }}
                          dy={10}
                        />
                        <YAxis 
                          tick={{ fill: '#6E6E6E', fontSize: 12 }}
                          axisLine={{ stroke: '#D1D1D1' }}
                        />
                        <Tooltip 
                          formatter={(value, name) => [value, name]}
                          labelFormatter={formatTooltipDate}
                          contentStyle={{ 
                            backgroundColor: 'rgba(255, 255, 255, 0.95)', 
                            borderColor: '#D1D1D1',
                            borderRadius: '8px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)'
                          }}
                        />
                        <Legend 
                          verticalAlign="top" 
                          height={36}
                          wrapperStyle={{ paddingTop: '10px' }}
                        />
                        <Line 
                          type="monotone" 
                          dataKey="formCompletions" 
                          stroke={chartColors.formCompletions} 
                          strokeWidth={3}
                          dot={{ stroke: chartColors.formCompletions, strokeWidth: 2, r: 4, fill: 'white' }}
                          activeDot={{ r: 8, stroke: chartColors.formCompletions, strokeWidth: 2, fill: 'white' }}
                          name="Form Completions" 
                        />
                        <Line 
                          type="monotone" 
                          dataKey="callBookings" 
                          stroke={chartColors.callBookings} 
                          strokeWidth={3}
                          dot={{ stroke: chartColors.callBookings, strokeWidth: 2, r: 4, fill: 'white' }}
                          activeDot={{ r: 8, stroke: chartColors.callBookings, strokeWidth: 2, fill: 'white' }}
                          name="Call Bookings" 
                        />
                        <Line 
                          type="monotone" 
                          dataKey="payments" 
                          stroke={chartColors.payments} 
                          strokeWidth={3}
                          dot={{ stroke: chartColors.payments, strokeWidth: 2, r: 4, fill: 'white' }}
                          activeDot={{ r: 8, stroke: chartColors.payments, strokeWidth: 2, fill: 'white' }}
                          name="Payments" 
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </TabsContent>
              </Tabs>
            </CardContent>
          </Card>
          
          {/* Additional Metrics Cards */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Completion Rate Card */}
            <Card className="border-2 border-border-light bg-background-secondary hover:shadow-lg transition-all duration-300">
              <CardContent className="p-6">
                <div className="flex flex-col items-center text-center">
                  <div className="w-12 h-12 bg-accent-tertiary bg-opacity-10 rounded-full flex items-center justify-center mb-4">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-accent-tertiary">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <h3 className="text-lg font-medium text-text-primary mb-1">Form Completion Rate</h3>
                  {analyticsData.filteredData?.metrics?.formStarts > 0 ? (
                    <>
                      <p className="text-3xl font-bold text-accent-tertiary mb-1">
                        {Math.round((analyticsData.filteredData.metrics.formCompletions / analyticsData.filteredData.metrics.formStarts) * 100)}%
                      </p>
                      <p className="text-text-light text-sm">
                        {analyticsData.filteredData.metrics.formCompletions} / {analyticsData.filteredData.metrics.formStarts} forms completed
                      </p>
                    </>
                  ) : (
                    <p className="text-text-light">No data available</p>
                  )}
                </div>
              </CardContent>
            </Card>
            
            {/* Conversion Rate Card */}
            <Card className="border-2 border-border-light bg-background-secondary hover:shadow-lg transition-all duration-300">
              <CardContent className="p-6">
                <div className="flex flex-col items-center text-center">
                  <div className="w-12 h-12 bg-accent-primary bg-opacity-10 rounded-full flex items-center justify-center mb-4">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-accent-primary">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941" />
                    </svg>
                  </div>
                  <h3 className="text-lg font-medium text-text-primary mb-1">Conversion to Call</h3>
                  {analyticsData.filteredData?.metrics?.formCompletions > 0 ? (
                    <>
                      <p className="text-3xl font-bold text-accent-primary mb-1">
                        {Math.round((analyticsData.filteredData.metrics.callBookings / analyticsData.filteredData.metrics.formCompletions) * 100)}%
                      </p>
                      <p className="text-text-light text-sm">
                        {analyticsData.filteredData.metrics.callBookings} calls from {analyticsData.filteredData.metrics.formCompletions} completions
                      </p>
                    </>
                  ) : (
                    <p className="text-text-light">No data available</p>
                  )}
                </div>
              </CardContent>
            </Card>
            
            {/* Payment Rate Card */}
            <Card className="border-2 border-border-light bg-background-secondary hover:shadow-lg transition-all duration-300">
              <CardContent className="p-6">
                <div className="flex flex-col items-center text-center">
                  <div className="w-12 h-12 bg-accent-secondary bg-opacity-10 rounded-full flex items-center justify-center mb-4">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-accent-secondary">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                    </svg>
                  </div>
                  <h3 className="text-lg font-medium text-text-primary mb-1">Payment Conversion</h3>
                  {analyticsData.filteredData?.metrics?.callBookings > 0 ? (
                    <>
                      <p className="text-3xl font-bold text-accent-secondary mb-1">
                        {Math.round((analyticsData.filteredData.metrics.payments / analyticsData.filteredData.metrics.callBookings) * 100)}%
                      </p>
                      <p className="text-text-light text-sm">
                        {analyticsData.filteredData.metrics.payments} payments from {analyticsData.filteredData.metrics.callBookings} calls
                      </p>
                    </>
                  ) : (
                    <p className="text-text-light">No data available</p>
                  )}
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleAnalyticsDashboard; 