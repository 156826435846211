// src/components/ClientWorkoutEdit.jsx

import React, { useState, useEffect } from 'react';
import { Button } from './ui/Button';
import { Input } from './ui/Input';
import { Trash2, Plus, ChevronUp, ChevronDown, X, FileText, Calendar, Flame, Dumbbell, Wind, Loader2, AlertCircle } from 'lucide-react';
import ExerciseSearch from './ExerciseSearch';
import { assignCustomWorkout, updateAssignedWorkout, deleteAssignedWorkout, getWorkoutTemplate, getWorkout } from '../services/workoutService';
import { useAuth } from '../contexts/AuthContext';
import MessageSchedule from './MessageSchedule';
import ExpandedSetsEditor from './ExpandedSetsEditor';
import { createCustomExercise, modifyExercise, getAllExercises, getExerciseById } from '../services/exerciseService';
import ModifyExerciseOverlay from './overlays/ModifyExerciseOverlay';
import CreateExerciseOverlay from './overlays/CreateExerciseOverlay';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Badge } from './ui/Badge';
import { Alert } from './ui/Alert';

const ClientWorkoutEdit = () => {
  console.log('ClientWorkoutEdit - Component initialized');
  console.log('ClientWorkoutEdit - Imported functions:', {
    getExerciseById: typeof getExerciseById,
    createCustomExercise: typeof createCustomExercise,
    modifyExercise: typeof modifyExercise,
    getAllExercises: typeof getAllExercises
  });

  const navigate = useNavigate();
  const { workoutId } = useParams();
  const [searchParams] = useSearchParams();
  const clientId = searchParams.get('clientId');
  const date = searchParams.get('date') ? new Date(searchParams.get('date')) : null;
  const templateId = searchParams.get('templateId');

  console.log('ClientWorkoutEdit - Initial params:', {
    workoutId,
    clientId,
    date: date?.toISOString(),
    templateId
  });

  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [messageSendTime, setMessageSendTime] = useState(null);
  const [workoutData, setWorkoutData] = useState({
    name: '',
    description: '',
    headerImageUrl: '',
    workoutStructure: [],
    exercises: [],
    coachId: user?.uid || '',
    userId: clientId || '',
    templateId: templateId || '',
    programId: '',
    programWeek: 1,
    programDay: 1,
    isOverride: false,
    messageStatus: 'pending',
    status: 'assigned',
    exerciseCount: 0,
    week: 1,
    duration: 0
  });
  const [expandedExercise, setExpandedExercise] = useState(null);
  const [showExerciseEdit, setShowExerciseEdit] = useState(false);
  const [exerciseToEdit, setExerciseToEdit] = useState(null);
  const [showModifyOverlay, setShowModifyOverlay] = useState(false);
  const [showCreateOverlay, setShowCreateOverlay] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [allExercises, setAllExercises] = useState([]);
  const [isRecurring, setIsRecurring] = useState(false);
  const [recurringWeeks, setRecurringWeeks] = useState(1);
  const [selectedDays, setSelectedDays] = useState({
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false
  });
  const [template, setTemplate] = useState(null);
  const [existingWorkout, setExistingWorkout] = useState(null);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  useEffect(() => {
    const fetchExercises = async () => {
      try {
        const exercises = await getAllExercises(user.uid);
        setAllExercises(exercises);
      } catch (error) {
        console.error('Error fetching exercises:', error);
      }
    };

    fetchExercises();
  }, [user.uid]);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        
        if (workoutId !== 'new' && workoutId) {
          // Load existing workout
          const workout = await getWorkout(clientId, workoutId);
          console.log('ClientWorkoutEdit - Loaded existing workout:', workout);
          
          setExistingWorkout(workout);
          const formattedWorkout = {
            name: workout.name,
            description: workout.description,
            headerImageUrl: workout.headerImageUrl,
            workoutStructure: workout.workoutStructure || [],
            exercises: workout.exercises.map(ex => {
              console.log('ClientWorkoutEdit - Processing exercise:', ex);
              return {
                id: ex.id,
                exerciseId: ex.exerciseId,
                workoutStructureId: ex.workoutStructureId,
                isCustom: ex.isCustom || false,
                order: ex.order,
                type: ex.type || 'weight',
                sets: ex.sets.map((set, index) => {
                  console.log('ClientWorkoutEdit - Processing set:', set);
                  return {
                    id: set.id || `set-${Date.now()}-${index}`,
                    setNumber: set.setNumber || index + 1,
                    type: set.type || 'weight',
                    targetValue: set.targetValue || 0,
                    actualValue: set.actualValue || 0,
                    reps: set.reps || { min: 8, max: 12 },
                    actualReps: set.actualReps || { min: 8, max: 12 },
                    targetRPE: set.targetRPE || 7,
                    actualRPE: set.actualRPE || 7,
                    completed: set.completed || false,
                    setCategory: set.setCategory || 'working',
                    progressionRule: set.progressionRule || {
                      type: 'percentage',
                      value: 5
                    },
                    // Add percentage fields based on set category
                    ...(set.setCategory === 'warmup' && set.warmupPercentage ? { warmupPercentage: set.warmupPercentage } : {}),
                    ...(set.setCategory === 'dropset' && set.dropsetPercentage ? { dropsetPercentage: set.dropsetPercentage } : {}),
                    ...(set.setCategory === 'backoff' && set.backoffPercentage ? { backoffPercentage: set.backoffPercentage } : {})
                  };
                }),
                notes: ex.notes || '',
                rest: ex.rest || 60,
                selectedAlternativeId: ex.selectedAlternativeId,
                previousWeekPerformance: ex.previousWeekPerformance
              };
            }),
            coachId: user.uid,
            userId: clientId,
            templateId: workout.templateId || '',
            programId: workout.programId || '',
            programWeek: workout.programWeek || 1,
            programDay: workout.programDay || 1,
            isOverride: workout.isOverride || false,
            messageStatus: workout.messageStatus || 'pending',
            status: workout.status || 'assigned',
            exerciseCount: workout.exerciseCount || workout.exercises.length,
            week: workout.week || 1,
            duration: workout.duration || 0,
            createdAt: workout.createdAt,
            updatedAt: workout.updatedAt,
            startedAt: workout.startedAt,
            completedAt: workout.completedAt
          };
          console.log('ClientWorkoutEdit - Formatted workout data:', formattedWorkout);
          setWorkoutData(formattedWorkout);
        } else if (templateId) {
          // Load template
          const template = await getWorkoutTemplate(user.uid, templateId);
          setTemplate(template);
          setWorkoutData({
            name: template.name,
            description: template.description,
            headerImageUrl: template.headerImageUrl,
            workoutStructure: template.workoutStructure || [],
            exercises: template.exercises.map(ex => ({
              id: ex.id || `exercise-${Date.now()}`,
              exerciseId: ex.exerciseId,
              workoutStructureId: ex.workoutStructureId,
              isCustom: ex.isCustom || false,
              order: ex.order,
              sets: ex.sets.map((set, index) => ({
                id: set.id || `set-${Date.now()}-${index}`,
                setNumber: set.setNumber || index + 1,
                type: set.type || 'weight',
                targetValue: set.targetValue || 0,
                actualValue: 0,
                reps: set.reps || { min: 8, max: 12 },
                actualReps: set.reps || { min: 8, max: 12 },
                targetRPE: set.targetRPE || 7,
                actualRPE: set.targetRPE || 7,
                completed: false,
                setCategory: set.setCategory || 'working',
                progressionRule: set.progressionRule || {
                  type: 'percentage',
                  value: 5
                },
                // Add percentage fields based on set category
                ...(set.setCategory === 'warmup' && set.warmupPercentage ? { warmupPercentage: set.warmupPercentage } : {}),
                ...(set.setCategory === 'dropset' && set.dropsetPercentage ? { dropsetPercentage: set.dropsetPercentage } : {}),
                ...(set.setCategory === 'backoff' && set.backoffPercentage ? { backoffPercentage: set.backoffPercentage } : {})
              })),
              notes: ex.notes || '',
              rest: ex.rest || 60,
              selectedAlternativeId: null,
              previousWeekPerformance: null
            })),
            coachId: user.uid,
            userId: clientId,
            templateId: template.id,
            programId: '',
            programWeek: 1,
            programDay: 1,
            isOverride: false,
            messageStatus: 'pending',
            status: 'assigned',
            exerciseCount: template.exerciseCount || template.exercises.length,
            week: 1,
            duration: template.duration || 0,
            createdAt: new Date(),
            updatedAt: new Date()
          });
        }
      } catch (error) {
        console.error('ClientWorkoutEdit - Error loading data:', error);
        // Show error toast or notification here
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [workoutId, templateId, clientId, user.uid]);

  const handleClose = () => {
    navigate(`/schedule?clientId=${clientId}`);
  };

  const handleWorkoutAssigned = () => {
    navigate(`/schedule?clientId=${clientId}`);
  };

  const addSection = (type) => {
    const newSection = {
      id: `${type}-${Date.now()}`,
      type,
      name: type === 'warmup' ? 'Warm Up'
           : type === 'cooldown' ? 'Cool Down'
           : `Block ${String.fromCharCode(65 + workoutData.workoutStructure.filter(s => s.type === 'block').length)}`,
      order: workoutData.workoutStructure.length,
      executionStyle: 'sequential',
      circuitRounds: type === 'block' ? 1 : undefined
    };

    setWorkoutData(prev => {
      let newWorkoutStructure;
      if (type === 'warmup') {
        newWorkoutStructure = [newSection, ...prev.workoutStructure];
      } else {
        newWorkoutStructure = [...prev.workoutStructure, newSection];
      }
      return { ...prev, workoutStructure: newWorkoutStructure };
    });
  };

  const removeSection = (sectionId) => {
    setWorkoutData(prev => {
      const filteredExercises = prev.exercises.filter(e => e.workoutStructureId !== sectionId);
      const filteredWorkoutStructure = prev.workoutStructure.filter(s => s.id !== sectionId);
      return { ...prev, workoutStructure: filteredWorkoutStructure, exercises: filteredExercises };
    });
  };

  const addExercise = (sectionId) => {
    const newExercise = {
      id: `exercise-${Date.now()}`,
      exerciseId: '',
      workoutStructureId: sectionId,
      isCustom: false,
      order: workoutData.exercises.filter(e => e.workoutStructureId === sectionId).length,
      type: 'weight', // Default type
      sets: [
        // Default set
        {
          id: `set-${Date.now()}-1`,
          setNumber: 1,
          type: 'weight',
          targetValue: 0,
          actualValue: 0,
          reps: { min: 8, max: 12 },
          actualReps: { min: 8, max: 12 },
          targetRPE: 7,
          actualRPE: 7,
          completed: false,
          setCategory: 'working',
          warmupPercentage: 0.5
        }
      ],
      notes: '',
      rest: 60,
      selectedAlternativeId: null,
      previousWeekPerformance: null
    };

    setWorkoutData(prev => ({
      ...prev,
      exercises: [...prev.exercises, newExercise],
      exerciseCount: prev.exerciseCount + 1
    }));
  };
  

  const removeExercise = (sectionId, order) => {
    setWorkoutData(prev => ({
      ...prev,
      exercises: prev.exercises.filter(e => !(e.workoutStructureId === sectionId && e.order === order))
    }));
  };

  const updateExercise = (sectionId, exerciseId, field, value) => {
    console.log('ClientWorkoutEdit - updateExercise called:', { sectionId, exerciseId, field, value });
    
    setWorkoutData(prev => {
      const updatedData = {
        ...prev,
        exercises: prev.exercises.map(ex => {
          if (ex.workoutStructureId === sectionId && ex.id === exerciseId) {
            if (field === 'exerciseId') {
              const selectedExercise = allExercises.find(e => e.id === value);
              console.log('ClientWorkoutEdit - Selected exercise details:', selectedExercise);
              
              if (selectedExercise) {
                // Get the type from the first set
                const setType = ex.sets[0]?.type || 'weight';
                console.log('ClientWorkoutEdit - Using set type:', setType);
                
                // Create new sets with the correct type
                const defaultSets = ex.sets.map((set, index) => {
                  console.log('ClientWorkoutEdit - Converting set to type:', setType);
                  
                  const baseSet = {
                    ...set,
                    type: setType,
                    targetValue: setType === 'time' ? 30 : 0,
                  };

                  if (setType === 'time') {
                    console.log('ClientWorkoutEdit - Configuring time-based set');
                    delete baseSet.reps;
                    delete baseSet.actualReps;
                  } else if (setType === 'reps_to_failure') {
                    console.log('ClientWorkoutEdit - Configuring reps-to-failure set');
                    baseSet.reps = 'failure';
                    baseSet.actualReps = 'failure';
                  } else {
                    console.log('ClientWorkoutEdit - Configuring weight-based set');
                    if (!baseSet.reps) {
                      baseSet.reps = { min: 8, max: 12 };
                    }
                  }

                  if (setType === 'weight' && index === 0) {
                    baseSet.warmupPercentage = 0.5;
                  }

                  console.log('ClientWorkoutEdit - Final set configuration:', baseSet);
                  return baseSet;
                });

                const updatedExercise = {
                  ...ex,
                  exerciseId: value,
                  sets: defaultSets
                };
                console.log('ClientWorkoutEdit - Final exercise update:', updatedExercise);
                return updatedExercise;
              }
            }
            return { ...ex, [field]: value };
          }
          return ex;
        })
      };
      console.log('ClientWorkoutEdit - Final workout data update:', updatedData);
      return updatedData;
    });
  };

  const handleAssignOrUpdate = async () => {
    setLoading(true);
    try {
      console.log('ClientWorkoutEdit - Starting workout update/assignment:', {
        isExisting: !!existingWorkout,
        clientId,
        workoutId: existingWorkout?.id,
        templateId,
        date
      });

      if (!clientId) {
        throw new Error('Client ID is required');
      }

      // Validate exercises
      if (!workoutData.exercises || workoutData.exercises.length === 0) {
        throw new Error('Workout must have at least one exercise');
      }

      // Get exercise details for all exercises
      console.log('ClientWorkoutEdit - Getting exercise details for exercises:', {
        exerciseCount: workoutData.exercises.length,
        exercises: workoutData.exercises.map(ex => ({
          id: ex.id,
          exerciseId: ex.exerciseId
        }))
      });

      const exerciseDetails = await Promise.all(
        workoutData.exercises.map(async (exercise, index) => {
          console.log(`ClientWorkoutEdit - Fetching details for exercise ${index}:`, {
            id: exercise.id,
            exerciseId: exercise.exerciseId,
            coachId: user.uid
          });
          
          try {
            const details = await getExerciseById(exercise.exerciseId, user.uid);
            console.log(`ClientWorkoutEdit - Got details for exercise ${index}:`, {
              found: !!details,
              type: details?.type
            });
            return details;
          } catch (error) {
            console.error(`ClientWorkoutEdit - Error fetching exercise ${index} details:`, error);
            throw error;
          }
        })
      );

      console.log('ClientWorkoutEdit - Got all exercise details:', {
        count: exerciseDetails.length,
        details: exerciseDetails.map(d => ({
          id: d?.id,
          type: d?.type
        }))
      });

      // Prepare the workout data with only the fields that should be updated
      const updatedFields = {
        name: workoutData.name || 'Untitled Workout',
        description: workoutData.description || '',
        workoutStructure: workoutData.workoutStructure || [],
        exercises: workoutData.exercises.map((exercise, index) => {
          console.log('ClientWorkoutEdit - Processing exercise:', {
            id: exercise.id,
            exerciseId: exercise.exerciseId,
            type: exercise.type,
            setsCount: exercise.sets?.length
          });

          // Get the exercise type from exercise details
          const details = exerciseDetails[index];
          if (!details) {
            console.error(`No details found for exercise ${exercise.exerciseId} at index ${index}`);
            throw new Error(`Could not find details for exercise ${exercise.exerciseId}`);
          }

          if (!details.type) {
            console.error(`No type found in details for exercise ${exercise.exerciseId}:`, details);
            throw new Error(`Exercise type is undefined for exercise ${exercise.exerciseId}`);
          }

          return {
            id: exercise.id,
            exerciseId: exercise.exerciseId || '',
            workoutStructureId: exercise.workoutStructureId,
            isCustom: exercise.isCustom || false,
            order: exercise.order || 0,
            type: details.type, // Use type from exercise details
            sets: (exercise.sets || []).map((set, setIndex) => {
              console.log(`ClientWorkoutEdit - Processing set ${setIndex} for exercise ${exercise.exerciseId}:`, {
                type: set.type,
                targetValue: set.targetValue,
                reps: set.reps,
                category: set.setCategory,
                exerciseType: details.type
              });

              if (!set) {
                console.error(`Invalid set at index ${setIndex} for exercise ${exercise.exerciseId}`);
                throw new Error(`Invalid set data at index ${setIndex}`);
              }

              const baseSet = {
                id: set.id || `${exercise.id}_set_${setIndex}`,
                type: details.type, // Use type from exercise details
                targetValue: set.targetValue || 0,
                actualValue: set.actualValue || 0,
                reps: set.reps || null,
                actualReps: set.actualReps || null,
                targetRPE: set.targetRPE || null,
                actualRPE: set.actualRPE || null,
                completed: false,
                setCategory: set.setCategory || 'working'
              };

              // Add percentage fields based on set category
              if (details.type === 'weight') {
                if (set.setCategory === 'warmup') {
                  baseSet.warmupPercentage = set.warmupPercentage || 0.5;
                } else if (set.setCategory === 'dropset') {
                  baseSet.dropsetPercentage = set.dropsetPercentage || 0.8;
                } else if (set.setCategory === 'backoff') {
                  baseSet.backoffPercentage = set.backoffPercentage || 0.9;
                }
              }

              return baseSet;
            }),
            notes: exercise.notes || '',
            rest: exercise.rest || 60,
            selectedAlternativeId: exercise.selectedAlternativeId || null,
            previousWeekPerformance: exercise.previousWeekPerformance || null
          };
        })
      };

      console.log('ClientWorkoutEdit - Prepared update data:', {
        fields: Object.keys(updatedFields),
        exerciseCount: updatedFields.exercises.length,
        exercises: updatedFields.exercises.map(ex => ({
          id: ex.id,
          type: ex.type,
          setCount: ex.sets.length
        }))
      });

      if (existingWorkout) {
        // Update existing workout - only pass the fields that should be updated
        await updateAssignedWorkout(user.uid, clientId, existingWorkout.id, updatedFields);
        console.log('ClientWorkoutEdit - Successfully updated existing workout');
      } else {
        // Create new workout - include all necessary fields
        const newWorkoutData = {
          ...updatedFields,
          coachId: user.uid,
          userId: clientId,
          scheduledDate: date ? new Date(date) : new Date(),
          messageDeliveryDate: messageSendTime || (date ? new Date(date) : new Date()),
          messageStatus: 'pending',
          status: 'assigned',
          createdAt: new Date(),
          updatedAt: new Date(),
          templateId: templateId || '',
          programId: '',
          programWeek: 1,
          programDay: 1,
          isOverride: false,
          week: 1,
          duration: 0
        };

        console.log('ClientWorkoutEdit - Creating new workout:', {
          clientId,
          templateId,
          fields: Object.keys(newWorkoutData),
          exercises: newWorkoutData.exercises.map(ex => ({
            id: ex.id,
            type: ex.type,
            setCount: ex.sets.length
          }))
        });

        await assignCustomWorkout(newWorkoutData, clientId, new Date(date), messageSendTime);
        console.log('ClientWorkoutEdit - Successfully created new workout');
      }
      
      // Navigate back to calendar with client ID
      navigate(`/schedule?clientId=${clientId}`);
    } catch (error) {
      console.error('ClientWorkoutEdit - Error:', error);
      alert(error.message || 'Failed to assign workout. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Group exercises by sections
  const sectionsWithExercises = workoutData.workoutStructure.map(sec => ({
    ...sec,
    exercises: workoutData.exercises
      .filter(ex => ex.workoutStructureId === sec.id)
      .sort((a, b) => a.order - b.order)
  }));

  const handleDelete = async () => {
    setShowDeleteAlert(true);
  };

  const confirmDelete = async () => {
    setLoading(true);
    try {
      await deleteAssignedWorkout(user.uid, clientId, workoutId);
      navigate(`/schedule?clientId=${clientId}`);
    } catch (error) {
      console.error('Error deleting workout:', error);
      alert('Failed to delete workout. Please try again.');
    } finally {
      setLoading(false);
      setShowDeleteAlert(false);
    }
  };

  const handleEditExercise = (exercise) => {
    setSelectedExercise(exercise);
    if (exercise) {
      setShowModifyOverlay(true);
    } else {
      setShowCreateOverlay(true);
    }
  };

  const handleSaveExercise = async (formData) => {
    try {
      let savedExercise;
      if (selectedExercise) {
        // Modifying existing exercise
        savedExercise = await modifyExercise(user.uid, selectedExercise.id, formData);
        // Update allExercises
        setAllExercises(prev => prev.map(ex => 
          ex.id === selectedExercise.id 
            ? { ...ex, ...formData, formCues: { instructions: formData.instructions, commonMistakes: formData.commonMistakes }, defaultVideoUrl: formData.mediaUrl } 
            : ex
        ));
      } else {
        // Creating new exercise
        savedExercise = await createCustomExercise(user.uid, formData);
        setAllExercises(prev => [...prev, {
          ...savedExercise,
          name: formData.name,
          instructions: formData.instructions,
          commonMistakes: formData.commonMistakes,
          mediaUrl: formData.mediaUrl,
          isCustom: true
        }]);
      }
  
      // Update workoutData exercises if any use this exerciseId
      setWorkoutData(prev => ({
        ...prev,
        exercises: prev.exercises.map(exercise => 
          exercise.exerciseId === (selectedExercise ? selectedExercise.id : savedExercise.id)
            ? { ...exercise, name: formData.name, instructions: formData.instructions, commonMistakes: formData.commonMistakes, mediaUrl: formData.mediaUrl }
            : exercise
        )
      }));
  
      // Close modify or create overlay only (not the entire ClientWorkoutEditOverlay)
      setShowModifyOverlay(false);
      setShowCreateOverlay(false);
      setSelectedExercise(null);
    } catch (error) {
      console.error('Error saving exercise:', error);
      alert('Failed to save exercise. Please try again.');
    }
  };
  

  const RecurringOptions = () => (
    <div className="mb-6 space-y-4 bg-neutral-800 p-4 rounded-lg">
      <div className="flex items-center justify-between">
        <label className="text-white font-medium">Make this a recurring workout?</label>
        <input
          type="checkbox"
          checked={isRecurring}
          onChange={(e) => setIsRecurring(e.target.checked)}
          className="h-4 w-4 rounded border-gray-300"
        />
      </div>
      
      {isRecurring && (
        <>
          <div className="space-y-2">
            <label className="text-white font-medium">Repeat for how many weeks?</label>
            <input
              type="number"
              min="1"
              max="52"
              value={recurringWeeks}
              onChange={(e) => setRecurringWeeks(parseInt(e.target.value))}
              className="w-full bg-neutral-700 text-white rounded p-2"
            />
          </div>
          
          <div className="space-y-2">
            <label className="text-white font-medium">Select days to repeat on:</label>
            <div className="grid grid-cols-7 gap-2">
              {Object.keys(selectedDays).map((day) => (
                <button
                  key={day}
                  onClick={() => setSelectedDays(prev => ({
                    ...prev,
                    [day]: !prev[day]
                  }))}
                  className={`p-2 rounded text-sm ${
                    selectedDays[day] 
                      ? 'bg-blue-600 text-white' 
                      : 'bg-neutral-700 text-gray-300'
                  }`}
                >
                  {day.slice(0, 3)}
                </button>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );

  const handleSave = async () => {
    if (!isRecurring) {
      // Existing single workout save logic
      // ... your current save logic ...
    } else {
      // Handle recurring workouts
      const startDate = new Date(date);
      const assignments = [];
      
      for (let week = 0; week < recurringWeeks; week++) {
        Object.entries(selectedDays).forEach(([day, isSelected]) => {
          if (isSelected) {
            const dayIndex = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].indexOf(day);
            const currentDate = new Date(startDate);
            currentDate.setDate(startDate.getDate() + (week * 7) + ((7 + dayIndex - startDate.getDay()) % 7));
            
            assignments.push({
              ...workoutData,
              scheduledTime: currentDate
            });
          }
        });
      }

      // Save all assignments
      try {
        await Promise.all(assignments.map(assignment => 
          assignCustomWorkout(assignment, clientId, assignment.scheduledTime)
        ));
        handleWorkoutAssigned();
      } catch (error) {
        console.error('Error assigning recurring workouts:', error);
      }
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-background-primary pt-20">
        <div className="container mx-auto p-4 max-w-6xl">
          <Card className="bg-background-secondary shadow-lg">
            <div className="p-8 flex items-center justify-center">
              <div className="space-y-4 text-center">
                <Loader2 className="h-8 w-8 animate-spin mx-auto text-accent-primary" />
                <p className="text-text-primary">Loading workout data...</p>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }

  if (!workoutData && !template) {
    return (
      <div className="min-h-screen bg-background-primary pt-20">
        <div className="container mx-auto p-4 max-w-6xl">
          <Card className="bg-background-secondary shadow-lg">
            <div className="p-8 flex items-center justify-center">
              <div className="space-y-4 text-center">
                <AlertCircle className="h-8 w-8 mx-auto text-red-500" />
                <p className="text-text-primary">Failed to load workout data</p>
                <Button onClick={() => navigate(-1)} variant="outline">
                  Go Back
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background-primary pt-20">
      <div className="container mx-auto p-4 max-w-6xl">
        <Card className="bg-background-secondary shadow-lg">
          {/* Header */}
          <div className="border-b border-border-light/20 p-6">
            <h2 className="text-2xl font-semibold text-text-primary">
              {existingWorkout ? 'Edit Workout' : 'Assign New Workout'}
            </h2>
          </div>

          {/* Content */}
          <div className="p-6">
            {/* Left Column - Basic Info */}
            <div className="col-span-4 space-y-6">
              <Card>
                <CardHeader>
                  <CardTitle className="text-xl flex items-center gap-2">
                    <FileText className="h-5 w-5 text-accent-primary" />
                    Basic Information
                  </CardTitle>
                </CardHeader>
                <CardContent className="space-y-4">
                  <div className="space-y-2">
                    <label className="text-sm font-medium text-text-primary">Workout Name</label>
                    <Input
                      placeholder="Enter workout name"
                      value={workoutData.name || ''}
                      onChange={(e) => setWorkoutData(prev => ({ ...prev, name: e.target.value }))}
                      className="bg-background-tertiary border-border-light focus:border-accent-primary"
                    />
                  </div>
                  <div className="space-y-2">
                    <label className="text-sm font-medium text-text-primary">Description</label>
                    <textarea
                      placeholder="Enter workout description"
                      value={workoutData.description || ''}
                      onChange={(e) => setWorkoutData(prev => ({ ...prev, description: e.target.value }))}
                      className="w-full min-h-[100px] rounded-lg bg-background-tertiary border-border-light focus:border-accent-primary p-3"
                    />
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle className="text-xl flex items-center gap-2">
                    <Calendar className="h-5 w-5 text-accent-primary" />
                    Schedule
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <MessageSchedule
                    scheduledDate={date}
                    messageSendTime={messageSendTime}
                    onMessageSendTimeChange={setMessageSendTime}
                  />
                </CardContent>
              </Card>
            </div>

            {/* Right Column - Sections & Exercises */}
            <div className="col-span-8 space-y-6">
              {/* Section Controls */}
              <div className="flex gap-2">
                {!workoutData.workoutStructure.some(s => s.type === 'warmup') && (
                  <Button
                    onClick={() => addSection('warmup')}
                    className="bg-accent-primary/10 text-accent-primary hover:bg-accent-primary/20"
                  >
                    <Plus className="h-4 w-4 mr-2" />
                    Add Warmup
                  </Button>
                )}
                {!workoutData.workoutStructure.some(s => s.type === 'main') && (
                  <Button
                    onClick={() => addSection('main')}
                    className="bg-accent-primary/10 text-accent-primary hover:bg-accent-primary/20"
                  >
                    <Plus className="h-4 w-4 mr-2" />
                    Add Main Section
                  </Button>
                )}
                {!workoutData.workoutStructure.some(s => s.type === 'cooldown') && (
                  <Button
                    onClick={() => addSection('cooldown')}
                    className="bg-accent-primary/10 text-accent-primary hover:bg-accent-primary/20"
                  >
                    <Plus className="h-4 w-4 mr-2" />
                    Add Cooldown
                  </Button>
                )}
              </div>

              {/* Sections */}
              {sectionsWithExercises.map((section) => (
                <Card key={section.id}>
                  <CardHeader className="flex flex-row items-center justify-between">
                    <CardTitle className="text-xl capitalize flex items-center gap-2">
                      {section.type === 'warmup' && <Flame className="h-5 w-5 text-orange-500" />}
                      {section.type === 'main' && <Dumbbell className="h-5 w-5 text-accent-primary" />}
                      {section.type === 'cooldown' && <Wind className="h-5 w-5 text-blue-500" />}
                      {section.name}
                    </CardTitle>
                    <Button
                      onClick={() => removeSection(section.id)}
                      variant="ghost"
                      className="text-red-500 hover:text-red-400 hover:bg-red-500/10"
                    >
                      <Trash2 className="h-4 w-4" />
                    </Button>
                  </CardHeader>

                  <CardContent className="space-y-4">
                    {/* Exercises */}
                    {section.exercises.map((exercise) => (
                      <div 
                        key={exercise.id}
                        className="border border-border-light rounded-lg p-4 space-y-4"
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex-1 mr-4">
                            <ExerciseSearch
                              value={exercise.exerciseId}
                              onChange={(value) => updateExercise(section.id, exercise.id, 'exerciseId', value)}
                              onEditExercise={handleEditExercise}
                              allExercises={allExercises}
                              className="w-full"
                            />
                          </div>
                          <div className="flex items-center gap-2">
                            <Button
                              onClick={() => setExpandedExercise(expandedExercise === exercise.id ? null : exercise.id)}
                              variant="ghost"
                              className="text-accent-primary hover:bg-accent-primary/10"
                            >
                              {expandedExercise === exercise.id ? (
                                <ChevronUp className="h-4 w-4" />
                              ) : (
                                <ChevronDown className="h-4 w-4" />
                              )}
                            </Button>
                            <Button
                              onClick={() => removeExercise(section.id, exercise.id)}
                              variant="ghost"
                              className="text-red-500 hover:text-red-400 hover:bg-red-500/10"
                            >
                              <Trash2 className="h-4 w-4" />
                            </Button>
                          </div>
                        </div>

                        {expandedExercise === exercise.id && (
                          <div className="mt-4 space-y-4">
                            <div className="grid grid-cols-2 gap-4">
                              <div className="space-y-2">
                                <label className="text-sm font-medium text-text-primary">Rest (seconds)</label>
                                <Input
                                  type="number"
                                  value={exercise.rest}
                                  onChange={(e) => updateExercise(section.id, exercise.id, 'rest', parseInt(e.target.value))}
                                  className="bg-background-tertiary border-border-light"
                                  min="0"
                                />
                              </div>
                              <div className="space-y-2">
                                <label className="text-sm font-medium text-text-primary">Notes</label>
                                <Input
                                  value={exercise.notes || ''}
                                  onChange={(e) => updateExercise(section.id, exercise.id, 'notes', e.target.value)}
                                  className="bg-background-tertiary border-border-light"
                                  placeholder="Exercise notes"
                                />
                              </div>
                            </div>

                            <div className="space-y-2">
                              <div className="flex items-center justify-between">
                                <label className="text-sm font-medium text-text-primary">Exercise Type</label>
                                <span className="text-sm text-text-secondary capitalize">{exercise.type || 'weight'}</span>
                              </div>
                              <div className="flex items-center gap-2">
                                {exercise.type === 'weight' && (
                                  <Badge variant="secondary">Weight Based</Badge>
                                )}
                                {exercise.type === 'time' && (
                                  <Badge variant="secondary">Time Based</Badge>
                                )}
                                {exercise.type === 'reps_to_failure' && (
                                  <Badge variant="secondary">Reps to Failure</Badge>
                                )}
                              </div>
                            </div>

                            <ExpandedSetsEditor
                              sets={exercise.sets}
                              onChange={(sets) => updateExercise(section.id, exercise.id, 'sets', sets)}
                              exerciseType={exercise.type || 'weight'}
                            />

                            {exercise.previousWeekPerformance && (
                              <div className="bg-background-tertiary p-4 rounded-lg">
                                <h4 className="text-sm font-medium text-text-primary mb-2">Previous Performance</h4>
                                <div className="space-y-2">
                                  {exercise.previousWeekPerformance.sets.map((set, idx) => (
                                    <div key={idx} className="flex items-center justify-between text-sm">
                                      <span>Set {idx + 1}:</span>
                                      <span>
                                        {exercise.type === 'time' ? (
                                          `${set.actualValue} sec`
                                        ) : exercise.type === 'reps_to_failure' ? (
                                          set.actualReps === 'failure' ? 'To Failure' : `${set.actualReps} reps`
                                        ) : (
                                          `${set.actualValue}lbs × ${typeof set.actualReps === 'object' ? 
                                            `${set.actualReps.min}-${set.actualReps.max}` : 
                                            set.actualReps}`
                                        )}
                                        {set.actualRPE && ` @RPE ${set.actualRPE}`}
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ))}

                    {/* Add Exercise Button */}
                    <Button
                      onClick={() => addExercise(section.id)}
                      className="w-full bg-accent-primary/10 hover:bg-accent-primary/20 text-accent-primary border-2 border-dashed border-accent-primary/30"
                    >
                      <Plus className="h-4 w-4 mr-2" />
                      Add Exercise to {section.name}
                    </Button>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>

          {/* Footer */}
          <div className="border-t border-border-light/20 p-6 bg-background-tertiary">
            <div className="flex justify-end gap-4">
              {existingWorkout && (
                <Button
                  onClick={handleDelete}
                  disabled={loading}
                  variant="destructive"
                  className="bg-red-500 hover:bg-red-600"
                >
                  {loading ? 'Deleting...' : 'Delete Workout'}
                </Button>
              )}
              <Button
                onClick={handleAssignOrUpdate}
                disabled={loading}
                className="bg-accent-primary hover:bg-accent-primary/90"
              >
                {loading ? 'Saving...' : existingWorkout ? 'Update Workout' : 'Assign Workout'}
              </Button>
            </div>
          </div>
        </Card>
      </div>

      {/* Modals */}
      {showModifyOverlay && (
        <ModifyExerciseOverlay
          exercise={selectedExercise}
          coachId={user.uid}
          onSave={handleSaveExercise}
          onClose={() => {
            setShowModifyOverlay(false);
            setSelectedExercise(null);
          }}
        />
      )}

      {showCreateOverlay && (
        <CreateExerciseOverlay
          onSave={handleSaveExercise}
          onClose={() => {
            setShowCreateOverlay(false);
            setSelectedExercise(null);
          }}
          coachId={user.uid}
          originalExercise={null}
        />
      )}

      <Alert
        isOpen={showDeleteAlert}
        title="Delete Workout"
        message="Are you sure you want to delete this workout?"
        type="warning"
        confirmText="Delete Workout"
        cancelText="Cancel"
        onConfirm={confirmDelete}
        onCancel={() => setShowDeleteAlert(false)}
      />
    </div>
  );
};

export default ClientWorkoutEdit;