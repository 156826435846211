import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from './ui/Card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/Tabs';
import { Button } from './ui/Button';
import { Progress } from './ui/Progress';
import { Settings } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';

import NutritionTab from './AccountPageTabs/NutritionTab';
import ProductsTab from './AccountPageTabs/ProductsTab';
import SleepTab from './AccountPageTabs/SleepTab';
import MessagingTab from './AccountPageTabs/MessagingTab';
import TrainingTab from './AccountPageTabs/TrainingTab';
import MobilityTab from './AccountPageTabs/MobilityTab';

const AccountPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [progress, setProgress] = useState(30);

  const onNavigate = (path) => {
    navigate(`/${path}`);
  };

  return (
    <div className="min-h-screen w-full bg-black">
      <div className="container mx-auto p-4 pt-20">
        {/* Profile Completion Card */}
        <Card className="mb-4 bg-neutral-900 border-0">
          <CardHeader className="py-3">
            <div className="flex justify-between items-center">
              <CardTitle className="text-white text-xl">Profile Completion</CardTitle>
              <div className="flex items-center gap-4">
                <span className="text-lg font-semibold text-white">{progress}%</span>
                <Button
                  onClick={() => onNavigate('account-settings')}
                  variant="ghost"
                  size="icon"
                  className="text-white hover:text-blue-500"
                >
                  <Settings className="h-5 w-5" />
                </Button>
              </div>
            </div>
            <Progress value={progress} className="h-2" />
          </CardHeader>
        </Card>

        {/* Knowledge Base Card */}
        <Card className="bg-neutral-900 border-0">
          <CardHeader className="py-3">
            <CardTitle className="text-white text-xl">Coach Knowledge Base</CardTitle>
            <CardDescription className="text-gray-400">
              Input your coaching philosophy and recommendations
            </CardDescription>
          </CardHeader>
          <CardContent className="p-0">
            <Tabs defaultValue="nutrition" className="flex flex-col">
              <TabsList className="grid w-full grid-cols-6 px-6">
                <TabsTrigger value="nutrition">Nutrition</TabsTrigger>
                <TabsTrigger value="products">Products</TabsTrigger>
                <TabsTrigger value="sleep">Sleep</TabsTrigger>
                <TabsTrigger value="messaging">Messaging</TabsTrigger>
                <TabsTrigger value="training">Training</TabsTrigger>
                <TabsTrigger value="mobility">Mobility</TabsTrigger>
              </TabsList>
              <div className="mt-4">
                <TabsContent value="nutrition">
                  <NutritionTab userId={user.uid} />
                </TabsContent>
                <TabsContent value="products">
                  <ProductsTab userId={user.uid} />
                </TabsContent>
                <TabsContent value="sleep">
                  <SleepTab userId={user.uid} />
                </TabsContent>
                <TabsContent value="messaging">
                  <MessagingTab userId={user.uid} />
                </TabsContent>
                <TabsContent value="training">
                  <TrainingTab userId={user.uid} />
                </TabsContent>
                <TabsContent value="mobility">
                  <MobilityTab userId={user.uid} />
                </TabsContent>
              </div>
            </Tabs>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default AccountPage;