// src/components/overlays/ModifyExerciseOverlay.jsx
import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/Card';
import { Button } from '../ui/Button';
import { Input } from '../ui/Input';
import { Badge } from '../ui/Badge';
import { X, Plus, Trash2, Video } from 'lucide-react';

const ModifyExerciseOverlay = ({ exercise, onSave, onClose }) => {
  const [formData, setFormData] = useState({
    mediaUrl: exercise?.defaultVideoUrl || '',
    instructions: exercise?.instructions || [''],
    commonMistakes: exercise?.commonMistakes || ['']
  });

  const handleArrayUpdate = (field, index, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: prev[field].map((item, i) => i === index ? value : item)
    }));
  };

  const addArrayItem = (field) => {
    setFormData(prev => ({
      ...prev,
      [field]: [...prev[field], '']
    }));
  };

  const removeArrayItem = (field, index) => {
    setFormData(prev => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index)
    }));
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex items-start justify-center z-50 overflow-y-auto py-8">
      <Card className="relative bg-neutral-900 max-w-3xl w-full rounded-lg my-auto mx-4">
        <Button
          onClick={onClose}
          variant="ghost"
          className="absolute top-2 right-2 text-white hover:text-gray-300"
        >
          <X className="h-4 w-4" />
        </Button>

        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            Modify Exercise: {exercise.name}
            <Badge className="bg-purple-500">Modified</Badge>
          </CardTitle>
        </CardHeader>

        <CardContent>
          <form className="space-y-6">
            <div className="space-y-2">
              <label className="text-sm text-gray-400">Video URL</label>
              <Input
                value={formData.mediaUrl}
                onChange={(e) => setFormData(prev => ({ ...prev, mediaUrl: e.target.value }))}
                className="bg-neutral-800 border-neutral-700"
                placeholder="https://"
              />
            </div>

            <div className="space-y-2">
              <label className="text-sm text-gray-400">Instructions</label>
              {formData.instructions.map((instruction, index) => (
                <div key={index} className="flex gap-2">
                  <Input
                    value={instruction}
                    onChange={(e) => handleArrayUpdate('instructions', index, e.target.value)}
                    className="bg-neutral-800 border-neutral-700 flex-1"
                  />
                  <Button
                    type="button"
                    variant="ghost"
                    onClick={() => removeArrayItem('instructions', index)}
                    className="text-red-500 hover:text-red-400"
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
              ))}
              <Button
                type="button"
                onClick={() => addArrayItem('instructions')}
                className="w-full bg-neutral-800 hover:bg-neutral-700"
              >
                <Plus className="h-4 w-4 mr-2" />
                Add Instruction
              </Button>
            </div>

            <div className="space-y-2">
              <label className="text-sm text-gray-400">Common Mistakes</label>
              {formData.commonMistakes.map((mistake, index) => (
                <div key={index} className="flex gap-2">
                  <Input
                    value={mistake}
                    onChange={(e) => handleArrayUpdate('commonMistakes', index, e.target.value)}
                    className="bg-neutral-800 border-neutral-700 flex-1"
                  />
                  <Button
                    type="button"
                    variant="ghost"
                    onClick={() => removeArrayItem('commonMistakes', index)}
                    className="text-red-500 hover:text-red-400"
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
              ))}
              <Button
                type="button"
                onClick={() => addArrayItem('commonMistakes')}
                className="w-full bg-neutral-800 hover:bg-neutral-700"
              >
                <Plus className="h-4 w-4 mr-2" />
                Add Common Mistake
              </Button>
            </div>

            <Button
              onClick={() => onSave(formData)}
              className="w-full bg-blue-600 hover:bg-blue-700"
            >
              Save Changes
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default ModifyExerciseOverlay;
