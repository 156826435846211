import React, { useState, useEffect, useRef } from 'react';
import { Input } from './ui/Input';
import { Search, Plus, Edit } from 'lucide-react';
import { Button } from './ui/Button';
import { Badge } from './ui/Badge';
import { useAuth } from '../contexts/AuthContext';

const ExerciseSearch = ({ value, onChange, className, onEditExercise, allExercises, ...props}) => {
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [results, setResults] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (value && allExercises.length > 0) {
      const foundEx = allExercises.find(ex => ex.id === value);
      if (foundEx) {
        setSearchTerm(foundEx.name);
        setSelectedExercise(foundEx);
      } else {
        setSearchTerm('');
        setSelectedExercise(null);
      }
    } else {
      setSearchTerm('');
      setSelectedExercise(null);
    }
  }, [value, allExercises]);
  
  // Local search function that doesn't need to query Firestore
  const searchExercises = (term) => {
    if (!term) {
      setResults([]);
      return;
    }
    const searchTermLower = term.toLowerCase();
    const exercisesList = allExercises || [];
    
    // Find the currently selected exercise in allExercises
    const currentExercise = exercisesList.find(ex => ex.id === value);
    
    const filteredResults = exercisesList.filter(exercise => {
      const nameMatch = exercise.name?.toLowerCase().includes(searchTermLower);
      const descriptionMatch = exercise.description?.toLowerCase().includes(searchTermLower);
      const equipmentMatch = exercise.equipment?.some(eq => 
        eq.toLowerCase().includes(searchTermLower)
      );
      const muscleGroupMatch = exercise.muscleGroups?.some(mg => 
        mg.toLowerCase().includes(searchTermLower)
      );
      return nameMatch || descriptionMatch || equipmentMatch || muscleGroupMatch;
    });

    // If we have a selected exercise, ensure it appears in results
    if (currentExercise && !filteredResults.some(ex => ex.id === currentExercise.id)) {
      filteredResults.unshift(currentExercise);
    }

    const sortedResults = filteredResults.sort((a, b) => {
      // Keep selected exercise at top if it exists
      if (a.id === value) return -1;
      if (b.id === value) return 1;
      
      const aExact = a.name?.toLowerCase().startsWith(searchTermLower);
      const bExact = b.name?.toLowerCase().startsWith(searchTermLower);
      if (aExact && !bExact) return -1;
      if (!aExact && bExact) return 1;
      return (a.name || '').localeCompare(b.name || '');
    });

    setResults(sortedResults);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setIsOpen(true);
    if (!value) {
      onChange('');
      setSelectedExercise(null);
      setResults([]);
      setIsOpen(false);
    } else {
      searchExercises(value);
    }
  };
  
  const handleSelectExercise = (exercise) => {
    setSearchTerm(exercise.name);
    setSelectedExercise(exercise);
    onChange(exercise.id);
    setIsOpen(false);
  };

  // Add debounced search
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchTerm) {
        searchExercises(searchTerm);
      }
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  // Handle clicking outside of dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <div className="relative flex gap-2">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-text-light" />
          <Input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            onFocus={() => searchTerm && setIsOpen(true)}
            placeholder="Search exercises..."
            className={`pl-10 bg-background-tertiary border-border-light focus:border-accent-primary ${className}`}
          />
        </div>
        {selectedExercise && (
          <Button
            type="button"
            onClick={() => onEditExercise(selectedExercise)}
            variant="outline"
            className="flex items-center gap-2 text-accent-primary border-accent-primary/20 hover:bg-accent-primary/10"
          >
            <Edit className="h-4 w-4" />
            Edit
          </Button>
        )}
      </div>

      {isOpen && (
        <div 
          className="fixed z-50 w-[var(--dropdown-width)] mt-1 bg-background-secondary rounded-lg border border-border-light shadow-lg"
          style={{
            top: dropdownRef.current?.getBoundingClientRect().bottom + 'px',
            left: dropdownRef.current?.getBoundingClientRect().left + 'px',
            '--dropdown-width': dropdownRef.current?.offsetWidth + 'px'
          }}
        >
          <div className="max-h-[280px] overflow-auto">
            {results.length > 0 ? (
              results.map(exercise => (
                <div
                  key={exercise.id}
                  className="p-3 hover:bg-background-tertiary cursor-pointer transition-colors border-b border-border-light/10 last:border-0"
                  onClick={() => handleSelectExercise(exercise)}
                >
                  <div className="flex items-center justify-between">
                    <span className="font-medium text-text-primary">{exercise.name}</span>
                    <div className="flex gap-1">
                      {exercise.isCustom && (
                        <Badge variant="outline" className="text-accent-primary border-accent-primary/20">
                          Custom
                        </Badge>
                      )}
                      {exercise.originalExerciseId && (
                        <Badge variant="outline" className="text-accent-secondary border-accent-secondary/20">
                          Modified
                        </Badge>
                      )}
                      <Badge variant="outline" className="text-text-light border-border-light/20">
                        {exercise.type}
                      </Badge>
                    </div>
                  </div>
                  <div className="text-sm text-text-light flex items-center gap-2 mt-1">
                    {exercise.muscleGroups?.length > 0 && (
                      <span>{exercise.muscleGroups.join(', ')}</span>
                    )}
                    {exercise.equipment?.length > 0 && (
                      <span>• {exercise.equipment.join(', ')}</span>
                    )}
                  </div>
                  {exercise.description && (
                    <div className="text-sm text-text-light mt-1 line-clamp-2">
                      {exercise.description}
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="p-4 text-text-light text-center">
                No exercises found
              </div>
            )}
          </div>
          
          {searchTerm && (
            <div className="p-2 border-t border-border-light/10">
              <Button
                type="button"
                onClick={() => onEditExercise(null)}
                className="w-full bg-accent-primary/10 hover:bg-accent-primary/20 text-accent-primary"
              >
                <Plus className="h-4 w-4 mr-2" />
                Create Custom Exercise
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ExerciseSearch;
