import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Calendar as CalendarIcon, ChevronLeft, ChevronRight } from 'lucide-react';
import { format } from 'date-fns';

const DatePicker = ({ value, onChange, min, max, placeholder = 'Select a date...' }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  
  // Format the date to display
  const formattedDate = value ? format(new Date(value), 'MMMM d, yyyy') : '';
  
  // Generate calendar days for the current month
  const generateCalendarDays = () => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();
    
    // First day of month
    const firstDay = new Date(year, month, 1);
    const startingDayOfWeek = firstDay.getDay(); // 0 for Sunday, 1 for Monday, etc.
    
    // Last day of month
    const lastDay = new Date(year, month + 1, 0);
    const totalDays = lastDay.getDate();
    
    // Days from previous month to fill in the first row
    const prevMonthDays = [];
    for (let i = 0; i < startingDayOfWeek; i++) {
      const day = new Date(year, month, -i);
      prevMonthDays.unshift({ 
        date: day,
        isCurrentMonth: false,
        isDisabled: true
      });
    }
    
    // Current month days
    const days = [];
    for (let i = 1; i <= totalDays; i++) {
      const day = new Date(year, month, i);
      const isDisabled = (min && day < new Date(min)) || (max && day > new Date(max));
      days.push({ 
        date: day,
        isCurrentMonth: true,
        isDisabled: isDisabled
      });
    }
    
    // Combine days
    return [...prevMonthDays, ...days];
  };
  
  const handleDateClick = (day) => {
    if (!day.isDisabled) {
      onChange(day.date.toISOString());
      setShowCalendar(false);
    }
  };
  
  const handlePrevMonth = () => {
    setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1));
  };
  
  const handleNextMonth = () => {
    setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1));
  };
  
  const handleInputClick = () => {
    setShowCalendar(!showCalendar);
    // Initialize current month based on selected date or today
    if (value) {
      setCurrentMonth(new Date(value));
    } else {
      setCurrentMonth(new Date());
    }
  };
  
  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="w-full max-w-md mx-auto"
    >
      <div className="relative">
        {/* Date input */}
        <div
          onClick={handleInputClick}
          className={`w-full p-4 border-2 rounded-lg flex items-center justify-between cursor-pointer ${
            showCalendar ? 'border-accent-primary' : 'border-border-light hover:border-accent-primary'
          } transition-colors duration-200`}
        >
          <span className={`${value ? 'text-text-primary' : 'text-text-light opacity-60'} text-lg`}>
            {formattedDate || placeholder}
          </span>
          <CalendarIcon className="w-5 h-5 text-accent-primary" />
        </div>
        
        {/* Calendar dropdown */}
        {showCalendar && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            className="absolute left-0 right-0 mt-2 p-4 bg-background-secondary border border-border-light rounded-lg shadow-lg z-10"
          >
            {/* Calendar header */}
            <div className="flex items-center justify-between mb-4">
              <button
                type="button"
                onClick={handlePrevMonth}
                className="p-2 rounded-full hover:bg-background-tertiary transition-colors"
              >
                <ChevronLeft className="w-5 h-5 text-text-light" />
              </button>
              
              <h3 className="text-lg font-medium text-text-primary">
                {format(currentMonth, 'MMMM yyyy')}
              </h3>
              
              <button
                type="button"
                onClick={handleNextMonth}
                className="p-2 rounded-full hover:bg-background-tertiary transition-colors"
              >
                <ChevronRight className="w-5 h-5 text-text-light" />
              </button>
            </div>
            
            {/* Calendar weekdays */}
            <div className="grid grid-cols-7 mb-2">
              {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map((day, index) => (
                <div key={index} className="text-center text-xs text-text-light py-2">
                  {day}
                </div>
              ))}
            </div>
            
            {/* Calendar days */}
            <div className="grid grid-cols-7 gap-1">
              {generateCalendarDays().map((day, index) => {
                const isSelected = value && format(new Date(value), 'yyyy-MM-dd') === format(day.date, 'yyyy-MM-dd');
                const isToday = format(new Date(), 'yyyy-MM-dd') === format(day.date, 'yyyy-MM-dd');
                
                return (
                  <button
                    key={index}
                    type="button"
                    disabled={day.isDisabled}
                    onClick={() => handleDateClick(day)}
                    className={`
                      h-10 w-10 rounded-full flex items-center justify-center text-sm
                      ${!day.isCurrentMonth ? 'text-text-light opacity-30' : ''}
                      ${day.isDisabled ? 'cursor-not-allowed opacity-30' : 'hover:bg-background-tertiary'}
                      ${isSelected ? 'bg-accent-primary text-white hover:bg-accent-primary' : ''}
                      ${isToday && !isSelected ? 'border border-accent-primary text-accent-primary' : ''}
                      transition-colors duration-200
                    `}
                  >
                    {day.date.getDate()}
                  </button>
                );
              })}
            </div>
            
            {/* Close button */}
            <div className="mt-4 text-center">
              <button
                type="button"
                onClick={() => setShowCalendar(false)}
                className="px-4 py-2 text-sm text-accent-primary hover:bg-accent-primary/10 rounded-md transition-colors"
              >
                Close
              </button>
            </div>
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

export default DatePicker; 