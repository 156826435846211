import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useAuth } from '../../contexts/AuthContext';
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { Button } from '../ui/Button';
import { CheckCircle, Users, Download, Settings, ExternalLink } from 'lucide-react';
import Confetti from 'react-confetti';

const SuccessScreen = () => {
  const navigate = useNavigate();
  const { user, completeOnboarding } = useAuth();
  const [copyLinkText, setCopyLinkText] = useState('Copy Invite Link');
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [showConfetti, setShowConfetti] = useState(true);
  
  // Update window size for confetti
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  // Hide confetti after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 5000);
    
    return () => clearTimeout(timer);
  }, []);
  
  useEffect(() => {
    const updateOnboardingStatus = async () => {
      try {
        if (user?.uid) {
          await updateDoc(doc(db, 'coaches', user.uid), {
            onboardingComplete: true,
            updatedAt: serverTimestamp()
          });
          
          // Also update in AuthContext
          if (completeOnboarding) {
            completeOnboarding();
          }
        }
      } catch (error) {
        console.error('Error updating onboarding status:', error);
      }
    };
    
    updateOnboardingStatus();
  }, [user, completeOnboarding]);
  
  const handleCopyLink = async () => {
    try {
      // Get coach username or use uid as fallback
      const coachDoc = await getDoc(doc(db, 'coaches', user.uid));
      const username = coachDoc.data()?.username || user.uid;
      
      const inviteUrl = `https://evolv.coach/${username}`;
      await navigator.clipboard.writeText(inviteUrl);
      
      setCopyLinkText('Copied!');
      setTimeout(() => setCopyLinkText('Copy Invite Link'), 2000);
    } catch (error) {
      console.error('Error copying link:', error);
    }
  };
  
  return (
    <div className="flex flex-col items-center justify-center min-h-screen text-center p-4 relative overflow-hidden">
      {/* Confetti animation */}
      {showConfetti && (
        <Confetti
          width={windowSize.width}
          height={windowSize.height}
          recycle={false}
          numberOfPieces={500}
          gravity={0.2}
          colors={['#C4845F', '#9F8C74', '#3B4664', '#4A3636', '#6E6E6E']}
        />
      )}
      
      <motion.div 
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="max-w-3xl mx-auto bg-background-secondary p-8 rounded-lg border border-border-light shadow-lg"
      >
        <motion.div 
          className="w-20 h-20 rounded-full bg-accent-primary/20 flex items-center justify-center mx-auto mb-6"
          initial={{ scale: 0.8 }}
          animate={{ scale: [0.8, 1.2, 1] }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <CheckCircle className="w-10 h-10 text-accent-primary" />
        </motion.div>
        
        <h1 className="text-4xl font-bold text-text-primary mb-4 font-headers">
          Your AI Coach is Ready!
        </h1>
        
        <p className="text-xl text-text-secondary mb-8 font-body">
          You're all set to grow your coaching business with Evolv. Your AI coach is ready to help your clients 24/7.
        </p>
        
        <div className="grid md:grid-cols-3 gap-6 mb-8">
          {[
            {
              icon: <Users className="w-8 h-8 text-accent-primary" />,
              title: "Invite Clients",
              description: "Share your invite link or accept client requests in your dashboard",
              buttonText: copyLinkText,
              buttonAction: handleCopyLink
            },
            {
              icon: <Download className="w-8 h-8 text-accent-primary" />,
              title: "Get the iOS App",
              description: "Create workouts, meal plans and manage clients on-the-go",
              buttonText: "Download App",
              buttonAction: () => window.open('https://apps.apple.com/us/app/evolv/id6739216841', '_blank')
            },
            {
              icon: <Settings className="w-8 h-8 text-accent-primary" />,
              title: "Customize Forms",
              description: "Create custom intake forms for your client onboarding process",
              buttonText: "Customize Forms",
              buttonAction: () => navigate('/onboarding-forms')
            }
          ].map((card, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 + index * 0.1, duration: 0.4 }}
              className="bg-background-primary p-6 rounded-lg border border-border-light hover:shadow-md transition-shadow duration-300"
            >
              <div className="mb-4">{card.icon}</div>
              <h3 className="text-lg font-medium text-text-primary mb-2 font-headers">
                {card.title}
              </h3>
              <p className="text-text-secondary text-sm mb-4 font-body">
                {card.description}
              </p>
              <Button
                onClick={card.buttonAction}
                className="w-full bg-accent-tertiary text-white hover:bg-accent-primary transition-all duration-200 shadow-sm"
              >
                {card.buttonText}
              </Button>
            </motion.div>
          ))}
        </div>
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6, duration: 0.4 }}
        >
          <Button
            onClick={() => navigate('/dashboard')}
            className="bg-accent-primary text-white px-8 py-4 rounded-lg text-lg font-medium 
                     hover:bg-hover-primary transition-all duration-200 shadow-md hover:shadow-lg
                     min-w-[200px] flex items-center gap-2"
          >
            Go to Dashboard
            <ExternalLink className="w-4 h-4" />
          </Button>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default SuccessScreen; 