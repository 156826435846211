// src/components/AccountPageTabs/MobilityTab.jsx
import React, { useState, useEffect } from 'react';
import { Button } from '../ui/Button';
import { Card, CardHeader, CardContent } from '../ui/Card';
import { Calendar, Send, X, Plus, Clock, Video } from 'lucide-react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import ReactMarkdown from 'react-markdown';
import AddMobilityExerciseOverlay from './AddMobilityExerciseOverlay';

const MobilityTab = ({ userId }) => {
  const [exercises, setExercises] = useState([]);
  const [showAddExercise, setShowAddExercise] = useState(false);
  const [availableTags] = useState([
    'Upper Body', 'Lower Body', 'Spine', 'Hips', 'Shoulders', 
    'Ankles', 'Wrists', 'Recovery', 'Pre-Workout', 'Post-Workout',
    'Dynamic', 'Static', 'Joint Mobility', 'Muscle Release'
  ]);

  useEffect(() => {
    loadExercises();
  }, [userId]);

  const loadExercises = async () => {
    try {
      const exercisesDoc = await getDoc(doc(db, 'coaches', userId, 'mobility', 'exercises'));
      if (exercisesDoc.exists()) {
        setExercises(exercisesDoc.data().exercises || []);
      }
    } catch (error) {
      console.error('Error loading mobility exercises:', error);
    }
  };

  const handleSaveExercise = async (newExercise) => {
    if (!newExercise.name.trim() || !newExercise.instructions.trim()) return;
    
    const timestamp = new Date().toISOString();
    const exerciseToSave = {
      ...newExercise,
      createdAt: timestamp,
      lastModified: timestamp
    };

    try {
      const updatedExercises = [...exercises, exerciseToSave];
      await setDoc(doc(db, 'coaches', userId, 'mobility', 'exercises'), 
        { exercises: updatedExercises }, 
        { merge: true }
      );
      setExercises(updatedExercises);
      setShowAddExercise(false);
    } catch (error) {
      console.error('Error saving mobility exercise:', error);
    }
  };

  const removeExercise = async (index) => {
    try {
      const updatedExercises = exercises.filter((_, i) => i !== index);
      await setDoc(doc(db, 'coaches', userId, 'mobility', 'exercises'), 
        { exercises: updatedExercises }, 
        { merge: true }
      );
      setExercises(updatedExercises);
    } catch (error) {
      console.error('Error removing mobility exercise:', error);
    }
  };

  return (
    <div className="flex flex-col h-full overflow-y-auto">
      <div className="flex-1 space-y-6">
        {/* Header */}
        <div className="sticky top-0 z-10 bg-background flex justify-between items-center px-4 py-2">
          <h2 className="text-2xl font-bold text-foreground">Mobility Exercises</h2>
          <Button 
            onClick={() => setShowAddExercise(true)} 
            size="lg"
            className="inline-flex items-center gap-2"
          >
            <Plus className="w-5 h-5" />
            Add New Exercise
          </Button>
        </div>

        {/* Exercise Cards Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4 pb-8">
          {exercises.map((exercise, index) => (
            <Card key={index} className="bg-background border-accent/20 hover:border-accent/40 transition-colors">
              <CardHeader>
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="text-lg font-semibold text-foreground">{exercise.name}</h3>
                    <div className="flex items-center gap-2 mt-1 text-sm text-muted-foreground">
                      <Clock className="w-4 h-4" />
                      <span>{exercise.duration}</span>
                    </div>
                    <div className="flex flex-wrap gap-1 mt-2">
                      {exercise.tags?.map(tag => (
                        <span key={tag} className="px-2 py-1 text-xs rounded-full bg-accent/20 text-accent-foreground">
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="flex space-x-2">
                    {exercise.videoUrl && (
                      <Button 
                        variant="ghost" 
                        size="icon"
                        onClick={() => window.open(exercise.videoUrl, '_blank')}
                        className="text-muted-foreground hover:text-foreground"
                      >
                        <Video className="w-4 h-4" />
                      </Button>
                    )}
                    <Button 
                      variant="ghost" 
                      size="icon"
                      className="text-muted-foreground hover:text-foreground"
                    >
                      <Send className="w-4 h-4" />
                    </Button>
                    <Button 
                      variant="ghost" 
                      size="icon"
                      onClick={() => removeExercise(index)}
                      className="text-muted-foreground hover:text-red-400"
                    >
                      <X className="w-4 h-4" />
                    </Button>
                  </div>
                </div>
              </CardHeader>
              <CardContent className="max-h-96 overflow-y-auto">
                <div className="prose prose-invert prose-sm max-w-none">
                  <ReactMarkdown>{exercise.instructions}</ReactMarkdown>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>

      {/* Add Exercise Overlay */}
      <AddMobilityExerciseOverlay
        isOpen={showAddExercise}
        onClose={() => setShowAddExercise(false)}
        onSave={handleSaveExercise}
        availableTags={availableTags}
      />
    </div>
  );
};

export default MobilityTab;