// src/components/accountpagetabs/MessagingTab.jsx

import React, { useState, useEffect } from 'react';
import { Upload, X } from 'lucide-react';
import { Button } from '../ui/Button';
import { Label } from '../ui/Label';
import { httpsCallable, getFunctions } from 'firebase/functions';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import MessageEditor from '../MessageEditor';
import { cn } from '../../lib/utils';
import { Switch } from '../ui/Switch';

const MessagingTab = ({ userId }) => {
  // State variables
  const [uploadedScreenshots, setUploadedScreenshots] = useState([]);
  const [hasUploads, setHasUploads] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isMessageOverlayOpen, setIsMessageOverlayOpen] = useState(false);

  // Message Style Settings
  const [toneStyle, setToneStyle] = useState([]);
  const [emojiUse, setEmojiUse] = useState(false);
  const [grammarLevel, setGrammarLevel] = useState('');
  const [languageStyle, setLanguageStyle] = useState('');

  const functions = getFunctions();

  // Message Style Options
  const toneStyleOptions = [
    'Witty',
    'Professional',
    'Encouraging',
    'Direct',
    'Detailed',
    'Concise',
    'Feminine',
    'Masculine',
    'Youthful',
    'Empathetic',
  ];

  const grammarOptions = {
    'Casual texter':
      'Informal grammar with relaxed phrasing. Occasional errors in punctuation, capitalization, spelling, etc.',
    'Perfect Grammar': 'Perfect grammar.',
  };

  const languageStyleOptions = ['Friendly', 'Authoritative', 'Motivational', 'Neutral'];

  useEffect(() => {
    const loadMessageStyleSettings = async () => {
      try {
        const messageStyleDoc = await getDoc(
          doc(db, 'coaches', userId, 'philosophy', 'messageStyleSettings')
        );
        
        if (messageStyleDoc.exists()) {
          const msgData = messageStyleDoc.data();
          setToneStyle(msgData.toneStyle || []);
          setEmojiUse(msgData.emojiUse !== undefined ? msgData.emojiUse : false);
          setGrammarLevel(msgData.grammarLevel || '');
          setLanguageStyle(msgData.languageStyle || '');
          setHasUploads(
            Boolean(
              msgData.exampleConversation && 
              Object.keys(msgData.exampleConversation).length > 0
            )
          );
        } 
      } catch (error) {
        console.error('Error loading message style settings:', error);
      }
    };
  
    loadMessageStyleSettings();
  }, [userId]);
  
  // Handlers for Message Style Settings
  const updateMessageStyleSetting = async (field, value) => {
    try {
      const messageStyleRef = doc(
        db,
        'coaches',
        userId,
        'philosophy',
        'messageStyleSettings'
      );
      await setDoc(
        messageStyleRef,
        {
          [field]: value,
        },
        { merge: true }
      );
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
    }
  };

  const handleToneStyleToggle = async (option) => {
    let updatedToneStyle;
    if (toneStyle.includes(option)) {
      // Remove the option
      updatedToneStyle = toneStyle.filter((item) => item !== option);
    } else {
      // Add the option
      updatedToneStyle = [...toneStyle, option];
    }
    setToneStyle(updatedToneStyle);
    await updateMessageStyleSetting('toneStyle', updatedToneStyle);
  };

  const handleEmojiUseChange = async (value) => {
    setEmojiUse(value);
    await updateMessageStyleSetting('emojiUse', value);
  };

  const handleGrammarLevelChange = async (option) => {
    setGrammarLevel(option);
    await updateMessageStyleSetting('grammarLevel', option);
  };

  const handleLanguageStyleChange = async (option) => {
    setLanguageStyle(option);
    await updateMessageStyleSetting('languageStyle', option);
  };

  // Handlers for uploading screenshots
  const handleScreenshotUpload = async (e) => {
    const files = Array.from(e.target.files);

    // Validate file count
    if (files.length + uploadedScreenshots.length > 3) {
      setUploadError('You can upload up to 3 screenshots.');
      return;
    }

    // Validate file types and sizes
    const maxSize = 5 * 1024 * 1024; // 5MB
    const validFiles = [];

    for (const file of files) {
      if (!file.type.startsWith('image/')) {
        setUploadError('Only image files are allowed.');
        return;
      }

      if (file.size > maxSize) {
        setUploadError('Each file must be less than 5MB.');
        return;
      }

      validFiles.push(file);
    }

    setUploadedScreenshots((prev) => [...prev, ...validFiles]);
    setUploadError('');
  };

  const handleRemoveScreenshot = (index) => {
    setUploadedScreenshots((prev) => prev.filter((_, i) => i !== index));
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // Extract base64 data without the data URL prefix
        const base64String = reader.result.split(',')[1];
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const handleSubmitScreenshots = async () => {
    if (uploadedScreenshots.length === 0) {
      setUploadError('Please upload at least one screenshot.');
      return;
    }

    setIsProcessing(true);
    setUploadError('');

    try {
      // Convert images to base64
      const base64Images = await Promise.all(uploadedScreenshots.map(fileToBase64));

      // Call the Cloud Function
      const extractConversation = httpsCallable(functions, 'extractConversation');
      const response = await extractConversation({
        userId: userId,
        images: base64Images,
      });

      if (response.data.success) {
        alert('Screenshots processed successfully!');
        setUploadedScreenshots([]);
        setHasUploads(true);
      } else {
        throw new Error(response.data.message || 'Failed to process screenshots');
      }
    } catch (error) {
      console.error('Error processing screenshots:', error);
      setUploadError(error.message || 'Failed to process screenshots');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto space-y-8">
      {/* Message Style Settings */}
      <div className="bg-background-secondary rounded-lg border border-border-light p-6">
        <h3 className="text-lg font-headers text-text-primary mb-6">Message Style</h3>
        
        <div className="space-y-8">
          {/* Tone Style */}
          <div>
            <label className="block text-sm font-medium text-text-secondary mb-3">
              Tone Style (Select multiple)
            </label>
            <div className="flex flex-wrap gap-2">
              {toneStyleOptions.map((option) => (
                <button
                  key={option}
                  onClick={() => handleToneStyleToggle(option)}
                  className={cn(
                    "px-4 py-2 rounded-lg font-body transition-all duration-200",
                    toneStyle.includes(option)
                      ? "bg-accent-primary text-white hover:bg-hover-primary"
                      : "bg-background-tertiary text-text-secondary hover:bg-hover-secondary"
                  )}
                >
                  {option}
                </button>
              ))}
            </div>
          </div>

          {/* Language Style */}
          <div>
            <label className="block text-sm font-medium text-text-secondary mb-3">
              Language Style
            </label>
            <div className="flex flex-wrap gap-2">
              {languageStyleOptions.map((option) => (
                <button
                  key={option}
                  onClick={() => handleLanguageStyleChange(option)}
                  className={cn(
                    "px-4 py-2 rounded-lg font-body transition-all duration-200",
                    languageStyle === option
                      ? "bg-accent-primary text-white hover:bg-hover-primary"
                      : "bg-background-tertiary text-text-secondary hover:bg-hover-secondary"
                  )}
                >
                  {option}
                </button>
              ))}
            </div>
          </div>

          {/* Grammar Level */}
          <div>
            <label className="block text-sm font-medium text-text-secondary mb-3">
              Grammar Level
            </label>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              {Object.entries(grammarOptions).map(([option, description]) => (
                <div
                  key={option}
                  onClick={() => handleGrammarLevelChange(option)}
                  className={cn(
                    "p-4 rounded-lg border cursor-pointer transition-all duration-200",
                    grammarLevel === option
                      ? "border-accent-primary bg-background-tertiary"
                      : "border-border-light hover:border-accent-secondary"
                  )}
                >
                  <div className="flex items-center gap-3">
                    <div
                      className={cn(
                        "w-4 h-4 rounded-full border-2 transition-colors",
                        grammarLevel === option
                          ? "border-accent-primary bg-accent-primary"
                          : "border-accent-secondary"
                      )}
                    />
                    <span className="font-medium text-text-primary">{option}</span>
                  </div>
                  <p className="mt-2 text-sm text-text-secondary">{description}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Emoji Use */}
          <div>
            <label className="block text-sm font-medium text-text-secondary mb-3">
              Emoji Use
            </label>
            <div className="flex items-center gap-3">
              <Switch
                checked={emojiUse}
                onCheckedChange={handleEmojiUseChange}
              />
              <span className="text-text-secondary">
                {emojiUse ? 'Enabled' : 'Disabled'}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Example Conversation Upload */}
      <div className="bg-background-secondary rounded-lg border border-border-light p-6">
        <h3 className="text-lg font-headers text-text-primary mb-6">Example Conversation</h3>
        
        <div className="space-y-6">
          {hasUploads ? (
            <div className="flex items-center gap-4 p-4 bg-background-tertiary rounded-lg border border-border-light">
              <div className="w-2 h-2 rounded-full bg-accent-primary" />
              <span className="text-text-secondary font-body">
                Example conversation uploaded
              </span>
              <button
                onClick={() => setIsMessageOverlayOpen(true)}
                className="ml-auto text-accent-tertiary hover:text-accent-primary transition-colors"
              >
                View
              </button>
            </div>
          ) : (
            <div className="space-y-4">
              <div className="flex flex-wrap gap-3">
                {uploadedScreenshots.map((file, index) => (
                  <div
                    key={index}
                    className="relative group bg-background-tertiary rounded-lg p-2"
                  >
                    <img
                      src={URL.createObjectURL(file)}
                      alt={`Screenshot ${index + 1}`}
                      className="w-24 h-24 object-cover rounded-lg"
                    />
                    <button
                      onClick={() => handleRemoveScreenshot(index)}
                      className="absolute -top-2 -right-2 p-1 bg-white rounded-full 
                               shadow-md opacity-0 group-hover:opacity-100 transition-opacity"
                    >
                      <X className="w-4 h-4 text-text-secondary hover:text-red-500 transition-colors" />
                    </button>
                  </div>
                ))}
              </div>

              <div className="flex items-center gap-4">
                <Button
                  onClick={() => document.getElementById('screenshot-upload').click()}
                  className="bg-accent-secondary text-white hover:bg-hover-primary 
                           transition-all duration-200 shadow-md hover:shadow-lg"
                >
                  <Upload className="w-4 h-4 mr-2" />
                  Upload Screenshots
                </Button>
                {uploadedScreenshots.length > 0 && (
                  <Button
                    onClick={handleSubmitScreenshots}
                    disabled={isProcessing}
                    className="bg-accent-primary text-white hover:bg-hover-primary 
                             transition-all duration-200 shadow-md hover:shadow-lg"
                  >
                    Process Screenshots
                  </Button>
                )}
              </div>

              <input
                id="screenshot-upload"
                type="file"
                accept="image/*"
                multiple
                onChange={handleScreenshotUpload}
                className="hidden"
              />

              {uploadError && (
                <div className="text-red-500 text-sm mt-2">{uploadError}</div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Info Message */}
      <div className="flex items-start space-x-3 p-4 bg-background-tertiary rounded-lg border border-border-light">
        <Upload className="w-5 h-5 text-accent-primary flex-shrink-0 mt-0.5" />
        <p className="text-sm text-text-secondary font-body">
          Changes are automatically saved. Your message style settings help us understand your communication preferences.
        </p>
      </div>

      {/* Message Overlay Modal */}
      {isMessageOverlayOpen && (
        <MessageEditor
          userId={userId}
          isOpen={isMessageOverlayOpen}
          onClose={() => setIsMessageOverlayOpen(false)}
        />
      )}
    </div>
  );
};

export default MessagingTab;
