// src/components/overlays/ClientWorkoutEditOverlay.jsx

import React, { useState, useEffect } from 'react';
import { Button } from '../ui/Button';
import { Input } from '../ui/Input';
import { Trash2, Plus, ChevronUp, ChevronDown } from 'lucide-react';
import ExerciseSearch from '../ExerciseSearch';
import { assignCustomWorkout, updateAssignedWorkout, deleteAssignedWorkout } from '../../services/workoutService';
import { useAuth } from '../../contexts/AuthContext';
import MessageSchedule from '../MessageSchedule';
import ExpandedSetsEditor from '../ExpandedSetsEditor';
import { createCustomExercise, modifyExercise, getAllExercises } from '../../services/exerciseService';
import ModifyExerciseOverlay from './ModifyExerciseOverlay';
import CreateExerciseOverlay from './CreateExerciseOverlay';


const ClientWorkoutEditOverlay = ({ clientId, date, template, existingWorkout, onClose, onWorkoutAssigned }) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [messageSendTime, setMessageSendTime] = useState(null);
  const [workoutData, setWorkoutData] = useState({
    name: template?.name || existingWorkout?.name || '',
    description: template?.description || existingWorkout?.description || '',
    headerImageUrl: template?.headerImageUrl || existingWorkout?.headerImageUrl || '',
    sections: [],
    exercises: [],
    coachId: user?.uid || ''
  });
  const [expandedExercise, setExpandedExercise] = useState(null);
  const [showExerciseEdit, setShowExerciseEdit] = useState(false);
  const [exerciseToEdit, setExerciseToEdit] = useState(null);
  const [showModifyOverlay, setShowModifyOverlay] = useState(false);
  const [showCreateOverlay, setShowCreateOverlay] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [allExercises, setAllExercises] = useState([]);


  useEffect(() => {
    const fetchExercises = async () => {
      try {
        const exercises = await getAllExercises(user.uid);
        setAllExercises(exercises);
      } catch (error) {
        console.error('Error fetching exercises:', error);
      }
    };

    fetchExercises();
  }, [user.uid]);

  useEffect(() => {
    const loadData = async () => {
      if (existingWorkout) {
        // Load from existing assigned workout
        const sections = existingWorkout.sections || [];
        const exercises = existingWorkout.exercises || [];
        setWorkoutData(prev => ({
          ...prev,
          name: existingWorkout.name || '',
          description: existingWorkout.description || '',
          headerImageUrl: existingWorkout.headerImageUrl || '',
          sections,
          exercises,
          coachId: existingWorkout.coachId || user.uid
        }));
      } else if (template) {
        // Load from template
        const sections = template.sections || [];
        const exercises = template.exercises || [];
        setWorkoutData(prev => ({
          ...prev,
          name: template.name || '',
          description: template.description || '',
          headerImageUrl: template.headerImageUrl || '',
          sections: [...sections],
          exercises: [...exercises],
          coachId: user.uid // Ensure we have the coachId set
        }));
      }
    };
    if (template || existingWorkout) {
      loadData();
    }
  }, [template, existingWorkout]);

  const addSection = (type) => {
    const newSection = {
      id: `${type}-${Date.now()}`,
      type,
      name: type === 'warmup' ? 'Warm Up'
           : type === 'cooldown' ? 'Cool Down'
           : `Block ${String.fromCharCode(65 + workoutData.sections.filter(s => s.type === 'block').length)}`
    };
    setWorkoutData(prev => {
      let newSections = [...prev.sections];
      if (type === 'warmup') {
        newSections = [newSection, ...newSections]; 
      } else {
        newSections.push(newSection);
      }
      return { ...prev, sections: newSections };
    });
  };

  const removeSection = (sectionId) => {
    setWorkoutData(prev => {
      const filteredExercises = prev.exercises.filter(e => e.sectionId !== sectionId);
      const filteredSections = prev.sections.filter(s => s.id !== sectionId);
      return { ...prev, sections: filteredSections, exercises: filteredExercises };
    });
  };

  const addExercise = (sectionId) => {
    const order = workoutData.exercises.filter(e => e.sectionId === sectionId).length;
    const newExercise = {
      exerciseId: '',
      sets: Array(3).fill().map(() => ({ reps: '8', weight: null })), // Initialize with 3 sets
      targetReps: '8', 
      targetWeight: null, 
      rest: 60,
      notes: '',
      sectionId,
      order
    };
    setWorkoutData(prev => ({
      ...prev,
      exercises: [...prev.exercises, newExercise]
    }));
  };
  

  const removeExercise = (sectionId, order) => {
    setWorkoutData(prev => ({
      ...prev,
      exercises: prev.exercises.filter(e => !(e.sectionId === sectionId && e.order === order))
    }));
  };

  const updateExercise = (sectionId, order, field, value) => {
    setWorkoutData(prev => ({
      ...prev,
      exercises: prev.exercises.map(ex => {
        if (ex.sectionId === sectionId && ex.order === order) {
          return { ...ex, [field]: value };
        }
        return ex;
      })
    }));
  };

  const handleAssignOrUpdate = async () => {
    setLoading(true);
    try {
      const finalData = { ...workoutData, coachId: user.uid };
      if (existingWorkout) {
        await updateAssignedWorkout(existingWorkout.coachId || user.uid, clientId, existingWorkout.id, finalData, date, messageSendTime);
      } else {
        await assignCustomWorkout(finalData, clientId, date, messageSendTime);
      }
      onWorkoutAssigned();
    } catch (error) {
      console.error('Error assigning workout:', error);
    } finally {
      setLoading(false);
    }
  };

  // Group exercises by sections
  const sectionsWithExercises = workoutData.sections.map(sec => ({
    ...sec,
    exercises: workoutData.exercises
      .filter(ex => ex.sectionId === sec.id)
      .sort((a, b) => a.order - b.order)
  }));

  const handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete this workout? This action cannot be undone.')) {
      return;
    }

    setLoading(true);
    try {
      await deleteAssignedWorkout(existingWorkout.coachId || user.uid, clientId, existingWorkout.id);
      onWorkoutAssigned(); // Refresh the calendar view
      onClose(); // Close the overlay
    } catch (error) {
      console.error('Error deleting workout:', error);
      alert('Failed to delete workout. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleEditExercise = (exercise) => {
    setSelectedExercise(exercise);
    if (exercise) {
      setShowModifyOverlay(true);
    } else {
      setShowCreateOverlay(true);
    }
  };

  const handleSaveExercise = async (formData) => {
    try {
      let savedExercise;
      if (selectedExercise) {
        // Modifying existing exercise
        savedExercise = await modifyExercise(user.uid, selectedExercise.id, formData);
        // Update the exercise in allExercises with the new data
        setAllExercises(prev => prev.map(ex => 
          ex.id === selectedExercise.id 
            ? { ...ex, ...formData, isModified: true }
            : ex
        ));
      } else {
        // Creating new exercise
        savedExercise = await createCustomExercise(user.uid, formData);
        // Add the new exercise to allExercises
        setAllExercises(prev => [...prev, { ...savedExercise, isCustom: true }]);
      }
  
      // Update the exercises data structure with the new exercise
      const exerciseId = selectedExercise ? selectedExercise.id : savedExercise.id;
      
      // For ClientWorkoutEditOverlay.jsx
      if (workoutData) {
        setWorkoutData(prev => ({
          ...prev,
          exercises: prev.exercises.map(exercise =>
            exercise.exerciseId === exerciseId
              ? { ...exercise, name: formData.name }
              : exercise
          )
        }));
      }
    } catch (error) {
      console.error('Error saving exercise:', error);
    }
  };  

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex items-start justify-center z-50 overflow-y-auto py-8">
      <div className="relative bg-neutral-900 max-w-4xl w-full rounded-lg p-4 my-auto mx-4">
        <Button
          onClick={onClose}
          variant="ghost"
          className="absolute top-2 right-2 text-white hover:text-gray-300"
        >
          X
        </Button>
        <div className="min-h-[200px]">
          <h2 className="text-xl font-bold mb-4 text-white">
            {existingWorkout ? 'Edit Assigned Workout' : 'Assign Workout'}
          </h2>

          <div className="space-y-4">
            <div className="flex flex-col space-y-2">
              <Input
                placeholder="Workout Name"
                value={workoutData.name || ''}
                onChange={(e) => setWorkoutData(prev => ({ ...prev, name: e.target.value }))}
                className="bg-neutral-800 border-neutral-700 w-50"
              />
              <Input
                placeholder="Description"
                value={workoutData.description || ''}
                onChange={(e) => setWorkoutData(prev => ({ ...prev, description: e.target.value }))}
                className="bg-neutral-800 border-neutral-700 w-50"
              />
            </div>

            <div className="flex flex-col space-y-4">
              <div className="flex space-x-2">
                {!workoutData.sections.some(s => s.type === 'warmup') && (
                  <Button
                    type="button"
                    onClick={() => addSection('warmup')}
                    className="bg-neutral-800 hover:bg-neutral-700"
                  >
                    Add Warm Up
                  </Button>
                )}
                <Button
                  type="button"
                  onClick={() => addSection('block')}
                  className="bg-neutral-800 hover:bg-neutral-700"
                >
                  Add Block
                </Button>
                {!workoutData.sections.some(s => s.type === 'cooldown') && (
                  <Button
                    type="button"
                    onClick={() => addSection('cooldown')}
                    className="bg-neutral-800 hover:bg-neutral-700"
                  >
                    Add Cool Down
                  </Button>
                )}
              </div>
  
            {sectionsWithExercises.map(section => (
              <div key={section.id} className="border border-neutral-700 rounded-lg p-4 space-y-4">
                <div className="flex items-center justify-between">
                  <h4 className="text-lg font-medium text-white">{section.name}</h4>
                  <Button
                    type="button"
                    variant="ghost"
                    onClick={() => removeSection(section.id)}
                    className="text-red-500 hover:text-red-400"
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
  
                {section.exercises.map((exercise, idx) => {
                  const isExpanded = expandedExercise === `${section.id}-${exercise.order}`;
                  return (
                    <div key={idx} className="relative border border-neutral-700 rounded-lg p-4 space-y-4">
                      <div className="flex items-center justify-between">
                        <div className="flex-1 mr-4">
                        <ExerciseSearch
                          value={exercise.exerciseId}
                          onChange={(value) => updateExercise(section.id, idx, 'exerciseId', value)}
                          className="w-full"
                          onEditExercise={handleEditExercise}
                          allExercises={allExercises} // Pass the latest exercises to ExerciseSearch
                        />
                        </div>
                        <Button
                          type="button"
                          variant="ghost"
                          onClick={() => removeExercise(section.id, exercise.order)}
                          className="text-red-500 hover:text-red-400"
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </div>
  
                      <div className="flex items-end space-x-4">
                        <div className="flex items-center space-x-2">
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => setExpandedExercise(isExpanded ? null : `${section.id}-${exercise.order}`)}
                            className="text-gray-400 hover:text-white h-10 w-10 flex-shrink-0"
                          >
                            {isExpanded ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
                          </Button>
                          <div className="space-y-1">
                            <label className="text-sm text-gray-400 block">Sets</label>
                            <Input
                              type="number"
                              value={exercise.sets.length}
                              onChange={(e) => {
                                const newCount = parseInt(e.target.value);
                                const currentSets = exercise.sets || [];
                                const newSets = Array(newCount)
                                  .fill()
                                  .map((_, i) =>
                                    currentSets[i] || { reps: exercise.targetReps, weight: exercise.targetWeight }
                                  );
                                updateExercise(section.id, exercise.order, 'sets', newSets);
                              }}
                              className="bg-neutral-800 border-neutral-700 w-20"
                              min="1"
                            />
                          </div>
                        </div>
  
                        <div className="space-y-1">
                          <label className="text-sm text-gray-400 block">Reps</label>
                          <Input
                            value={exercise.targetReps || ''}
                            onChange={(e) => updateExercise(section.id, exercise.order, 'targetReps', e.target.value)}
                            className="bg-neutral-800 border-neutral-700 w-20"
                          />
                        </div>
  
                        <div className="space-y-1">
                          <label className="text-sm text-gray-400 block">Weight (lbs)</label>
                          <Input
                            type="number"
                            value={exercise.targetWeight != null ? exercise.targetWeight : ''}
                            onChange={(e) =>
                              updateExercise(section.id, exercise.order, 'targetWeight', parseFloat(e.target.value))
                            }
                            className="bg-neutral-800 border-neutral-700 w-20"
                            min="0"
                            placeholder="Optional"
                          />
                        </div>
  
                        <div className="space-y-1">
                          <label className="text-sm text-gray-400 block">Rest (sec)</label>
                          <Input
                            type="number"
                            value={exercise.rest != null ? exercise.rest : 60}
                            onChange={(e) =>
                              updateExercise(section.id, exercise.order, 'rest', parseInt(e.target.value))
                            }
                            className="bg-neutral-800 border-neutral-700 w-20"
                            min="0"
                          />
                        </div>
                      </div>
  
                      <Input
                        placeholder="Notes"
                        value={exercise.notes || ''}
                        onChange={(e) => updateExercise(section.id, exercise.order, 'notes', e.target.value)}
                        className="bg-neutral-800 border-neutral-700"
                      />
  
                      {isExpanded && (
                        <div className="mt-4">
                          <ExpandedSetsEditor
                            sets={exercise.sets}
                            onChange={(newSets) => updateExercise(section.id, exercise.order, 'sets', newSets)}
                            onClose={() => setExpandedExercise(null)}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
  
                <Button
                  type="button"
                  onClick={() => addExercise(section.id)}
                  className="w-full bg-neutral-800 hover:bg-neutral-700"
                >
                  <Plus className="h-4 w-4 mr-2" />
                  Add Exercise to {section.name}
                </Button>
              </div>
            ))}
  
            <MessageSchedule
              scheduledDate={date}
              messageSendTime={messageSendTime}
              onMessageSendTimeChange={setMessageSendTime}
            />
  
            <Button
              onClick={handleAssignOrUpdate}
              disabled={loading}
              className="w-full bg-blue-600 hover:bg-blue-700"
            >
              {loading ? 'Saving...' : existingWorkout ? 'Update Workout' : 'Assign Workout'}
            </Button>
          </div>
        </div>
      </div>
    </div>

    {showModifyOverlay && (
        <ModifyExerciseOverlay
          exercise={selectedExercise}
          onSave={handleSaveExercise}
          onClose={() => {
            setShowModifyOverlay(false);
            setSelectedExercise(null);
          }}
        />
      )}

      {showCreateOverlay && (
        <CreateExerciseOverlay
          onSave={handleSaveExercise}
          onClose={() => {
            setShowCreateOverlay(false);
            setSelectedExercise(null);
          }}
        />
      )}
    </div>
  );
};


export default ClientWorkoutEditOverlay;