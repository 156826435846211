import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { MessageCircle, CheckCircle, X, Pencil, ThumbsDown } from 'lucide-react';

/**
 * Modal component for collecting feedback on AI messages
 * 
 * @param {Object} props
 * @param {string} props.message - The AI message being rated
 * @param {Function} props.onSubmit - Callback for feedback submission, receives (reason, correctedMessage)
 * @param {Function} props.onCancel - Callback for canceling feedback
 */
export default function FeedbackModal({ message, onSubmit, onCancel }) {
  const [reason, setReason] = useState('');
  const [correctedMessage, setCorrectedMessage] = useState('');
  const [showCorrection, setShowCorrection] = useState(false);
  
  const reasonOptions = [
    'Too generic',
    'Not enough information',
    'Incorrect information',
    'Too formal/robotic',
    'Too casual',
    'Not personalized',
    'Bad emoji usage',
    'Other'
  ];
  
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center z-50 p-4"
    >
      <motion.div 
        initial={{ opacity: 0, scale: 0.95, y: 20 }}
        animate={{ opacity: 1, scale: 1, y: 0 }}
        exit={{ opacity: 0, scale: 0.95, y: 20 }}
        transition={{ duration: 0.2 }}
        className="bg-background-secondary rounded-xl max-w-lg w-full p-6 shadow-xl border border-border-light"
      >
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-3">
            <div className="rounded-full bg-red-100 p-2">
              <ThumbsDown className="h-5 w-5 text-red-500" />
            </div>
            <h3 className="text-xl font-medium text-text-primary font-headers">Improve this response</h3>
          </div>
          <button 
            onClick={onCancel}
            className="text-text-light hover:text-text-primary rounded-full p-1 transition-colors"
          >
            <X className="h-5 w-5" />
          </button>
        </div>
        
        <div className="mb-5 p-4 bg-background-tertiary rounded-lg text-sm border border-border-light/30">
          <div className="flex items-start gap-3 mb-2">
            <div className="rounded-full bg-accent-primary/10 p-1.5 mt-0.5">
              <MessageCircle className="h-4 w-4 text-accent-primary" />
            </div>
            <p className="text-text-secondary leading-relaxed">{message}</p>
          </div>
        </div>
        
        <div className="mb-5">
          <label className="block mb-2.5 font-medium text-text-primary">What's wrong with this response?</label>
          <div className="grid grid-cols-2 gap-2.5">
            {reasonOptions.map((opt) => (
              <button
                key={opt}
                type="button"
                className={`p-2.5 rounded-lg text-left transition-all ${
                  reason === opt 
                    ? 'bg-accent-primary/10 border-accent-primary text-accent-primary ring-1 ring-accent-primary font-medium' 
                    : 'bg-background-primary border-border-light/50 text-text-secondary hover:border-accent-primary/30'
                } border`}
                onClick={() => setReason(opt)}
              >
                {opt}
              </button>
            ))}
          </div>
        </div>
        
        <div className="mb-5">
          <button
            type="button"
            className="flex items-center gap-2 text-accent-primary hover:text-hover-primary transition-colors"
            onClick={() => setShowCorrection(!showCorrection)}
          >
            <Pencil className="h-4 w-4" />
            {showCorrection ? 'Hide correction field' : 'Suggest a better response'}
          </button>
        </div>
        
        {showCorrection && (
          <motion.div 
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="mb-5 overflow-hidden"
          >
            <label className="block mb-2 font-medium text-text-primary">How would you phrase it better?</label>
            <textarea
              value={correctedMessage}
              onChange={(e) => setCorrectedMessage(e.target.value)}
              className="w-full p-3.5 border border-border-light/50 rounded-lg bg-background-primary 
                         text-text-primary placeholder:text-text-light/50 focus:border-accent-primary 
                         focus:outline-none focus:ring-1 focus:ring-accent-primary/30"
              rows={4}
              placeholder="Write how you would have responded to the client..."
            />
            <p className="text-xs text-text-light mt-2">
              Your correction helps your AI learn your specific coaching style and language
            </p>
          </motion.div>
        )}
        
        <div className="flex justify-end gap-3 pt-2">
          <button
            type="button"
            className="px-4 py-2.5 border border-border-light rounded-lg hover:bg-background-tertiary
                      text-text-secondary transition-colors"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="px-5 py-2.5 bg-accent-primary text-white rounded-lg hover:bg-hover-primary 
                     disabled:bg-accent-primary/50 disabled:cursor-not-allowed transition-colors
                     flex items-center gap-2 shadow-sm"
            disabled={!reason}
            onClick={() => onSubmit(reason, showCorrection ? correctedMessage : null)}
          >
            <CheckCircle className="h-4 w-4" />
            Submit Feedback
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
} 