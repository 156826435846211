// src/components/NudgeManager.jsx


import React, { useState } from 'react';
import { X, Plus, Clock, Calendar } from 'lucide-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Input } from './ui/Input';
import { Button } from './ui/Button';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { Label } from './ui/Label';
import { collection, addDoc, deleteDoc, doc, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';

const NudgeManager = ({ nudges, onClose, onUpdateNudges, clientId, coachFirstName }) => {
  const [newNudgeText, setNewNudgeText] = useState('');
  const [newNudgeTime, setNewNudgeTime] = useState(new Date());
  const [popupMessage, setPopupMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const handleAddNudge = async () => {
    if (!newNudgeText.trim()) {
      setPopupMessage('Please enter a nudge message');
      setShowPopup(true);
      return;
    }

    if (newNudgeTime <= new Date()) {
      setPopupMessage('Please select a future date and time');
      setShowPopup(true);
      return;
    }

    try {
      const prefixedNudgeText = `${coachFirstName}: ${newNudgeText.trim()}`;
      const nudge = {
        text: prefixedNudgeText,
        scheduledTime: Timestamp.fromDate(newNudgeTime),
        sent: false,
        createdAt: Timestamp.now(),
      };

      const nudgesCollection = collection(db, 'users', clientId, 'nudges');
      await addDoc(nudgesCollection, nudge);

      setNewNudgeText('');
      setNewNudgeTime(new Date());
      onUpdateNudges();
    } catch (error) {
      console.error('Error adding nudge:', error);
      setPopupMessage('Error adding nudge. Please try again.');
      setShowPopup(true);
    }
  };

  const handleDeleteNudge = async (nudgeId) => {
    try {
      await deleteDoc(doc(db, 'users', clientId, 'nudges', nudgeId));
      onUpdateNudges();
    } catch (error) {
      console.error('Error deleting nudge:', error);
      setPopupMessage('Error deleting nudge. Please try again.');
      setShowPopup(true);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <Card className="w-full max-w-xl bg-background-secondary border border-border-medium shadow-lg">
        <CardHeader className="border-b border-border-light p-4">
          <div className="flex items-center justify-between">
            <CardTitle className="text-text-primary font-headers text-h2">
              Schedule Nudges
            </CardTitle>
            <Button
              variant="ghost"
              size="icon"
              onClick={onClose}
              className="h-8 w-8 rounded-full text-text-light hover:text-text-primary hover:bg-background-tertiary"
            >
              <X className="h-4 w-4" />
            </Button>
          </div>
        </CardHeader>
        
        <CardContent className="flex flex-col h-[calc(90vh-8rem)] p-6">
          {/* Nudges List */}
          <div className="flex-1 overflow-y-auto mb-6 pr-2 space-y-3">
            {nudges.length > 0 ? (
              nudges.map((nudge) => (
                <div
                  key={nudge.id}
                  className="group flex items-start gap-4 p-4 rounded-lg bg-background-primary hover:bg-background-tertiary transition-all duration-300"
                >
                  <div className="flex-1 min-w-0">
                    <p className="text-text-primary font-medium break-words">
                      {nudge.text}
                    </p>
                    <div className="flex items-center gap-2 mt-2 text-sm text-text-light">
                      <Clock className="h-4 w-4" />
                      <span>
                        {nudge.scheduledTime.toDate().toLocaleString()}
                      </span>
                    </div>
                  </div>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleDeleteNudge(nudge.id)}
                    className="opacity-0 group-hover:opacity-100 transition-all duration-300 text-text-light hover:text-red-500 hover:bg-red-50"
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </div>
              ))
            ) : (
              <div className="flex flex-col items-center justify-center h-32 text-text-light">
                <Calendar className="h-8 w-8 mb-2 text-accent-secondary" />
                <p className="font-medium">No nudges scheduled</p>
                <p className="text-sm">Create one using the form below</p>
              </div>
            )}
          </div>

          {/* Add Nudge Form */}
          <div className="border-t border-border-light pt-6 space-y-4">
            <div className="space-y-4">
              <div>
                <Label 
                  htmlFor="nudgeText" 
                  className="text-sm font-medium text-text-primary mb-1.5"
                >
                  Message
                </Label>
                <Input
                  id="nudgeText"
                  value={newNudgeText}
                  onChange={(e) => setNewNudgeText(e.target.value)}
                  placeholder="Enter your nudge message..."
                  className="w-full bg-background-primary border-border-light text-text-primary 
                    placeholder:text-text-light focus:border-accent-primary focus:ring-1 focus:ring-accent-primary"
                />
              </div>
              
              <div>
                <Label 
                  htmlFor="nudgeTime" 
                  className="text-sm font-medium text-text-primary mb-1.5"
                >
                  Schedule Time
                </Label>
                <DatePicker
                  id="nudgeTime"
                  selected={newNudgeTime}
                  onChange={(date) => setNewNudgeTime(date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  className="w-full p-2 bg-background-primary border border-border-light rounded-lg 
                    text-text-primary focus:border-accent-primary focus:ring-1 focus:ring-accent-primary"
                  wrapperClassName="w-full"
                />
              </div>
            </div>

            <Button 
              onClick={handleAddNudge}
              className="w-full bg-accent-primary hover:bg-hover-primary text-white font-medium 
                py-3 flex items-center justify-center gap-2 shadow-md hover:shadow-lg transition-all duration-300"
            >
              <Plus className="h-5 w-5" />
              <span>Schedule Nudge</span>
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default NudgeManager;