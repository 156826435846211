import React, { useState, useEffect } from 'react';
import { Button } from '../ui/Button';
import { Input } from '../ui/Input';
import { Edit2, X, Plus } from 'lucide-react';
import { collection, addDoc, updateDoc, deleteDoc, doc, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

const ProductsTab = ({ userId }) => {
  const [products, setProducts] = useState({
    supplements: [],
    grocery: [],
    miscellaneous: [],
  });
  const [activeCategory, setActiveCategory] = useState('supplements');
  const [newProduct, setNewProduct] = useState({
    name: '',
    tags: '',
    link: ''
  });

  const loadProducts = async () => {
    try {
      const categories = ['supplements', 'grocery', 'miscellaneous'];
      const newProducts = {};

      for (const category of categories) {
        const productsRef = collection(
          db,
          'coaches',
          userId,
          'products',
          'productCategory',
          category
        );
        const productsSnapshot = await getDocs(productsRef);
        newProducts[category] = productsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      }

      setProducts(newProducts);
    } catch (error) {
      console.error('Error loading products:', error);
    }
  };

  useEffect(() => {
    loadProducts();
  }, [userId]);

  const handleAddProduct = async () => {
    if (!newProduct.name.trim()) return;

    try {
      const productRef = collection(
        db,
        'coaches',
        userId,
        'products',
        'productCategory',
        activeCategory
      );

      await addDoc(productRef, newProduct);
      await loadProducts();
      setNewProduct({ name: '', tags: '', link: '' });
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };

  const removeProduct = async (productId) => {
    try {
      const productRef = doc(
        db,
        'coaches',
        userId,
        'products',
        'productCategory',
        activeCategory,
        productId
      );
      await deleteDoc(productRef);
      await loadProducts();
    } catch (error) {
      console.error('Error removing product:', error);
    }
  };

  return (
    <div className="space-y-4 p-6">
      <div className="flex gap-2 mb-6">
        {['supplements', 'grocery', 'miscellaneous'].map((category) => (
          <Button
            key={category}
            onClick={() => setActiveCategory(category)}
            className={`${
              activeCategory === category ? 'bg-blue-500' : 'bg-neutral-700'
            } text-white`}
          >
            {category.charAt(0).toUpperCase() + category.slice(1)}
          </Button>
        ))}
      </div>

      <div className="flex gap-3 mb-8">
        <Input
          placeholder="Product name"
          value={newProduct.name}
          onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
          className="bg-neutral-800 border-neutral-700 text-white"
        />
        <Input
          placeholder="Tags (comma separated)"
          value={newProduct.tags}
          onChange={(e) => setNewProduct({ ...newProduct, tags: e.target.value })}
          className="bg-neutral-800 border-neutral-700 text-white"
        />
        <Input
          placeholder="Link (optional)"
          value={newProduct.link}
          onChange={(e) => setNewProduct({ ...newProduct, link: e.target.value })}
          className="bg-neutral-800 border-neutral-700 text-white"
        />
        <Button
          onClick={handleAddProduct}
          className="bg-blue-500 text-white hover:bg-blue-600 px-6"
        >
          <Plus className="h-4 w-4" />
        </Button>
      </div>

      <div className="space-y-2">
        {products[activeCategory]?.map((product) => (
          <div
            key={product.id}
            className="flex justify-between items-center bg-neutral-800 p-4 rounded-lg hover:bg-neutral-750 transition-colors"
          >
            <div className="flex-1">
              <div className="flex items-center gap-2">
                <div className="text-white font-medium">{product.name}</div>
                {product.link && (
                  <a
                    href={product.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-400 hover:text-blue-300 text-sm"
                  >
                    Link →
                  </a>
                )}
              </div>
              {product.tags && (
                <div className="text-sm text-gray-400 mt-1">
                  {product.tags.split(',').map((tag, index) => (
                    <span
                      key={index}
                      className="inline-block bg-neutral-700 text-gray-300 rounded-full px-2 py-0.5 text-xs mr-2"
                    >
                      {tag.trim()}
                    </span>
                  ))}
                </div>
              )}
            </div>
            <Button
              size="icon"
              variant="ghost"
              onClick={() => removeProduct(product.id)}
              className="text-gray-400 hover:text-red-400"
            >
              <X className="h-4 w-4" />
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductsTab;