// src/components/overlays/TemplateManagementOverlay.jsx

import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import { Card, CardContent } from '../ui/Card';
import { Button } from '../ui/Button';
import { Plus } from 'lucide-react';

const TemplateManagementOverlay = ({ onClose, onTemplateSelect }) => {
  const { user } = useAuth();
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const templatesRef = collection(db, `coaches/${user.uid}/workoutTemplates`);
        const snapshot = await getDocs(templatesRef);
        const templateList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        templateList.sort((a, b) => b.createdAt?.toMillis() - a.createdAt?.toMillis());
        setTemplates(templateList);
      } catch (error) {
        console.error('Error fetching templates:', error);
      } finally {
        setLoading(false);
      }
    };
    if (user?.uid) {
      fetchTemplates();
    }
  }, [user]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
      <div className="bg-neutral-900 max-w-2xl w-full rounded-lg p-4 relative">
        <Button
          onClick={onClose}
          variant="ghost"
          className="absolute top-2 right-2 text-white hover:text-gray-300"
        >
          X
        </Button>
        <h2 className="text-xl font-bold mb-4 text-white">Select a Template</h2>
        {loading ? (
          <div className="flex justify-center py-8">Loading...</div>
        ) : (
          <div className="space-y-4 max-h-96 overflow-y-auto">
            {templates.map(t => (
              <Card
                key={t.id}
                className="bg-neutral-800 hover:bg-neutral-700 cursor-pointer transition-colors"
                onClick={() => onTemplateSelect(t)}
              >
                <CardContent className="p-4">
                  <div className="flex items-center justify-between">
                    <div>
                      <h3 className="font-semibold text-white">{t.name}</h3>
                      <p className="text-sm text-gray-400">{t.description}</p>
                      <p className="text-sm text-blue-400">{t.exercises?.length || 0} exercises</p>
                    </div>
                    {t.headerImageUrl && (
                      <img
                        src={t.headerImageUrl}
                        alt="Template"
                        className="h-16 w-16 object-cover rounded"
                      />
                    )}
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TemplateManagementOverlay;
