// src/components/ExpandedSetsEditor.jsx

import React from 'react';
import { Button } from './ui/Button';
import { Input } from './ui/Input';
import { Trash2, Plus, ChevronUp, ChevronDown } from 'lucide-react';

const ExpandedSetsEditor = ({ sets = [], onChange, onClose }) => {
  // Ensure sets is always an array
  const setsArray = Array.isArray(sets) ? sets : [];

  const addSet = () => {
    const newSets = [...setsArray, { reps: '', weight: null }];
    onChange(newSets);
  };

  const removeSet = (index) => {
    const newSets = setsArray.filter((_, i) => i !== index);
    onChange(newSets);
  };

  const updateSet = (index, field, value) => {
    const newSets = setsArray.map((set, i) => {
      if (i === index) {
        return { ...set, [field]: value };
      }
      return set;
    });
    onChange(newSets);
  };

  return (
    <div className="mt-4 p-4 bg-neutral-800 rounded-lg">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold text-white">Edit Sets</h3>
        <Button 
          variant="ghost" 
          size="sm"
          onClick={onClose}
          className="text-gray-400 hover:text-white"
        >
          <ChevronUp className="h-4 w-4" />
        </Button>
      </div>
      
      <div className="space-y-3">
        {setsArray.map((set, index) => (
          <div key={index} className="grid grid-cols-12 gap-2 items-center">
            <span className="text-sm text-gray-400 col-span-2">Set {index + 1}</span>
            <div className="col-span-4">
              <Input
                type="text"
                value={set.reps}
                onChange={(e) => updateSet(index, 'reps', e.target.value)}
                placeholder="Reps"
                className="bg-neutral-700 border-neutral-600"
              />
            </div>
            <div className="col-span-4">
              <Input
                type="number"
                value={set.weight || ''}
                onChange={(e) => updateSet(index, 'weight', e.target.value ? parseFloat(e.target.value) : null)}
                placeholder="Weight"
                className="bg-neutral-700 border-neutral-600"
              />
            </div>
            <div className="col-span-2 flex justify-end">
              <Button
                variant="ghost"
                size="sm"
                onClick={() => removeSet(index)}
                className="text-red-500 hover:text-red-400"
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            </div>
          </div>
        ))}
      </div>
      
      <Button
        onClick={addSet}
        className="w-full mt-4 bg-neutral-700 hover:bg-neutral-600"
      >
        <Plus className="h-4 w-4 mr-2" />
        Add Set
      </Button>
    </div>
  );
};

export default ExpandedSetsEditor;