import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from './ui/Dialog';
import { Button } from './ui/Button';
import { Card } from './ui/Card';
import { Loader2, Plus, Minus, Calendar, CreditCard, Users } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { API_URLS } from '../constants/api';
import { cn } from '../lib/utils';

const SubscriptionModal = ({ 
    isOpen, 
    onClose, 
    currentSeats, 
    requestedSeats,
    onConfirm,
    isLoading,
    trialEndsAt,
    subscriptionStatus
  }) => {
    const [seats, setSeats] = useState(requestedSeats || currentSeats);
    const [proration, setProration] = useState(null);
    const { user } = useAuth();
    const [trialInfo, setTrialInfo] = useState(null);
    const PRICE_PER_SEAT = 5; // Price per seat in USD

    // Update seats when currentSeats changes
    useEffect(() => {
      setSeats(requestedSeats || currentSeats);
    }, [currentSeats, requestedSeats]);

    useEffect(() => {
      // Calculate trial days remaining
      if (trialEndsAt && subscriptionStatus === 'trialing') {
        const now = new Date();
        // Convert Firebase Timestamp to Date if necessary
        const trialEnd = trialEndsAt instanceof Date ? trialEndsAt : trialEndsAt.toDate();
        const daysRemaining = Math.ceil((trialEnd.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
        setTrialInfo({ daysRemaining: Math.max(0, daysRemaining) });
      }
    }, [trialEndsAt, subscriptionStatus]);
  
    useEffect(() => {
      const fetchProrationAndTrial = async () => {
        if (!user || seats === currentSeats) return;
        
        try {
          const response = await fetch(API_URLS.getProrationCost, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${await user.getIdToken()}`,
            },
            body: JSON.stringify({
              seats: seats,
              currentSeats: currentSeats // Add current seats to the request
            }),
          });
  
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to fetch proration');
          }
          
          const data = await response.json();
          setProration(data.proration);
        } catch (error) {
          console.error('Error fetching proration:', error);
        }
      };
  
      fetchProrationAndTrial();
    }, [seats, currentSeats, user]);

    const calculateCost = () => {
      const monthlyCost = seats * PRICE_PER_SEAT;
      return {
        monthly: monthlyCost,
        difference: (seats - currentSeats) * PRICE_PER_SEAT
      };
    };
    
    const costs = calculateCost();

    const getSubscriptionStatus = () => {
      if (subscriptionStatus === 'trialing' && trialInfo?.daysRemaining > 0) {
        return `Trial Period (${trialInfo.daysRemaining} days remaining)`;
      } else if (subscriptionStatus === 'active') {
        return 'Active Subscription';
      }
      return 'Inactive Subscription';
    };

    const handleConfirm = async () => {
      try {
        await onConfirm(seats);
        onClose();
      } catch (error) {
        console.error('Error confirming subscription update:', error);
      }
    };

    return (
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="bg-background-secondary border-2 border-border-light max-w-xl">
          <DialogHeader className="space-y-3">
            <DialogTitle className="font-headers text-2xl text-text-primary">
              Manage Client Spots
            </DialogTitle>
            <DialogDescription className="text-text-light font-body">
              {subscriptionStatus === 'trialing' ? (
                <div className="flex items-center gap-2 text-accent-primary">
                  <Calendar className="h-4 w-4" />
                  <span>Trial Period - {trialInfo?.daysRemaining} days remaining</span>
                </div>
              ) : (
                <span>Adjust the number of client spots in your program</span>
              )}
            </DialogDescription>
          </DialogHeader>

          <div className="space-y-6 py-4">
            {/* Seat Selection */}
            <Card className="p-6 bg-background-tertiary border border-border-light">
              <div className="space-y-4">
                <div className="flex items-center gap-3 text-text-primary">
                  <Users className="h-5 w-5 text-accent-primary" />
                  <span className="font-medium">Client Spots</span>
                </div>
                
                <div className="flex items-center justify-between bg-background-secondary p-4 rounded-lg">
                  <span className="text-text-secondary">Number of spots:</span>
                  <div className="flex items-center gap-4">
                    <Button
                      variant="outline"
                      size="icon"
                      onClick={() => setSeats(Math.max(1, seats - 1))}
                      disabled={seats <= 1}
                      className="h-8 w-8 rounded-full border-2 border-accent-primary text-accent-primary hover:bg-accent-primary hover:text-white"
                    >
                      <Minus className="h-4 w-4" />
                    </Button>
                    <span className="w-12 text-center font-medium text-lg">{seats}</span>
                    <Button
                      variant="outline"
                      size="icon"
                      onClick={() => setSeats(seats + 1)}
                      className="h-8 w-8 rounded-full border-2 border-accent-primary text-accent-primary hover:bg-accent-primary hover:text-white"
                    >
                      <Plus className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
              </div>
            </Card>

            {/* Cost Breakdown */}
            <Card className="p-6 bg-background-tertiary border border-border-light">
              <div className="space-y-4">
                <div className="flex items-center gap-3 text-text-primary">
                  <CreditCard className="h-5 w-5 text-accent-primary" />
                  <span className="font-medium">Cost Breakdown</span>
                </div>

                <div className="space-y-3 font-body">
                  <div className="flex justify-between items-center p-3 bg-background-secondary rounded-lg">
                    <span className="text-text-light">Current monthly</span>
                    <span className="font-medium text-text-primary">${(currentSeats * PRICE_PER_SEAT).toFixed(2)}</span>
                  </div>

                  <div className="flex justify-between items-center p-3 bg-background-secondary rounded-lg">
                    <span className="text-text-light">New monthly</span>
                    <span className="font-medium text-text-primary">${(seats * PRICE_PER_SEAT).toFixed(2)}</span>
                  </div>

                  {proration && proration.amount !== 0 && (
                    <div className="border-t border-border-light pt-4 mt-4 space-y-2">
                      <div className="flex justify-between items-center p-3 bg-accent-primary/10 rounded-lg">
                        <span className="text-accent-primary">Prorated charge</span>
                        <span className="font-medium text-accent-primary">${proration.amount.toFixed(2)}</span>
                      </div>
                      <p className="text-xs text-text-light px-3">
                        This covers the remaining {proration.daysRemaining} days of your current billing period. 
                        Your next full charge of ${(seats * PRICE_PER_SEAT).toFixed(2)} will be on {new Date(proration.nextPaymentDate).toLocaleDateString()}.
                      </p>
                    </div>
                  )}

                  <div className="flex justify-between items-center p-4 bg-accent-tertiary/10 rounded-lg mt-4 font-medium">
                    <span className="text-accent-tertiary">Due now</span>
                    <span className="text-accent-tertiary text-lg">
                      ${trialInfo?.daysRemaining > 0 ? "0.00" : (proration?.amount || 0).toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
            </Card>
          </div>

          <div className="flex justify-end gap-3 pt-4 border-t border-border-light">
            <Button
              variant="outline"
              onClick={onClose}
              className="border-2 border-accent-secondary text-accent-secondary hover:bg-accent-secondary hover:text-white"
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirm}
              disabled={isLoading || seats === currentSeats}
              variant="primary"
              className="min-w-[120px]"
            >
              {isLoading ? (
                <div className="flex items-center gap-2">
                  <Loader2 className="h-4 w-4 animate-spin" />
                  <span>Processing...</span>
                </div>
              ) : (
                'Confirm Changes'
              )}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
};

export default SubscriptionModal;