// src/components/ActivateClient.jsx

import React, { useState } from 'react';
import { Input } from './ui/Input';
import { Button } from './ui/Button';
import { X } from 'lucide-react';

const ActivateClient = ({ isOpen, onClose, onActivate, clientData, setClientData }) => {
  const handleActivate = () => {
    onActivate(clientData);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-[#1C1C1C] p-6 rounded-md w-96 relative">
        <button
          className="absolute top-2 right-2 text-[#E0E0E0] hover:text-[#6495ED]"
          onClick={onClose}
        >
          <X className="h-5 w-5" />
        </button>
        <h2 className="text-lg font-semibold text-[#FFFFFF] mb-4">Activate Client</h2>
        <div className="space-y-2">
          <Input
            placeholder="Name"
            value={clientData.name}
            onChange={(e) =>
              setClientData({ ...clientData, name: e.target.value })
            }
            className="bg-[#333333] text-[#E0E0E0]"
          />
          <Input
            placeholder="Email"
            value={clientData.email}
            onChange={(e) =>
              setClientData({ ...clientData, email: e.target.value })
            }
            className="bg-[#333333] text-[#E0E0E0]"
          />
          <Input
            placeholder="Phone Number"
            value={clientData.phoneNumber}
            onChange={(e) =>
              setClientData({ ...clientData, phoneNumber: e.target.value })
            }
            className="bg-[#333333] text-[#E0E0E0]"
          />
          <Button
            onClick={handleActivate}
            className="bg-[#6495ED] text-[#FFFFFF] hover:bg-[#4F7BE8] w-full"
          >
            Activate
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ActivateClient;