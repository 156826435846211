import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from './ui/Dialog';
import { Button } from './ui/Button';
import { Card } from './ui/Card';
import { Loader2, Plus, Minus, Calendar } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { API_URLS } from '../constants/api';
import { cn } from '../lib/utils';

const SubscriptionModal = ({ 
    isOpen, 
    onClose, 
    currentSeats, 
    requestedSeats,
    onConfirm,
    isLoading,
    trialEndsAt,
    subscriptionStatus
  }) => {
    const [seats, setSeats] = useState(requestedSeats || currentSeats);
    const [proration, setProration] = useState(null);
    const { user } = useAuth();
    const [trialInfo, setTrialInfo] = useState(null);
    const PRICE_PER_SEAT = 5; // Price per seat in USD

    // Update seats when currentSeats changes
    useEffect(() => {
      setSeats(requestedSeats || currentSeats);
    }, [currentSeats, requestedSeats]);

    useEffect(() => {
      // Calculate trial days remaining
      if (trialEndsAt && subscriptionStatus === 'trialing') {
        const now = new Date();
        // Convert Firebase Timestamp to Date if necessary
        const trialEnd = trialEndsAt instanceof Date ? trialEndsAt : trialEndsAt.toDate();
        const daysRemaining = Math.ceil((trialEnd.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
        setTrialInfo({ daysRemaining: Math.max(0, daysRemaining) });
      }
    }, [trialEndsAt, subscriptionStatus]);
  
    useEffect(() => {
      const fetchProrationAndTrial = async () => {
        if (!user || seats === currentSeats) return;
        
        try {
          const response = await fetch(API_URLS.getProrationCost, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${await user.getIdToken()}`,
            },
            body: JSON.stringify({
              seats: seats,
              currentSeats: currentSeats // Add current seats to the request
            }),
          });
  
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to fetch proration');
          }
          
          const data = await response.json();
          setProration(data.proration);
        } catch (error) {
          console.error('Error fetching proration:', error);
        }
      };
  
      fetchProrationAndTrial();
    }, [seats, currentSeats, user]);

    const calculateCost = () => {
      const monthlyCost = seats * PRICE_PER_SEAT;
      return {
        monthly: monthlyCost,
        difference: (seats - currentSeats) * PRICE_PER_SEAT
      };
    };
    
    const costs = calculateCost();

    const getSubscriptionStatus = () => {
      if (subscriptionStatus === 'trialing' && trialInfo?.daysRemaining > 0) {
        return `Trial Period (${trialInfo.daysRemaining} days remaining)`;
      } else if (subscriptionStatus === 'active') {
        return 'Active Subscription';
      }
      return 'Inactive Subscription';
    };

    const handleConfirm = async () => {
      try {
        await onConfirm(seats);
        onClose();
      } catch (error) {
        console.error('Error confirming subscription update:', error);
      }
    };

    return (
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="bg-neutral-900 text-white">
          <DialogHeader>
            <DialogTitle>Update Subscription</DialogTitle>
            <DialogDescription className="text-gray-400">
              {getSubscriptionStatus()}
            </DialogDescription>
          </DialogHeader>

          <div className="space-y-6">
            {/* Current Plan Info */}
            <Card className="bg-neutral-800 p-4">
              <div className="flex items-center gap-2 text-blue-400 mb-2">
                <Calendar className="h-4 w-4" />
                <span className="text-sm font-medium">Current Plan</span>
              </div>
              <div className="space-y-1">
                <p className="text-sm">Current Seats: {currentSeats}</p>
                <p className="text-sm">Status: {subscriptionStatus}</p>
                {trialInfo && trialInfo.daysRemaining > 0 && (
                  <p className="text-sm text-blue-400">
                    Trial ends in {trialInfo.daysRemaining} days
                  </p>
                )}
              </div>
            </Card>

            {/* Seat Management */}
            <div className="flex items-center justify-between">
              <span>Update Seats:</span>
              <div className="flex items-center gap-4">
                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => setSeats(Math.max(1, seats - 1))}
                  disabled={seats <= 1}
                  className="bg-neutral-800 hover:bg-neutral-700"
                >
                  <Minus className="h-4 w-4" />
                </Button>
                <span className="w-8 text-center">{seats}</span>
                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => setSeats(seats + 1)}
                  className="bg-neutral-800 hover:bg-neutral-700"
                >
                  <Plus className="h-4 w-4" />
                </Button>
              </div>
            </div>

            {/* Cost Breakdown */}
            <Card className="bg-neutral-800 p-4 space-y-3">
              <h3 className="font-medium">Cost Breakdown</h3>
              <div className="space-y-2 text-sm">
                <div className="flex justify-between">
                  <span>Current monthly cost:</span>
                  <span>${(currentSeats * PRICE_PER_SEAT).toFixed(2)}</span>
                </div>
                <div className="flex justify-between">
                  <span>New monthly cost:</span>
                  <span>${costs.monthly.toFixed(2)}</span>
                </div>
                {proration && proration.amount !== 0 && (
                  <>
                    <div className="flex justify-between text-blue-400 border-t border-neutral-700 pt-2">
                      <span>Immediate charge for {proration.daysRemaining} days:</span>
                      <span>${proration.amount.toFixed(2)}</span>
                    </div>
                    <div className="text-gray-400 text-xs">
                      <p>This is a prorated charge for the remaining days in your current billing period. 
                        On {new Date(proration.nextPaymentDate).toLocaleDateString()}, you'll be charged the new monthly rate of ${costs.monthly.toFixed(2)}.</p>
                    </div>
                  </>
                )}
                <div className="flex justify-between font-medium pt-2 border-t border-neutral-700">
                  <span>Due now:</span>
                  <span>
                    ${trialInfo?.daysRemaining > 0 ? "0.00" : (proration?.amount || 0).toFixed(2)}
                  </span>
                </div>
              </div>
            </Card>

            <div className="flex justify-end gap-3 pt-4">
              <Button
                variant="outline"
                onClick={onClose}
                className="bg-neutral-800 hover:bg-neutral-700"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                disabled={isLoading || seats === currentSeats}
                className="bg-blue-600 hover:bg-blue-700"
              >
                {isLoading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Updating...
                  </>
                ) : (
                  'Confirm Changes'
                )}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
};

export default SubscriptionModal;