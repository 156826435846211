// src/components/ExpandedSetsEditor.jsx

import React from 'react';
import { Button } from './ui/Button';
import { Input } from './ui/Input';
import { Trash2, Plus, ChevronUp, ChevronDown, ListOrdered } from 'lucide-react';

const ExpandedSetsEditor = ({ sets = [], onChange }) => {
  // Ensure sets is always an array with consistent data structure
  const setsArray = Array.isArray(sets) ? sets : [];

  const addSet = () => {
    try {
      // Match the initial state structure from TemplateCreation
      const newSets = [...setsArray, { reps: 8, weight: 0 }];
      onChange(newSets);
    } catch (error) {
      console.error('Error in addSet:', error);
    }
  };

  const removeSet = (index) => {
    try {
      const newSets = setsArray.filter((_, i) => i !== index);
      onChange(newSets);
    } catch (error) {
      console.error('Error in removeSet:', error);
    }
  };

  const updateSet = (index, field, value) => {
    try {
      const newSets = setsArray.map((set, i) => {
        if (i === index) {
          return { ...set, [field]: value };
        }
        return set;
      });
      onChange(newSets);
    } catch (error) {
      console.error('Error in updateSet:', error);
    }
  };

  return (
    <div className="rounded-xl border-2 border-accent-primary/10 bg-background-secondary p-6">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-lg font-semibold text-text-primary flex items-center gap-2">
          <div className="p-2 bg-accent-primary/10 rounded-lg">
            <ListOrdered className="h-5 w-5 text-accent-primary" />
          </div>
          Sets Configuration
        </h3>
      </div>
      
      <div className="space-y-4">
        {/* Header */}
        <div className="grid grid-cols-12 gap-4 px-4 text-sm font-medium text-text-light">
          <div className="col-span-2">Set</div>
          <div className="col-span-4">Reps</div>
          <div className="col-span-4">Weight</div>
          <div className="col-span-2"></div>
        </div>

        {/* Sets */}
        <div className="space-y-3">
          {setsArray.map((set, index) => (
            <div 
              key={index} 
              className="group grid grid-cols-12 gap-4 items-center bg-background-tertiary/50 rounded-lg p-4 hover:bg-accent-primary/5 transition-colors"
            >
              <div className="col-span-2 flex items-center gap-2">
                <div className="h-8 w-8 rounded-lg bg-accent-primary/10 flex items-center justify-center text-accent-primary font-semibold">
                  {index + 1}
                </div>
              </div>
              <div className="col-span-4">
                <Input
                  type="number"
                  value={set.reps || 0}
                  onChange={(e) => updateSet(index, 'reps', e.target.value)}
                  className="h-10 text-center font-medium border-2 focus:border-accent-primary rounded-lg"
                  min="0"
                />
              </div>
              <div className="col-span-4">
                <Input
                  type="number"
                  value={set.weight || 0}
                  onChange={(e) => updateSet(index, 'weight', e.target.value)}
                  className="h-10 text-center font-medium border-2 focus:border-accent-primary rounded-lg"
                  min="0"
                />
              </div>
              <div className="col-span-2 flex justify-end">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => removeSet(index)}
                  className="opacity-0 group-hover:opacity-100 text-red-400 hover:text-red-300 hover:bg-red-500/10"
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
      
      <Button
        onClick={addSet}
        className="w-full mt-6 bg-accent-primary/10 hover:bg-accent-primary/20 text-accent-primary border-2 border-dashed border-accent-primary/30 h-12"
      >
        <Plus className="h-5 w-5 mr-2" />
        Add Set
      </Button>
    </div>
  );
};

export default ExpandedSetsEditor;
