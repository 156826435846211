// src/components/AccountPage.js
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from './ui/Card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/Tabs';
import { Button } from './ui/Button';
import { Progress } from './ui/Progress';
import { Settings, HelpCircle, MessageCircle, X, ChevronLeft, ChevronRight, CheckCircle } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { motion } from 'framer-motion';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { collection, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { FirstAccountTour } from '../tours';
import Joyride from 'react-joyride';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { cn } from '../lib/utils';
import AIChatOverlay from './AIChatOverlay';
import { buttonStyles } from './ui/Styles';

import NutritionPhilosophyEditor from './AccountPageTabs/NutritionPhilosophyEditor';
import ProductsTab from './AccountPageTabs/ProductsTab';
import MessagingTab from './AccountPageTabs/MessagingTab';
import RoutineBuilder from './AccountPageTabs/RoutineBuilder';



console.log("FirstAccountTour content:", FirstAccountTour);

const AccountPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [activeTab, setActiveTab] = useState("nutrition");
  const [isFirstSignup, setIsFirstSignup] = useState(false);
  const [showTourModal, setShowTourModal] = useState(false);
  const [tourStep, setTourStep] = useState(0);
  const [showAiDemo, setShowAiDemo] = useState(false);
  const [demoResponses, setDemoResponses] = useState('');
  const [isGeneratingDemo, setIsGeneratingDemo] = useState(false);
  const [displayedText, setDisplayedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showChatOverlay, setShowChatOverlay] = useState(false);
  const [demoMessages, setDemoMessages] = useState([]);
  const [showLearningModal, setShowLearningModal] = useState(false);
  const [learningResponses, setLearningResponses] = useState([]);
  const [pendingDemoClient, setPendingDemoClient] = useState(null);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    console.log("AccountPage mounted");
    const checkFirstTime = async () => {
      if (user?.uid) {
        try {
          const userDoc = await getDoc(doc(db, 'coaches', user.uid));
          
          if (userDoc.exists()) {
            const isFirstTime = userDoc.data().firstTime === true;
            setShowWelcomeModal(isFirstTime);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setShowWelcomeModal(false);
        }
      }
    };

    checkFirstTime();
  }, [user?.uid]);

  useEffect(() => {
    console.log("Welcome modal state:", showWelcomeModal);
  }, [showWelcomeModal]);

  useEffect(() => {
    console.log("Tour modal state:", showTourModal);
  }, [showTourModal]);

  const handleStartSetup = () => {
    console.log("Starting setup");
    setShowWelcomeModal(false);
    setShowTourModal(true);
    setTourStep(0);
    console.log("Tour modal and step set");
  };

  useEffect(() => {
    if (demoResponses && !isGeneratingDemo) {
      const timer = setTimeout(() => {
        if (currentIndex < demoResponses.length) {
          setDisplayedText(prev => prev + demoResponses[currentIndex]);
          setCurrentIndex(prev => prev + 1);
        }
      }, 30);

      return () => clearTimeout(timer);
    }
  }, [currentIndex, demoResponses, isGeneratingDemo]);

  const generateDemoResponse = async () => {
    setIsGeneratingDemo(true);
    try {
      // Gather all coach data
      const coachData = {
        products: [],
        nutrition: {},
        routines: {},
        daily: {}
      };

      // Get all products
      const productsRef = collection(db, 'coaches', user.uid, 'products');
      const productsSnap = await getDocs(productsRef);
      coachData.products = productsSnap.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Get nutrition philosophy
      const nutritionRef = doc(db, 'coaches', user.uid, 'philosophy', 'nutrition');
      const nutritionSnap = await getDoc(nutritionRef);
      if (nutritionSnap.exists()) {
        coachData.nutrition = nutritionSnap.data();
      }

      // Get daily routines
      const dailyRef = doc(db, 'coaches', user.uid, 'daily', 'morning');
      const dailySnap = await getDoc(dailyRef);
      if (dailySnap.exists()) {
        coachData.daily = dailySnap.data();
      }

      // Get routines
      const routinesRef = collection(db, 'coaches', user.uid, 'routines');
      const routinesSnap = await getDocs(routinesRef);
      coachData.routines = routinesSnap.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      console.log("Gathered coach data:", coachData);

      const functions = getFunctions(undefined, 'us-central1');
      const generateDemoResponseFunction = httpsCallable(
        functions, 
        'generateDemoResponse',
        { timeout: 300000 }
      );
      
      const result = await generateDemoResponseFunction({ coachData });
      console.log("Function result:", result);
      
      if (result.data.success) {
        setDemoResponses(result.data.data);
        setShowAiDemo(true);
      } else {
        throw new Error(result.data.message || 'Failed to generate responses');
      }
    } catch (error) {
      console.error('Error generating demo responses:', error);
      setDemoResponses('Error generating responses. Please try again.');
    } finally {
      setIsGeneratingDemo(false);
    }
  };
  const handleNextStep = async () => {
    console.log("Current tour step:", tourStep);

    if (tourStep === 0) {
      const docRef = doc(db, 'coaches', user.uid, 'philosophy', 'nutrition');
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists() && docSnap.data().selectedDiet === 'keto') {
        setTourStep(1);
        setActiveTab("routines");
      } else {
        alert("Please select Keto diet to continue");
      }
    } else if (tourStep === 1) {
      setTourStep(2);
      setTimeout(() => {
        setActiveTab("products");
      }, 100);
    } else if (tourStep === 2) {
      // Check if protein powder is selected
      const productsRef = collection(db, 'coaches', user.uid, 'products');
      const productsSnapshot = await getDocs(productsRef);
      const hasProteinPowder = productsSnapshot.docs.some(
        doc => doc.data().name === 'Protein Powder'
      );

      if (hasProteinPowder) {
        setTourStep(3);
        setTimeout(() => {
          setActiveTab("messaging");
        }, 100);
      } else {
        alert("Please select Protein Powder to continue");
      }
    } else if (tourStep === 3) {
      setTourStep(5);
      setShowTourModal(false);
      setShowLearningModal(true);
    } else if (tourStep === 5) {
      await generateDemoResponse();
      setTourStep(6);
    } else if (tourStep === 6) {
      // Clean up all modals and states
      setShowAiDemo(false);
      setShowTourModal(false);
      setShowWelcomeModal(false);
      setDemoResponses('');
      setDisplayedText('');
      setCurrentIndex(0);
      
      if (user?.uid) {
        try {
          await updateDoc(doc(db, 'coaches', user.uid), {
            firstTime: false
          });
        } catch (error) {
          console.error("Error updating first-time status:", error);
        }
      }
    }
  };

  const handleBack = () => {
    if (tourStep === 1) {
      setActiveTab("nutrition");
      setTourStep(0);
    } else if (tourStep === 2) {
      setActiveTab("routines");
      setTourStep(1);
    } else if (tourStep === 3) {
      setActiveTab("products");
      setTourStep(2);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [demoMessages]);

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!input.trim() || !coachContext) return;

      const userMessage = input.trim();
      setInput('');

      // Get previous message and last 5 messages
      const previousMessage = messages.length > 0 ? messages[messages.length - 1].content : null;
      const lastFiveMessages = messages.slice(-5).map(msg => msg.content).join('\n');

      setMessages(prev => [...prev, { role: 'user', content: userMessage }]);
      setIsLoading(true);

    try {
      const functions = getFunctions();
      const generateDemoResponse = httpsCallable(functions, 'generateDemoResponse');
      
      const result = await generateDemoResponse({
        message: input.trim(),
        coachData: {
          products: [],
          nutrition: {},
          routines: {},
          daily: {}
        },
        previousMessage: messages.length > 0 ? messages[messages.length - 1].content : null,
        lastFiveMessages: messages.slice(-5).map(msg => msg.content).join('\n')
      });
      
      if (result.data.success && result.data.data) {
        setMessages(prev => [...prev, { 
          role: 'assistant', 
          content: result.data.data 
        }]);
      } else {
        throw new Error(result.data.message || 'No response received');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const AiDemoModal = () => (
    <div className="fixed inset-0 flex items-center justify-center z-[99999]">
      <div className="fixed inset-0 bg-text-primary bg-opacity-5 backdrop-blur-sm" />
      <motion.div 
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        className="relative bg-background-secondary p-8 rounded-lg max-w-2xl mx-4 shadow-lg border border-border-light"
      >
        <div className="font-body text-text-primary min-h-[200px]">
          {tourStep === 5 ? (
            <div className="text-center">
              <h2 className="text-h2 font-headers font-medium mb-4">Now let's check your AI in action!</h2>
              <p className="text-text-secondary">Your AI twin is ready to demonstrate how it will interact with clients</p>
            </div>
          ) : (
            isGeneratingDemo ? (
              <div className="flex items-center justify-center gap-3">
                <span className="animate-spin rounded-full h-5 w-5 border-2 border-accent-primary border-t-transparent" />
                <span className="text-text-secondary">Generating responses...</span>
              </div>
            ) : (
              <div className="space-y-4">
                {demoResponses}
              </div>
            )
          )}
        </div>
        <button
          onClick={async () => {
            if (user?.uid) {
              try {
                await updateDoc(doc(db, 'coaches', user.uid), {
                  firstTime: false
                });
              } catch (error) {
                console.error("Error updating firstTime status:", error);
              }
            }
            setShowAiDemo(true);
            setShowChatOverlay(true);
          }}
          className={`${buttonStyles.primary} w-full mt-6`}
        >
          {tourStep === 5 ? "Show Me" : "Start Chatting"}
        </button>
      </motion.div>
    </div>
  );

  const TourModal = () => {
    if (!showTourModal) return null;
    
    return (
      <motion.div 
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        className={`fixed ${getPosition()} transform -translate-x-1/2 z-[1000]`}
      >
        <div className="bg-background-secondary p-6 rounded-lg shadow-lg border border-border-light max-w-md mx-4">
          <p className="font-body text-text-secondary mb-4">{FirstAccountTour[tourStep]?.content}</p>
          <div className="flex justify-between gap-3">
            {tourStep > 0 && tourStep < 5 && (
              <button
                onClick={handleBack}
                className="px-4 py-2 rounded-lg border border-border-medium hover:bg-background-tertiary transition-colors text-text-primary font-body"
              >
                Back
              </button>
            )}
            <button
              onClick={handleNextStep}
              className={`${buttonStyles.primary} ml-auto`}
            >
              {tourStep === 5 ? "Show Me" : tourStep === 4 ? "Next" : "Next"}
            </button>
          </div>
        </div>
      </motion.div>
    );
  };

  const getPosition = () => {
    console.log("Getting position for step:", tourStep);
    switch (tourStep) {
      case 0:
        return 'top-24 left-[35%]';
      case 1:
        return 'top-24 left-1/2';
      case 2:
        return 'top-24 left-[60%]';
      case 3:
        return 'top-24 left-[85%]';
      default:
        return 'top-24 left-1/2';
    }
  };

  useEffect(() => {
    const loadDemoMessages = async () => {
      if (!user) return;
      
      try {
        const demoMessagesRef = collection(db, 'coaches', user.uid, 'demoMessages');
        const messagesSnap = await getDocs(demoMessagesRef);
        const messages = messagesSnap.docs
          .map(doc => doc.data())
          .sort((a, b) => a.timestamp?.seconds - b.timestamp?.seconds);
        setDemoMessages(messages);
      } catch (error) {
        console.error('Error loading demo messages:', error);
      }
    };

    loadDemoMessages();
  }, [user]);

  const LearningOverlay = ({ onClose }) => {
    const [answer, setAnswer] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [learningResponses, setLearningResponses] = useState([]);
    const questions = [
      "Hey, I couldnt complete my last workout",
      "I don't like tempeh, what other vegetarian protein sources should I have?",
      "Just did biceps to failure",
      "Not in the mood to workout today",
      "I do not like fish",
      'Yo i didnt like the last meal',
      'Need some motivation. Rough day.',
      'My back is sore',
      'I dont like bicep curls is there aything else I can do?',
      'Should I intermittent fast',
    ];

    // Load existing responses on mount
    useEffect(() => {
      const loadExistingResponses = async () => {
        if (!user?.uid) return;
        
        try {
          const qaRef = collection(db, 'coaches', user.uid, 'trainingQA');
          const qaSnap = await getDocs(qaRef);
          const responses = qaSnap.docs.map(doc => ({
            question: doc.data().question,
            answer: doc.data().answer
          }));
          
          setLearningResponses(responses);
          // Set current question index to the number of existing responses
          setCurrentQuestionIndex(responses.length);
        } catch (error) {
          console.error('Error loading existing responses:', error);
        }
      };

      loadExistingResponses();
    }, [user?.uid]);

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!answer.trim()) return;

      setIsLoading(true);
      try {
        // Store the Q&A pair
        const qaRef = collection(db, 'coaches', user.uid, 'trainingQA');
        await addDoc(qaRef, {
          question: questions[currentQuestionIndex],
          answer: answer.trim(),
          timestamp: serverTimestamp()
        });

        // Add to local state
        setLearningResponses(prev => [...prev, { 
          question: questions[currentQuestionIndex], 
          answer: answer.trim() 
        }]);
        
        // Clear answer and move to next question
        setAnswer('');
        setCurrentQuestionIndex(prev => prev + 1);
      } catch (error) {
        console.error('Error storing Q&A:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const isComplete = learningResponses.length === questions.length;

    return (
      <div className="fixed inset-0 flex items-center justify-center z-[99999]">
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="absolute inset-0 bg-text-primary bg-opacity-5 backdrop-blur-sm"
          onClick={onClose}
        />
        
        <motion.div 
          initial={{ scale: 0.95, opacity: 0, y: 20 }}
          animate={{ scale: 1, opacity: 1, y: 0 }}
          className="bg-background-secondary rounded-lg w-full max-w-3xl mx-4 h-[85vh] flex flex-col relative shadow-lg border border-border-light"
        >
          {/* Header */}
          <div className="flex justify-between items-center p-6 border-b border-border-light">
            <div>
              <h2 className="font-headers text-h2 font-medium text-text-primary mb-1">
                Train Your AI Coach
              </h2>
              <p className="text-text-light font-body text-small">
                Help your AI understand how you'd respond to common client messages
              </p>
            </div>
            <button 
              onClick={onClose}
              className="p-2 hover:bg-background-tertiary rounded-full transition-colors"
            >
              <X className="w-5 h-5 text-text-light" />
            </button>
          </div>

          {/* Progress Bar */}
          <div className="px-6 py-4 border-b border-border-light bg-background-primary">
            <div className="flex justify-between items-center mb-2">
              <span className="font-body text-small text-text-secondary">
                {!isComplete ? `Question ${currentQuestionIndex + 1} of ${questions.length}` : 'Training Complete!'}
              </span>
              <span className="font-body text-small text-text-light">
                {Math.round((learningResponses.length / questions.length) * 100)}% Complete
              </span>
            </div>
            <div className="h-1.5 bg-border-light rounded-full overflow-hidden">
              <motion.div 
                initial={{ width: 0 }}
                animate={{ width: `${(learningResponses.length / questions.length) * 100}%` }}
                className="h-full bg-accent-primary rounded-full"
              />
            </div>
          </div>

          {/* Main Content Area */}
          <div className="flex-1 overflow-y-auto p-6 space-y-6">
            {!isComplete ? (
              <div className="bg-background-primary p-6 rounded-lg border border-border-light">
                <div className="flex items-start gap-3 mb-4">
                  <MessageCircle className="w-5 h-5 text-accent-primary mt-1" />
                  <div>
                    <p className="font-body text-small text-text-light mb-2">Client Message:</p>
                    <p className="font-headers text-h3 text-text-primary">
                      {questions[currentQuestionIndex]}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-center py-8">
                <CheckCircle className="w-16 h-16 text-accent-primary mx-auto mb-4" />
                <h3 className="font-headers text-h2 text-text-primary mb-2">Training Complete!</h3>
                <p className="text-text-secondary">Your AI is now ready to chat with clients</p>
              </div>
            )}

            {/* Previous Responses */}
            {learningResponses.map((qa, index) => (
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                key={index} 
                className="bg-background-tertiary rounded-lg p-6 space-y-4"
              >
                <div className="flex items-start gap-3">
                  <MessageCircle className="w-5 h-5 text-text-light mt-1" />
                  <div>
                    <p className="font-body text-small text-text-light mb-1">Client:</p>
                    <p className="text-text-primary">{qa.question}</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <CheckCircle className="w-5 h-5 text-accent-primary mt-1" />
                  <div>
                    <p className="font-body text-small text-text-light mb-1">Your Response:</p>
                    <p className="text-text-secondary">{qa.answer}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>

          {/* Input Area */}
          {!isComplete && (
            <form onSubmit={handleSubmit} className="p-6 border-t border-border-light bg-background-primary">
              <div className="space-y-4">
                <div>
                  <label className="block text-text-secondary font-body text-small mb-2">
                    How would you respond to this message?
                  </label>
                  <textarea
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                    placeholder="Type your coaching response..."
                    className="w-full bg-background-secondary p-4 rounded-lg text-text-primary font-body
                             border border-border-light focus:border-accent-primary focus:ring-1 focus:ring-accent-primary
                             placeholder:text-text-light min-h-[120px] transition-colors"
                  />
                </div>

                <div className="flex gap-3">
                  <button
                    type="button"
                    onClick={onClose}
                    className="px-6 py-3 rounded-lg font-body text-text-primary border border-border-medium
                             hover:bg-background-tertiary transition-colors"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={isLoading || !answer.trim()}
                    className={`flex-1 ${buttonStyles.primary} ${
                      (isLoading || !answer.trim()) && 'opacity-50 cursor-not-allowed'
                    }`}
                  >
                    {isLoading ? (
                      <div className="flex items-center justify-center gap-2">
                        <span className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent" />
                        Saving...
                      </div>
                    ) : currentQuestionIndex < questions.length - 1 ? (
                      "Next Question"
                    ) : (
                      "Complete Training"
                    )}
                  </button>
                </div>
              </div>
            </form>
          )}

          {/* Complete State Actions */}
          {isComplete && (
            <div className="p-6 border-t border-border-light">
              <button
                onClick={() => {
                  onClose();
                  setShowAiDemo(true);
                }}
                className={buttonStyles.primary + " w-full"}
              >
                Continue to AI Demo
              </button>
            </div>
          )}
        </motion.div>
      </div>
    );
  };

  return (
    <div className="min-h-screen w-full bg-background-primary relative">
      <button 
        className="fixed top-6 right-6 z-50 p-3 rounded-lg hover:bg-background-tertiary transition-colors text-text-primary"
        onClick={() => navigate('/account-settings')}
      >
        <Settings className="w-6 h-6" />
      </button>

      {showWelcomeModal && (
        <div className="fixed inset-0 flex items-center justify-center z-[99999]">
          <div className="fixed inset-0 bg-text-primary bg-opacity-5 backdrop-blur-sm z-[99999]" />
          <motion.div 
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            className="relative bg-background-secondary p-8 rounded-lg max-w-md mx-4 text-center shadow-lg border border-border-light z-[100000]"
          >
            <h2 className="font-headers text-h2 font-medium text-text-primary mb-3">
              Let's Create Your AI Twin
            </h2>
            <p className="text-text-secondary font-body mb-6">
              In just a few minutes, you'll have an AI version of yourself ready to coach your clients 24/7
            </p>
            <Button
              variant="primary"
              className="w-full"
              onClick={handleStartSetup}
            >
              Start Setup (5 min)
            </Button>
          </motion.div>
        </div>
      )}

      <TourModal />
      {showAiDemo && <AiDemoModal />}
      {showChatOverlay && (
        <AIChatOverlay 
          initialMessages={demoMessages}
          onClose={() => {
            setShowChatOverlay(false);
            setShowAiDemo(false);
          }}
        />
      )}

      <div className="container mx-auto px-4 pt-20 pb-0 max-w-[1400px]">
        <Tabs value={activeTab} onValueChange={setActiveTab}>
          <TabsList className="grid w-full grid-cols-4 bg-background-secondary rounded-lg p-1 mb-6">
            <TabsTrigger 
              value="nutrition"
              className="font-body text-text-primary data-[state=active]:bg-accent-primary data-[state=active]:text-white"
            >
              Nutrition
            </TabsTrigger>
            <TabsTrigger 
              value="routines"
              className="font-body text-text-primary data-[state=active]:bg-accent-primary data-[state=active]:text-white"
            >
              Routines
            </TabsTrigger>
            <TabsTrigger 
              value="products"
              className="font-body text-text-primary data-[state=active]:bg-accent-primary data-[state=active]:text-white"
            >
              Products
            </TabsTrigger>
            <TabsTrigger 
              value="messaging"
              className="font-body text-text-primary data-[state=active]:bg-accent-primary data-[state=active]:text-white"
            >
              Messaging
            </TabsTrigger>
          </TabsList>

          <TabsContent value="nutrition" className="mb-0">
            <NutritionPhilosophyEditor userId={user?.uid} />
          </TabsContent>
          <TabsContent value="routines" className="mb-0">
            <RoutineBuilder userId={user?.uid} />
          </TabsContent>
          <TabsContent value="products" className="mb-0">
            <ProductsTab userId={user?.uid} isFirstSignup={isFirstSignup} />
          </TabsContent>
          <TabsContent value="messaging" className="mb-0">
            <MessagingTab userId={user?.uid} />
          </TabsContent>
        </Tabs>
      </div>

      <Button
        onClick={() => {
          setShowAiDemo(true);
          setShowChatOverlay(true);
        }}
        className={`${buttonStyles.primary} fixed bottom-20 md:bottom-8 right-8 shadow-lg flex items-center gap-2 z-50 rounded-full`}
      >
        <MessageCircle className="w-5 h-5" />
        <span>Test your AI</span>
      </Button>

      {showLearningModal && (
        <LearningOverlay 
          onClose={() => {
            setShowLearningModal(false);
            setShowAiDemo(true);
          }}
        />
      )}
    </div>
  );
};

export default AccountPage;