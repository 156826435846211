// src/components/TemplateManagement.jsx

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs,deleteDoc ,doc } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { Button } from './ui/Button';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { Plus, Trash2, Dumbbell } from 'lucide-react';
import { cardStyles } from './ui/Styles';

const TemplateManagement = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const templatesRef = collection(db, `coaches/${user.uid}/workoutTemplates`);
        const snapshot = await getDocs(templatesRef);
        const templateList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        // Sort by date created
        templateList.sort((a, b) => b.createdAt?.toMillis() - a.createdAt?.toMillis());
        setTemplates(templateList);
      } catch (error) {
        console.error('Error fetching templates:', error);
      } finally {
        setLoading(false);
      }
    };

    if (user?.uid) {
      fetchTemplates();
    }
  }, [user]);
  const handleDeleteTemplate = async (e, templateId) => {
    e.stopPropagation(); 
    
      try {
        await deleteDoc(doc(db, `coaches/${user.uid}/workoutTemplates/${templateId}`));
        setTemplates(templates.filter(template => template.id !== templateId));
      } catch (error) {
        console.error('Error deleting template:', error);
      }
    
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-background-primary">
        <div className="flex flex-col items-center gap-4">
          <div className="animate-spin rounded-full h-12 w-12 border-4 border-accent-primary border-t-transparent" />
          <p className="text-text-light font-body animate-pulse">Loading templates...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background-primary text-text-primary">
      <div className="max-w-7xl mx-auto py-xxl px-md md:px-xl">
        <Card className="overflow-visible">
          <CardHeader className="pb-lg space-y-4">
            <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
              <div>
                <h1 className="font-headers text-h1 font-bold text-text-primary">
                  Workout Templates
                </h1>
                <p className="text-text-light font-body mt-2">
                  Create and manage your workout templates to streamline your coaching process
                </p>
              </div>
              <Button
                variant="primary"
                onClick={() => navigate('/create-template')}
                className="flex items-center gap-2 whitespace-nowrap"
              >
                <Plus className="h-5 w-5" />
                Create Template
              </Button>
            </div>
          </CardHeader>

          <CardContent>
            {templates.length === 0 ? (
              <div className="text-center py-xl bg-background-secondary rounded-lg border-2 border-dashed border-border-light">
                <Dumbbell className="h-16 w-16 text-accent-primary mx-auto mb-md opacity-50" />
                <h3 className="font-headers text-h2 mb-2">Start Creating Templates</h3>
                <p className="text-text-light font-body max-w-md mx-auto mb-6">
                  Design workout templates to efficiently manage and assign workouts to your clients
                </p>
                <Button
                  variant="primary"
                  onClick={() => navigate('/create-template')}
                  className="flex items-center gap-2 mx-auto"
                >
                  <Plus className="h-5 w-5" />
                  Create Your First Template
                </Button>
              </div>
            ) : (
              <div className="grid gap-4 sm:grid-cols-1 lg:grid-cols-2">
                {templates.map(template => (
                  <Card 
                    key={template.id} 
                    className="group hover:shadow-lg transition-all duration-300 cursor-pointer"
                    onClick={() => navigate(`/templates/edit/${template.id}`)}
                  >
                    <CardContent className="p-lg">
                      <div className="flex gap-4">
                        <div className="flex-shrink-0">
                          {template.headerImageUrl ? (
                            <img
                              src={template.headerImageUrl}
                              alt={template.name}
                              className="h-24 w-24 object-cover rounded-lg shadow-md"
                            />
                          ) : (
                            <div className="h-24 w-24 bg-accent-tertiary bg-opacity-10 rounded-lg flex items-center justify-center">
                              <span className="text-accent-tertiary text-h1 font-bold">
                                {template.name.charAt(0)}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="flex-grow">
                          <h3 className="font-headers text-h3 font-medium mb-2 group-hover:text-accent-primary transition-colors">
                            {template.name}
                          </h3>
                          <p className="text-text-light font-body text-small line-clamp-2 mb-3">
                            {template.description}
                          </p>
                          <div className="flex items-center gap-2">
                            <span className="inline-flex items-center px-3 py-1 rounded-full bg-accent-tertiary bg-opacity-10 text-accent-tertiary text-small font-medium">
                              {template.exercises?.length || 0} exercises
                            </span>
                          </div>
                        </div>
                        <Button
                          variant="destructive"
                          size="icon"
                          onClick={(e) => handleDeleteTemplate(e, template.id)}
                          className="opacity-0 group-hover:opacity-100 transition-opacity hover:bg-accent-primary hover:text-white"
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                ))}
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default TemplateManagement;
