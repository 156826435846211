// src/components/AppRoutes.jsx (updated with signup route)
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import NavigationBar from './NavigationBar';
import { FullPageLoader, PageTransitionLoader, usePageLoading } from './LoadingComponents';

import Login from './Login';
import Signup from './Signup';
import CoachDashboard from './CoachDashboard';
import ClientPage from './ClientPage';
import AccountPage from './AccountPage';
import MessagesPage from './Message/MessagesPage';
import CoachProfile from './CoachProfile';
import CoachesManagement from './CoachesManagement';
import AccountSettings from './AccountSettings';
import TemplateManagement from './TemplateManagement';
import TemplateCreation from './TemplateCreation';
import CalendarPage from './CalendarPage';
import NameUsernamePage from './Onboarding/NameUsernamePage';
import MessageManagementDashboard from './Message/MessageManagementDashboard';
import ClientWorkoutEdit from './ClientWorkoutEdit';
//import ReferralManagementPage from './ReferralManagementPage'; 

// Import client onboarding components
import CoachLandingPage from './clientOnboarding/CoachLandingPage';
import OnboardingFlow from './clientOnboarding/OnboardingFlow';
import BookingPage from './clientOnboarding/BookingPage';

// Import coach onboarding customization components
import { OnboardingManager, FormResponses, LandingPageEditor } from './coachOnboardingCustomization';

// Import analytics components
import SimpleAnalyticsDashboard from './analytics/SimpleAnalyticsDashboard';

// Import new onboarding components
import OnboardingContainer from './Onboarding/OnboardingContainer';
// Removing the welcome screen import since we're not using it for now
// import OnboardingWelcome from './Onboarding/OnboardingWelcome';
import ValueDemoScreen from './Onboarding/ValueDemoScreen';
import SetupContainer from './Onboarding/SetupContainer';
import NutritionSetup from './Onboarding/NutritionSetup';
import RoutinesSetup from './Onboarding/RoutinesSetup';
import ProductsSetup from './Onboarding/ProductsSetup';
import MessagingSetup from './Onboarding/MessagingSetup';
import ActivationScreen from './Onboarding/ActivationScreen';
import SuccessScreen from './Onboarding/SuccessScreen';

const AppRoutes = () => {
  const { user, loading, isAdmin, onboardingComplete } = useAuth();
  const isChangingPage = usePageLoading();

  // Show full-page loader only during initial auth check
  if (loading) {
    return <FullPageLoader />;
  }

  return (
    <>
      {isChangingPage && <PageTransitionLoader />}
      <Routes>
        {/* Public routes */}
        <Route 
          path="/" 
          element={user ? <Navigate to="/dashboard" replace /> : <Login />} 
        />
        <Route 
          path="/signup"
          element={<Signup />} 
        />

        {/* New Onboarding Routes */}
        <Route
          path="/onboarding"
          element={user ? <OnboardingContainer /> : <Navigate to="/" replace />}
        >
          {/* Changed the index route to redirect to name-username first */}
          <Route index element={<Navigate to="name-username" replace />} />
          <Route path="setup" element={<SetupContainer />}>
            <Route index element={<Navigate to="nutrition" replace />} />
            <Route path="nutrition" element={<NutritionSetup />} />
            <Route path="routines" element={<RoutinesSetup />} />
            <Route path="products" element={<ProductsSetup />} />
            <Route path="messaging" element={<MessagingSetup />} />
          </Route>
          <Route path="activation" element={<ActivationScreen />} />
          <Route path="name-username" element={<NameUsernamePage />} />
          <Route path="success" element={<SuccessScreen />} />
        </Route>

        {/* Legacy Onboarding Route (kept for backward compatibility) */}
        <Route
          path="/onboarding/setup"
          element={user ? <Navigate to="/onboarding" replace /> : <Navigate to="/" replace />}
        />

        {/* Protected routes with onboarding check */}
        <Route
          path="/dashboard"
          element={
            user ? (
              onboardingComplete ? (
                <>
                  <NavigationBar isAdmin={isAdmin} />
                  <CoachDashboard />
                </>
              ) : (
                <Navigate to="/onboarding" replace />
              )
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        
        {/* Form customization route */}
        <Route
          path="/onboarding-forms"
          element={user ? (
            <>
              <NavigationBar isAdmin={isAdmin} />
              <OnboardingManager />
            </>
          ) : (
            <Navigate to="/" replace />
          )}
        />
        
        {/* Alias for form customization route */}
        <Route
          path="/onboarding-manager"
          element={user ? <Navigate to="/onboarding-forms" replace /> : <Navigate to="/" replace />}
        />
        
        {/* Form responses route */}
        <Route
          path="/form-responses"
          element={user ? (
            <>
              <NavigationBar isAdmin={isAdmin} />
              <FormResponses />
            </>
          ) : (
            <Navigate to="/" replace />
          )}
        />
        
        {/* Landing page editor route */}
        <Route
          path="/landing-page-editor"
          element={user ? (
            <>
              <NavigationBar isAdmin={isAdmin} />
              <LandingPageEditor />
            </>
          ) : (
            <Navigate to="/" replace />
          )}
        />

        {/* Analytics dashboard route */}
        <Route
          path="/analytics/onboarding"
          element={user ? (
            <>
              <NavigationBar isAdmin={isAdmin} />
              <SimpleAnalyticsDashboard />
            </>
          ) : (
            <Navigate to="/" replace />
          )}
        />

        {/* Other protected routes with onboarding check */}
        <Route
          path="/client/:clientId"
          element={
            user ? (
              onboardingComplete ? (
                <>
                  <NavigationBar isAdmin={isAdmin} />
                  <ClientPage />
                </>
              ) : (
                <Navigate to="/onboarding" replace />
              )
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/account"
          element={
            user ? (
              onboardingComplete ? (
                <>
                  <NavigationBar isAdmin={isAdmin} />
                  <AccountPage />
                </>
              ) : (
                <Navigate to="/onboarding" replace />
              )
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/messages"
          element={
            user ? (
              onboardingComplete ? (
                <>
                  <NavigationBar isAdmin={isAdmin} />
                  <MessagesPage />
                </>
              ) : (
                <Navigate to="/onboarding" replace />
              )
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/messageManagement"
          element={
            user ? (
              onboardingComplete ? (
                <>
                  <NavigationBar isAdmin={isAdmin} />
                  <MessageManagementDashboard />
                </>
              ) : (
                <Navigate to="/onboarding" replace />
              )
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/coach-profile/:coachId"
          element={
            user ? (
              onboardingComplete ? (
                <>
                  <NavigationBar isAdmin={isAdmin} />
                  <CoachProfile />
                </>
              ) : (
                <Navigate to="/onboarding" replace />
              )
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/account-settings"
          element={
            user ? (
              onboardingComplete ? (
                <>
                  <NavigationBar isAdmin={isAdmin} />
                  <AccountSettings />
                </>
              ) : (
                <Navigate to="/onboarding" replace />
              )
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/workout/:workoutId"
          element={
            user ? (
              onboardingComplete ? (
                <>
                  <NavigationBar isAdmin={isAdmin} />
                  <WorkoutTracking />
                </>
              ) : (
                <Navigate to="/onboarding" replace />
              )
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/templates"
          element={
            user ? (
              onboardingComplete ? (
                <>
                  <NavigationBar isAdmin={isAdmin} />
                  <TemplateManagement />
                </>
              ) : (
                <Navigate to="/onboarding" replace />
              )
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/templates/edit/:templateId"
          element={
            user ? (
              onboardingComplete ? (
                <>
                  <NavigationBar isAdmin={isAdmin} />
                  <TemplateCreation isEdit={true} />
                </>
              ) : (
                <Navigate to="/onboarding" replace />
              )
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/create-template"
          element={
            user ? (
              onboardingComplete ? (
                <>
                  <NavigationBar isAdmin={isAdmin} />
                  <TemplateCreation />
                </>
              ) : (
                <Navigate to="/onboarding" replace />
              )
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/schedule"
          element={
            user ? (
              onboardingComplete ? (
                <>
                  <NavigationBar isAdmin={isAdmin} />
                  <CalendarPage />
                </>
              ) : (
                <Navigate to="/onboarding" replace />
              )
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/workouts/edit/:workoutId"
          element={
            user ? (
              onboardingComplete ? (
                <>
                  <NavigationBar isAdmin={isAdmin} />
                  <ClientWorkoutEdit />
                </>
              ) : (
                <Navigate to="/onboarding" replace />
              )
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        {/* Admin-only route */}
        <Route
          path="/coaches"
          element={
            user && isAdmin ? (
              <>
                <NavigationBar isAdmin={isAdmin} />
                <CoachesManagement />
              </>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        {/* Client Onboarding Public Routes - IMPORTANT: These must come after all specific routes to avoid conflicts */}
        <Route path="/:username">
          <Route index element={<CoachLandingPage />} />
          <Route path="onboarding" element={<OnboardingFlow />} />
          <Route path="booking" element={<BookingPage />} />
          <Route path="success" element={<Navigate to="../booking" replace />} />
        </Route>

        {/* Catch all route */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
};

const WorkoutTracking = () => (
  <div className="min-h-screen bg-background-primary pt-20">
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-semibold mb-4">Workout Tracking</h1>
      <p>This feature is coming soon!</p>
    </div>
  </div>
);

export default AppRoutes;
