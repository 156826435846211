// src/services/workoutService.js

import { db } from '../firebase';
import { doc, collection, getDocs, addDoc, Timestamp, writeBatch, getDoc, deleteDoc } from 'firebase/firestore';
import { getExerciseData } from './workoutTemplateService';
import { sendWorkoutMessage } from './workoutMessageService';

export const getAssignedWorkoutsForClient = async (coachId, clientId) => {
  const assignedRef = collection(db, `coaches/${coachId}/users/${clientId}/assignedWorkouts`);
  const snap = await getDocs(assignedRef);
  return snap.docs.map(d => ({id: d.id, ...d.data()}));
};

// Helper to calculate default message send time
const calculateMessageSendTime = (scheduledDate) => {
  const now = new Date();
  const twoDaysFromNow = new Date();
  twoDaysFromNow.setDate(now.getDate() + 2);
  
  if (scheduledDate < twoDaysFromNow) {
    // If workout is scheduled within 2 days, send immediately
    return now;
  } else {
    // Otherwise, send one day before
    const sendDate = new Date(scheduledDate);
    sendDate.setDate(sendDate.getDate() - 1);
    return sendDate;
  }
};

export const assignCustomWorkout = async (workoutData, clientId, date, customSendTime = null) => {
  const assignedWorkoutId = doc(collection(db, 'temp')).id;
  const coachId = workoutData.coachId || 'unknown';
  
  // Calculate when the message should be sent
  const messageSendTime = customSendTime || calculateMessageSendTime(date);
  
  const assignedWorkout = {
    ...workoutData,
    userId: clientId,
    dateAssigned: Timestamp.now(),
    scheduledTime: Timestamp.fromDate(date),
    messageSendTime: Timestamp.fromDate(messageSendTime),
    messageStatus: 'pending', // Track if workout message has been sent
    status: 'assigned',
    originalTemplateId: null,
    isTemplate: false,
    dateStarted: null,
    dateCompleted: null,
    coachId
  };

  const batch = writeBatch(db);
  const coachWorkoutRef = doc(db, `coaches/${coachId}/users/${clientId}/assignedWorkouts`, assignedWorkoutId);
  const userWorkoutRef = doc(db, `users/${clientId}/workouts`, assignedWorkoutId);
  
  batch.set(coachWorkoutRef, assignedWorkout);
  batch.set(userWorkoutRef, assignedWorkout);
  await batch.commit();

  // If message should be sent immediately, send it now
  if (messageSendTime <= new Date()) {
    await sendWorkoutMessage(clientId, assignedWorkout);
    
    // Update message status
    const updateBatch = writeBatch(db);
    updateBatch.update(coachWorkoutRef, { messageStatus: 'sent' });
    updateBatch.update(userWorkoutRef, { messageStatus: 'sent' });
    await updateBatch.commit();
  }

  return assignedWorkoutId;
};

export const updateAssignedWorkout = async (coachId, clientId, workoutId, workoutData, date, customSendTime = null) => {
  // Calculate new message send time if date changed
  const messageSendTime = customSendTime || calculateMessageSendTime(date);
  
  const assignedWorkout = {
    ...workoutData,
    userId: clientId,
    updatedAt: Timestamp.now(),
    scheduledTime: Timestamp.fromDate(date),
    messageSendTime: Timestamp.fromDate(messageSendTime),
    isTemplate: false
  };

  const coachWorkoutRef = doc(db, `coaches/${coachId}/users/${clientId}/assignedWorkouts`, workoutId);
  const userWorkoutRef = doc(db, `users/${clientId}/workouts`, workoutId);

  const batch = writeBatch(db);
  batch.update(coachWorkoutRef, assignedWorkout);
  batch.update(userWorkoutRef, assignedWorkout);
  await batch.commit();

  // If message should be sent immediately and hasn't been sent yet
  const workoutDoc = await getDoc(coachWorkoutRef);
  const currentWorkout = workoutDoc.data();
  
  if (messageSendTime <= new Date() && currentWorkout?.messageStatus !== 'sent') {
    await sendWorkoutMessage(clientId, assignedWorkout);
    
    // Update message status
    const updateBatch = writeBatch(db);
    updateBatch.update(coachWorkoutRef, { messageStatus: 'sent' });
    updateBatch.update(userWorkoutRef, { messageStatus: 'sent' });
    await updateBatch.commit();
  }

  return workoutId;
};

export const deleteAssignedWorkout = async (coachId, clientId, workoutId) => {
  try {
    const batch = writeBatch(db);
    
    // Delete from coach's assigned workouts
    const coachWorkoutRef = doc(db, `coaches/${coachId}/users/${clientId}/assignedWorkouts`, workoutId);
    batch.delete(coachWorkoutRef);
    
    // Delete from user's workouts
    const userWorkoutRef = doc(db, `users/${clientId}/workouts`, workoutId);
    batch.delete(userWorkoutRef);
    
    await batch.commit();
    return true;
  } catch (error) {
    console.error('Error deleting workout:', error);
    throw error;
  }
};
