// src/components/ui/Styles.js

import theme from '../../styles/theme';

export const buttonStyles = {
  primary: 
    'bg-accent-primary text-white ' +
    'font-body font-medium ' +
    'px-6 py-3 rounded-lg ' +
    'hover:bg-hover-primary ' +
    'transition-all duration-300 ' +
    'shadow-md hover:shadow-lg',
  secondary: 
    'bg-accent-secondary ' +
    'text-white ' +
    'font-body font-medium ' +
    'px-6 py-3 rounded-lg ' +
    'hover:bg-hover-primary ' +
    'transition-all duration-300 ' +
    'shadow-md hover:shadow-lg'
};

export const cardStyles = 
  'bg-background-primary ' +
  'rounded-xl ' +
  'shadow-lg ' +
  'border ' +
  'border-background-secondary ' +
  'hover:shadow-xl transition-shadow duration-300 ' +
  'overflow-hidden';

// New glass effect card styles for overlays
export const glassCardStyles = {
  dark: 
    'backdrop-blur-md bg-black/60 ' +
    'rounded-3xl ' +
    'overflow-hidden ' +
    'shadow-xl ' +
    'border border-white/10 ' +
    'relative',
    
  light:
    'backdrop-blur-md bg-white/90 ' +
    'rounded-3xl ' +
    'overflow-hidden ' +
    'shadow-xl ' +
    'border border-background-secondary/40 ' +
    'relative'
};

// New accent gradient top border for glass cards
export const accentBorderStyles = {
  top: 'absolute top-0 left-0 right-0 h-1 ' +
       'bg-gradient-to-r from-accent-primary/80 via-accent-secondary/80 to-accent-primary/80',
  
  left: 'absolute top-0 bottom-0 left-0 w-1 ' +
        'bg-gradient-to-b from-accent-primary/80 to-accent-secondary/80'
};

// Glass inner highlight effect
export const glassHighlightStyles =
  'absolute inset-0 bg-gradient-to-b from-white/5 to-transparent pointer-events-none';
