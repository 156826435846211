// src/components/NutritionTab.jsx


import React, { useState, useEffect } from 'react';
import { format, subDays, parseISO } from 'date-fns';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Card, CardContent } from './ui/Card';
import { Button } from './ui/Button';
import { CalendarIcon, ChevronLeft, ChevronRight } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const formatTime = (timeString) => {
    try {
      // Assuming timeString is in "HH:mm:ss" format
      const [hours, minutes] = timeString.split(':');
      const date = new Date();
      date.setHours(parseInt(hours), parseInt(minutes));
      
      return date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      });
    } catch (error) {
      console.error('Error formatting time:', error);
      return timeString;
    }
  };

const NutritionTab = ({ clientId }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [nutritionData, setNutritionData] = useState(null);
  const [weeklyData, setWeeklyData] = useState([]);
  const [showDetails, setShowDetails] = useState(false);

  

  // Fetch nutrition data for selected date
  const fetchNutritionData = async (date) => {
    try {
      const dateStr = format(date, 'yyyy-MM-dd');
      const nutritionRef = doc(db, 'users', clientId, 'nutritionalData', dateStr);
      const docSnap = await getDoc(nutritionRef);
      
      if (docSnap.exists()) {
        const data = docSnap.data();
        // Sort entries by timestamp if they exist
        if (data.entries) {
          data.entries.sort((a, b) => {
            return a.timestamp.localeCompare(b.timestamp);
          });
        }
        setNutritionData(data);
      } else {
        setNutritionData(null);
      }
    } catch (error) {
      console.error('Error fetching nutrition data:', error);
    }
  };

  // Fetch weekly data for trends
  const fetchWeeklyData = async () => {
    try {
      const weekData = [];
      for (let i = 6; i >= 0; i--) {
        const date = subDays(selectedDate, i);
        const dateStr = format(date, 'yyyy-MM-dd');
        const nutritionRef = doc(db, 'users', clientId, 'nutritionalData', dateStr);
        const docSnap = await getDoc(nutritionRef);
        
        weekData.push({
          date: format(date, 'MM/dd'),
          calories: docSnap.exists() ? docSnap.data().dailyTotals?.calories || 0 : 0,
          protein: docSnap.exists() ? docSnap.data().dailyTotals?.protein || 0 : 0,
          fiber: docSnap.exists() ? docSnap.data().dailyTotals?.fiber || 0 : 0,
        });
      }
      setWeeklyData(weekData);
    } catch (error) {
      console.error('Error fetching weekly data:', error);
    }
  };

  useEffect(() => {
    fetchNutritionData(selectedDate);
    fetchWeeklyData();
  }, [selectedDate, clientId]);

  const handleDateChange = (days) => {
    setSelectedDate(prev => {
      const newDate = new Date(prev);
      newDate.setDate(prev.getDate() + days);
      return newDate;
    });
  };

  const renderDailyStats = () => {
    if (!nutritionData?.dailyTotals) {
      return (
        <div className="text-center text-gray-400 py-4">
          No nutrition data available for this date
        </div>
      );
    }

    const { dailyTotals } = nutritionData;

    return (
      <div className="grid grid-cols-3 gap-4">
        <Card className="bg-[#333333] p-4">
          <div className="text-sm text-gray-400">Calories</div>
          <div className="text-xl font-bold">{Math.round(dailyTotals.calories)}</div>
        </Card>
        <Card className="bg-[#333333] p-4">
          <div className="text-sm text-gray-400">Protein</div>
          <div className="text-xl font-bold">{Math.round(dailyTotals.protein)}g</div>
        </Card>
        <Card className="bg-[#333333] p-4">
          <div className="text-sm text-gray-400">Fiber</div>
          <div className="text-xl font-bold">{dailyTotals.fiber.toFixed(1)}g</div>
        </Card>
      </div>
    );
  };

  const renderDetailedStats = () => {
    if (!nutritionData?.dailyTotals) return null;

    const { dailyTotals, entries } = nutritionData;

    return (
      <div className="space-y-6">
        <Card className="bg-[#333333] p-4">
          <h4 className="text-lg font-semibold mb-4">Daily Totals</h4>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            <div>
              <div className="text-sm text-gray-400">Total Carbs</div>
              <div>{dailyTotals.totalCarbs.toFixed(1)}g</div>
            </div>
            <div>
              <div className="text-sm text-gray-400">Total Fat</div>
              <div>{dailyTotals.totalFat.toFixed(1)}g</div>
            </div>
            <div>
              <div className="text-sm text-gray-400">Sugar</div>
              <div>{dailyTotals.sugar.toFixed(1)}g</div>
            </div>
            <div>
              <div className="text-sm text-gray-400">Sodium</div>
              <div>{dailyTotals.sodium}mg</div>
            </div>
            <div>
              <div className="text-sm text-gray-400">Saturated Fat</div>
              <div>{dailyTotals.saturatedFat.toFixed(1)}g</div>
            </div>
            <div>
              <div className="text-sm text-gray-400">Added Sugar</div>
              <div>{dailyTotals.addedSugar.toFixed(1)}g</div>
            </div>
          </div>
        </Card>

        <div className="space-y-4">
          <h4 className="text-lg font-semibold">Food Entries</h4>
          {entries?.map((entry, entryIndex) => (
            <Card key={entryIndex} className="bg-[#333333] p-4">
              <div className="text-sm text-gray-400 mb-2">
                {formatTime(entry.timestamp)}
              </div>
              <div className="space-y-4">
                {entry.foodItems.map((foodItem, itemIndex) => (
                  <div key={itemIndex} className="border-t border-gray-600 pt-4 first:border-0 first:pt-0">
                    <div className="font-medium mb-2">{foodItem.item}</div>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-2 text-sm">
                      <div>Calories: {foodItem.nutrition.calories}</div>
                      <div>Protein: {foodItem.nutrition.protein}g</div>
                      <div>Carbs: {foodItem.nutrition.totalCarbs}g</div>
                      <div>Fat: {foodItem.nutrition.totalFat}g</div>
                      <div>Fiber: {foodItem.nutrition.fiber}g</div>
                      <div>Sugar: {foodItem.nutrition.sugar}g</div>
                    </div>
                  </div>
                ))}
              </div>
            </Card>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="h-full flex flex-col">
      {/* Fixed header */}
      <div className="flex-none mb-4">
        <div className="flex items-center justify-between bg-[#333333] p-2 rounded-lg">
          <Button 
            variant="ghost" 
            onClick={() => handleDateChange(-1)}
            className="text-gray-400"
          >
            <ChevronLeft />
          </Button>
          <div className="flex items-center space-x-2">
            <CalendarIcon className="text-gray-400" />
            <span>{format(selectedDate, 'MMM dd, yyyy')}</span>
          </div>
          <Button 
            variant="ghost" 
            onClick={() => handleDateChange(1)}
            className="text-gray-400"
          >
            <ChevronRight />
          </Button>
        </div>
      </div>
  
      {/* Scrollable content */}
      <div className="flex-1 overflow-y-auto min-h-0">
        <div className="space-y-4">
          {/* Daily Stats */}
          <div>
            <h3 className="text-lg font-semibold mb-2">Daily Nutrition</h3>
            {renderDailyStats()}
          </div>
  
          {/* Weekly Trends */}
          <div>
            <h3 className="text-lg font-semibold mb-2">Weekly Trends</h3>
            <div className="h-48">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={weeklyData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" dataKey="calories" stroke="#8884d8" name="Calories" />
                  <Line type="monotone" dataKey="protein" stroke="#82ca9d" name="Protein (g)" />
                  <Line type="monotone" dataKey="fiber" stroke="#ffc658" name="Fiber (g)" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
  
          {/* Show More Button */}
          <div className="text-center py-2">
            <Button
              variant="ghost"
              onClick={() => setShowDetails(!showDetails)}
              className="text-sm"
            >
              {showDetails ? 'Show Less' : 'Show More Details'}
            </Button>
          </div>
  
          {/* Detailed Stats */}
          {showDetails && renderDetailedStats()}
        </div>
      </div>
    </div>
  );
};


export default NutritionTab;
