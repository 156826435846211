// src/components/NutritionTab.jsx


import React, { useState, useEffect } from 'react';
import { format, subDays, parseISO } from 'date-fns';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Card, CardContent, CardHeader, CardTitle } from './ui/Card';
import { Button } from './ui/Button';
import { CalendarIcon, ChevronLeft, ChevronRight, TrendingUp, Apple, Clock } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import theme from '../styles/theme';

const formatTime = (timeString) => {
    try {
      // Assuming timeString is in "HH:mm:ss" format
      const [hours, minutes] = timeString.split(':');
      const date = new Date();
      date.setHours(parseInt(hours), parseInt(minutes));
      
      return date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      });
    } catch (error) {
      console.error('Error formatting time:', error);
      return timeString;
    }
  };

const NutritionTab = ({ clientId }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [nutritionData, setNutritionData] = useState(null);
  const [weeklyData, setWeeklyData] = useState([]);
  const [showDetails, setShowDetails] = useState(false);

  

  // Fetch nutrition data for selected date
  const fetchNutritionData = async (date) => {
    try {
      const dateStr = format(date, 'yyyy-MM-dd');
      const nutritionRef = doc(db, 'users', clientId, 'nutritionalData', dateStr);
      const docSnap = await getDoc(nutritionRef);
      
      if (docSnap.exists()) {
        const data = docSnap.data();
        // Sort entries by timestamp if they exist
        if (data.entries) {
          data.entries.sort((a, b) => {
            return a.timestamp.localeCompare(b.timestamp);
          });
        }
        setNutritionData(data);
      } else {
        setNutritionData(null);
      }
    } catch (error) {
      console.error('Error fetching nutrition data:', error);
    }
  };

  // Fetch weekly data for trends
  const fetchWeeklyData = async () => {
    try {
      const weekData = [];
      for (let i = 6; i >= 0; i--) {
        const date = subDays(selectedDate, i);
        const dateStr = format(date, 'yyyy-MM-dd');
        const nutritionRef = doc(db, 'users', clientId, 'nutritionalData', dateStr);
        const docSnap = await getDoc(nutritionRef);
        
        weekData.push({
          date: format(date, 'MM/dd'),
          calories: docSnap.exists() ? docSnap.data().dailyTotals?.calories || 0 : 0,
          protein: docSnap.exists() ? docSnap.data().dailyTotals?.protein || 0 : 0,
          fiber: docSnap.exists() ? docSnap.data().dailyTotals?.fiber || 0 : 0,
        });
      }
      setWeeklyData(weekData);
    } catch (error) {
      console.error('Error fetching weekly data:', error);
    }
  };

  useEffect(() => {
    fetchNutritionData(selectedDate);
    fetchWeeklyData();
  }, [selectedDate, clientId]);

  const handleDateChange = (days) => {
    setSelectedDate(prev => {
      const newDate = new Date(prev);
      newDate.setDate(prev.getDate() + days);
      return newDate;
    });
  };

  const renderDailyStats = () => {
    if (!nutritionData?.dailyTotals) {
      return (
        <div className="text-center text-text-light py-8 font-body">
          No nutrition data available for this date
        </div>
      );
    }

    const { dailyTotals } = nutritionData;

    return (
      <div className="grid grid-cols-3 gap-4">
        <StatCard
          title="Calories"
          value={Math.round(dailyTotals.calories)}
          icon={<TrendingUp className="h-5 w-5" />}
          color="text-accent-primary"
        />
        <StatCard
          title="Protein"
          value={Math.round(dailyTotals.protein)}
          unit="g"
          icon={<Apple className="h-5 w-5" />}
          color="text-accent-secondary"
        />
        <StatCard
          title="Fiber"
          value={dailyTotals.fiber.toFixed(1)}
          unit="g"
          icon={<Clock className="h-5 w-5" />}
          color="text-accent-tertiary"
        />
      </div>
    );
  };

  const renderDetailedStats = () => {
    if (!nutritionData?.dailyTotals) return null;

    const { dailyTotals, entries } = nutritionData;

    return (
      <div className="space-y-6">
        <Card className="bg-background-secondary border-border-light">
          <CardHeader>
            <CardTitle className="font-headers text-lg">Detailed Nutrition</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
              <NutrientItem label="Total Carbs" value={dailyTotals.totalCarbs.toFixed(1)} unit="g" />
              <NutrientItem label="Total Fat" value={dailyTotals.totalFat.toFixed(1)} unit="g" />
              <NutrientItem label="Sugar" value={dailyTotals.sugar.toFixed(1)} unit="g" />
              <NutrientItem label="Sodium" value={dailyTotals.sodium} unit="mg" />
              <NutrientItem label="Saturated Fat" value={dailyTotals.saturatedFat.toFixed(1)} unit="g" />
              <NutrientItem label="Added Sugar" value={dailyTotals.addedSugar.toFixed(1)} unit="g" />
            </div>
          </CardContent>
        </Card>

        <div className="space-y-4">
          <h4 className="font-headers text-lg font-medium text-text-primary">Food Entries</h4>
          {entries?.map((entry, entryIndex) => (
            <Card key={entryIndex} className="bg-background-secondary border-border-light hover:shadow-md transition-shadow">
              <CardContent className="p-4">
                <div className="text-sm text-text-light mb-3 font-body">
                  {formatTime(entry.timestamp)}
                </div>
                <div className="space-y-4">
                  {entry.foodItems.map((foodItem, itemIndex) => (
                    <div key={itemIndex} className="border-t border-border-light pt-4 first:border-0 first:pt-0">
                      <div className="font-medium mb-2 text-text-primary">{foodItem.item}</div>
                      <div className="grid grid-cols-2 md:grid-cols-3 gap-2 text-sm text-text-secondary">
                        <NutrientItem label="Calories" value={foodItem.nutrition.calories} />
                        <NutrientItem label="Protein" value={foodItem.nutrition.protein} unit="g" />
                        <NutrientItem label="Carbs" value={foodItem.nutrition.totalCarbs} unit="g" />
                        <NutrientItem label="Fat" value={foodItem.nutrition.totalFat} unit="g" />
                        <NutrientItem label="Fiber" value={foodItem.nutrition.fiber} unit="g" />
                        <NutrientItem label="Sugar" value={foodItem.nutrition.sugar} unit="g" />
                      </div>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="h-full flex flex-col space-y-6">
      {/* Date Navigation */}
      <Card className="bg-background-secondary border-border-light">
        <CardContent className="p-4">
          <div className="flex items-center justify-between">
            <Button 
              variant="ghost" 
              onClick={() => handleDateChange(-1)}
              className="hover:text-accent-primary"
            >
              <ChevronLeft className="h-5 w-5" />
            </Button>
            <div className="flex items-center space-x-3">
              <CalendarIcon className="h-5 w-5 text-accent-secondary" />
              <span className="text-lg font-headers font-medium">
                {format(selectedDate, 'MMMM d, yyyy')}
              </span>
            </div>
            <Button 
              variant="ghost" 
              onClick={() => handleDateChange(1)}
              className="hover:text-accent-primary"
            >
              <ChevronRight className="h-5 w-5" />
            </Button>
          </div>
        </CardContent>
      </Card>

      {/* Main Content */}
      <div className="flex-1 overflow-y-auto space-y-6">
        {renderDailyStats()}
        
        {/* Weekly Trends */}
        <Card className="bg-background-secondary border-border-light">
          <CardHeader>
            <CardTitle className="font-headers text-lg">Weekly Trends</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={weeklyData}>
                  <CartesianGrid strokeDasharray="3 3" stroke={theme.colors.border.light} />
                  <XAxis 
                    dataKey="date" 
                    stroke={theme.colors.text.light}
                    tick={{ fill: theme.colors.text.light }}
                  />
                  <YAxis 
                    stroke={theme.colors.text.light}
                    tick={{ fill: theme.colors.text.light }}
                  />
                  <Tooltip 
                    contentStyle={{ 
                      backgroundColor: theme.colors.background.secondary,
                      border: `1px solid ${theme.colors.border.medium}`,
                      borderRadius: theme.borderRadius.md,
                      fontFamily: theme.typography.fontFamily.body
                    }}
                  />
                  <Line 
                    type="monotone" 
                    dataKey="calories" 
                    stroke={theme.colors.accent.primary} 
                    name="Calories"
                    strokeWidth={2}
                  />
                  <Line 
                    type="monotone" 
                    dataKey="protein" 
                    stroke={theme.colors.accent.secondary} 
                    name="Protein (g)"
                    strokeWidth={2}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>

        <Button
          variant="ghost"
          onClick={() => setShowDetails(!showDetails)}
          className="w-full text-accent-tertiary hover:text-accent-primary"
        >
          {showDetails ? 'Show Less' : 'Show More Details'}
        </Button>

        {showDetails && renderDetailedStats()}
      </div>
    </div>
  );
};

// Helper Components
const StatCard = ({ icon, title, value, unit = '', color }) => (
  <Card className="bg-background-secondary border-border-light hover:shadow-md transition-shadow">
    <CardContent className="p-4">
      <div className="flex items-center justify-between mb-2">
        <span className={`${color}`}>{icon}</span>
        <span className="text-text-light text-sm font-body">{title}</span>
      </div>
      <div className="text-2xl font-headers font-bold text-text-primary">
        {value}{unit}
      </div>
    </CardContent>
  </Card>
);

const NutrientItem = ({ label, value, unit = '' }) => (
  <div className="space-y-1">
    <div className="text-sm text-text-light font-body">{label}</div>
    <div className="font-medium text-text-primary">
      {value}{unit}
    </div>
  </div>
);

export default NutritionTab;
