import React, { useState, useRef } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../../ui/Card';
import { Button } from '../../ui/Button';
import { Alert, AlertDescription } from '../../ui/Alert';
import { Upload, Image, X, Check } from 'lucide-react';

const MediaUploadTab = ({
  backgroundMedia,
  setBackgroundMedia,
  backgroundMediaFile,
  setBackgroundMediaFile,
  backgroundMediaType,
  setBackgroundMediaType,
  backgroundMediaPosition,
  setBackgroundMediaPosition,
}) => {
  const [uploadError, setUploadError] = useState('');
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const imageInputRef = useRef(null);
  
  const handleMediaChange = (e) => {
    setUploadError('');
    setUploadSuccess(false);
    
    if (e.target.files[0]) {
      const file = e.target.files[0];
      
      // Check file size (15MB max)
      if (file.size > 15 * 1024 * 1024) {
        setUploadError('File size exceeds 15MB limit. Please upload a smaller file.');
        return;
      }
      
      const fileType = file.type.split('/')[0];
      
      // Only accept images
      if (fileType !== 'image') {
        setUploadError('Please upload an image file.');
        return;
      }
      
      setBackgroundMediaType('image');
      setBackgroundMediaFile(file);
      setBackgroundMedia(URL.createObjectURL(file));
      setUploadSuccess(true);
      
      // Clear success message after 3 seconds
      setTimeout(() => {
        setUploadSuccess(false);
      }, 3000);
    }
  };
  
  // Add these helper functions to trigger file inputs
  const triggerFileInput = () => {
    if (imageInputRef.current) {
      imageInputRef.current.click();
    }
  };
  
  const clearMedia = () => {
    setBackgroundMedia(null);
    setBackgroundMediaFile(null);
    setBackgroundMediaType(null);
  };
  
  const positionOptions = [
    { value: 'center', label: 'Center' },
    { value: 'center top', label: 'Top' },
    { value: 'center bottom', label: 'Bottom' },
    { value: 'left center', label: 'Left' },
    { value: 'right center', label: 'Right' },
  ];

  return (
    <Card>
      <CardHeader>
        <CardTitle>Background Media</CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        
        {uploadError && (
          <Alert variant="destructive" className="mb-4">
            <AlertDescription>{uploadError}</AlertDescription>
          </Alert>
        )}
        
        {uploadSuccess && (
          <Alert className="mb-4 bg-green-50 border-green-200">
            <Check className="h-4 w-4 text-green-500 mr-2" />
            <AlertDescription className="text-green-700">Image successfully uploaded!</AlertDescription>
          </Alert>
        )}
        
        <div className="bg-background-tertiary rounded-lg p-6 border border-dashed border-border-light">
          {backgroundMedia ? (
            <div className="relative">
              <div className="aspect-video rounded-md overflow-hidden border border-border-light bg-background-secondary">
                <img
                  src={backgroundMedia}
                  alt="Background preview"
                  className="w-full h-full object-cover"
                />
              </div>
              
              <div className="mt-4 flex items-center justify-between">
                <div>
                  <span className="inline-block px-2 py-1 rounded-full text-xs font-medium bg-accent-primary/10 text-accent-primary">
                    <div className="flex items-center">
                      <Image className="w-3 h-3 mr-1" />
                      Image
                    </div>
                  </span>
                </div>
                
                <Button 
                  variant="outline" 
                  size="sm"
                  onClick={clearMedia}
                  className="text-red-500 flex items-center gap-1"
                >
                  <X className="h-3 w-3" />
                  Remove
                </Button>
              </div>
            </div>
          ) : (
            <div className="text-center py-8">
              <div className="flex justify-center mb-4">
                <div className="p-3 rounded-full bg-accent-primary/10 text-accent-primary">
                  <Upload className="h-6 w-6" />
                </div>
              </div>
              
              <h3 className="text-text-primary font-medium mb-2">Upload Background Image</h3>
              
              {/* Hidden file input */}
              <input
                ref={imageInputRef}
                type="file"
                accept="image/*"
                onChange={handleMediaChange}
                className="hidden"
              />
              
              <Button
                variant="outline" 
                className="flex items-center gap-2"
                onClick={triggerFileInput}
              >
                <Image className="h-4 w-4" />
                Upload Image
              </Button>
              
              <p className="text-xs text-text-light mt-4">
                Supported formats: JPG, PNG<br />
                Maximum file size: 15MB
              </p>
            </div>
          )}
        </div>
        
        {backgroundMedia && (
          <div>
            <label className="block text-text-light mb-2">Image Position</label>
            <div className="grid grid-cols-5 gap-2">
              {positionOptions.map((option) => (
                <button
                  key={option.value}
                  type="button"
                  onClick={() => setBackgroundMediaPosition(option.value)}
                  className={`p-2 border rounded-md text-center text-sm ${
                    backgroundMediaPosition === option.value
                      ? 'border-accent-primary bg-accent-primary/10 text-accent-primary'
                      : 'border-border-light text-text-light hover:border-accent-primary/50'
                  }`}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default MediaUploadTab; 