import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { format, subDays } from 'date-fns';

export const useSimplifiedAnalytics = (coachId, selectedFormId = null, dateRange = '7days') => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [analyticsData, setAnalyticsData] = useState(null);
  const [availableForms, setAvailableForms] = useState([]);
  
  useEffect(() => {
    if (!coachId) {
      console.log('No coachId provided to useSimplifiedAnalytics hook');
      setLoading(false);
      return;
    }
    
    console.log(`Fetching analytics for coach: ${coachId}, form: ${selectedFormId || 'all'}, range: ${dateRange}`);
    
    const fetchAnalytics = async () => {
      try {
        setLoading(true);
        setError(null);
        
        // Get coach summary document
        const coachSummaryRef = doc(db, 'analytics', 'summary', 'coaches', coachId);
        const coachSummaryDoc = await getDoc(coachSummaryRef);
        
        console.log(`Coach summary exists: ${coachSummaryDoc.exists()}`);
        
        if (!coachSummaryDoc.exists()) {
          // No analytics data yet
          console.log('No analytics data found for this coach');
          setAnalyticsData(null);
          setLoading(false);
          return;
        }
        
        const coachSummary = coachSummaryDoc.data();
        console.log('Retrieved coach summary data:', coachSummary);
        
        // Get form list for this coach
        const formsQuery = query(
          collection(db, 'onboardingForms'),
          where('coachId', '==', coachId)
        );
        
        const formsSnapshot = await getDocs(formsQuery);
        const forms = formsSnapshot.docs.map(doc => ({
          id: doc.id,
          title: doc.data().title || `Form ${doc.id.substr(0, 6)}`
        }));
        
        setAvailableForms([
          { id: 'all', title: 'All Forms' },
          ...forms
        ]);
        
        // If a specific form is selected, get form-level analytics
        let formData = null;
        if (selectedFormId && selectedFormId !== 'all') {
          const formSummaryRef = doc(
            db, 'analytics', 'summary', 'coaches', coachId, 'forms', selectedFormId
          );
          const formSummaryDoc = await getDoc(formSummaryRef);
          
          if (formSummaryDoc.exists()) {
            formData = formSummaryDoc.data();
            console.log('Retrieved form-specific data:', formData);
          }
        }
        
        // Filter by date range if needed
        const filteredData = filterDataByDateRange(
          formData || coachSummary, 
          dateRange
        );
        
        setAnalyticsData({
          summary: coachSummary,
          formData: formData,
          filteredData
        });
        
      } catch (err) {
        console.error('Error fetching analytics:', err);
        console.error('Error details:', {
          message: err.message,
          code: err.code,
          stack: err.stack
        });
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    
    fetchAnalytics();
  }, [coachId, selectedFormId, dateRange]);
  
  const filterDataByDateRange = (data, range) => {
    const now = new Date();
    let startDate;
    
    // Determine start date based on range
    switch(range) {
      case '7days':
        startDate = subDays(now, 7);
        break;
      case '30days':
        startDate = subDays(now, 30);
        break;
      case '90days':
        startDate = subDays(now, 90);
        break;
      default:
        startDate = subDays(now, 7);
    }
    
    const startDateStr = format(startDate, 'yyyy-MM-dd');
    console.log(`Filtering data from ${startDateStr} to now`);
    
    // Check if data has byDate property
    if (!data.byDate) {
      console.warn('Analytics data missing byDate property:', data);
      return {
        byDate: {},
        totals: { pageViews: 0, formStarts: 0, formCompletions: 0, callBookings: 0, payments: 0 },
        rates: { landingToForm: 0, formToCompletion: 0, completionToCall: 0, callToPayment: 0, overall: 0 }
      };
    }
    
    // Filter byDate object to only include dates within range
    const filteredByDate = Object.entries(data.byDate || {})
      .filter(([date]) => date >= startDateStr)
      .reduce((obj, [date, value]) => {
        obj[date] = value;
        return obj;
      }, {});
    
    // Calculate totals for filtered date range
    const totals = Object.values(filteredByDate).reduce(
      (sum, dayData) => {
        sum.pageViews += dayData.pageViews || 0;
        sum.formStarts += dayData.formStarts || 0;
        sum.formCompletions += dayData.formCompletions || 0;
        sum.callBookings += dayData.callBookings || 0;
        sum.payments += dayData.payments || 0;
        return sum;
      },
      { pageViews: 0, formStarts: 0, formCompletions: 0, callBookings: 0, payments: 0 }
    );
    
    // Calculate conversion rates for this period
    const rates = {
      landingToForm: totals.pageViews > 0 ? (totals.formStarts / totals.pageViews) * 100 : 0,
      formToCompletion: totals.formStarts > 0 ? (totals.formCompletions / totals.formStarts) * 100 : 0,
      completionToCall: totals.formCompletions > 0 ? (totals.callBookings / totals.formCompletions) * 100 : 0,
      callToPayment: totals.callBookings > 0 ? (totals.payments / totals.callBookings) * 100 : 0,
      overall: totals.pageViews > 0 ? (totals.payments / totals.pageViews) * 100 : 0
    };
    
    return {
      byDate: filteredByDate,
      totals,
      rates
    };
  };
  
  return {
    loading,
    error,
    analyticsData,
    availableForms
  };
}; 