// src/components/ClientWorkoutEdit.jsx

import React, { useState, useEffect } from 'react';
import { Button } from './ui/Button';
import { Input } from './ui/Input';
import { Trash2, Plus, ChevronUp, ChevronDown, X, FileText, Calendar, Flame, Dumbbell, Wind, Loader2, AlertCircle } from 'lucide-react';
import ExerciseSearch from './ExerciseSearch';
import { assignCustomWorkout, updateAssignedWorkout, deleteAssignedWorkout, getWorkoutTemplate, getWorkout } from '../services/workoutService';
import { useAuth } from '../contexts/AuthContext';
import MessageSchedule from './MessageSchedule';
import ExpandedSetsEditor from './ExpandedSetsEditor';
import { createCustomExercise, modifyExercise, getAllExercises } from '../services/exerciseService';
import ModifyExerciseOverlay from './overlays/ModifyExerciseOverlay';
import CreateExerciseOverlay from './overlays/CreateExerciseOverlay';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

const ClientWorkoutEdit = () => {
  const navigate = useNavigate();
  const { workoutId } = useParams();
  const [searchParams] = useSearchParams();
  const clientId = searchParams.get('clientId');
  const date = searchParams.get('date') ? new Date(searchParams.get('date')) : null;
  const templateId = searchParams.get('templateId');

  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [messageSendTime, setMessageSendTime] = useState(null);
  const [workoutData, setWorkoutData] = useState({
    name: '',
    description: '',
    headerImageUrl: '',
    sections: [],
    exercises: [],
    coachId: user?.uid || ''
  });
  const [expandedExercise, setExpandedExercise] = useState(null);
  const [showExerciseEdit, setShowExerciseEdit] = useState(false);
  const [exerciseToEdit, setExerciseToEdit] = useState(null);
  const [showModifyOverlay, setShowModifyOverlay] = useState(false);
  const [showCreateOverlay, setShowCreateOverlay] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [allExercises, setAllExercises] = useState([]);
  const [isRecurring, setIsRecurring] = useState(false);
  const [recurringWeeks, setRecurringWeeks] = useState(1);
  const [selectedDays, setSelectedDays] = useState({
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false
  });
  const [template, setTemplate] = useState(null);
  const [existingWorkout, setExistingWorkout] = useState(null);

  useEffect(() => {
    const fetchExercises = async () => {
      try {
        const exercises = await getAllExercises(user.uid);
        setAllExercises(exercises);
      } catch (error) {
        console.error('Error fetching exercises:', error);
      }
    };

    fetchExercises();
  }, [user.uid]);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        
        if (workoutId !== 'new' && workoutId) {
          // Load existing workout
          const workout = await getWorkout(clientId, workoutId);
          setExistingWorkout(workout);
          setWorkoutData({
            name: workout.name,
            description: workout.description,
            headerImageUrl: workout.headerImageUrl,
            sections: workout.sections,
            exercises: workout.exercises.map(ex => ({
              ...ex,
              sets: ex.sets.map(set => ({
                reps: set.reps,
                weight: set.weight,
                actualReps: null,
                actualWeight: null,
                completed: false
              }))
            })),
            coachId: user.uid
          });
        } else if (templateId) {
          // Load template
          const template = await getWorkoutTemplate(user.uid, templateId);
          setTemplate(template);
          setWorkoutData({
            name: template.name,
            description: template.description,
            headerImageUrl: template.headerImageUrl,
            sections: template.sections,
            exercises: template.exercises.map(ex => ({
              ...ex,
              sets: ex.sets.map(set => ({
                reps: set.reps,
                weight: set.weight,
                actualReps: null,
                actualWeight: null,
                completed: false
              }))
            })),
            coachId: user.uid
          });
        }
      } catch (error) {
        console.error('Error loading data:', error);
        // Show error toast or notification here
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [workoutId, templateId, clientId, user.uid]);

  const handleClose = () => {
    navigate(-1); // Go back to previous page
  };

  const handleWorkoutAssigned = () => {
    navigate('/schedule'); // Return to calendar
  };

  const addSection = (type) => {
    const newSection = {
      id: `${type}-${Date.now()}`,
      type,
      name: type === 'warmup' ? 'Warm Up'
           : type === 'cooldown' ? 'Cool Down'
           : `Block ${String.fromCharCode(65 + workoutData.sections.filter(s => s.type === 'block').length)}`
    };
    setWorkoutData(prev => {
      let newSections = [...prev.sections];
      if (type === 'warmup') {
        newSections = [newSection, ...newSections]; 
      } else {
        newSections.push(newSection);
      }
      return { ...prev, sections: newSections };
    });
  };

  const removeSection = (sectionId) => {
    setWorkoutData(prev => {
      const filteredExercises = prev.exercises.filter(e => e.sectionId !== sectionId);
      const filteredSections = prev.sections.filter(s => s.id !== sectionId);
      return { ...prev, sections: filteredSections, exercises: filteredExercises };
    });
  };

  const addExercise = (sectionId) => {
    const order = workoutData.exercises.filter(e => e.sectionId === sectionId).length;
    const newExercise = {
      exerciseId: '',
      sets: Array(3).fill().map(() => ({ reps: 8, weight: 25 })), // Initialize with 3 sets
      reps: 8, 
      weight: 25, 
      rest: 60,
      notes: '',
      sectionId,
      order
    };
    setWorkoutData(prev => ({
      ...prev,
      exercises: [...prev.exercises, newExercise]
    }));
  };
  

  const removeExercise = (sectionId, order) => {
    setWorkoutData(prev => ({
      ...prev,
      exercises: prev.exercises.filter(e => !(e.sectionId === sectionId && e.order === order))
    }));
  };

  const updateExercise = (sectionId, order, field, value) => {
    setWorkoutData(prev => ({
      ...prev,
      exercises: prev.exercises.map(ex => {
        if (ex.sectionId === sectionId && ex.order === order) {
          return { ...ex, [field]: value };
        }
        return ex;
      })
    }));
  };

  const handleAssignOrUpdate = async () => {
    setLoading(true);
    try {
      const finalData = { 
        ...workoutData, 
        coachId: user.uid,
        exercises: workoutData.exercises.map(exercise => ({
          ...exercise,
          exerciseId: exercise.exerciseId || '',  
          sets: Array.isArray(exercise.sets) ? exercise.sets : [], 
        }))
      };

      // Always treat as a new assignment when coming from template
      if (clientId && date) {
        await assignCustomWorkout(
          finalData, 
          clientId, 
          new Date(date), 
          messageSendTime
        );
        
        // Navigate back to calendar after successful assignment
        navigate('/schedule');
      } else {
        // Handle error - missing required params
        console.error('Missing required clientId or date for assignment');
        alert('Unable to assign workout: Missing client or date information');
      }
    } catch (error) {
      console.error('Error assigning workout:', error);
      alert('Failed to assign workout. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Group exercises by sections
  const sectionsWithExercises = workoutData.sections.map(sec => ({
    ...sec,
    exercises: workoutData.exercises
      .filter(ex => ex.sectionId === sec.id)
      .sort((a, b) => a.order - b.order)
  }));

  const handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete this workout? This action cannot be undone.')) {
      return;
    }

    setLoading(true);
    try {
      await deleteAssignedWorkout(existingWorkout.coachId || user.uid, clientId, existingWorkout.id);
      handleWorkoutAssigned(); // Refresh the calen dar view
      handleClose(); // Close the overlay
    } catch (error) {
      console.error('Error deleting workout:', error);
      alert('Failed to delete workout. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleEditExercise = (exercise) => {
    setSelectedExercise(exercise);
    if (exercise) {
      setShowModifyOverlay(true);
    } else {
      setShowCreateOverlay(true);
    }
  };

  const handleSaveExercise = async (formData) => {
    try {
      let savedExercise;
      if (selectedExercise) {
        // Modifying existing exercise
        savedExercise = await modifyExercise(user.uid, selectedExercise.id, formData);
        // Update allExercises
        setAllExercises(prev => prev.map(ex => 
          ex.id === selectedExercise.id 
            ? { ...ex, ...formData, formCues: { instructions: formData.instructions, commonMistakes: formData.commonMistakes }, defaultVideoUrl: formData.mediaUrl } 
            : ex
        ));
      } else {
        // Creating new exercise
        savedExercise = await createCustomExercise(user.uid, formData);
        setAllExercises(prev => [...prev, {
          ...savedExercise,
          name: formData.name,
          instructions: formData.instructions,
          commonMistakes: formData.commonMistakes,
          mediaUrl: formData.mediaUrl,
          isCustom: true
        }]);
      }
  
      // Update workoutData exercises if any use this exerciseId
      setWorkoutData(prev => ({
        ...prev,
        exercises: prev.exercises.map(exercise => 
          exercise.exerciseId === (selectedExercise ? selectedExercise.id : savedExercise.id)
            ? { ...exercise, name: formData.name, instructions: formData.instructions, commonMistakes: formData.commonMistakes, mediaUrl: formData.mediaUrl }
            : exercise
        )
      }));
  
      // Close modify or create overlay only (not the entire ClientWorkoutEditOverlay)
      setShowModifyOverlay(false);
      setShowCreateOverlay(false);
      setSelectedExercise(null);
    } catch (error) {
      console.error('Error saving exercise:', error);
      alert('Failed to save exercise. Please try again.');
    }
  };
  

  const RecurringOptions = () => (
    <div className="mb-6 space-y-4 bg-neutral-800 p-4 rounded-lg">
      <div className="flex items-center justify-between">
        <label className="text-white font-medium">Make this a recurring workout?</label>
        <input
          type="checkbox"
          checked={isRecurring}
          onChange={(e) => setIsRecurring(e.target.checked)}
          className="h-4 w-4 rounded border-gray-300"
        />
      </div>
      
      {isRecurring && (
        <>
          <div className="space-y-2">
            <label className="text-white font-medium">Repeat for how many weeks?</label>
            <input
              type="number"
              min="1"
              max="52"
              value={recurringWeeks}
              onChange={(e) => setRecurringWeeks(parseInt(e.target.value))}
              className="w-full bg-neutral-700 text-white rounded p-2"
            />
          </div>
          
          <div className="space-y-2">
            <label className="text-white font-medium">Select days to repeat on:</label>
            <div className="grid grid-cols-7 gap-2">
              {Object.keys(selectedDays).map((day) => (
                <button
                  key={day}
                  onClick={() => setSelectedDays(prev => ({
                    ...prev,
                    [day]: !prev[day]
                  }))}
                  className={`p-2 rounded text-sm ${
                    selectedDays[day] 
                      ? 'bg-blue-600 text-white' 
                      : 'bg-neutral-700 text-gray-300'
                  }`}
                >
                  {day.slice(0, 3)}
                </button>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );

  const handleSave = async () => {
    if (!isRecurring) {
      // Existing single workout save logic
      // ... your current save logic ...
    } else {
      // Handle recurring workouts
      const startDate = new Date(date);
      const assignments = [];
      
      for (let week = 0; week < recurringWeeks; week++) {
        Object.entries(selectedDays).forEach(([day, isSelected]) => {
          if (isSelected) {
            const dayIndex = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].indexOf(day);
            const currentDate = new Date(startDate);
            currentDate.setDate(startDate.getDate() + (week * 7) + ((7 + dayIndex - startDate.getDay()) % 7));
            
            assignments.push({
              ...workoutData,
              scheduledTime: currentDate
            });
          }
        });
      }

      // Save all assignments
      try {
        await Promise.all(assignments.map(assignment => 
          assignCustomWorkout(assignment, clientId, assignment.scheduledTime)
        ));
        handleWorkoutAssigned();
      } catch (error) {
        console.error('Error assigning recurring workouts:', error);
      }
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-background-primary pt-20">
        <div className="container mx-auto p-4 max-w-6xl">
          <Card className="bg-background-secondary shadow-lg">
            <div className="p-8 flex items-center justify-center">
              <div className="space-y-4 text-center">
                <Loader2 className="h-8 w-8 animate-spin mx-auto text-accent-primary" />
                <p className="text-text-primary">Loading workout data...</p>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }

  if (!workoutData && !template) {
    return (
      <div className="min-h-screen bg-background-primary pt-20">
        <div className="container mx-auto p-4 max-w-6xl">
          <Card className="bg-background-secondary shadow-lg">
            <div className="p-8 flex items-center justify-center">
              <div className="space-y-4 text-center">
                <AlertCircle className="h-8 w-8 mx-auto text-red-500" />
                <p className="text-text-primary">Failed to load workout data</p>
                <Button onClick={() => navigate(-1)} variant="outline">
                  Go Back
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background-primary pt-20">
      <div className="container mx-auto p-4 max-w-6xl">
        <Card className="bg-background-secondary shadow-lg">
          {/* Header */}
          <div className="border-b border-border-light/20 p-6">
            <h2 className="text-2xl font-semibold text-text-primary">
              {existingWorkout ? 'Edit Workout' : 'Assign New Workout'}
            </h2>
          </div>

          {/* Content */}
          <div className="p-6">
            {/* Left Column - Basic Info */}
            <div className="col-span-4 space-y-6">
              <Card>
                <CardHeader>
                  <CardTitle className="text-xl flex items-center gap-2">
                    <FileText className="h-5 w-5 text-accent-primary" />
                    Basic Information
                  </CardTitle>
                </CardHeader>
                <CardContent className="space-y-4">
                  <div className="space-y-2">
                    <label className="text-sm font-medium text-text-primary">Workout Name</label>
                    <Input
                      placeholder="Enter workout name"
                      value={workoutData.name || ''}
                      onChange={(e) => setWorkoutData(prev => ({ ...prev, name: e.target.value }))}
                      className="bg-background-tertiary border-border-light focus:border-accent-primary"
                    />
                  </div>
                  <div className="space-y-2">
                    <label className="text-sm font-medium text-text-primary">Description</label>
                    <textarea
                      placeholder="Enter workout description"
                      value={workoutData.description || ''}
                      onChange={(e) => setWorkoutData(prev => ({ ...prev, description: e.target.value }))}
                      className="w-full min-h-[100px] rounded-lg bg-background-tertiary border-border-light focus:border-accent-primary p-3"
                    />
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle className="text-xl flex items-center gap-2">
                    <Calendar className="h-5 w-5 text-accent-primary" />
                    Schedule
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <MessageSchedule
                    scheduledDate={date}
                    messageSendTime={messageSendTime}
                    onMessageSendTimeChange={setMessageSendTime}
                  />
                </CardContent>
              </Card>
            </div>

            {/* Right Column - Sections & Exercises */}
            <div className="col-span-8 space-y-6">
              {/* Section Controls */}
              <div className="flex gap-2">
                {!workoutData.sections.some(s => s.type === 'warmup') && (
                  <Button
                    onClick={() => addSection('warmup')}
                    className="bg-accent-primary/10 text-accent-primary hover:bg-accent-primary/20"
                  >
                    <Plus className="h-4 w-4 mr-2" />
                    Add Warmup
                  </Button>
                )}
                {!workoutData.sections.some(s => s.type === 'main') && (
                  <Button
                    onClick={() => addSection('main')}
                    className="bg-accent-primary/10 text-accent-primary hover:bg-accent-primary/20"
                  >
                    <Plus className="h-4 w-4 mr-2" />
                    Add Main Section
                  </Button>
                )}
                {!workoutData.sections.some(s => s.type === 'cooldown') && (
                  <Button
                    onClick={() => addSection('cooldown')}
                    className="bg-accent-primary/10 text-accent-primary hover:bg-accent-primary/20"
                  >
                    <Plus className="h-4 w-4 mr-2" />
                    Add Cooldown
                  </Button>
                )}
              </div>

              {/* Sections */}
              {sectionsWithExercises.map((section) => (
                <Card key={section.id}>
                  <CardHeader className="flex flex-row items-center justify-between">
                    <CardTitle className="text-xl capitalize flex items-center gap-2">
                      {section.type === 'warmup' && <Flame className="h-5 w-5 text-orange-500" />}
                      {section.type === 'main' && <Dumbbell className="h-5 w-5 text-accent-primary" />}
                      {section.type === 'cooldown' && <Wind className="h-5 w-5 text-blue-500" />}
                      {section.name}
                    </CardTitle>
                    <Button
                      onClick={() => removeSection(section.id)}
                      variant="ghost"
                      className="text-red-500 hover:text-red-400 hover:bg-red-500/10"
                    >
                      <Trash2 className="h-4 w-4" />
                    </Button>
                  </CardHeader>

                  <CardContent className="space-y-4">
                    {/* Exercises */}
                    {section.exercises.map((exercise, idx) => (
                      <div
                        key={idx}
                        className="group relative border border-border-light rounded-lg p-4 hover:border-accent-primary/50 transition-all duration-200"
                      >
                        <div className="flex items-center justify-between gap-4">
                          <div className="flex-1">
                            <ExerciseSearch
                              value={exercise.exerciseId}
                              onChange={(value) => updateExercise(section.id, idx, 'exerciseId', value)}
                              onEditExercise={handleEditExercise}
                              allExercises={allExercises}
                              className="w-full"
                            />
                          </div>
                          <div className="flex items-center gap-2">
                            <Button
                              onClick={() => setExpandedExercise(expandedExercise === idx ? null : idx)}
                              variant="ghost"
                              className="text-accent-primary hover:bg-accent-primary/10"
                            >
                              {expandedExercise === idx ? (
                                <ChevronUp className="h-4 w-4" />
                              ) : (
                                <ChevronDown className="h-4 w-4" />
                              )}
                            </Button>
                            <Button
                              onClick={() => removeExercise(section.id, exercise.order)}
                              variant="ghost"
                              className="text-red-500 hover:text-red-400 hover:bg-red-500/10"
                            >
                              <Trash2 className="h-4 w-4" />
                            </Button>
                          </div>
                        </div>

                        {/* Expanded View */}
                        {expandedExercise === idx && (
                          <div className="mt-4 space-y-4">
                            <div className="grid grid-cols-2 gap-4">
                              <div className="space-y-2">
                                <label className="text-sm font-medium text-text-primary">Rest (seconds)</label>
                                <Input
                                  type="number"
                                  value={exercise.rest || 60}
                                  onChange={(e) => updateExercise(section.id, idx, 'rest', e.target.value)}
                                  className="bg-background-tertiary border-border-light"
                                />
                              </div>
                              <div className="space-y-2">
                                <label className="text-sm font-medium text-text-primary">Notes</label>
                                <Input
                                  value={exercise.notes || ''}
                                  onChange={(e) => updateExercise(section.id, idx, 'notes', e.target.value)}
                                  className="bg-background-tertiary border-border-light"
                                />
                              </div>
                            </div>
                            <ExpandedSetsEditor
                              sets={exercise.sets}
                              onChange={(sets) => updateExercise(section.id, idx, 'sets', sets)}
                            />
                          </div>
                        )}
                      </div>
                    ))}

                    {/* Add Exercise Button */}
                    <Button
                      onClick={() => addExercise(section.id)}
                      className="w-full bg-accent-primary/10 hover:bg-accent-primary/20 text-accent-primary border-2 border-dashed border-accent-primary/30"
                    >
                      <Plus className="h-4 w-4 mr-2" />
                      Add Exercise to {section.name}
                    </Button>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>

          {/* Footer */}
          <div className="border-t border-border-light/20 p-6 bg-background-tertiary">
            <div className="flex justify-end gap-4">
              {existingWorkout && (
                <Button
                  onClick={handleDelete}
                  disabled={loading}
                  variant="destructive"
                  className="bg-red-500 hover:bg-red-600"
                >
                  {loading ? 'Deleting...' : 'Delete Workout'}
                </Button>
              )}
              <Button
                onClick={handleAssignOrUpdate}
                disabled={loading}
                className="bg-accent-primary hover:bg-accent-primary/90"
              >
                {loading ? 'Saving...' : existingWorkout ? 'Update Workout' : 'Assign Workout'}
              </Button>
            </div>
          </div>
        </Card>
      </div>

      {/* Modals */}
      {showModifyOverlay && (
        <ModifyExerciseOverlay
          exercise={selectedExercise}
          onSave={handleSaveExercise}
          onClose={() => {
            setShowModifyOverlay(false);
            setSelectedExercise(null);
          }}
        />
      )}

      {showCreateOverlay && (
        <CreateExerciseOverlay
          onSave={handleSaveExercise}
          onClose={() => {
            setShowCreateOverlay(false);
            setSelectedExercise(null);
          }}
        />
      )}
    </div>
  );
};

export default ClientWorkoutEdit;