import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';
import { cn } from '../../lib/utils';
import theme from '../../styles/theme';

const SelectTrigger = React.forwardRef(({ children, className, disabled, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "relative flex h-9 w-full items-center justify-between rounded-md border border-border-medium bg-background-secondary px-3 py-2 text-sm",
      disabled && "opacity-50 cursor-not-allowed",
      className
    )}
    {...props}
  >
    {children}
  </div>
));
SelectTrigger.displayName = 'SelectTrigger';

const SelectContent = React.forwardRef(({ children, className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "absolute z-50 w-full mt-1 rounded-md border border-border-medium bg-background-secondary py-1 shadow-md",
      className
    )}
    {...props}
  >
    {children}
  </div>
));
SelectContent.displayName = 'SelectContent';

const SelectValue = React.forwardRef(({ children, placeholder, className, ...props }, ref) => (
  <span
    ref={ref}
    className={cn(
      "block truncate text-text-primary",
      !children && "text-text-light",
      className
    )}
    {...props}
  >
    {children || placeholder}
  </span>
));
SelectValue.displayName = 'SelectValue';

const Select = React.forwardRef(({
  value,
  onChange,
  onValueChange,
  children,
  placeholder,
  className,
  disabled,
  ...props
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  // Support both onChange and onValueChange for backward compatibility
  const handleValueChange = (newValue) => {
    if (onChange) {
      // Simulate an event object similar to native select
      onChange({ target: { value: newValue } });
    } else if (onValueChange) {
      onValueChange(newValue);
    }
  };

  // Find the option whose 'value' matches the currently selected 'value'
  const selectedOption = React.Children.toArray(children).find(
    (child) => React.isValidElement(child) && child.props.value === value
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleTriggerClick = () => {
    if (!disabled) {
      setIsOpen((prev) => !prev);
    }
  };

  return (
    <div ref={selectRef} className="relative">
      <SelectTrigger
        ref={ref}
        onClick={handleTriggerClick}
        className={className}
        disabled={disabled}
        {...props}
      >
        <SelectValue placeholder={placeholder}>
          {selectedOption?.props.children}
        </SelectValue>
        <ChevronDown
          className={cn(
            "h-4 w-4 text-text-light transition-transform",
            isOpen && "transform rotate-180"
          )}
        />
      </SelectTrigger>

      {isOpen && !disabled && (
        <SelectContent>
          {React.Children.map(children, (child) => {
            if (!React.isValidElement(child)) return null;
            
            // Handle both option elements and SelectItem components
            if (child.type === 'option') {
              return (
                <SelectItem
                  value={child.props.value}
                  onClick={() => {
                    handleValueChange(child.props.value);
                    setIsOpen(false);
                  }}
                  selected={child.props.value === value}
                >
                  {child.props.children}
                </SelectItem>
              );
            }
            
            return React.cloneElement(child, {
              onClick: () => {
                handleValueChange(child.props.value);
                setIsOpen(false);
              },
              selected: child.props.value === value
            });
          })}
        </SelectContent>
      )}
    </div>
  );
});
Select.displayName = 'Select';

const SelectItem = React.forwardRef(({
  value,
  children,
  onClick,
  selected,
  className,
  ...props
}, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "relative flex w-full cursor-pointer select-none items-center px-3 py-1.5 text-sm outline-none text-text-primary hover:bg-hover-secondary focus:bg-hover-secondary",
        selected && "bg-accent-primary/10 text-accent-primary",
        className
      )}
      onClick={onClick}
      {...props}
    >
      {children}
    </div>
  );
});
SelectItem.displayName = 'SelectItem';

export {
  Select,
  SelectTrigger,
  SelectContent,
  SelectValue,
  SelectItem
};
