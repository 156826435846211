import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Plus, Minus, AlertCircle } from 'lucide-react';

const NumberInput = ({ value, onChange, min = 0, max = 100 }) => {
  // Ensure value is a number for controlled component
  const [localValue, setLocalValue] = useState(value || 0);
  const [showAlert, setShowAlert] = useState(false);

  // Update both local and parent state
  const handleChange = (newValue) => {
    // Clamp value between min and max
    const clampedValue = Math.max(min, Math.min(max, newValue));
    setLocalValue(clampedValue);
    onChange(clampedValue);
  };

  const increment = () => {
    if (localValue < max) {
      handleChange(Number(localValue) + 1);
    }
  };

  const decrement = () => {
    if (localValue > min) {
      handleChange(Number(localValue) - 1);
    }
  };

  // Handle direct input change
  const handleInputChange = (e) => {
    const newValue = e.target.value === '' ? '' : Number(e.target.value);
    setLocalValue(newValue);
    
    // Only update parent if within range
    if (newValue !== '' && newValue >= min && newValue <= max) {
      onChange(newValue);
      setShowAlert(false);
    } else if (newValue !== '') {
      // Show alert for out-of-range values
      setShowAlert(true);
    }
  };

  // On blur, make sure value is within range
  const handleBlur = () => {
    if (localValue === '') {
      handleChange(min);
      setShowAlert(false);
    } else if (localValue < min || localValue > max) {
      // If out of range, clamp the value and show alert briefly
      handleChange(Number(localValue));
      setTimeout(() => setShowAlert(false), 3000);
    } else {
      handleChange(Number(localValue));
      setShowAlert(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="w-full max-w-md mx-auto"
    >
      <div className="flex flex-col items-center space-y-6">
        {/* Big number display */}
        <div className="text-7xl font-light text-accent-primary font-headers">
          {localValue}
        </div>
        
        {/* Number controls */}
        <div className="flex items-center space-x-6">
          <button
            type="button"
            onClick={decrement}
            disabled={localValue <= min}
            className={`w-12 h-12 rounded-full flex items-center justify-center transition-colors duration-200
                      ${localValue <= min 
                        ? 'bg-border-light text-text-light cursor-not-allowed' 
                        : 'bg-accent-primary text-white hover:bg-hover-primary'}`}
          >
            <Minus size={24} />
          </button>
          
          <input
            type="number"
            value={localValue}
            onChange={handleInputChange}
            onBlur={handleBlur}
            min={min}
            max={max}
            className="w-20 text-center text-2xl bg-transparent border-b-2 border-border-light
                     focus:outline-none focus:border-accent-primary text-text-primary
                     transition-colors duration-200"
          />
          
          <button
            type="button"
            onClick={increment}
            disabled={localValue >= max}
            className={`w-12 h-12 rounded-full flex items-center justify-center transition-colors duration-200
                      ${localValue >= max 
                        ? 'bg-border-light text-text-light cursor-not-allowed' 
                        : 'bg-accent-primary text-white hover:bg-hover-primary'}`}
          >
            <Plus size={24} />
          </button>
        </div>
        
        {/* Alert message for out-of-range values */}
        {showAlert && (
          <motion.div
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            className="flex items-center p-3 mt-2 rounded-md bg-red-500/10 border border-red-500 text-sm text-red-500"
          >
            <AlertCircle size={16} className="mr-2" />
            <span>{`Please enter a value between ${min} and ${max}`}</span>
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

export default NumberInput; 