// src/components/accountpagetabs/MessagingTab.jsx

import React, { useState, useEffect } from 'react';
import { Upload, X } from 'lucide-react';
import { Button } from '../ui/Button';
import { Label } from '../ui/Label';
import { httpsCallable, getFunctions } from 'firebase/functions';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import MessageOverlay from '../MessageOverlay';

const MessagingTab = ({ userId }) => {
  // State variables
  const [uploadedScreenshots, setUploadedScreenshots] = useState([]);
  const [hasUploads, setHasUploads] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isMessageOverlayOpen, setIsMessageOverlayOpen] = useState(false);

  // Message Style Settings
  const [toneStyle, setToneStyle] = useState([]);
  const [emojiUse, setEmojiUse] = useState(false);
  const [grammarLevel, setGrammarLevel] = useState('');
  const [languageStyle, setLanguageStyle] = useState('');

  const functions = getFunctions();

  // Message Style Options
  const toneStyleOptions = [
    'Witty',
    'Professional',
    'Encouraging',
    'Direct',
    'Detailed',
    'Concise',
    'Feminine',
    'Masculine',
    'Youthful',
    'Empathetic',
  ];

  const grammarOptions = {
    'Casual texter':
      'Informal grammar with relaxed phrasing. Occasional errors in punctuation, capitalization, spelling, etc.',
    'Perfect Grammar': 'Perfect grammar.',
  };

  const languageStyleOptions = ['Friendly', 'Authoritative', 'Motivational', 'Neutral'];

  useEffect(() => {
    const loadMessageStyleSettings = async () => {
      try {
        const messageStyleDoc = await getDoc(
          doc(db, 'coaches', userId, 'philosophy', 'messageStyleSettings')
        );
        if (messageStyleDoc.exists()) {
          const msgData = messageStyleDoc.data();
          setToneStyle(msgData.toneStyle || []);
          setEmojiUse(msgData.emojiUse !== undefined ? msgData.emojiUse : false);
          setGrammarLevel(msgData.grammarLevel || '');
          setLanguageStyle(msgData.languageStyle || '');
          setHasUploads(
            Boolean(
              msgData.exampleConversation && msgData.exampleConversation.trim().length > 0
            )
          );
        } else {
          setHasUploads(false); // No messageStyleSettings document
        }
      } catch (error) {
        console.error('Error loading message style settings:', error);
      }
    };
    loadMessageStyleSettings();
  }, [userId]);

  // Handlers for Message Style Settings
  const updateMessageStyleSetting = async (field, value) => {
    try {
      const messageStyleRef = doc(
        db,
        'coaches',
        userId,
        'philosophy',
        'messageStyleSettings'
      );
      await setDoc(
        messageStyleRef,
        {
          [field]: value,
        },
        { merge: true }
      );
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
    }
  };

  const handleToneStyleToggle = async (option) => {
    let updatedToneStyle;
    if (toneStyle.includes(option)) {
      // Remove the option
      updatedToneStyle = toneStyle.filter((item) => item !== option);
    } else {
      // Add the option
      updatedToneStyle = [...toneStyle, option];
    }
    setToneStyle(updatedToneStyle);
    await updateMessageStyleSetting('toneStyle', updatedToneStyle);
  };

  const handleEmojiUseChange = async (value) => {
    setEmojiUse(value);
    await updateMessageStyleSetting('emojiUse', value);
  };

  const handleGrammarLevelChange = async (option) => {
    setGrammarLevel(option);
    await updateMessageStyleSetting('grammarLevel', option);
  };

  const handleLanguageStyleChange = async (option) => {
    setLanguageStyle(option);
    await updateMessageStyleSetting('languageStyle', option);
  };

  // Handlers for uploading screenshots
  const handleScreenshotUpload = async (e) => {
    const files = Array.from(e.target.files);

    // Validate file count
    if (files.length + uploadedScreenshots.length > 3) {
      setUploadError('You can upload up to 3 screenshots.');
      return;
    }

    // Validate file types and sizes
    const maxSize = 5 * 1024 * 1024; // 5MB
    const validFiles = [];

    for (const file of files) {
      if (!file.type.startsWith('image/')) {
        setUploadError('Only image files are allowed.');
        return;
      }

      if (file.size > maxSize) {
        setUploadError('Each file must be less than 5MB.');
        return;
      }

      validFiles.push(file);
    }

    setUploadedScreenshots((prev) => [...prev, ...validFiles]);
    setUploadError('');
  };

  const handleRemoveScreenshot = (index) => {
    setUploadedScreenshots((prev) => prev.filter((_, i) => i !== index));
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // Extract base64 data without the data URL prefix
        const base64String = reader.result.split(',')[1];
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const handleSubmitScreenshots = async () => {
    if (uploadedScreenshots.length === 0) {
      setUploadError('Please upload at least one screenshot.');
      return;
    }

    setIsProcessing(true);
    setUploadError('');

    try {
      // Convert images to base64
      const base64Images = await Promise.all(uploadedScreenshots.map(fileToBase64));

      // Call the Cloud Function
      const extractConversation = httpsCallable(functions, 'extractConversation');
      const response = await extractConversation({
        userId: userId,
        images: base64Images,
      });

      if (response.data.success) {
        alert('Screenshots processed successfully!');
        setUploadedScreenshots([]);
        setHasUploads(true);
      } else {
        throw new Error(response.data.message || 'Failed to process screenshots');
      }
    } catch (error) {
      console.error('Error processing screenshots:', error);
      setUploadError(error.message || 'Failed to process screenshots');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="h-[calc(100vh-300px)] overflow-y-auto space-y-6 pr-4">
      {/* Screenshot Upload Section */}
      <div className="border-2 border-dashed border-neutral-700 rounded-lg p-8 space-y-4">
        <div className="flex flex-col items-center">
          <Upload className="h-12 w-12 text-gray-400 mb-4" />
          <Label className="text-white text-lg font-semibold mb-2">
            Upload Message Screenshots
          </Label>
          <p className="text-sm text-gray-400 mb-4">
            Drag and drop message screenshots or click to upload
          </p>

          {/* Upload Status Section */}
          {uploadedScreenshots.length > 0 && (
            <div className="w-full max-w-md bg-neutral-800 rounded-lg p-4 mb-4">
              <h4 className="text-white font-medium mb-2">
                Uploaded Files ({uploadedScreenshots.length}/3)
              </h4>
              {uploadedScreenshots.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between py-2 border-b border-neutral-700"
                >
                  <span className="text-sm text-gray-300">{file.name}</span>
                  <Button
                    size="sm"
                    variant="ghost"
                    onClick={() => handleRemoveScreenshot(index)}
                    className="text-red-400 hover:text-red-300"
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </div>
              ))}
            </div>
          )}

          {/* Upload Controls */}
          <div className="flex flex-col items-center gap-3 w-full max-w-md">
            {hasUploads && (
              <Button
                onClick={() => setIsMessageOverlayOpen(true)}
                variant="outline"
                className="w-full border-blue-500 text-blue-500 hover:bg-blue-500/10"
              >
                View Previous Uploads
              </Button>
            )}

            <div className="flex gap-3 w-full">
              <input
                type="file"
                id="message-upload"
                className="hidden"
                multiple
                accept="image/*"
                onChange={handleScreenshotUpload}
              />
              <Button
                onClick={() => document.getElementById('message-upload').click()}
                className="flex-1 bg-blue-500 hover:bg-blue-600"
                disabled={uploadedScreenshots.length >= 3}
              >
                Select Files
              </Button>
              {uploadedScreenshots.length > 0 && (
                <Button
                  onClick={handleSubmitScreenshots}
                  className="flex-1 bg-green-500 hover:bg-green-600"
                  disabled={isProcessing}
                >
                  {isProcessing ? 'Processing...' : 'Upload Files'}
                </Button>
              )}
            </div>
          </div>

          {uploadError && <p className="text-red-500 text-sm mt-2">{uploadError}</p>}
        </div>
      </div>

      {/* Message Style Settings */}
      <div className="space-y-6">
        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-white">Tone/Style</h3>
          <div className="flex flex-wrap gap-2">
            {toneStyleOptions.map((option) => (
              <Button
                key={option}
                onClick={() => handleToneStyleToggle(option)}
                className={`${
                  toneStyle.includes(option) ? 'bg-blue-500' : 'bg-neutral-800'
                } text-white hover:bg-blue-600`}
              >
                {option}
              </Button>
            ))}
          </div>
        </div>

        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-white">Emoji Use</h3>
          <div className="flex gap-4">
            {['Yes', 'No'].map((option) => (
              <Button
                key={option}
                onClick={() => handleEmojiUseChange(option === 'Yes')}
                className={`${
                  emojiUse === (option === 'Yes') ? 'bg-blue-500' : 'bg-neutral-800'
                } text-white hover:bg-blue-600`}
              >
                {option}
              </Button>
            ))}
          </div>
        </div>

        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-white">Grammar Level</h3>
          <div className="flex gap-4">
            {Object.keys(grammarOptions).map((option) => (
              <Button
                key={option}
                onClick={() => handleGrammarLevelChange(option)}
                className={`${
                  grammarLevel === option ? 'bg-blue-500' : 'bg-neutral-800'
                } text-white hover:bg-blue-600`}
              >
                {option}
              </Button>
            ))}
          </div>
        </div>

        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-white">Language Style</h3>
          <div className="flex gap-4">
            {languageStyleOptions.map((option) => (
              <Button
                key={option}
                onClick={() => handleLanguageStyleChange(option)}
                className={`${
                  languageStyle === option ? 'bg-blue-500' : 'bg-neutral-800'
                } text-white hover:bg-blue-600`}
              >
                {option}
              </Button>
            ))}
          </div>
        </div>
      </div>

      {/* Message Overlay Modal */}
      {isMessageOverlayOpen && (
        <MessageOverlay
          userId={userId}
          isOpen={isMessageOverlayOpen}
          onClose={() => setIsMessageOverlayOpen(false)}
        />
      )}
    </div>
  );
};

export default MessagingTab;
