// src/components/overlays/TemplateManagementOverlay.jsx

import React, { useState, useEffect } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import { Card, CardContent } from '../ui/Card';
import { Button } from '../ui/Button';
import { Plus, Trash2, Calendar, Loader2, Layout, ChevronRight, X, FolderPlus, MoreVertical, Folder, FolderMinus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Input } from '../ui/Input';
import { createWorkoutTemplate } from '../../services/workoutTemplateService';
import FolderCard from '../folders/FolderCard';
import { createFolder, deleteFolder, addTemplateToFolder, removeTemplateFromFolder } from '../../services/folderService';
import { Alert } from '../ui/Alert';


const TemplateManagementOverlay = ({ onClose, onTemplateSelect, isAssigning }) => {
  const { user } = useAuth();
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isCreating, setIsCreating] = useState(false);
  const [newTemplate, setNewTemplate] = useState({
    name: '',
    description: '',
    sections: [],
    exercises: []
  });
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [showTemplateMenu, setShowTemplateMenu] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showFolderSelector, setShowFolderSelector] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    title: '',
    message: '',
    onConfirm: () => {},
    type: 'info'
  });

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const templatesRef = collection(db, `coaches/${user.uid}/workoutTemplates`);
        const snapshot = await getDocs(templatesRef);
        const templateList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        templateList.sort((a, b) => b.createdAt?.toMillis() - a.createdAt?.toMillis());
        setTemplates(templateList);
      } catch (error) {
        console.error('Error fetching templates:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchFolders = async () => {
      try {
        const foldersRef = collection(db, `coaches/${user.uid}/folders`);
        const snapshot = await getDocs(foldersRef);
        const folderList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setFolders(folderList);
      } catch (error) {
        console.error('Error fetching folders:', error);
      }
    };

    if (user?.uid) {
      fetchTemplates();
      fetchFolders();
    }
  }, [user]);

  const handleDeleteTemplate = async (e, templateId) => {
    e.stopPropagation();
    if (!window.confirm('Are you sure you want to delete this template?')) return;
    
    try {
      await deleteDoc(doc(db, `coaches/${user.uid}/workoutTemplates/${templateId}`));
      setTemplates(templates.filter(template => template.id !== templateId));
    } catch (error) {
      console.error('Error deleting template:', error);
    }
  };

  const handleCreateClick = () => {
    onTemplateSelect({
      name: '',
      description: '',
      sections: [],
      exercises: [],
      isNew: true
    });
  };

  const handleCreateFolder = async () => {
    if (!newFolderName.trim()) return;
    
    try {
      const newFolder = await createFolder(user.uid, { name: newFolderName });
      setFolders([...folders, newFolder]);
      setNewFolderName('');
      setShowCreateFolder(false);
    } catch (error) {
      console.error('Error creating folder:', error);
    }
  };

  const handleDeleteFolder = async (folder) => {
    if (!window.confirm('Are you sure you want to delete this folder?')) return;
    
    try {
      await deleteFolder(user.uid, folder.id);
      setFolders(folders.filter(f => f.id !== folder.id));
      if (selectedFolder?.id === folder.id) {
        setSelectedFolder(null);
      }
    } catch (error) {
      console.error('Error deleting folder:', error);
    }
  };

  // Filter templates based on selected folder
  const displayedTemplates = selectedFolder
    ? templates.filter(template => selectedFolder.workoutTemplateIds?.includes(template.id))
    : templates;

  const handleAddToFolder = async (folder) => {
    try {
      if (folder.workoutTemplateIds?.includes(selectedTemplate.id)) {
        setAlertConfig({
          isOpen: true,
          title: 'Already Added',
          message: 'This template is already in the folder',
          type: 'info',
          onConfirm: () => setAlertConfig({ isOpen: false })
        });
        return;
      }

      await addTemplateToFolder(user.uid, folder.id, selectedTemplate.id);
      
      const updatedFolders = folders.map(f => {
        if (f.id === folder.id) {
          return {
            ...f,
            workoutTemplateIds: [...(f.workoutTemplateIds || []), selectedTemplate.id]
          };
        }
        return f;
      });
      
      setFolders(updatedFolders);
      setSelectedTemplate(null);
      setShowFolderSelector(false);
      
      setAlertConfig({
        isOpen: true,
        title: 'Success',
        message: 'Template added to folder successfully',
        type: 'success',
        onConfirm: () => setAlertConfig({ isOpen: false })
      });
    } catch (error) {
      console.error('Error adding template to folder:', error);
      setAlertConfig({
        isOpen: true,
        title: 'Error',
        message: 'Failed to add template to folder',
        type: 'error',
        onConfirm: () => setAlertConfig({ isOpen: false })
      });
    }
  };

  const handleRemoveFromFolder = async (e, template) => {
    e.stopPropagation();
    
    setAlertConfig({
      isOpen: true,
      title: 'Remove Template',
      message: 'Are you sure you want to remove this template from the folder?',
      type: 'warning',
      onConfirm: async () => {
        try {
          await removeTemplateFromFolder(user.uid, selectedFolder.id, template.id);
          
          // Update local state
          const updatedFolders = folders.map(f => {
            if (f.id === selectedFolder.id) {
              return {
                ...f,
                workoutTemplateIds: f.workoutTemplateIds.filter(id => id !== template.id)
              };
            }
            return f;
          });
          setFolders(updatedFolders);
          
          // Update templates display
          setTemplates(prevTemplates => [...prevTemplates]); // Force refresh
          
          setAlertConfig({ isOpen: false });
        } catch (error) {
          console.error('Error removing template from folder:', error);
          setAlertConfig({
            isOpen: true,
            title: 'Error',
            message: 'Failed to remove template from folder',
            type: 'error',
            onConfirm: () => setAlertConfig({ isOpen: false })
          });
        }
      }
    });
  };

  // Update the templates section to include folder actions
  const renderTemplateCard = (template) => (
    <Card
      key={template.id}
      onClick={() => {
        if (isAssigning) {
          onTemplateSelect(template);
        } else {
          // Get existing URL parameters
          const searchParams = new URLSearchParams(window.location.search);
          const clientId = searchParams.get('clientId');
          const date = searchParams.get('date');
          
          // Build new URL with parameters
          const params = new URLSearchParams();
          if (clientId) params.set('clientId', clientId);
          if (date) params.set('date', date);
          params.set('templateId', template.id);
          
          navigate(`/workouts/edit/new?${params.toString()}`);
        }
      }}
      className="group hover:border-accent-primary/30 transition-all duration-300 cursor-pointer"
    >
      <CardContent className="p-4">
        <div className="flex items-center justify-between">
          <div className="flex-1 min-w-0">
            <div className="flex items-center gap-3">
              <div className="flex-shrink-0 w-10 h-10 rounded-lg bg-accent-primary/10 flex items-center justify-center">
                <Calendar className="h-5 w-5 text-accent-primary" />
              </div>
              <div>
                <h3 className="font-medium text-text-primary truncate">
                  {template.name || 'Untitled Template'}
                </h3>
                <p className="text-sm text-text-light truncate">
                  {template.description || `${template.exercises?.length || 0} exercises`}
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            {selectedFolder ? (
              <Button
                variant="ghost"
                size="sm"
                onClick={(e) => handleRemoveFromFolder(e, template)}
                className="opacity-0 group-hover:opacity-100 text-amber-600 hover:text-amber-700 hover:bg-amber-50"
              >
                <FolderMinus className="h-4 w-4 mr-1" />
                Remove from Folder
              </Button>
            ) : (
              <Button
                variant="ghost"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedTemplate(template);
                  setShowFolderSelector(true);
                }}
                className="opacity-0 group-hover:opacity-100"
              >
                <FolderPlus className="h-4 w-4 mr-1" />
                Add to Folder
              </Button>
            )}
            {!selectedFolder && (
              <Button
                variant="ghost"
                size="icon"
                onClick={(e) => handleDeleteTemplate(e, template.id)}
                className="opacity-0 group-hover:opacity-100 text-red-500 hover:text-red-600 hover:bg-red-50"
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            )}
            <ChevronRight className="h-5 w-5 text-text-light group-hover:text-accent-primary transition-colors" />
          </div>
        </div>
      </CardContent>
    </Card>
  );

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <div className="bg-background-secondary max-w-3xl w-full rounded-xl shadow-lg relative">
        {/* Header */}
        <div className="p-6 border-b border-border-light flex items-center justify-between">
          <div className="flex items-center gap-3">
            <Layout className="h-6 w-6 text-accent-primary" />
            <h2 className="text-xl font-semibold text-text-primary font-headers">
              Workout Templates
            </h2>
          </div>
          <div className="flex items-center gap-3">
            <Button
              onClick={() => setShowCreateFolder(true)}
              variant="outline"
              className="text-text-light hover:text-text-primary"
            >
              <FolderPlus className="h-4 w-4 mr-2" />
              New Folder
            </Button>
            <Button
              onClick={handleCreateClick}
              className="bg-accent-primary hover:bg-hover-primary text-white transition-all duration-300"
            >
              <Plus className="h-4 w-4 mr-2" />
              Create Template
            </Button>
            <Button
              onClick={onClose}
              variant="ghost"
              className="text-text-light hover:text-text-primary"
              size="icon"
            >
              <X className="h-5 w-5" />
            </Button>
          </div>
        </div>

        {/* Content */}
        <div className="p-6 max-h-[70vh] overflow-y-auto">
          {/* Folders Section */}
          {!selectedFolder && folders.length > 0 && (
            <div className="mb-6">
              <h3 className="text-lg font-medium text-text-primary mb-4">Folders</h3>
              <div className="grid gap-4">
                {folders.map(folder => (
                  <FolderCard
                    key={folder.id}
                    folder={folder}
                    onSelect={setSelectedFolder}
                    onDelete={handleDeleteFolder}
                    onAddTemplate={selectedTemplate ? handleAddToFolder : undefined}
                  />
                ))}
              </div>
            </div>
          )}

          {/* Templates Section */}
          <div>
            {selectedFolder && (
              <FolderCard
                isBackButton
                onBack={() => setSelectedFolder(null)}
              />
            )}
            <h3 className="text-lg font-medium text-text-primary my-4">
              {selectedFolder ? `Templates in ${selectedFolder.name}` : 'All Templates'}
            </h3>
            <div className="grid gap-4">
              {displayedTemplates.map(template => renderTemplateCard(template))}
            </div>
          </div>
        </div>
      </div>

      {/* Create Folder Dialog */}
      {showCreateFolder && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-60">
          <div className="bg-background-secondary p-6 rounded-xl w-96">
            <h3 className="text-xl font-semibold mb-4">Create New Folder</h3>
            <Input
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              placeholder="Folder name"
              className="mb-4"
            />
            <div className="flex justify-end gap-3">
              <Button
                variant="ghost"
                onClick={() => setShowCreateFolder(false)}
              >
                Cancel
              </Button>
              <Button
                onClick={handleCreateFolder}
                disabled={!newFolderName.trim()}
              >
                Create
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* Folder Selection Overlay */}
      {showFolderSelector && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-60">
          <div className="bg-background-secondary p-6 rounded-xl w-[480px] max-h-[80vh] overflow-y-auto">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-xl font-semibold">Select Folder</h3>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => {
                  setShowFolderSelector(false);
                  setSelectedTemplate(null);
                }}
              >
                <X className="h-5 w-5" />
              </Button>
            </div>
            
            <div className="grid gap-4">
              {folders.map(folder => (
                <Card
                  key={folder.id}
                  onClick={() => handleAddToFolder(folder)}
                  className="hover:border-accent-primary/30 transition-all duration-300 cursor-pointer"
                >
                  <CardContent className="p-4">
                    <div className="flex items-center gap-3">
                      <div className="flex-shrink-0 w-10 h-10 rounded-lg bg-accent-primary/10 flex items-center justify-center">
                        <Folder className="h-5 w-5 text-accent-primary" />
                      </div>
                      <div>
                        <h3 className="font-medium text-text-primary">
                          {folder.name}
                        </h3>
                        <p className="text-sm text-text-light">
                          {folder.workoutTemplateIds?.length || 0} workouts
                        </p>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </div>
      )}

      <Alert
        {...alertConfig}
        onCancel={() => setAlertConfig({ isOpen: false })}
      />
    </div>
  );
};

export default TemplateManagementOverlay;
