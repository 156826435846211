import React from 'react';
import { motion } from 'framer-motion';
import { Check } from 'lucide-react';

const MultipleChoice = ({ options, value, onChange, autoAdvance = true }) => {
  const handleSelect = (optionValue) => {
    // First update the state with the new value
    onChange(optionValue);
    
    // Dispatch a custom event to signal automatic advancement
    // Use a minimal timeout to ensure the state update completes first
    if (autoAdvance) {
      setTimeout(() => {
        const event = new CustomEvent('questionAnswered', {
          detail: { questionType: 'multipleChoice', shouldAdvance: true }
        });
        document.dispatchEvent(event);
      }, 300); // Increased timeout for better animation completion
    }
  };

  // Animation variants for staggered children
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.07,
        delayChildren: 0.1
      }
    }
  };
  
  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0, transition: { duration: 0.5, ease: [0.22, 1, 0.36, 1] } }
  };

  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="show"
      className="w-full max-w-lg mx-auto"
    >
      <div className="space-y-4">
        {options.map((option) => (
          <motion.div
            key={option.value}
            variants={item}
            className="w-full"
          >
            <button
              type="button"
              onClick={() => handleSelect(option.value)}
              className={`w-full py-4 px-5 rounded-xl flex items-center justify-between
                       border-2 transition-all duration-300 ease-out relative
                       ${value === option.value 
                        ? 'border-accent-primary bg-gradient-to-r from-accent-primary/10 to-accent-primary/5 shadow-md' 
                        : 'border-border-light hover:border-accent-primary/30 hover:bg-background-tertiary'}`}
            >
              <div className="flex items-center">
                <div 
                  className={`w-6 h-6 mr-4 rounded-full flex items-center justify-center 
                           border-2 transition-all duration-300 ease-out
                           ${value === option.value 
                             ? 'bg-accent-primary border-accent-primary scale-110' 
                             : 'border-border-medium'}`}
                >
                  {value === option.value && (
                    <motion.div
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    >
                      <Check size={14} className="text-white" />
                    </motion.div>
                  )}
                </div>
                <span 
                  className={`text-lg transition-colors duration-200
                           ${value === option.value 
                             ? 'text-text-primary font-medium' 
                             : 'text-text-light'}`}
                >
                  {option.label}
                </span>
              </div>
              
              {/* Selected indicator badge */}
              {value === option.value && (
                <motion.div
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.3 }}
                  className="text-xs font-medium text-accent-primary rounded-full bg-accent-primary/10 px-3 py-1"
                >
                  Selected
                </motion.div>
              )}
            </button>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default MultipleChoice; 