// src/contexts/AuthContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  signInWithPopup 
} from 'firebase/auth';
import { doc, setDoc, getDoc, onSnapshot, updateDoc, serverTimestamp } from 'firebase/firestore';
import { auth, db, googleProvider } from '../firebase';
import { arrayUnion } from 'firebase/firestore'; 

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [onboardingComplete, setOnboardingComplete] = useState(false);
  const [onboardingStep, setOnboardingStep] = useState(0);

  useEffect(() => {
    let unsubscribeAuth = () => {};
    let unsubscribeSnapshot = () => {};

    unsubscribeAuth = onAuthStateChanged(auth, async (firebaseUser) => {
      try {
        if (firebaseUser) {
          const idTokenResult = await firebaseUser.getIdTokenResult(true);
          setUser(firebaseUser);
          setIsAdmin(idTokenResult.claims.isAdmin || false);
          
          const coachRef = doc(db, 'coaches', firebaseUser.uid);
          const coachDoc = await getDoc(coachRef);

          if (!coachDoc.exists()) {
            console.log('Creating missing coach document');
            await setDoc(coachRef, {
              email: firebaseUser.email,
              isCoach: true,
              isAdmin: false,
              subscriptionStatus: 'inactive',
              subscriptionQuantity: 0,
              numberOfClients: 0,
              clients: [],
              createdAt: serverTimestamp(),
              updatedAt: serverTimestamp(),
              stripeCustomerId: null,
              hasHadTrial: false,
              firstTime: true,
              firstTimeDashboard: true,
              onboardingComplete: false,
              onboardingStep: 0,
            });
          } else {
            // Update existing coach document with missing fields
            const data = coachDoc.data();
            const updateData = {};
            if (data.subscriptionQuantity === undefined) updateData.subscriptionQuantity = 0;
            if (data.numberOfClients === undefined) updateData.numberOfClients = 0;
            if (data.clients === undefined) updateData.clients = [];
            if (data.subscriptionStatus === undefined) updateData.subscriptionStatus = 'inactive';
            if (data.hasHadTrial === undefined) updateData.hasHadTrial = false;
            if (data.stripeCustomerId === undefined) updateData.stripeCustomerId = null;
            if (data.onboardingComplete === undefined) updateData.onboardingComplete = false;
            if (data.onboardingStep === undefined) updateData.onboardingStep = 0;
            
            if (Object.keys(updateData).length > 0) {
              updateData.updatedAt = serverTimestamp();
              await updateDoc(coachRef, updateData);
              console.log('Updated coach document with missing fields:', updateData);
            }
          }

          // Set up listener for coach document
          unsubscribeSnapshot = onSnapshot(coachRef, (doc) => {
            if (doc.exists()) {
              const data = doc.data();
              console.log('Coach document updated:', data);
              setSubscriptionStatus(data.subscriptionStatus || 'inactive');
              setIsAdmin(data.isAdmin || false);
              setOnboardingComplete(data.onboardingComplete || false);
              setOnboardingStep(data.onboardingStep || 0);
            } else {
              console.error('Coach document does not exist after creation attempt');
            }
          }, (error) => {
            console.error("Coach document listener error:", error);
          });
        } else {
          setUser(null);
          setIsAdmin(false);
          setSubscriptionStatus(null);
          setOnboardingComplete(false);
          setOnboardingStep(0);
          if (unsubscribeSnapshot) unsubscribeSnapshot();
        }
      } catch (error) {
        console.error('Error in auth state change:', error);
      } finally {
        setLoading(false);
      }
    });

    return () => {
      unsubscribeAuth();
      if (unsubscribeSnapshot) unsubscribeSnapshot();
    };
  }, []);

  const login = async (email, password) => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      return result.user;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const signup = async (email, password) => {
    try {
      const result = await createUserWithEmailAndPassword(auth, email, password);
      
      // Create coach document
      await setDoc(doc(db, 'coaches', result.user.uid), {
        email: result.user.email,
        isCoach: true,
        isAdmin: false,
        subscriptionStatus: 'inactive',
        subscriptionQuantity: 0,
        numberOfClients: 0,
        clients: [],
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        stripeCustomerId: null,
        hasHadTrial: false,
        onboardingComplete: false,
        onboardingStep: 0,
      });

      return result.user;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      
      // Check if the user document already exists
      const coachRef = doc(db, 'coaches', user.uid);
      const coachDoc = await getDoc(coachRef);
      
      // Determine if onboarding is needed
      let needsOnboarding = false;
      
      if (!coachDoc.exists()) {
        // New user - create document and needs onboarding
        needsOnboarding = true;
        await setDoc(coachRef, {
          email: user.email,
          displayName: user.displayName || '',
          photoURL: user.photoURL || '',
          isCoach: true,
          isAdmin: false,
          subscriptionStatus: 'inactive',
          subscriptionQuantity: 0,
          numberOfClients: 0,
          clients: [],
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          stripeCustomerId: null,
          hasHadTrial: false,
          firstTime: true,
          firstTimeDashboard: true,
          onboardingComplete: false,
          onboardingStep: 0,
        });
      } else {
        // Check if user has completed onboarding
        const userData = coachDoc.data();
        needsOnboarding = !userData.onboardingComplete;
      }
      
      return { user, needsOnboarding };
    } catch (error) {
      console.error('Error signing in with Google:', error);
      throw new Error(error.message);
    }
  };

  const updateOnboardingProgress = async (step) => {
    if (!user?.uid) return;
    
    try {
      await updateDoc(doc(db, 'coaches', user.uid), {
        onboardingStep: step,
        updatedAt: serverTimestamp()
      });
    } catch (error) {
      console.error('Error updating onboarding progress:', error);
    }
  };
  
  const completeOnboarding = async () => {
    if (!user?.uid) return;
    
    try {
      await updateDoc(doc(db, 'coaches', user.uid), {
        onboardingComplete: true,
        updatedAt: serverTimestamp()
      });
    } catch (error) {
      console.error('Error completing onboarding:', error);
    }
  };

  const logout = () => signOut(auth);

  const value = {
    user,
    isAdmin,
    subscriptionStatus,
    loading,
    onboardingComplete,
    onboardingStep,
    updateOnboardingProgress,
    completeOnboarding,
    login,
    signup,
    signInWithGoogle,
    logout,
    getIdToken: async () => user?.getIdToken(true),
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
