import React, { useState, useEffect } from 'react';
import { Loader } from 'lucide-react';

const FullScreenMedia = ({ 
  mediaUrl, 
  mediaType, 
  fallbackImage, 
  alt = "Coach background", 
  objectPosition = "center"
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
  // Handle window resize for responsive behavior
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  // Determine media type if not explicitly provided
  const detectMediaType = (url) => {
    if (!url) return 'none';
    if (mediaType) return mediaType;
    
    const extension = url.split('.').pop().toLowerCase();
    if (['mp4', 'webm', 'mov'].includes(extension)) return 'video';
    if (['jpg', 'jpeg', 'png', 'webp'].includes(extension)) return 'image';
    if (['gif'].includes(extension)) return 'gif';
    
    return 'image'; // Default to image
  };
  
  const type = detectMediaType(mediaUrl);
  
  const handleMediaLoad = () => {
    setLoading(false);
  };
  
  const handleMediaError = () => {
    setLoading(false);
    setError(true);
    console.error("Failed to load media:", mediaUrl);
  };

  return (
    <div className="absolute inset-0 w-full h-full overflow-hidden bg-black">
      {/* Loading indicator */}
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-background-primary/20 z-10">
          <Loader className="w-10 h-10 text-white animate-spin" />
        </div>
      )}
      
      {/* Enhanced darkening overlay - stronger to improve text readability */}
      <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/40 to-black/70 z-[1]"></div>
      
      {/* Media Container - Optimized for portrait and landscape formats */}
      <div className={`relative h-full w-full ${!isMobile ? 'md:mx-auto md:max-w-screen-xl' : ''}`}>
        {type === 'video' && (
          <video
            src={mediaUrl}
            autoPlay
            muted
            loop
            playsInline
            onLoadedData={handleMediaLoad}
            onError={handleMediaError}
            className={`absolute inset-0 h-full w-full object-cover z-0 ${loading ? 'opacity-0' : 'opacity-100'} transition-opacity duration-500`}
            style={{ objectPosition }}
          />
        )}
        
        {(type === 'image' || type === 'gif' || error) && (
          <img
            src={error ? fallbackImage || '' : mediaUrl}
            alt={alt}
            onLoad={handleMediaLoad}
            onError={handleMediaError}
            className={`absolute inset-0 h-full w-full object-cover z-0 ${loading ? 'opacity-0' : 'opacity-100'} transition-opacity duration-500`}
            style={{ objectPosition }}
          />
        )}
      </div>
      
      {/* Gradient overlays to improve readability and text contrast */}
      <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent z-[2] pointer-events-none"></div>
      
      {/* Horizontal gradient overlays for desktop to blend edges - only shown on desktop */}
      {!isMobile && (
        <>
          <div className="hidden md:block absolute top-0 bottom-0 left-0 w-1/6 bg-gradient-to-r from-black to-transparent z-[2]"></div>
          <div className="hidden md:block absolute top-0 bottom-0 right-0 w-1/6 bg-gradient-to-l from-black to-transparent z-[2]"></div>
        </>
      )}
      
      {/* Fallback for no media */}
      {type === 'none' && !loading && (
        <div className="absolute inset-0 bg-gradient-to-br from-accent-primary to-background-primary z-0" />
      )}
    </div>
  );
};

export default FullScreenMedia; 