import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, getDoc, doc, orderBy, limit, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/Card';
import { Button } from '../ui/Button';
import { Input } from '../ui/Input';
import { Select } from '../ui/Select';
import { Badge } from '../ui/Badge';
import { Search, Calendar, User, Mail, Phone, ArrowLeft, Clock, Filter, Download, Trash2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import theme from '../../styles/theme';
import { format } from 'date-fns';

// Default questions metadata
const DEFAULT_QUESTIONS = {
  bio_age: { title: 'Age', type: 'number', unit: 'years' },
  bio_height: { title: 'Height', type: 'number', unit: 'cm' },
  bio_weight: { title: 'Weight', type: 'number', unit: 'kg' },
  bio_sleep: { title: 'Hours of Sleep', type: 'number', unit: 'hours' },
  diet_restrictions: { 
    title: 'Dietary Restrictions', 
    type: 'checkbox', 
    isArray: true,
    valueMap: {
      'vegetarian': 'Vegetarian',
      'vegan': 'Vegan',
      'gluten_free': 'Gluten Free',
      'dairy_free': 'Dairy Free',
      'keto': 'Keto',
      'paleo': 'Paleo',
      'pescatarian': 'Pescatarian',
      'none': 'None'
    }
  },
  equipment_access: { 
    title: 'Equipment Access', 
    type: 'checkbox', 
    isArray: true,
    valueMap: {
      'full_gym': 'Full Gym',
      'home_gym': 'Home Gym',
      'dumbbells': 'Dumbbells',
      'kettlebells': 'Kettlebells',
      'resistance_bands': 'Resistance Bands',
      'bench': 'Bench',
      'pull_up_bar': 'Pull Up Bar',
      'cardio_machine': 'Cardio Machine',
      'no_equipment': 'No Equipment'
    }
  },
  exercise_likes: { 
    title: 'Exercise Preferences', 
    type: 'checkbox', 
    isArray: true,
    valueMap: {
      'weights': 'Weight Training',
      'cardio': 'Cardio',
      'swimming': 'Swimming',
      'cycling': 'Cycling',
      'running': 'Running',
      'yoga': 'Yoga',
      'hiit': 'HIIT',
      'functional': 'Functional Training',
      'pilates': 'Pilates'
    }
  },
  exercise_dislikes: { 
    title: 'Exercise Dislikes', 
    type: 'checkbox', 
    isArray: true,
    valueMap: {
      'weights': 'Weight Training',
      'cardio': 'Cardio',
      'swimming': 'Swimming',
      'cycling': 'Cycling',
      'running': 'Running',
      'yoga': 'Yoga',
      'hiit': 'HIIT',
      'functional': 'Functional Training',
      'pilates': 'Pilates'
    }
  },
  food_dislikes: { title: 'Food Dislikes', type: 'checkbox', isArray: true },
  goals_primary: { 
    title: 'Primary Goal', 
    type: 'multipleChoice',
    valueMap: {
      'weight_loss': 'Weight Loss',
      'muscle_gain': 'Muscle Gain',
      'strength': 'Strength',
      'endurance': 'Endurance',
      'flexibility': 'Flexibility',
      'mobility': 'Mobility',
      'overall_health': 'Overall Health',
      'athletic_performance': 'Athletic Performance'
    }
  },
  goals_secondary: { 
    title: 'Secondary Goals', 
    type: 'checkbox', 
    isArray: true,
    valueMap: {
      'weight_loss': 'Weight Loss',
      'muscle_gain': 'Muscle Gain',
      'strength': 'Strength',
      'endurance': 'Endurance',
      'flexibility': 'Flexibility',
      'mobility': 'Mobility',
      'stress_reduction': 'Stress Reduction',
      'energy': 'Energy',
      'sleep': 'Sleep',
      'overall_health': 'Overall Health',
      'athletic_performance': 'Athletic Performance'
    }
  },
  injuries: { title: 'Injuries', type: 'complex', isArray: true },
  time_days_available: { title: 'Days Available', type: 'number', unit: 'days/week' },
  time_preference: { 
    title: 'Time Preference', 
    type: 'multipleChoice',
    valueMap: {
      'early_morning': 'Early Morning',
      'morning': 'Morning',
      'afternoon': 'Afternoon',
      'evening': 'Evening',
      'late_night': 'Late Night',
      'flexible': 'Flexible'
    }
  },
  time_session_duration: { 
    title: 'Session Duration', 
    type: 'multipleChoice',
    valueMap: {
      'under_30min': 'Under 30 minutes',
      '30_45min': '30-45 minutes',
      '45_60min': '45-60 minutes',
      '60_90min': '60-90 minutes',
      'over_90min': 'Over 90 minutes'
    }
  }
};

const FormResponses = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [forms, setForms] = useState([]);
  const [selectedFormId, setSelectedFormId] = useState('');
  const [responses, setResponses] = useState([]);
  const [filteredResponses, setFilteredResponses] = useState([]);
  const [selectedResponse, setSelectedResponse] = useState(null);
  const [questions, setQuestions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [dateFilter, setDateFilter] = useState('all');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  // Fetch the coach's forms
  useEffect(() => {
    const fetchForms = async () => {
      if (!user?.uid) return;
      
      try {
        const formsQuery = query(
          collection(db, 'onboardingForms'),
          where('coachId', '==', user.uid)
        );
        
        const formsSnapshot = await getDocs(formsQuery);
        const formsList = formsSnapshot.docs.map(formDoc => ({
          id: formDoc.id,
          ...formDoc.data()
        }));
        
        setForms(formsList);
        
        // Select the first form by default if available
        if (formsList.length > 0 && !selectedFormId) {
          setSelectedFormId(formsList[0].id);
        }
      } catch (error) {
        console.error('Error fetching forms:', error);
      }
    };
    
    fetchForms();
  }, [user?.uid]);

  // Fetch responses for the selected form
  useEffect(() => {
    const fetchResponses = async () => {
      if (!selectedFormId) {
        setResponses([]);
        setFilteredResponses([]);
        setQuestions({});
        setIsLoading(false);
        return;
      }
      
      setIsLoading(true);
      
      try {
        // Fetch questions for this form to show readable labels later
        const questionsQuery = query(
          collection(db, 'onboardingForms', selectedFormId, 'questions')
        );
        
        const questionsSnapshot = await getDocs(questionsQuery);
        const questionsData = {};
        
        questionsSnapshot.docs.forEach(doc => {
          questionsData[doc.id] = doc.data();
        });
        
        // Add default questions metadata
        setQuestions({...DEFAULT_QUESTIONS, ...questionsData});
        
        // Fetch responses for this form
        const responsesQuery = query(
          collection(db, 'onboardingResponses'),
          where('formId', '==', selectedFormId),
          where('coachId', '==', user.uid),
          orderBy('completedAt', 'desc')
        );
        
        const responsesSnapshot = await getDocs(responsesQuery);
        const responsesList = await Promise.all(responsesSnapshot.docs.map(async responseDoc => {
          const responseData = responseDoc.data();
          
          // Try to get user data if userId exists
          let userData = null;
          if (responseData.userId) {
            const userDoc = await getDoc(doc(db, 'users', responseData.userId));
            if (userDoc.exists()) {
              userData = userDoc.data();
            }
          }
          
          return {
            id: responseDoc.id,
            ...responseData,
            userData
          };
        }));
        
        setResponses(responsesList);
        setFilteredResponses(responsesList);
      } catch (error) {
        console.error('Error fetching responses:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchResponses();
  }, [selectedFormId, user?.uid]);

  // Handle search and filtering
  useEffect(() => {
    if (!responses.length) {
      setFilteredResponses([]);
      return;
    }
    
    let filtered = [...responses];
    
    // Apply search term filter
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      filtered = filtered.filter(response => {
        // Search in user data
        if (response.userData) {
          if (
            response.userData.name?.toLowerCase().includes(term) ||
            response.userData.email?.toLowerCase().includes(term) ||
            response.userData.phoneNumber?.includes(term)
          ) {
            return true;
          }
        }
        
        // Search in responses
        const responseValues = Object.values(response.responses || {});
        return responseValues.some(value => 
          String(value).toLowerCase().includes(term)
        );
      });
    }
    
    // Apply date filter
    if (dateFilter !== 'all') {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
      const lastWeek = new Date(today);
      lastWeek.setDate(lastWeek.getDate() - 7);
      const lastMonth = new Date(today);
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      
      filtered = filtered.filter(response => {
        if (!response.completedAt) return false;
        
        const completedDate = response.completedAt.toDate();
        
        switch (dateFilter) {
          case 'today':
            return completedDate >= today;
          case 'yesterday':
            return completedDate >= yesterday && completedDate < today;
          case 'week':
            return completedDate >= lastWeek;
          case 'month':
            return completedDate >= lastMonth;
          default:
            return true;
        }
      });
    }
    
    setFilteredResponses(filtered);
  }, [responses, searchTerm, dateFilter]);

  const getReadableAnswer = (questionId, answer) => {
    const question = questions[questionId];
    if (!question) return formatValue(answer);
    
    // Handle default question formats with valueMap
    if (question.valueMap) {
      // For array answers
      if (Array.isArray(answer)) {
        return answer.map(value => 
          question.valueMap[value] || formatValue(value)
        ).join(', ');
      }
      
      // For single value
      return question.valueMap[answer] || formatValue(answer);
    }
    
    // For arrays of simple values (like strings)
    if (Array.isArray(answer)) {
      if (question.type === 'complex') {
        return answer.map(item => {
          if (typeof item === 'object' && item !== null) {
            return `${item.name || ''} ${item.severity ? `(${item.severity})` : ''}`;
          }
          return formatValue(item);
        }).join(', ');
      }
      
      return answer.map(formatValue).join(', ');
    }
    
    // For multiple choice or checkbox questions with options
    if ((question.type === 'multipleChoice' || question.type === 'checkbox') && question.options) {
      // For array answers (checkbox)
      if (Array.isArray(answer)) {
        return answer.map(value => {
          const option = question.options.find(opt => opt.value === value);
          return option ? option.label : formatValue(value);
        }).join(', ');
      }
      
      // For single value (multiple choice)
      const option = question.options.find(opt => opt.value === answer);
      return option ? option.label : formatValue(answer);
    }
    
    // Add unit if specified
    if (question.unit && (typeof answer === 'number' || !isNaN(Number(answer)))) {
      return `${answer} ${question.unit}`;
    }
    
    // Default return
    return formatValue(answer);
  };

  // Helper function to format values nicely
  const formatValue = (value) => {
    if (value === null || value === undefined) return '';
    
    if (Array.isArray(value)) {
      return value.map(formatValue).join(', ');
    }
    
    if (typeof value === 'object') {
      return JSON.stringify(value, null, 2);
    }
    
    // Format snake_case strings like "early_morning" to "Early Morning"
    if (typeof value === 'string' && value.includes('_')) {
      return value.split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
    
    return String(value);
  };

  const exportToCsv = () => {
    if (!filteredResponses.length) return;
    
    // Get all unique question IDs across all responses
    const allQuestionIds = new Set();
    filteredResponses.forEach(response => {
      Object.keys(response.responses || {}).forEach(questionId => {
        allQuestionIds.add(questionId);
      });
    });
    
    // Create headers
    const headers = [
      'Submission Date',
      'Name',
      'Email',
      'Phone',
      ...Array.from(allQuestionIds).map(qId => {
        // For default questions without metadata, create readable headers
        if (questions[qId]?.title) {
          return questions[qId].title;
        } else {
          // Format string like 'bio_age' to 'Bio Age'
          return qId.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
        }
      })
    ];
    
    // Create rows
    const rows = filteredResponses.map(response => {
      const row = [
        response.completedAt ? format(response.completedAt.toDate(), 'yyyy-MM-dd HH:mm') : '',
        response.userData?.name || response.contactInfo?.name || '',
        response.userData?.email || response.contactInfo?.email || '',
        response.userData?.phoneNumber || response.contactInfo?.phone || '',
      ];
      
      // Add answers for each question
      Array.from(allQuestionIds).forEach(qId => {
        const answer = response.responses?.[qId];
        row.push(answer ? getReadableAnswer(qId, answer) : '');
      });
      
      return row;
    });
    
    // Combine headers and rows
    const csvContent = [
      headers.join(','),
      ...rows.map(row => row.map(cell => `"${String(cell).replace(/"/g, '""')}"`).join(','))
    ].join('\n');
    
    // Create and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `form-responses-${selectedFormId}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDeleteResponse = async () => {
    if (!selectedResponse) return;
    
    try {
      // Delete the response from Firestore
      await deleteDoc(doc(db, 'onboardingResponses', selectedResponse.id));
      
      // Update the local state
      setResponses(prevResponses => prevResponses.filter(response => response.id !== selectedResponse.id));
      setFilteredResponses(prevFilteredResponses => prevFilteredResponses.filter(response => response.id !== selectedResponse.id));
      
      // Reset selection and close modal
      setSelectedResponse(null);
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error('Error deleting response:', error);
      alert('Failed to delete response. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-background-primary pt-20">
      <div className="container mx-auto p-4">
        <div className="mb-6">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center">
              <Button
                variant="ghost"
                onClick={() => navigate('/onboarding-forms')}
                className="mr-2"
              >
                <ArrowLeft className="h-4 w-4" />
              </Button>
              <div>
                <h1 className="text-2xl font-semibold text-text-primary">Form Responses</h1>
                <p className="text-text-light">View and manage client form submissions</p>
              </div>
            </div>
            
            <Button
              variant="outline"
              onClick={exportToCsv}
              disabled={!filteredResponses.length}
              className="flex items-center gap-2"
            >
              <Download className="h-4 w-4" />
              Export to CSV
            </Button>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
            <div>
              <label htmlFor="form-selector" className="block text-sm font-medium text-text-primary mb-1">
                Select Form
              </label>
              <Select
                id="form-selector"
                value={selectedFormId}
                onChange={(e) => setSelectedFormId(e.target.value)}
                className="w-full"
              >
                {forms.map(form => (
                  <option key={form.id} value={form.id}>{form.title}</option>
                ))}
              </Select>
            </div>
            
            <div>
              <label htmlFor="date-filter" className="block text-sm font-medium text-text-primary mb-1">
                Date Filter
              </label>
              <Select
                id="date-filter"
                value={dateFilter}
                onChange={(e) => setDateFilter(e.target.value)}
                className="w-full"
              >
                <option value="all">All Time</option>
                <option value="today">Today</option>
                <option value="yesterday">Yesterday</option>
                <option value="week">Last 7 Days</option>
                <option value="month">Last 30 Days</option>
              </Select>
            </div>
            
            <div>
              <label htmlFor="search" className="block text-sm font-medium text-text-primary mb-1">
                Search
              </label>
              <div className="relative">
                <Input
                  id="search"
                  type="text"
                  placeholder="Search by name, email or response..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-10"
                />
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-text-light" />
              </div>
            </div>
          </div>
        </div>
        
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Responses List */}
          <div className="lg:col-span-1">
            <Card>
              <CardHeader className="pb-2">
                <CardTitle className="text-lg">
                  Submissions ({filteredResponses.length})
                </CardTitle>
              </CardHeader>
              <CardContent>
                {isLoading ? (
                  <div className="flex justify-center py-6">
                    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-l-2 border-accent-primary"></div>
                  </div>
                ) : filteredResponses.length === 0 ? (
                  <div className="text-center py-10">
                    <p className="text-text-light">No form responses found</p>
                  </div>
                ) : (
                  <div className="space-y-3 max-h-[70vh] overflow-y-auto pr-1">
                    {filteredResponses.map(response => (
                      <div 
                        key={response.id}
                        className={`p-4 rounded-lg hover:bg-hover-secondary cursor-pointer transition-colors ${selectedResponse?.id === response.id ? 'bg-hover-secondary border-l-4 border-accent-primary' : 'bg-background-secondary'}`}
                        onClick={() => setSelectedResponse(response)}
                      >
                        <div className="flex justify-between items-start mb-2">
                          <h3 className="font-medium text-text-primary">
                            {response.userData?.name || response.contactInfo?.name || 'Anonymous User'}
                          </h3>
                          <Badge variant={response.userData?.subscriptionStatus === 'active' ? 'success' : 'default'}>
                            {response.userData?.subscriptionStatus === 'active' ? 'Client' : 'Lead'}
                          </Badge>
                        </div>
                        
                        {(response.userData?.email || response.contactInfo?.email) && (
                          <div className="flex items-center text-sm text-text-light mb-1">
                            <Mail className="h-3 w-3 mr-1" />
                            {response.userData?.email || response.contactInfo?.email}
                          </div>
                        )}
                        
                        {response.completedAt && (
                          <div className="flex items-center text-xs text-text-light">
                            <Clock className="h-3 w-3 mr-1" />
                            {format(response.completedAt.toDate(), 'MMM d, yyyy h:mm a')}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
          
          {/* Response Details */}
          <div className="lg:col-span-2">
            {selectedResponse ? (
              <Card>
                <CardHeader className="pb-2 border-b">
                  <div className="flex justify-between items-center">
                    <CardTitle className="text-lg">
                      Response Details
                    </CardTitle>
                    <div className="flex items-center gap-2">
                      <div className="text-sm text-text-light">
                        Completed: {selectedResponse.completedAt ? 
                          format(selectedResponse.completedAt.toDate(), 'MMM d, yyyy h:mm a') : 
                          'N/A'}
                      </div>
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => setIsDeleteModalOpen(true)}
                        className="text-red-500 hover:text-red-700 hover:bg-red-50"
                      >
                        <Trash2 className="h-4 w-4 mr-1" />
                        Delete
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                <CardContent className="py-4">
                  {/* User Information */}
                  <div className="bg-background-tertiary p-4 rounded-lg mb-6">
                    <h3 className="font-medium mb-3 text-text-primary">User Information</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <div className="flex items-center mb-2">
                          <User className="h-4 w-4 text-text-light mr-2" />
                          <span className="text-sm font-medium">Name:</span>
                          <span className="text-sm ml-2">{selectedResponse.userData?.name || selectedResponse.contactInfo?.name || 'Not provided'}</span>
                        </div>
                        <div className="flex items-center">
                          <Mail className="h-4 w-4 text-text-light mr-2" />
                          <span className="text-sm font-medium">Email:</span>
                          <span className="text-sm ml-2">{selectedResponse.userData?.email || selectedResponse.contactInfo?.email || 'Not provided'}</span>
                        </div>
                      </div>
                      <div>
                        <div className="flex items-center mb-2">
                          <Phone className="h-4 w-4 text-text-light mr-2" />
                          <span className="text-sm font-medium">Phone:</span>
                          <span className="text-sm ml-2">{selectedResponse.userData?.phoneNumber || selectedResponse.contactInfo?.phone || 'Not provided'}</span>
                        </div>
                        <div className="flex items-center">
                          <Calendar className="h-4 w-4 text-text-light mr-2" />
                          <span className="text-sm font-medium">Submitted on:</span>
                          <span className="text-sm ml-2">
                            {selectedResponse.completedAt ? 
                              format(selectedResponse.completedAt.toDate(), 'MMMM d, yyyy') : 
                              'N/A'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  {/* Form Responses */}
                  <h3 className="font-medium mb-3 text-text-primary">Form Responses</h3>
                  <div className="space-y-4 max-h-[50vh] overflow-y-auto pr-1">
                    {Object.keys(selectedResponse.responses || {}).map(questionId => {
                      const question = questions[questionId];
                      const answer = selectedResponse.responses[questionId];
                      
                      // If no question metadata found, create a generic title from the key
                      const questionTitle = question ? question.title : 
                        questionId.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
                      
                      return (
                        <div key={questionId} className="bg-background-secondary p-4 rounded-lg">
                          <h4 className="font-medium text-sm text-text-primary mb-1">
                            {questionTitle}
                          </h4>
                          {question?.description && (
                            <p className="text-xs text-text-light mb-2">{question.description}</p>
                          )}
                          <div className="bg-background-primary p-3 rounded border border-border-light">
                            {getReadableAnswer(questionId, answer)}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </CardContent>
              </Card>
            ) : (
              <Card>
                <CardContent className="flex flex-col items-center justify-center py-12">
                  <div className="bg-background-tertiary rounded-full p-4 mb-4">
                    <Filter className="h-6 w-6 text-text-light" />
                  </div>
                  <h3 className="text-xl font-medium text-text-primary mb-2">No response selected</h3>
                  <p className="text-text-light text-center max-w-md">
                    Select a form submission from the list to view detailed responses
                  </p>
                </CardContent>
              </Card>
            )}
          </div>
        </div>
      </div>
      
      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-background-primary rounded-lg p-6 max-w-md w-full mx-4">
            <h3 className="text-lg font-semibold mb-4">Delete Lead</h3>
            <p className="text-text-secondary mb-6">
              Are you sure you want to delete this lead's form response? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <Button
                variant="ghost"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="destructive"
                className="bg-red-500 text-white hover:bg-red-600"
                onClick={handleDeleteResponse}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormResponses; 