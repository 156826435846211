// components/InsightsGenerator.jsx

import React, { useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase'; // Ensure the correct path
import { Button } from './ui/Button';
import { Card, CardContent, CardHeader, CardTitle } from './ui/Card';
import { Loader2, X, Calendar, ChevronRight } from 'lucide-react';

const InsightsGenerator = ({ clientId, onClose, onInsightsGenerated }) => {
  const [timeFrame, setTimeFrame] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [insights, setInsights] = useState(null);
  const [error, setError] = useState(null);
  const containerHeightClass = timeFrame && (isGenerating || insights) ? 'h-3/4' : 'h-auto';


  const handleGenerateInsights = async (days) => {
    setTimeFrame(days);
    setIsGenerating(true);
    setError(null);

    try {
      const generateInsights = httpsCallable(functions, 'generateInsights');
      const result = await generateInsights({ clientId, timeFrameDays: days });

      if (result.data.success) {
        setInsights(result.data.data);
        if (onInsightsGenerated) {
            onInsightsGenerated(result.data.data);
          }
      } else {
        setError(result.data.message || 'Failed to generate insights.');
      }
    } catch (err) {
      console.error('Error calling generateInsights:', err);
      setError(err.message || 'Error generating insights.');
    } finally {
      setIsGenerating(false);
    }
  };

  const TimeFrameOption = ({ days, label, description }) => (
    <button
      onClick={() => handleGenerateInsights(days)}
      className="group relative w-full bg-background-secondary hover:bg-background-tertiary 
        border border-border-light hover:border-accent-primary rounded-lg p-6 
        transition-all duration-300 text-left"
    >
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-lg font-headers font-medium text-text-primary mb-2">
            {label}
          </h3>
          <p className="text-sm text-text-light group-hover:text-text-secondary transition-colors">
            {description}
          </p>
        </div>
        <ChevronRight className="w-5 h-5 text-accent-primary opacity-0 group-hover:opacity-100 
          transform group-hover:translate-x-1 transition-all" />
      </div>
    </button>
  );

  const renderTimeFrameSelection = () => (
    <div className="space-y-6">
      <p className="text-text-secondary font-body">
        Select a time frame to analyze the conversation history and generate AI-powered insights.
      </p>
      <div className="grid grid-cols-1 gap-4">
        <TimeFrameOption
          days={7}
          label="Last 7 Days"
          description="Recent interactions and short-term patterns"
        />
        <TimeFrameOption
          days={14}
          label="Last 14 Days"
          description="Medium-term progress and recurring themes"
        />
        <TimeFrameOption
          days={21}
          label="Last 21 Days"
          description="Comprehensive analysis of long-term patterns"
        />
      </div>
    </div>
  );

  const renderInsightSection = ({ title, items, icon, accentColor = 'text-accent-primary' }) => (
    <Card className="bg-background-secondary border border-border-light">
      <CardHeader className="flex flex-row items-center gap-4 pb-2">
        <div className={`${accentColor} p-2 rounded-full bg-background-tertiary`}>
          {icon}
        </div>
        <CardTitle className="text-lg font-headers font-medium">{title}</CardTitle>
      </CardHeader>
      <CardContent>{items}</CardContent>
    </Card>
  );

  const renderInsights = () => {
    if (!insights) return null;
    const { summary, wins, challenges, recommendations } = insights;

    return (
      <div className="space-y-6">
        {/* Summary Section */}
        {renderInsightSection({
          title: "Summary",
          items: <p className="text-text-secondary leading-relaxed">{summary}</p>,
          icon: <Calendar className="w-5 h-5" />
        })}

        {/* Wins Section */}
        {wins && Object.keys(wins).length > 0 && (
          <div className="space-y-4">
            <h2 className="text-xl font-headers font-medium text-accent-tertiary">Wins</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {Object.entries(wins).map(([category, items]) => (
                <Card key={category} className="bg-background-secondary hover:bg-background-tertiary 
                  transition-all duration-300 border border-border-light">
                  <CardContent className="p-4">
                    <h3 className="text-text-primary font-medium mb-3 capitalize">
                      {category.replace(/_/g, ' ')}
                    </h3>
                    <ul className="space-y-2">
                      {items.map((item, index) => (
                        <li key={index} className="text-text-secondary flex items-start gap-2">
                          <span className="text-accent-primary">•</span>
                          {item}
                        </li>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        )}

        {/* Challenges Section */}
        {challenges && Object.keys(challenges).length > 0 && (
          <div className="space-y-4">
            <h2 className="text-xl font-headers font-medium text-accent-tertiary">Challenges</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {Object.entries(challenges).map(([category, items]) => (
                <Card key={category} className="bg-background-secondary hover:bg-background-tertiary 
                  transition-all duration-300 border border-border-light">
                  <CardContent className="p-4">
                    <h3 className="text-text-primary font-medium mb-3 capitalize">
                      {category.replace(/_/g, ' ')}
                    </h3>
                    <ul className="space-y-2">
                      {items.map((item, index) => (
                        <li key={index} className="text-text-secondary flex items-start gap-2">
                          <span className="text-accent-primary">•</span>
                          {item}
                        </li>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        )}

        {/* Recommendations Section */}
        {recommendations && recommendations.length > 0 && (
          <div className="space-y-4">
            <h2 className="text-xl font-headers font-medium text-accent-tertiary">AI Recommendations</h2>
            <div className="bg-background-secondary border border-border-light rounded-lg p-4">
              <ul className="space-y-3">
                {recommendations.map((item, index) => (
                  <li key={index} className="text-text-secondary flex items-start gap-2">
                    <span className="text-accent-primary">{index + 1}.</span>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="fixed inset-0 bg-background-primary bg-opacity-80 backdrop-blur-sm 
      flex items-center justify-center z-50 p-4">
      <div className="bg-background-secondary rounded-lg shadow-lg w-full max-w-4xl 
        max-h-[90vh] overflow-hidden border border-border-medium">
        {/* Header */}
        <div className="px-6 py-4 border-b border-border-light flex justify-between items-center">
          <h1 className="text-2xl font-headers font-bold text-text-primary">
            Generate Insights
          </h1>
          <Button
            variant="ghost"
            size="icon"
            onClick={onClose}
            className="text-text-light hover:text-text-primary"
          >
            <X className="w-5 h-5" />
          </Button>
        </div>

        {/* Content */}
        <div className="p-6 overflow-y-auto max-h-[calc(90vh-80px)]">
          {!timeFrame && renderTimeFrameSelection()}
          
          {isGenerating && (
            <div className="flex flex-col items-center justify-center py-12 space-y-4">
              <Loader2 className="w-8 h-8 animate-spin text-accent-primary" />
              <p className="text-text-secondary">Analyzing conversation history...</p>
            </div>
          )}

          {error && (
            <div className="bg-red-50 border border-red-200 rounded-lg p-4 text-red-600">
              {error}
            </div>
          )}

          {!isGenerating && insights && renderInsights()}
        </div>
      </div>
    </div>
  );
};

export default InsightsGenerator;
