import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth, signOut, sendPasswordResetEmail } from 'firebase/auth';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { Input } from './ui/Input';
import { Button } from './ui/Button';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { API_URLS } from '../constants/api';
import { 
  UserCircle, 
  Phone, 
  CreditCard, 
  LogOut, 
  ArrowLeft, 
  Lock,
  Mail,
  CheckCircle,
  AlertCircle
} from 'lucide-react';
import { loadStripe } from '@stripe/stripe-js';

// Initialize Stripe
const stripePromise = loadStripe('pk_live_51PmzEsJp9datwQ8XX5QXruYq4HeCdzi5qLpPTOJ6DauNGUe4RicW0vtsCiyow8gqjubN2NtcB9w5jr7ycWtgMAaR00wFdVnC4K');

const AccountSettings = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [resetError, setResetError] = useState('');
  const auth = getAuth();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const loadAccountData = async () => {
      if (!user) return;
      
      try {
        const coachDoc = await getDoc(doc(db, 'coaches', user.uid));
        if (coachDoc.exists()) {
          const data = coachDoc.data();
          setFirstName(data.firstName || '');
          setLastName(data.lastName || '');
          setPhoneNumber(data.phoneNumber || '');
          setSubscriptionStatus(data.subscriptionStatus || 'inactive');
        }
      } catch (error) {
        console.error('Error loading account data:', error);
      }
    };

    if (user) {
      loadAccountData();
    }
  }, [user]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail(auth, user.email);
      setResetEmailSent(true);
      setResetError('');
      // Reset the success message after 5 seconds
      setTimeout(() => setResetEmailSent(false), 5000);
    } catch (error) {
      setResetError('Failed to send reset email. Please try again.');
      setTimeout(() => setResetError(''), 5000);
    }
  };

  const handleLogout = () => {
    signOut(auth);
  };

  const updateName = async () => {
    if (!firstName.trim() || !lastName.trim()) {
      alert('Please enter both first and last name');
      return;
    }
    
    try {
      await updateDoc(doc(db, 'coaches', user.uid), {
        firstName,
        lastName,
      });
      alert('Name updated successfully');
    } catch (error) {
      console.error('Error updating name:', error);
    }
  };

  const verifyPhoneNumber = async () => {
    if (!phoneNumber.trim()) {
      alert('Please enter a phone number');
      return;
    }
    
    try {
      await updateDoc(doc(db, 'coaches', user.uid), {
        phoneNumber,
      });
      alert('Phone number saved successfully');
    } catch (error) {
      console.error('Error saving phone number:', error);
    }
  };

  const handleSubscriptionManagement = async () => {
    setIsLoading(true);
    try {
      if (subscriptionStatus === 'inactive') {
        const response = await fetch(API_URLS.createCheckoutSession, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${await user.getIdToken()}`,
          },
          body: JSON.stringify({
            domain: window.location.origin,
          }),
        });

        if (!response.ok) throw new Error('Failed to create checkout session');
        const { sessionId } = await response.json();
        const stripe = await stripePromise;
        await stripe.redirectToCheckout({ sessionId });
      } else {
        const response = await fetch(API_URLS.createPortalSession, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${await user.getIdToken()}`,
          },
          body: JSON.stringify({
            domain: window.location.origin,
          }),
        });

        if (!response.ok) throw new Error('Failed to create portal session');
        const { url } = await response.json();
        window.location.href = url;
      }
    } catch (error) {
      console.error('Error managing subscription:', error);
      alert('Error managing subscription: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`min-h-screen bg-background-primary p-6 ${
      isMobile 
        ? 'pb-24' // Add padding at bottom for mobile nav
        : 'pt-24' // Add padding at top for desktop nav
    }`}>
      <div className="max-w-4xl mx-auto">
        {/* Header Section */}
        <div className="flex items-center space-x-6 mb-8">
          <Button
            onClick={() => navigate('/account')}
            variant="ghost"
            size="icon"
            className="bg-background-secondary hover:bg-background-tertiary text-text-primary rounded-full p-3 shadow-md"
          >
            <ArrowLeft className="h-5 w-5" />
          </Button>
          <div>
            <h1 className="font-headers text-h1 text-text-primary font-medium">Account Settings</h1>
            <div className="flex items-center mt-2 space-x-2">
              <Mail className="h-4 w-4 text-text-light" />
              <span className="text-text-light text-small font-body">{user?.email}</span>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Main Settings Column */}
          <div className="md:col-span-2 space-y-6">
            {/* Profile Card */}
            <Card className="bg-background-secondary border-border-light shadow-md hover:shadow-lg transition-shadow">
              <CardHeader className="pb-2">
                <div className="flex items-center space-x-3">
                  <UserCircle className="h-5 w-5 text-accent-primary" />
                  <CardTitle className="font-headers text-h3 text-text-primary">Profile Information</CardTitle>
                </div>
              </CardHeader>
              <CardContent className="space-y-6">
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <label className="text-small font-medium text-text-light">First Name</label>
                    <Input
                      placeholder="Enter your first name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className="bg-background-primary border-border-medium focus:border-accent-primary h-11 text-text-primary"
                    />
                  </div>
                  <div className="space-y-2">
                    <label className="text-small font-medium text-text-light">Last Name</label>
                    <Input
                      placeholder="Enter your last name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      className="bg-background-primary border-border-medium focus:border-accent-primary h-11 text-text-primary"
                    />
                  </div>
                </div>
                <Button
                  onClick={updateName}
                  variant="primary"
                  className="w-full"
                >
                  Update Profile
                </Button>
              </CardContent>
            </Card>

            {/* Phone Verification Card */}
            <Card className="bg-background-secondary border-border-light shadow-md hover:shadow-lg transition-shadow">
              <CardHeader className="pb-2">
                <div className="flex items-center space-x-3">
                  <Phone className="h-5 w-5 text-accent-primary" />
                  <CardTitle className="font-headers text-h3 text-text-primary">Phone Number</CardTitle>
                </div>
              </CardHeader>
              <CardContent className="space-y-4">
                <div className="space-y-2">
                  <label className="text-small font-medium text-text-light">Phone Number</label>
                  <Input
                    type="tel"
                    placeholder="Enter your phone number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="bg-background-primary border-border-medium focus:border-accent-primary h-11 text-text-primary"
                  />
                </div>
                <Button 
                  onClick={verifyPhoneNumber} 
                  variant="primary"
                  className="w-full"
                >
                  Save Phone Number
                </Button>
              </CardContent>
            </Card>
          </div>

          {/* Side Column */}
          <div className="space-y-6">
            {/* Subscription Card */}
            <Card className="bg-background-secondary border-border-light shadow-md hover:shadow-lg transition-shadow">
              <CardHeader className="pb-2">
                <div className="flex items-center space-x-3">
                  <CreditCard className="h-5 w-5 text-accent-primary" />
                  <CardTitle className="font-headers text-h3 text-text-primary">Subscription</CardTitle>
                </div>
              </CardHeader>
              <CardContent className="space-y-4">
                <div className="bg-background-tertiary p-4 rounded-lg">
                  <p className="text-text-primary font-medium">Current Plan</p>
                  <p className="text-text-light text-small mt-1 capitalize">{subscriptionStatus}</p>
                </div>
                <Button
                  onClick={handleSubscriptionManagement}
                  disabled={isLoading}
                  variant="secondary"
                  className="w-full"
                >
                  {isLoading ? 'Processing...' : 
                   subscriptionStatus === 'inactive' ? 'Subscribe Now' : 'Manage Plan'}
                </Button>
              </CardContent>
            </Card>

            {/* Security Card */}
            <Card className="bg-background-secondary border-border-light shadow-md hover:shadow-lg transition-shadow">
              <CardHeader className="pb-2">
                <div className="flex items-center space-x-3">
                  <Lock className="h-5 w-5 text-accent-primary" />
                  <CardTitle className="font-headers text-h3 text-text-primary">Security</CardTitle>
                </div>
              </CardHeader>
              <CardContent className="space-y-4">
                {resetEmailSent && (
                  <div className="flex items-center space-x-2 text-green-600 bg-green-50 p-3 rounded-lg">
                    <CheckCircle className="h-5 w-5" />
                    <span className="text-small">Password reset email sent!</span>
                  </div>
                )}
                {resetError && (
                  <div className="flex items-center space-x-2 text-red-600 bg-red-50 p-3 rounded-lg">
                    <AlertCircle className="h-5 w-5" />
                    <span className="text-small">{resetError}</span>
                  </div>
                )}
                <Button 
                  onClick={handlePasswordReset}
                  variant="secondary"
                  className="w-full"
                >
                  Reset Password
                </Button>
              </CardContent>
            </Card>

            {/* Logout Button */}
            <Button
              onClick={handleLogout}
              variant="ghost"
              className="w-full bg-background-tertiary hover:bg-hover-secondary text-text-primary"
            >
              <LogOut className="h-4 w-4 mr-2" />
              Logout
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;