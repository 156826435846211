import React, { useState, useEffect } from 'react';
import { Plus, X, AlertCircle } from 'lucide-react';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Button } from './ui/Button';
import { Input } from './ui/Input';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription
} from './ui/Card';
import { Alert, AlertDescription } from './ui/Alert';

const ClientDietTab = ({ clientId }) => {
  const [preferences, setPreferences] = useState([]);
  const [isAddingPreference, setIsAddingPreference] = useState(false);
  const [newPreference, setNewPreference] = useState('');
  const [error, setError] = useState('');
  const [inputError, setInputError] = useState('');

  useEffect(() => {
    fetchDietaryInfo();
  }, [clientId]);

  const fetchDietaryInfo = async () => {
    try {
      const docRef = doc(db, 'users', clientId, 'userKnowledge', 'preferences');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setPreferences(data.foodPreferences || []);
      }
    } catch (err) {
      setError('Error loading dietary information');
      console.error('Error fetching dietary info:', err);
    }
  };

  const validateForm = () => {
    setInputError('');
    if (!newPreference.trim()) {
      setInputError('Please enter a food preference');
      return false;
    }
    return true;
  };

  const handleAddPreference = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const docRef = doc(db, 'users', clientId, 'userKnowledge', 'preferences');
      const updatedPreferences = [...preferences, newPreference.trim()];

      await setDoc(docRef, {
        foodPreferences: updatedPreferences
      }, { merge: true });

      setPreferences(updatedPreferences);
      setNewPreference('');
      setIsAddingPreference(false);
      setError('');
    } catch (err) {
      setError('Error saving preference');
      console.error('Error adding preference:', err);
    }
  };

  const handleDeletePreference = async (index) => {
    try {
      const updatedPreferences = preferences.filter((_, i) => i !== index);
      const docRef = doc(db, 'users', clientId, 'userKnowledge', 'preferences');
      await updateDoc(docRef, {
        foodPreferences: updatedPreferences
      });
      setPreferences(updatedPreferences);
    } catch (err) {
      setError('Error deleting preference');
      console.error('Error deleting preference:', err);
    }
  };

  const resetForm = () => {
    setIsAddingPreference(false);
    setNewPreference('');
    setError('');
    setInputError('');
  };

  return (
    <div className="space-y-6">
      {error && (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <Card>
        <CardHeader>
          <CardTitle>Dietary Information</CardTitle>
          <CardDescription>
            Manage food preferences and dietary restrictions
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {/* Add New Preference Button */}
            {!isAddingPreference && (
              <Button
                onClick={() => setIsAddingPreference(true)}
                variant="secondary"
                className="w-full flex items-center justify-center gap-2"
              >
                <Plus className="h-4 w-4" />
                Add Food Preference
              </Button>
            )}

            {isAddingPreference && (
              <div className="space-y-4 p-6 border border-border-light rounded-lg bg-background-secondary shadow-md">
                <div className="space-y-3">
                  <div className="flex items-center justify-between">
                    <label className="font-headers text-text-secondary font-medium">
                      New Food Preference
                    </label>
                    <Button
                      variant="ghost"
                      size="icon"
                      className="h-8 w-8 text-text-light hover:text-accent-primary hover:bg-background-tertiary rounded-md"
                      onClick={resetForm}
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  </div>
                  
                  <Input
                    placeholder="(e.g., Vegetarian, No Dairy)"
                    value={newPreference}
                    onChange={(e) => setNewPreference(e.target.value)}
                    className={inputError ? "border-red-500 focus:ring-red-500" : ""}
                  />
                  
                  {inputError && (
                    <p className="text-red-500 text-sm font-body flex items-center gap-2">
                      <AlertCircle className="h-4 w-4" />
                      {inputError}
                    </p>
                  )}
                </div>

                <div className="flex justify-end space-x-3 pt-2">
                  <Button
                    variant="outline"
                    onClick={resetForm}
                  >
                    Cancel
                  </Button>
                  <Button 
                    variant="primary"
                    onClick={handleAddPreference}
                  >
                    Save Preference
                  </Button>
                </div>
              </div>
            )}

            {/* Existing Preferences List */}
            {preferences.length > 0 && (
              <div className="mt-6">
                <h3 className="font-headers text-text-secondary font-medium mb-3">
                  Current Preferences
                </h3>
                <div className="space-y-2">
                  {preferences.map((preference, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between p-3 
                               bg-background-secondary rounded-lg border border-border-light
                               hover:border-accent-secondary transition-all duration-300
                               group"
                    >
                      <div>
                        <p className="font-body text-text-primary">{preference}</p>
                      </div>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleDeletePreference(index)}
                        className="text-text-light hover:text-accent-primary 
                                 hover:bg-background-tertiary
                                 opacity-0 group-hover:opacity-100"
                      >
                        <X className="h-4 w-4" />
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ClientDietTab;