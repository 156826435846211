// src/components/ui/Modal.jsx
import React from 'react';
import { X } from 'lucide-react';
import { cn } from '../../lib/utils';

const Modal = ({ isOpen, onClose, children, className = '' }) => { // Added className prop
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className={`bg-neutral-900 p-6 rounded-lg relative ${className}`}> {/* Applied className here */}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-white"
        >
          <X className="h-6 w-6" />
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
