// src/components/ui/Input.jsx

import React from 'react';
import { cn } from '../../lib/utils';

const Input = React.forwardRef(({ className, ...props }, ref) => (
  <input
    ref={ref}
    className={cn(
      "w-full bg-transparent",
      "border-b-2 border-text-light/20",
      "px-3 py-2 text-base",
      "font-body text-text-primary",
      "placeholder:text-text-light/50",
      "focus:border-accent-primary focus:outline-none",
      "transition-all duration-300",
      "disabled:cursor-not-allowed disabled:opacity-50",
      className
    )}
    {...props}
  />
));

Input.displayName = "Input";

export { Input };
