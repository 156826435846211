// src/components/MessageSchedule.jsx

import React from 'react';
import { Input } from './ui/Input';
import { Label } from './ui/Label';
import  { Switch }  from './ui/Switch';
import { Clock } from 'lucide-react';

const MessageSchedule = ({ scheduledDate, messageSendTime, onMessageSendTimeChange }) => {
    const [useCustomTime, setUseCustomTime] = React.useState(false);
    
    // Calculate default send time (1 day before if >2 days away, at 2 PM CT)
    const defaultSendTime = React.useMemo(() => {
      const now = new Date();
      const scheduledDateTime = new Date(scheduledDate);
      const twoDaysFromNow = new Date();
      twoDaysFromNow.setDate(now.getDate() + 2);
      
      if (scheduledDateTime < twoDaysFromNow) {
        // If less than 2 days away, send immediately
        return now;
      } else {
        
        const sendDate = new Date(scheduledDateTime);
        sendDate.setDate(sendDate.getDate() - 1);
        
        
        const jan = new Date(sendDate.getFullYear(), 0, 1).getTimezoneOffset();
        const jul = new Date(sendDate.getFullYear(), 6, 1).getTimezoneOffset();
        const isCDT = sendDate.getTimezoneOffset() < Math.max(jan, jul);
        
        sendDate.setUTCHours(isCDT ? 19 : 20); // 2 PM CT in UTC
        sendDate.setMinutes(0);
        sendDate.setSeconds(0);
        sendDate.setMilliseconds(0);
        
        return sendDate;
      }
    }, [scheduledDate]);
  
    // Set initial message send time only once when component mounts or scheduledDate changes
    React.useEffect(() => {
      if (!messageSendTime && typeof onMessageSendTimeChange === 'function') {
        onMessageSendTimeChange(defaultSendTime);
      }
    }, [defaultSendTime, scheduledDate]);
  
    // Helper function to check if date is in DST
    const isDaylightSavingTime = (date) => {
      const jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
      const jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
      const stdTimezoneOffset = Math.max(jan, jul);
      return date.getTimezoneOffset() < stdTimezoneOffset;
    };
  
    // Format date for datetime-local input
    const formatDateForInput = (date) => {
      return date.toISOString().slice(0, 16);
    };
  
    const handleCustomTimeToggle = (checked) => {
      setUseCustomTime(checked);
      if (!checked) {
        onMessageSendTimeChange(defaultSendTime);
      }
    };
  
    const handleTimeChange = (e) => {
      const newDate = new Date(e.target.value);
      onMessageSendTimeChange(newDate);
    };
  
    // Format display date in Central Time
    const formatDisplayDate = (date) => {
      return new Date(date).toLocaleString('en-US', {
        timeZone: 'America/Chicago',
        dateStyle: 'full',
        timeStyle: 'short'
      });
    };
  
    return (
      <div className="space-y-4">
        <div className="flex items-center justify-between bg-background-secondary p-4 rounded-lg border border-border-light/10">
          <div className="space-y-1">
            <div className="flex items-center gap-2">
              <Clock className="h-5 w-5 text-accent-primary" />
              <Label className="font-medium text-text-primary">
                Message Timing
              </Label>
            </div>
            <p className="text-sm text-text-light">
              {scheduledDate < new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000)
                ? "Workout will be sent immediately"
                : "Default: One day before at 2 PM Central"}
            </p>
          </div>
          <Switch
            checked={useCustomTime}
            onCheckedChange={handleCustomTimeToggle}
            className="data-[state=checked]:bg-accent-primary"
          />
        </div>
  
        {useCustomTime && (
          <div className="bg-background-secondary p-4 rounded-lg border border-border-light/10 space-y-3">
            <Label htmlFor="sendTime" className="text-sm font-medium text-text-primary">
              Custom Send Time
            </Label>
            <Input
              id="sendTime"
              type="datetime-local"
              value={formatDateForInput(messageSendTime || defaultSendTime)}
              onChange={handleTimeChange}
              className="bg-background-tertiary border-border-light focus:border-accent-primary"
            />
            <p className="text-xs text-text-light italic">
              Selected time shown in your local timezone
            </p>
          </div>
        )}
  
        {!useCustomTime && (
          <div className="bg-accent-primary/5 p-3 rounded-lg border border-accent-primary/10">
            <p className="text-sm text-accent-primary font-medium">
              Scheduled: {formatDisplayDate(defaultSendTime)}
            </p>
          </div>
        )}
      </div>
    );
  };
  
export default MessageSchedule;