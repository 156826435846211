// src/components/ui/Checkbox.jsx

import React from 'react';
import { Check } from 'lucide-react';
import { cn } from '../../lib/utils';

const Checkbox = React.forwardRef(({ className, checked, onChange, disabled, ...props }, ref) => (
  <div
    ref={ref}
    role="checkbox"
    aria-checked={checked}
    data-state={checked ? "checked" : "unchecked"}
    className={cn(
      "peer h-5 w-5 shrink-0 rounded-md border-2 cursor-pointer",
      "border-text-light/30 bg-transparent",
      "hover:border-accent-primary",
      "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-accent-primary",
      "disabled:cursor-not-allowed disabled:opacity-50",
      checked && "bg-accent-primary border-accent-primary",
      className
    )}
    onClick={() => !disabled && onChange?.(!checked)}
    {...props}
  >
    {checked && (
      <div className="flex items-center justify-center text-white">
        <Check className="h-3.5 w-3.5" />
      </div>
    )}
  </div>
));

Checkbox.displayName = "Checkbox";

export { Checkbox };