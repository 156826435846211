// src/components/AccountPageTabs/NutritionPhilosophyEditor.jsx

import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { Card, CardHeader, CardContent } from '../ui/Card';
import { Button } from '../ui/Button';
import { Switch } from '../ui/Switch';
import { Badge } from '../ui/Badge';
import { Checkbox } from '../ui/Checkbox';
import { ChevronDown, ChevronUp, Utensils, Settings2, AlertTriangle } from 'lucide-react';
import { cn } from '../../lib/utils';

const DIET_PRESETS = {
  carnivore: {
    label: 'Carnivore',
    sources: [
      'beef', 'bison', 'lamb', 'chicken', 'turkey',
      'fish', 'salmon', 'sardines', 'shellfish', 'eggs'
    ]
  },
  keto: {
    label: 'Keto',
    sources: [
      'beef', 'bison', 'lamb', 'chicken', 'fish', 'salmon', 'sardines', 'eggs',
      'avocado', 'cold-pressed-olive-oil', 'coconut-oil', 'butter', 'ghee', 'nuts', 'seeds'
    ]
  },
  mediterranean: {
    label: 'Mediterranean',
    sources: [
      'fish', 'salmon', 'sardines', 'shellfish', 'chicken', 'turkey', 'cold-pressed-olive-oil',
      'whole-grains', 'lentils', 'chickpeas', 'black-beans', 'vegetables', 'fruits', 'nuts', 'seeds', 'greek-yogurt'
    ]
  },
  paleo: {
    label: 'Paleo',
    sources: [
      'beef', 'bison', 'lamb', 'chicken', 'turkey', 'fish', 'salmon', 'sardines', 'shellfish', 'eggs',
      'vegetables', 'fruits', 'nuts', 'seeds', 'sweet-potato', 'avocado', 'coconut-oil', 'cold-pressed-olive-oil'
    ]
  },
  vegan: {
    label: 'Vegan',
    sources: [
      'tempeh', 'tofu', 'lentils', 'chickpeas', 'black-beans', 'whole-grains',
      'fruits', 'vegetables', 'nuts', 'seeds', 'nut-butters', 'avocado', 'cold-pressed-olive-oil', 'coconut-oil',
      'oats', 'brown-rice', 'quinoa'
    ]
  },
  wfpb: {
    label: 'Whole Food Plant Based',
    sources: [
      'lentils', 'chickpeas', 'black-beans', 'whole-grains', 'fruits', 'vegetables',
      'nuts', 'seeds', 'quinoa', 'oats', 'brown-rice'
    ]
  }
};

const GLOBAL_QUALITIES = [
  {
    id: 'organic',
    label: 'All Organic',
    applicableTo: [
      'chicken', 'turkey', 'beef', 'lamb', 'bison', 'fruits', 'vegetables', 'lentils', 'chickpeas', 'black-beans',
      'sweet-potato', 'white-rice', 'brown-rice', 'whole-grains', 'oats', 'quinoa', 'tempeh', 'tofu', 'nuts', 'seeds'
    ]
  },
  {
    id: 'grassFed',
    label: 'All Grass-Fed',
    applicableTo: ['beef', 'bison', 'lamb', 'greek-yogurt']
  },
  {
    id: 'wildCaught',
    label: 'All Wild-Caught',
    applicableTo: ['fish', 'salmon', 'sardines', 'shellfish']
  },
  {
    id: 'pastureRaised',
    label: 'All Pasture-Raised',
    applicableTo: ['eggs', 'chicken', 'turkey']
  }
];

const FOOD_QUALITY_MAPPING = {
  organic: {
    label: 'Organic',
    applicableTo: [
      'chicken', 'turkey', 'beef', 'lamb', 'bison', 'fruits', 'vegetables', 'lentils', 'chickpeas', 'black-beans',
      'sweet-potato', 'white-rice', 'brown-rice', 'whole-grains', 'oats', 'quinoa', 'tempeh', 'tofu', 'nuts', 'seeds'
    ]
  },
  grassFed: {
    label: 'Grass-Fed',
    applicableTo: ['beef', 'bison', 'lamb', 'greek-yogurt']
  },
  wildCaught: {
    label: 'Wild-Caught',
    applicableTo: ['fish', 'salmon', 'sardines', 'shellfish']
  },
  pastureRaised: {
    label: 'Pasture-Raised',
    applicableTo: ['eggs', 'chicken', 'turkey']
  }
};

const ITEMS_TO_AVOID = {
  label: 'Items to Avoid',
  categories: [
    {
      id: 'seed-oils',
      label: 'Seed Oils',
      examples: 'Canola, Soybean, Corn, Sunflower oils'
    },
    {
      id: 'added-sugar',
      label: 'Added Sugar',
      examples: 'High fructose corn syrup, table sugar, artificial sweeteners'
    },
    {
      id: 'ultra-processed',
      label: 'Ultra Processed Foods',
      examples: 'Packaged snacks, processed meats, artificial ingredients'
    },
    {
      id: 'refined-carbs',
      label: 'Refined Carbohydrates',
      examples: 'White flour, processed grains, instant foods'
    },
    {
      id: 'alcohol',
      label: 'Alcohol',
      examples: 'Beer, wine, spirits'
    },
    {
      id: 'marijuana',
      label: 'Marijuana',
      examples: 'All forms of cannabis'
    },
    {
      id: 'caffeine',
      label: 'Caffeine',
      examples: 'Coffee, energy drinks, tea, supplements'
    },
    {
      id: 'nicotine',
      label: 'Nicotine',
      examples: 'Cigarettes, vaping, nicotine products'
    }
  ]
};

const INITIAL_FOODS = {
  proteins: {
    label: 'Protein Sources',
    items: [
      { id: 'beef', label: 'Beef' },
      { id: 'bison', label: 'Bison' },
      { id: 'lamb', label: 'Lamb' },
      { id: 'chicken', label: 'Chicken' },
      { id: 'turkey', label: 'Turkey' },
      { id: 'fish', label: 'Fish (Cod, etc.)' },
      { id: 'salmon', label: 'Salmon' },
      { id: 'sardines', label: 'Sardines' },
      { id: 'shellfish', label: 'Shellfish' },
      { id: 'eggs', label: 'Eggs' },
      { id: 'greek-yogurt', label: 'Greek Yogurt' },
      { id: 'cottage-cheese', label: 'Cottage Cheese' },
      { id: 'tempeh', label: 'Tempeh' },
      { id: 'tofu', label: 'Tofu' },
      { id: 'lentils', label: 'Lentils' },
      { id: 'chickpeas', label: 'Chickpeas' },
      { id: 'black-beans', label: 'Black Beans' }
    ]
  },
  carbs: {
    label: 'Carb Sources',
    items: [
      { id: 'sweet-potato', label: 'Sweet Potato' },
      { id: 'white-rice', label: 'White Rice' },
      { id: 'brown-rice', label: 'Brown Rice' },
      { id: 'quinoa', label: 'Quinoa' },
      { id: 'oats', label: 'Oats' },
      { id: 'whole-grains', label: 'Whole Grains' },
      { id: 'fruits', label: 'Fruits' },
      { id: 'vegetables', label: 'Vegetables' },
      { id: 'lentils', label: 'Lentils' },
      { id: 'chickpeas', label: 'Chickpeas' },
      { id: 'black-beans', label: 'Black Beans' }
    ]
  },
  fats: {
    label: 'Fat Sources',
    items: [
      { id: 'cold-pressed-olive-oil', label: 'Cold-Pressed Olive Oil' },
      { id: 'coconut-oil', label: 'Coconut Oil' },
      { id: 'avocado', label: 'Avocado' },
      { id: 'butter', label: 'Butter' },
      { id: 'ghee', label: 'Ghee' },
      { id: 'nuts', label: 'Nuts' },
      { id: 'seeds', label: 'Seeds' },
      { id: 'nut-butters', label: 'Nut Butters' }
    ]
  }
};

const getFoodNameWithQualities = (foodId, qualities) => {
  if (!qualities || qualities.length === 0) return foodId;
  
  // Get the base food name (capitalize first letter of each word)
  const baseName = foodId
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  
  // Sort qualities in a consistent order: organic, grass-fed, wild-caught, pasture-raised
  const orderedQualities = qualities.sort((a, b) => {
    const order = {
      'organic': 1,
      'grassFed': 2,
      'wildCaught': 3,
      'pastureRaised': 4
    };
    return order[a] - order[b];
  });

  // Convert quality IDs to readable format
  const readableQualities = orderedQualities.map(quality => {
    switch (quality) {
      case 'organic': return 'Organic';
      case 'grassFed': return 'Grass-Fed';
      case 'wildCaught': return 'Wild-Caught';
      case 'pastureRaised': return 'Pasture-Raised';
      default: return quality;
    }
  });

  return `${readableQualities.join(', ')} ${baseName}`;
};

const GlobalQualityToggles = ({ onToggle, activeQualities }) => (
  <div className="space-y-3">
    <div className="flex items-center space-x-2">
      <Settings2 className="w-4 h-4 text-accent-secondary" />
      <h3 className="text-sm font-medium text-text-primary">Global Quality Settings</h3>
    </div>
    <div className="flex flex-wrap gap-2">
      {GLOBAL_QUALITIES.map(({ id, label }) => (
        <Badge
          key={id}
          variant={activeQualities.includes(id) ? "default" : "outline"}
          className={`cursor-pointer transition-all duration-200 ${
            activeQualities.includes(id) 
              ? 'text-white bg-accent-primary hover:bg-hover-primary' 
              : 'bg-background-tertiary hover:bg-hover-secondary text-text-primary'
          }`}
          onClick={() => onToggle(id)}
        >
          {label}
        </Badge>
      ))}
    </div>
  </div>
);

const FoodQualitySelector = ({ foodId, selectedQualities, onToggle }) => {
  const applicableQualities = Object.entries(FOOD_QUALITY_MAPPING)
    .filter(([quality, { applicableTo }]) => applicableTo.includes(foodId))
    .map(([quality, { label }]) => ({
      value: quality,
      label
    }));

  if (applicableQualities.length === 0) return null;

  return (
    <div className="flex flex-wrap gap-2 mt-2">
      {applicableQualities.map(({ value, label }) => (
        <div key={value} className="flex items-center space-x-2">
          <Checkbox
            id={`${foodId}-${value}`}
            checked={selectedQualities[foodId]?.includes(value)}
            onChange={(checked) => onToggle(foodId, value)}
            className="text-blue-500"
          />
          <label 
            htmlFor={`${foodId}-${value}`}
            className="text-sm text-neutral-400 hover:text-neutral-300 cursor-pointer"
          >
            {label}
          </label>
        </div>
      ))}
    </div>
  );
};

const FoodCategorySection = ({
  category,
  foods,
  selectedFoods,
  selectedQualities,
  onFoodToggle,
  onQualityToggle,
  expanded,
  onToggleExpand
}) => (
  <div className="space-y-2">
    <button
      onClick={onToggleExpand}
      className="w-full flex items-center justify-between p-2 hover:bg-background-tertiary rounded-md transition-colors duration-200"
    >
      <div className="flex items-center space-x-3">
        <span className="font-headers text-text-primary capitalize">
          {category}
        </span>
        <Badge variant="subtle" className="capitalize">
          {selectedFoods[category]?.length || 0} selected
        </Badge>
      </div>
      {expanded ? (
        <ChevronUp className="w-5 h-5 text-text-light" />
      ) : (
        <ChevronDown className="w-5 h-5 text-text-light" />
      )}
    </button>

    {expanded && (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
        {foods.items.map((food) => (
          <div
            key={food.id}
            className="flex items-start space-x-3 p-3 bg-background-secondary rounded-lg border border-border-light"
          >
            <Checkbox
              checked={selectedFoods[category]?.includes(food.id)}
              onChange={() => onFoodToggle(category, food.id)}
              className="mt-1"
            />
            <div className="flex-1 space-y-2">
              <span className="font-body text-text-primary">
                {food.label}
              </span>
              {/* Food qualities section */}
              {selectedFoods[category]?.includes(food.id) && (
                <div className="flex flex-wrap gap-2 mt-2">
                  {food.qualities?.map(quality => (
                    <Badge
                      key={quality}
                      variant={selectedQualities[food.id]?.includes(quality) ? "default" : "outline"}
                      className={`cursor-pointer text-xs ${
                        selectedQualities[food.id]?.includes(quality) ? 'text-white bg-accent-primary' : ''
                      }`}
                      onClick={() => onQualityToggle(food.id, quality)}
                    >
                      {quality}
                    </Badge>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    )}
  </div>
);

const RestrictedItemsSection = ({ selectedItems, onToggle }) => (
  <div className="border-b border-border-light last:border-b-0">
    <div className="p-4">
      <div className="flex items-center space-x-2 mb-4">
        <AlertTriangle className="w-5 h-5 text-accent-primary" />
        <h3 className="text-lg font-semibold text-text-primary font-headers">
          {ITEMS_TO_AVOID.label}
        </h3>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {ITEMS_TO_AVOID.categories.map(item => (
          <div 
            key={item.id} 
            className="p-3 border border-border-light rounded-lg bg-background-secondary 
                     transition-all duration-200 hover:border-accent-primary"
          >
            <div className="flex items-center justify-between mb-2">
              <span className="font-medium text-text-primary font-body">
                {item.label}
              </span>
              <Switch
                checked={selectedItems.includes(item.id)}
                onCheckedChange={() => onToggle(item.id)}
                className="data-[state=checked]:bg-accent-primary"
              />
            </div>
            <p className="text-sm text-text-light font-body">
              Examples: {item.examples}
            </p>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const NutritionPhilosophyEditor = ({ userId }) => {
  const [selectedDiet, setSelectedDiet] = useState(null);
  const [selectedFoods, setSelectedFoods] = useState(
    Object.entries(INITIAL_FOODS).reduce((acc, [category, { items }]) => {
      acc[category] = items.map(item => item.id);
      return acc;
    }, {})
  );
  const [selectedQualities, setSelectedQualities] = useState({});
  const [globalQualities, setGlobalQualities] = useState([]);
  const [restrictedItems, setRestrictedItems] = useState([]);
  const [expandedCategory, setExpandedCategory] = useState('proteins');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      if (!userId) return;
      
      setIsLoading(true);
      try {
        const docRef = doc(db, 'coaches', userId, 'philosophy', 'nutrition');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setSelectedDiet(data.selectedDiet || null);
          
          // If we have saved foods in Firestore, use those
          if (data.selectedFoods) {
            const normalizedFoods = {};
            Object.entries(data.selectedFoods).forEach(([category, foods]) => {
              normalizedFoods[category] = foods.map(food => {
                // Extract the base food ID from the quality-enhanced name
                return food.split(' ').pop().toLowerCase().replace(/\s+/g, '-');
              });
            });
            setSelectedFoods(normalizedFoods);
          } else {
            // Only set defaults if no data exists
            setSelectedFoods(
              Object.entries(INITIAL_FOODS).reduce((acc, [category, { items }]) => {
                acc[category] = items.map(item => item.id);
                return acc;
              }, {})
            );
          }
          
          setSelectedQualities(data.selectedQualities || {});
          setGlobalQualities(data.globalQualities || []);
          setRestrictedItems(data.restrictedItems || []);
        } else {
          // If no document exists, set defaults
          setSelectedFoods(
            Object.entries(INITIAL_FOODS).reduce((acc, [category, { items }]) => {
              acc[category] = items.map(item => item.id);
              return acc;
            }, {})
          );
        }
      } catch (error) {
        console.error('Error loading nutrition data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [userId]);

  useEffect(() => {
    const saveData = async () => {
      if (!userId || isLoading || !selectedFoods) return;

      const docRef = doc(db, 'coaches', userId, 'philosophy', 'nutrition');

      const transformedFoods = Object.entries(selectedFoods).reduce((acc, [category, foodIds]) => {
        acc[category] = foodIds.map(foodId => {
          const foodQualities = selectedQualities[foodId] || [];
          return getFoodNameWithQualities(foodId, foodQualities);
        });
        return acc;
      }, {});
  
      const payload = {
        selectedDiet,
        globalQualities,
        selectedFoods: transformedFoods,
        selectedQualities,
        restrictedItems,
      };

      try {
        await setDoc(docRef, payload, { merge: true });
      } catch (error) {
        console.error('Error saving nutrition data:', error);
      }
    };

    saveData();
  }, [userId, selectedDiet, globalQualities, selectedFoods, selectedQualities, restrictedItems, isLoading]);

  const handleDietSelect = (dietKey) => {
    setSelectedDiet(dietKey);
    const dietSources = DIET_PRESETS[dietKey].sources;

    setSelectedFoods(
      Object.entries(INITIAL_FOODS).reduce((acc, [category, { items }]) => {
        acc[category] = items
          .filter(item => dietSources.includes(item.id))
          .map(item => item.id);
        return acc;
      }, {})
    );

    setSelectedQualities({});
    setGlobalQualities([]);
  };

  const handleGlobalQualityToggle = (qualityId) => {
    setGlobalQualities(prev => {
      const isActive = prev.includes(qualityId);
      const newGlobalQualities = isActive
        ? prev.filter(q => q !== qualityId)
        : [...prev, qualityId];

      const quality = GLOBAL_QUALITIES.find(q => q.id === qualityId);
      const updatedQualities = { ...selectedQualities };

      quality.applicableTo.forEach(foodId => {
        if (isActive) {
          if (updatedQualities[foodId]) {
            updatedQualities[foodId] = updatedQualities[foodId].filter(q => q !== qualityId);
            if (updatedQualities[foodId].length === 0) {
              delete updatedQualities[foodId];
            }
          }
        } else {
          if (!updatedQualities[foodId]) {
            updatedQualities[foodId] = [];
          }
          if (!updatedQualities[foodId].includes(qualityId)) {
            updatedQualities[foodId] = [...updatedQualities[foodId], qualityId];
          }
        }
      });

      setSelectedQualities(updatedQualities);
      return newGlobalQualities;
    });
  };

  const handleFoodToggle = (category, foodId) => {
    setSelectedFoods(prev => ({
      ...prev,
      [category]: prev[category].includes(foodId)
        ? prev[category].filter(id => id !== foodId)
        : [...prev[category], foodId]
    }));

    // If removing a food, remove its qualities as well
    if (selectedFoods[category].includes(foodId)) {
      setSelectedQualities(prev => {
        const updated = { ...prev };
        delete updated[foodId];
        return updated;
      });
    }
  };

  const handleIndividualQualityToggle = (foodId, quality) => {
    setSelectedQualities(prev => {
      const currentQualities = prev[foodId] || [];
      const updated = {
        ...prev,
        [foodId]: currentQualities.includes(quality)
          ? currentQualities.filter(q => q !== quality)
          : [...currentQualities, quality]
      };

      if (updated[foodId].length === 0) {
        delete updated[foodId];
      }

      const globalQuality = GLOBAL_QUALITIES.find(q => q.id === quality);
      if (globalQuality) {
        const allApplicableFoodsHaveQuality = globalQuality.applicableTo.every(
          applicableFood => updated[applicableFood]?.includes(quality)
        );

        setGlobalQualities(prev => 
          allApplicableFoodsHaveQuality 
            ? [...new Set([...prev, quality])]
            : prev.filter(q => q !== quality)
        );
      }

      return updated;
    });
  };

  const handleRestrictedItemToggle = (itemId) => {
    setRestrictedItems(prev => 
      prev.includes(itemId)
        ? prev.filter(id => id !== itemId)
        : [...prev, itemId]
    );
  };

  if (isLoading || !selectedFoods) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-pulse text-text-light font-body">Loading...</div>
      </div>
    );
  }

  return (
    <div className="max-w-5xl mx-auto p-4 space-y-6">
      <Card className="bg-background-secondary border-border-light">
        <CardHeader>
          <div className="flex flex-col space-y-6">
            <div className="flex items-center justify-between">
              <h2 className="font-headers text-h2 text-text-primary tracking-tight">
                Nutrition Philosophy
              </h2>
              <div className="flex items-center space-x-2">
                <Settings2 className="w-5 h-5 text-accent-secondary" />
              </div>
            </div>
            
            <div className="space-y-4">
              <div className="flex items-center space-x-2">
                <Utensils className="w-4 h-4 text-accent-secondary" />
                <h3 className="text-sm font-medium font-body text-text-secondary">
                  Diet Template
                </h3>
              </div>
              <div className="flex flex-wrap gap-2">
                {Object.entries(DIET_PRESETS).map(([key, { label }]) => (
                  <Badge
                    key={key}
                    variant={selectedDiet === key ? "default" : "outline"}
                    className={cn(
                      "cursor-pointer transition-all duration-200",
                      selectedDiet === key 
                        ? 'text-white bg-accent-primary hover:bg-hover-primary' 
                        : 'hover:bg-background-tertiary'
                    )}
                    onClick={() => handleDietSelect(key)}
                  >
                    {label}
                  </Badge>
                ))}
              </div>
            </div>

            <div className="pt-4 border-t border-border-light">
              <GlobalQualityToggles 
                onToggle={handleGlobalQualityToggle}
                activeQualities={globalQualities}
              />
            </div>
          </div>
        </CardHeader>

        <CardContent>
          <div className="space-y-4">
            {Object.entries(INITIAL_FOODS).map(([category, foods]) => (
              <div
                key={category}
                className="bg-background-primary rounded-lg p-4 border border-border-light"
              >
                <FoodCategorySection
                  category={category}
                  foods={foods}
                  selectedFoods={selectedFoods}
                  selectedQualities={selectedQualities}
                  onFoodToggle={handleFoodToggle}
                  onQualityToggle={handleIndividualQualityToggle}
                  expanded={expandedCategory === category}
                  onToggleExpand={() => setExpandedCategory(
                    expandedCategory === category ? null : category
                  )}
                />
              </div>
            ))}

            <div className="mt-8 pt-6 border-t border-border-light">
              <RestrictedItemsSection
                selectedItems={restrictedItems}
                onToggle={handleRestrictedItemToggle}
              />
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Warning Section */}
      <div className="flex items-start space-x-3 p-4 bg-background-tertiary rounded-lg border border-border-light">
        <AlertTriangle className="w-5 h-5 text-accent-primary flex-shrink-0 mt-0.5" />
        <p className="text-sm text-text-secondary font-body">
          Changes are automatically saved. Your nutrition philosophy helps us understand your dietary preferences and restrictions to provide better recommendations.
        </p>
      </div>
    </div>
  );
};

export default NutritionPhilosophyEditor;
