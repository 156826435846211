import React, { useState, useEffect, useRef } from 'react';
import { Input } from './ui/Input';
import { Search, Plus, Edit } from 'lucide-react';
import { Button } from './ui/Button';
import { Badge } from './ui/Badge';
import { useAuth } from '../contexts/AuthContext';
import { getAllExercises } from '../services/exerciseService';

const ExerciseSearch = ({ value, onChange, className, onEditExercise, allExercises, ...props}) => {
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [results, setResults] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Local search function that doesn't need to query Firestore
  const searchExercises = (term) => {
    if (!term) {
      setResults([]);
      return;
    }
    const searchTermLower = term.toLowerCase();
    const exercisesList = allExercises || [];
    
    // Find the currently selected exercise in allExercises
    const currentExercise = exercisesList.find(ex => ex.id === value);
    
    const filteredResults = exercisesList.filter(exercise => {
      const nameMatch = exercise.name?.toLowerCase().includes(searchTermLower);
      const searchTermsMatch = exercise.searchTerms?.some(term => 
        term.toLowerCase().includes(searchTermLower)
      );
      return nameMatch || searchTermsMatch;
    });

    // If we have a selected exercise, ensure it appears in results
    if (currentExercise && !filteredResults.some(ex => ex.id === currentExercise.id)) {
      filteredResults.unshift(currentExercise);
    }

    const sortedResults = filteredResults.sort((a, b) => {
      // Keep selected exercise at top if it exists
      if (a.id === value) return -1;
      if (b.id === value) return 1;
      
      const aExact = a.name?.toLowerCase().startsWith(searchTermLower);
      const bExact = b.name?.toLowerCase().startsWith(searchTermLower);
      if (aExact && !bExact) return -1;
      if (!aExact && bExact) return 1;
      if (a.priorityLevel === 'high' && b.priorityLevel !== 'high') return -1;
      if (a.priorityLevel !== 'high' && b.priorityLevel === 'high') return 1;
      return (a.name || '').localeCompare(b.name || '');
    });

    setResults(sortedResults);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setIsOpen(true);
    if (!value) {
      onChange('');
      setSelectedExercise(null);
      setResults([]);
      setIsOpen(false);
    } else {
      searchExercises(value);
    }
  };
  
  const handleSelectExercise = (exercise) => {
    setSearchTerm(exercise.name);
    setSelectedExercise(exercise);
    onChange(exercise.id);
    setIsOpen(false);
  };

  // Add debounced search
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchTerm) {
        searchExercises(searchTerm);
      }
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  // Handle clicking outside of dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <div className="relative flex gap-2">
        <Input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          onFocus={() => searchTerm && setIsOpen(true)}
          placeholder="Search exercises..."
          className={`bg-neutral-800 border-neutral-700 flex-1 pl-10 ${className}`}
        />
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
        {selectedExercise && (
          <Button
            type="button"
            onClick={() => onEditExercise(selectedExercise)}
            className="bg-neutral-700 hover:bg-neutral-600"
          >
            <Edit className="h-4 w-4 mr-2" />
            Edit
          </Button>
        )}
      </div>

      {isOpen && (
        <div className="absolute z-50 w-full mt-1 bg-neutral-800 border border-neutral-700 rounded-md shadow-lg">
          <div className="max-h-60 overflow-auto">
            {results.map(exercise => (
              <div
                key={exercise.id}
                className="px-4 py-2 hover:bg-neutral-700 cursor-pointer"
                onClick={() => handleSelectExercise(exercise)}
              >
                <div className="flex items-center justify-between">
                  <span className="font-medium text-white">{exercise.name}</span>
                  <div className="flex gap-1">
                    {exercise.isCustom && (
                      <Badge className="bg-blue-500">Custom</Badge>
                    )}
                    {exercise.isModified && (
                      <Badge className="bg-purple-500">Modified</Badge>
                    )}
                  </div>
                </div>
                <div className="text-sm text-gray-400 flex items-center gap-2">
                  <span>{exercise.muscleGroups?.primary?.join(', ') || ''}</span>
                  {exercise.equipmentNeeded?.length > 0 && (
                    <span>• {exercise.equipmentNeeded.join(', ')}</span>
                  )}
                </div>
              </div>
            ))}
          </div>
          {isOpen && searchTerm && (
            <div className="p-2 border-t border-neutral-700">
              <Button
                type="button"
                onClick={() => onEditExercise(null)}
                className="w-full bg-neutral-700 hover:bg-neutral-600"
              >
                <Plus className="h-4 w-4 mr-2" />
                Create Custom Exercise
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};


export default ExerciseSearch;
