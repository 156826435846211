// src/services/exerciseService.js
import { db } from '../firebase';
import { doc, collection, addDoc, getDoc, getDocs, query, setDoc } from 'firebase/firestore';

export const createCustomExercise = async (coachId, exerciseData) => {
  // Create a new exercise in the coach's custom exercises collection
  const customExerciseRef = collection(db, `coaches/${coachId}/customExercises`);
  
  const exercise = {
    ...exerciseData,
    coachId,
    createdAt: new Date(),
    name_lower: exerciseData.name.toLowerCase(),
    type: ['custom'],
    skillLevel: exerciseData.skillLevel || 'beginner',
    difficulty: exerciseData.difficulty || 'beginner',
    searchTerms: [exerciseData.name.toLowerCase()],
    muscleGroups_flat: [
      ...(exerciseData.muscleGroups?.primary || []),
      ...(exerciseData.muscleGroups?.secondary || [])
    ],
    // Add all the standard exercise fields with defaults
    averageTimePerRep: 1,
    category: exerciseData.category || 'functional',
    contraindications: exerciseData.contraindications || [],
    defaultVideoUrl: exerciseData.mediaUrl || '',
    energySystemEmphasis: exerciseData.energySystemEmphasis || 'aerobic',
    equipmentNeeded: exerciseData.equipmentNeeded || [],
    fatigueImpact: exerciseData.fatigueImpact || 'low',
    formCues: {
      commonMistakes: exerciseData.commonMistakes || [],
      instructions: exerciseData.instructions || []
    },
    jointActions: exerciseData.jointActions || [],
    jointStress: exerciseData.jointStress || 'low',
    movementPattern: exerciseData.movementPattern || '',
    progressiveOverloadSuitability: exerciseData.progressiveOverloadSuitability || 'non-linear',
    rangeOfMotion: exerciseData.rangeOfMotion || 'full',
    repRange: {
      endurance: { max: 20, min: 10 },
      hypertrophy: { max: 0, min: 0 },
      strength: { max: 0, min: 0 }
    },
    restPeriod: { maxSeconds: 30, minSeconds: 0 },
    spotterRequirement: false,
    stabilizationDemand: exerciseData.stabilizationDemand || 'moderate',
    tags: [exerciseData.name.toLowerCase(), ...(exerciseData.tags || [])],
    trainingPhaseSuitability: exerciseData.trainingPhaseSuitability || [],
    unilateral: exerciseData.unilateral || false,
    userPreferences: exerciseData.userPreferences || []
  };

  const docRef = await addDoc(customExerciseRef, exercise);
  return { id: docRef.id, ...exercise };
};

export const modifyExercise = async (coachId, exerciseId, modifications) => {
  const modificationRef = doc(db, `coaches/${coachId}/exerciseModifiers`, exerciseId);

  // Build modifiedFields based on what the user changed
  const modifiedFields = {};
  if (modifications.mediaUrl) {
    modifiedFields.defaultVideoUrl = modifications.mediaUrl;
  }
  if (modifications.instructions || modifications.commonMistakes) {
    modifiedFields.formCues = {
      instructions: modifications.instructions || [],
      commonMistakes: modifications.commonMistakes || []
    };
  }

  const dataToStore = {
    originalExerciseId: exerciseId,
    coachId,
    modifiedFields,
    updatedAt: new Date(),
    createdAt: new Date()
  };

  try {
    await setDoc(modificationRef, dataToStore);
    return { id: exerciseId, ...dataToStore };
  } catch (error) {
    console.error('Error modifying exercise:', error);
    throw error;
  }
};

export const getAllExercises = async (coachId) => {
  // Get global exercises
  const exercisesRef = collection(db, 'exercises');
  const exercisesSnap = await getDocs(exercisesRef);
  const globalExercises = exercisesSnap.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
    isGlobal: true
  }));

  // Get coach's custom exercises
  const customExercisesRef = collection(db, `coaches/${coachId}/customExercises`);
  const customExercisesSnap = await getDocs(customExercisesRef);
  const customExercises = customExercisesSnap.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
    isCustom: true
  }));

  // Get coach's modifications
  const modifiersRef = collection(db, `coaches/${coachId}/exerciseModifiers`);
  const modifiersSnap = await getDocs(modifiersRef);
  const modifiers = modifiersSnap.docs.reduce((acc, doc) => {
    const data = doc.data();
    acc[doc.id] = data.modifiedFields || {};
    return acc;
  }, {});

  // Merge modifications with global exercises
  const modifiedGlobalExercises = globalExercises.map(exercise => {
    const modifiedFields = modifiers[exercise.id];
    if (modifiedFields) {
      return {
        ...exercise,
        ...modifiedFields,
        isModified: true,
        originalExercise: exercise // Keep original for reference
      };
    }
    return exercise;
  });

  // Combine and sort all exercises
  const allExercises = [...modifiedGlobalExercises, ...customExercises];
  
  // Sorting logic remains the same
  return allExercises.sort((a, b) => {
    if (a.isCustom && !b.isCustom) return -1;
    if (!a.isCustom && b.isCustom) return 1;
    if (a.isModified && !b.isModified) return -1;
    if (!a.isModified && b.isModified) return 1;
    return a.name_lower.localeCompare(b.name_lower);
  });
};


export const getExerciseById = async (exerciseId, coachId) => {
  // Check for custom exercise first
  const customExerciseRef = doc(db, `coaches/${coachId}/customExercises`, exerciseId);
  const customExerciseSnap = await getDoc(customExerciseRef);
  
  if (customExerciseSnap.exists()) {
    return { 
      id: exerciseId, 
      ...customExerciseSnap.data(), 
      isCustom: true 
    };
  }

  // Check for modifications
  const modificationRef = doc(db, `coaches/${coachId}/exerciseModifiers`, exerciseId);
  const modificationSnap = await getDoc(modificationRef);
  
  // Get original exercise
  const exerciseRef = doc(db, 'exercises', exerciseId);
  const exerciseSnap = await getDoc(exerciseRef);
  
  if (!exerciseSnap.exists()) {
    return null;
  }
  
  const baseExercise = exerciseSnap.data();
  
  // If modifications exist, merge them with the base exercise
  if (modificationSnap.exists()) {
    const modifications = modificationSnap.data();
    return {
      id: exerciseId,
      ...baseExercise,
      ...modifications,
      isModified: true,
      originalExercise: baseExercise // Keep original exercise data
    };
  }
  
  return { id: exerciseId, ...baseExercise, isGlobal: true };
};