import React from 'react';
import { 
  Dumbbell, 
  Clock, 
  CalendarDays, 
  GanttChartSquare,
  Flame,
  Sandwich,
  ChefHat,
  Timer,
  ScrollText,
  Salad
} from 'lucide-react';

export const WorkoutPreviewCard = ({ preview }) => {
  if (!preview) return null;
  
  // Handle timestamp conversion for scheduledTime
  const scheduledDate = preview.scheduledTime?.seconds 
    ? new Date(preview.scheduledTime.seconds * 1000)
    : new Date();

  return (
    <div className="group bg-background-secondary rounded-xl shadow-md overflow-hidden border border-border-light hover:shadow-xl transition-all duration-300 hover:border-accent-primary">
      {preview.headerImageUrl && (
        <div className="relative h-40">
          <div 
            className="absolute inset-0 bg-cover bg-center transform group-hover:scale-105 transition-transform duration-500" 
            style={{ backgroundImage: `url(${preview.headerImageUrl})` }} 
          />
          <div className="absolute inset-0 bg-gradient-to-t from-background-secondary/90 to-transparent" />
        </div>
      )}
      <div className="p-6">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-h3 font-bold text-text-primary font-headers">{preview.name}</h3>
          <div className="flex items-center gap-2 text-accent-primary bg-background-tertiary px-3 py-1 rounded-full">
            <Dumbbell size={18} />
            <span className="text-sm font-medium">{preview.exerciseCount}</span>
          </div>
        </div>
        
        <div className="flex items-center gap-6 text-sm mb-4">
          <div className="flex items-center gap-2 text-accent-secondary">
            <Timer size={16} className="stroke-2" />
            <span className="font-medium">{preview.duration} min</span>
          </div>
          <div className="flex items-center gap-2 text-accent-secondary">
            <CalendarDays size={16} className="stroke-2" />
            <span className="font-medium">
              {scheduledDate.toLocaleDateString()}
            </span>
          </div>
        </div>
        
        {preview.description && (
          <div className="flex gap-2 text-text-secondary">
            <ScrollText size={16} className="flex-shrink-0 mt-1" />
            <p className="text-sm leading-relaxed">{preview.description}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export const MealPreviewCard = ({ preview }) => {
  if (!preview) return null;

  return (
    <div className="group bg-background-secondary rounded-xl shadow-md overflow-hidden border border-border-light hover:shadow-xl transition-all duration-300 hover:border-accent-primary">
      <div className="p-6">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-3">
            <ChefHat size={24} className="text-accent-primary" />
            <h3 className="text-h3 font-bold text-text-primary font-headers">Meal Log</h3>
          </div>
          <div className="flex items-center gap-2 text-accent-secondary bg-background-tertiary px-3 py-1 rounded-full">
            <CalendarDays size={16} className="stroke-2" />
            <span className="text-sm font-medium">
              {new Date(preview.date).toLocaleDateString()}
            </span>
          </div>
        </div>
        
        <div className="flex gap-6 mb-6">
          <div className="flex items-center gap-2 bg-background-tertiary px-3 py-2 rounded-lg">
            <Flame size={18} className="text-accent-primary" />
            <div className="flex flex-col">
              <span className="text-xs text-text-light">Calories</span>
              <span className="text-sm font-bold text-text-primary">{preview.totalCalories}</span>
            </div>
          </div>
          <div className="flex items-center gap-2 bg-background-tertiary px-3 py-2 rounded-lg">
            <GanttChartSquare size={18} className="text-accent-primary" />
            <div className="flex flex-col">
              <span className="text-xs text-text-light">Protein</span>
              <span className="text-sm font-bold text-text-primary">{preview.totalProtein}g</span>
            </div>
          </div>
        </div>
        
        <div className="space-y-2">
          {Array.isArray(preview.foodItems) && preview.foodItems.map((item, index) => (
            <div key={index} className="flex items-center gap-3 p-2 rounded-lg hover:bg-background-tertiary transition-colors">
              <Salad size={16} className="text-accent-secondary" />
              <span className="text-sm text-text-secondary">{item}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
