// src/components/overlays/ModifyExerciseOverlay.jsx
import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/Card';
import { Button } from '../ui/Button';
import { Input } from '../ui/Input';
import { Badge } from '../ui/Badge';
import { X, Plus, Trash2, Video, Play, ListOrdered, Dumbbell } from 'lucide-react';

const ModifyExerciseOverlay = ({ exercise, onSave, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    mediaUrl: exercise?.defaultVideoUrl || '',
    instructions: exercise?.instructions || [''],
    commonMistakes: exercise?.commonMistakes || ['']
  });

  const handleArrayUpdate = (field, index, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: prev[field].map((item, i) => i === index ? value : item)
    }));
  };

  const addArrayItem = (field) => {
    setFormData(prev => ({
      ...prev,
      [field]: [...prev[field], '']
    }));
  };

  const removeArrayItem = (field, index) => {
    setFormData(prev => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index)
    }));
  };

  const handleVideoPlay = (url) => {
    // Check if it's a Firebase Storage URL
    if (url.includes('firebasestorage.googleapis.com')) {
      // Open in new tab for iOS videos
      window.open(url, '_blank');
    } else if (url.includes('youtube.com') || url.includes('youtu.be')) {
      // Handle YouTube videos
      window.open(url, '_blank');
    }
  };

  const saveExercise = async () => {
    setIsLoading(true);
    try {
      await onSave({
        ...exercise,
        defaultVideoUrl: formData.mediaUrl,
      });
      onClose();
    } catch (error) {
      console.error('Error saving exercise:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <Card className="w-full max-w-lg">
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>Modify Exercise</CardTitle>
          <Button variant="ghost" size="icon" onClick={onClose}>
            <X className="h-4 w-4" />
          </Button>
        </CardHeader>

        <CardContent>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="space-y-4">
              <div className="flex items-center space-x-2">
                <Input
                  value={formData.mediaUrl}
                  onChange={(e) => setFormData(prev => ({ ...prev, mediaUrl: e.target.value }))}
                  placeholder="Video URL"
                  className="flex-1"
                />
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => handleVideoPlay(formData.mediaUrl)}
                >
                  <Video className="h-4 w-4" />
                </Button>
              </div>
            </div>
          </form>
        </CardContent>

        <div className="sticky bottom-0 p-4 bg-background-secondary border-t border-border-light/20">
          <Button
            onClick={saveExercise}
            className="w-full bg-accent-primary hover:bg-accent-primary/90"
          >
            {isLoading ? (
              <div className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-muted-foreground border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status" />
            ) : null}
            Save Changes
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default ModifyExerciseOverlay;
