import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Add this import
import { db } from '../firebase';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { useAuth } from '../contexts/AuthContext';

const CoachesManagement = () => {
  const { isAdmin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPage = location.pathname.substring(1);
  const [coaches, setCoaches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadCoaches();
  }, []);

  const loadCoaches = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const coachesQuery = query(
        collection(db, 'coaches'),
        orderBy('createdAt', 'desc')
      );
      
      const coachesSnapshot = await getDocs(coachesQuery);
      const coachesData = coachesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate().toLocaleString() || 'Unknown',
        lastActive: doc.data().lastActive?.toDate().toLocaleString() || 'Never'
      }));
      
      setCoaches(coachesData);
    } catch (error) {
      console.error('Error loading coaches:', error);
      setError('Failed to load coaches');
    } finally {
      setLoading(false);
    }
  };

  const handleCoachClick = (coachId) => {
    navigate(`/coach-profile/${coachId}`);
  };


  if (!isAdmin) {
    return <div>Access denied</div>;
  }

  return (
    <div className="bg-[#000000] min-h-screen text-[#E0E0E0] flex flex-col w-full pt-14">
      <div className="p-6">
        <Card className="bg-[#1C1C1C] border-0">
          <CardHeader className="flex flex-row items-center justify-between">
            <CardTitle className="text-[#FFFFFF] text-2xl">Coaches</CardTitle>
            {error && (
              <div className="text-red-500 text-sm">{error}</div>
            )}
          </CardHeader>
          
          <CardContent>
            {loading ? (
              <div className="flex justify-center p-4">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#6495ED]" />
              </div>
            ) : (
              <div className="space-y-4">
                <div className="grid grid-cols-4 gap-4 p-3 text-sm font-medium text-gray-400">
                  <div>Email</div>
                  <div>Clients</div>
                  <div>Created</div>
                  <div>Last Active</div>
                </div>
                
                {coaches.map((coach) => (
                  <div
                    key={coach.id}
                    className="grid grid-cols-4 gap-4 p-4 bg-[#333333] rounded-lg hover:bg-[#404040] transition-colors"
                    onClick={() => handleCoachClick(coach.id)}
                  >
                    <div className="text-[#FFFFFF] truncate">
                      {coach.email}
                      {coach.isAdmin && (
                        <span className="ml-2 px-2 py-0.5 text-xs bg-blue-500 text-white rounded-full">
                          Admin
                        </span>
                      )}
                    </div>
                    <div className="text-[#E0E0E0]">
                      {coach.numberOfClients || 0} clients
                    </div>
                    <div className="text-[#E0E0E0] text-sm">
                      {coach.createdAt}
                    </div>
                    <div className="text-[#E0E0E0] text-sm">
                      {coach.lastActive}
                    </div>
                  </div>
                ))}

                {coaches.length === 0 && !loading && (
                  <div className="text-center py-8 text-gray-500">
                    No coaches found
                  </div>
                )}
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default CoachesManagement;
