// components/ui/Alert.jsx
import React from 'react';
import { Card, CardContent } from './Card';
import { Button } from './Button';
import { X, AlertCircle, AlertTriangle, CheckCircle, Info } from 'lucide-react';
import { cn } from '../../lib/utils';

export const Alert = ({ 
  title,
  message,
  onConfirm,
  onCancel,
  isOpen,
  confirmText = "Confirm",
  cancelText = "Cancel",
  type = "info" // can be 'info', 'warning', 'error', 'success'
}) => {
  if (!isOpen) return null;

  const typeConfig = {
    info: {
      icon: Info,
      borderColor: "border-accent-tertiary",
      bgColor: "bg-accent-tertiary/10",
      buttonColor: "bg-accent-tertiary hover:bg-accent-tertiary/90",
      iconColor: "text-accent-tertiary"
    },
    warning: {
      icon: AlertTriangle,
      borderColor: "border-amber-500",
      bgColor: "bg-amber-500/10",
      buttonColor: "bg-amber-500 hover:bg-amber-600",
      iconColor: "text-amber-500"
    },
    error: {
      icon: AlertCircle,
      borderColor: "border-red-500",
      bgColor: "bg-red-500/10",
      buttonColor: "bg-red-500 hover:bg-red-600",
      iconColor: "text-red-500"
    },
    success: {
      icon: CheckCircle,
      borderColor: "border-green-500",
      bgColor: "bg-green-500/10",
      buttonColor: "bg-green-500 hover:bg-green-600",
      iconColor: "text-green-500"
    }
  };

  const config = typeConfig[type];
  const Icon = config.icon;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-[100] p-4 animate-in fade-in duration-200">
      <Card className={cn(
        "max-w-md w-full",
        "border-2",
        "shadow-xl",
        "transform transition-all",
        "scale-in-center duration-200",
        config.borderColor
      )}>
        <CardContent className="p-6">
          {/* Header */}
          <div className="flex items-start gap-4 mb-6">
            <div className={cn(
              "w-12 h-12 rounded-full flex items-center justify-center",
              config.bgColor
            )}>
              <Icon className={cn("w-6 h-6", config.iconColor)} />
            </div>
            <div className="flex-1">
              <div className="flex items-start justify-between">
                <h3 className="text-xl font-semibold font-headers text-text-primary leading-tight">
                  {title}
                </h3>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={onCancel}
                  className="text-text-light hover:text-text-primary -mt-1 -mr-2"
                >
                  <X className="h-4 w-4" />
                </Button>
              </div>
              <p className="text-text-secondary mt-2 font-body leading-relaxed">
                {message}
              </p>
            </div>
          </div>

          {/* Actions */}
          <div className="flex justify-end items-center gap-3 mt-6">
            <Button
              variant="ghost"
              onClick={onCancel}
              className="font-medium text-text-light hover:text-text-primary hover:bg-background-tertiary"
            >
              {cancelText}
            </Button>
            <Button
              onClick={onConfirm}
              className={cn(
                "text-white font-medium shadow-md hover:shadow-lg transition-all duration-300",
                config.buttonColor
              )}
            >
              {confirmText}
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export const AlertDescription = ({ children, className = '', ...props }) => {
  return (
    <div className={`text-sm ${className}`} {...props}>
      {children}
    </div>
  );
};