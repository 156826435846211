// components/ui/Button.jsx
import React from 'react';
import { cn } from '../../lib/utils';

const Button = React.forwardRef(
  ({ variant = 'default', size = 'default', className = '', children, ...props }, ref) => {
    const variants = {
      primary: 'bg-accent-primary text-white hover:bg-hover-primary shadow-md hover:shadow-lg',
      secondary: 'bg-accent-secondary text-white hover:bg-hover-primary shadow-md hover:shadow-lg',
      outline: 'border-2 border-accent-primary text-accent-primary hover:bg-background-tertiary',
      ghost: 'text-text-primary hover:bg-background-tertiary',
      link: 'text-accent-tertiary hover:text-accent-primary underline-offset-4 hover:underline'
    };

    const sizes = {
      sm: 'h-8 px-3 text-sm',
      default: 'h-10 px-4 text-base',
      lg: 'h-12 px-6 text-lg',
      icon: 'h-10 w-10'
    };

    return (
      <button
        ref={ref}
        className={cn(
          'inline-flex items-center justify-center rounded-md',
          'font-body font-medium',
          'transition-all duration-200',
          'focus:outline-none focus:ring-2 focus:ring-accent-primary focus:ring-offset-2',
          'disabled:opacity-50 disabled:cursor-not-allowed',
          variants[variant],
          sizes[size],
          className
        )}
        {...props}
      >
        {children}
      </button>
    );
  }
);

Button.displayName = "Button";

export { Button };