import React, { useState, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '../../contexts/AuthContext';

const OnboardingContainer = () => {
  const { user, onboardingComplete, updateOnboardingProgress } = useAuth();
  const [onboardingProgress, setOnboardingProgress] = useState(0);
  const [currentStep, setCurrentStep] = useState('/onboarding');
  const location = useLocation();
  const navigate = useNavigate();
  
  // Redirect to dashboard if onboarding is already complete
  useEffect(() => {
    if (onboardingComplete) {
      navigate('/dashboard');
    }
  }, [onboardingComplete, navigate]);
  
  // Calculate progress based on current route
  useEffect(() => {
    const pathToProgress = {
      // Starting with name-username at 10% as the first step in the flow
      '/onboarding': 10,
      '/onboarding/name-username': 10,
      '/onboarding/setup/nutrition': 30,
      '/onboarding/setup/routines': 50,
      '/onboarding/setup/products': 65,
      '/onboarding/setup/messaging': 80,
      '/onboarding/activation': 90,
      '/onboarding/success': 100
    };
    
    setCurrentStep(location.pathname);
    
    // Find the most specific matching path
    let bestMatch = '/onboarding';
    let bestMatchLength = 0;
    
    Object.keys(pathToProgress).forEach(path => {
      if (location.pathname.startsWith(path) && path.length > bestMatchLength) {
        bestMatch = path;
        bestMatchLength = path.length;
      }
    });
    
    setOnboardingProgress(pathToProgress[bestMatch] || 0);
    
    // Update onboarding step in database based on progress
    if (user && pathToProgress[bestMatch] > 0) {
      // Convert progress percentage to step number (0-4)
      const stepNumber = Math.min(
        Math.floor(pathToProgress[bestMatch] / 25),
        4
      );
      updateOnboardingProgress(stepNumber);
    }
  }, [location, user, updateOnboardingProgress]);

  return (
    <div className="min-h-screen bg-background-primary">
      {/* Progress bar - always show since we're starting with the name-username page */}
      <div className="fixed top-0 left-0 right-0 h-1 bg-background-tertiary z-50">
        <div 
          className="h-full bg-accent-primary transition-all duration-500"
          style={{ width: `${onboardingProgress}%` }}
        />
      </div>
      
      {/* Screen transition container */}
      <AnimatePresence mode="wait">
        <motion.div
          key={currentStep}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.3 }}
          className="w-full min-h-screen"
        >
          <Outlet />
        </motion.div>
      </AnimatePresence>
      
      {/* Common footer with customization note - always show */}
      <div className="fixed bottom-10 left-1/2 transform -translate-x-1/2 w-auto pointer-events-none">
        <motion.div 
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.3 }}
          className="bg-background-secondary px-4 py-2 rounded-full shadow-md text-sm text-text-secondary font-body border border-border-light pointer-events-auto"
        >
          All settings can be customized later on your account page
        </motion.div>
      </div>
    </div>
  );
};

export default OnboardingContainer; 