import React from 'react';
import { motion } from 'framer-motion';
import TextInput from './QuestionTypes/TextInput';
import NumberInput from './QuestionTypes/NumberInput';
import MultipleChoice from './QuestionTypes/MultipleChoice';
import CheckboxGroup from './QuestionTypes/CheckboxGroup';
import DatePicker from './QuestionTypes/DatePicker';
import TagInput from './QuestionTypes/TagInput';
import InjurySelector from './QuestionTypes/InjurySelector';

const FormStep = ({ question, value, onChange }) => {
  // Handle case where question is undefined
  if (!question) {
    return (
      <div className="w-full max-w-2xl mx-auto text-center">
        <p className="text-text-light">Loading question...</p>
      </div>
    );
  }

  // Render different question types
  const renderQuestionInput = () => {
    switch (question.type) {
      case 'text':
        return (
          <TextInput
            value={value}
            onChange={onChange}
            placeholder={question.placeholder || 'Type your answer here...'}
          />
        );
      case 'number':
        return (
          <NumberInput
            value={value}
            onChange={onChange}
            min={question.validations?.min}
            max={question.validations?.max}
          />
        );
      case 'multipleChoice':
        return (
          <MultipleChoice
            options={question.options}
            value={value}
            onChange={onChange}
            autoAdvance={!question.preventAutoAdvance}
          />
        );
      case 'checkbox':
        return (
          <CheckboxGroup
            options={question.options}
            value={value || []}
            onChange={onChange}
          />
        );
      case 'date':
        return (
          <DatePicker
            value={value}
            onChange={onChange}
            min={question.validations?.min}
            max={question.validations?.max}
            placeholder={question.placeholder || 'Select a date...'}
          />
        );
      case 'tagInput':
        return (
          <TagInput
            value={value || []}
            onChange={onChange}
            placeholder={question.placeholder || 'Type and press Enter...'}
          />
        );
      case 'injurySelector':
        return (
          <InjurySelector
            value={value || []}
            onChange={onChange}
            question={question}
          />
        );
      // Add more question types as needed
      default:
        return (
          <TextInput
            value={value}
            onChange={onChange}
            placeholder="Type your answer here..."
          />
        );
    }
  };

  return (
    <div className="w-full max-w-2xl mx-auto py-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.5, ease: [0.22, 1, 0.36, 1] }}
        className="mb-10"
      >
        {/* Question number indicator */}
        {question.showNumber && (
          <div className="mb-6 flex justify-center">
            <div className="w-8 h-8 rounded-full bg-accent-primary/10 flex items-center justify-center text-accent-primary font-medium text-sm">
              {question.number || '#'}
            </div>
          </div>
        )}
        
        {/* Question title */}
        <h2 className="text-3xl font-headers font-medium text-text-primary mb-4 text-center leading-tight">
          {question.title}
        </h2>
        
        {/* Question description */}
        {question.description && (
          <p className="text-text-light text-lg text-center max-w-xl mx-auto leading-relaxed mt-2">
            {question.description}
          </p>
        )}
      </motion.div>

      {/* Question input */}
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -10 }}
        transition={{ duration: 0.6, delay: 0.1, ease: [0.22, 1, 0.36, 1] }}
        className="w-full"
      >
        {renderQuestionInput()}
      </motion.div>

      {/* Bottom helper section */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.4, delay: 0.3 }}
        className="flex flex-col items-center mt-6"
      >
        {/* Required indicator */}
        {question.isRequired && (
          <div className="flex items-center text-text-light text-sm mb-2">
            <div className="w-1 h-1 rounded-full bg-accent-primary mr-2"></div>
            <span>Required</span>
          </div>
        )}
        
        {/* Helper text */}
        {question.helperText && (
          <p className="text-text-light text-sm mt-1 text-center italic">
            {question.helperText}
          </p>
        )}
      </motion.div>
    </div>
  );
};

export default FormStep; 