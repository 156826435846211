// src/components/TemplateManagement.jsx

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { Button } from './ui/Button';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { Plus, Trash2, Dumbbell, FolderPlus, Folder, MoreVertical, FolderMinus, X, ChevronRight, ChevronDown } from 'lucide-react';
import { cardStyles } from './ui/Styles';
import { createFolder, deleteFolder, addTemplateToFolder, removeTemplateFromFolder } from '../services/folderService';
import { Alert } from './ui/Alert';

const TemplateManagement = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [templates, setTemplates] = useState([]);
  const [folders, setFolders] = useState([]);
  const [expandedFolders, setExpandedFolders] = useState(new Set());
  const [loading, setLoading] = useState(true);
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showFolderSelector, setShowFolderSelector] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    title: '',
    message: '',
    onConfirm: () => {},
    type: 'info'
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // First get all workout folders
        const foldersRef = collection(db, `coaches/${user.uid}/workoutFolders`);
        const foldersSnapshot = await getDocs(foldersRef);
        const folderList = foldersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setFolders(folderList);
        
        // Set first folder as expanded by default
        if (folderList.length > 0) {
          setExpandedFolders(new Set([folderList[0].id]));
        }

        // Then get all templates
        const templatesRef = collection(db, `coaches/${user.uid}/workoutTemplates`);
        const snapshot = await getDocs(templatesRef);
        const templateList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        // Sort by date created
        templateList.sort((a, b) => b.createdAt?.toMillis() - a.createdAt?.toMillis());
        setTemplates(templateList);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (user?.uid) {
      fetchData();
    }
  }, [user]);

  const toggleFolder = (folderId) => {
    setExpandedFolders(prev => {
      const newSet = new Set(prev);
      if (newSet.has(folderId)) {
        newSet.delete(folderId);
      } else {
        newSet.add(folderId);
      }
      return newSet;
    });
  };

  const handleDeleteTemplate = async (e, templateId) => {
    e.stopPropagation(); 
    if (window.confirm('Are you sure you want to delete this template?')) {
      try {
        // First remove the template from all folders
        const updatePromises = folders
          .filter(folder => folder.items?.includes(templateId))
          .map(folder => {
            return removeTemplateFromFolder(user.uid, folder.id, templateId);
          });
        
        await Promise.all(updatePromises);
        
        // Then delete the template
        await deleteDoc(doc(db, `coaches/${user.uid}/workoutTemplates/${templateId}`));
        setTemplates(templates.filter(template => template.id !== templateId));
      } catch (error) {
        console.error('Error deleting template:', error);
      }
    }
  };

  const handleCreateFolder = async () => {
    if (!newFolderName.trim()) return;
    
    try {
      const newFolder = await createFolder(user.uid, { name: newFolderName });
      setFolders([...folders, newFolder]);
      setNewFolderName('');
      setShowCreateFolder(false);
    } catch (error) {
      console.error('Error creating folder:', error);
    }
  };

  const handleDeleteFolder = async (folder) => {
    if (!window.confirm('Are you sure you want to delete this folder?')) return;
    
    try {
      await deleteFolder(user.uid, folder.id);
      setFolders(folders.filter(f => f.id !== folder.id));
    } catch (error) {
      console.error('Error deleting folder:', error);
    }
  };

  const handleAddToFolder = async (folder) => {
    try {
      if (folder.items?.includes(selectedTemplate.id)) {
        setAlertConfig({
          isOpen: true,
          title: 'Already Added',
          message: 'This template is already in the folder',
          type: 'info',
          onConfirm: () => setAlertConfig({ isOpen: false })
        });
        return;
      }

      await addTemplateToFolder(user.uid, folder.id, selectedTemplate.id);
      
      const updatedFolders = folders.map(f => {
        if (f.id === folder.id) {
          return {
            ...f,
            items: [...(f.items || []), selectedTemplate.id]
          };
        }
        return f;
      });
      
      setFolders(updatedFolders);
      setSelectedTemplate(null);
      setShowFolderSelector(false);
      
      setAlertConfig({
        isOpen: true,
        title: 'Success',
        message: 'Template added to folder successfully',
        type: 'success',
        onConfirm: () => setAlertConfig({ isOpen: false })
      });
    } catch (error) {
      console.error('Error adding template to folder:', error);
      setAlertConfig({
        isOpen: true,
        title: 'Error',
        message: 'Failed to add template to folder',
        type: 'error',
        onConfirm: () => setAlertConfig({ isOpen: false })
      });
    }
  };

  const handleRemoveFromFolder = async (folderId, template) => {
    try {
      await removeTemplateFromFolder(user.uid, folderId, template.id);
      
      const updatedFolders = folders.map(f => {
        if (f.id === folderId) {
          return {
            ...f,
            items: f.items.filter(id => id !== template.id)
          };
        }
        return f;
      });
      setFolders(updatedFolders);
      
      setAlertConfig({
        isOpen: true,
        title: 'Success',
        message: 'Template removed from folder successfully',
        type: 'success',
        onConfirm: () => setAlertConfig({ isOpen: false })
      });
    } catch (error) {
      console.error('Error removing template from folder:', error);
      setAlertConfig({
        isOpen: true,
        title: 'Error',
        message: 'Failed to remove template from folder',
        type: 'error',
        onConfirm: () => setAlertConfig({ isOpen: false })
      });
    }
  };

  const renderTemplateCard = (template, folderId = null) => (
    <Card
      key={template.id}
      onClick={() => navigate(`/workouts/edit/new?templateId=${template.id}`)}
      className="group hover:border-accent-primary/30 transition-all duration-300 cursor-pointer"
    >
      <CardContent className="p-4">
        <div className="flex items-center justify-between">
          <div className="flex-1 min-w-0">
            <div className="flex items-center gap-3">
              <div className="flex-shrink-0 w-10 h-10 rounded-lg bg-accent-primary/10 flex items-center justify-center">
                <Dumbbell className="h-5 w-5 text-accent-primary" />
              </div>
              <div>
                <h3 className="font-medium text-text-primary truncate">
                  {template.name || 'Untitled Template'}
                </h3>
                <p className="text-sm text-text-light truncate">
                  {template.description || `${template.exercises?.length || 0} exercises`}
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            {folderId ? (
              <Button
                variant="ghost"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveFromFolder(folderId, template);
                }}
                className="opacity-0 group-hover:opacity-100 text-amber-600 hover:text-amber-700 hover:bg-amber-50"
              >
                <FolderMinus className="h-4 w-4 mr-1" />
                Remove
              </Button>
            ) : (
              <Button
                variant="ghost"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedTemplate(template);
                  setShowFolderSelector(true);
                }}
                className="opacity-0 group-hover:opacity-100"
              >
                <FolderPlus className="h-4 w-4 mr-1" />
                Add to Folder
              </Button>
            )}
            <Button
              variant="ghost"
              size="icon"
              onClick={(e) => handleDeleteTemplate(e, template.id)}
              className="opacity-0 group-hover:opacity-100 text-red-500 hover:text-red-600 hover:bg-red-50"
            >
              <Trash2 className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );

  // Get templates that are in folders and those that aren't
  const getUnfolderedTemplates = () => {
    const folderedTemplateIds = new Set(
      folders.flatMap(folder => folder.items || [])
    );
    return templates.filter(template => !folderedTemplateIds.has(template.id));
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-background-primary">
        <div className="flex flex-col items-center gap-4">
          <div className="animate-spin rounded-full h-12 w-12 border-4 border-accent-primary border-t-transparent" />
          <p className="text-text-light font-body animate-pulse">Loading templates...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background-primary text-text-primary">
      <div className="max-w-7xl mx-auto py-xxl px-md md:px-xl">
        <Card className="overflow-visible">
          <CardHeader className="pb-lg space-y-4">
            <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
              <div>
                <h1 className="font-headers text-h1 font-bold text-text-primary">
                  Workout Templates
                </h1>
                <p className="text-text-light font-body mt-2">
                  Create and manage your workout templates to streamline your coaching process
                </p>
              </div>
              <div className="flex items-center gap-3">
                <Button
                  variant="outline"
                  onClick={() => setShowCreateFolder(true)}
                  className="whitespace-nowrap"
                >
                  <FolderPlus className="h-5 w-5 mr-2" />
                  New Folder
                </Button>
                <Button
                  variant="primary"
                  onClick={() => navigate('/create-template')}
                  className="whitespace-nowrap"
                >
                  <Plus className="h-5 w-5 mr-2" />
                  Create Template
                </Button>
              </div>
            </div>
          </CardHeader>

          <CardContent>
            {templates.length === 0 ? (
              <div className="text-center py-xl bg-background-secondary rounded-lg border-2 border-dashed border-border-light">
                <Dumbbell className="h-16 w-16 text-accent-primary mx-auto mb-md opacity-50" />
                <h3 className="font-headers text-h2 mb-2">Start Creating Templates</h3>
                <p className="text-text-light font-body max-w-md mx-auto mb-6">
                  Design workout templates to efficiently manage and assign workouts to your clients
                </p>
                <Button
                  variant="primary"
                  onClick={() => navigate('/create-template')}
                  className="flex items-center gap-2 mx-auto"
                >
                  <Plus className="h-5 w-5" />
                  Create Your First Template
                </Button>
              </div>
            ) : (
              <div className="space-y-8">
                {/* Folders Section */}
                {folders.length > 0 && (
                  <div>
                    <h2 className="text-lg font-medium text-text-primary mb-4">Folders</h2>
                    <div className="grid gap-6">
                      {folders.map(folder => (
                        <div key={folder.id} className="space-y-3 bg-background-secondary rounded-lg p-4">
                          <div className="flex items-center justify-between">
                            <div 
                              className="flex items-center gap-2 cursor-pointer"
                              onClick={() => toggleFolder(folder.id)}
                            >
                              {expandedFolders.has(folder.id) ? (
                                <ChevronDown className="h-5 w-5 text-text-light" />
                              ) : (
                                <ChevronRight className="h-5 w-5 text-text-light" />
                              )}
                              <Folder className="h-5 w-5 text-accent-primary" />
                              <h3 className="font-medium">{folder.name}</h3>
                              <span className="text-sm text-text-light">
                                ({folder.items?.length || 0} templates)
                              </span>
                            </div>
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={() => handleDeleteFolder(folder)}
                              className="text-red-500 hover:text-red-600 hover:bg-red-50"
                            >
                              <Trash2 className="h-4 w-4" />
                            </Button>
                          </div>
                          {expandedFolders.has(folder.id) && (
                            <div className="grid gap-4 pl-6 border-l-2 border-border-light mt-2">
                              {templates
                                .filter(template => folder.items?.includes(template.id))
                                .map(template => renderTemplateCard(template, folder.id))}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* Unfoldered Templates Section */}
                <div>
                  <h2 className="text-lg font-medium text-text-primary mb-4">Unorganized Templates</h2>
                  <div className="grid gap-4">
                    {getUnfolderedTemplates().map(template => renderTemplateCard(template))}
                  </div>
                </div>
              </div>
            )}
          </CardContent>
        </Card>
      </div>

      {/* Create Folder Dialog */}
      {showCreateFolder && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-background-secondary p-6 rounded-xl w-96">
            <h3 className="text-xl font-semibold mb-4">Create New Folder</h3>
            <input
              type="text"
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              placeholder="Folder name"
              className="w-full p-2 mb-4 border rounded"
            />
            <div className="flex justify-end gap-3">
              <Button
                variant="ghost"
                onClick={() => setShowCreateFolder(false)}
              >
                Cancel
              </Button>
              <Button
                onClick={handleCreateFolder}
                disabled={!newFolderName.trim()}
              >
                Create
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* Folder Selection Dialog */}
      {showFolderSelector && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-background-secondary p-6 rounded-xl w-[480px] max-h-[80vh] overflow-y-auto">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-xl font-semibold">Add to Folder</h3>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => {
                  setShowFolderSelector(false);
                  setSelectedTemplate(null);
                }}
              >
                <X className="h-5 w-5" />
              </Button>
            </div>
            <div className="grid gap-4">
              {folders.map(folder => (
                <Card
                  key={folder.id}
                  onClick={() => handleAddToFolder(folder)}
                  className="hover:border-accent-primary/30 transition-all duration-300 cursor-pointer"
                >
                  <CardContent className="p-4">
                    <div className="flex items-center gap-3">
                      <div className="flex-shrink-0 w-10 h-10 rounded-lg bg-accent-primary/10 flex items-center justify-center">
                        <Folder className="h-5 w-5 text-accent-primary" />
                      </div>
                      <div>
                        <h3 className="font-medium text-text-primary">
                          {folder.name}
                        </h3>
                        <p className="text-sm text-text-light">
                          {folder.items?.length || 0} templates
                        </p>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </div>
      )}

      <Alert
        {...alertConfig}
        onCancel={() => setAlertConfig({ isOpen: false })}
      />
    </div>
  );
};

export default TemplateManagement;
