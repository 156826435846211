// src/components/CoachDashboard.jsx

import React, { useState, useEffect, useCallback} from 'react';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  arrayUnion,
  arrayRemove,
  increment,
  addDoc,
  setDoc,
} from 'firebase/firestore';
import { CheckIcon, X , HelpCircle, Plus, ChevronRight, Users, Settings } from 'lucide-react';
import { Input } from './ui/Input';
import { Button } from './ui/Button';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { db } from '../firebase';
import NavigationBar from './NavigationBar';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { API_URLS } from '../constants/api';
import { motion } from 'framer-motion';
import { initializeClientAccount } from '../utils/clientInitialization';

const CoachDashboard = () => {
  const { user, isAdmin } = useAuth();
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [message, setMessage] = useState('');
  const [coachData, setCoachData] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('');
  const [isLoadingClients, setIsLoadingClients] = useState(true);
  const [pendingRequests, setPendingRequests] = useState([]);

  useEffect(() => {
    if (user?.uid) {
      const unsubscribe = loadClients();
      return () => {
        if (unsubscribe) unsubscribe();
      };
    }
  }, [user?.uid]);

  useEffect(() => {
    if (user?.uid) {
      const q = query(
        collection(db, 'users'),
        where('requestedCoachId', '==', user.uid),
        where('requestStatus', '==', 'pending')
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const uniqueRequests = new Map();
        
        snapshot.docs.forEach((doc) => {
          const request = {
            id: doc.id,
            ...doc.data()
          };
          // Only add if not already in the map
          if (!uniqueRequests.has(doc.id)) {
            uniqueRequests.set(doc.id, request);
          }
        });
        
        const requests = Array.from(uniqueRequests.values());
        console.log('Pending requests:', requests);
        setPendingRequests(requests);
      });

      return () => unsubscribe();
    }
  }, [user?.uid]);

  useEffect(() => {
    let unsubscribeCoach = () => {};
  
    const setupSubscriptions = async () => {
      if (!user?.uid) return;
  
      try {
        const coachRef = doc(db, 'coaches', user.uid);
        unsubscribeCoach = onSnapshot(coachRef, (doc) => {
          if (doc.exists) {
            const data = doc.data();
            setCoachData(data);
          }
        });
      } catch (error) {
        console.error('Error setting up subscription listener:', error);
      }
    };
  
    setupSubscriptions();
    return () => unsubscribeCoach();
  }, [user?.uid]);

  const loadClients = () => {
    if (!user?.uid) return;
  
    setIsLoadingClients(true);
  
    const clientsQuery = query(
      collection(db, 'users'),
      where('coachId', '==', user.uid)
    );
  
    return onSnapshot(clientsQuery, async (snapshot) => {
      try {
        const clientList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setClients(clientList);
        
        const clientIds = clientList.map(client => client.id);
        
        const coachDocRef = doc(db, 'coaches', user.uid);
        await updateDoc(coachDocRef, {
          numberOfClients: clientList.length,
          clients: clientIds,
          updatedAt: serverTimestamp(),
        });
  
      } catch (error) {
        console.error('Error processing client update:', error);
        setMessage('Error updating clients: ' + error.message);
      } finally {
        setIsLoadingClients(false);
      }
    }, (error) => {
      console.error('Error in client subscription:', error);
      setMessage('Error loading clients: ' + error.message);
      setIsLoadingClients(false);
    });
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setUploading(true);
    setUploadMessage('');
    
    try {
      const fileContents = await file.text();
      const exercisesData = JSON.parse(fileContents);
  
      const idToken = await user.getIdToken();
      console.log('Upload URL:', API_URLS.uploadExercises);
      const response = await fetch(API_URLS.uploadExercises, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify({ exercises: exercisesData }),
      });
  
      const result = await response.json();
  
      if (result.success) {
        setUploadMessage('Exercises uploaded successfully');
      } else {
        throw new Error(result.error || 'Failed to upload exercises');
      }
    } catch (error) {
      console.error('Error uploading exercises:', error);
      setUploadMessage(`Error uploading exercises: ${error.message}`);
    } finally {
      setUploading(false);
    }
  };

  const removeClient = async (clientId) => {
    try {
      const idToken = await user.getIdToken();
      const response = await fetch(API_URLS.removeClient, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify({ clientId }),
      });
  
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Failed to remove client');
      }
  
      await loadClients();
      setMessage('Client removed successfully');
    } catch (error) {
      console.error('Error removing client:', error);
      setMessage('Error removing client: ' + error.message);
    }
  };

  const handleClientClick = (clientId) => {
    navigate(`/client/${clientId}`);
  };

  const acceptRequest = async (client) => {
    try {
      // Accept the request
      await updateDoc(doc(db, 'users', client.id), {
        coachId: user.uid,
        requestedCoachId: null,
        requestStatus: 'accepted',
        updatedAt: serverTimestamp(),
        subscriptionStatus: 'active',
      });
      
      // Initialize client account settings
      await initializeClientAccount(client.id, user.uid, client.name);
      
      setMessage('Client request accepted successfully');
    } catch (error) {
      setMessage(`Error accepting request: ${error.message}`);
    }
    window.history.replaceState({}, '', '/dashboard');
  };

  const declineRequest = async (client) => {
    try {
      await updateDoc(doc(db, 'users', client.id), {
        requestedCoachId: null,
        requestStatus: 'declined',
        updatedAt: serverTimestamp()
      });
    } catch (error) {
      setMessage(`Error declining request: ${error.message}`);
    }
  };

  return (
    <div className="min-h-screen bg-background-primary mt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Dashboard Header */}
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-8">
          <div>
            <h1 className="font-headers text-3xl font-bold text-text-primary">
              Evolv.
            </h1>
            <p className="text-text-light mt-2">
              Managing {clients.length} clients
            </p>
          </div>
          <div className="flex items-center gap-3 mt-4 sm:mt-0">
            <Button 
              variant="secondary"
              onClick={() => navigate('/onboarding-forms')}
              className="flex items-center gap-2"
            >
              <Settings className="h-4 w-4" />
              Customize Onboarding
            </Button>
            <Button 
              variant="primary"
              onClick={() => setShowSubscriptionModal(true)}
              className="flex items-center gap-2"
            >
              <Plus className="h-4 w-4" />
              Add Client Spots
            </Button>
          </div>
        </div>

        {/* Main Content */}
        <div className="space-y-6">
          {/* Client Overview Card */}
          <Card>
            <CardHeader>
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                <CardTitle>Clients</CardTitle>
                <div className="flex items-center gap-4 mt-2 sm:mt-0">
                  <span className="text-sm text-text-light">
                    {pendingRequests.length} pending {pendingRequests.length === 1 ? 'request' : 'requests'}
                  </span>
                </div>
              </div>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                {/* Pending Requests */}
                {pendingRequests.map((client) => (
                  <div 
                    key={client.id}
                    className="bg-background-tertiary rounded-lg p-4 border-l-4 border-accent-primary"
                  >
                    <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
                      <div>
                        <div className="flex items-center gap-2">
                          <span className="font-medium text-text-primary">{client.name}</span>
                          <span className="text-sm px-2 py-1 bg-accent-primary/10 text-accent-primary rounded-full">
                            New Request
                          </span>
                        </div>
                        <div className="text-sm text-text-light mt-1">{client.email}</div>
                      </div>
                      <div className="flex gap-2 mt-2 sm:mt-0">
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() => acceptRequest(client)}
                        >
                          Accept
                        </Button>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => declineRequest(client)}
                        >
                          Decline
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}

                {/* Active Clients */}
                {clients.map((client) => (
                  <div 
                    key={client.id}
                    className="group bg-background-secondary hover:bg-background-tertiary rounded-lg p-4 transition-all cursor-pointer"
                    onClick={() => handleClientClick(client.id)}
                  >
                    <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
                      <div>
                        <div className="font-medium text-text-primary">{client.name}</div>
                        <div className="text-sm text-text-light mt-1">{client.email}</div>
                      </div>
                      <div className="flex items-center gap-2 mt-2 sm:mt-0">
                        <Button
                          variant="ghost"
                          size="sm"
                          className="opacity-0 group-hover:opacity-100 transition-opacity"
                          onClick={(e) => {
                            e.stopPropagation();
                            removeClient(client.id);
                          }}
                        >
                          Remove
                        </Button>
                        <ChevronRight className="h-5 w-5 text-text-light group-hover:text-accent-primary transition-colors" />
                      </div>
                    </div>
                  </div>
                ))}

                {/* Empty State */}
                {isLoadingClients ? (
                  <div className="text-center py-12">
                    <div className="animate-spin h-8 w-8 border-4 border-accent-primary border-t-transparent rounded-full mx-auto mb-4" />
                    <p className="text-text-light">Loading clients...</p>
                  </div>
                ) : clients.length === 0 && pendingRequests.length === 0 ? (
                  <div className="text-center py-12">
                    <div className="bg-background-tertiary rounded-full p-4 w-16 h-16 mx-auto mb-4 flex items-center justify-center">
                      <Users className="h-8 w-8 text-text-light" />
                    </div>
                    <h3 className="text-lg font-medium text-text-primary mb-2">No clients yet</h3>
                    <p className="text-sm text-text-light max-w-md mx-auto">
                      Share your coach profile with potential clients to get started
                    </p>
                  </div>
                ) : null}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CoachDashboard;