// src/firebase.js

import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Firebase Config
import { firebaseConfig } from './firebaseConfig';

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const functions = getFunctions(app, 'us-central1'); 
const db = getFirestore(app);
const storage = getStorage(app);

// Create a Google Provider instance
const googleProvider = new GoogleAuthProvider();

export { app, auth, functions, db, storage, googleProvider };
