import React, { useState, useEffect, useRef } from 'react';
import { Button } from '../ui/Button';
import { Input } from '../ui/Input';
import { Edit2, X, Plus } from 'lucide-react';
import { collection, addDoc, updateDoc, deleteDoc, doc, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { cn } from '../../lib/utils';

const QuickAddProducts = ({ userId, onProductAdded }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  
  const quickAddProducts = [
    { name: 'Protein Powder', id: 'protein' },
    { name: 'Fish Oil', id: 'fish-oil' },
    { name: 'Vitamin C', id: 'vitamin-c' },
    { name: 'Iron Supplement', id: 'iron' }
  ];

  const handleQuickAdd = async (product) => {
    try {
      if (selectedProducts.includes(product.id)) {
        // Remove product
        const productsRef = collection(
          db, 
          'coaches', 
          userId, 
          'products'
        );
        
        const querySnapshot = await getDocs(productsRef);
        querySnapshot.forEach(async (doc) => {
          if (doc.data().name === product.name) {
            await deleteDoc(doc.ref);
          }
        });
        
        setSelectedProducts(selectedProducts.filter(id => id !== product.id));
      } else {
        // Add product
        const productRef = collection(
          db, 
          'coaches', 
          userId, 
          'products'
        );
        
        await addDoc(productRef, {
          name: product.name,
          tags: 'supplements',
          category: 'supplements'
        });
        
        setSelectedProducts([...selectedProducts, product.id]);
      }
      
      onProductAdded && onProductAdded();
    } catch (error) {
      console.error("Error managing product:", error);
    }
  };

  return (
    <div className="flex gap-2 flex-wrap">
      {quickAddProducts.map((product) => (
        <button
          key={product.id}
          onClick={() => handleQuickAdd(product)}
          className={cn(
            "px-4 py-2 rounded-lg font-body transition-all duration-200",
            selectedProducts.includes(product.id)
              ? "bg-accent-primary text-white hover:bg-hover-primary"
              : "bg-background-tertiary text-text-secondary hover:bg-hover-secondary"
          )}
        >
          {product.name}
        </button>
      ))}
    </div>
  );
};

const ProductsTab = ({ userId, onMount, isFirstSignup }) => {
  const [products, setProducts] = useState({
    supplements: [],
    grocery: [],
    miscellaneous: [],
  });
  const [activeCategory, setActiveCategory] = useState('supplements');
  const [newProduct, setNewProduct] = useState({
    name: '',
    tags: '',
    link: ''
  });
  const quickAddRef = useRef(null);

  useEffect(() => {
    if (quickAddRef.current) {
      onMount && onMount(quickAddRef.current);
    }
  }, [onMount]);

  const loadProducts = async () => {
    try {
      const productsRef = collection(db, 'coaches', userId, 'products');
      const productsSnapshot = await getDocs(productsRef);
      const newProducts = {
        supplements: [],
        grocery: [],
        miscellaneous: []
      };

      productsSnapshot.docs.forEach(doc => {
        const product = { id: doc.id, ...doc.data() };
        const category = product.category || 'supplements';
        newProducts[category].push(product);
      });

      setProducts(newProducts);
    } catch (error) {
      console.error('Error loading products:', error);
    }
  };

  useEffect(() => {
    loadProducts();
  }, [userId]);

  useEffect(() => {
    if (isFirstSignup) {
      // Add protein powder automatically
      const addProteinPowder = async () => {
        try {
          const productRef = collection(
            db, 
            'coaches', 
            userId, 
            'products'
          );
    
          await addDoc(productRef, {
            name: 'Protein Powder',
            tags: 'protein,supplements,post-workout',
            link: 'https://www.amazon.com/OPTIMUM-NUTRITION-STANDARD-Protein-Isolates/dp/B000GISTZ4?dib=eyJ2IjoiMSJ9.W2w4iTZAjSLjp3Z9KsP_Tilva0YcMLYPEDQvnViyEx30gfu5vdF1wgdVz-nSXRABvj79hsR8U6ONRf3vqWkBR_uQOYPiWJ5C7iOk6dF282E0I25TK0CGnc6RNngGPx4bxavFFI6RkHUoG2ZnISM94fuA13Z41m59NSIYC_LxxDXYoENpS4CaXPFiMkO53VPjk9U7deiLUcx3p3ar1PyxmSodA3uDF3SzHbUIMcTjcHy3oPNsZv94rSf15IQmAGMCnGnQ9n9_nHSse2ejod9EuDmbkyDVMpR8bcr1QohVnsEcxF0OUL_ilIRUHqb3YD7lpNbp0J4-hOj0fP6b3Mut2jNfglx5alscCBASArghrVgEgidBwyJ0INx5LFCpMdn0tPX9tqlEoJBqts7Pmwu2uTgVw0EpVok7jmU2_6tyg4_6uDjNA_BNMcbjdj2aerkP.a7VnMlJhLuiAmQnA0rmaEjkAo9Ld8M-XbO2af6ixt8s&dib_tag=se&keywords=protein+powder&qid=1736466841&sr=8-5'
          });
          
          await loadProducts();
        } catch (error) {
          console.error('Error adding protein powder:', error);
        }
      };

      addProteinPowder();
    }
  }, [isFirstSignup, userId]);

  const handleAddProduct = async () => {
    if (!newProduct.name.trim()) return;

    try {
      const productRef = collection(
        db, 
        'coaches', 
        userId, 
        'products'
      );

      await addDoc(productRef, {
        ...newProduct,
        category: activeCategory
      });
      
      await loadProducts();
      setNewProduct({ name: '', tags: '', link: '' });
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };

  const removeProduct = async (productId) => {
    try {
      const productRef = doc(
        db, 
        'coaches', 
        userId, 
        'products',
        productId
      );
      await deleteDoc(productRef);
      await loadProducts();
    } catch (error) {
      console.error('Error removing product:', error);
    }
  };

  return (
    <div className="max-w-4xl mx-auto space-y-6">
      <div className="flex gap-3 mb-8">
        {['supplements', 'grocery', 'miscellaneous'].map((category) => (
          <button
            key={category}
            onClick={() => setActiveCategory(category)}
            className={cn(
              "px-4 py-2 rounded-lg font-body transition-all duration-200",
              activeCategory === category
                ? "bg-accent-primary text-white"
                : "bg-background-tertiary text-text-secondary hover:bg-hover-secondary"
            )}
          >
            {category.charAt(0).toUpperCase() + category.slice(1)}
          </button>
        ))}
      </div>

      <div 
        ref={quickAddRef}
        className="mb-8"
      >
        <div className="bg-background-secondary rounded-lg p-6 border border-border-light">
          <h3 className="text-lg font-headers text-text-primary mb-4">Quick Add Products</h3>
          <QuickAddProducts userId={userId} onProductAdded={loadProducts} />
        </div>
      </div>

      <div className="bg-background-secondary rounded-lg p-6 border border-border-light">
        <h3 className="text-lg font-headers text-text-primary mb-4">Add New Product</h3>
        <div className="flex gap-3">
          <Input
            placeholder="Product name"
            value={newProduct.name}
            onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
            className="bg-background-primary border-border-light text-text-primary 
                     focus:border-accent-primary focus:ring-accent-primary"
          />
          <Input
            placeholder="Tags (comma separated)"
            value={newProduct.tags}
            onChange={(e) => setNewProduct({ ...newProduct, tags: e.target.value })}
            className="bg-background-primary border-border-light text-text-primary 
                     focus:border-accent-primary focus:ring-accent-primary"
          />
          <Input
            placeholder="Link (optional)"
            value={newProduct.link}
            onChange={(e) => setNewProduct({ ...newProduct, link: e.target.value })}
            className="bg-background-primary border-border-light text-text-primary 
                     focus:border-accent-primary focus:ring-accent-primary"
          />
          <Button
            onClick={handleAddProduct}
            className="bg-accent-primary text-white hover:bg-hover-primary px-6 
                     transition-all duration-200 shadow-md hover:shadow-lg"
          >
            <Plus className="h-4 w-4" />
          </Button>
        </div>
      </div>

      <div className="space-y-3">
        {products[activeCategory]?.map((product) => (
          <div
            key={product.id}
            className="group flex justify-between items-center bg-background-secondary p-4 
                     rounded-lg border border-border-light hover:border-accent-secondary 
                     transition-all duration-200"
          >
            <div className="flex-1">
              <div className="flex items-center gap-2">
                <div className="font-medium text-text-primary font-body">
                  {product.name}
                </div>
                {product.link && (
                  <a
                    href={product.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-accent-tertiary hover:text-accent-primary text-sm 
                             transition-colors font-body"
                  >
                    Link →
                  </a>
                )}
              </div>
              {product.tags && (
                <div className="flex flex-wrap gap-2 mt-2">
                  {product.tags.split(',').map((tag, index) => (
                    <span
                      key={index}
                      className="inline-block bg-background-tertiary text-text-secondary 
                               rounded-full px-3 py-1 text-xs font-body"
                    >
                      {tag.trim()}
                    </span>
                  ))}
                </div>
              )}
            </div>
            <button
              onClick={() => removeProduct(product.id)}
              className="p-2 text-text-light hover:text-red-500 hover:bg-red-50 
                       rounded-lg opacity-0 group-hover:opacity-100 transition-all duration-200"
            >
              <X className="h-4 w-4" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductsTab;