// components/ui/Alert.jsx
import React from 'react';

export const Alert = ({ children, variant = 'default', className = '', ...props }) => {
  const baseStyles = "p-4 rounded-lg mb-4 animate-in fade-in duration-300";
  const variantStyles = {
    default: "bg-gray-800 text-gray-200",
    destructive: "bg-red-900/50 text-red-200 border border-red-800",
    success: "bg-green-900/50 text-green-200 border border-green-800",
    warning: "bg-yellow-900/50 text-yellow-200 border border-yellow-800"
  };

  return (
    <div 
      role="alert"
      className={`${baseStyles} ${variantStyles[variant]} ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};

export const AlertDescription = ({ children, className = '', ...props }) => {
  return (
    <div className={`text-sm ${className}`} {...props}>
      {children}
    </div>
  );
};