// src/components/AccountPageTabs/RoutineBuilder.jsx

import React, { useState, useEffect } from 'react';
import { Plus, Trash2, Edit2, Clock, Sun, Moon, GripVertical, CheckSquare } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/Card';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { v4 as uuidv4 } from 'uuid';
import { cn } from '../../lib/utils';

const RoutineBuilder = ({ userId }) => {
  const [routineType, setRoutineType] = useState('morning');
  const [editingIndex, setEditingIndex] = useState(null);
  const [morningSteps, setMorningSteps] = useState([]);
  const [eveningSteps, setEveningSteps] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  const steps = routineType === 'morning' ? morningSteps : eveningSteps;
  const setSteps = routineType === 'morning' ? setMorningSteps : setEveningSteps;

  // Load routines from Firestore
  useEffect(() => {
    const loadRoutines = async () => {
      if (!userId) return;
      
      setIsLoading(true);
      try {
        const morningDoc = await getDoc(doc(db, 'coaches', userId, 'Routines', 'morningRoutine'));
        const eveningDoc = await getDoc(doc(db, 'coaches', userId, 'Routines', 'eveningRoutine'));
        
        setMorningSteps(morningDoc.exists() ? morningDoc.data().steps || [] : []);
        setEveningSteps(eveningDoc.exists() ? eveningDoc.data().steps || [] : []);
      } catch (error) {
        console.error('Error loading routines:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadRoutines();
  }, [userId]);

  // Save routines to Firestore
  useEffect(() => {
    const saveRoutine = async () => {
      if (!userId || isLoading) return;
      
      try {
        await setDoc(doc(db, 'coaches', userId, 'Routines', `${routineType}Routine`), {
          steps: steps.map(step => ({
            ...step,
            id: step.id || uuidv4()
          }))
        });
      } catch (error) {
        console.error('Error saving routine:', error);
      }
    };

    const timeoutId = setTimeout(saveRoutine, 500);
    return () => clearTimeout(timeoutId);
  }, [userId, steps, routineType, isLoading]);

  const calculateTimeOffset = (index) => {
    const previousSteps = steps.slice(0, index);
    return previousSteps
      .filter(step => step.type === 'timed')
      .reduce((total, step) => total + step.duration, 0);
  };

  const calculateTotalDuration = () => {
    return steps
      .filter(step => step.type === 'timed')
      .reduce((total, step) => total + (step.duration || 0), 0);
  };

  const TimelineView = () => {
    const steps = routineType === 'morning' ? morningSteps : eveningSteps;
    const setSteps = routineType === 'morning' ? setMorningSteps : setEveningSteps;

    const getRunningTime = () => {
      return steps
        .filter(step => step.type === 'timed')
        .reduce((total, step) => total + step.duration, 0);
    };

    return (
      <div className="space-y-4">
        {steps.map((step, index) => (
          <div 
            key={index} 
            className="group flex items-start gap-4 p-4 bg-neutral-800/50 rounded-lg border border-neutral-700 hover:border-neutral-600 transition-all"
            draggable="true"
            onDragStart={(e) => e.dataTransfer.setData('text/plain', index)}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => {
              e.preventDefault();
              const fromIndex = parseInt(e.dataTransfer.getData('text/plain'));
              const toIndex = index;
              const newSteps = [...steps];
              const [movedStep] = newSteps.splice(fromIndex, 1);
              newSteps.splice(toIndex, 0, movedStep);
              setSteps(newSteps);
            }}
          >
            <div className="cursor-move text-neutral-400 hover:text-neutral-300 mt-2">
              <GripVertical className="w-5 h-5" />
            </div>
            
            <div className="flex-1">
              <div className="flex justify-between items-start mb-2">
                <div>
                  <h3 className="font-semibold text-lg text-neutral-200">{step.title}</h3>
                  <div className="flex items-center gap-2 mt-1">
                    {step.type === 'timed' ? (
                      <>
                        <Clock className="w-4 h-4 text-neutral-400" />
                        <span className="text-sm text-neutral-400">{step.duration} minutes</span>
                      </>
                    ) : (
                      <>
                        <CheckSquare className="w-4 h-4 text-neutral-400" />
                        <span className="text-sm text-neutral-400">Checklist item</span>
                      </>
                    )}
                  </div>
                </div>
                
                <div className="flex gap-2">
                  <button 
                    onClick={() => setEditingIndex(index)}
                    className="p-1.5 text-neutral-400 hover:text-blue-400 hover:bg-blue-900/30 rounded-lg transition-colors"
                  >
                    <Edit2 className="w-4 h-4" />
                  </button>
                  <button 
                    onClick={() => setSteps(steps.filter((_, i) => i !== index))}
                    className="p-1.5 text-neutral-400 hover:text-red-400 hover:bg-red-900/30 rounded-lg transition-colors"
                  >
                    <Trash2 className="w-4 h-4" />
                  </button>
                </div>
              </div>
              
              <p className="text-neutral-400 text-sm">{step.description}</p>
            </div>
          </div>
        ))}

        {steps.length > 0 && steps.some(step => step.type === 'timed') && (
          <div className="mt-6 pt-4 border-t border-neutral-700">
            <div className="flex items-center gap-2 text-neutral-400">
              <Clock className="w-4 h-4" />
              <span className="font-medium">Timed Activities Total: {getRunningTime()} minutes</span>
            </div>
          </div>
        )}
      </div>
    );
  };

  const EditModal = ({ index, onClose }) => {
    const isNewStep = index === steps.length;
    const initialStep = isNewStep ? {
      type: 'timed',
      title: '',
      description: '',
      duration: 15
    } : steps[index];
    
    const [stepType, setStepType] = useState(initialStep.type);
    const [title, setTitle] = useState(initialStep.title);
    const [description, setDescription] = useState(initialStep.description);
    const [duration, setDuration] = useState(initialStep.duration);

    const handleSave = () => {
      const newStep = {
        id: initialStep.id || uuidv4(),
        type: stepType,
        title,
        description,
        ...(stepType === 'timed' && { duration: parseInt(duration) })
      };

      const newSteps = [...steps];
      if (isNewStep) {
        newSteps.push(newStep);
      } else {
        newSteps[index] = newStep;
      }
      setSteps(newSteps);
      onClose();
    };

    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
        <div className="bg-background-secondary rounded-xl p-6 max-w-lg w-full m-4 border border-border-light">
          <h3 className="text-xl font-headers text-text-primary mb-4">
            {isNewStep ? 'Add Step' : 'Edit Step'}
          </h3>
          
          <div className="space-y-4">
            <div className="flex gap-4">
              <button
                type="button"
                onClick={() => setStepType('timed')}
                className={cn(
                  "flex-1 p-3 rounded-lg flex items-center justify-center gap-2 transition-colors",
                  stepType === 'timed'
                    ? "bg-accent-primary text-white"
                    : "bg-background-tertiary text-text-secondary hover:bg-hover-secondary"
                )}
              >
                <Clock className="w-4 h-4" />
                Timed Activity
              </button>
              <button
                type="button"
                onClick={() => setStepType('checklist')}
                className={cn(
                  "flex-1 p-3 rounded-lg flex items-center justify-center gap-2 transition-colors",
                  stepType === 'checklist'
                    ? "bg-accent-primary text-white"
                    : "bg-background-tertiary text-text-secondary hover:bg-hover-secondary"
                )}
              >
                <CheckSquare className="w-4 h-4" />
                Checklist Item
              </button>
            </div>

            <div>
              <label className="block text-sm font-medium text-text-secondary mb-1">
                Title
              </label>
              <input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full p-2 bg-background-primary border border-border-light rounded-lg 
                         text-text-primary focus:ring-2 focus:ring-accent-primary focus:border-accent-primary"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-text-secondary mb-1">
                Description
              </label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={3}
                className="w-full p-2 bg-background-primary border border-border-light rounded-lg 
                         text-text-primary focus:ring-2 focus:ring-accent-primary focus:border-accent-primary"
              />
            </div>

            {stepType === 'timed' && (
              <div>
                <label className="block text-sm font-medium text-text-secondary mb-1">
                  Duration (minutes)
                </label>
                <input
                  type="number"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                  min="1"
                  className="w-full p-2 bg-background-primary border border-border-light rounded-lg 
                           text-text-primary focus:ring-2 focus:ring-accent-primary focus:border-accent-primary"
                />
              </div>
            )}
          </div>

          <div className="flex justify-end gap-3 mt-6">
            <button
              onClick={onClose}
              className="px-4 py-2 text-text-secondary hover:text-text-primary transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="px-4 py-2 bg-accent-primary text-white rounded-lg 
                       hover:bg-hover-primary transition-colors"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-text-secondary">Loading...</div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto space-y-6">
      <Card className="bg-background-secondary border border-border-light">
        <CardHeader className="pb-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              {routineType === 'morning' ? (
                <Sun className="w-6 h-6 text-accent-primary" />
              ) : (
                <Moon className="w-6 h-6 text-accent-tertiary" />
              )}
              <CardTitle className="font-headers text-text-primary">
                {routineType === 'morning' ? 'Morning' : 'Evening'} Routine
              </CardTitle>
            </div>
            
            <div className="flex gap-2">
              <button
                onClick={() => setRoutineType('morning')}
                className={cn(
                  "px-4 py-2 rounded-lg font-body transition-all duration-200",
                  routineType === 'morning'
                    ? "bg-accent-primary text-white"
                    : "bg-background-tertiary text-text-secondary hover:bg-hover-secondary"
                )}
              >
                Morning
              </button>
              <button
                onClick={() => setRoutineType('evening')}
                className={cn(
                  "px-4 py-2 rounded-lg font-body transition-all duration-200",
                  routineType === 'evening'
                    ? "bg-accent-tertiary text-white"
                    : "bg-background-tertiary text-text-secondary hover:bg-hover-secondary"
                )}
              >
                Evening
              </button>
            </div>
          </div>
        </CardHeader>

        <CardContent className="space-y-4">
          {steps.map((step, index) => (
            <div 
              key={step.id || index}
              className={cn(
                "group relative flex items-start gap-4 p-4 rounded-lg transition-all duration-200",
                "bg-background-primary border border-border-light",
                "hover:border-accent-secondary hover:shadow-md"
              )}
              draggable="true"
              onDragStart={(e) => e.dataTransfer.setData('text/plain', index)}
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => {
                e.preventDefault();
                const fromIndex = parseInt(e.dataTransfer.getData('text/plain'));
                const toIndex = index;
                const newSteps = [...steps];
                const [movedStep] = newSteps.splice(fromIndex, 1);
                newSteps.splice(toIndex, 0, movedStep);
                setSteps(newSteps);
              }}
            >
              <div className="cursor-move text-accent-secondary hover:text-accent-primary mt-2">
                <GripVertical className="w-5 h-5" />
              </div>
              
              <div className="flex-1">
                <div className="flex justify-between items-start mb-2">
                  <div>
                    <h3 className="font-headers text-text-primary text-lg">{step.title}</h3>
                    <div className="flex items-center gap-2 mt-1">
                      {step.type === 'timed' ? (
                        <>
                          <Clock className="w-4 h-4 text-accent-secondary" />
                          <span className="text-sm text-text-light font-body">
                            {step.duration} minutes
                          </span>
                        </>
                      ) : (
                        <>
                          <CheckSquare className="w-4 h-4 text-accent-secondary" />
                          <span className="text-sm text-text-light font-body">
                            Checklist item
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  
                  <div className="flex gap-2">
                    <button 
                      onClick={() => setEditingIndex(index)}
                      className="p-1.5 text-accent-secondary hover:text-accent-primary 
                               hover:bg-background-tertiary rounded-lg transition-colors"
                    >
                      <Edit2 className="w-4 h-4" />
                    </button>
                    <button 
                      onClick={() => setSteps(steps.filter((_, i) => i !== index))}
                      className="p-1.5 text-accent-secondary hover:text-red-500 
                               hover:bg-red-50 rounded-lg transition-colors"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                </div>
                
                <p className="text-text-secondary text-sm font-body">
                  {step.description}
                </p>
              </div>
            </div>
          ))}

          <button
            onClick={() => setEditingIndex(steps.length)}
            className={cn(
              "w-full p-4 rounded-lg border-2 border-dashed",
              "font-body text-accent-secondary hover:text-accent-primary",
              "border-border-light hover:border-accent-primary",
              "transition-all duration-200 flex items-center justify-center gap-2"
            )}
          >
            <Plus className="w-5 h-5" />
            Add Step
          </button>

          {steps.length > 0 && steps.some(step => step.type === 'timed') && (
            <div className="mt-6 pt-4 border-t border-border-light">
              <div className="flex items-center gap-2 text-text-secondary font-body">
                <Clock className="w-4 h-4 text-accent-secondary" />
                <span className="font-medium">
                  Total Duration: {calculateTotalDuration()} minutes
                </span>
              </div>
            </div>
          )}
        </CardContent>
      </Card>

      {editingIndex !== null && (
        <EditModal
          index={editingIndex}
          onClose={() => setEditingIndex(null)}
        />
      )}
    </div>
  );
};

export default RoutineBuilder;