import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth, signOut, sendPasswordResetEmail } from 'firebase/auth';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { Input } from './ui/Input';
import { Button } from './ui/Button';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { API_URLS } from '../constants/api';
import { 
  UserCircle, 
  Phone, 
  LogOut, 
  ArrowLeft, 
  Lock,
  Mail,
  CheckCircle,
  AlertCircle
} from 'lucide-react';

const AccountSettings = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [calendlyLink, setCalendlyLink] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [resetError, setResetError] = useState('');
  const [calendlyError, setCalendlyError] = useState('');
  const auth = getAuth();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const loadAccountData = async () => {
      if (!user) return;
      
      try {
        const coachDoc = await getDoc(doc(db, 'coaches', user.uid));
        if (coachDoc.exists()) {
          const data = coachDoc.data();
          setFirstName(data.firstName || '');
          setLastName(data.lastName || '');
          setPhoneNumber(data.phoneNumber || '');
          setCalendlyLink(data.calendlyLink || '');
          setSubscriptionStatus(data.subscriptionStatus || 'inactive');
        }
      } catch (error) {
        console.error('Error loading account data:', error);
      }
    };

    loadAccountData();

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [user]);

  const handlePasswordReset = async () => {
    setResetEmailSent(false);
    setResetError('');
    try {
      await sendPasswordResetEmail(auth, user.email);
      setResetEmailSent(true);
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setResetError('Failed to send password reset email. Please try again.');
    }
  };

  const handleLogout = () => {
    signOut(auth);
    navigate('/');
  };

  const updateName = async () => {
    if (!user) return;
    
    try {
      setIsLoading(true);
      await updateDoc(doc(db, 'coaches', user.uid), {
        firstName,
        lastName,
      });
      
      // Also update any other collections that store the coach's name
      setIsLoading(false);
    } catch (error) {
      console.error('Error updating name:', error);
      setIsLoading(false);
    }
  };

  const validateAndSaveCalendlyLink = async () => {
    // Reset error state
    setCalendlyError('');
    
    // Validate that this is a Calendly URL
    if (calendlyLink && !calendlyLink.includes('calendly.com')) {
      setCalendlyError('Please enter a valid Calendly URL (should contain calendly.com)');
      return;
    }
    
    try {
      setIsLoading(true);
      await updateDoc(doc(db, 'coaches', user.uid), {
        calendlyLink,
        updatedAt: new Date()
      });
      alert('Calendly link saved successfully');
    } catch (error) {
      console.error('Error saving Calendly link:', error);
      setCalendlyError('Failed to save Calendly link. Please try again.');
    }
  };
    

  const verifyPhoneNumber = async () => {
    if (!user || !phoneNumber) return;
    
    try {
      setIsLoading(true);
      await updateDoc(doc(db, 'coaches', user.uid), {
        phoneNumber,
        phoneVerified: true, // In a real app, you'd verify with SMS code
      });
      setIsLoading(false);
    } catch (error) {
      console.error('Error updating phone:', error);
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-background-primary pt-24 pb-16">
      <div className="max-w-4xl mx-auto px-4">
        <div className="flex items-center mb-8">
          <Button
            variant="ghost"
            className="mr-2"
            onClick={() => navigate(-1)}
          >
            <ArrowLeft className="h-5 w-5" />
          </Button>
          <h1 className="text-2xl font-bold text-text-primary">Account Settings</h1>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Main Settings Column */}
          <div className="md:col-span-2 space-y-6">
            {/* Profile Card */}
            <Card className="bg-background-secondary border-border-light shadow-md hover:shadow-lg transition-shadow">
              <CardHeader className="pb-2">
                <div className="flex items-center space-x-3">
                  <UserCircle className="h-5 w-5 text-accent-primary" />
                  <CardTitle className="font-headers text-h3 text-text-primary">Profile Information</CardTitle>
                </div>
              </CardHeader>
              <CardContent className="space-y-6">
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <label className="text-small font-medium text-text-light">First Name</label>
                    <Input
                      placeholder="Enter your first name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className="bg-background-primary border-border-medium focus:border-accent-primary h-11 text-text-primary"
                    />
                  </div>
                  <div className="space-y-2">
                    <label className="text-small font-medium text-text-light">Last Name</label>
                    <Input
                      placeholder="Enter your last name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      className="bg-background-primary border-border-medium focus:border-accent-primary h-11 text-text-primary"
                    />
                  </div>
                </div>
                <Button
                  onClick={updateName}
                  variant="primary"
                  className="w-full"
                >
                  Update Profile
                </Button>
                
                <div className="pt-2 border-t border-border-light">
                  <div className="space-y-2">
                    <label className="text-small font-medium text-text-light">Calendly Link</label>
                    <Input
                      placeholder="https://calendly.com/yourusername"
                      value={calendlyLink}
                      onChange={(e) => setCalendlyLink(e.target.value)}
                      className="bg-background-primary border-border-medium focus:border-accent-primary h-11 text-text-primary"
                    />
                    {calendlyError && (
                      <div className="text-red-500 text-xs mt-1">{calendlyError}</div>
                    )}
                    <p className="text-xs text-text-light mt-1">
                      Connect your Calendly account to enable client bookings after they complete your onboarding form
                    </p>
                  </div>
                  <Button
                    onClick={validateAndSaveCalendlyLink}
                    variant="secondary"
                    className="w-full mt-2"
                  >
                    Save Calendly Link
                  </Button>
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <Phone className="h-5 w-5 text-accent-primary" />
                  <span>Phone Verification</span>
                </CardTitle>
              </CardHeader>
              <CardContent className="space-y-4">
                <div>
                  <label className="text-sm text-text-light mb-1 block">Phone Number</label>
                  <Input
                    placeholder="Enter your phone number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="bg-background-secondary"
                  />
                </div>
                <Button
                  onClick={verifyPhoneNumber}
                  variant="primary"
                  className="w-full md:w-auto"
                  disabled={isLoading || !phoneNumber}
                >
                  {isLoading ? 'Verifying...' : 'Verify Phone'}
                </Button>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <Lock className="h-5 w-5 text-accent-primary" />
                  <span>Security</span>
                </CardTitle>
              </CardHeader>
              <CardContent className="space-y-4">
                <div className="space-y-2">
                  <Button
                    onClick={handlePasswordReset}
                    variant="outline"
                    className="w-full md:w-auto"
                  >
                    Reset Password
                  </Button>
                  {resetEmailSent && (
                    <div className="flex items-center gap-2 text-sm text-accent-tertiary">
                      <CheckCircle className="h-4 w-4" />
                      <span>Password reset email sent to {user?.email}</span>
                    </div>
                  )}
                  {resetError && (
                    <div className="flex items-center gap-2 text-sm text-accent-negative">
                      <AlertCircle className="h-4 w-4" />
                      <span>{resetError}</span>
                    </div>
                  )}
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardContent className="py-4">
                <Button
                  onClick={handleLogout}
                  variant="ghost"
                  className="w-full text-accent-negative flex items-center justify-center gap-2"
                >
                  <LogOut className="h-5 w-5" />
                  <span>Sign Out</span>
                </Button>
              </CardContent>
            </Card>
          </div>
          
          {/* Right column would go here */}
          <div className="md:col-span-1">
            {/* Additional content can be added here */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;