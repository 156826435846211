import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { doc, getDoc, setDoc, updateDoc, serverTimestamp, collection, getDocs, addDoc, deleteDoc, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import { useOutletContext } from 'react-router-dom';
import { MessageCircle, ShoppingBag, Coffee, Utensils, Package } from 'lucide-react';
import { Button } from '../ui/Button';

const ProductsSetup = () => {
  const { user } = useAuth();
  const { goToNextStep, goToPrevStep } = useOutletContext();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Predefined product categories with options
  const productCategories = [
    {
      category: 'supplements',
      icon: <ShoppingBag className="w-5 h-5 text-accent-tertiary" />,
      products: [
        {
          id: 'protein',
          name: 'Promix Protein Powder',
          description: 'One ingredient grass-fed whey.',
          aiExample: 'Check out Promix Protein Powder. One ingredient grass-fed whey. Nothing artificial. Really clean.',
          tags: 'protein,supplements,post-workout',
          link: ''
        },
        {
          id: 'omega3',
          name: 'Omega-3 Fish Oil',
          description: 'Essential fatty acids for heart and brain health',
          aiExample: 'Omega-3 Fish Oil would be a good addition to your routine. Look for brands that contain omega-3s as free fatty acids',
          tags: 'omega-3,fish-oil,heart-health,inflammation',
          link: ''
        },
        {
          id: 'thorneMagnesiumBisglycinate',
          name: 'Thorne Magnesium Bisglycinate',
          description: 'Powdered formula that promotes restful sleep and muscle relaxation',
          aiExample: 'Thorne Magnesium Bisglycinate will help you sleep. Here is the link:',
          tags: 'magnesium,sleep,recovery,mineral',
          link: 'https://www.thorne.com/products/dp/magnesium-bisglycinate'
        }
      ]
    },
    {
      category: 'grocery',
      icon: <Utensils className="w-5 h-5 text-accent-tertiary" />,
      products: [
        {
          id: 'skyeProteinCrisps',
          name: 'Skye Dark Chocolate Protein Crisps',
          description: '5 ingredient alternative to protein bars. No added sugars, no artificial ingredients. 20g of protein. Taste unreal. ',
          aiExample: 'Yes I agree with you. Most protein bars are loaded with artificial ingredients. I eat these Dark Chocolate Protein Crisps when I need a quick snack. ',
          tags: 'protein,puffedQuinoa,darkChocolate,wholefoods,fewingredients',
          link: 'https://skyenutrition.us/products/protein-quinoa-crisp'
        },
        {
          id: 'lineageProvisionsDriedSteak',
          name: 'Lineage Provisions 100% Grass-Fed Air-dried Steak',
          description: 'Great clean snack high protein snack ',
          aiExample: 'Have you tried the Lineage Provisions 100% Grass-Fed Air-dried Steak? It\'s a great clean high protein snack. ',
          tags: 'protein,meat,snack,grassfed,airdried',
          link: ''
        },
        {
          id: 'vitalFarmsPastureRaisedEggs',
          name: 'Vital Farms Pasture Raised Eggs',
          description: 'Great source of protein and healthy fats',
          aiExample: 'I love these eggs. They are great source of protein and healthy fats. ',
          tags: 'protein,eggs,healthyfats,pastureraised,organic',
          link: ''
        }
      ]
    },
    {
      category: 'miscellaneous',
      icon: <Package className="w-5 h-5 text-accent-tertiary" />,
      products: [
        {
          id: 'mouthTape',
          name: 'Mouth Tape',
          description: 'Mouth tape to promote nasal breathing and better sleep',
          aiExample: 'Mouth Tape is great for promoting nasal breathing and better sleep. I would give it a try',
          tags: 'sleep,breathing,fitness',
          link: ''
        },
        {
          id: 'hyperice',
          name: 'Hyperice Massage Gun',
          description: 'Incredible recovery tool. Massage gun to help with recovery and muscle soreness',
          aiExample: 'I use the Hyperice Massage Gun to help with recovery and muscle soreness. Check out my code',
          tags: 'recovery,massage,wellness',
          link: ''
        }
      ]
    }
  ];

  // Find product by ID
  const findProductById = (productId) => {
    for (const category of productCategories) {
      const product = category.products.find(p => p.id === productId);
      if (product) {
        return {
          ...product,
          category: category.category
        };
      }
    }
    return null;
  };

  // Load saved products if they exist
  useEffect(() => {
    const loadProductData = async () => {
      try {
        if (!user?.uid) return;
        
        // Query products collection instead of a single document
        const productsRef = collection(db, 'coaches', user.uid, 'products');
        const productsSnapshot = await getDocs(productsRef);
        
        // Extract product IDs from existing products
        const existingProductIds = [];
        productsSnapshot.forEach(doc => {
          const data = doc.data();
          // Find the matching predefined product by name
          for (const category of productCategories) {
            const matchingProduct = category.products.find(p => p.name === data.name);
            if (matchingProduct) {
              existingProductIds.push(matchingProduct.id);
              break;
            }
          }
        });
        
        setSelectedProducts(existingProductIds);
      } catch (error) {
        console.error('Error loading product data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    loadProductData();
  }, [user]);

  // Toggle product selection
  const toggleProduct = async (productId) => {
    const productExists = selectedProducts.includes(productId);
    let newSelection;
    
    if (productExists) {
      // Remove product
      newSelection = selectedProducts.filter(id => id !== productId);
      
      try {
        if (!user?.uid) return;
        
        const product = findProductById(productId);
        if (!product) return;
        
        // Get the product document to delete
        const productsRef = collection(db, 'coaches', user.uid, 'products');
        const productsSnapshot = await getDocs(productsRef);
        
        productsSnapshot.forEach(async (doc) => {
          if (doc.data().name === product.name) {
            await deleteDoc(doc.ref);
          }
        });
      } catch (error) {
        console.error('Error removing product:', error);
        return;
      }
    } else {
      // Add product
      newSelection = [...selectedProducts, productId];
      
      try {
        if (!user?.uid) return;
        
        const product = findProductById(productId);
        if (!product) return;
        
        // Add as individual document to products collection with correct structure
        const productRef = collection(db, 'coaches', user.uid, 'products');
        await addDoc(productRef, {
          name: product.name,
          tags: product.tags,
          category: product.category,
          link: ''  // Always use empty string for link
        });
      } catch (error) {
        console.error('Error adding product:', error);
        return;
      }
    }
    
    setSelectedProducts(newSelection);
  };

  // Find product details by ID
  const getProductById = (productId) => {
    for (const category of productCategories) {
      const product = category.products.find(p => p.id === productId);
      if (product) return product;
    }
    return null;
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-text-secondary">Loading...</div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div className="space-y-4">
        <h3 className="text-xl font-medium text-text-primary font-headers">Select Products You Recommend</h3>
        <p className="text-text-secondary font-body">
          When clients ask for product recommendations, your AI will suggest these items.
        </p>
        
        <div className="space-y-8">
          {productCategories.map((category) => (
            <div key={category.category} className="space-y-4">
              <div className="flex items-center gap-2">
                {category.icon}
                <h4 className="text-lg font-medium text-text-primary font-headers">{category.category.charAt(0).toUpperCase() + category.category.slice(1)}</h4>
              </div>
              
              <div className="grid md:grid-cols-2 gap-4">
                {category.products.map(product => (
                  <div
                    key={product.id}
                    onClick={() => toggleProduct(product.id)}
                    className={`
                      p-5 rounded-lg border-2 cursor-pointer transition-all duration-200
                      ${selectedProducts.includes(product.id)
                        ? 'border-accent-primary bg-accent-primary/10 shadow-md transform scale-[1.02]'
                        : 'border-border-light hover:border-accent-secondary hover:bg-background-tertiary'
                      }
                    `}
                  >
                    <div className="flex items-center gap-3 mb-2">
                      <div className={`w-5 h-5 rounded-full border-2 flex items-center justify-center 
                                      ${selectedProducts.includes(product.id) ? 'border-accent-primary' : 'border-border-medium'}`}>
                        {selectedProducts.includes(product.id) && (
                          <div className="w-2.5 h-2.5 rounded-full bg-accent-primary" />
                        )}
                      </div>
                      <h4 className="text-lg font-medium text-text-primary font-headers">{product.name}</h4>
                    </div>
                    <p className="text-text-secondary mb-4 font-body">{product.description}</p>
                    
                    {/* AI preview */}
                    {selectedProducts.includes(product.id) && (
                      <div className="bg-background-tertiary p-4 rounded-lg mt-4 border border-accent-primary/20">
                        <div className="flex items-start gap-3">
                          <MessageCircle className="w-5 h-5 text-accent-primary shrink-0 mt-1" />
                          <div>
                            <p className="text-sm text-text-light mb-1 font-body">When asked about products, your AI will say:</p>
                            <p className="text-text-primary font-body font-medium">"{product.aiExample}"</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      
      {/* Navigation buttons */}
      <div className="flex justify-between mt-8">
        <Button
          onClick={goToPrevStep}
          className="bg-background-tertiary text-text-primary hover:bg-hover-secondary transition-all duration-200 border border-border-medium px-6 py-3 text-base"
        >
          Back
        </Button>
        
        <Button
          onClick={goToNextStep}
          className="bg-accent-primary text-white hover:bg-hover-primary transition-all duration-200 shadow-md hover:shadow-lg min-w-[150px] px-6 py-3 text-base"
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default ProductsSetup; 