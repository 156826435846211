// src/components/ui/Label.jsx

import React from 'react';
import { cn } from '../../lib/utils';

export const Label = React.forwardRef(({ className, children, ...props }, ref) => {
  return (
    <label
      ref={ref}
      className={cn(
        "text-sm font-medium font-body text-text-primary",
        "peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
        "transition-colors duration-200",
        className
      )}
      {...props}
    >
      {children}
    </label>
  );
});

Label.displayName = "Label";